import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const initialProps = {
  anchor: null,
  menuList: null,
  onMenuSelect: null,
};

export default function EWMenu(props = initialProps) {
  var { anchor, menuList, onMenuSelect } = props;
  console.log("menu Props: ", props);
  const open = Boolean(anchor);
  const handleClose = (value) => {
  
    onMenuSelect(value);
  };

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuList?.map((ele) => (
          <MenuItem style={{width:100}} onClick={() => handleClose(ele)}>{ele.label}</MenuItem>
        ))}
      </Menu>
    </div>
  );
}
