import React from "react";
import { Link } from "react-router-dom";
import { Divider, Paper, Typography } from "@mui/material";
import DataGrid2 from "../../MComponent/Table/DataGrid2";
import { constants } from "./Dimension/Data/QueryConstants";
import { getDateFromEpoch } from "../../Utils/Common/Library";
import store from "../../Redux/Store";
import { useHistory } from "react-router-dom";
function ViewBox({ rowData }) {
  console.log(rowData, "PLAN");
  const Plan = rowData;
  if (!Plan.Dimensions) Plan.Dimensions = [];
  if (!Plan.Sections) Plan.Sections = [];

  return (
    <div>
      <Link to={{ pathname: "/pms", state: { Plan } }}>View</Link>
    </div>
  );
}
function getDate(val) {
  console.log("Vlaue in aggrid is: ", val);
  return getDateFromEpoch({ epoch: val });
}

function MyPlan() {
  const history = useHistory();
  const userId = store.getState().AuthReducer.userId;
  console.log("USER ID: ", userId, " ", store.getState().AuthReducer);
  //  return <></>;
  return (
    <div className="Flex" style={{ justifyContent: "start" }}>
      <div>
        <Typography variant="h4">My Plan</Typography>
        <Divider sx={{ margin: "2em 0" }} />
        <Paper sx={{ width: "40vw" }}>
          <DataGrid2
            valueFormatter={{
              PlanStartDate: getDate,
              PlanEndDate: getDate,
            }}
            query={{
              RequiredFields: [{ UserID: userId }],
              QueryName: constants.FindAllEPPByAppraiseeUserID,
            }}
            // featureId={"19151"}
            // getDataOptions={{
            //   payload: {
            //     "Data.AppraiseeUserID": userId,
            //   },
            // }}
            heading={["Plan Period", "Start Date", "End Date"]}
            rowToFieldMap={["PlanPeriod", "PlanStartDate", "PlanEndDate"]}
            onRowClicked={({ data }) => {
              console.log(data, "THISISROWDATA");
              const Plan = data;
              if (!Plan.Dimensions) Plan.Dimensions = [];
              if (!Plan.Sections) Plan.Sections = [];
              history.push({ pathname: "/pms", state: { Plan } });
            }}
          />
        </Paper>
      </div>
    </div>
  );
}

export default MyPlan;
