import { IconButton, Tooltip } from "@mui/material";
import React from "react";

function ActionIcons({ Icons }) {
  return (
    <div style={{ display: "flex", gap: "1em" }}>
      {Icons.map((icon, index) => {
        // const Icon = require("@mui/icons-material/" + icon.Icon).default;
        const Icon = () => <></>;
        return (
          <Tooltip key={icon.IconID} title={icon.Label}>
            <IconButton>
              <Icon sx={{ fontSize: "1.3em" }} />
            </IconButton>
          </Tooltip>
        );
      })}
    </div>
  );
}

export default ActionIcons;
