import React from 'react'
import {
    Modal,
    Button,
    Paper,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Box,
  } from '@mui/material'

function Applicability2() {
    const Data=["Location","Business Unit","Legal Entity","Funtion","WorkLocation","Role","Project","Team"]
  return (
    <div>
{Data.map((ele)=>{
    return(
        <>
        <div className='Flex' style={{flexDirection:'row'}}>
        <h4 >{ele}</h4>
        <Box sx={{ minWidth: 160, margin: 3 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{ele}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={ -1}
            onChange={()=>{}}
          >
            <MenuItem value={-1}>{'Select a value'}</MenuItem>
            <MenuItem value={0}>{'Head Office'}</MenuItem>
            <MenuItem value={1}>{'Branch Office'}</MenuItem>
            {/* {menuItems?.map((ele, index) => {
              return (
                <MenuItem value={ele.value ? ele.value : ele.label} key={index}>
                  {ele.label}
                </MenuItem>
              )
            })} */}
          </Select>
        </FormControl>
        <br />
      </Box>
        </div>
        </>
    )
})}

    </div>
  )
}

export default Applicability2