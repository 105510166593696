import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { DialogContent, IconButton } from "@mui/material";

import { Close as CloseIcon } from "@mui/icons-material";
//import { changePassword } from '../Utils/ApiService';

export default function CPCard(props) {
  console.log("From CP card: ", props);
  const { onClose, open } = props;
  const Placeholder_1 = props.Placeholder_1;
  const Placeholder_2 = props.Placeholder_2;
  const Placeholder_3 = props.Placeholder_3;
  const Placeholder_4 = props.Placeholder_4;
  const Component1 =
    Placeholder_1 !== undefined
      ? require("../Atmoic/" + Placeholder_1.AComponent).default
      : null;
  const Component2 =
    Placeholder_2 !== undefined
      ? require("../Atmoic/" + Placeholder_2.AComponent).default
      : null;
  const Component3 =
    Placeholder_3 !== undefined
      ? require("../Atmoic/" + Placeholder_3.AComponent).default
      : null;
  const Component4 =
    Placeholder_4 !== undefined
      ? require("../Atmoic/" + Placeholder_4.AComponent).default
      : null;

  const [values, setValues] = useState([]);
  const handleClose = () => {
    onClose();
  };

  const onSubmit = () => {
    onClose();
    //   changePassword(values);
  };
  const handleChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    let pair = {};
    pair[key] = value;
    setValues((prevState) => {
      return { ...prevState, ...pair };
    });
  };

  const ref = React.createRef();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        {props.Title}
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Component1
          placeholder={Placeholder_1.props.Label}
          type={Placeholder_1.props.Type}
          ref={ref}
          onChange={handleChange}
        />
        <br />
        <Component2
          placeholder={Placeholder_2.props.Label}
          type={Placeholder_2.props.Type}
          ref={ref}
          onChange={handleChange}
        />
        <br />
        <Component3
          placeholder={Placeholder_3.props.Label}
          type={Placeholder_3.props.Type}
          ref={ref}
          onChange={handleChange}
        />
        <br />
        <Component4
          Title={Placeholder_4.props.Label}
          Type="contained"
          Handler={onSubmit}
        />
      </DialogContent>
    </Dialog>
  );
}
