import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import methodHandler from "../utils/EventHandler";
import useRender from "../utils/useRender";

function CheckBox_1({ id, compNumber }) {
  var { label, value, onChange, onTrue, onFalse } = useSelector(
    (state) => state.DataReducer[id][compNumber]
  );
  const dispatch = useDispatch();
  function changeState() {
    if (onChange) {
      methodHandler(onChange, id);
    }
    if (!value === true && onTrue) {
      methodHandler(onTrue, id);
    }
    if (!value === false && onFalse) {
      methodHandler(onFalse, id);
    }
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: compNumber,
        data: !value,
        props: "value",
      },
    });
  }
  if (!useRender({ id, compNumber })) return <></>;
  return (
    <div>
      {" "}
      <FormGroup>
        <FormControlLabel
          onClick={changeState}
          control={<Checkbox defaultChecked={value} />}
          label={label}
        />
      </FormGroup>
    </div>
  );
}

export default CheckBox_1;
