import React, { useEffect, useState } from "react";
import Atomic from "../../../AComponent/Atomic";
import { useDispatch, useSelector } from "react-redux";
import Applicability from "../../../MComponent/Applicability/Applicability5";
import { Typography } from "@mui/material";
const { Input, Button, Date, Dropdown, EwSwitch, Radio } = Atomic;
const id = "MPP";
const RatingScaleID = "19148";
const unitMenu = [
  {
    label: "Days",
    value: "D",
  },
  {
    label: "Months",
    value: "M",
  },
  {
    label: "Year",
    value: "Y",
  },
];
function General() {
  const {
    PlanName,
    PlanDescription,
    PlanStart,
    PlanEnd,
    PlanApplicabilityId,
    MinimumTenureUnit,
    MinimumTenure,
    MinimumConfirmationTenureUnit,
    MinimumConfirmationTenure,
    MaximumTenureUnit,
    MaximumTenure,
    MinimumPerformancePeriod,
    PlanParticipationBasis,
    MinimumEmployeeTenure,
    MaximumEmployeeTenure,
    MinimumDaysInPlan,
    MinimumReviewPeriod,
    AggregatePlanScore,
    AggregateRounding,
    AggregateScoreRater,
    AggregateRatingScaleID,
    AggregateScaleDisplay,
    AggregateScoreDisplay,
    AggregateFinalScore,
    AdjustedPlanScore,
    AdjustedDefaultRating,
    AdjustedMinimumPeriod,
    AdjustedPlanScoreLabelConversion,
    AdjustedPlanScoreRatingScaleID,
    AdjustedPlanScoreDisplay,
    AdjustedPlanFinalScore,
    AdjustedRounding,
    AssessorPlanScore,
    AssessorPlanRater,
    AssessorPlanRatingScaleId,
    AssessorPlanFinalScore,
    ModeratedPlanScore,
    ModeratedPlanScoreRatingId,
    ModeratedPlanScoreLabel,
    ModeratedPlanScoreUploaded,
    ModeratedPlanScoreFinalScore,
    rateList,
  } = useSelector((state) => {
    if (!state.DataReducer[id]) return {};
    return state.DataReducer[id];
  });
  function updateState(val, type) {
    if (type === "AdjustedPlanScoreRatingScaleID") {
      updateState(undefined, "AdjustedDefaultRatingList");
    }
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: type,
        data: val,
      },
    });
  }
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (
  //     AdjustedPlanScoreRatingScaleID &&
  //     AdjustedPlanScoreRatingScaleID !== -1 &&
  //     !AdjustedDefaultRatingList
  //   ) {
  //     getData({
  //       featureId: RatingScaleID,
  //       payload: {
  //         "Header.TransactionID": AdjustedPlanScoreRatingScaleID,
  //       },
  //     }).then((res) => {
  //       let arr = [];
  //       if (res.Data && res.Data[0]) {
  //         arr = res.Data[0]?.ScaleValues?.map((ele) => ({
  //           label: ele.Label,
  //           value: ele.OptionID,
  //         }));
  //       }
  //       if (arr.length > 0) {
  //         updateState(arr, "AdjustedDefaultRatingList");
  //       }
  //     });
  //   }
  // }, [AdjustedPlanScoreRatingScaleID]);
  function renderBsicInfo() {
    return (
      <>
        <div className="Flex-row">
          <div className="Flex">
            <div>
              <Input
                label="Plan Name"
                value={PlanName}
                onChange={(val) => updateState(val, "PlanName")}
              />
            </div>
            <div>
              <Input
                label="Plan Description"
                value={PlanDescription}
                onChange={(val) => updateState(val, "PlanDescription")}
                rows={4}
              />
            </div>
          </div>
          <div className="Flex g20">
            <div>
              <Date
                label={"Plan Start"}
                value={PlanStart}
                onChange={(val) => updateState(val, "PlanStart")}
                muiProps={{
                  views: ["day", "month"],
                  inputFormat: "MM/dd",
                }}
              />
            </div>
            <div>
              <Date
                label={"Plan End"}
                value={PlanEnd}
                onChange={(val) => updateState(val, "PlanEnd")}
                muiProps={{
                  views: ["day", "month"],
                  inputFormat: "MM/dd",
                }}
              />
            </div>
          </div>
        </div>
        <br />
      </>
    );
  }
  function renderApplicability() {
    const menu = [
      { label: "Date of Joining", value: "J" },
      { label: "Date of Confirmation", value: "C" },
    ];
    return (
      <>
        <Typography variant="h3">Plan Applicability</Typography>
        <hr />
        <br />
        <div className="Flex">
          <Applicability
            AuthorFeatureID={"19149"}
            value={PlanApplicabilityId}
            onChange={(val) => updateState(val, "PlanApplicabilityId")}
            ApplicabilityReferenceField="PlanApplicabilityId"
          />
        </div>

        <br />
        {/* <div style={{ width: "100" }}>
          <Dropdown
            menuItems={menu}
            label="Plan Participation Basis"
            value={PlanParticipationBasis}
            onChange={(val) => updateState(val, "PlanParticipationBasis")}
          />
        </div>
        <div className="Flex-row">
          <div>
            <Input
              label="Minimum Employee Tenure"
              value={MinimumEmployeeTenure}
              onChange={(val) => updateState(val, "MinimumEmployeeTenure")}
              type="number"
            />
          </div>
          <div>
            <Input
              label="Maximum Employee Tenure"
              value={MaximumEmployeeTenure}
              onChange={(val) => updateState(val, "MaximumEmployeeTenure")}
              type="number"
            />
          </div>
          <div>
            <Input
              label="Minimum Days in Plan"
              value={MinimumDaysInPlan}
              onChange={(val) => updateState(val, "MinimumDaysInPlan")}
              type="number"
            />
          </div>
        </div>
        <div className="Flex">
          <Input
            label="Minimum Review Period"
            value={MinimumReviewPeriod}
            onChange={(val) => updateState(val, "MinimumReviewPeriod")}
            type="number"
          />
        </div>
        <br /> */}
      </>
    );
  }
  function renderParticipation() {
    return (
      <>
        <Typography variant="h3">Minimum Tenure</Typography>
        <hr />
        <br />
        <div className="Flex-row">
          <div style={{ width: "50%" }}>
            <Dropdown
              menuItems={unitMenu}
              label={"Unit"}
              value={MinimumTenureUnit}
              onChange={(val) => updateState(val, "MinimumTenureUnit")}
              type="number"
            />
          </div>
          <div>
            <Input
              label={"Tenure"}
              value={MinimumTenure}
              onChange={(val) => updateState(val, "MinimumTenure")}
            />
          </div>
        </div>
        <br />
        <Typography variant="h3">Minimum Confirmation Tenure</Typography>
        <hr />
        <br />
        <div className="Flex-row">
          <div style={{ width: "50%" }}>
            <Dropdown
              menuItems={unitMenu}
              label={"Unit"}
              value={MinimumConfirmationTenureUnit}
              onChange={(val) =>
                updateState(val, "MinimumConfirmationTenureUnit")
              }
            />
          </div>
          <div>
            <Input
              label={"Tenure"}
              value={MinimumConfirmationTenure}
              onChange={(val) => updateState(val, "MinimumConfirmationTenure")}
              type="number"
            />
          </div>
        </div>
        <br />
        <Typography variant="h3">Maximum Tenure</Typography>
        <hr />
        <br />
        <div className="Flex-row">
          <div style={{ width: "50%" }}>
            <Dropdown
              menuItems={unitMenu}
              label={"Unit"}
              value={MaximumTenureUnit}
              onChange={(val) => updateState(val, "MaximumTenureUnit")}
            />
          </div>
          <div>
            <Input
              placeholder={"99"}
              label={"Tenure"}
              value={MaximumTenure}
              onChange={(val) => updateState(val, "MaximumTenure")}
              type="number"
            />
          </div>
        </div>
        <div className="Flex-row">
          <div>
            <Input
              label="Minimum Performance Period"
              value={MinimumPerformancePeriod}
              onChange={(val) => updateState(val, "MinimumPerformancePeriod")}
            />
          </div>
          <div>
            <Input
              label="Minimum Review Period"
              value={MinimumReviewPeriod}
              onChange={(val) => updateState(val, "MinimumReviewPeriod")}
              type="number"
            />
          </div>
        </div>
      </>
    );
  }
  function renderAggregateScore() {
    let scaleDisplayOPtions = [
      { label: "Value", value: "V" },
      { label: "Scale Label", value: "L" },
      { label: "Both value and label", value: "B" },
    ];
    let scoreDisplayOptions = [
      { label: "Displayed to all Actors", value: "A" },
      { label: "Restricted to Appraiser and Reviewer", value: "R" },
    ];
    let raters = [
      { label: "Appraisee", value: "AE" },
      { label: "Appraiser", value: "AR" },
      { label: "Reviewer", value: "RR" },
    ];
    return (
      <>
        <Typography variant="h3">Aggregate Plan Score</Typography>
        <hr />
        <div className="Flex">
          <EwSwitch
            label={"Aggregate Plan Score"}
            value={AggregatePlanScore === "E"}
            onChange={(flag) =>
              updateState(flag ? "E" : "D", "AggregatePlanScore")
            }
          />
        </div>
        {AggregatePlanScore !== "E" ? (
          <></>
        ) : (
          <>
            <div className="Flex-row">
              <div>
                <Input
                  label="Rounding"
                  value={AggregateRounding}
                  onChange={(val) => updateState(val, "AggregateRounding")}
                  type="number"
                />
              </div>
              <div>
                <Dropdown
                  menuItems={raters}
                  label={"Rater"}
                  value={AggregateScoreRater}
                  onChange={(val) => updateState(val, "AggregateScoreRater")}
                />
              </div>
            </div>
            <br />
            <div className="Flex">
              <div style={{ width: "100%" }}>
                <Dropdown
                  label={"Aggregate Plan Score Rating Scales"}
                  featureId={RatingScaleID}
                  value={AggregateRatingScaleID}
                  onChange={(val) => updateState(val, "AggregateRatingScaleID")}
                  mapper={{
                    label: "RatingScaleName",
                    value: "TransactionID",
                  }}
                  apiResult={rateList}
                  storeApiResult={(val) => updateState(val, "rateList")}
                />
              </div>
            </div>
            <br />
            <div className="Flex-row g20">
              <div style={{ width: "50%" }}>
                <Dropdown
                  menuItems={scaleDisplayOPtions}
                  label="Aggregate Scale Display"
                  value={AggregateScaleDisplay}
                  onChange={(val) => updateState(val, "AggregateScaleDisplay")}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Dropdown
                  menuItems={scoreDisplayOptions}
                  label="Aggregate Score Display"
                  value={AggregateScoreDisplay}
                  onChange={(val) => updateState(val, "AggregateScoreDisplay")}
                />
              </div>
            </div>
            <div className="Flex">
              <EwSwitch
                label={"Calculate Final Score"}
                value={AggregateFinalScore === "E"}
                onChange={(flag) =>
                  updateState(flag ? "E" : "D", "AggregateFinalScore")
                }
              />
            </div>
          </>
        )}
        <br />
      </>
    );
  }
  function renderAdjustedScore() {
    let scoreDisplayOptions = [
      { label: "Displayed to all Actors", value: "A" },
      { label: "Restricted to Appraiser and Reviewer", value: "R" },
    ];
    return (
      <>
        <Typography variant="h3">Adjusted Score</Typography>
        <hr />
        <div className="Flex">
          <EwSwitch
            label={"Adjusted Plan Score"}
            value={AdjustedPlanScore === "E"}
            onChange={(flag) =>
              updateState(flag ? "E" : "D", "AdjustedPlanScore")
            }
          />
        </div>

        {AdjustedPlanScore === "E" && (
          <>
            <div className="Flex">
              <EwSwitch
                label="Adjusted Plan Score Label Conversion"
                value={AdjustedPlanScoreLabelConversion === "E"}
                onChange={(flag) =>
                  updateState(
                    flag ? "E" : "D",
                    "AdjustedPlanScoreLabelConversion"
                  )
                }
              />
            </div>
            <br />
            {AdjustedPlanScoreLabelConversion === "E" && (
              <div className="Flex-row">
                <div style={{ width: "50%" }}>
                  <Dropdown
                    label={"Adjusted Plan Score Rating Scales"}
                    featureId={RatingScaleID}
                    value={AdjustedPlanScoreRatingScaleID}
                    onChange={(val) =>
                      updateState(val, "AdjustedPlanScoreRatingScaleID")
                    }
                    mapper={{
                      label: "RatingScaleName",
                      value: "TransactionID",
                    }}
                    apiResult={rateList}
                    storeApiResult={(val) => updateState(val, "rateList")}
                  />
                </div>
                {/* {AdjustedDefaultRatingList && (
                  <div>
                    <Dropdown
                      label={"Default Rating"}
                      menuItems={AdjustedDefaultRatingList}
                      value={AdjustedDefaultRating}
                      onChange={(val) =>
                        updateState(val, "AdjustedDefaultRating")
                      }
                    />
                  </div>
                )} */}
              </div>
            )}
            <br />
            <div className="Flex-row">
              <div>
                <Input
                  label="Rounding"
                  value={AdjustedRounding}
                  onChange={(val) => updateState(val, "AdjustedRounding")}
                  type="number"
                />
              </div>
              <div>
                <Input
                  label="Minimum Period"
                  value={AdjustedMinimumPeriod}
                  onChange={(val) => updateState(val, "AdjustedMinimumPeriod")}
                  type="number"
                />
              </div>
            </div>
            <div>
              <Dropdown
                menuItems={scoreDisplayOptions}
                label="Adjusted Plan Score Display"
                value={AdjustedPlanScoreDisplay}
                onChange={(val) => updateState(val, "AdjustedPlanScoreDisplay")}
              />
            </div>
            <div className="Flex">
              <div>
                <EwSwitch
                  label={"Final Score"}
                  value={AdjustedPlanFinalScore === "E"}
                  onChange={(flag) =>
                    updateState(flag ? "E" : "D", "AdjustedPlanFinalScore")
                  }
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  }
  function renderAssessorScore() {
    let raterOptions = [
      { label: "Appraiser", value: "A" },
      { label: "Reviewer", value: "R" },
      {
        label: "Rating given by Appraiser but can be changed by Reviewer",
        value: "B",
      },
    ];
    return (
      <>
        <Typography variant="h3">Assessor Score</Typography>
        <hr />
        <br />
        <div className="Flex">
          <EwSwitch
            label={"Assessor Plan Score"}
            value={AssessorPlanScore === "E"}
            onChange={(flag) =>
              updateState(flag ? "E" : "D", "AssessorPlanScore")
            }
          />
        </div>
        <br />
        {AssessorPlanScore === "E" && (
          <>
            <div className="Flex">
              <div style={{ width: "100%" }}>
                <Dropdown
                  label={"Assessor Plan Score Rating Scale"}
                  featureId={RatingScaleID}
                  value={AssessorPlanRatingScaleId}
                  onChange={(val) =>
                    updateState(val, "AssessorPlanRatingScaleId")
                  }
                  mapper={{
                    label: "RatingScaleName",
                    value: "TransactionID",
                  }}
                  apiResult={rateList}
                  storeApiResult={(val) => updateState(val, "rateList")}
                />
              </div>
              <br />
            </div>
            <div className="Flex-row g20">
              <div style={{ width: "50%" }}>
                <Dropdown
                  menuItems={raterOptions}
                  label="Rater"
                  value={AssessorPlanRater}
                  onChange={(val) => updateState(val, "AssessorPlanRater")}
                />
              </div>
              <div>
                <EwSwitch
                  label={"Final Score"}
                  value={AssessorPlanFinalScore === "E"}
                  onChange={(flag) =>
                    updateState(flag ? "E" : "D", "AssessorPlanFinalScore")
                  }
                />
              </div>
            </div>
          </>
        )}
        <br />
      </>
    );
  }
  function renderModeratePlanScore() {
    return (
      <>
        <Typography variant="h3">Moderate Score</Typography>
        <hr />
        <br />
        <div className="Flex">
          <EwSwitch
            label={"Moderate Plan Score"}
            value={ModeratedPlanScore === "E"}
            onChange={(flag) =>
              updateState(flag ? "E" : "D", "ModeratedPlanScore")
            }
          />
        </div>
        {ModeratedPlanScore === "E" && (
          <>
            <br />
            <div className="Flex-row g20">
              <div>
                <Input
                  label="Moderated Score Label"
                  value={ModeratedPlanScoreLabel}
                  onChange={(val) =>
                    updateState(val, "ModeratedPlanScoreLabel")
                  }
                />
              </div>

              <div style={{ width: "50%" }}>
                <Dropdown
                  label={"Moderated Score Rating Scale"}
                  featureId={RatingScaleID}
                  value={ModeratedPlanScoreRatingId}
                  onChange={(val) =>
                    updateState(val, "ModeratedPlanScoreRatingId")
                  }
                  mapper={{
                    label: "RatingScaleName",
                    value: "TransactionID",
                  }}
                  apiResult={rateList}
                  storeApiResult={(val) => updateState(val, "rateList")}
                />
              </div>
              <br />
            </div>
            <div className="Flex">
              <div>
                <EwSwitch
                  label={"Moderated Score is uploaded"}
                  value={ModeratedPlanScoreUploaded === "E"}
                  onChange={(flag) =>
                    updateState(flag ? "E" : "D", "ModeratedPlanScoreUploaded")
                  }
                />
              </div>
              <div>
                <EwSwitch
                  label={"Final Score"}
                  value={ModeratedPlanScoreFinalScore === "E"}
                  onChange={(flag) =>
                    updateState(
                      flag ? "E" : "D",
                      "ModeratedPlanScoreFinalScore"
                    )
                  }
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  }
  return (
    <>
      {renderBsicInfo()}
      {renderApplicability()}
      {renderParticipation()}
      {renderAggregateScore()}
      {renderAdjustedScore()}
      {renderAssessorScore()}
      {renderModeratePlanScore()}
    </>
  );
}

export default General;
