import React, { useState, useEffect } from "react";

// import Address from "../MComponent/Address/Address";
// import Mobile from "../MComponent/Mobile/Mobile";
// import { Button, Modal, Box } from "@mui/material";
// import { useSelector, useDispatch } from "react-redux";
// import medical from "../Testjson/Organization/Medical.json";
// import LayoutRenderer from "../Interpreter/LayoutRenderer";
// import Applicability from "../MComponent/Applicability/Applicability4";
// import ReactFlow from "react-flow-renderer";
// import Flow from "./Flow";
// import Editor2 from "./Editor3";
// import Workflow from "../NComponent/Workflow/workflow";
// import Card_5 from "../CComponent/Cards/Card_5";
// import Image from "../AComponent/Image/Image_1";
// import IFSC from "../MComponent/Bank/IFSC";
// import DimensionSection from "../NComponent/pms/MPP/DimensionSection";

function Test() {
  return (
    <>
      {/* <EmployeeInfo /> */}
      {/* <PlanInit /> */}
      {/* <Assesment /> */}
    </>
  );
}

export default Test;
