import React,{useState,useEffect} from "react";
//import Data from "../Testjson/NeedToAct.json";
import Data from "../Testjson/abc.json"
import JsonInterpreter from "../Interpreter/JsonInterpreter";

function NeedToAct(){
console.log("From Need To Act ",Data.Components)
    return(
        <div style={{position:'relative'}}>
       <JsonInterpreter Components={Data.Components} />
        </div>
    )
}


export default NeedToAct;