export const Actions = {
  PAGE_STATE: "PAGE_STATE",
  RESET: "RESET",
  SET: "SET",
  MODAL_UNSET: "MODAL_UNSET",
  SET_DATA: "SET_DATA",
  UNSET_DATA: "UNSET_DATA",
  SET_FIELD_DATA: "SET_FIELD_DATA",
  UPDATE_DATA: "UPDATE_DATA",
  SET_FORM_DATA: "SET_FORM_DATA",
  SET_SHELL_LOADING: "SET_SHELL_LOADING",
  UPDATE_PROPS: "UPDATE_PROPS",
  UPDATE_ALL_FIELDS: "UPDATE_ALL_FIELDS",
  STORE_PAYLOAD: "STORE_PAYLOAD",
  UPDATE_PAYLOAD: "UPDATE_PAYLOAD",
  EDIT_MODE: "EDIT_MODE",
  REMOVE_SHELL_DATA: "REMOVE_SHELL_DATA",
  ADD_AUTH_TOKEN: "ADD_AUTH_TOKEN",
  ADD_TENANT_CODE: "ADD_TENANT_CODE",
  ADD_USER_DETAILS: "ADD_USER_DETAILS",
  ADD_OTHER_TOKEN_DETAILS: "ADD_OTHER_TOKEN_DETAILS",
  SET_MESSAGE: "SET_MESSAGE",
  UNSET_MESSAGE: "UNSET_MESSAGE",
  SET_2_FACTOR: "SET_2_FACTOR",
  UNSET_2_FACTOR: "UNSET_2_FACTOR",
  VERIFIED_2_FACTOR: "VERIFIED_2_FACTOR",
  ENABLED_GROUPS: "ENABLED_GROUPS",
  ADD_GROUP: "ADD_GROUP",
  ADD_GROUPS: "ADD_GROUPS",
  ADD_ENABLED_GROUPS:"ADD_ENABLED_GROUPS",
  REMOVE_GROUP: "REMOVE_GROUP",
  REMOVE_ALL_GROUP: "REMOVE_ALL_GROUP",
  GROUP_REFERNECE: "GROUP_REFERNECE",
  SET_FORM: "SET_FORM",
  ADD_WORKFLOW: "ADD_WORKFLOW",
  ADD_SIDE_COMPONENT:"ADD_SIDE_COMPONENT",
  ADD_AUTH_DATA:"ADD_AUTH_DATA"
};
