import React from 'react'

const initialProps = {
  url: null,
  size: {
    height:null,
    width:null,
  },
  type: null,
}

function Image(props = initialProps) {
  var { url, size, type } = props
  if (!size) {
    size = {
      height: 70,
      width: 70,
    }
  }
  if (type && type === 1) {
    size.borderRadius = '50%'
  }
  return (
    <>
      <img src={url} style={{ ...size,backgroundSize:'cover' }} />
    </>
  )
}

export default Image
