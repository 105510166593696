import {
  Box,
  IconButton,
  Paper,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Handle, MarkerType, Position, updateEdge } from "reactflow";
import useReduxStore from "../../../Utils/CustomHooks/ReduxUpdater";
import { rgbaToHex } from "../helpers";

function ScenarioNode({ data, isConnectable }) {
  const { StepID, ScenarioID, styles } = data;
  const [state, updateState] = useReduxStore({ id: "Workflow2" });

  console.log(styles.color, "STYLES");

  const steps = state.data.steps;

  const ScenarioData = steps[StepID].StepScenarios?.find(
    (s) => s.ScenarioID === ScenarioID
  );
  const { ScenarioName } = ScenarioData || {};

  return (
    <Paper
      elevation={2}
      sx={{
        border: `2px solid ${alpha(styles.color, 0.5)}`,
        borderRadius: "12px",
        boxSizing: "border-box",
        position: "relative",
        height: "4em",
        minWidth: "7em",
      }}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        style={{ opacity: 0, pointerEvents: "none", left: "10px" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
          justifyContent: "center",
          justifyItems: "center",
          height: "100%",
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: styles.color }}
          fontWeight={"600"}
        >
          {ScenarioName}
        </Typography>
      </Box>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
        style={{ opacity: 0, right: "5px", pointerEvents: "none" }}
      />
    </Paper>
  );
}

export default ScenarioNode;
