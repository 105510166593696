import React from 'react';
import { MenuItem } from "@mui/material";
import option from "../Testjson/Header/CP.json";
//1import { useKeycloak } from '@react-keycloak/web'

export default function Preference(props) {
  //1const {keycloak}=useKeycloak();
 // console.log("Pref: ",keycloak);
 // keycloak.updateToken().then((res)=>{console.log("Key: 1",res)}).catch((e)=>console.log("Key: 2",e))
  const parentfunc = props.onClick;
  var updateditem = props.Title;
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (id) => {
    if(id==2)
    {
      setOpen(true);
    }
    else if(id==3)
    {
      //logout code;
   //1 keycloak.logout();
    }
    parentfunc();
  };

  const handleClose = () => {
    setOpen(false);
  };
  updateditem = updateditem.replace(/\s+/g, '').toLowerCase();
  const type = option.type;
  const Component = type !== undefined ? require("./" +type ).default : null;
  return (
    <div>
      <MenuItem name ={updateditem} onClick = {() => handleClickOpen(props.Id)}>{props.Title}</MenuItem>
      {open && <Component open = {open} onClose = {handleClose} {...option} />}
    </div>
  );
}
