import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import * as Validators from '../../Utils/Validators/library'

import LayoutRenderer from '../../Interpreter/LayoutRenderer'

function Shell(props) {
  const { payload } = props
  const Data = JSON.parse(JSON.stringify(props))
  //const [Data,setData]=useState(JSON.parse(JSON.stringify(props)));
  function clearData() {
    console.log("Clear data called")
    dispatch({
      type:'REMOVE_SHELL_DATA',
      payload:{
        id:componentInstanceId
      }
    })
  }
  useEffect(() => {
    return ()=>{clearData()}
  },[])
  Data.type = 'layout'

  const [componentInstanceId] = useState(uuid())
 
  const dispatch = useDispatch()
  console.log('From Shell_2 '," ",componentInstanceId)
  if (Data.payload) {
    dispatch({
      type: 'STORE_PAYLOAD',
      payload: {
        id: componentInstanceId,
        submitPayload: Data.payload,
      },
    })
  }
  if(Data.enabledGroups?.length>0){
    dispatch({
      type:'ENABLED_GROUPS',
      payload:{
        id:componentInstanceId,
        data:Data.enabledGroups
      }
    })
  }
  function DependencyHandler({ ele }) {
    console.log('Form dep: ', ele)
    const state = useSelector((state) => state.DataReducer[componentInstanceId])

    const dispatch = useDispatch()

    let params = []
    for (let i = 0; i < ele.condition.params.length; i++) {
      let par = ele.condition.params[i]
      let splitValue = par.split('.')

      if (splitValue.length == 2) {
        console.log('New dependency: ', splitValue, ' ', state)

        params.push(state[splitValue[0]][splitValue[1]])
      } else params.push(par)
    }

    let decision = Validators[ele.condition.method](...params, state, payload)
    if (ele.ifTrue) {
      decision = decision ? ele.ifTrue : ele.ifFalse
    }

    console.log('New dependency: 1', decision, ' ', params, ' ', state)
    if (
      JSON.stringify(state[ele.component][ele.onProps]) !==
      JSON.stringify(decision)
    ) {
      dispatch({
        type: 'UPDATE_PROPS',
        payload: {
          id: componentInstanceId,
          componentNumber: ele.component,
          props: ele.onProps,
          data: decision,
        },
      })
    }

    return <></>
  }

  return (
    <>
      <Paper style={{ padding: 100 }}>
        <LayoutRenderer Shell={{ id: componentInstanceId }} Data={Data} />
      </Paper>
      {props.fieldDependency?.map((ele) => (
        <DependencyHandler ele={ele} />
      ))}
    </>
  )
}

export default Shell
