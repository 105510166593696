import React, { useState, useEffect } from "react";
import useReduxStore from "../../Utils/CustomHooks/ReduxUpdater";
import Molecule from "../../MComponent/Molecule";
import Atomic from "../../AComponent/Atomic";
import DateRangeIcon from "@mui/icons-material/DateRange";
import useWindowDimensions from "../../Utils/CustomHooks/WindowRezise";
import { Typography } from "@mui/material";
import {
  submitPayload,
  handleResult,
  getData,
} from "../../Utils/Common/Library";
import AlertMessage from "../../Utils/Messages/AlertMessage";
import store from "../../Redux/Store";
import { Actions } from "../../Redux/Actions/Actiontypes";

const MasterPlanFeatureID = "19149";
const FeatureID = "19154";
const ParamterMapFeatureId = "19147";
const { EwModal } = Molecule;
const { Dropdown, Button } = Atomic;

function getDateInString(date) {
  // date = date.split("-");
  // date = new Date("2023", date[1], date[0]);
  date = new Date(+date);
  return `${date.toLocaleString("default", {
    month: "long",
  })} - ${date.getDate()}`;
}
function convertToEpoch(year, date) {
  date = new Date(+date);
  let month = date.getMonth();
  let day = date.getDate();
  return "" + new Date(year, month, day).getTime();
}
function getYear(year, date1, date2) {
  // date1 = parseInt(date1.split("-")[1]);
  // date2 = parseInt(date2.split("-")[1]);
  date1 = new Date(+date1).getMonth();
  date2 = new Date(+date2).getMonth();
  console.log("2 dates: ", date1, " ", date2);
  if (date2 < date1) {
    return +year + 1;
  } else return year;
}
function getPlanCycle(year1, year2) {
  if (year1 == year2) return year1;
  return year1 + "-" + ("" + year2).split("").splice(2, 4).join("");
}
function PlanInit({ onClose }) {
  const [data, updateState] = useReduxStore({ id: "InitPlan" });
  const { width, height } = useWindowDimensions();
  const [modal, setModal] = useState(true);
  const [existingMPP, setExistingMpp] = useState({});
  const [mapExist, setMapExist] = useState(false);
  const [error, setError] = useState(null);
  const [pageError, setPageError] = useState(null);
  const currentYear = +new Date().getFullYear();
  const yearsToDisplay = [
    currentYear - 2,
    currentYear - 1,
    currentYear,
    currentYear + 1,
  ];

  useEffect(() => {
    getData({ featureId: ParamterMapFeatureId })
      .then((res) => {
        if (res && res.Data && res.Data.length > 0) {
          setMapExist(true);
          getData({ featureId: FeatureID }).then((res) => {
            let obj = existingMPP;
            res?.Data?.forEach(({ PlanCycleStartDate, MPPID }) => {
              let year = new Date(+PlanCycleStartDate).getFullYear();
              if (obj[MPPID]) {
                obj[MPPID].push(year);
              } else {
                obj[MPPID] = [year];
              }
            });
            setExistingMpp(obj);
          });
        } else {
          setMapExist(false);
          setPageError({
            type: 1,
            message: "No Paramter Map Defined.",
          });
        }
      })
      .catch((e) => {
        setMapExist(false);
        setPageError({
          type: 1,
          message: "Network Error",
        });
      });
  }, []);
  const {
    PlanCycleEndDate,
    PlanCycleStartDate,
    MPPID,
    PlanCycle,
    PlanLaunchDateAndTime,
    selectedDoc,
    year,
  } = data;
  let { PlanName, PlanStart, PlanEnd } = selectedDoc || {};
  function onSave() {
    let payload = {
      PlanCycleStartDate: convertToEpoch(year, PlanStart),
      PlanCycleEndDate: convertToEpoch(
        +getYear(year, PlanStart, PlanEnd),
        PlanEnd
      ),
      PlanCycle: "" + getPlanCycle(year, getYear(year, PlanStart, PlanEnd)),
      PlanLaunchDateAndTime: "",
      MPPID,
    };
    const userID = store.getState().AuthReducer.userID;
    submitPayload({
      payload,
      featureId: FeatureID,
      headerSection: {
        SubjectUserID: "",
        TransactionUserID: userID,
      },
    })
      .then((res) => {
        if (handleResult(res) === 1) {
          setError("Success!");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function removeData() {
    store.dispatch({
      type: Actions.REMOVE_SHELL_DATA,
      payload: {
        id: "InitPlan",
      },
    });
  }
  return (
    <div>
      <EwModal
        open={modal}
        onClose={() => {
          setModal(false);
          onClose();
          removeData();
        }}
      >
        <>
          {pageError && (
            <AlertMessage {...pageError} onClose={() => setPageError(null)} />
          )}
          {mapExist && (
            <div style={{ minWidth: 0.7 * width, minHeight: 0.8 * height }}>
              <Typography variant="h3">Start A Plan Cycle</Typography>
              <hr />
              <div style={{ marginTop: "5%", width: "100%" }}>
                <div className="Flex up" style={{ width: "100%" }}>
                  <div style={{ width: "50%" }}>
                    <Dropdown
                      label={"Master Plans"}
                      featureId={MasterPlanFeatureID}
                      mapper={{
                        label: "PlanName",
                        value: "TransactionID",
                      }}
                      value={MPPID}
                      onChange={(val, doc) => {
                        updateState(val, "MPPID");
                        updateState(doc, "selectedDoc");
                        updateState(null, "year");
                      }}
                      fields={[
                        "Header.*",
                        "Data.PlanName",
                        "Data.PlanStart",
                        "Data.PlanEnd",
                      ]}
                    />
                  </div>
                  <br />
                  {selectedDoc && (
                    <>
                      <div
                        className="Flex-row g20 start"
                        style={{ width: "40%", justifyContent: "flex-start" }}
                      >
                        <DateRangeIcon />
                        <div>
                          <Typography variant="h6">
                            {getDateInString(PlanStart)}
                          </Typography>
                        </div>
                        <div style={{ width: "50%" }}>
                          <Dropdown
                            label={"Year"}
                            menuItems={yearsToDisplay}
                            value={year}
                            onChange={(val) => updateState(val, "year")}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <br />
                  {year && (
                    <>
                      <div className="Flex-row g20 start">
                        <DateRangeIcon />
                        <Typography variant="h6">
                          {getDateInString(PlanEnd) +
                            ", " +
                            getYear(year, PlanStart, PlanEnd)}
                        </Typography>
                      </div>
                      <br />
                      {error && (
                        <div className="Flex">
                          <AlertMessage
                            message={error}
                            onClose={() => setError(null)}
                          />
                        </div>
                      )}
                      <div className="Flex">
                        {console.log("Beofer stop: ", existingMPP)}
                        {existingMPP[MPPID]?.includes(+year) ? (
                          <Button
                            title={"Stop Cycle"}
                            color={"error"}
                            onClick={() => {}}
                          />
                        ) : (
                          <Button title={"Start Cycle"} onClick={onSave} />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      </EwModal>
    </div>
  );
}

export default PlanInit;
