import axios from 'axios'


var apiEndpoint = 'https://o8kqr69ehe.execute-api.ap-south-1.amazonaws.com/v1'

export const genrateOTP = (phoneNumber, cb) => {
  let parameter = {
    mobileNo: phoneNumber,
    templateId: '5fd324bc548a85198b71b647',
    name: 'John',
  }

  console.log(parameter)
  axios.post(apiEndpoint + '/gen-otp', parameter).then((result) => {
    let data = result.data

    cb(data)
  })
}

export const verifyOtp = (otp, phoneNumber, cb) => {
  let parameter = {
    otpNo: otp,
    mobileNo: phoneNumber,
  }
  console.log(parameter)
  axios.post(apiEndpoint + '/verify-otp', parameter).then((result) => {
    let data = result.data
    console.log("From Verify: ",data);
    cb(data)
  })
}
