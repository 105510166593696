import { Box, Slider } from "@mui/material";
import React from "react";

const ContinuousScaleSlider = ({ step, min, max }) => {
  function valuetext(value) {
    return `${value}`;
  }

  return (
    <Box>
      <Slider
        aria-label="Scale values"
        defaultValue={min}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        step={step}
        min={min}
        max={max}
      />
    </Box>
  );
};

export default ContinuousScaleSlider;
