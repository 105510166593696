export class WorkflowProcesser {
  constructor(workflow) {
    // if (!workflow) {
    //   throw new Error("Workflow object not passed");
    // }
    // if (!workflow.WorkflowSteps) {
    //   throw new Error("Incorrect Workflow object passed to processer");
    // }
    console.log();
    this.workflow = workflow;
  }

  getWorkflowStatus() {
    return this.workflow?.WorkflowStatus;
  }

  getAllSteps() {
    //console.log("Workflow is: ",this.workflow)
    return this.workflow?.WorkflowSteps?.length;
  }

  getCurrentStep() {
    console.log("Step is: workflow", this.workflow?.WorkflowSteps);
    return this.workflow?.WorkflowSteps.findIndex(
      (item) => item.StepType === "Pending"
    );
  }

  getCurrentStepData() {
    return this.workflow?.WorkflowSteps?.find(
      (item) => item.StepType === "Pending"
    );
  }

  getCurrentStepUserID() {
    const currentStep = this.getCurrentStep();
    console.log("Step is: workflow", currentStep);
    if (currentStep === -1) return null;
    return this.workflow?.WorkflowSteps[currentStep].StepOwnerUserIDs[0];
  }

  getCurrentStepID() {
    const currentStep = this.getCurrentStep();
    if (currentStep === -1) return null;
    return this.workflow?.WorkflowSteps[currentStep].StepID;
  }

  getStepByStepUserID(UserID) {
    const WorkflowSteps = [...this.workflow?.WorkflowSteps];
    const Step = WorkflowSteps.findIndex(
      (step) => step.StepOwnerUserIDs[0] === UserID
    );
    return Step + 1;
  }
  getStepByIndex(index) {
    if (index === -1) return null;
    return this.workflow?.WorkflowSteps[index];
  }

  getStepOfLoggedInUser(userId) {
    console.log("Inside: getStepOfLoggedInUser ", this.workflow);
    return this.workflow?.WorkflowSteps.findIndex(
      (item, index) => item.StepOwnerUserIDs?.includes(userId) && index !== 0
    );
  }
}
