import React from "react";


function EWText({size,Value}){

    return (
        <>
    <span style={{fontSize:size}}>{Value}</span>
        </>
    )
}

export default EWText;