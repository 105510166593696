import { Box, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import Atomic from "../../../../AComponent/Atomic";
import {
  getGroupLibrary,
  getParameterLibrary,
  getTableHeading,
  isDisabled,
  isLibrary,
  isPermitted,
  isRestricted,
  isUserDefined,
} from "../Helpers/helper";
import EwTable from "../../../../MComponent/Table/Table";

const { Input, Button, Dropdown, Checkboxes } = Atomic;

export const SelectGroup = ({ onGroupChecked, onGroupUnChecked, id }) => {
  const [checked, setChecked] = useState(false);
  const onChangeHandler = (value) => {
    setChecked(value);
    if (value) {
      console.log(id);
      onGroupChecked(id);
    } else {
      onGroupUnChecked(id);
    }
  };
  return <Checkboxes onChange={onChangeHandler} value={checked} />;
};

const AddGroup = ({ type, rule, targetRules, closeForm }) => {
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [description, setDescription] = useState("");
  const [uom, setUom] = useState("");
  const [libItem, setLibItem] = useState(-1);

  const TargetDataTypes = [...targetRules?.TargetDataTypes];

  const [targetState, setTargetState] = useState({
    TargetsEnabled: targetRules?.TargetsEnabled,
    TargetMapping: targetRules?.TargetMapping,
    TargetType: targetRules?.TargetType,
    TargetDataType: "",
    AchievementDataType: "",
  });

  const formType = type;
  const nameType =
    formType === "g2" ? "Group 2" : formType === "p" ? "Parameter" : "Group 1";
  const ruleType = rule;
  const libraryItems =
    type === "g1"
      ? getGroupLibrary(1)
      : type === "g2"
      ? getGroupLibrary(2)
      : getParameterLibrary();

  const nameChangeHandler = (value) => setName(value);
  const shortNameChangeHandler = (value) => setShortName(value);
  const descriptionChangeHandler = (value) => setDescription(value);
  const uomChangeHandler = (value) => setUom(value);
  const formSubmitHandler = () => {
    const updatedData = {
      name,
      shortName,
      description,
    };

    console.log(updatedData);

    closeForm();
  };
  const libraryItemChangeHandler = (value) => {
    setLibItem(value);
  };

  const [selected, setSelected] = useState([]);

  const onGroupChecked = (value) => {
    setSelected((prevState) => {
      let newState = [...prevState];
      newState.push(value);
      return newState;
    });
  };

  const onGroupUnChecked = (value) => {
    setSelected((prevState) => {
      let newState = [...prevState];
      return newState.filter((id) => id !== value);
    });
  };

  console.log(ruleType, "pms");

  console.log(TargetDataTypes, "ADD GROUP");

  const dataTypes = {
    ST: { label: "Alpha Numeric Text (200 chars) ", value: "ST" },
    LT: { label: "Alpha Numeric Large Text", value: "LT" },
    NI: { label: "Numeric", value: "NI" },
    ND: { label: "Numeric (Decimal)", value: "ND" },
    PI: { label: "Percentage", value: "PI" },
    PD: { label: "Percentage (Decimal)", value: "PD" },
  };

  return (
    <Box
      sx={{
        height: "50vh",
        width: "40vw",
        padding: "0.5em 1em",
      }}
      className="scroll"
    >
      <Box sx={{ width: "100%" }}>
        {isLibrary(ruleType) && (
          <Box sx={{ width: "100%" }}>
            <Typography variant="body1" sx={{ margin: "1em 0" }}>
              Add from Library
            </Typography>
            {/* <Dropdown
              menuItems={libraryItems}
              label={"Type"}
              onChange={libraryItemChangeHandler}
              value={libItem}
            /> */}
            <EwTable
              heading={getTableHeading(type)}
              rows={[
                ...libraryItems.map((item) => [
                  {
                    Component: SelectGroup,
                    props: { onGroupChecked, onGroupUnChecked, id: item.value },
                  },
                  item.label,
                ]),
              ]}
            />
          </Box>
        )}
        {isUserDefined(ruleType) && (
          <Box sx={{ width: "100%" }}>
            <Typography variant="h3" sx={{ margin: "1em 0" }}>
              Create new {nameType}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ flex: 1, marginRight: "0.5em" }}>
                {" "}
                <Input
                  type={"text"}
                  label={`${nameType} Name`}
                  onChange={nameChangeHandler}
                  value={name}
                  styles={{ width: "100%", margin: "1em 0" }}
                />
              </Box>
              <Box sx={{ flex: 1, marginRight: "0.5em" }}>
                <Input
                  type={"text"}
                  label={`${nameType} Short Name`}
                  onChange={shortNameChangeHandler}
                  value={shortName}
                  styles={{ width: "100%", margin: "1em 0" }}
                />
              </Box>
            </Box>
            <Input
              type={"text"}
              label={`${nameType} Description`}
              onChange={descriptionChangeHandler}
              value={description}
              styles={{ width: "100%", margin: "1em 0" }}
              rows={5}
            />
            {formType === "p" && (
              <Input
                type={"text"}
                label={`${nameType} UOM`}
                onChange={uomChangeHandler}
                value={uom}
                styles={{ width: "100%", margin: "1em 0" }}
              />
            )}
            <Box marginTop={"2em"}>
              <Typography variant="h3">Targets Settings</Typography>
              <Divider sx={{ margin: "1em auto" }} />
              {isDisabled(targetState.TargetsEnabled) ? (
                <div className="Flex">
                  <Typography variant="body1">
                    You don't have permission to configure target settings
                  </Typography>
                </div>
              ) : (
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "3em" }}
                >
                  <Box>
                    <Typography variant="h4">Target Type</Typography>
                    <Typography variant="overline">
                      {targetState.TargetType === "FV"
                        ? "Fixed Value"
                        : "Incompatiable target type found"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h4" sx={{ mb: "1em" }}>
                      Target Data Type
                    </Typography>
                    {TargetDataTypes.length === 0 ? (
                      <Typography variant="overline">
                        No Target Data Types enabled
                      </Typography>
                    ) : (
                      <Dropdown
                        label={"Target Data Types"}
                        menuItems={[
                          ...TargetDataTypes.map((dt) => {
                            console.log(
                              dt,
                              "ADD GROUP DATA TYPE",
                              dataTypes[dt]
                            );
                            return dataTypes[dt];
                          }),
                        ]}
                        onChange={(value) => {
                          setTargetState((prevState) => ({
                            ...prevState,
                            TargetDataType: value,
                            AchievementDataType: value,
                          }));
                        }}
                      />
                    )}
                  </Box>
                  <Box>
                    <Typography variant="h4">Achievement Data Type</Typography>
                    <Typography variant="overline">
                      {targetState.AchievementDataType
                        ? dataTypes[targetState.AchievementDataType].label
                        : "Select Target Data Type"}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
        <Button
          onClick={formSubmitHandler}
          title={"Submit"}
          variant={"contained"}
          style={{ marginTop: "2em", float: "right" }}
        />
      </Box>
    </Box>
  );
};

export default AddGroup;
