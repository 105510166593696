import { Avatar, Box, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LocationIcon } from "./Utils/Common/Icons";
import { complexQuery } from "./Utils/Common/Library";
import { constants } from "./NComponent/pms/Dimension/Data/QueryConstants";

function UserCard({ role, UserID }) {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (UserID) {
      complexQuery({
        QueryName: constants.FindUserAndItsOrganisationDetails,
        RequiredFields: [{ UserID }],
      }).then((res) => {
        setData(res[0]);
      });
    }
  }, []);

  return !data ? (
    <>Loading user ...</>
  ) : (
    <Card
      sx={{
        position: "relative",
        overflow: "visible",
        boxShadow: "0px 0px 3px #B1AEBB",
        borderRadius: "12px",
      }}
    >
      <Box
        position={"absolute"}
        sx={{ top: "-7.5px", backgroundColor: "white", display: "flex" }}
      >
        <Typography variant="caption" fontWeight={400} color={"primary"}>
          {role}
        </Typography>
      </Box>
      <Box display={"flex"} gap={"1em"} alignItems={"flex-start"}>
        <Box>
          <Avatar
            src="https://www.pngarts.com/files/10/Default-Profile-Picture-Transparent-Image.png"
            sx={{ height: "2.5em", width: "2.5em" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.3em",
            flex: 1,
          }}
        >
          <Typography variant="tagline">{data.FullName || ""}</Typography>
          <Typography variant="tagline">{data.RoleName || ""}</Typography>
          <Typography variant="tagline">
            {data.DesignationName || ""}
          </Typography>
          <Box display={"flex"} gap={"0.2em"}>
            <LocationIcon />{" "}
            <Typography variant="tagline" fontWeight={400} color={"#6A6A6A"}>
              {data.LocationName || ""}
            </Typography>
          </Box>
        </Box>
        <Box display={"flex"}>
          <Typography variant="tagline" fontWeight={400}>
            {data.EmployeeCode || ""}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default UserCard;
