import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Atomic from "../../../../AComponent/Atomic";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const { Input } = Atomic;

function NumericInput({ value, onChange, onBlur }) {
  const [num, setNum] = useState(value);

  useEffect(() => {
    setNum(value);
  }, [value]);

  return (
    <Box display={"flex"} alignItems={"center"} mb={"-8px"}>
      <Input
        sx={{ width: "4em", margin: 0 }}
        variant="standard"
        onChange={(val) => {
          onChange && onChange(+val);
          setNum(val);
        }}
        onBlur={onBlur}
        type={"number"}
        value={num}
        placeholder="Value"
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <IconButton
          size="small"
          sx={{ padding: 0 }}
          onClick={(e) => {
            e.preventDefault();
            setNum((prevState) => {
              if (prevState) {
                onChange && onChange(0);
                return +prevState + 1;
              } else {
                onChange && onChange(1);
                return 1;
              }
            });
          }}
        >
          <ArrowDropUp fontSize="small" />
        </IconButton>
        <IconButton
          sx={{ padding: 0 }}
          size="small"
          onClick={(e) => {
            e.preventDefault();
            setNum((prevState) => {
              if (prevState && prevState > 1) {
                onChange && onChange(+prevState - 1);
                return +prevState - 1;
              } else {
                onChange && onChange(0);
                return 0;
              }
            });
          }}
        >
          <ArrowDropDown fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
}

export default NumericInput;
