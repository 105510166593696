import React from "react";





function ModalRenderer(){
    return(
        <>
        </>
    )
}


export default ModalRenderer;