import { Box } from "@mui/material";
import React from "react";
import OverallDashboard from "./OverallDashboard";
import Molecule from "../../MComponent/Molecule";
import DimensionDashboard from "./DimensionDashboard";

const { Tabs } = Molecule;

function PlanSummaryReportDashboard({ queryResults }) {
  const componentList = [
    {
      label: "Goals",
      Component: DimensionDashboard,
      props: { queryResults, dimension: "GOALS" },
    },
    {
      label: "Core Values",
      Component: DimensionDashboard,
      props: { queryResults, dimension: "CORE_VALUES" },
    },
    {
      label: "Overall",
      Component: OverallDashboard,
      props: { queryResults },
    },
  ];

  return (
    <Box>
      <Tabs componentList={componentList} />
    </Box>
  );
}

export default PlanSummaryReportDashboard;
