import { Box, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import UserCard from "./UserCard";
import Dashboard from "./NComponent/pms/Dashboard";
import PeriodScoreDistribution from "./NComponent/pms/PeriodScoreDistribution";
import EwModal from "./MComponent/Modal/Modal";
import { getDateFromEpoch } from "./Utils/Common/Library";
const PlanStageMap = {
  G: "Goal Settings",
  A: "Assessment",
  H: "Plan Ended",
};

function SidePanel({
  ViewName,
  PlanStartDate,
  PlanEndDate,
  PlanStage,
  WorkflowUsers,
  ScoreDistributionProps,
}) {
  console.log(ScoreDistributionProps, "ScoreDistributionProps");
  const [modal, setModal] = useState(null);
  console.log(WorkflowUsers, "WORKFLOW USERS");
  if (!ViewName) ViewName = "Unnamed View";
  if (!WorkflowUsers) WorkflowUsers = [];
  if (!ScoreDistributionProps)
    ScoreDistributionProps = {
      DimensionID: "",
      UserID: "",
      Visibility: false,
    };

  const { DimensionID, UserID, Visibility } = ScoreDistributionProps;

  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  function convertDateToString(initDate) {
    if (!initDate) return "Not Defined";
    return getDateFromEpoch({ epoch: initDate, returnNthString: true });
    let date = new Date(parseInt(initDate));
    return `${date.getDate()}${nth(date.getDate())} ${date.toLocaleString(
      "default",
      {
        month: "long",
      }
    )} ${date.toLocaleString("default", { year: "numeric" })}`;
  }
  function format(val) {
    return parseFloat(val).toFixed(4);
  }
  return (
    <Box className="scroll" style={{ height: "85vh" }}>
      <Box marginLeft={"0.7em"}>
        <Box>
          <Typography variant="small_text">You are viewing</Typography>
          <Typography variant="h2">{ViewName}</Typography>
        </Box>
        <Box
          margin={"1.5em 0"}
          display={"flex"}
          flexDirection={"column"}
          gap={"0.7em"}
        >
          <Box display={"flex"} gap={"0.5em"}>
            <Typography variant="body2">
              {convertDateToString(PlanStartDate)}
            </Typography>{" "}
            <Typography variant="subtitle2">to</Typography>{" "}
            <Typography variant="body2">
              {convertDateToString(PlanEndDate)}
            </Typography>
          </Box>
          {PlanStage && (
            <Box display={"flex"} gap={"0.7em"}>
              <Typography variant="subtitle2">Plan Stage - </Typography>
              <Typography variant="body2">{PlanStageMap[PlanStage]}</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        maxHeight={"45vh"}
        className="invisble-scroll"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          padding: "1em",
          margin: "2em 0",
        }}
      >
        {WorkflowUsers.map((user) => (
          <UserCard role={user.label} UserID={user.value} key={user.value} />
        ))}
      </Box>
      {Visibility && (
        <Box marginLeft={"1em"}>
          <Link
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setModal({
                Component: PeriodScoreDistribution,
                props: {
                  DimensionID,
                  UserID,
                },
              });
            }}
          >
            View Score Distribution
          </Link>
        </Box>
      )}
      {modal && (
        <EwModal
          onClose={() => {
            setModal(null);
          }}
          open={modal}
        >
          <modal.Component {...modal.props} />
        </EwModal>
      )}
    </Box>
  );
}

export default SidePanel;
