import React from "react";
//import { ChatEngine } from 'react-chat-engine'
{
  /* <ChatEngine
			height='100vh'
			userName='admin'
			userSecret='admin123'
			projectID='afbc3d7b-2f2e-4b6a-9e65-7aaf8c83fd6d'
		/> */
}

function Chat() {
  return <>Chat</>;
}

export default Chat;
