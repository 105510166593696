import React, { useState, useEffect } from 'react'
//import Button from '@mui/material/Button';
import MultiSelect from './MultiSelect'
import {
  Modal,
  Button,
  Paper,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Box,
} from '@mui/material'


var ref = {
  option1: {
    label: 'Drop2',
    menuItems: [{ label: 'option3' }, { label: 'option4' }],
  },
  option2: {
    label: 'Drop2',
    menuItems: [{ label: 'option5' }, { label: 'option6' }],
    multiple: true,
  },
}

function Applicability() {
  var data = [
    {
      label: 'Drop1',
      menuItems: [{ label: 'Location' }, { label: 'Location type' }],
    },
  ]
  const [modalOpen, setModalOpen] = useState(false)
  const [dropdowns, setDropdowns] = useState(data)

  useEffect(() => {
    console.log('Drop down: ', dropdowns)
  }, [dropdowns])
useEffect(()=>{
if(!modalOpen){
 
  setDropdowns([])
}else{
  console.log("canceled ",data)
  setDropdowns([...data])
}
},[modalOpen])
  function renderDropdown(index) {
    if (!dropdowns[index]) return <></>
    const { label, value, menuItems, multiple } = dropdowns[index]
    if (!label || !menuItems || menuItems?.length === 0) return <></>

    if (multiple) {
      console.log('Multiple :', index)
      return (
        <MultiSelect
          dropdowns={dropdowns}
          setDropdown={setDropdowns}
          index={index}
        />
      )
    }

    function handleChange(e) {
      let val = e.target.value
      let arr = dropdowns
      arr[index].value = val
      if (val === -1) {
        arr.splice(index + 1, arr.length - 1)
        // arr[index+1]=null
      } else {
        arr[index + 1] = ref[val]
      }
      arr[index].value = val
      setDropdowns([...arr])
    }
    return (
      <Box sx={{ minWidth: 160, margin: 3 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value ? value : -1}
            onChange={handleChange}
          >
            <MenuItem value={-1}>{'Select a value'}</MenuItem>
            {menuItems?.map((ele, index) => {
              return (
                <MenuItem value={ele.value ? ele.value : ele.label} key={index}>
                  {ele.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <br />
      </Box>
    )
  }

  function renderAllSelected() {
      if(dropdowns.length===0)return<></>
      
    return (
      <>
      {console.log('Drop downs updated: ',dropdowns)}
        {dropdowns.map((ele) => {
             console.log("From list ",ele)
            if(!ele)return<></>
          if (ele.value && ele.value!==-1) {
            return (
              <>
                <h5>{ele.label}</h5>
                <span>{ele.value}</span>
                <br />
              </>
            )
          } else if (ele.values?.length > 0) {
             
            return (
              <>
              <h5>{ele.label}</h5>
                <ul>
                  {ele.values.map((list) => {
                    return<li>{list}</li>
                  })}
                </ul>
                <br />
              </>
            )
          }
        })}
      </>
    )
  }

  function renderModal() {
    return (
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="Flex" style={{ height: '100%', zIndex: -10,}}>
          
          <Paper className="Flex" style={{ padding: 200,position:'relative',paddingTop:20  }}>
          <span onClick={()=>setModalOpen(false)} style={{top:5,right:10,position:'absolute',fontSize:20,cursor:'pointer'}}>X</span>
            <div>
              <h1>Applicability</h1>
            </div>
            <br />
            <br />
            <div className="Flex" style={{ flexDirection: 'row' }}>
              {dropdowns.map((ele, index) => renderDropdown(index))}
            </div>
            {/* <MultiSelect /> */}
            <br />
            {renderAllSelected()}
            <br />
            <Button onClick={()=>setModalOpen(false)}>Cancel</Button>
          </Paper>
        </div>
      </Modal>
    )
  }

  return (
    <div>
      <Button onClick={() => setModalOpen(true)}>Add Applicability</Button>

      {renderModal()}
    </div>
  )
}

export default Applicability
