import React from 'react';
import Moment from 'react-moment';


function ADueDate1( {label, dueDate} ){

    function isOverdue(dueDate) {
        const today = new Date();
        const due =  new Date(dueDate.year, dueDate.month-1, dueDate.date);
        if ( due.getTime() > today.getTime()){
            return false;
        }
        else{
            return true;
        }
    }

    function overDueIn(dueDate){
        const due =  new Date(dueDate.year, dueDate.month-1, dueDate.date);
        return(
            <Moment to={due}>{new Date()}</Moment>
        );
    }

    function showDueStatus(){
        return(
            <span>
                {
                    isOverdue(dueDate) ? 
                    <span className="overDueText">Overdue</span> 
                    : 
                    <span> {overDueIn(dueDate)}</span>
                }
            </span>
        );
    }

    return(
        <div>
            {
                label ? 
                <p>{label} :  {showDueStatus()}</p> 
                : 
                <p>{showDueStatus()}</p>
            }
            
        </div>
    );
}

export default ADueDate1;