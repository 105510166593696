import React from 'react'
import { Paper, Button } from '@mui/material'

const initialProps = {
  heading: null,
  text: null,
  buttonLinkLabel: null,
  buttonLink: null,
  data:null
}

/*
data: [Array required]{
  label: [string]
  value: [string/number]
}
*/

function Card_5(props = initialProps) {
  const { heading, text, buttonLinkLabel, buttonLink, data,nextScreen } = props
  if (data?.length > 0) {
    return (
      <div>
      <Paper style={{ padding: 10, width: 280,height:250 }}>
        <h3>{heading}</h3>
        <br />
        {data.map((ele, index) => (
          <div
            style={{
              flex: 1,
              flexDirection: 'row',
              display: 'flex',
              overflow: 'hidden',
              backgroundColor: index % 2 === 0 ? '#F5F6FB' : 'white',
            }}
          >
            <div style={{ flex: 0.3, overflow: 'hidden' }}>{ele.label}</div>
            <div
              style={{
                flex: 0.2,
                overflow: 'hidden',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              :
            </div>
            <div style={{ flex: 0.5, overflow: 'hidden' }}>{ele.value}</div>
          </div>
        ))}
        <br />
        <div
          className="Flex-row"
          style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
        >
        {  nextScreen && <Button >{buttonLinkLabel?buttonLinkLabel:"More >"}</Button>}
        </div>
      </Paper>
      </div>
    )
  }
  return (
    <div>
      <Paper
        style={{
         
          padding: 10,
          width: 280,
          height: 250,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <h2 style={{ flex: 0.1 }}>{heading}</h2>
 
        <div style={{ flex: 0.7, overflow: 'hidden' }}>
          {' '}
          <p style={{ textAlign: 'left' }}>{text}</p>
        </div>
  
        <div className='Flex-row' style={{justifyContent:'flex-end',flex:0.1}}>
        <Button >{buttonLinkLabel?buttonLinkLabel:"More >"}</Button>
        </div>
      </Paper>
    </div>
  )
}

export default Card_5
