import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import methodHandler from '../../AComponent/utils/EventHandler'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'

import './field.css'

//const URL="http://functionhandler-env.eba-nsef3vus.ap-south-1.elasticbeanstalk.com/functionHandler";
const URL="https://internal.employwise.app/functionHandler"
function TwoFactor(props) {
  const { id, compNumber } = props
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(true)
  const [otps, setOtps] = useState({
    mobile: '',
    email: '',
  })
  const [error, setError] = useState(null)
  const { open, onClick } = useSelector((state) =>
    JSON.parse(JSON.stringify(state.DataReducer[id][compNumber])),
  )
  const dispatch = useDispatch()
  useEffect(() => {
    setError(false)
    if (open) fetchToken()
  }, [open])

  function fetchToken() {
 
    console.log('Fetching')
    var params={
      "function": "twofactorauthentication",
      "RequestType":"1",
      "featureID": "00108",
      "tenantID":"00",
      "featureVariantId":"000"
    }
    axios
      .post(URL,params)
      .then((res) => {
      //  setToken(res.data)
      console.log(res)
       // setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }
  function closeModal() {
    dispatch({
      type: 'UPDATE_PROPS',
      payload: {
        id,
        componentNumber: compNumber,
        data: false,
        props: 'open',
      },
    })
  }

  function Verify() {
    //'http://localhost:5000/submitOtp'
    console.log('From verify ', otps)
    setLoading(true)
    setError(null)
    var params={
      "function": "twofactorauthentication",
      "RequestType":"2",
      "featureID": "00108",
      "tenantID":"00",
      "featureVariantId":"000",
      "UserID": "1643798901455"
    }
    axios
      .post(URL, params)
      .then((res) => {
        setLoading(false)
        if (res.data.success) {
          if (onClick) {
            // setLoading(true)
            methodHandler(onClick, id)
              .then((res) => {
                closeModal();
              })
              .catch((e) => {
                //  setLoading(false)
                closeModal()
              })
          }
        } else {
          setError(true)
        }
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  function renderLoading() {
    return (
      <>
        <Modal open={open ? true : false} onClose={closeModal}>
          <Paper className="Modal-paper Flex" elevation={3}>
            <div className="Flex">
              <h2>Loading....</h2>
            </div>
          </Paper>
        </Modal>
      </>
    )
  }
  function renderInput(type) {
    return (
      <div>
        <small>Enter OTP For {type}</small>
        <br />
        <input
          type="number"
          maxLength={6}
          className="partitioned"
          variant="standard"
          inputmode="numeric"
          onChange={(e) => {
            setOtps((prev) => ({ ...prev, [type]: e.target.value }))
            setError(null)
          }}
        />
      </div>
    )
  }
  if (loading) return renderLoading()
  return (
    <div>
      {console.log('From 2 factor: ', token)}
      <Modal open={open ? true : false} onClose={closeModal}>
        <div className="Flex" style={{ height: '100%' }}>
          <Paper
            className="Flex"
            style={{ width: '40%', padding: 30 }}
            elevation={3}
          >
            <h2>OTP Authentication</h2>
            {error && <h3 style={{ color: 'red' }}>OTP Verification failed</h3>}
            <br />
            <br />
            {token.mobile && renderInput('mobile')}
            <br />
            {token.email && renderInput('email')}
            <br />
            <div className='Flex' style={{flexDirection:'row'}}>
            <span
              style={{ marginLeft: 20, color: 'blue', cursor: 'pointer' }}
              onClick={Verify}
            >
              Submit
            </span>
            <span style={{ marginLeft: 20, color: 'red', cursor: 'pointer' }} onClick={closeModal}>Cancel</span>
            </div>
         
          </Paper>
        </div>
      </Modal>
    </div>
  )
}

export default TwoFactor
