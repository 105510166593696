import React, { useEffect, useState } from "react";
import Atomic from "../../../AComponent/Atomic";
import Applicability5 from "../../../MComponent/Applicability/Applicability5";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../../Utils/Common/Library";
import { Typography } from "@mui/material";
const { Radio, Dropdown, EwSwitch, Button, Input, EwStepper } = Atomic;

const FeatureID = "19147";
const RatingScaleFeatureID = "19148";
const DimensionFeatureId = "19144";
const id = "DMP";

const DiscardRules = {
  MMM: ["PPP", "RPP", "RRP", "RRR"],
  DMM: ["RPP", "RRP", "RRR"],
  DDM: ["RRP", "RRR"],
  MMU: ["RRN", "RPN", "PPN"],
  MUU: ["PNN", "RNN"],
};
const abbrMap = {
  D: "Disabled",
  E: "Enabled",
  N: "Not applicable",
  U: "User Defined",
  M: "Mapped",
  R: "Restricted",
  P: "Permitted",
  L: "Library",
};
const MMMrules = [
  "RRR",
  "LLL",
  "UUU",
  "RRL",
  "RRU",
  "RLL",
  "RLU",
  "RUU",
  "LLU",
  "LUL",
  "LUU",
];
const AddRules = {
  UUU: ["LLL", "UUU"],
  MMM: MMMrules,
  DUU: ["NLL", "NLU", "NUL", "NUU"],
  DMM: ["NRR", "NRL", "NRU", "NLL", "NLU", "NUL", "NUU"],
  DDU: ["NNL", "NNU"],
  DDM: ["NNR", "NNU", "NNL"],
  MMU: MMMrules,
  MUU: MMMrules,
};
const userTargetType = [
  { label: "Fixed Value", value: "FV" },
  { label: "Anchored Rating Scale Computed Rating", value: "CR" },
  { label: "Rating Guide", value: "RG" },
];
const mappedTargetType = [
  { label: "Expected Scale Value", value: "EV" },
  { label: "Anchored to Rating Scale Value", value: "AR" },
  { label: "Anchored Rating Scale Computed Rating", value: "CR" },
  { label: "Rating Guide", value: "RG" },
];
const numericDataType = [
  { label: "Numeric", value: "NI" },
  { label: "Numeric (Decimal)", value: "ND" },
  { label: "Percentage", value: "PI" },
  { label: "Percentage (Decimal)", value: "PD" },
];
const allDataType = [
  { label: "Alpha Numeric Text (200 chars) ", value: "ST" },
  { label: "Alpha Numeric Large Text", value: "LT" },
  ...numericDataType,
];
const dataTypes = {
  ST: { label: "Alpha Numeric Text (200 chars) ", value: "ST" },
  LT: { label: "Alpha Numeric Large Text", value: "LT" },
  NI: { label: "Numeric", value: "NI" },
  ND: { label: "Numeric (Decimal)", value: "ND" },
  PI: { label: "Percentage", value: "PI" },
  PD: { label: "Percentage (Decimal)", value: "PD" },
};
const weightRules = [
  { label: "Disable Weights", value: "D" },
  { label: "Disable Weights", value: "D" },
  { label: "Disable Weights", value: "D" },
  { label: "Disable Weights", value: "D" },
];
function DMP2() {
  const {
    DimensionID,
    MapApplicabilityID,
    MapStructure,
    TargetRule,
    DiscardRule,
    AddRule,
    AchievementDataType,
    TargetDataType,
    MapName,
    MapShortName,
    MapDescription,
    openForm,
    editMode,
    dimList,
    dimData,
    dimensionData,
    MinimumFirstGroup,
    MaximumFirstGroup,
    MinimumSecondGroup,
    MaximumSecondGroup,
    MinimumParameters,
    MaximumParameters,
    FirstGroupWeightRule,
    SecondGroupWeightRule,
    ParameterWeightRule,
    ratingScaleList,
    RatingScaleID,
    SecondGroups,
    FirstGroups,
    Parameters,
    SelectedRatingScale,
    DefaultRating,
    UserTargetOff,
    UserTargetST,
    UserTargetLT,
    UserTargetNI,
    UserTargetND,
    UserTargetPI,
    UserTargetPD,
  } = useSelector((state) => {
    if (!state.DataReducer[id]) return {};
    return JSON.parse(JSON.stringify(state.DataReducer[id]));
  });
  const [currentStep, setCurrentStep] = useState(0);
  useEffect(() => {
    if (DimensionID) {
      getData({
        featureId: DimensionFeatureId,
        payload: { "Header.TransactionID": DimensionID },
      }).then((res) => {
        if (res?.Data && res?.Data[0]) updateState(res.Data[0], "dimData");
      });
    }
  }, [DimensionID]);

  // useEffect(() => {
  //   return () => {
  //     dispatch({
  //       type: "UNSET_DATA",
  //       payload: {
  //         id: "DMP",
  //       },
  //     });
  //   };
  // }, []);
  const dispatch = useDispatch();
  function updateState(val, type) {
    console.log("update: ", val, " ", type);
    if (type === "MapStructure") {
      updateState(undefined, "DiscardRule");
      updateState(undefined, "AddRule");
      updateState(undefined, "SecondGroups");
      updateState(undefined, "FirstGroups");
      updateState(undefined, "Parameters");
    }
    if (type === "TargetRule") {
      updateState(undefined, "TargetDataType");
      updateState(undefined, "SecondGroups");
      updateState(undefined, "FirstGroups");
      updateState(undefined, "Parameters");
    }
    if (type === "TargetDataType") {
      updateState(undefined, "AchievementDataType");
      updateState(undefined, "SecondGroups");
      updateState(undefined, "FirstGroups");
      updateState(undefined, "Parameters");
    }
    if (type === "DimensionID") {
      updateState(undefined, "MapStructure");
      updateState(undefined, "SecondGroups");
      updateState(undefined, "FirstGroups");
      updateState(undefined, "Parameters");
    }
    if (type === "SecondGroupWeightRule" && typeof SecondGroups === "object") {
      let groups = [...SecondGroups];
      groups = groups.map((group) => ({
        ...group,
        Weight: "",
        MinimumWeight: "",
        MaximumWeight: "",
      }));
      updateState(groups, "SecondGroups");
    }
    if (type === "FirstGroupWeightRule" && typeof FirstGroups === "object") {
      let groups = [...FirstGroups];
      groups = groups.map((group) => ({
        ...group,
        Weight: "",
        MinimumWeight: "",
        MaximumWeight: "",
      }));
      updateState(groups, "FirstGroups");
    }
    if (type === "ParameterWeightRule" && typeof Parameters === "object") {
      let params = [...Parameters];
      params = params.map((param) => ({
        ...param,
        Weight: "",
        MinimumWeight: "",
        MaximumWeight: "",
      }));
      updateState(params, "Parameters");
    }

    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: type,
        data: val,
      },
    });
  }

  function docSelected(doc) {
    doc.openForm = true;
    doc.editMode = true;
    dispatch({
      type: "UPDATE_ALL_FIELDS",
      payload: {
        id,
        data: doc,
      },
    });
    // updateState(true, "openForm");
  }
  function renderBasicMapinfo() {
    return (
      <>
        <div className="Flex-row" style={{ justifyContent: "space-around" }}>
          <div className="Flex" style={{ width: "30%" }}>
            <div>
              <Input
                label={"Map Name"}
                value={MapName}
                onChange={(val) => updateState(val, "MapName")}
              />
            </div>
            <div>
              <Input
                label={"Map Short Name"}
                value={MapShortName}
                onChange={(val) => updateState(val, "MapShortName")}
              />
            </div>
          </div>
          <div className="Scroll">
            <Input
              label={"Map Description"}
              value={MapDescription}
              rows={4}
              onChange={(val) => updateState(val, "MapDescription")}
            />
          </div>
          <div>
            <Applicability5
              AuthorFeatureID={FeatureID}
              value={MapApplicabilityID}
              onChange={(val) => updateState(val, "MapApplicabilityID")}
              ApplicabilityReferenceField="MapApplicabilityID"
            />
            {/* <Button title={"Applicablity"} onClick={() => {}} /> */}
          </div>
        </div>
        <br />
      </>
    );
  }
  function renderDimensionSelector() {
    function dimensionSelected(id) {
      updateState(id, "DimensionID");
    }
    return (
      <>
        <Dropdown
          label={"Dimension"}
          mapper={{
            label: "DimensionName",
            value: "TransactionID",
          }}
          featureId={DimensionFeatureId}
          apiResult={dimList}
          storeApiResult={(val) => updateState(val, "dimList")}
          value={DimensionID}
          onChange={dimensionSelected}
        />
        <br />
        <div className="Flex">
          <Button title={"Next"} onClick={() => setCurrentStep(1)} />
        </div>
      </>
    );
  }
  function renderMapStructureSettings() {
    if (!dimData) return <></>;
    let map = MapStructure;
    var { FirstGrouping, SecondGrouping } = dimData;
    FirstGrouping = FirstGrouping === "Enabled";
    SecondGrouping = SecondGrouping === "Enabled";

    if (!map) map = "";
    var [sec, first, param] = map.split("");
    if (!sec && !SecondGrouping) sec = "D";
    if (!first && !FirstGrouping && !SecondGrouping) first = "D";
    const radioList = [
      { label: "Mapped", value: "M" },
      { label: "User Defined", value: "U" },
    ];
    let paramRadioLIst, firstRadioList;
    if (sec === "U") firstRadioList = [{ label: "User Defined", value: "U" }];
    else firstRadioList = radioList;
    if (first === "D" || first === "M") paramRadioLIst = radioList;
    else paramRadioLIst = [{ label: "User Defined", value: "U" }];

    function renderLimitInout(type) {
      let min, max, variable;
      if (type === 2) {
        min = MinimumSecondGroup;
        max = MaximumSecondGroup;
        variable = "SecondGroup";
      } else if (type === 1) {
        min = MinimumFirstGroup;
        max = MaximumFirstGroup;
        variable = "FirstGroup";
      } else {
        min = MinimumParameters;
        max = MaximumParameters;
        variable = "Parameters";
      }
      return (
        <div className="Flex-row g20">
          <div style={{ width: "30%" }}>
            {type === 2
              ? "Second Groups"
              : type === 1
              ? "First Groups"
              : "Parameter"}
          </div>
          <div>
            <Input
              label={"Minimum"}
              value={min}
              onChange={(val) => updateState(val, "Minimum" + variable)}
              type="number"
            />
          </div>
          <div>
            <Input
              label={"Maximum"}
              value={max}
              onChange={(val) => updateState(val, "Maximum" + variable)}
              type="number"
            />
          </div>
        </div>
      );
    }

    return (
      <>
        <br />
        <div>
          {/* <h3>Map Structure</h3>
          <hr /> */}
        </div>
        <br />
        <div
          className="Flex-row-up g20"
          style={{ justifyContent: "flex-start" }}
        >
          {SecondGrouping && (
            <div>
              <Radio
                label={"Second Group"}
                menuItems={radioList}
                value={sec}
                onChange={(val) => updateState(val, "MapStructure")}
              />
            </div>
          )}
          {sec && (
            <div>
              {console.log("First: ", first)}
              <Radio
                label={"First Group"}
                menuItems={firstRadioList}
                value={first}
                onChange={(val) => updateState(sec + val, "MapStructure")}
              />
            </div>
          )}
          {first && (
            <div>
              <Radio
                label={"Parameter"}
                menuItems={paramRadioLIst}
                value={param ? param : -1}
                onChange={(val) =>
                  updateState(sec + first + val, "MapStructure")
                }
              />
            </div>
          )}
        </div>
        <br />
        <div>
          {SecondGrouping && renderLimitInout(2)}
          {FirstGrouping && renderLimitInout(1)}
          {renderLimitInout(0)}
        </div>
        <br />
        <div className="Flex">
          <Button title={"Next"} onClick={() => setCurrentStep(2)} />
        </div>
      </>
    );
  }

  function renderDiscardSettings(ruleFor) {
    if (!dimData || !MapStructure) return <></>;
    if (MapStructure.length !== 3) return <></>;
    let map = MapStructure;
    let discardRule = DiscardRule;
    let addRule = AddRule;
    if (!discardRule) discardRule = "";
    if (!addRule) addRule = "";
    let allowed = ruleFor === 1 ? DiscardRules[map] : AddRules[map];
    let [secGroup, firstGroup, paramGroup] = map.split("");
    let [secDRule, firstDRule, paramDRule] = discardRule.split("");
    let [secARule, firstARule, paramARule] = addRule.split("");
    if (secGroup === "D") {
      secDRule = "R";
      secARule = "N";
    }
    if (firstGroup === "D") {
      firstDRule = "R";
      firstARule = "N";
    }
    function getItems(type) {
      if (!allowed) return [];
      let options = new Set();
      let prev;
      if (ruleFor === 1) {
        prev =
          type === 2 ? secDRule + firstDRule : type === 1 ? secDRule : null;
      } else {
        prev =
          type === 2 ? secARule + firstARule : type === 1 ? secARule : null;
      }

      allowed.map((ele) => {
        if (prev && ele.substring(0, prev.length) === prev) {
          options.add(ele.charAt(type));
        } else if (!prev) {
          options.add(ele.charAt(type));
        }
      });

      return [...options].map((ele) => ({ label: abbrMap[ele], value: ele }));
    }
    return (
      <>
        <br />
        {/* <h3>{ruleFor === 1 ? "Discard Rules" : "Add Rules"}</h3>
        <hr /> */}
        <div
          className="Flex-row-up"
          style={{ gap: 20, justifyContent: "flex-start" }}
        >
          {secGroup === "M" && (
            <div>
              <Radio
                label="Second Group"
                value={ruleFor === 1 ? secDRule : secARule}
                onChange={(val) => {
                  if (ruleFor === 1) updateState(val, "DiscardRule");
                  else updateState(val, "AddRule");
                }}
                menuItems={getItems(0)}
              />
            </div>
          )}
          {["M", "U"].includes(firstGroup) &&
            (ruleFor === 1 ? secDRule : secARule) &&
            getItems(1).length > 0 &&
            getItems(1)[0].value !== "N" && (
              <div>
                <Radio
                  label="First Group"
                  value={ruleFor === 1 ? firstDRule : firstARule}
                  menuItems={getItems(1)}
                  onChange={(val) => {
                    if (ruleFor === 1)
                      updateState(secDRule + val, "DiscardRule");
                    else updateState(secARule + val, "AddRule");
                  }}
                />
              </div>
            )}

          {(ruleFor === 1 ? firstDRule : firstARule) && (
            <div>
              {getItems(2)[0]?.value === "N" ? (
                <></>
              ) : (
                <>
                  <Radio
                    label="Parameter Rule"
                    value={ruleFor === 1 ? paramDRule : paramARule}
                    onChange={(val) => {
                      if (ruleFor === 1)
                        updateState(secDRule + firstDRule + val, "DiscardRule");
                      else updateState(secARule + firstARule + val, "AddRule");
                    }}
                    menuItems={getItems(2)}
                  />
                </>
              )}
            </div>
          )}
        </div>
        <br />
        <div className="Flex">
          <Button
            title={"Next"}
            onClick={() => setCurrentStep(ruleFor === 1 ? 3 : 4)}
          />
        </div>
      </>
    );
  }

  // function renderTargetSettings() {
  //   if (!DimensionID) return <></>;
  //   let rule = TargetRule;
  //   if (!rule) rule = "";
  //   let [enable, type1, type2, setBy] = rule.split("");
  //   if (!type1) type1 = "_";
  //   if (!type2) type2 = "_";
  //   let targetType = type1 + type2;
  //   const radioOptions = [
  //     { label: "Mapped", value: "M" },
  //     { label: "User Defined", value: "U" },
  //   ];
  //   function renderTargetDataType() {
  //     if (enable !== "E" || !TargetRule) return <></>;
  //     if (TargetRule.charAt(1) === "_") return <></>;
  //     if (targetType === "AR") return <></>;
  //     let allowedOptions = allDataType;
  //     if (targetType === "CR") allowedOptions = numericDataType;

  //     return (
  //       <div style={{ width: "50%" }}>
  //         <Dropdown
  //           label={"Target Data Type"}
  //           menuItems={allowedOptions}
  //           value={TargetDataType}
  //           onChange={(val) => updateState(val, "TargetDataType")}
  //         />
  //       </div>
  //     );
  //   }

  //   function renderRatingScale() {
  //     if (enable !== "E" || !["EV", "AR", "CR", "RG","FV"].includes(targetType))
  //       return <></>;
  //     return (
  //       <div style={{ width: "50%" }}>
  //         <Dropdown
  //           label={"Rating Scale"}
  //           mapper={{
  //             label: "RatingScaleName",
  //             value: "TransactionID",
  //           }}
  //           featureId={RatingScaleFeatureID}
  //           apiResult={ratingScaleList}
  //           storeApiResult={(val) => updateState(val, "ratingScaleList")}
  //           value={RatingScaleID}
  //           onChange={(val) => updateState(val, "RatingScaleID")}
  //           filter={(item) =>
  //             item?.RatingScaleType && item.RatingScaleType === "D"
  //           }
  //         />
  //       </div>
  //     );
  //   }
  //   return (
  //     <>
  //       <br />
  //       {/* <h3>Targets And Achievement</h3>
  //       <hr /> */}
  //       <div className="Flex">
  //         <EwSwitch
  //           label={"Targets"}
  //           value={enable === "E"}
  //           onChange={(flag) => updateState(flag ? "E" : "D", "TargetRule")}
  //         />
  //       </div>
  //       {enable === "E" && (
  //         <div>
  //           <div className="Flex-row g20">
  //             <div>
  //               <Radio
  //                 label={"Target Set By"}
  //                 menuItems={radioOptions}
  //                 value={setBy}
  //                 onChange={(val) =>
  //                   updateState(enable + "__" + val, "TargetRule")
  //                 }
  //               />
  //             </div>
  //             {setBy && (
  //               <div style={{ width: "40%" }}>
  //                 <Dropdown
  //                   label="Target Type"
  //                   menuItems={
  //                     setBy === "M" ? mappedTargetType : userTargetType
  //                   }
  //                   value={targetType === "__" ? undefined : targetType}
  //                   onChange={(val) =>
  //                     updateState(enable + val + setBy, "TargetRule")
  //                   }
  //                 />
  //               </div>
  //             )}
  //           </div>
  //           <br />
  //           <div className="Flex">{renderRatingScale()}</div>
  //           {/* <div className="Flex-row g20">
  //             {renderTargetDataType()}
  //             {renderAchivementDataType()}
  //           </div> */}
  //         </div>
  //       )}
  //       <br />
  //       <div className="Flex">
  //         <Button title={"Next"} onClick={() => setCurrentStep(5)} />
  //       </div>
  //     </>
  //   );
  // }
  function renderTargetSettings() {
    if (!dimData) return <Typography>Select A Dimension</Typography>;
    if (!AddRule) return <Typography>Add Rule Not defined</Typography>;
    let showUserRule = AddRule.charAt(2) !== "R";
    return (
      <>
        {/* <div className="Flex">
          <EwSwitch
            label={"Targets"}
            value={TargetRule === "E"}
            onChange={(flag) => updateState(flag ? "E" : "D", "TargetRule")}
          />
        </div> */}
        <br />
        {
          <div className="Flex-row g20">
            <div style={{ width: "50%" }}>
              <Dropdown
                label={"Rating Scale"}
                mapper={{
                  label: "RatingScaleName",
                  value: "TransactionID",
                }}
                featureId={RatingScaleFeatureID}
                apiResult={ratingScaleList}
                storeApiResult={(val) => updateState(val, "ratingScaleList")}
                value={RatingScaleID}
                onChange={(val, doc) => {
                  updateState(val, "RatingScaleID");
                  updateState(doc, "SelectedRatingScale");
                }}
              />
            </div>

            {SelectedRatingScale?.ScaleValues?.length > 0 && (
              <div style={{ width: "50%" }}>
                <Dropdown
                  label={"Deafult Rating"}
                  menuItems={SelectedRatingScale.ScaleValues.map(
                    ({ Label, OptionID }) => ({ label: Label, value: OptionID })
                  )}
                  value={DefaultRating}
                  onChange={(val) => updateState(val, "DefaultRating")}
                />
              </div>
            )}
            {/* {JSON.stringify(SelectedRatingScale)} */}
          </div>
        }
        <br />
        {showUserRule && (
          <div>
            <Typography variant="h5">User's Parameter Target Rule</Typography>
            <hr />
            <Typography>
              This section defines the target rule for users when they add their
              own prameter or add it from library.
            </Typography>
            <br />
            <div className="Flex">
              <EwSwitch
                label={"User Target Off"}
                value={UserTargetOff === "E"}
                onChange={(val) =>
                  updateState(val ? "E" : "D", "UserTargetOff")
                }
              />
            </div>
            {UserTargetOff !== "E" && (
              <div>
                <EwSwitch
                  label={"Short text data Type"}
                  value={UserTargetST === "E"}
                  onChange={(val) =>
                    updateState(val ? "E" : "D", "UserTargetST")
                  }
                />

                <EwSwitch
                  label={"Long text data Type"}
                  value={UserTargetLT === "E"}
                  onChange={(val) =>
                    updateState(val ? "E" : "D", "UserTargetLT")
                  }
                />

                <EwSwitch
                  label={"Numeric Integer"}
                  value={UserTargetNI === "E"}
                  onChange={(val) =>
                    updateState(val ? "E" : "D", "UserTargetNI")
                  }
                />

                <EwSwitch
                  label={"Numeric Decimal"}
                  value={UserTargetND === "E"}
                  onChange={(val) =>
                    updateState(val ? "E" : "D", "UserTargetND")
                  }
                />

                <EwSwitch
                  label={"Percentage Value"}
                  value={UserTargetPI === "E"}
                  onChange={(val) =>
                    updateState(val ? "E" : "D", "UserTargetPI")
                  }
                />
                <EwSwitch
                  label={"Percentage in 2 decimals"}
                  value={UserTargetPD === "E"}
                  onChange={(val) =>
                    updateState(val ? "E" : "D", "UserTargetPD")
                  }
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  }
  function renderWeightRules() {
    if (!dimData) return <></>;
    var { FirstGrouping, SecondGrouping } = dimData;
    FirstGrouping = FirstGrouping === "Enabled";
    SecondGrouping = SecondGrouping === "Enabled";
    let options = [
      { label: "Disable", value: "D" },
      { label: "Mapped but non editable by user", value: "M" },
      { label: "Mapped but user editable", value: "E" },
      { label: "User defined and not mapped", value: "U" },
    ];
    let aggregate = { label: "Aggregated", value: "A" };
    return (
      <>
        {/* <h3>Weight Rules</h3>
        <hr /> */}
        <br />
        <div className="Flex-row g20">
          {SecondGrouping && (
            <div style={{ width: "30%" }}>
              <Dropdown
                menuItems={[...options, aggregate]}
                label="Second Group"
                value={SecondGroupWeightRule}
                onChange={(val) => updateState(val, "SecondGroupWeightRule")}
              />
            </div>
          )}
          {FirstGrouping && (
            <div style={{ width: "30%" }}>
              <Dropdown
                menuItems={[...options, aggregate]}
                label="First Group"
                value={FirstGroupWeightRule}
                onChange={(val) => updateState(val, "FirstGroupWeightRule")}
              />
            </div>
          )}
          <div style={{ width: "30%" }}>
            <Dropdown
              menuItems={options}
              label="Paramter"
              value={ParameterWeightRule}
              onChange={(val) => updateState(val, "ParameterWeightRule")}
            />
          </div>
        </div>
        <br />
      </>
    );
  }
  const heading = ["Map Name", "Edit"];
  const steps = [
    "Basic Info",
    "Map Structure",
    "Discard Rules",
    "Add Rules",
    "Target Settings",
    "Weight Rules",
  ];
  //className="scroll" style={{ height: "80vh", overflowY: "auto" }}
  return (
    <div>
      <div>
        <br />
        <br />
        <EwStepper
          activeStep={currentStep}
          steps={steps}
          onClick={(index) => setCurrentStep(index)}
        />
        <br />
        <div>
          {currentStep === 0 && renderBasicMapinfo()}
          <br />
          {currentStep === 0 && renderDimensionSelector()}
          {currentStep === 1 && renderMapStructureSettings()}
          {currentStep === 2 && renderDiscardSettings(1)}
          {currentStep === 3 && renderDiscardSettings(2)}
          {currentStep === 4 && renderTargetSettings()}
          {currentStep === 5 && renderWeightRules()}
        </div>
      </div>
    </div>
  );
}

export default DMP2;
