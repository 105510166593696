import React from "react";
import Button_1 from  '../Buttons/Button_1'
import axios from "axios"


function Uploader({url,data,config,type}){
    function upload(){
       if(type==='POST'){
           axios.post(url,data,config).then((res)=>{

           }).catch((e)=>{

           })
       } else if(type==='GET'){
        axios.get(url,data,config).then((res)=>{

        }).catch((e)=>{

        })
       }
    }
    return(
        <>
<Button_1 Title="Upload" Handler={upload}  />
        </>
    )
}


export default Uploader;