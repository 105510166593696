import React from "react";
import Data from "../Testjson/MSG.json";
import JsonInterpreter from "../Interpreter/JsonInterpreter";

function Messages() {
  return (
    <>
      <div style={{ position: "relative" }}>
        <JsonInterpreter Components={Data.Components} />
      </div>
    </>
  );
}

export default Messages;
