import { Box, Grid, Link, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getData,
  getUserId,
  submitPayload,
} from "../../../../Utils/Common/Library";
import Atomic from "../../../../AComponent/Atomic";
import FeatureIDs from "../../../../Utils/Common/FeatureIDs";
import Speedometer from "../Charts/Speedometer";
import { TryOutlined } from "@mui/icons-material";
import { WorkflowProcesser } from "../../Dimension/WorkflowProcesser";
import { getFinalPeriod } from "../../Dimension/Phases/PeriodMap";
import AlertMessage from "../../../../Utils/Messages/AlertMessage";
import EwModal from "../../../../MComponent/Modal/Modal";
import Dashboard from "../../Dashboard";
import ActionDebouncer from "../../Dimension/ActionDebouncer";

const { Input, Button, Dropdown } = Atomic;

function ScoreSubmit({
  onSubmit,
  initScore,
  ratingScale,
  isLoading,
  handleTabEdit,
  renderCondition,
}) {
  const [score, setScore] = useState(initScore || "");
  const { RatingScaleType, ScaleValues } = ratingScale;

  return (
    <Box>
      <Box display={"flex"} alignItems={"center"} gap={"1em"}>
        {RatingScaleType === "C" ? (
          <Input
            label={"Score"}
            onChange={(val) => {
              handleTabEdit();
              setScore(val);
            }}
            value={score}
            size={"small"}
            styles={{
              width: "70%",
              margin: "0",
              marginTop: "0.5em",
            }}
            InputProps={{
              style: {
                paddingRight: "0",
              },
            }}
          />
        ) : (
          <Dropdown
            label={"Score"}
            onChange={(val) => {
              handleTabEdit();
              setScore(val);
            }}
            size={"small"}
            disable={renderCondition()}
            value={score}
            menuItems={[
              ...ScaleValues.map((value) => ({
                label: `${value.Label} - ${value.Value}`,
                value: value.Value,
              })),
            ]}
          />
        )}
        {!renderCondition() && (
          <Button
            label={"Save"}
            isLoading={isLoading}
            size="small"
            onClick={() => {
              onSubmit(score);
            }}
            title={"Submit"}
            style={{ margin: 0 }}
          />
        )}
      </Box>
    </Box>
  );
}

function PlanScores({
  EPP,
  handleTabEdit,
  FinalAssessments,
  AggregatePlanScores,
  ModeratedPlanScores,
  AssessorPlanScores,
  AdjustedPlanScores,
}) {
  const [AssessorRatingScale, setAssessorRatingScale] = useState();
  const [actionError, setActionError] = useState(null);

  const D1ID = EPP.Dimensions[0].DimensionID,
    D2ID = EPP.Dimensions[1].DimensionID;

  function getAllowedActions(doc1, doc2) {
    if (!doc1 && !doc2) return [];
    else if (!doc1) doc1 = JSON.parse(JSON.stringify(doc2));
    else if (!doc2) doc2 = JSON.parse(JSON.stringify(doc1));

    const WorkflowDoc = {
      ConversationID: "",
      WorkflowID: "",
      WorkflowStatus: "",
      WorkflowSteps: "",
    };

    const Workflow1 = Object.keys(WorkflowDoc).reduce((total, current) => {
      total[current] = doc1[current];
      return total;
    }, {});

    const Workflow2 = Object.keys(WorkflowDoc).reduce((total, current) => {
      total[current] = doc2[current];
      return total;
    }, {});

    let workflowProcesser1, workflowProcesser2;
    try {
      workflowProcesser1 = new WorkflowProcesser(Workflow1);
      workflowProcesser2 = new WorkflowProcesser(Workflow2);
    } catch (e) {
      return [];
    }

    const CurrentStep1 = workflowProcesser1.getCurrentStepData();
    const CurrentStep2 = workflowProcesser2.getCurrentStepData();
    // console.log(
    //   "Overview: ",
    //   CurrentStep1.StepOwnerLabel,
    //   " ",
    //   CurrentStep1.StepOwnerLabel
    // );
    return CurrentStep1 &&
      CurrentStep2 &&
      CurrentStep1.StepOwnerLabel === "Reviewer" &&
      CurrentStep2.StepOwnerLabel === "Reviewer"
      ? CurrentStep1?.AllowedActions?.map((action) => {
          if (CurrentStep2?.AllowedActions?.includes(action)) {
            return action;
          } else {
            return undefined;
          }
        }).filter((action) => action) || []
      : [];
  }

  const AssessmentDocs = {
    ...FinalAssessments,
    AllowedActions: getAllowedActions(
      FinalAssessments[D1ID].length > 0
        ? FinalAssessments[D1ID][0]
        : FinalAssessments[D2ID].length > 0
        ? FinalAssessments[D2ID][0]
        : undefined
    ),
  };

  console.log(
    EPP,
    FinalAssessments,
    AggregatePlanScores,
    ModeratedPlanScores,
    AssessorPlanScores,
    AdjustedPlanScores,
    "PLANSCORES"
  );

  const {
    AggregatedPlanScore,
    ModeratedPlanScore,
    AdjustedPlanScore,
    AssessorPlanScore,
  } = EPP;

  const { AssessorPlanRatingScaleId } = AssessorPlanScores;

  useEffect(() => {
    if (AssessorPlanRatingScaleId) {
      getData({
        featureId: FeatureIDs.RatingScale,
        payload: { "Header.TransactionID": AssessorPlanRatingScaleId },
      }).then((res) => {
        setAssessorRatingScale(res.Data[0]);
      });
    }
  }, [AssessorPlanRatingScaleId, AssessorPlanScores]);

  function getFinalScore() {
    const validFinalScores = [];
    if (
      AggregatePlanScores.AggregateFinalScore === "E" &&
      AggregatedPlanScore
    ) {
      validFinalScores.push(AggregatedPlanScore);
    }
    if (
      ModeratedPlanScores.ModeratedPlanScoreFinalScore === "E" &&
      ModeratedPlanScore
    ) {
      validFinalScores.push(ModeratedPlanScore);
    }
    if (
      getScoreCondition() &&
      AssessorPlanScores.AssessorPlanFinalScore === "E" &&
      AssessorPlanScore
    ) {
      validFinalScores.push(AssessorPlanScore);
    }
    if (
      AdjustedPlanScores.AdjustedPlanFinalScore === "E" &&
      AdjustedPlanScore
    ) {
      validFinalScores.push(AdjustedPlanScore);
    }

    if (validFinalScores.length === 0) {
      return "--.--";
    } else if (validFinalScores.length === 1) {
      return validFinalScores[0];
    } else {
      // return validFinalScores.reduce((total, current) => {
      //   total = Math.min(+total, +current);
      //   return total;
      // }, 0);
      return validFinalScores[0];
    }
  }

  const Appraisers = EPP.Dimensions.map(
    (dimension) => dimension.AppraiserID
  ).filter((id) => id);
  const Reviewers = EPP.Dimensions.map(
    (dimension) => dimension.ReviewerID
  ).filter((id) => id);

  function editEPP(val) {
    const edit = {
      TransactionID: EPP.TransactionID,
      EffectiveFromTimestamp: EPP.EffectiveFromTimestamp,
      RequestType: "1",
    };

    return submitPayload({
      featureId: FeatureIDs.EmployeePerformancePlan,
      edit,
      payload: {
        ...EPP,
        AssessorPlanScore: val,
      },
    });
  }

  function submitDimensionWorkflow(ParameterAssessment, ActionType) {
    const AssessmentDoc = {
      ParametersAndTargetDocumentID: "",
      Parameters: "",
      DimensionParameterAssessmentID: "",
      EventType: "",
      ReviewFromDate: "",
      PeriodAggregateScore: "",
      EmployeePerformancePlanID: "",
      WorkflowID: "",
      AppraiseeUserID: "",
      MasterPlanID: "",
      ReviewPeriodType: "",
      InterimReason: "",
      ReviewTillDate: "",
      DimensionID: "",
      ReviewPeriodUnit: "",
    };

    const WorkflowDoc = {
      ConversationID: "",
      WorkflowID: "",
      WorkflowStatus: "",
      WorkflowSteps: "",
    };

    const Header = {
        TransactionID: ParameterAssessment.TransactionID,
        EffectiveFromTimestamp: ParameterAssessment.EffectiveFromTimestamp,
      },
      Data = Object.keys(AssessmentDoc).reduce((total, current) => {
        total[current] = ParameterAssessment[current];
        return total;
      }, {});

    const Workflow = Object.keys(WorkflowDoc).reduce((total, current) => {
      total[current] = ParameterAssessment[current];
      return total;
    }, {});

    const workflowProcesser = new WorkflowProcesser(Workflow);
    let stepId = workflowProcesser.getCurrentStepID();
    const WorkflowID = Workflow.WorkflowID;
    let obj = {
      stepId,
      action: ActionType,
      WorkflowID,
    };

    let edit = {
      TransactionID: Header.TransactionID,
      EffectiveFromTimestamp: Header.EffectiveFromTimestamp,
    };
    console.log("Before sending: pms", Data, " ", obj, " ", edit, Workflow);

    return submitPayload({
      featureId: "19152",
      payload: Data,
      updateAction: obj,
      edit,
    });
  }

  async function editDimensionWorkflow(DimensionID, ActionType) {
    try {
      const res = { Data: AssessmentDocs[DimensionID] };
      if (typeof res !== "string") {
        const assessments = res.Data;
        if (assessments.length > 0 && assessments[0]) {
          const response = await submitDimensionWorkflow(
            assessments[0],
            ActionType
          );
          return response;
        } else {
          setActionError({
            type: 1,
            message: "Assessment not started for one of the dimensions",
          });
          return false;
        }
      } else {
        setActionError({
          type: 1,
          message: "Assessment not started for one of the dimensions",
        });
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  function getCondition({ type }) {
    const id = EPP.Dimensions[0].DimensionID;
    const assessments = AssessmentDocs[id];

    console.log(AssessmentDocs, "AssessmentDocs");

    if (!assessments || assessments.length === 0) return false;

    const PeriodType = assessments[0].ReviewPeriodType;
    const PeriodUnit = getFinalPeriod(PeriodType);

    const doc = assessments.find(
      (a) =>
        a.ReviewPeriodType === PeriodType && a.ReviewPeriodUnit === PeriodUnit
    );
    if (!doc) return false;
    const WorkflowDoc = {
      ConversationID: "",
      WorkflowID: "",
      WorkflowStatus: "",
      WorkflowSteps: "",
    };

    if (!doc) return false;

    const Workflow = Object.keys(WorkflowDoc).reduce((total, current) => {
      total[current] = doc[current];
      return total;
    }, {});

    const workflowProcesser = new WorkflowProcesser(Workflow);

    const currentStep = workflowProcesser.getCurrentStepData();

    if (!currentStep) return false; // This condition is correct when the workflow is done and the user is able to view the content

    if (
      type === "link" &&
      (!currentStep || currentStep.StepOwnerLabel === "Appraisee")
    )
      return true;

    if (
      currentStep.StepOwnerLabel === "Reviewer" &&
      getUserId() === currentStep.StepOwnerUserIDs[0]
    ) {
      return true;
    } else {
      return false;
    }
  }

  function getBeforeFinalPeriodCondition() {
    const id = EPP.Dimensions[0].DimensionID;
    const assessments = AssessmentDocs[id];

    if (!assessments || assessments.length === 0) return false;

    const PeriodType = assessments[0].ReviewPeriodType;
    const PeriodUnit = getFinalPeriod(PeriodType);

    const doc = assessments.find(
      (a) =>
        a.ReviewPeriodType === PeriodType && a.ReviewPeriodUnit === PeriodUnit
    );

    if (!doc) return true;
    return false;
  }

  function getScoreCondition() {
    const id = EPP.Dimensions[0].DimensionID;
    const assessments = AssessmentDocs[id];

    if (!assessments || assessments.length === 0) return false;

    const PeriodType = assessments[0].ReviewPeriodType;
    const PeriodUnit = getFinalPeriod(PeriodType);

    console.log(AssessmentDocs, "AssessmentDocs");

    const doc = assessments.find(
      (a) =>
        a.ReviewPeriodType === PeriodType && a.ReviewPeriodUnit === PeriodUnit
    );
    if (!doc) return false;
    const WorkflowDoc = {
      ConversationID: "",
      WorkflowID: "",
      WorkflowStatus: "",
      WorkflowSteps: "",
    };

    if (!doc) return false;

    const Workflow = Object.keys(WorkflowDoc).reduce((total, current) => {
      total[current] = doc[current];
      return total;
    }, {});

    const workflowProcesser = new WorkflowProcesser(Workflow);
    const currentStep = workflowProcesser.getCurrentStepData();

    if (!currentStep) return true; // This says that if the workflow is done, the users can see the score now.

    return (
      workflowProcesser.getWorkflowStatus() === "C" ||
      currentStep.StepOwnerLabel === "Appraisee"
    );
  }

  function onSubmit(ActionType) {
    if (ActionType === "Submit" && !EPP.AssessorPlanScore) {
      setActionError({ type: 1, message: "Please save the score" });
      return;
    }
    const Dimensions = EPP.Dimensions;
    const val = EPP.AssessorPlanScore;

    // This condition is a PATCH
    if (Dimensions.length === 2) {
      setButtonLoading2(true);
      editDimensionWorkflow(Dimensions[0].DimensionID, ActionType)
        .then((res) => {
          if (res === false) return;
          console.log("Setting Time out: overview");
          setTimeout(() => {
            editDimensionWorkflow(Dimensions[1].DimensionID, ActionType)
              .then((res2) => {
                if (res2 === false) return;
                if (ActionType !== "Submit") {
                  setModal({
                    Component: ActionDebouncer,
                    dontClose: true,
                  });
                  return;
                }

                // editEPP(val)
                //   .then((res) => {
                //     console.log(res);
                //   })
                //   .finally(() => {
                //     setButtonLoading2(false);
                //     setModal({
                //       Component: ActionDebouncer,
                //       dontClose: true,
                //     });
                //     // window.location.reload();
                //   });
              })
              .finally(() => {
                setButtonLoading2(false);
                setModal({
                  Component: ActionDebouncer,
                  dontClose: true,
                });
              });
          }, 3000);
        })
        .catch((e) => {
          // setButtonLoading2(false);
        });
    }
  }

  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonLoading2, setButtonLoading2] = useState(false);

  const [modal, setModal] = useState(null);

  console.log(EPP.Dimensions, getUserId(), "REVEWERDATA");

  // const isDisableSubmit =
  return Object.keys(AssessmentDocs).length === 0 ? (
    <>Loading...</>
  ) : (
    <Paper
      elevation={2}
      sx={{
        borderRadius: "0.5em",
        height: "100%",
        padding: "2em",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <Box
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        marginBottom={"1em"}
      >
        <Typography variant="h3" color={"primary"}>
          Annual Plan Scores
        </Typography>
        {TryOutlined && (
          <Box>
            <Typography variant="h2" color={"primary"}>
              {getFinalScore()}
            </Typography>
          </Box>
        )}
      </Box>
      {false && (
        <Box display={"flex"} justifyContent={"space-around"}>
          <Box textAlign={"center"}>
            <Typography variant="subtitle2" color={"grey"}>
              Final Score
            </Typography>
            {/* <Speedometer score={getFinalScore()} total={5} /> */}
          </Box>
        </Box>
      )}
      <Box display={"flex"} flexDirection={"column"} gap={"1em"}>
        {AggregatePlanScores.AggregatePlanScore === "E" &&
          (AggregatePlanScores.AggregateScoreDisplay === "A" ||
            (AggregatePlanScores.AggregateScoreDisplay === "R" &&
              getUserId() !== EPP.AppraiseeUserID)) && (
            <Box display={"flex"} gap={"1em"} alignItems={"center"}>
              <Typography variant="subtitle2" color={"grey"}>
                Aggregated Score
              </Typography>
              <Typography variant="subtitle1" color={"primary"}>
                {!getBeforeFinalPeriodCondition()
                  ? AggregatedPlanScore || "--.--"
                  : "--.--"}
              </Typography>
            </Box>
          )}
        {ModeratedPlanScores.ModeratedPlanScore === "E" && (
          <Box display={"flex"} gap={"1em"} alignItems={"center"}>
            <Typography variant="subtitle2" color={"grey"}>
              Moderated Score
            </Typography>
            <Typography variant="subtitle1" color={"primary"}>
              {!getBeforeFinalPeriodCondition()
                ? ModeratedPlanScore || "--.--"
                : "--.--"}
            </Typography>
          </Box>
        )}
        {AdjustedPlanScores.AdjustedPlanScore === "E" &&
          (AdjustedPlanScores.AdjustedPlanScoreDisplay === "A" ||
            (AdjustedPlanScores.AdjustedPlanScoreDisplay === "R" &&
              getUserId() !== EPP.AppraiseeUserID)) && (
            <Box display={"flex"} gap={"1em"} alignItems={"center"}>
              <Typography variant="subtitle2" color={"grey"}>
                Adjusted Score
              </Typography>
              <Typography variant="subtitle1" color={"primary"}>
                {!getBeforeFinalPeriodCondition()
                  ? AdjustedPlanScore || "--.--"
                  : "--.--"}
              </Typography>
            </Box>
          )}
        {AssessorPlanScores.AssessorPlanScore === "E" &&
          getUserId() !== EPP.AppraiseeUserID && (
            <Box display={"flex"} gap={"1em"} alignItems={"center"}>
              <Typography variant="subtitle2" color={"grey"}>
                Assessor Score
              </Typography>
              <Typography variant="h3" color={"primary"}>
                {AssessorPlanScores.AssessorPlanRater === "A" &&
                Appraisers.every((val, i, arr) => val === arr[0]) &&
                Appraisers[0] === getUserId() ? (
                  AssessorRatingScale ? (
                    <ScoreSubmit />
                  ) : (
                    <>Loading...</>
                  )
                ) : AssessorPlanScores.AssessorPlanRater === "R" &&
                  Reviewers.every((val, i, arr) => val === arr[0]) &&
                  Reviewers[0] === getUserId() ? (
                  AssessorRatingScale ? (
                    <ScoreSubmit
                      ratingScale={AssessorRatingScale}
                      initScore={AssessorPlanScore}
                      isLoading={buttonLoading}
                      handleTabEdit={handleTabEdit}
                      renderCondition={() => !getCondition({ type: undefined })}
                      onSubmit={(val) => {
                        handleTabEdit("save");
                        if (!val || val === -1 || val === "") {
                          setActionError({
                            type: 1,
                            message: "Please Select A Score",
                          });
                          return;
                        }
                        setButtonLoading(true);
                        editEPP(val)
                          .then(() => {
                            setActionError({ type: 0, message: "success" });
                          })
                          .finally(() => {
                            setButtonLoading(false);
                            window.location.reload();
                          });
                      }}
                    />
                  ) : (
                    <>Loading ...</>
                  )
                ) : AssessorPlanScores.AssessorPlanRater === "B" &&
                  EPP.AppraiseeUserID !== getUserId() ? (
                  AssessorRatingScale ? (
                    <ScoreSubmit />
                  ) : (
                    <>Loading...</>
                  )
                ) : false ? (
                  "--.--"
                ) : (
                  AssessorPlanScore || "--.--"
                )}
              </Typography>
              <br />
              {actionError && (
                <AlertMessage
                  {...actionError}
                  onClose={() => setActionError(null)}
                  autoClose={true}
                  autoCloseTime={3000}
                />
              )}
            </Box>
          )}
      </Box>

      <br />
      <Box bottom={"1em"} left={"2em"}>
        {EPP.Dimensions.filter((d) => d.ReviewerID === getUserId()).length >
          0 && (
          <Link
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setModal({
                Component: Dashboard,
                props: {
                  ReviewerID: getUserId(),
                },
              });
            }}
          >
            View Reviewer Score Distribution
          </Link>
        )}
        <br />
        {EPP.Dimensions.filter((d) => d.AppraiserID === getUserId()).length >
          0 && (
          <Link
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setModal({
                Component: Dashboard,
                props: {
                  AppraiserID: getUserId(),
                },
              });
            }}
          >
            View Appraiser Score Distribution
          </Link>
        )}
      </Box>
      <br />
      <Box bottom={"1em"} right={"1em"}>
        <Box display={"flex"} gap={"1em"}>
          {console.log("Allowed actions: ", AssessmentDocs)}
          {AssessmentDocs &&
            AssessmentDocs.AllowedActions &&
            AssessmentDocs.AllowedActions.map((action) => {
              let label = action;
              // if (action === "Return") label = "Return";
              // if()
              // else label = "Submit";
              return (
                <>
                  {AssessorPlanScores.AssessorPlanRater === "A" &&
                  Appraisers.every((val, i, arr) => val === arr[0]) &&
                  Appraisers[0] === getUserId() ? (
                    <Button
                      label={label}
                      isLoading={buttonLoading2}
                      size="small"
                      onClick={() => {
                        handleTabEdit("save");
                        onSubmit(action);
                      }}
                      title={label}
                      style={{ margin: 0 }}
                    />
                  ) : AssessorPlanScores.AssessorPlanRater === "R" &&
                    Reviewers.every((val, i, arr) => val === arr[0]) &&
                    Reviewers[0] === getUserId() ? (
                    getCondition({ type: undefined }) ? (
                      <Button
                        label={label}
                        isLoading={buttonLoading2}
                        size="small"
                        onClick={() => {
                          handleTabEdit("save");
                          onSubmit(action);
                        }}
                        title={label}
                        style={{ margin: 0 }}
                      />
                    ) : (
                      <></>
                    )
                  ) : AssessorPlanScores.AssessorPlanRater === "B" &&
                    EPP.AppraiseeUserID !== getUserId() ? (
                    <Button
                      label={label}
                      isLoading={buttonLoading2}
                      size="small"
                      onClick={() => {
                        handleTabEdit("save");
                        onSubmit(action);
                      }}
                      title={label}
                      style={{ margin: 0 }}
                    />
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
        </Box>
      </Box>
      {modal && (
        <EwModal
          onClose={() => {
            !modal.dontClose && setModal(null);
          }}
          open={modal}
        >
          <modal.Component {...modal.props} />
        </EwModal>
      )}
    </Paper>
  );
}

export default PlanScores;
