import { Box, IconButton, Paper, Typography, useTheme } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import StepAccordian from "../UI/StepAccordian";
import Atomic from "../../../../AComponent/Atomic";
import { Add, Delete } from "@mui/icons-material";
import { v4 as uuid } from "uuid";
import NumericInput from "../UI/NumericInput";
import EwModal from "../../../../MComponent/Modal/Modal";
import StepOwnerForm from "./StepOwnerForm";
import GroupDropdown from "../UI/GroupDropdown";
import MultiSelect from "../../../../AComponent/Dropdown/MultiSelect";
import { generateIntegerID } from "../../helpers";

const { Button, Dropdown } = Atomic;

const userTypeMap = {
  SU: "Subject User",
  TU: "Transaction User",
  LM: "Line Manager",
  FM: "Functional Manager",
  EH: "Business Unit Head",
};

const TATUnitMap = {
  MI: "Minutes",
  H: "Hours",
  D: "Days",
  W: "Weeks",
  MO: "Months",
  Y: "Years",
};

const ActionsMap = {
  IN: ["Submit", "Request", "Start"],
  AP: ["Approve", "Recommend", "Pay", "Verify"],
  DE: ["Deny", "Reject"],
  RP: ["Return to previous"],
  RI: ["Return to initiator"],
  RD: ["Redirect", "Delegate"],
};

const ConditionParametersMap = {
  Age: { label: "Age", type: "number", component: NumberComponent },
  Gender: { label: "Gender", type: "droplist", component: DroplistComponent },
  Tenure: { label: "Tenure", type: "number", component: NumberComponent },
  DataFields: {
    label: "DataFields",
    type: "datafields",
    component: DatafieldsComponent,
  },
  Applicability: {
    label: "Applicability",
    type: "applicability",
    component: ApplicabilityComponent,
  },
};

const ConditionOperatorsMap = {
  number: {
    EQ: "equal to",
    GT: "greater than",
    GE: "greater than or equal to",
    LT: "less than",
    LE: "less than or equal to",
    NE: "not equal to",
    BW: "between",
    NB: "not between",
  },
  date: {
    IS: "date is",
    BF: "date before",
    AF: "date after",
    BT: "date is between",
  },
  string: {
    EX: "exactly",
    CN: "contains",
    DC: "does not contain",
    SW: "starts with",
    EW: "ends with",
  },
  droplist: {
    AF: "is any of",
    NF: "is not any of",
  },
  applicability: {
    BT: "belongs to",
  },
};

function NumberComponent({ condition, setCondition, onChange, dataType }) {
  return (
    <Box display={"flex"} alignItems={"flex-end"} gap={"1em"}>
      <Typography variant="subtitle2">is</Typography>
      <Dropdown
        menuItems={[
          ...Object.entries(ConditionOperatorsMap[dataType]).map(
            ([key, value]) => {
              return { label: value, value: key };
            }
          ),
        ]}
        label={"Operator"}
        onChange={(val) => {
          setCondition((prevState) => {
            onChange({ ...prevState, ConditionOperator: val });
            return { ...prevState, ConditionOperator: val };
          });
        }}
        size={"small"}
        value={condition.ConditionOperator || -1}
        variant={"standard"}
      />
      {
        <>
          {["EQ", "GT", "GE", "LT", "LE", "NE"].includes(
            condition.ConditionOperator
          ) && (
            <>
              <NumericInput
                value={condition.ComparisonValueMin}
                onChange={(val) => {
                  setCondition((prev) => ({
                    ...prev,
                    ComparisonValueMin: val,
                  }));
                }}
                onBlur={() => {
                  onChange(condition);
                }}
              />
            </>
          )}
          {["BW", "NB"].includes(condition.ConditionOperator) && (
            <>
              <NumericInput
                value={condition.ComparisonValueMin}
                onChange={(val) => {
                  setCondition((prev) => ({
                    ...prev,
                    ComparisonValueMin: val,
                  }));
                }}
                onBlur={() => {
                  onChange(condition);
                }}
              />
              <Typography variant="subtitle2">and</Typography>
              <NumericInput
                value={condition.ComparisonValueMax}
                onChange={(val) => {
                  setCondition((prev) => ({
                    ...prev,
                    ComparisonValueMax: val,
                  }));
                }}
                onBlur={() => {
                  onChange(condition);
                }}
              />
            </>
          )}
        </>
      }
    </Box>
  );
}

function DroplistComponent({ condition, setCondition, onChange, dataType }) {
  return (
    <Box display={"flex"} alignItems={"flex-end"} gap={"1em"}>
      <Typography variant="subtitle2">is</Typography>
      <Dropdown
        menuItems={[
          ...Object.entries(ConditionOperatorsMap[dataType]).map(
            ([key, value]) => {
              return { label: value, value: key };
            }
          ),
        ]}
        label={"Operator"}
        onChange={(val) => {
          setCondition((prevState) => {
            onChange({ ...prevState, ConditionOperator: val });
            return { ...prevState, ConditionOperator: val };
          });
        }}
        value={condition.ConditionOperator || -1}
        size={"small"}
        variant={"standard"}
      />
      <MultiSelect
        menuItems={[
          ...Object.entries(ConditionOperatorsMap[dataType]).map(
            ([key, value]) => {
              return { label: value, value: key };
            }
          ),
        ]}
        label={"Operator"}
        onChange={(val) => {
          setCondition((prevState) => {
            onChange({ ...prevState, ComparisonList: val });
            return { ...prevState, ComparisonList: val };
          });
        }}
        value={condition.ComparisonList || -1}
        size={"small"}
        variant={"standard"}
      />
    </Box>
  );
}

function DatafieldsComponent() {
  return <></>;
}

function ApplicabilityComponent() {
  return <></>;
}

function Condition({ initCondition, onChange }) {
  const [condition, setCondition] = useState({
    ConditionID: initCondition.ConditionID || "",
    ConditionParameter: initCondition.ConditionParameter || "",
    ConditionOperator: initCondition.ConditionOperator || "",
    ComparisonValueMin: initCondition.ComparisonValueMin || "",
    ComparisonValueMax: initCondition.ComparisonValueMax || "",
    ComparisonList: initCondition.ComparisonList || [],
    Applicability: initCondition.Applicability || "",
  });

  const Component = !["", -1].includes(condition.ConditionParameter)
    ? ConditionParametersMap[condition.ConditionParameter].component
    : undefined;

  return (
    <Box display={"flex"} gap={"1em"} alignItems={"flex-end"}>
      <Typography variant="subtitle2">if</Typography>
      <Dropdown
        menuItems={[
          ...Object.entries(ConditionParametersMap).map(([key, value]) => {
            return { label: value.label, value: key };
          }),
        ]}
        label={"Condition"}
        onChange={(val) => {
          setCondition((prevState) => {
            const newState = { ...prevState, ConditionParameter: val };
            onChange(newState);
            return newState;
          });
        }}
        size={"small"}
        value={condition.ConditionParameter || -1}
        variant={"standard"}
      />
      {Component && (
        <Component
          condition={condition}
          setCondition={setCondition}
          onChange={onChange}
          dataType={ConditionParametersMap[condition.ConditionParameter].type}
        />
      )}
    </Box>
  );
}

function ScenarioCard({ initScenario, onBlurEvent }) {
  const [modal, setModal] = useState(null);
  const { palette } = useTheme();
  const [scenario, setScenario] = useState({
    ScenarioID: initScenario.ScenarioID || "",
    ScenarioName: initScenario.ScenarioName || "",
    StepOwnerType: initScenario.StepOwnerType || "",
    StepOwnerLevel: initScenario.StepOwnerLevel || "",
    Actions: initScenario.Actions || [],
    Conditions: initScenario.Conditions || [],
  });

  const picked = (initScenario.Actions || []).reduce((total, current) => {
    if (current.ActionID) {
      total[current.ActionID] = true;
    }
    return total;
  }, {});

  const [AutoAction, setAutoAction] = useState({
    TAT:
      initScenario.Actions.find((a) => a.AutoActionTime)?.AutoActionTime || "",
    TATUnit:
      initScenario.Actions.find((a) => a.AutoActionTimeUnit)
        ?.AutoActionTimeUnit || "",
  });

  return (
    <Box>
      <br />
      <Typography variant="subtitle1">
        Select any condition types that apply to this workflow as well as the
        step owner for these conditions
      </Typography>
      <br />
      <Box>
        {scenario.Conditions?.map((condition, index, arr) => {
          return (
            <Box key={condition.ConditionID}>
              <Box sx={{ display: "flex", alignItems: "flex-end", gap: "1em" }}>
                <Condition
                  initCondition={condition}
                  onChange={(val) => {
                    setScenario((prevState) => {
                      const newState = { ...prevState };
                      const { Conditions } = newState;

                      const index = Conditions.findIndex(
                        (c) => c.ConditionID === condition.ConditionID
                      );
                      if (index !== -1) {
                        Conditions[index] = val;
                      }

                      onBlurEvent({ ...newState, Conditions });
                      return { ...newState, Conditions };
                    });
                  }}
                />
                <IconButton
                  onClick={() => {
                    setScenario((prevState) => {
                      const { Conditions } = prevState;
                      const index = Conditions?.find(
                        (c) => c.ConditionID === condition.ConditionID
                      );
                      if (index !== -1) {
                        Conditions?.splice(index, 1);
                      }
                      onBlurEvent({ ...prevState, Conditions });
                      return { ...prevState, Conditions };
                    });
                  }}
                >
                  <Delete color="error" />
                </IconButton>
              </Box>
              <br />
              {index !== arr.length - 1 && (
                <Typography variant="subtitle1" color={"primary"}>
                  AND
                </Typography>
              )}
              <br />
            </Box>
          );
        })}
      </Box>
      <br />
      <Button
        variant={"text"}
        otherProps={{ endIcon: <Add /> }}
        onClick={() => {
          setScenario((prevState) => {
            const newState = { ...prevState };
            const { Conditions } = newState;

            const id = uuid();
            Conditions.push({
              ConditionID: id,
              if: "",
              is: "",
              value: [],
            });
            return {
              ...prevState,
              Conditions,
            };
          });
        }}
        title={"And"}
      />
      <Box>
        <Box display={"flex"} alignItems={"flex-end"} gap={"1em"}>
          <Typography variant="subtitle2">
            then the actions will be taken by the
          </Typography>
          <Box
            onClick={() => {
              setModal({
                Component: StepOwnerForm,
                props: {
                  closeForm: () => setModal(null),
                  UserType: scenario.StepOwnerType,
                  UserLevel: scenario.StepOwnerLevel,
                  onAdd: (user) => {
                    setScenario((prevState) => {
                      const newState = {
                        ...prevState,
                        StepOwnerType: user.UserType,
                        StepOwnerLevel: user.UserLevel,
                      };
                      onBlurEvent({ ...newState });
                      return newState;
                    });
                  },
                },
              });
            }}
            sx={{
              boxSizing: "border-box",
              borderBottom: "1px solid #afafaf",
              display: "flex",
              gap: "0.7em",
              cursor: "pointer",
              padding: "0.3em",
              alignItems: "center",
              ":hover": {
                borderBottom: "1px solid black",
                backgroundColor: "rgba(0, 0, 0, 0.05)",
              },
            }}
          >
            <Typography variant="body1" sx={{ color: "#757575" }}>
              {scenario.StepOwnerType
                ? userTypeMap[scenario.StepOwnerType] +
                  " " +
                  (scenario.StepOwnerLevel || "")
                : "Step Owner"}
            </Typography>
            <Add fontSize="small" sx={{ color: "#757575" }} />
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "1em", alignItems: "flex-end" }}>
          <Typography variant="subtitle2" fontWeight={"400"}>
            which will be
          </Typography>
          {scenario.Actions.map((action) => {
            return (
              <Box
                key={action.ActionID}
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  gap: "0.3em",
                }}
              >
                <GroupDropdown
                  menuItems={[
                    ...Object.entries(ActionsMap)
                      .map(([key, value]) => {
                        return picked[key] && action.ActionID !== key
                          ? undefined
                          : {
                              GroupName: key,
                              Items: value.map((v) => ({ label: v, value: v })),
                            };
                      })
                      .filter((e) => e),
                  ]}
                  label={"Action"}
                  onChange={(val) => {
                    setScenario((prevState) => {
                      const { Actions } = prevState;

                      const index = Actions.findIndex(
                        (a) => a.ActionID === action.ActionID
                      );
                      if (index !== -1) {
                        Actions[index].ActionLabel = val;
                        const entries = Object.entries(ActionsMap);
                        for (let i = 0; i < entries.length; i++) {
                          const [key, value] = entries[i];

                          if (value.includes(val)) {
                            Actions[index].ActionID = key;
                            picked[key] = true;
                            break;
                          }
                        }
                      }
                      const newState = {
                        ...prevState,
                        Actions: [...Actions],
                      };
                      onBlurEvent(newState);
                      return newState;
                    });
                  }}
                  size={"small"}
                  value={action.ActionLabel}
                  variant={"standard"}
                />
                <IconButton
                  color="error"
                  onClick={() => {
                    setScenario((prevState) => {
                      const newState = { ...prevState };
                      const { Actions: newActions } = newState;
                      const index = newActions.findIndex(
                        (a) => a.ActionID === action.ActionID
                      );
                      console.log(newActions, action, "THIOS TRIGEREDSHAPOE");
                      if (index !== -1) {
                        const ID = newActions[index].ActionID;
                        delete picked[ID];
                        newActions.splice(index, 1);
                      }
                      onBlurEvent({ ...newState, Actions: newActions });
                      return { ...newState, Actions: newActions };
                    });
                  }}
                  size="small"
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Box>
            );
          })}

          {Object.keys(picked).length < Object.keys(ActionsMap).length && (
            <IconButton
              onClick={() => {
                setScenario((prevState) => {
                  const newState = { ...prevState };
                  const { Actions } = newState;

                  newState.Actions = [
                    ...Actions,
                    {
                      ActionID: uuid(),
                      ActionLabel: "",
                      AutoAction: "",
                      AutoActionTime: "",
                      AutoActionTimeUnit: "",
                      ActionConversation: "",
                      Alerts: "D",
                      Notifications: [null, null, null, null],
                    },
                  ];
                  onBlurEvent(newState);
                  return newState;
                });
              }}
              size="small"
            >
              <Add fontSize="small" />
            </IconButton>
          )}
        </Box>
        <Box sx={{ display: "flex", gap: "1em", alignItems: "flex-end" }}>
          <Typography variant="subtitle2" fontWeight={"400"}>
            If the Step Owner hasn’t taken action in
          </Typography>
          <NumericInput
            value={AutoAction.TAT}
            onChange={(val) => {
              setAutoAction((prevState) => {
                const index = scenario.Actions.findIndex(
                  (action) => action.AutoActionTime
                );
                if (index !== -1) {
                  const Actions = scenario.Actions;
                  Actions[index].AutoActionTime = val;
                  onBlurEvent({ ...scenario, Actions: [...Actions] });
                }
                return { ...prevState, TAT: val };
              });
            }}
          />
          ,
          <Dropdown
            menuItems={[
              ...Object.entries(TATUnitMap).map(([key, value]) => ({
                label: value,
                value: key,
              })),
            ]}
            label={"Range"}
            onChange={(val) => {
              setAutoAction((prevState) => {
                const index = scenario.Actions.findIndex(
                  (action) => action.AutoActionTime
                );
                if (index !== -1) {
                  const Actions = scenario.Actions;
                  Actions[index].AutoActionTimeUnit = val;
                  onBlurEvent({ ...scenario, Actions: [...Actions] });
                }
                return { ...prevState, TATUnit: val };
              });
            }}
            size={"small"}
            value={AutoAction.TATUnit || -1}
            variant={"standard"}
          />
          ,
          <Typography variant="subtitle2" fontWeight={"400"}>
            assume the action
          </Typography>
          {scenario.Actions?.length ? (
            AutoAction.TAT &&
            AutoAction.TATUnit &&
            AutoAction.TATUnit !== -1 ? (
              <Dropdown
                menuItems={[
                  ...scenario.Actions.map((action, index) => ({
                    label: action.ActionLabel,
                    value: index.toString(),
                  })),
                ]}
                label={"Action"}
                onChange={(val) => {
                  setScenario((prevState) => {
                    const { Actions } = prevState;
                    const index = Actions.findIndex(
                      (a) => a.AutoAction === "E"
                    );
                    console.log(index, Actions, "THESE IS THE SHIT");
                    if (index !== -1) {
                      Actions[index].AutoAction = "D";
                      Actions[index].AutoActionTime = "";
                      Actions[index].AutoActionTimeUnit = "";
                    }

                    val = parseInt(val);
                    if (val !== -1) {
                      Actions[val].AutoAction = "E";
                      Actions[val].AutoActionTime = AutoAction.TAT;
                      Actions[val].AutoActionTimeUnit = AutoAction.TATUnit;
                      onBlurEvent({ ...prevState, Actions: [...Actions] });
                    }
                    return { ...prevState, Actions: [...Actions] };
                  });
                }}
                size={"small"}
                value={scenario.Actions.findIndex(
                  (action) => action.AutoActionTime
                )}
                variant={"standard"}
              />
            ) : (
              <Typography
                variant="subtitle2"
                sx={{ color: `${palette.error.main}` }}
              >
                Please enter the time and its unit
              </Typography>
            )
          ) : (
            <Typography
              variant="subtitle2"
              sx={{ color: `${palette.error.main}` }}
            >
              No Actions defined
            </Typography>
          )}
        </Box>
      </Box>
      {modal && (
        <EwModal onClose={() => setModal(null)} open={modal}>
          <modal.Component {...modal.props} />
        </EwModal>
      )}
    </Box>
  );
}

function DefaultScenarioCard({ initDefaultScenario, onBlurEvent }) {
  const [modal, setModal] = useState(null);
  const [defaultScenario, setDefaultScenario] = useState({
    ScenarioID: initDefaultScenario.ScenarioID || "",
    ScenarioName: initDefaultScenario.ScenarioName || "",
    StepOwnerType: initDefaultScenario.StepOwnerType || "",
    StepOwnerLevel: initDefaultScenario.StepOwnerLevel || "",
    Actions: initDefaultScenario.Actions || [],
  });

  const { palette } = useTheme();

  const picked = (initDefaultScenario.Actions || []).reduce(
    (total, current) => {
      if (current.ActionID) {
        total[current.ActionID] = true;
      }
      return total;
    },
    {}
  );

  console.log(picked, "PICKEDFRUIT");

  const [AutoAction, setAutoAction] = useState({
    TAT:
      initDefaultScenario.Actions.find((a) => a.AutoActionTime)
        ?.AutoActionTime || "",
    TATUnit:
      initDefaultScenario.Actions.find((a) => a.AutoActionTimeUnit)
        ?.AutoActionTimeUnit || "",
  });

  return (
    <Paper elevation={2} sx={{ boxSizing: "border-box" }}>
      <Box display={"flex"} alignItems={"flex-end"} gap={"1em"}>
        <Typography variant="subtitle2" fontWeight={"600"}>
          By default,
        </Typography>
        <Typography variant="subtitle2" fontWeight={"400"}>
          The actions will be taken by the
        </Typography>
        <Box
          onClick={() => {
            setModal({
              Component: StepOwnerForm,
              props: {
                UserType: defaultScenario.StepOwnerType,
                UserLevel: defaultScenario.StepOwnerLevel,
                closeForm: () => setModal(null),
                onAdd: (user) => {
                  setDefaultScenario((prevState) => {
                    const newState = {
                      ...prevState,
                      StepOwnerType: user.UserType,
                      StepOwnerLevel: user.UserLevel,
                    };
                    onBlurEvent({ ...newState });
                    return newState;
                  });
                },
              },
            });
          }}
          sx={{
            boxSizing: "border-box",
            borderBottom: "1px solid #afafaf",
            display: "flex",
            gap: "0.7em",
            cursor: "pointer",
            padding: "0.3em",
            alignItems: "center",
            ":hover": {
              borderBottom: "1px solid black",
              backgroundColor: "rgba(0, 0, 0, 0.05)",
            },
          }}
        >
          <Typography variant="body1" sx={{ color: "#757575" }}>
            {defaultScenario.StepOwnerType
              ? userTypeMap[defaultScenario.StepOwnerType] +
                " " +
                (defaultScenario.StepOwnerLevel || "")
              : "Step Owner"}
          </Typography>
          <Add fontSize="small" sx={{ color: "#757575" }} />
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "1em", alignItems: "flex-end" }}>
        <Typography variant="subtitle2" fontWeight={"400"}>
          which will be
        </Typography>
        {defaultScenario.Actions.map((action) => {
          return (
            <Box
              key={action.ActionID}
              sx={{
                display: "flex",
                alignItems: "flex-end",
                gap: "0.3em",
              }}
            >
              <GroupDropdown
                menuItems={[
                  ...Object.entries(ActionsMap)
                    .map(([key, value]) => {
                      return picked[key] && action.ActionID !== key
                        ? undefined
                        : {
                            GroupName: key,
                            Items: value.map((v) => ({ label: v, value: v })),
                          };
                    })
                    .filter((e) => e),
                ]}
                label={"Action"}
                onChange={(val) => {
                  setDefaultScenario((prevState) => {
                    const { Actions } = prevState;

                    const index = Actions.findIndex(
                      (a) => a.ActionID === action.ActionID
                    );
                    if (index !== -1) {
                      Actions[index].ActionLabel = val;
                      const entries = Object.entries(ActionsMap);
                      for (let i = 0; i < entries.length; i++) {
                        const [key, value] = entries[i];

                        if (value.includes(val)) {
                          Actions[index].ActionID = key;
                          picked[key] = true;
                          break;
                        }
                      }
                    }
                    const newState = {
                      ...prevState,
                      Actions: [...Actions],
                    };
                    onBlurEvent(newState);
                    return newState;
                  });
                }}
                size={"small"}
                value={action.ActionLabel}
                variant={"standard"}
              />
              <IconButton
                color="error"
                onClick={() => {
                  setDefaultScenario((prevState) => {
                    const newState = { ...prevState };
                    const { Actions: newActions } = newState;
                    const index = newActions.findIndex(
                      (a) => a.ActionID === action.ActionID
                    );
                    console.log(newActions, action, "THIOS TRIGEREDSHAPOE");
                    if (index !== -1) {
                      const ID = newActions[index].ActionID;
                      delete picked[ID];
                      newActions.splice(index, 1);
                    }
                    onBlurEvent({ ...newState, Actions: newActions });
                    return { ...newState, Actions: newActions };
                  });
                }}
                size="small"
              >
                <Delete fontSize="small" />
              </IconButton>
            </Box>
          );
        })}

        {Object.keys(picked).length < Object.keys(ActionsMap).length && (
          <IconButton
            onClick={() => {
              setDefaultScenario((prevState) => {
                const newState = { ...prevState };
                const { Actions } = newState;

                newState.Actions = [
                  ...Actions,
                  {
                    ActionID: uuid(),
                    ActionLabel: "",
                    AutoAction: "",
                    AutoActionTime: "",
                    AutoActionTimeUnit: "",
                    ActionConversation: "",
                    Alerts: "D",
                    Notifications: [null, null, null, null],
                  },
                ];
                onBlurEvent(newState);
                return newState;
              });
            }}
            size="small"
          >
            <Add fontSize="small" />
          </IconButton>
        )}
      </Box>
      <Box sx={{ display: "flex", gap: "1em", alignItems: "flex-end" }}>
        <Typography variant="subtitle2" fontWeight={"400"}>
          If the Step Owner hasn’t taken action in
        </Typography>
        <NumericInput
          value={AutoAction.TAT}
          onChange={(val) => {
            setAutoAction((prevState) => {
              const index = defaultScenario.Actions.findIndex(
                (action) => action.AutoActionTime
              );
              if (index !== -1) {
                const Actions = defaultScenario.Actions;
                Actions[index].AutoActionTime = val;
                onBlurEvent({ ...defaultScenario, Actions: [...Actions] });
              }
              return { ...prevState, TAT: val };
            });
          }}
        />
        ,
        <Dropdown
          menuItems={[
            ...Object.entries(TATUnitMap).map(([key, value]) => ({
              label: value,
              value: key,
            })),
          ]}
          label={"Range"}
          onChange={(val) => {
            setAutoAction((prevState) => {
              const index = defaultScenario.Actions.findIndex(
                (action) => action.AutoActionTime
              );
              if (index !== -1) {
                const Actions = defaultScenario.Actions;
                Actions[index].AutoActionTimeUnit = val;
                onBlurEvent({ ...defaultScenario, Actions: [...Actions] });
              }
              return { ...prevState, TATUnit: val };
            });
          }}
          size={"small"}
          value={AutoAction.TATUnit || -1}
          variant={"standard"}
        />
        ,
        <Typography variant="subtitle2" fontWeight={"400"}>
          assume the action
        </Typography>
        {defaultScenario.Actions?.length ? (
          AutoAction.TAT && AutoAction.TATUnit && AutoAction.TATUnit !== -1 ? (
            <Dropdown
              menuItems={[
                ...defaultScenario.Actions.map((action, index) => ({
                  label: action.ActionLabel,
                  value: index.toString(),
                })),
              ]}
              label={"Action"}
              onChange={(val) => {
                setDefaultScenario((prevState) => {
                  const { Actions } = prevState;
                  const index = Actions.findIndex((a) => a.AutoAction === "E");
                  console.log(index, Actions, "THESE IS THE SHIT");
                  if (index !== -1) {
                    Actions[index].AutoAction = "D";
                    Actions[index].AutoActionTime = "";
                    Actions[index].AutoActionTimeUnit = "";
                  }

                  val = parseInt(val);
                  if (val !== -1) {
                    Actions[val].AutoAction = "E";
                    Actions[val].AutoActionTime = AutoAction.TAT;
                    Actions[val].AutoActionTimeUnit = AutoAction.TATUnit;
                    onBlurEvent({ ...prevState, Actions: [...Actions] });
                  }
                  return { ...prevState, Actions: [...Actions] };
                });
              }}
              size={"small"}
              value={defaultScenario.Actions.findIndex(
                (action) => action.AutoActionTime
              )}
              variant={"standard"}
            />
          ) : (
            <Typography
              variant="subtitle2"
              sx={{ color: `${palette.error.main}` }}
            >
              Please enter the time and its unit
            </Typography>
          )
        ) : (
          <Typography
            variant="subtitle2"
            sx={{ color: `${palette.error.main}` }}
          >
            No Actions defined
          </Typography>
        )}
      </Box>
      {modal && (
        <EwModal onClose={() => setModal(null)} open={modal}>
          <modal.Component {...modal.props} />
        </EwModal>
      )}
    </Paper>
  );
}

function Actions({ StepTAT, StepTATUnit, StepScenarios, updateState }) {
  const DefaultScenarioData = useMemo(() => {
    return StepScenarios.find((scenario) => scenario.ScenarioID === "000");
  }, [StepScenarios]);

  const RestScenarios = useMemo(() => {
    return StepScenarios.filter((s) => s.ScenarioID !== "000");
  }, [StepScenarios]);

  const saveScenarios = useCallback(
    (scenario) => {
      const index = StepScenarios.findIndex(
        (s) => s.ScenarioID === scenario.ScenarioID
      );

      if (index !== -1) {
        StepScenarios[index] = scenario;
        console.log(StepScenarios, "STEPSCENARIOS");
        updateState(StepScenarios, "StepScenarios");
      }
      return;
    },
    [StepScenarios, updateState]
  );

  const [TAT, setTAT] = useState({
    StepTAT: StepTAT || "",
    StepTATUnit: StepTATUnit || "",
  });

  return (
    <Box position={"relative"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.6em",
          padding: "0.5em",
        }}
        maxHeight={"60vh"}
        className="invisble-scroll"
      >
        <Box display={"flex"} gap={"1em"} alignItems={"flex-end"}>
          <Typography variant="subtitle2">
            The step is expected to be completed in
          </Typography>
          <NumericInput
            value={TAT.StepTAT}
            onChange={(val) => {
              setTAT((prevState) => ({ ...prevState, StepTAT: val }));
            }}
            onBlur={() => {
              updateState(TAT.StepTAT, "StepTAT");
            }}
          />
          <Dropdown
            label={"Time"}
            menuItems={[
              ...Object.entries(TATUnitMap).map(([key, value]) => ({
                label: value,
                value: key,
              })),
            ]}
            onChange={(val) => {
              setTAT((prevState) => {
                const newState = { ...prevState, StepTATUnit: val };
                updateState(val, "StepTATUnit");
                return newState;
              });
            }}
            value={TAT.StepTATUnit || -1}
            variant={"standard"}
            size={"small"}
          />
        </Box>
        <DefaultScenarioCard
          initDefaultScenario={DefaultScenarioData}
          onBlurEvent={saveScenarios}
        />
        <Typography variant="subtitle1">Add conditional scenarios:</Typography>
        {RestScenarios?.length === 0 ? (
          <></>
        ) : (
          RestScenarios?.map((scenario) => {
            return (
              <StepAccordian
                Name={scenario.ScenarioName}
                onNameChange={() => {}}
                key={scenario.ScenarioID}
              >
                <ScenarioCard
                  initScenario={scenario}
                  onBlurEvent={saveScenarios}
                />
              </StepAccordian>
            );
          })
        )}
      </Box>
      <br />
      <Button
        style={{ position: "absolute", bottom: "-1.5em", left: 0 }}
        variant={"text"}
        otherProps={{ endIcon: <Add /> }}
        onClick={() => {
          updateState(
            [
              ...StepScenarios,
              {
                ScenarioID:
                  StepScenarios?.length === 1
                    ? "001"
                    : generateIntegerID(
                        StepScenarios[StepScenarios?.length - 1].ScenarioID
                      ),
                ScenarioName: `Scenario ${StepScenarios.length}`,
                StepOwnerType: "",
                StepOwnerLevel: "",
                Actions: [],
              },
            ],
            "StepScenarios"
          );
        }}
        title={"Scenario"}
      />
      <Typography variant="subtitle2">
        Else, assume the <b>default action</b>
      </Typography>
      <br />
    </Box>
  );
}

export default Actions;
