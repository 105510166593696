import {
  Autocomplete,
  Box,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
//import { ConfidentialDocument } from "./Dimension/Data/ConfidentialData";
import { isEnabled } from "./Dimension/Helpers/helper";
import Atomic from "../../AComponent/Atomic";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import FeatureIDs from "../../Utils/Common/FeatureIDs";
import { getData, getUserId, submitPayload } from "../../Utils/Common/Library";
import AlertMessage from "../../Utils/Messages/AlertMessage";
import { useDispatch } from "react-redux";
import { Actions } from "../../Redux/Actions/Actiontypes";
import SidePanel from "../../SidePanel";
import { getFinalPeriod } from "./Dimension/Phases/PeriodMap";
import { WorkflowProcesser } from "./Dimension/WorkflowProcesser";
const { Input, Dropdown, Radio, Button } = Atomic;

const map = {
  M: true,
  O: true,
  D: false,
};

function PerformanceCard({ PerformanceSummary, onChange, disable }) {
  const theme = useTheme();
  return (
    <Paper>
      <Typography variant="h2" color={`${theme.palette.primary.light}`}>
        Performance
      </Typography>
      <br />
      <Typography variant="subtitle2">
        Performance Summary for the Appraisee
      </Typography>
      <Input
        onChange={(val) => {
          onChange(val, "PerformanceSummary");
        }}
        label={"Enter Summary"}
        rows={"4"}
        value={PerformanceSummary}
        styles={{ width: "100%", margin: "1em 0 0 0" }}
        disable={disable}
      />
    </Paper>
  );
}

function PotentialCard({ PotentialSummary, onChange, disable }) {
  const theme = useTheme();
  return (
    <Paper>
      <Typography variant="h2" color={`${theme.palette.primary.light}`}>
        Potential
      </Typography>
      <br />
      <Typography variant="subtitle2">
        Potential Summary for the Appraisee
      </Typography>
      <Input
        onChange={(val) => {
          onChange(val, "PotentialSummary");
        }}
        label={"Enter Summary"}
        rows={"4"}
        value={PotentialSummary}
        styles={{ width: "100%", margin: "1em 0 0 0" }}
        disable={disable}
      />
    </Paper>
  );
}

function GrowthReadinessCard({
  GrowthReadiness,
  GrowthRoleID,
  GrowthReadinessAssessmentOptions,
  onChange,
  disable,
}) {
  const theme = useTheme();
  const [roles, setRoles] = useState();
  const [selectedRole, setSelectedRole] = useState();
  useEffect(() => {
    getData({ featureId: FeatureIDs.Role }).then((res) => {
      if (typeof res === "string" || !res.Data) return;
      setRoles(
        res.Data.map((role) => {
          const item = {
            label: role.RoleName,
            TransactionID: role.TransactionID,
          };
          if (item.TransactionID === GrowthRoleID) {
            setSelectedRole(item);
          }
          return item;
        })
      );
    });
  }, []);
  return !roles ? (
    <>Loading ...</>
  ) : (
    <Paper>
      <Typography variant="h2" color={`${theme.palette.primary.light}`}>
        Growth Readiness
      </Typography>
      <br />
      <Typography variant="subtitle2">
        How would you rate the appraissee's readiness to grow into a new role?
      </Typography>
      <Box display={"flex"} gap={"3px"}>
        <Radio
          onChange={(value) => {
            onChange(value, "GrowthReadiness");
          }}
          value={GrowthReadiness}
          menuItems={[...GrowthReadinessAssessmentOptions].map((option) => ({
            label: option.ID,
            value: option.ID,
          }))}
          disable={disable}
        />
      </Box>
      <br />
      <Box display={"flex"} gap={"3em"}>
        <Box>
          <Typography variant="subtitle2">
            What role can the appraisee grow into?
          </Typography>
          {/* <Dropdown
            label={"Select Role"}
            onChange={(value) => {
              onChange(value, "GrowthRoleID");
            }}
            value={GrowthRoleID}
            featureId={FeatureIDs.Role}
            mapper={{ label: "RoleName", value: "RoleID" }}
            // menuItems={[...PromotionAssessmentOptions].map((option) => ({
            //   label: option.ID,
            //   value: option.ID,
            // }))}
            size={"small"}
            styles={{ margin: "10px 0 0 0", width: "80%" }}
            disable={disable}
          /> */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            options={[...roles]}
            sx={{ marginTop: "10px", width: "80%" }}
            disabled={disable}
            onChange={(event, newValue) => {
              onChange(newValue ? newValue.TransactionID : "", "GrowthRoleID");
              setSelectedRole(newValue);
            }}
            value={selectedRole}
            renderInput={(params) => <TextField {...params} label="Role" />}
          />
        </Box>
      </Box>
    </Paper>
  );
}

function PromotionAssessment({
  PromotionRecommendation,
  PromotionAssessmentOptions,
  disable,
  onChange,
}) {
  const theme = useTheme();
  return (
    <Paper>
      <Typography variant="h2" color={`${theme.palette.primary.light}`}>
        Promotion Assessment
      </Typography>
      <br />
      <Typography variant="subtitle2">
        Would you recommend the appraissee for a promotion?
      </Typography>
      <Box display={"flex"} gap={"3px"}>
        <Radio
          onChange={(val) => {
            onChange(val, "PromotionRecommendation");
          }}
          value={PromotionRecommendation}
          menuItems={[...PromotionAssessmentOptions].map((option) => ({
            label: option.ID,
            value: option.ID,
          }))}
          disable={disable}
        />
      </Box>
    </Paper>
  );
}

function ExitRiskCard({ ExitRisk, ExitAssessmentOptions, onChange, disable }) {
  const theme = useTheme();
  return (
    <Paper>
      <Typography variant="h2" color={`${theme.palette.primary.light}`}>
        Exit Risk
      </Typography>
      <br />
      <Typography variant="subtitle2">
        How likely is this appraissee to leave?
      </Typography>
      <Box display={"flex"} gap={"3px"}>
        <Radio
          onChange={(value) => {
            onChange(value, "ExitRisk");
          }}
          value={ExitRisk}
          menuItems={[...ExitAssessmentOptions].map((option) => ({
            label: option.ID,
            value: option.ID,
          }))}
          disable={disable}
        />
      </Box>
    </Paper>
  );
}

const Confidential = ({ EPP, handleTabEdit }) => {
  const [AssessmentDocs, setAssessmentDocs] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: Actions.ADD_SIDE_COMPONENT,
      payload: {
        Component: SidePanel,
        props: {
          ViewName: "Confidential",
          PlanStartDate: EPP.PlanStartDate,
          PlanEndDate: EPP.PlanEndDate,
          // PlanStage: DimensionPeriodData.DimensionPhase,
          WorkflowUsers: [{ label: "Appraisee", value: EPP.AppraiseeUserID }],
        },
      },
    });
  }, []);
  useEffect(() => {
    if (EPP.Dimensions.length === 2) {
      getDimensionAssessmentDocs(EPP.Dimensions[0].DimensionID).then((res1) => {
        getDimensionAssessmentDocs(EPP.Dimensions[1].DimensionID).then(
          (res2) => {
            if (typeof res2 === "string" || !res2.Data) {
              setAssessmentDocs({ [EPP.Dimensions[1].DimensionID]: [] });
            } else {
              setAssessmentDocs({
                [EPP.Dimensions[0].DimensionID]:
                  typeof res1 !== "string" && res1.Data
                    ? [...res1.Data]
                    : undefined,
                [EPP.Dimensions[1].DimensionID]:
                  typeof res1 !== "string" && res1.Data
                    ? [...res2.Data]
                    : undefined,
              });
            }
          }
        );
      });
    }
  }, []);
  function getDimensionAssessmentDocs(DimensionID) {
    return getData({
      featureId: FeatureIDs.ParameterAsseessment,
      payload: {
        "Data.AppraiseeUserID": EPP.AppraiseeUserID,
        "Data.DimensionID": DimensionID,
        "Data.EmployeePerformancePlanID": EPP.TransactionID,
      },
      fields: ["Header.*", "Data.*", "Workflow.*"],
    });
  }

  function getCondition() {
    const id = EPP.Dimensions[0].DimensionID;
    const id2 = EPP.Dimensions[1].DimensionID;
    const assessments = AssessmentDocs[id] || AssessmentDocs[id2];

    const Appraisers = EPP.Dimensions.map((d) => d.AppraiserID);

    if (!assessments || assessments.length === 0) return false;

    const PeriodType =
      assessments[0].ReviewPeriodType === "I"
        ? assessments[0].ReviewPeriodUnit.charAt(0)
        : assessments[0].ReviewPeriodType;
    const PeriodUnit = getFinalPeriod(PeriodType);

    const doc = assessments.find(
      (a) =>
        a.ReviewPeriodType === PeriodType && a.ReviewPeriodUnit === PeriodUnit
    );

    if (!doc) return false;

    const WorkflowDoc = {
      ConversationID: "",
      WorkflowID: "",
      WorkflowStatus: "",
      WorkflowSteps: "",
    };

    const Workflow = Object.keys(WorkflowDoc).reduce((total, current) => {
      total[current] = doc[current];
      return total;
    }, {});

    const workflowProcesser = new WorkflowProcesser(Workflow);

    const CurrentStep = workflowProcesser.getCurrentStepData();
    if (!CurrentStep) return false;

    const StepOwnerUserID = workflowProcesser.getCurrentStepUserID();

    const { StepOwnerLabel } = CurrentStep;

    return StepOwnerLabel === "Appraiser" && getUserId() === StepOwnerUserID;
  }

  console.log(JSON.stringify(EPP), "EPP LOGGED");
  const [document, setDocument] = useState(null);
  const [actionError, setActionError] = useState(false);
  const [payload, setPayload] = useState(null);

  function onChange(value, type) {
    handleTabEdit();
    setPayload((prevState) => ({ ...prevState, [type]: value }));
  }

  useEffect(() => {
    getData({
      featureId: FeatureIDs.MasterPerformancePlan,
      payload: {
        "Header.TransactionID": EPP.MasterPerformancePlanID,
      },
    }).then((res) => {
      if (res && res.Data) {
        console.log("From eppdata: ", res);
        const {
          ConfidentialLabel: SectionLabel,
          ConfidentialDescription: SectionDescription,
          ConfidentialPerformanceSummary,
          ConfidentialPotentialSummary,
          GrowthRoleAssessment,
          GrowthRoleAssessmentOptions: GrowthRoleReadinessOptions,
          PromotionAssessment,
          PromotionAssessmentOptions,
          ExitAssessment,
          ExitAssessmentOptions,
        } = res.Data[0];

        setDocument({
          Section: "E",
          SectionLabel,
          SectionDescription,
          GrowthRoleReadinessOptions: GrowthRoleReadinessOptions
            ? GrowthRoleReadinessOptions
            : [],
          PromotionAssessment,
          PromotionAssessmentOptions: PromotionAssessmentOptions
            ? PromotionAssessmentOptions
            : [],
          ExitAssessment,
          ExitAssessmentOptions: ExitAssessmentOptions
            ? ExitAssessmentOptions
            : [],
          GrowthRoleAssessment,
          PerformanceSummary: ConfidentialPerformanceSummary,
          PotentialSummary: ConfidentialPotentialSummary,
        });
        getData({
          featureId: FeatureIDs.ConfidentialAssessment,
          payload: {
            "Data.AppraiseeUserID": EPP.AppraiseeUserID,
            "Data.EmployeePlanID": EPP.TransactionID,
          },
        }).then((res2) => {
          if (res2 && res2?.Data) setPayload(res2.Data[0]);
        });
      }
    });
  }, []);

  console.log(JSON.stringify(document), "Miracle.js");

  function onSaveAssessment() {
    const fields = [
      { doc: "PerformanceSummary", field: "PerformanceSummary" },
      { doc: "PotentialSummary", field: "PotentialSummary" },
      { doc: "GrowthRoleAssessment", field: "GrowthReadiness" },
      { doc: "GrowthRoleAssessment", field: "GrowthRoleID" },
      { doc: "PromotionAssessment", field: "PromotionRecommendation" },
      { doc: "ExitAssessment", field: "ExitRisk" },
    ];

    for (let i = 0; i < fields.length; i++) {
      if (document[fields[i].doc] === "M" && !payload[fields[i].field]) {
        setActionError({ type: 1, message: `${fields[i].field} is mandatory` });
        return;
      }
    }
    const FINAL_PAYLOAD = {
      EmployeePlanID: payload.EmployeePlanID,
      PerformancePlanID: payload.PerformancePlanID,
      AppraiseeUserID: payload.AppraiseeUserID,
      AppraiserUserID: getUserId(),
      PerformanceSummary: payload.PerformanceSummary,
      PotentialSummary: payload.PotentialSummary,
      GrowthReadiness: payload.GrowthReadiness,
      GrowthRoleID: payload.GrowthRoleID,
      PromotionRecommendation: payload.PromotionRecommendation,
      ExitRisk: payload.ExitRisk,
    };

    const edit = {
      TransactionID: payload.TransactionID,
      EffectiveFromTimestamp: payload.EffectiveFromTimestamp,
      RequestType: "1",
    };

    submitPayload({
      featureId: FeatureIDs.ConfidentialAssessment,
      payload: FINAL_PAYLOAD,
      edit,
    })
      .then((res) => {
        setActionError({ type: 0, message: "Success" });
      })
      .catch((e) => {
        setActionError({ type: 1, message: "Something went wrong" });
      })
      .finally(() => {});
    return;
  }

  return false ? (
    <div className="Flex" style={{ alignItems: "normal" }}>
      <Typography variant="h4" sx={{ margin: "0.5em 0" }}>
        {document.SectionLabel}
      </Typography>
      <Divider />
      <Typography variant="body1" sx={{ margin: "0.5em 0" }}>
        {document.SectionDescription}
      </Typography>
      <div className="Flex-row" style={{ gap: "1em", margin: "3em 0 0 0" }}>
        {map[document.GrowthRoleAssessment] && (
          <div style={{ flex: 1 }}>
            <Dropdown
              label={"Growth Role Readiness"}
              menuItems={document.GrowthRoleReadinessOptions}
              onChange={() => {}}
            />
          </div>
        )}
        {map[document.PromotionAssessment] && (
          <div style={{ flex: 1 }}>
            <Dropdown
              label={"Promotion Assessment"}
              menuItems={document.PromotionAssessmentOptions}
              onChange={() => {}}
            />
          </div>
        )}
        {map[document.ExitAssessment] && (
          <div style={{ flex: 1 }}>
            <Dropdown
              label={"Exit Assessment"}
              menuItems={document.ExitAssessmentOptions}
              onChange={() => {}}
            />
          </div>
        )}
      </div>
    </div>
  ) : !document || !payload || Object.keys(AssessmentDocs).length === 0 ? (
    <>Loading ...</>
  ) : isEnabled(document.Section) ? (
    <Paper sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
      {console.log(payload, !document && !payload, "HTML PAYLOAD")}
      {map[document.PerformanceSummary] && (
        <PerformanceCard
          PerformanceSummary={payload.PerformanceSummary}
          onChange={onChange}
          disable={!getCondition()}
        />
      )}
      {map[document.PotentialSummary] && (
        <PotentialCard
          PotentialSummary={payload.PotentialSummary}
          onChange={onChange}
          disable={!getCondition()}
        />
      )}
      {map[document.GrowthRoleAssessment] && (
        <GrowthReadinessCard
          GrowthReadiness={payload.GrowthReadiness}
          GrowthRoleID={payload.GrowthRoleID}
          PromotionAssessmentEnabled={document.PromotionAssessment}
          GrowthReadinessAssessmentOptions={document.GrowthRoleReadinessOptions}
          PromotionRecommendation={payload.PromotionRecommendation}
          PromotionAssessmentOptions={document.PromotionAssessmentOptions}
          onChange={onChange}
          disable={!getCondition()}
        />
      )}
      {map[document.PromotionAssessment] && (
        <PromotionAssessment
          PromotionRecommendation={payload.PromotionRecommendation}
          PromotionAssessmentOptions={document.PromotionAssessmentOptions}
          onChange={onChange}
          disable={!getCondition()}
        />
      )}
      {map[document.ExitAssessment] && (
        <ExitRiskCard
          ExitRisk={payload.ExitRisk}
          ExitAssessmentOptions={document.ExitAssessmentOptions}
          onChange={onChange}
          disable={!getCondition()}
        />
      )}
      {actionError && (
        <AlertMessage {...actionError} onClose={() => setActionError(null)} />
      )}
      <Box>
        {getCondition() && (
          <Button
            style={{ float: "right", margin: "2em 0" }}
            color={"primary"}
            label={"Save"}
            onClick={() => {
              handleTabEdit("save");
              console.log(JSON.stringify(payload), "MINIMALISTIC PAYLOAD");
              onSaveAssessment();
            }}
            title={"Save"}
          />
        )}
      </Box>
    </Paper>
  ) : (
    <>This section is disabled</>
  );
};

export default Confidential;
