import React from 'react'
import Button from '@mui/material/Button'

function EWButton({ Type, Color, Title, Handler }) {
  if (!Type) Type = ''
  console.log(Color," ")
  return (
    <>
      <Button onClick={Handler} color={Color?Color: 'secondary'} style={{fontSize:10}}  variant={Type}>
        {Title}
      </Button>
    </>
  )
}

export default EWButton
