
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

function Profile({ TenantInfo, UserInfo, UserActions }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title="Profile">
        <div
          style={{
            padding: "0.3em 0.6em",
            border: "1px solid #C5C5C5",
            borderRadius: "10px",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          <div style={{ display: "flex", gap: "0.5em", alignItems: "center" }}>
            <Avatar alt="Remy Sharp" src={`${UserInfo.ProfilePic}`} />{" "}
            <Typography variant="h6" color={"#757575"}>
              {UserInfo.Name}
            </Typography>
          </div>
        </div>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
          }}
          sx={{ cursor: "default", ":hover": { backgroundColor: "white" } }}
          disableRipple
          disableTouchRipple
        >
          <div className="Flex" style={{ gap: "1em" }}>
            <img
              src={`${TenantInfo.Logo}`}
              alt="TenantLogo"
              style={{ width: "7em" }}
            />
            <Divider sx={{ width: "100%" }} />
          </div>
        </MenuItem>
        {UserActions.map((action, index) => {
          return (
            <MenuItem onClick={handleClose} key={action.ActionID}>
              {action.Label}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

export default Profile;
