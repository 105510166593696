import React from 'react';
import AButton1 from '../Atmoic/AButton1';

function Card2({ cardInfo }){

    return(
        <div className="card2">

            <div>
                {cardInfo.TransactionId ? <p>{cardInfo.TransactionId}</p> : null}
            </div>
            
            <div>
                {cardInfo.UserId ? <p>{cardInfo.UserId}</p> : null}
            </div>
            
            <div className="actionButtonGroup">

                {
                    cardInfo.Actions.map(index => {
                        return <AButton1 label={index.Title} key={index.key}/>
                    })
                }

            </div>
            

        </div>
    );
}

export default Card2;