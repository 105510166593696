import React,{useState} from 'react'
import Paper from '@mui/material/Paper'


function NeedToActCard(props) {
  console.log("From NTA Cards")
  const [elvation,setElevation]=useState(1)
  var styleArray = [
    { position: 'absolute', top: 10, left: 10 },
    { position: 'absolute', top: 50, left: 10,width:'75%' },
    { position: 'absolute', bottom: 10,width:'100%' },
    { position: 'absolute', top: 10, right: 10 },
  ]
  return (
    <>
      <Paper
      elevation={elvation}
      onMouseEnter={()=>{setElevation(3)}}
      onMouseLeave={()=>{setElevation(1)}}
        style={{
          height: 180,
          width: 350,
          position: 'relative',
        }}
      >
        {Array.from({ length: 4 }, (ele,index) => {
          let place = 'Placeholder_' + (index + 1)
          let PComp
         
          return (
            <div style={styleArray[index]}>
              {props[place] && (
                <>
                  {
                    PComp = require('../../MolecularComponents/' +
                      props[place].Mcomponent).default
                  }
                
                  <PComp {...props[place].props} key={index} />
                </>
              )}
            </div>
          )
        })}
       {/* <div style={{position:'absolute',left:-70,top:50}}>
        <img src={props.config.Img_url} height="70px" width="70px" style={{borderRadius:50,}} />
      </div> */}
      </Paper>
      
    </>
  )
}

export default NeedToActCard
