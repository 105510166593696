import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Gateway from '../../Utils/Api/Gateway'
import { v4 as uuid } from 'uuid'
import HandleCondition from '../../Utils/Condition'
import { useSelector, useDispatch } from 'react-redux'

import * as Validators from "../../Utils/Validators/library";

const Root = '../../'
const FILE_NAME="Shell.js";

function Shell(props) {
  const { dimensions, elements, data, param,fieldDependency } = props
  const [elvation, setElevation] = useState(1)
  const [validated, setValidation] = useState(false)
  const [loading, setLoading] = useState(true)
  const [componentInstanceId, setComponentInstanceId] = useState(uuid())
  const dispatch = useDispatch()
  //const componentInstanceId="Test";
  //uuid();

  const shellData = useSelector((state) => {
   console.log("New Shell Data: ",state.DataReducer[componentInstanceId])
    return state.DataReducer[componentInstanceId]
  })

  const state = useSelector((state) => state)

  function unSetData() {
    dispatch({
      type: 'UNSET_DATA',
      payload: {
        id: componentInstanceId,
      },
    })
  }
  const RfieldDependency=useSelector((state)=>state.DataReducer[componentInstanceId]?.fieldDependency);
  const Rstate=useSelector((state)=>state.DataReducer)
  useEffect(() => {
    //validateProps(props);
 
    if (!data) {
      console.log('in IF')
      Gateway({}, {}, param, 'GET').then((res) => {
        dispatch({
          type: 'SET_DATA',
          payload: {
            id: componentInstanceId,
            data: res,
          },
        })
        console.log('State from Shell: ', state)
        setLoading(false)
      })
    } else {
      console.log('in else')
      
      dispatch({
        type: 'SET_DATA',
        payload: {
          id: componentInstanceId,
          data: data,
        },
      })
      console.log('State from Shell: ', state, ' ', componentInstanceId)
      // setData(data);
      setLoading(false)
    }
    if(fieldDependency){
    dispatch({
      type:'SET_FIELD_DATA',
      payload:{
        id:componentInstanceId,
        fieldDependency
      }
    })
  
  
  }
    setValidation(true)

    return () => {
      console.log('Unmounted')
      unSetData()
    }
  }, [])

  function validateProps(props) {
    if (!dimensions) {
      return
    }
    setValidation(true)
    setLoading(false)
  }

  function renderElements() {
    return elements.map((ele, index) => {
      let componentName = ele.componentName.split('.')
      let compAddress =
        componentName[0] +
        'Component/' +
        componentName[1] +
        '/' +
        componentName[2]
      console.log('From Shell: ', compAddress)
      let Component = require('../../' + compAddress).default
      
      return (
        <div className='comment' style={{ position: 'absolute', ...ele.position }}>
          {console.log('From shell Data: ', shellData[ele.mapTo])}
          <Component {...ele} {...ele.props} key={index} {...shellData[ele.mapTo]} dataReduxId={componentInstanceId} fieldDependency={RfieldDependency} dataReduxerState={Rstate} parentField={ele.mapTo} />
        </div>
      )
    })
  }

  function RenderComponent({ validated }) {
    if (!validated && loading) return <></>
    if (!validated && !loading)
      return <span style={{ color: 'red' }}>Prop Validation Failed</span>
    if (loading) return <span>Lodaing...</span>

    return (
      <>
        <Paper
          elevation={elvation}
          // onMouseEnter={() => {
          //   setElevation(3)
          // }}
          // onMouseLeave={() => {
          //   setElevation(1)
          // }}
          style={{
            width: dimensions.width,
            height: dimensions.height,
            position: 'relative',
            padding: 5,
          }}
        >
          {renderElements()}
        </Paper>
      </>
    )
  }
  return (
    <>
    {RfieldDependency &&  dispatch(HandleCondition(fieldDependency,Rstate,componentInstanceId))}
      {shellData ? (
        <RenderComponent validated={validated} />
      ) : (
        <span style={{ color: 'red' }}>No Data</span>
      )}
      {/* {shellData ? RenderComponent({ validated: true }): <span>No Data</span>} */}
      {/* {RenderComponent({ validated: true })} */}
    </>
  )
}

export default Shell
