import * as methods from "../../Utils/Validators/library";
import store from "../../Redux/Store";

function methodHandler(methodObj, id) {
  var params = methodObj.params;
  if (!params) params = [];
  var data = store.getState()?.DataReducer[id];

  params = params.map((ele) => {
    if (typeof ele !== "string") return ele;
    let eleArr = ele.split(".");

    if (eleArr.length > 1) {
      return data[eleArr[0]][eleArr[1]];
    } else return ele;
  });
  let func = methods[methodObj.method];
  console.log("From Method Handler ", func, " ",typeof func);
  var result = methods[methodObj.method](...params, id);

  return new Promise((resolve, reject) => {
    if (result instanceof Promise) {
      result.then((res) => resolve(res)).catch((e) => reject(e));
    } else resolve(result);
  });
}

export default methodHandler;
