import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Validators from "./Validators/library";

const HandleCondition = (fieldDependency, Data, reduxId) => (dispatch) => {
  console.log("Before handle: ");

  //  return new Promise((resolve,reject)=>{
  //if(!fieldDependency || fieldDependency.length===0)reject();

  //console.log("From Handle Conditon ",Data," ",conditions,ReduxId,parentField)
  var DecisionArr = [];
  fieldDependency?.map((field) => {
    var fieldToBeEffected = field.fieldToBeEffected;
    console.log("Inside fireld to be effected ");
    field.propsToBeEffected?.map((ele) => {
      console.log("Inside prop to be effected ");
      var finalDecision;
      var count = 0;
      ele.dependency.map((dep) => {
        console.log("Inside depen to be effected ", Data);
        let value = Data[reduxId][dep.name].value;
        console.log("ptr1 ", value);
        let params = dep.condition.parameter.map((par) => {
          if (par === "value") return value;
          else return par;
        });
        let decision = Validators[dep.condition.method](params);

        if (count == 0) {
          if (decision) finalDecision = dep.ifTrue;
          else finalDecision = dep.ifFalse;
          count++;
        } else {
          if (dep.operation === "&&") {
            finalDecision =
              finalDecision && (decision ? dep.ifTrue : dep.ifFalse);
          } else if (dep.operation === "||") {
            finalDecision =
              finalDecision || (decision ? dep.ifTrue : dep.ifFalse);
          }
        }
        console.log("From decision making1 ", finalDecision);
      });
      console.log("From decision making ", finalDecision);
      //resolve({finalDecision,prop:ele.propName});
      DecisionArr.push({
        fieldToBeEffected,
        finalDecision,
        prop: ele.propName,
      });
    });
    //console.log("Before Dispatching")
    dispatch({
      type: "UPDATE_DATA",
      payload: {
        id: reduxId,
        data: DecisionArr,
      },
    });
    //resolve(DecisionArr);
    return DecisionArr;
  });
  //    dispatch({
  //     type:'SET_FIELD_DATA',
  //     payload:{
  //       id:ReduxId,
  //       field:parentField,
  //       data:finalDecision,
  //       prop:ele.propName
  //     }
  //    })

  // })
};

export default HandleCondition;
