import React, { useState } from "react";
import Paper from "@mui/material/Paper";

function Card_2({ Elements }) {
  const [elvation, setElevation] = useState(1);
  var styleArray = [
    { position: "absolute", top: -20, left: -30 },
    { position: "absolute", top: 20, left: 100 },
    { position: "absolute", top: 60, left: 10, padding: 20 },
    { position: "absolute", bottom: 20, left: 10, padding: 20 },
  ];
  return (
    <>
      <Paper
        elevation={elvation}
        onMouseEnter={() => {
          setElevation(3);
        }}
        onMouseLeave={() => {
          setElevation(1);
        }}
        style={{
          height: 200,
          width: 300,
          position: "relative",
        }}
      >
        {Elements.map((ele, index) => {
          let compArr = ele.Component.split(".");
          let compAddress;
          if (compArr.length === 3)
            compAddress =
              compArr[0] + "Component/" + compArr[1] + "/" + compArr[2];
          let Comp;

          return (
            <div style={styleArray[index]}>
              {(Comp = require("../../" + compAddress).default)}
              <Comp key={index} {...ele.props} />
            </div>
          );
        })}
      </Paper>
      <br />
    </>
  );
}

export default Card_2;
