import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Link, Typography } from "@mui/material";
import React, { useState } from "react";

function SelectedViewAccordian({ AccordianID, title, caption, children }) {
  const [open, setOpen] = useState(false);
  return (
    <Box sx={{ width: "100%", margin: "" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "white",
        }}
        onClick={() => {}}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link
            sx={{ marginRight: "0.5em", cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              setOpen((prevState) => !prevState);
            }}
          >
            {!open && <KeyboardArrowRight sx={{ color: "black" }} />}
            {open && <KeyboardArrowDown sx={{ color: "black" }} />}
          </Link>
          <Box display={"flex"} gap={"0.5em"} alignItems={"center"}>
            <Typography variant={"body2"}>{title}</Typography>
            <Typography variant="small_text">{`(${
              caption || "Included"
            })`}</Typography>
          </Box>
        </Box>
      </Box>
      {open && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link
            sx={{ marginRight: "0.5em", opacity: "0" }}
            onClick={(e) => {
              e.preventDefault();
              setOpen((prevState) => !prevState);
            }}
          >
            {!open && (
              <KeyboardArrowRight sx={{ color: "black", display: "none" }} />
            )}
            {open && (
              <KeyboardArrowDown sx={{ color: "black", display: "none" }} />
            )}
          </Link>
          <Box
            sx={{
              marginTop: "0.3em",
              borderTop: 0,
              //   paddingLeft: "2em",
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default SelectedViewAccordian;
