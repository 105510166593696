import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import useProps from "../../../Utils/CustomHooks/GetProps";
import { ArrowForward, KeyboardArrowRight } from "@mui/icons-material";

const defaultProps = {
  menuItems: null,
  label: null,
  onChange: null,
  value: null,
  flex: null,
};

const row = {
  display: "flex",
  flexDirection: "row",
};

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  backgroundColor: theme.palette.primary["100"],
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: `radial-gradient(${theme.palette.secondary.main},${theme.palette.secondary.main} 28%,transparent 32%)`,
    content: '""',
  },
}));

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export default function DimensionTypeRadioGroup(props = defaultProps) {
  var { menuItems, label, onChange, value, flex, containerStyles } =
    useProps(props);

  if (menuItems && typeof menuItems[0] === "string") {
    menuItems = menuItems.map((ele) => ({ value: ele, label: ele }));
  }
  var styles = {};
  if (flex === 1) styles = row;
  if (containerStyles) styles = { ...styles, ...containerStyles };
  return (
    <FormControl component="fieldset" sx={{ width: "100%", display: "flex" }}>
      <FormLabel component="legend">{label}</FormLabel>
      {console.log("value: ", value)}
      <RadioGroup
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "0.5em",
        }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {menuItems.map((ele, index) => {
          const { label, value } = ele;
          return (
            <>
              <FormControlLabel
                value={value ? value : label}
                control={<BpRadio />}
                label={label}
                sx={{ marginRight: "0" }}
              />
              {index !== menuItems.length - 1 && (
                <KeyboardArrowRight fontSize="small" sx={{ color: "" }} />
              )}
            </>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
