import React, { useState } from "react";
import {
  Paper,
  Typography,
  Grid,
  InputBase,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import Atomic from "../AComponent/Atomic";
import Molecule from "../MComponent/Molecule";
import { Search } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useWindowDimensions from "../Utils/CustomHooks/WindowRezise";
const { Button, Input } = Atomic;
const { EwModal } = Molecule;

const dataList = [
  {
    name: "PVR Ambience Device-1",
    mac: "00:1B:44:11:3A:B7",
    registred: true,
  },
  {
    name: "PVR Ambience Device for staff",
    mac: "00:1B:44:11:3A:B7",
    registred: false,
  },
  {
    name: "PVR Ambience Device for staff",
    mac: "00:1B:44:11:3A:B7",
    registred: false,
  },
  {
    name: "PVR Ambience Device for staff",
    mac: "00:1B:44:11:3A:B7",
    registred: false,
  },
  {
    name: "PVR Ambience Device for staff",
    mac: "00:1B:44:11:3A:B7",
    registred: false,
  },
  {
    name: "PVR Ambience Device for staff",
    mac: "00:1B:44:11:3A:B7",
    registred: false,
  },
  {
    name: "PVR Ambience Device for staff",
    mac: "00:1B:44:11:3A:B7",
    registred: false,
  },
];
const options = ["Un-register"];

const endPointMap = {
  registerDevice: "/face-recognition/tam/device/register",
  activate: "/face-recognition/tam/device/installBDA",
  deactivate: "/face-recognition/tam/device/deactivate",
  getAllDevices: "/getAllDevices",
};

function Card({ name, mac, registred }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  return (
    <>
      <Paper
        style={{
          width: 350,
          height: 120,
          padding: 10,
          position: "relative",
          zIndex: 0,
        }}
      >
        {registred && (
          <div
            style={{
              position: "absolute",
              right: 5,
            }}
          >
            <IconButton
              aria-label="more"
              id="long-button"
              // aria-controls={open ? "long-menu" : undefined}
              // aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              PaperProps={{
                style: {
                  //maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option}
                  selected={option === "Pyxis"}
                  onClick={() => setAnchorEl(null)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
        <div
          style={{
            width: 95,
            height: 30,
            padding: 2,
            borderRadius: 0,
            position: "absolute",
            top: -20,
            zIndex: -5,
            borderBottom: "0px solid black",
          }}
          className="Flex"
        >
          {registred && (
            <Typography variant="caption" color={"lightgreen"}>
              Registred
            </Typography>
          )}
        </div>
        <div className="Flex">
          <Typography variant="h3">{name}</Typography>
          <br />
          <Typography>{mac}</Typography>
        </div>
      </Paper>
    </>
  );
}

function TAM() {
  const [data, setData] = useState(dataList);
  const [dataCopy, setDataCopy] = useState(dataList);
  const [modalOpen, setModalOpen] = useState(false);
  const [deviceData, setDeviceData] = useState({
    macId: "",
    workCode: "",
  });
  const { width } = useWindowDimensions();
  const { macId, workCode } = deviceData;
  function search(str) {
    if (str === "") {
      setData(dataCopy);
      return;
    }
    let arr = [];
    arr = dataCopy?.filter((item) =>
      JSON.stringify(item).toLowerCase().includes(str.toLowerCase())
    );
    setData([...arr]);
  }
  function updateDeviceData(val, type) {
    setDeviceData((prev) => ({ ...prev, [type]: val }));
  }
  return (
    <div>
      <EwModal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div style={{ padding: 5, width: 0.5 * width }}>
          <div className="Flex">
            <Typography variant="h1">Add A Device</Typography>
          </div>

          <br />
          <div className="Flex">
            <Input
              label={"Mac Address"}
              value={macId}
              onChange={(val) => updateDeviceData(val, "macId")}
            />
            <br />
            <Input
              label={"Work Location Code"}
              value={workCode}
              onChange={(val) => updateDeviceData(val, "workCode")}
            />
            <br />
            <Button title={"Save"} onClick={() => {}} />
          </div>
        </div>
      </EwModal>
      <div className="Flex-row">
        <div
          style={{ width: "70%", justifyContent: "flex-end", display: "flex" }}
        >
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Devices"
              inputProps={{ "aria-label": "search devices" }}
              onChange={(e) => search(e.target.value)}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <Search />
            </IconButton>
          </Paper>
        </div>
        <div className="Flex" style={{ width: "30%", alignItems: "flex-end" }}>
          <div>
            <Button
              variant="outlined"
              color={"success"}
              title={"Add Device"}
              onClick={() => {
                setModalOpen(true);
              }}
            />
          </div>
        </div>
      </div>
      <br />
      <Grid container spacing={5}>
        {data.map((ele) => (
          <Grid md={4} item>
            <Card {...ele} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default TAM;
