import React, { useState, useEffect } from "react";
import { Handle, Position } from "react-flow-renderer";
import { Button, Modal, Paper } from "@mui/material";
import Dropdown from "../../AComponent/Dropdown/Dropdown_3";
import Radio_3 from "../../AComponent/Radio/Radio_3";
import Notification from "../../MComponent/Notification/Notification";
//import Notification from "../Notification/Notification";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
//import Dropdown from '../../AComponent/Dropdown/Dropdown_3'

const styles = {
  modalStyle: {
    position: "absolute",
    top: "10%",
    left: "20%",
    overflow: "scroll",
    height: "100%",
    display: "block",
    width: "80%",
  },
  paper: {
    width: "60%",
    padding: 50,
    flexDirection: "column",
    display: "flex",
    position: "relative",
  },
};

function ActionNode(props) {
  const { name, id, coords, stepId } = props.data;
  const [modal, setModal] = useState(false);
  const [nextStepId, setNextStep] = useState(null);
  const [actionState, setActionState] = useState({
    autoAction: 0,
  });

  const [nextEdge, setNextEdge] = useState(null);
  const [links, setLinks] = useState([]);
  const workflow = useSelector((state) => {
    console.log("state ", state);
    if (state.Workflow.workflow)
      return JSON.parse(JSON.stringify(state.Workflow.workflow));
    else return {};
  });

  const dispatch = useDispatch();

  console.log("From Outpur; ", props);

  function updateWorkflow(obj) {
    dispatch({
      type: "ADD_WORKFLOW",
      payload: obj,
    });
  }
  useEffect(() => {
    if (workflow) {
      console.log("Called inside ", modal);
      let nodes = workflow.states?.nodes;
      let arr = [];
      for (let i = 0; i <= nodes.length; i++) {
        if (!nodes[i]) continue;
        const { id, type } = nodes[i];
        if (!id || !type) continue;

        if (type === "stepForm") {
          let name = workflow[id.split("-")[1]]?.stepName;

          if (name && id !== "end") {
            arr.push({
              label: name,
              value: id,
            });
          } else {
            arr.push({
              label: id,
              value: id,
            });
          }
        }
      }
      //arr.push('end')
      if (JSON.stringify(arr) !== JSON.stringify(links)) setLinks([...arr]);
    }
    if (modal) {
      setNextEdge(null);
      let edges = workflow.states.edges;
      for (let i = 0; i < edges.length; i++) {
        if (edges[i].source === id) {
          console.log("From modal: ", edges[i]);
          setNextEdge(edges[i].id);
          break;
        }
      }
    }
  }, [modal]);

  function addStep() {
    let obj = workflow;
    var { nodes, edges } = obj.states;
    var { x, y } = coords;
    console.log("ID si: ", id);
    let nodeId = obj.currentStep + 1;
    let edgeId = uuid();
    setNextStep(nodeId);
    setNextEdge(edgeId);
    nodes.push({
      id: "Step-" + nodeId,
      type: "stepForm",
      position: { x: x + 200, y },
      data: { id: nodeId, coords: { x: x + 200, y } },
    });
    edges.push({
      id: edgeId,
      source: id,
      target: "Step-" + nodeId,
      sourceHandle: id,
    });
    if (!obj[nodeId]) obj[nodeId] = {};
    obj[nodeId].stepName = "Step-" + nodeId;
    obj = {
      ...obj,
      currentStep: nodeId,
      states: {
        nodes,
        edges,
      },
      updateParent: true,
    };
    obj[id].nextStep = "Step-" + nodeId;
    dispatch({
      type: "ADD_WORKFLOW",
      payload: obj,
    });
  }
  function linkStep(val) {
    console.log("From LInk: ", val);
    let obj = workflow;
    var { nodes, edges } = obj.states;
    let edgeId = uuid();

    setNextEdge(edgeId);
    edges.push({
      id: edgeId,
      source: id,
      target: val,
    });
    obj[id].nextStep = val;
    obj = {
      ...obj,
      states: {
        nodes,
        edges,
      },
    };
    dispatch({
      type: "ADD_WORKFLOW",
      payload: obj,
    });
  }
  function connectNode() {
    function deleteEdge() {
      let obj = workflow;
      let edges = obj.states.edges;
      let index = -1;
      for (let i = 0; i < edges.length; i++) {
        if (edges[i].id === nextEdge) {
          index = i;
          break;
        }
      }
      if (index !== -1) {
        edges.splice(index, 1);
        obj.states.edges = [...edges];
        obj[id].nextStep = "";
        dispatch({
          type: "ADD_WORKFLOW",
          payload: obj,
        });
        setNextEdge(null);
      }
    }
    if (nextEdge) {
      return (
        <>
          <Button onClick={deleteEdge} color="error">
            Detach Step
          </Button>
        </>
      );
    } else {
      return (
        <>
          <div className="Flex-row">
            <div style={{ padding: 15 }}>
              <Button onClick={addStep} variant="contained">
                Add Next Step
              </Button>
            </div>
            <div style={{ padding: 15, width: "50%" }}>
              <Dropdown
                menuItems={links}
                label="Link To A Step"
                onChange={linkStep}
              />
            </div>
          </div>
        </>
      );
    }
  }
  let { autoAction, Notifications } = workflow[id] || {};
  return (
    <div>
      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
        }}
        style={styles.modalStyle}
      >
        <Paper style={styles.paper}>
          <div className="Flex" style={{ color: "red" }}>
            <h2>{name}</h2>
          </div>
          <div>
            <Radio_3
              flex={1}
              label="Auto Action"
              menuItems={[
                { label: "Yes", value: 1 },
                { label: "No", value: 2 },
              ]}
              value={autoAction}
              onChange={
                (val) => {
                  let obj = workflow;
                  obj[id].autoAction = val;
                  updateWorkflow(obj);
                }
                // setActionState((prev) => ({ ...prev, autoAction: val }))
              }
            />
          </div>
          {connectNode()}
          <div>
            <Notification
              type={"A"}
              value={Notifications}
              onChange={(val) => {
                let obj = workflow;
                obj[id].Notifications = val;
                updateWorkflow(obj);
              }}
            />
          </div>
        </Paper>
      </Modal>
      <Handle type="target" position={Position.Left} />
      <h5 style={{ color: "red" }}>{name}</h5>
      <Button onClick={() => setModal(true)}>Add Details</Button>
      <Handle type="source" position={Position.Right} id={id} />
    </div>
  );
}

export default ActionNode;
