import {
  Box,
  Divider,
  IconButton,
  Link,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Atomic from "../../../../AComponent/Atomic";
import Molecule from "../../../../MComponent/Molecule";
import { Edit, KeyboardArrowLeft } from "@mui/icons-material";
import { v4 as uuid } from "uuid";
import DiscreteScaleSlider from "./ScaleWidgets/DiscreteScaleSlider";
import ContinuousScaleSlider from "./ScaleWidgets/ContinuousScaleSlider";
import AlertDialog from "../../../../Utils/Dialog/Dialog";
import { getData, submitPayload } from "../../../../Utils/Common/Library";
import { useDispatch, useSelector } from "react-redux";
import DataGrid2 from "../../../../MComponent/Table/DataGrid2";

const { Button, Input, Radio } = Atomic;
const { Table, EwModal } = Molecule;

const REDUX_ID = "RATING_SCALES";

const initScaleValue = {
  OptionID: "",
  Order: "",
  Label: "",
  Value: "",
  Description: "",
  RangeMinimumValue: "",
  RangeMaximumValue: "",
};

const initRatingScale = {
  RatingScaleModuleID: "19",
  RatingScaleName: "",
  RatingScaleDescription: "",
  RatingScaleType: "",
  ScaleValues: [],
  MinimumScaleValue: "",
  MaximumScaleValue: "",
  ValueDecimals: "",
  ScaleWidgetID: "",
};

const ScaleDetails = ({
  onEdit,
  RatingScaleName,
  RatingScaleDescription,
  RatingScaleType,
  ScaleValues,
  MinimumScaleValue,
  MaximumScaleValue,
  ValueDecimals,
  ScaleWidgetID,
}) => {
  const heading = ["Label", "Description"];
  const rows = ScaleValues
    ? [...ScaleValues.map((value) => [value.Label, value.Description])]
    : [];
  return (
    <Box height={"60vh"} width={"70vw"} className="scroll">
      <Box
        display={"flex"}
        alignItems={"flex-end"}
        gap={"1em"}
        padding="1em"
        sx={{ backgroundColor: "#f7f4ea" }}
      >
        <Typography variant="h4">{RatingScaleName}</Typography>
        <Typography variant="body1" color={"#7f7f7f"}>
          {RatingScaleType === "D" ? "Discrete" : "Continuous"}
        </Typography>
      </Box>
      <Divider />
      <Box padding={"1em"} display={"flex"} flexWrap={"wrap"} gap={"2em"}>
        <Box flex={1}>
          <Box>
            <Typography variant="body1">{RatingScaleDescription}</Typography>
          </Box>
          <Box marginTop={"2em"}>
            <Typography variant="overline">Scale</Typography>
            {/* Scale Widget should be displayed depending upon the widgetID */}
            <Box margin={"0 1em"}>
              {RatingScaleType === "D" && (
                <DiscreteScaleSlider values={ScaleValues} />
              )}
              {RatingScaleType === "C" && (
                <ContinuousScaleSlider
                  step={Number(
                    (1 / Math.pow(10, ValueDecimals)).toFixed(ValueDecimals)
                  )}
                  min={+MinimumScaleValue}
                  max={+MaximumScaleValue}
                />
              )}
            </Box>
          </Box>
        </Box>
        {RatingScaleType === "D" && (
          <Box flex={1}>
            <Table heading={heading} rows={rows} />
          </Box>
        )}
      </Box>
      <Box className="Flex">
        <Button title={"Edit"} onClick={onEdit} />
      </Box>
    </Box>
  );
};

const ScaleValueForm = ({ initValue, defaultOrder, saveValueHandler }) => {
  const [value, setValue] = useState(
    initValue || {
      ...initScaleValue,
      Order: defaultOrder.toString(),
      OptionID: uuid(),
    }
  );

  const [range, setRange] = useState(
    value.RangeMinimumValue !== "" || value.RangeMaximumValue !== ""
      ? "yes"
      : "no"
  );

  useEffect(() => {
    if (range === "no") {
      setValue((prevState) => ({
        ...prevState,
        RangeMaximumValue: "",
        RangeMinimumValue: "",
      }));
    }
  }, [range]);

  const onChangeHandler = (key, value) => {
    console.log(typeof value);
    setValue((prevState) => ({ ...prevState, [key]: value }));
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Box
        height={"60vh"}
        width={"40vw"}
        className="scroll"
        paddingRight={"2em"}
      >
        <Typography variant="h5">Add Scale Value</Typography>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={"1em"}
          marginTop={"1em"}
        >
          <Box flex={1}>
            <Input
              label={"Label"}
              onChange={(value) => onChangeHandler("Label", value)}
              value={value.Label}
              styles={{ margin: "0.5em 0", width: "100%" }}
            />
          </Box>
          <Box flex={1}>
            <Input
              label={"Value"}
              type={"number"}
              onChange={(value) => onChangeHandler("Value", value)}
              value={value.Value}
              styles={{ margin: "0.5em 0", width: "100%" }}
            />
          </Box>
        </Box>
        <Box>
          <Input
            label={"Description"}
            rows={5}
            onChange={(value) => onChangeHandler("Description", value)}
            value={value.Description}
            styles={{ margin: "0.5em 0", width: "100%" }}
          />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={"2em"}
          alignItems={"flex-end"}
          marginTop={"1em"}
        >
          <Box flex={1}>
            <Input
              label={"Order"}
              type={"number"}
              onChange={(value) => onChangeHandler("Order", value)}
              value={value.Order}
              styles={{ margin: "0.5em 0", width: "100%" }}
            />
          </Box>
          <Box flex={1}>
            <Radio
              flex={1}
              label={"Enable Rating Range"}
              onChange={(value) => {
                console.log(value);
                setRange(value);
              }}
              menuItems={[
                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
              ]}
              value={range}
            />
          </Box>
        </Box>
        {range === "yes" && (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            gap={"1em"}
            alignItems={"flex-end"}
            marginTop={"1em"}
          >
            <Box flex={1}>
              <Input
                label={"Range Minimum Value"}
                type={"number"}
                onChange={(value) =>
                  onChangeHandler("RangeMinimumValue", value)
                }
                value={value.RangeMinimumValue}
                styles={{ margin: "0.5em 0", width: "100%" }}
              />
            </Box>
            <Box flex={1}>
              <Input
                label={"Range Maximum Value"}
                type={"number"}
                onChange={(value) =>
                  onChangeHandler("RangeMaximumValue", value)
                }
                value={value.RangeMaximumValue}
                styles={{ margin: "0.5em 0", width: "100%" }}
              />
            </Box>
          </Box>
        )}
        <Button
          title={"Save"}
          onClick={() => {
            saveValueHandler(value);
            console.log(value);
          }}
          style={{ float: "right", margin: "2em 0" }}
        />
      </Box>
    </Paper>
  );
};

const RatingScaleForm = ({ initScale, closeForm }) => {
  console.log(initScale, "initial");
  const [scale, setScale] = useState(
    initScale
      ? {
          ...initScale,
          ScaleValues: initScale.ScaleValues ? initScale.ScaleValues : [],
        }
      : initRatingScale
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState(null);
  const [error, setError] = useState({ isOpen: false });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onChangeHandler = (key, value) => {
    console.log(typeof value);
    if (key === "RatingScaleType" && value === "C") {
      setScale((prevState) => ({
        ...prevState,
        [key]: value,
        ScaleValues: [],
      }));
      return;
    }
    if (key === "RatingScaleType" && value === "D") {
      setScale((prevState) => ({
        ...prevState,
        [key]: value,
        MinimumScaleValue: "",
        MaximumScaleValue: "",
        ValueDecimals: "",
      }));
      return;
    }
    setScale((prevState) => ({ ...prevState, [key]: value }));
  };

  const scaleTypes = [
    { label: "Discrete", value: "D" },
    { label: "Continuous", value: "C" },
  ];

  const handleAddScaleValueModalOpen = () => {
    setModal({
      Component: ScaleValueForm,
      props: {
        defaultOrder: scale.ScaleValues.length,
        saveValueHandler: (value) => {
          const newState = { ...scale };
          newState.ScaleValues.push(value);
          setScale(newState);
          setModalOpen(false);
        },
      },
    });
    setModalOpen(true);
  };

  const validateScale = () => {
    if (scale.RatingScaleName === "")
      return { status: false, message: "Rating scale name is required." };
    if (scale.RatingScaleType === -1 || scale.RatingScaleType === "")
      return { status: false, message: "Rating scale type is required." };
    if (scale.RatingScaleType === "D") {
      const values = [...scale.ScaleValues];
      values.sort((val1, val2) => +val1.Order - +val2.Order);
      const len = values.length;
      if (len < 2) {
        return {
          status: false,
          message: "Minimum two scale values should be defined",
        };
      }
      let difference = false;
      for (let i = 0; i < len - 1; i++) {
        const diff = +values[i + 1].Value - +values[i].Value;
        if (diff <= 0)
          return { status: false, message: "Scale values are not equidistant" };

        if (typeof difference === "boolean") {
          difference = diff;
        }
        if (diff !== difference)
          return { status: false, message: "Scale values are not equidistant" };
      }
      setScale((prevState) => ({ ...prevState, ScaleValues: values }));
    } else {
      if (scale.MinimumScaleValue === "")
        return { status: false, message: "Minimum scale value is required." };
      if (scale.MaximumScaleValue === "")
        return { status: false, message: "Maximum scale value is required." };
      if (scale.ValueDecimals === "")
        return { status: false, message: "Value in decimals is required." };
    }
    return { status: true, message: "" };
  };

  const onSave = () => {
    setLoading(true);
    console.log(scale, "Response is here");
    if (scale.RatingScaleType === "C") scale.ScaleValues = [];
    let payload = {
      RatingScaleName: scale.RatingScaleName,
      RatingScaleType: scale.RatingScaleType,
      RatingScaleModuleID: scale.RatingScaleModuleID,
      RatingScaleDescription: scale.RatingScaleDescription,
      ScaleWidgetID: scale.ScaleWidgetID,
      ScaleValues: scale.ScaleValues,
      MinimumScaleValue: scale.MinimumScaleValue,
      MaximumScaleValue: scale.MaximumScaleValue,
      ValueDecimals: scale.ValueDecimals,
    };
    console.log(payload);
    let edit = null;
    if (scale.TransactionID) {
      edit = {
        TransactionID: scale.TransactionID,
        EffectiveFromTimestamp: scale.EffectiveFromTimestamp,
        RequestType: "1",
      };
    }

    console.log(payload, edit, "Edit is done");

    submitPayload({ featureId: "19148", payload, edit })
      .then((res) => {
        console.log(res, "Response is here");
        if (res.message === "Document inserted") {
          setMessage({
            type: 0,
            message: "New Dimension Created!",
          });
        } else if (res.message === "Document Edited") {
          setMessage({
            type: 0,
            message: "Dimension Edited!",
          });
        }
        return true;
      })
      .catch((e) => {
        setMessage({
          type: 1,
          message: "Network Error!",
        });
        return false;
      })
      .finally(() => {
        setLoading(false);
        closeForm();
      });
  };

  const heading = ["Label", "Value", "Order", "Rating Range", "Edit"];
  const rows = scale.ScaleValues
    ? [
        ...scale.ScaleValues?.map((value, index) => [
          value.Label,
          value.Value,
          value.Order,
          value.RangeMinimumValue
            ? `[${value.RangeMinimumValue} - ${value.RangeMaximumValue}]`
            : "-",
          {
            Component: () => (
              <IconButton
                sx={{ margin: 0 }}
                onClick={() => {
                  setModal({
                    Component: ScaleValueForm,
                    props: {
                      initValue: value,
                      defaultOrder: scale.ScaleValues.length,
                      saveValueHandler: (updatedValue) => {
                        const newState = { ...scale };
                        newState.ScaleValues[index] = updatedValue;
                        setScale(newState);
                        setModalOpen(false);
                      },
                    },
                  });
                  setModalOpen(true);
                }}
              >
                <Edit sx={{ color: "black", margin: 0 }} />
              </IconButton>
            ),
            props: {},
          },
        ]),
      ]
    : [];

  return loading ? (
    <Box>Loading ...</Box>
  ) : (
    <Box height={"60vh"} className="scroll" paddingRight={"2em"}>
      <Button
        title={"Rating Scales"}
        variant={"text"}
        onClick={closeForm}
        otherProps={{ startIcon: <KeyboardArrowLeft /> }}
      />
      <Box marginTop={"1.5em"}>
        <Box flex={1}>
          <Box>
            <Input
              label={"Name"}
              onChange={(value) => onChangeHandler("RatingScaleName", value)}
              value={scale.RatingScaleName}
              styles={{ margin: "0.5em 0", width: "100%" }}
            />
          </Box>
          <Box>
            <Input
              label={"Description"}
              onChange={(value) =>
                onChangeHandler("RatingScaleDescription", value)
              }
              value={scale.RatingScaleDescription}
              rows={5}
              styles={{ margin: "0.5em 0", width: "100%" }}
            />
          </Box>
          <Box marginTop={"1em"}>
            <Radio
              flex={1}
              label={"Scale Type"}
              onChange={(value) => onChangeHandler("RatingScaleType", value)}
              menuItems={scaleTypes}
              value={scale.RatingScaleType}
            />
          </Box>
        </Box>
        <Box margin={"1em 0"}>
          {scale.RatingScaleType === "C" && (
            <Box display={"flex"} gap={"1em"}>
              <Box flex={1}>
                <Input
                  label={"Minimum Scale Value"}
                  onChange={(value) =>
                    onChangeHandler("MinimumScaleValue", value)
                  }
                  value={scale.MinimumScaleValue}
                  styles={{ margin: "0.5em 0", width: "100%" }}
                />
              </Box>
              <Box flex={1}>
                <Input
                  label={"Maximum Scale Value"}
                  onChange={(value) =>
                    onChangeHandler("MaximumScaleValue", value)
                  }
                  value={scale.MaximumScaleValue}
                  styles={{ margin: "0.5em 0", width: "100%" }}
                />
              </Box>
              <Box flex={1}>
                <Input
                  label={"Value Decimals"}
                  onChange={(value) => onChangeHandler("ValueDecimals", value)}
                  value={scale.ValueDecimals}
                  styles={{ margin: "0.5em 0", width: "100%" }}
                />
              </Box>
            </Box>
          )}
          {scale.RatingScaleType === "D" && (
            <Box>
              {scale.ScaleValues.length === 0 ? (
                <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                  <Typography variant="h6" fontWeight={"400"}>
                    No values defined
                  </Typography>
                </Box>
              ) : (
                <Table heading={heading} rows={rows} pageLimit={4} />
              )}
              <Button
                title={"Add Scale Value"}
                onClick={handleAddScaleValueModalOpen}
              />
            </Box>
          )}
        </Box>
      </Box>
      {/* {error && (
          <Box
            sx={{
              border: "1px solid red",
              backgroundColor: "rgba(255, 0, 0, 0.2);",
            }}
            padding={"1em"}
            margin={"1em 0"}
          >
            {error}
          </Box>
        )} */}
      <AlertDialog
        title={error.message}
        open={error.isOpen}
        handleClose={() => setError({ isOpen: false })}
      />
      <Button
        title={"Save"}
        onClick={() => {
          const result = validateScale();
          if (result.status) {
            setError({ isOpen: false });
            onSave();
          } else {
            setError({ isOpen: true, message: result.message });
            return;
          }
        }}
        style={{ float: "right" }}
      />
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {modal && <modal.Component {...modal.props} />}
      </Modal>
    </Box>
  );
};

function MoreDetailsBox({ rowData, onClick }) {
  return (
    <Box>
      <Link
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onClick(rowData);
        }}
      >
        View
      </Link>
    </Box>
  );
}

function EditBox({ rowData, onClick }) {
  return (
    <IconButton
      sx={{ margin: 0 }}
      onClick={(e) => {
        e.preventDefault();
        onClick(rowData);
      }}
    >
      <Edit sx={{ color: "black", margin: 0 }} />
    </IconButton>
  );
}

const RatingScales = () => {
  const [modal, setModal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [form, setForm] = useState(false);

  const handleAddRatingScaleModalOpen = () => {
    setForm({
      isOpen: true,
      closeForm: () => setForm({ isOpen: false }),
    });
  };

  const ratingScalesTableHeading = ["Name", "Type", "More Details", "Edit"];

  return form.isOpen ? (
    <RatingScaleForm initScale={form.initScale} closeForm={form.closeForm} />
  ) : (
    <Box>
      <Table
        heading={ratingScalesTableHeading}
        featureId={"19148"}
        disableEdit={true}
        rowToFieldMap={[
          "RatingScaleName",
          "RatingScaleType",
          {
            label: "More Details",
            Component: MoreDetailsBox,
            props: {
              onClick: (scale) => {
                setModal({
                  Component: ScaleDetails,
                  props: {
                    ...scale,
                    onEdit: () => {
                      setModalOpen(false);
                      setForm({
                        isOpen: true,
                        initScale: scale,
                        closeForm: () => setForm({ isOpen: false }),
                      });
                    },
                  },
                });
                setModalOpen(true);
              },
            },
          },
          {
            label: "Edit",
            Component: EditBox,
            props: {
              onClick: (scale) => {
                setForm({
                  isOpen: true,
                  initScale: scale,
                  closeForm: () => setForm({ isOpen: false }),
                });
              },
            },
          },
        ]}
      />
      <Box className="Flex" marginTop={"3em"}>
        <Button
          title={"Add Rating Scale"}
          onClick={handleAddRatingScaleModalOpen}
        />
      </Box>
      <EwModal
        open={modalOpen}
        onClose={() => {
          console.log("I am here and there");
          setModalOpen(false);
        }}
      >
        {modal && <modal.Component {...modal.props} />}
      </EwModal>
    </Box>
  );
};

export default RatingScales;
