export const AggregateScoreRatingScaleItems = [
  {
    label: "Scale#1",
    value: "1",
  },
  {
    label: "Scale#2",
    value: "2",
  },
  {
    label: "Scale#3",
    value: "3",
  },
  {
    label: "Scale#4",
    value: "4",
  },
  {
    label: "Scale#5",
    value: "5",
  },
];

export const ParameterAlignmentItems = [
  {
    label: "Disabled",
    value: "D",
  },
  {
    label: "Upward Alignment Enabled",
    value: "UA",
  },
  {
    label: "Downward Alignment Enabled",
    value: "DA",
  },
];

export const TargetEditingItems = [
  {
    label: "Restricted",
    value: "R",
  },
  {
    label: "Permitted",
    value: "P",
  },
];

export const DimensionAggregateScoreItems = [
  {
    label: "Enabled",
    value: "E",
  },
  {
    label: "Disabled",
    value: "D",
  },
];

export const ScoreRoundingItems = [
  {
    label: "Round off to 1 decimal place.",
    value: "RO",
  },
  {
    label: "Round up to the nearest value",
    value: "RU",
  },
  {
    label: "Round-down to the nearest value",
    value: "RD",
  },
];

export const AggregationRatingItems = [
  {
    label: "Appraiser",
    value: "Appraiser",
  },
  {
    label: "Reviewer",
    value: "Reviewer",
  },
  {
    label: "Appraisee",
    value: "Appraisee",
  },
  {
    label: "Average",
    value: "Average",
  },
];
