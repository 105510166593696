import React from "react";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Hamburger from "./HeaderComponents/Hamburger";
import HeaderLayout from "./HeaderLayout.json";
import CompanyLogo from "./HeaderComponents/CompanyLogo";
import { Chip } from "@mui/material";
import URLS from "../Utils/index";
const { PRODUCTION } = URLS;

const useStyles = makeStyles((theme) => ({
  masthead: {
    backgroundColor: "white",
  },
  label: {
    color: "black",
  },
  blankspace: {
    backgroundColor: "black",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    color: "black",
  },
}));

function Header({setComponent}) {
  const classes = useStyles();

  const Layout = HeaderLayout;

  const MenuHamburger = Layout.Menu
    ? {
        Component: require("./HeaderComponents/" + Layout.Menu.Component)
          .default,
        props: {
          Sections: Layout.Menu.Sections,
        },
      }
    : null;

  const EmployWiseLogo = Layout.EmploywiseInfo
    ? {
        Component: require("./HeaderComponents/" +
          Layout.EmploywiseInfo.Component).default,
        props: {
          ...Layout.EmploywiseInfo,
        },
      }
    : null;

  const ActionIcons = Layout.Actions
    ? {
        Component: require("./HeaderComponents/" + Layout.Actions.Component)
          .default,
        props: {
          ...Layout.Actions,
        },
      }
    : null;

  const Profile = Layout.Profile
    ? {
        Component: require("./HeaderComponents/" + Layout.Profile.Component)
          .default,
        props: {
          ...Layout.Profile,
        },
      }
    : null;

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.masthead}>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
              padding: "0.8em 0",
            }}
          >
            <div
              className="Flex-row"
              style={{ gap: "1em", justifyContent: "normal" }}
            >
              {MenuHamburger && (
                <MenuHamburger.Component {...MenuHamburger.props} setComponent={setComponent} />
              )}
              {EmployWiseLogo && (
                <EmployWiseLogo.Component {...EmployWiseLogo.props} />
              )}
              {!PRODUCTION && <Chip label="Test Server" />}
            </div>
            <div
              className="Flex-row"
              style={{ gap: "1em", justifyContent: "normal" }}
            >
              {ActionIcons && <ActionIcons.Component {...ActionIcons.props} />}
              {Profile && <Profile.Component {...Profile.props} />}
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
