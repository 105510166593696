import React, { useState, useCallback, useEffect } from "react";
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  MiniMap,
  Controls,
} from "react-flow-renderer";
import {
  submitPayload,
  handleResult,
  getData,
  getFeatureDocument,
} from "../../Utils/Common/Library";
import Alert from "@mui/material/Alert";
import { useParams } from "react-router-dom";
import store from "../../Redux/Store";
import { v4 as uuid } from "uuid";
import Menu from "../../Utils/RightClickMenu/Menu";
import output from "./output";
import TextUpdaterNode from "./StepForm";
import "./workflow.css";
import AlertDialog from "../../Utils/Dialog/Dialog";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import EwModal from "../../MComponent/Modal/Modal";
import Atomic from "../../AComponent/Atomic";
import { conditionsData, constants } from "./stepFormData";
//import store from "../../Redux/Store";
// const FrontEndState =
//   '{"1":{"stepName":"Step-1","groups":[{"cases":[{"type":"Default"}],"stepOwner":"0","stepOwnerType":"LM","selectedActions":["Submit","Approve"],"selectedActionIds":["Step-1-Group-0-Action-Submit","Step-1-Group-0-Action-Approve"]}]},"states":{"nodes":[{"id":"Step-1","type":"stepForm","position":{"x":214,"y":182},"data":{"id":"1","coords":{"x":214,"y":182}},"width":252,"height":129},{"id":"end","type":"stepForm","position":{"x":400,"y":-200},"data":{"id":"end","coords":{"x":400,"y":-200}},"width":191,"height":110},{"id":"Step-1-Group-0-Action-Submit","type":"output","position":{"x":514,"y":-150},"data":{"id":"Step-1-Group-0-Action-Submit","name":"Submit","coords":{"x":514,"y":-150},"stepId":"1"},"stepName":"1","width":150,"height":90},{"id":"Step-1-Group-0-Action-Approve","type":"output","position":{"x":514,"y":0},"data":{"id":"Step-1-Group-0-Action-Approve","name":"Approve","coords":{"x":514,"y":0},"stepId":"1"},"stepName":"1","width":150,"height":90}],"edges":[{"id":"Step-1-Group-0-Edge-Submit","source":"Step-1","target":"Step-1-Group-0-Action-Submit","label":"Group-1"},{"id":"Step-1-Group-0-Edge-Approve","source":"Step-1","target":"Step-1-Group-0-Action-Approve","label":"Group-1"},{"id":"14d07f94-70d1-43dc-8ac5-e8b3ba34bbbf","source":"Step-1-Group-0-Action-Submit","target":"end"},{"id":"450defd7-c901-4d3b-afab-45b015a00fe6","source":"Step-1-Group-0-Action-Approve","target":"end"}]},"currentStep":1,"end":{"stepName":"Step-end"},"Step-1-Group-0-Action-Submit":{"type":"output","stepId":"1","autoAction":2,"Notifications":[],"actionName":"Submit","nextStep":"end"},"Step-1-Group-0-Action-Approve":{"type":"output","stepId":"1","autoAction":2,"Notifications":[],"actionName":"Approve","nextStep":"end"},"updateParent":false}';

const FrontEndState = null;
const workflowFeatureID = "00102";
const initialNodes = [
  {
    id: "Step-1",
    type: "stepForm",
    position: { x: 200, y: -100 },
    data: { id: "1", coords: { x: 200, y: -100 } },
  },
  {
    id: "end",
    type: "stepForm",
    position: { x: 800, y: -100 },
    data: { id: "end", coords: { x: 800, y: -100 } },
  },
];

const initialEdges = [
  // { id: 'edge-1', source: 'node-1', target: 'node-2', sourceHandle: 'a' },
  // { id: 'edge-2', source: 'node-1', target: 'node-3', sourceHandle: 'b' },
];
const nodeTypes = { stepForm: TextUpdaterNode, output: output };
function Workflow() {
  const channel = new BroadcastChannel("EW-data");
  //const [workflow, setWorkflow] = useState({})
  // const { AuthID, workflowID } = useParams();
  const [loading, setLoading] = useState(false);
  const [paramterState, setParameterState] = useState({
    AuthorFeatureID: "",
    workflowID: "",
    WorkflowButtonID: "",
  });
  const [pageLoading, setPageLoading] = useState(true);
  const [modalError, setModalError] = useState(null);
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [rightCoord, setRightCoord] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    title: "",
    body: "",
    buttons: [],
    handleClose: null,
  });

  const workflow = useSelector((state) => {
    console.log("state ", state);
    if (state.Workflow.workflow)
      return JSON.parse(JSON.stringify(state.Workflow.workflow));
    else return {};
  });
  const dispatch = useDispatch();

  function createCondition(props) {
    let {
      TenantID,
      ApplicabilityID,
      AgeCondition,
      GenderCondition,
      TenureCondition,
      featureId,
      TransactionDataCondition,
      stepName,
    } = props;
    function getExpFromComparator(obj, isTenure) {
      let str = "*";
      let { comparator, from, to, value: ageValue, tenureType } = obj;
      console.log("Agian: 1", obj, " ", isTenure);
      if (comparator === 4 && (from === undefined || to === undefined))
        return {
          type: "error",
          message: `${
            isTenure ? "Tenure" : "Age"
          } Condition of ${stepName} is missing`,
        };
      if (comparator !== 4 && !ageValue)
        return {
          type: "error",
          message: `${
            isTenure ? "Tenure" : "Age"
          } Condition of ${stepName} is missing`,
        };
      if (isTenure && !tenureType)
        return {
          type: "error",
          message: `Tenure Type Condition of ${stepName} is missing`,
        };
      if (isTenure) tenureType = `!${tenureType}`;
      else tenureType = "";
      //console.log("Agian: ", ageValue," ",comparator);
      if (comparator === 0) {
        str = `[${ageValue}!*${tenureType}]`;
      } else if (comparator === 1) {
        str = `[*!${ageValue}${tenureType}]`;
      } else if (comparator === 2) {
        str = `[=${ageValue}!*${tenureType}]`;
      } else if (comparator === 3) {
        str = `[*!${ageValue}${tenureType}=]`;
      } else if (comparator === 4) {
        str = `[${from}!${to}${tenureType}]`;
      } else {
        str = "*";
      }
      // switch (comparator) {
      //   case 0:
      //     str = `[${ageValue}!*${tenureType}]`;
      //   case 1:
      //     str = `[*!${ageValue}${tenureType}]`;
      //   case 2:
      //     str = `[=${ageValue}!*${tenureType}]`;
      //   case 3:
      //     str = `[*!${ageValue}${tenureType}=]`;
      //   case 4:
      //     str = `[${from}!${to}${tenureType}]`;
      //   default:
      //     str="*"
      // }
      console.log("Agian: ", ageValue, " ", comparator, " ", str);
      return str;
    }
    let compMap = {
      0: ">",
      1: "<",
      2: ">=",
      3: "<=",
      5: "=",
      6: "!=",
    };
    if (AgeCondition) {
      AgeCondition = getExpFromComparator(AgeCondition);
      if (AgeCondition.type === "error") return AgeCondition;
    }
    if (TenureCondition) {
      TenureCondition = getExpFromComparator(TenureCondition, true);
      if (TenureCondition.type === "error") return TenureCondition;
    }
    if (GenderCondition) {
      const { list } = GenderCondition;
      if (!list || list.length === 0) GenderCondition = "[*]";
      else {
        GenderCondition = `[${list.join("!")}]`;
      }
    }

    if (!ApplicabilityID) ApplicabilityID = "*";
    if (!AgeCondition) AgeCondition = "[*!*]";
    if (!GenderCondition) GenderCondition = "[*]";
    if (!TenureCondition) TenureCondition = "[*!*!*]";
    if (!TenantID) TenantID = "@TenantID@";
    if (!featureId) featureId = "test";
    let arr = "*";
    if (TransactionDataCondition && TransactionDataCondition.length > 0) {
      arr = [];
      console.log("Test TRansactional :", TransactionDataCondition);
      for (let i = 0; i < TransactionDataCondition.length; i++) {
        const { selectedField, comparator, value } =
          TransactionDataCondition[i];
        console.log(
          "Test TRansactional :",
          TransactionDataCondition[i],
          " ",
          i
        );
        let str = compMap[comparator];
        // if(comparator===4)str=
        arr.push(`Data.${selectedField},${str},${value}`);
      }
      arr = arr.join("!");
      console.log("Test TRansactional :final", arr);
    }

    return `(${TenantID},@UserID@,${ApplicabilityID},${AgeCondition},${GenderCondition},${TenureCondition},[*!*],[*!*],[{${featureId},000,@TransactionID@,[${arr}]}])`;
  }
  function validate(tree) {
    console.log("in while: 1", tree);
    let arr = tree["Step-1"];
    if (!arr || arr?.length === 0)
      return {
        type: "error",
        message: "Step 1 has no actions defined",
      };
    function getStepName(id) {
      if (workflow[id]?.stepName) return workflow[id].stepName;
      return "Step-" + id;
    }
    let traverse = new Set();
    traverse.add("Step-1");
    let count = 0;
    while (arr.length !== 0) {
      count++;
      if (count > 1000) break;
      console.log("in while: ", arr);
      let node = arr.pop();
      if (traverse.has(node)) continue;
      traverse.add(node);
      if (tree[node] && tree[node][0] === "end") continue;
      if (tree[node]) {
        arr = [...tree[node], ...arr];
        continue;
      }
      let ids = node.split("-");
      let stepName;
      if (ids.length > 2) {
        stepName = getStepName(ids[1]);
        let action = ids[5];
        return {
          type: "error",
          message: `${action} action of ${stepName} step does not lead to a next step!`,
        };
      } else {
        stepName = getStepName(ids[1]);
        return {
          type: "error",
          message: `${stepName} has no actions`,
        };
      }
    }
    if (Object.keys(tree).length !== traverse.size) {
      console.log("Traverse: ", traverse, " ");
      return {
        type: "error",
        message: `Break in the flow please check!`,
      };
    }
    console.log("in while: 2", traverse);
  }
  function getPayload() {
    let obj = workflow;
    let totalSteps = obj.currentStep;
    ///  const userid=store.getState().AuthReducer.userId
    const { stepOwnerLabelOptions } = obj.config;
    const typeToTimeMapper = {
      D: 24 * 60 * 60,
      M: 60,
      H: 60 * 60,
      T: 30 * 24 * 60 * 60,
    };
    let steps = [
      {
        StepID: "000",
        StepName: "Initiation",
        StepReminders: [],
        StepEscalations: [],
        StepConditions: [
          {
            CurrentStepCondition: "",
            CurrentStepAutoActionTimeout: "",
            CurrentStepOwnerType: "TU",
            CurrentStepOwner: "0",
            CurrentStepActions: [
              {
                Actions: "Submit",
                AutoActionEnabled: "false",
                NextStepOnActions: [
                  {
                    NextStepID: "1",
                    NextStepNotifications: [],
                  },
                ],
              },
            ],
            CurrentStepStepData: "",
            CurrentStepOwnerLabel: "",
          },
        ],
        StepTAT: "",
      },
    ];
    function validateTriggerTime(triggerTime, j, stepName) {
      if (!triggerTime)
        return {
          type: "error",
          message: `No Trigger Time Defined For Reminder- ${
            j + 1
          } of ${stepName} Delete The Reminder If No Longer Needed.`,
        };
      let { triggerTimeType, triggerTime: tTime } = triggerTime;
      if (!triggerTimeType || triggerTimeType === -1)
        return {
          type: "error",
          message: `No Trigger Time Type Selected (Months/Days/Minutes) For Reminder- ${
            j + 1
          } of ${stepName}`,
        };
      if (tTime < 0)
        return {
          type: "error",
          message: `Trigger Time Cannot Be Less Than 0 For Reminder- ${
            j + 1
          } of ${stepName}`,
        };
      return typeToTimeMapper[triggerTimeType] * +tTime;
    }
    for (let i = 1; i <= totalSteps; i++) {
      if (!obj[i]) continue;
      let {
        groups,
        stepName,
        reminders,
        escalations,
        defaultGroup,
        Notifications,
      } = obj[i];
      // let stepObj={};
      if (!defaultGroup)
        return {
          type: "error",
          message: `${stepName} has no default actions`,
        };

      if (i === 1) {
        console.log("Notifications is: ", Notifications);
        let arr = Notifications?.map((ele) => {
          if (!ele) return { NotificationID: "" };
          return ele;
        });

        steps[0].StepConditions[0].CurrentStepActions[0].NextStepOnActions[0].NextStepNotifications =
          arr || [];
        console.log("Notifications is: 1", steps);
      }
      if (!reminders) reminders = [];
      if (!escalations) escalations = [];
      let StepReminders = [];
      let StepEscalations = [];
      console.log("Escalations: 1", reminders);
      for (let j = 0; j < reminders.length; j++) {
        console.log("Escalations: 1", reminders[j]);
        let { triggerTime, repeatInterval, notificationId } = reminders[j];
        let validaResult = validateTriggerTime(triggerTime, j, stepName);
        if (validaResult.type === "error") return validaResult;
        console.log("Escalations: 1", validaResult);
        StepReminders.push({
          TriggerTime: "" + validaResult,
          RepeatInterval: "" + (repeatInterval || "0"),
          Notifications: notificationId
            ?.map((ele) => {
              if (ele) return ele;
              // if (!ele)
              //   return {
              //     NotificationID: "",
              //   };
              // return ele;
            })
            .filter((item) => item),
        });
      }

      for (let j = 0; j < escalations.length; j++) {
        let { triggerTime, repeatInterval, notificationId, repeatFrequency } =
          escalations[j];
        let validaResult = validateTriggerTime(triggerTime, j, stepName);
        if (validaResult.type === "error") return validaResult;
        if (!repeatFrequency || repeatFrequency === -1)
          return {
            type: "error",
            message: `No Frequency Selected For Reminder- ${
              j + 1
            } of ${stepName}`,
          };
        StepEscalations.push({
          TriggerTime: "" + validaResult,
          RepeatInterval: "" + (repeatInterval || "0"),
          Notifications: notificationId?.map((ele) => {
            if (!ele)
              return {
                NotificationID: "",
              };
            return ele;
          }),
          RepeatFrequency: repeatFrequency,
        });
      }
      function getNextStepId(stepId) {
        if (!stepId && stepId !== "end") {
          return "";
          // return {
          //   type: "error",
          //   message: `An action does not have a next step`,
          // };
        }
        if (stepId.split("-").length === 2) {
          return stepId.split("-")[1];
        }
        return stepId;
      }
      // if (!groups)
      //   return {
      //     type: "error",
      //     message: `No Groups Defined For ${stepName}`,
      //   };
      if (!groups) groups = [];
      let stepConditions = [];
      for (let j = 0; j < groups.length; j++) {
        let {
          cases,
          stepOwnerType,
          stepOwner,
          selectedActionIds,
          stepOwnerLabel,
        } = groups[j];
        if (!cases || cases.length === 0)
          return {
            type: "error",
            message: `No Conditions Defined For ${stepName}`,
          };
        if (
          stepOwnerLabelOptions &&
          stepOwnerLabelOptions.length > 0 &&
          (!stepOwnerLabel || stepOwnerLabel === -1)
        ) {
          return {
            type: "error",
            message: `No Step Owner Label Defined For ${stepName}`,
          };
        }
        let paramObj = {
          featureId: paramterState.AuthorFeatureID,
          stepName,
        };
        for (let k = 0; k < cases.length; k++) {
          const { type, value } = cases[k];
          if (type === constants.TD) {
            if (paramObj[type]) {
              paramObj[type].push(value);
            } else {
              paramObj[type] = [value];
            }
          } else {
            paramObj[type] = value;
          }
        }
        if (!stepOwnerType)
          return {
            type: "error",
            message: `Step Owner Not Defined For Group ${j + 1} in ${stepName}`,
          };

        let actions = selectedActionIds.map((ele) => {
          const { autoAction, Notifications, nextStep, actionName } = obj[ele];
          let arr = [];
          if (Notifications)
            arr = Notifications.map((ele) => {
              if (!ele) return { NotificationID: "" };
              return ele;
            });

          return {
            Actions: actionName,
            AutoActionEnabled: autoAction === 2 ? "false" : "true",
            NextStepOnActions: [
              {
                NextStepID: getNextStepId(nextStep),
                NextStepNotifications: arr,
              },
            ],
          };
        });
        let cond = createCondition(paramObj);
        if (cond?.type === "error") return cond;
        let stepConditionObj = {
          CurrentStepCondition: cond,
          CurrentStepAutoActionTimeout: "",
          CurrentStepOwnerType: stepOwnerType,
          CurrentStepOwner: stepOwner,
          CurrentStepActions: actions,
          CurrentStepStepData: "",
          CurrentStepOwnerLabel: stepOwnerLabel,
        };
        stepConditions.push(stepConditionObj);
      }
      {
        let { stepOwnerType, stepOwner, selectedActionIds, stepOwnerLabel } =
          defaultGroup;
        if (!stepOwnerType)
          return {
            type: "error",
            message: `Step Owner Not Defined For Default Group of ${stepName}`,
          };
        if (
          stepOwnerLabelOptions &&
          stepOwnerLabelOptions.length > 0 &&
          (!stepOwnerLabel || stepOwnerLabel === -1)
        ) {
          return {
            type: "error",
            message: `No Step Owner Label Defined For ${stepName}`,
          };
        }
        let actions = selectedActionIds.map((ele) => {
          const { autoAction, Notifications, nextStep, actionName } = obj[ele];
          let arr = [];
          if (Notifications)
            arr = Notifications.map((ele) => {
              if (!ele) return { NotificationID: "" };
              return ele;
            });
          return {
            Actions: actionName,
            AutoActionEnabled: autoAction === 2 ? "false" : "true",
            NextStepOnActions: [
              {
                NextStepID: getNextStepId(nextStep),
                NextStepNotifications: arr,
              },
            ],
          };
        });
        let stepConditionObj = {
          CurrentStepCondition: "",
          CurrentStepAutoActionTimeout: "",
          CurrentStepOwnerType: stepOwnerType,
          CurrentStepOwner: stepOwner,
          CurrentStepActions: actions,
          CurrentStepStepData: "",
          CurrentStepOwnerLabel: stepOwnerLabel,
        };
        stepConditions.push(stepConditionObj);
      }
      console.log("Escalations: ", StepReminders, " ", StepEscalations);
      // if (StepEscalations.length === 0) {
      //   StepEscalations = [
      //     {
      //       Notifications: [],
      //       RepeatFrequency: "",
      //       RepeatInterval: "",
      //       TriggerTime: "",
      //     },
      //   ];
      // }
      // if (StepReminders.length === 0) {
      //   StepReminders = [
      //     {
      //       Notifications: [],
      //       RepeatInterval: "",
      //       TriggerTime: "",
      //     },
      //   ];
      // }
      let stepObj = {
        StepID: "" + i,
        StepName: stepName,
        StepReminders,
        StepEscalations,
        StepConditions: stepConditions,
        LayoutID: "",
        StepTAT: "",
      };
      steps.push(stepObj);
      //  if(reminders){
      //   let {}=reminders
      //  }
    }
    return {
      type: "Success",
      payload: {
        AuthorFeatureID: paramterState.AuthorFeatureID,
        AuthorFeatureVariantID: "000",
        FrontEndState: JSON.stringify(workflow),
        Steps: steps,
      },
    };
  }
  function onSave() {
    //localStorage.setItem("WorkflowID", "abc");
    // window.onunload = () => {
    //   const data = { message: 'Hello from child window!' };
    //   window.opener.postMessage(data, '*');
    // };

    // const parentWindow = window.opener;

    // const data = { message: "Hello from child window!" };
    // parentWindow.postMessage(data, "*");

    setLoading(true);

    let edges = workflow.states?.edges;
    if (!edges) return;
    let tree = {};

    edges.map(({ source, target }) => {
      if (tree[source]) {
        tree[source] = [...tree[source], target];
      } else {
        tree[source] = [target];
      }
    });
    let validationResult = validate(tree);
    if (validationResult) {
      setLoading(false);
      console.log("in while: valid", validationResult.message);
      setModalError(validationResult.message);
      return;
    }
    let { type, message, payload } = getPayload();
    if (message) {
      setLoading(false);
      setModalError(message);
      return;
    }
    console.log("JSON Payload: ", JSON.stringify(payload));
    let params = { payload, featureId: workflowFeatureID };
    //if (workflow.editMode) params.edit = workflow.editMode;
    console.log("Final Payload: ", JSON.stringify(params));

    submitPayload(params).then((res) => {
      // let result=handleResult(res);
      console.log("Workflow result", handleResult(res));
      if ([1, 2].includes(handleResult(res))) {
        console.log("before chanel: ", channel);
        channel.postMessage({
          WorkflowId: res.TransactionID,
          WorkflowButtonID: paramterState.WorkflowButtonID,
        });
        window.opener.focus();
        window.close();
      }
      console.log("Payload result is: ", res);
    });
    // const channel = new BroadcastChannel("EW-data");

    setLoading(false);
  }
  function setTransactionData(AuthorFeatureID) {
    getFeatureDocument({ FeatureID: AuthorFeatureID }).then((res) => {
      let obj = workflow;
      obj.AuthorFeatureID = AuthorFeatureID;
      if (res && res.TransactionDataFields)
        obj.TransactionDataFields = res.TransactionDataFields;
      else obj.TransactionDataFields = [];
      dispatch({
        type: "ADD_WORKFLOW",
        payload: obj,
      });
    });
  }
  function setDefaultWorkflow(id, AuthorFeatureID, config) {
    console.log("ID is: ", id, " ", AuthorFeatureID);
    if (id) {
      getData({
        featureId: workflowFeatureID,
        payload: {
          "Header.TransactionID": id,
        },
      }).then((res) => {
        console.log("Result from backend: ", res);
        let data = {};
        if (res.Data && res.Data[0] && res.Data[0].FrontEndState) {
          data = JSON.parse(res.Data[0].FrontEndState);
          let temp = res.Data[0];
          data.updateParent = true;
          data.config = config;
          data.editMode = {
            TransactionID: id,
            EffectiveFromTimestamp: temp.EffectiveFromTimestamp,
          };
        }
        data.AuthorFeatureID = AuthorFeatureID;
        getFeatureDocument({ FeatureID: AuthorFeatureID })
          .then((res) => {
            if (res && res.TransactionDataFields)
              data.TransactionDataFields = res.TransactionDataFields;
            else data.TransactionDataFields = [];
            dispatch({
              type: "ADD_WORKFLOW",
              payload: data,
            });
          })
          .catch((e) => {
            console.log("Error: ", e);
            data.TransactionDataFields = [];
            dispatch({
              type: "ADD_WORKFLOW",
              payload: data,
            });
          });
        // dispatch({
        //   type: "ADD_WORKFLOW",
        //   payload: data,
        // });
      });
    } else if (!workflow.states) {
      let obj = workflow;
      //  obj.AuthorFeatureID = AuthorFeatureID;
      //if(!obj.states)
      obj.states = {
        nodes: initialNodes,
        edges: [],
      };
      obj.currentStep = 1;
      obj.config = config;
      obj["1"] = {
        stepName: "Step-1",
      };
      console.log("Check author feature ID: ", AuthorFeatureID);
      if (AuthorFeatureID)
        getFeatureDocument({ FeatureID: AuthorFeatureID }).then((res) => {
          obj.AuthorFeatureID = AuthorFeatureID;
          if (res && res.TransactionDataFields)
            obj.TransactionDataFields = res.TransactionDataFields;
          else obj.TransactionDataFields = [];
          dispatch({
            type: "ADD_WORKFLOW",
            payload: obj,
          });
        });
      else
        dispatch({
          type: "ADD_WORKFLOW",
          payload: obj,
        });
    }
  }
  useEffect(() => {
    channel.addEventListener("message", (event) => {
      if (event.data) {
        console.log("EW Data: ", event.data);
        if (event.data.workflowID)
          setDefaultWorkflow(
            event.data.workflowID,
            event.data.AuthorFeatureID,
            event.data.config
          );
        else
          setDefaultWorkflow(
            null,
            event.data.AuthorFeatureID,
            event.data.config
          );
        setParameterState(event.data);
      } else {
        setDefaultWorkflow(null, event.data.AuthorFeatureID, event.data.config);
      }
    });
    // setDefaultWorkflow(null, "");
    // window.addEventListener("contextmenu", (e) => {
    //   if (
    //     [...document.getElementsByClassName("step-form")].includes(e.target)
    //   ) {
    //     e.preventDefault();
    //     console.log("id is; ", e);
    //     const { pageX, pageY } = e;
    //     setRightCoord({
    //       x: pageX,
    //       y: pageY,
    //       id: e.target.id,
    //     });
    //   } else {
    //     setRightCoord(null);
    //   }
    // });
    // window.addEventListener("click", (e) => {
    //   if (document.getElementById("right-menu") !== e.target) {
    //     setRightCoord(null);
    //   }
    // });
  }, []);

  useEffect(() => {
    console.log("change work");

    if (workflow.updateParent === true) {
      console.log("Workflow changed: ", workflow);
      setNodes([...workflow.states.nodes]);
      setEdges([...workflow.states.edges]);
      let obj = workflow;
      obj.updateParent = false;
      dispatch({
        type: "ADD_WORKFLOW",
        payload: obj,
      });
    }
    // if (FrontEndState && !workflow.states) {
    //   let obj = JSON.parse(FrontEndState);
    //   obj.updateParent = true;
    //   console.log("Default workflow: ", obj);
    //   dispatch({
    //     type: "ADD_WORKFLOW",
    //     payload: obj,
    //   });
    // } else

    //setParameterState(paramObj);

    setPageLoading(false);
  }, [workflow]);
  // useEffect(()=>{
  //   setNodes(initialNodes)
  // },[])
  // const initialNodes = [
  //   { id: 'node-1', type: 'Form', position: { x: 0, y: 0 }, data: { value: 123,setNodes,setEdges } },
  //   {
  //     id: 'node-2', type: 'Form', position: { x:50, y: 0 }, data: { value: 123 }
  //   }
  // ];

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection) => {
      const { source, target } = connection;
      let isSourceActionNode = source.includes("Action");
      let isTargetActionNode = target.includes("Action");
      let edges = store.getState()?.Workflow?.workflow?.states?.edges;
      if (
        edges &&
        source !== target &&
        !(isSourceActionNode && isTargetActionNode) &&
        source !== "end" &&
        // target !== "Step-1" &&
        !(source.includes("Step") && source.split("-").length === 2)
        // !(source === "Step-1" && target === "end") &&
        // !(target === "end" && source.includes("Step")) &&
        // !(target.includes("Step") && source.includes("Step"))
      ) {
        edges.push({
          id: uuid(),
          source,
          target,
        });
        let obj = store.getState().Workflow.workflow;
        obj.states.edges = [...edges];
        console.log("Settings: obj: ", obj);
        dispatch({
          type: "ADD_WORKFLOW",
          payload: obj,
        });
      } else {
        //  if (source.includes("Step"))
        setModalError("Not Allowed");
      }

      // console.log("From Connect: ", connection);
      return setEdges((eds) => addEdge(connection, eds));
    },
    [setEdges]
  );

  function updateCoord(id, x, y) {
    let obj = workflow;
    obj.states.nodes = nodes;
    dispatch({
      type: "ADD_WORKFLOW",
      payload: obj,
    });

    return;
  }
  function deleteNode() {
    let id = rightCoord.id;
    let obj = workflow;
    if (!obj[id]) return;
    let nodes = obj.states.nodes;
    delete obj[id];
    nodes = nodes.filter((item) => {
      if (item.data.id === id) return false;
      return true;
    });
    obj.states.nodes = nodes;
    obj.updateParent = true;
    dispatch({
      type: "ADD_WORKFLOW",
      payload: obj,
    });
  }
  function copy() {}
  const options = [
    {
      icon: "ContentCopy",
      name: "Copy",
      onClick: copy,
    },
    {
      icon: "Delete",
      name: "Delete",
      onClick: deleteNode,
    },
  ];
  if (pageLoading) return <>Loading...</>;
  return (
    <div style={{ height: 800 }}>
      <EwModal open={modalError} onClose={() => setModalError(null)}>
        <div style={{ width: 500 }}>
          <div className="Flex">
            <Alert severity="error">{modalError}</Alert>
            <br />
            <Atomic.Button title="Close" onClick={() => setModalError(null)} />
          </div>
        </div>
      </EwModal>
      {rightCoord?.x && (
        <div
          style={{
            position: "absolute",
            top: rightCoord.y,
            left: rightCoord.x,
            zIndex: 100,
          }}
        >
          <Menu options={options} />
        </div>
      )}
      <ReactFlow
        nodes={nodes}
        edges={workflow?.states?.edges ? workflow.states.edges : []}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        onNodeDragStop={(e) => {
          console.log("Node chanhed: ", e);
          updateCoord(e.target.id, e.pageX, e.pageY);
        }}
        fitView
      >
        <MiniMap />
        <Controls />
      </ReactFlow>
      <div className="Flex">
        <LoadingButton loading={loading} onClick={onSave} variant="contained">
          Save
        </LoadingButton>
      </div>
      <AlertDialog {...alert} />
    </div>
  );
}

export default Workflow;
