import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import Grid from "@mui/material/Grid";

function Repeater(Repeaterprops) {
  console.log("Repeater props: ",Repeaterprops)
  const {
    componentName,
    props,
    config,
    columns,
    arr,
    containerStyles,
    horizontal,
  } = Repeaterprops.props;
  console.log("Props for repeater: ", Repeaterprops);
  const [data, setData] = useState(arr);

  const [loading, setLoading] = useState(false);
  let compName = componentName.split(".");
  let compAddress =
    compName[0] + "Component/" + compName[1] + "/" + compName[2];
  var Comp = require("../../" + compAddress).default;
  useEffect(() => {
    requestData();
  }, []);
  function requestData() {
    if (!config) return;
  }

  const alignStyle = {
    display: "flex",
    // width:  '100%',
    justifyContent: "flex-start",
    flexDirection: "column",
    // height:600,
    overflow: "scroll",
  };
  if (horizontal) {
    alignStyle.flexDirection = "row";
    alignStyle.width = "100%";
  } else {
    alignStyle.height = 600;
  }

  if (loading) return <>Loading</>;
  if (columns && columns <= 12) {
    let Xs = 12 / columns;
    return (
      <>
        <div className="Flex">
          <Grid style={{ padding: 20 }} container spacing={1}>
            {data?.map((ele, index) => (
              <Grid md={Xs} xs={12}>
                <Comp {...ele} key={index} />
                {index % Xs === 0 && <br />}
              </Grid>
            ))}
          </Grid>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="Flex">
        <div style={alignStyle}>
          {data?.map((ele, index) => {
            return (
              <>
                {console.log("from Repeater ele: ", ele, " ", props)}
                <Comp
                  {...props}
                  {...ele}
                  key={index}
                  componentInstanceId={uuid()}
                />
                <br />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Repeater;
