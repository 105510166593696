import { Avatar, Box, Paper, Typography, alpha, useTheme } from "@mui/material";
import React from "react";
import { Handle, Position } from "reactflow";
import { HandleIcon } from "../../../Utils/Common/Icons";
import useReduxStore from "../../../Utils/CustomHooks/ReduxUpdater";

function ActionNode({ data, isConnectable }) {
  const { StepID, ScenarioID, ActionID, styles } = data;
  const [state, updateState] = useReduxStore({ id: "Workflow2" });

  const { ActionLabel, AutoAction } =
    (
      (state.data.steps[StepID].StepScenarios || []).find(
        (s) => s.ScenarioID === ScenarioID
      )?.Actions || []
    ).find((a) => a.ActionID === ActionID) || {};

  const { palette } = useTheme();

  return (
    <Paper
      elevation={3}
      sx={{
        border: `2px solid ${alpha(styles.color, 0.5)}`,
        borderRadius: "28px",
        boxSizing: "border-box",
        position: "relative",
        minHeight: "3em",
      }}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        style={{
          opacity: 0,
          pointerEvents: "none",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
          justifyContent: "center",
          justifyItems: "center",
          height: "100%",
        }}
      >
        {ActionLabel ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "2em",
            }}
          >
            <Typography
              variant="h4"
              sx={{ color: styles.color }}
              fontWeight={"600"}
            >
              {ActionLabel}
            </Typography>
            {AutoAction === "E" && (
              <Avatar
                sx={{ width: 20, height: 20, bgcolor: palette.warning.main }}
              >
                <Typography variant="subtitle2">A</Typography>
              </Avatar>
            )}
          </Box>
        ) : (
          <Typography variant="subtitle1">No Action selected</Typography>
        )}
      </Box>
      {ActionLabel && (
        <Handle
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "1.2em",
            height: "1.2em",
            backgroundColor: `${palette.info.main}`,
            right: "-15px",
          }}
        >
          <HandleIcon />
        </Handle>
      )}
    </Paper>
  );
}

export default ActionNode;
