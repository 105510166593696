import React from "react";
import Atomic from "../../../AComponent/Atomic";
import Molecule from "../../../MComponent/Molecule";
import { Typography } from "@mui/material";
const { EwModal } = Molecule;
const { Dropdown } = Atomic;
function Rating({ onClose }) {
  return (
    <div>
      <EwModal open={true} onClose={onClose}>
        <div
          className="scroll"
          style={{ width: 800, minHeight: 300, maxHeight: 500 }}
        >
          <div className="Flex">
            <Typography variant="h1">Appraisal Rating</Typography>
          </div>
          <br />

        </div>
      </EwModal>
    </div>
  );
}

export default Rating;
