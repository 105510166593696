import React, { useState } from "react";
import Molecule from "../../../MComponent/Molecule";
import DownloadIcon from "@mui/icons-material/Download";
import { Typography } from "@mui/material";
import Atomic from "../../../AComponent/Atomic";
import axios from "axios";
import Loading from "./loading";

const url =
  "https://dp-lambda-bucket.s3.ap-south-1.amazonaws.com/FindAllEPPWithPersonName_1691040882545.json?X-Amz-Security-Token=IQoJb3JpZ2luX2VjEGUaCmFwLXNvdXRoLTEiSDBGAiEA60LoaGRixGnzRt8k%2Bt%2B47JX6LaEKs99T0MYuACGxw3MCIQCVAQmUU9AmM6NwKSt8V9%2B%2BxezzQ2gyXxGVyDg6QODN5yrHBQj%2B%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAEaDDU4NjczNzgzODY4MCIMVknvN%2Fydg%2B2RMoK6KpsFvuJcoR2iLyZA4tM1k6DMaiNdNfNi4xoULqozAKIwaI5QY9n2KSBYy04%2FUJxsAOqTcjC9jtIjGv%2FzjNfCDa%2B77uKfkZr0N03uhSWNcNjt91GrPz7B47lbDbczGFlVCbmikej6Dj5XyupIBcXXIvmTP8MVgTTV1LX%2BjDwTJQSbhj9FCvgVJ1iVAoUzFOD9vwui6VRcqZsWvFTIc6UAktab1W7xLx83QQ8KUKEIoh1S1M2Z1V7mYfrtlR3jsH9oiXS2MvkGq2xW2PpZn2VNiqtf0GStDJiOiJRQnlhg45WEygbZ6X0yPC4SHVSoC%2FGQpy6FY0K6iWIVsItQprbc5uv4WcPfvSXebTGRm8xa5bmOQXdSR01Beqy9b6%2B8WZv45qij3ZaSFSRgoQBG3AWj9u11BFpfOJ81Vh%2BmPbpdT76Sn4lpoR%2BDj%2FMGrREOcKigTWoT5bX9umaKs7K8cpV2CYO1WroTCtX3aLUVP9AH7Ek9wuos1O4jlk%2BOV%2BBhljrUJo1OAo0m18scf4S74yMxHSdecsf7r4zbQQclEqOWaXIz5R9GX%2FVxorMSyDbyGU5NgIdAGex5MkWQ%2B6ssYMSbUCPhrR5g%2F6bQXcvaGAzAeQGly6D3GPtQ6bZdRzRGHaQLNS11jtFJ4yI%2B23fBjLgd06eLQLpvJ%2BCMiIktMrObuwx1ox7RRltdPapGzIm2AHgtOWynMYAkq4rh96JbR7HKUnKK5y5FQA2059yOP0omnNZxP6yHzIuOLqzmvyNZh6mJGwN%2FqS3wGmpRqzkOnO91GpWZLbDCcX%2F5%2FvowdxLUVyt93QCes5%2Bg9gnIYAIJ5XfErd4O1%2FE%2BQiqYFlxTQbMqQEII8V0n37GYyGY%2BUTCAFyIZRrhAD%2BiBmkjpL%2FOO5TDD56ymBjqwAccO1VCab7oQTt186%2BPeHxI%2BTHEUUo5W5Umbtz6RV%2FbMcso5G%2FTiP6cYkziWUFgSH7bPExKGO0s4FCa1lB%2Beqi4KWAwiKp709itp74lcc4mrQ3cdlAWUk8EjnqNxMQYsoN%2B7uzd08WxL%2BCwgBDsHDirZjFyOvhkbqLZFgTmeswG%2BmMgRuMP6meEcZqKE2BCcaWVRbez7DaYhDK81%2BIN0IH9q8rOO0p8YofYpgtwR%2FNJ3&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230803T053442Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3599&X-Amz-Credential=ASIAYRHCM5JMGSWM3IHE%2F20230803%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=34f91291b0f7a6063ef9e279ec73b5b95483070d8238f23923dadba088a3d8ec";

const { Table, EwModal } = Molecule;
const { Button } = Atomic;
const data = [
  ["1st August, 2023", "Report 1", { Component: DownloadComponent }],
];
function DownloadComponent({ status }) {
  const [loading, setLoading] = useState(false);
  function downloadFile() {
    setLoading(true);
    const axiosWithoutAuth = axios.create();
    delete axiosWithoutAuth.defaults.headers.common["Authorization"];
    console.log("Headers: ", axiosWithoutAuth.defaults.headers);
    axiosWithoutAuth({
      url,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "dummy.json");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  }
  if (status === "P") return <Loading />;
  return (
    <>
      <Typography>
        <span onClick={downloadFile} className="pointer">
          {loading ? "Downloading" : <DownloadIcon />}
        </span>
      </Typography>
    </>
  );
}
let obj = data[0];
for (let i = 0; i < 100; i++) {
  if (i === 2) {
    data.push([
      "1st August, 2023",
      "Report 1",
      { Component: DownloadComponent, props: { status: "P" } },
    ]);
    continue;
  }
  data.push(obj);
}
//  data[0][2].props = { status: "P" };
function Reports1({ onClose }) {
  const [open, setOpen] = useState(true);
  return (
    <div>
      <EwModal open={open} onClose={onClose}>
        <div className="scroll" style={{ width: 800, height: 600 }}>
          <Table
            pageLimit={5}
            heading={["Date", "Name", "Downloads"]}
            rows={data}
          />
          <br />
          <div className="Flex">
            <Button
              title={"Genrate Report"}
              onClick={() => {}}
              color={"success"}
            />
          </div>
        </div>
      </EwModal>
    </div>
  );
}

export default Reports1;
