import React, { useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useDispatch, useSelector } from "react-redux";
import useRender from "../utils/useRender";

function Radio_2(props) {
  const { id, compNumber } = props;
  console.log("Radio: ", id, " ", compNumber);
  var { options, label, value } = useSelector(
    (state) => state.DataReducer[id][compNumber]
  );
  const dispatch = useDispatch();
  //const [value, setValue] = React.useState('female');
  useEffect(() => {
    if (typeof options[0] === "string") {
      let arr = options.map((ele) => ({ label: ele, value: ele }));
      dispatch({
        type: "UPDATE_PROPS",
        payload: {
          id,
          componentNumber: compNumber,
          data: arr,
          props: "options",
        },
      });
    }
  });
  const handleChange = (event) => {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: compNumber,
        data: event.target.value,
        props: "value",
      },
    });
  };
  if (!useRender({ id, compNumber })) return <></>;
  return (
    <>
      <br />
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={value}
          onChange={handleChange}
          style={{ display: "flex", flexDirection: "row" }}
        >
          {options.map((ele, index) => {
            return (
              <FormControlLabel
                value={ele.value ? ele.value : ele.label}
                control={<Radio />}
                label={ele.label}
                key={index}
                {...ele.otherProps}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </>
  );
}

export default Radio_2;
