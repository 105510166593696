import { Box, Divider, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Atomic from "../../../../AComponent/Atomic";
import { DimensionScores } from "../Data/DimensionScoreData";
import { getData, getDateFromEpoch } from "../../../../Utils/Common/Library";
import FeatureIDs from "../../../../Utils/Common/FeatureIDs";
import { PeriodMap, PeriodMapper } from "../../Dimension/Phases/PeriodMap";

const { Dropdown } = Atomic;

const DimensionScore = ({ EPP, DimensionScoreRules, handleTabEdit }) => {
  const [dimension, setDimension] = useState();
  const [currentPeriod, setCurrentPeriod] = useState({
    Period: "",
    Value: "",
  });

  function getDate(date) {
    return getDateFromEpoch({ epoch: date });
    const startDate = new Date(parseInt(date));
    return `${startDate.getDate()} ${startDate.toLocaleString("default", {
      month: "short",
    })}`;
  }

  useEffect(() => {
    if (DimensionScoreRules && DimensionScoreRules.DimensionID) {
      getData({
        featureId: FeatureIDs.PerformanceDimensionGrouping,
        payload: {
          "Header.TransactionID": DimensionScoreRules.DimensionID,
        },
        fields: ["Data.DimensionName"],
      }).then((res1) => {
        getData({
          featureId: FeatureIDs.ParameterAsseessment,
          payload: {
            "Data.AppraiseeUserID": EPP.AppraiseeUserID,
            "Data.DimensionID": DimensionScoreRules.DimensionID,
            "Data.EmployeePerformancePlanID": EPP.TransactionID,
          },
          fields: [
            "Data.ReviewPeriodType",
            "Data.ReviewPeriodUnit",
            "Data.ReviewFromDate",
            "Data.ReviewTillDate",
            "Data.PeriodAggregateScore",
          ],
        }).then((res2) => {
          if (
            typeof res2 === "string" ||
            (typeof res2 === "object" && res2.Data && res2.Data.length === 0)
          ) {
            setDimension({
              DimensionName: res1.Data[0].DimensionName,
              Periods: [],
              DimensionAggregateScores: undefined,
            });
            return;
          }
          console.log(res2, "RES258");
          const finalPeriods = [];
          res2.Data.forEach((period) => {
            const scores = period.PeriodAggregateScore;
            let isValid = false;
            scores?.forEach((score) => {
              if (score?.AggregateScore) {
                isValid = true;
              }
            });
            if (isValid) {
              finalPeriods.push(period);
            }
          });
          setDimension({
            DimensionName: res1.Data[0].DimensionName,
            Periods: [...finalPeriods],
            DimensionAggregateScores: Object.entries({
              ...EPP.Dimensions.find(
                (dim) => dim.DimensionID === DimensionScoreRules.DimensionID
              )?.DimensionAggregateScore,
            })
              .map(([key, value]) => {
                if (key.includes("Appraiser")) {
                  return {
                    label: "Appraiser",
                    value,
                  };
                } else {
                  return undefined;
                }
              })
              .filter((score) => score),
          });
          finalPeriods &&
            finalPeriods.length > 0 &&
            setCurrentPeriod({
              Period: { ...finalPeriods[0] },
              Value:
                finalPeriods[0].ReviewPeriodType +
                " " +
                finalPeriods[0].ReviewPeriodUnit,
            });
        });
      });
    }
  }, []);
  function getAggregateScore(score) {
    return parseFloat(score).toFixed(4);
  }

  console.log("EPOP", dimension, EPP);

  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: "0.5em",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      {!dimension ? (
        <Box>Loading scores ...</Box>
      ) : (
        <Box sx={{ padding: "1em" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              padding: "0.7em 0.3em",
              height: "2.5em",
            }}
          >
            <Typography variant="h3" color={"primary"}>
              {dimension.DimensionName}
            </Typography>
            {dimension.Periods && dimension.Periods.length > 0 && (
              <Dropdown
                label={"Review Period"}
                // featureId={FeatureIDs.PerformanceDimensionGrouping}
                // filter={(item) => {
                //   return [
                //     ...Dimensions.map((dimension) => dimension.DimensionID),
                //   ].includes(item.TransactionID);
                // }}
                // mapper={{ label: "DimensionName", value: "TransactionID" }}
                menuItems={[
                  ...dimension.Periods.map((period) => ({
                    label:
                      period.ReviewPeriodType === "I"
                        ? `Interim ${period.ReviewPeriodUnit}`
                        : PeriodMap[period.ReviewPeriodType].values[
                            period.ReviewPeriodUnit
                          ],
                    value:
                      period.ReviewPeriodType + " " + period.ReviewPeriodUnit,
                  })),
                ]}
                onChange={(val) => {
                  if (val === -1) return;
                  const [type, unit] = val.split(" ");
                  const period = dimension.Periods.find(
                    (p) =>
                      p.ReviewPeriodType === type && p.ReviewPeriodUnit === unit
                  );
                  setCurrentPeriod({ Period: period, Value: val });
                }}
                value={currentPeriod.Value || -1}
                size={"small"}
              />
            )}
          </Box>
          {/* <Divider /> */}
          <Box>
            <Typography variant="subtitle2" color={"gray"}>
              {dimension.DimensionName} Aggregate Scores
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {dimension.DimensionAggregateScores ? (
                <Box
                  sx={{ margin: "0.3em" }}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"0.5em"}
                >
                  <Box display={"flex"} gap={"2em"}>
                    {dimension.DimensionAggregateScores?.length > 0 ? (
                      (dimension.DimensionAggregateScores || []).map(
                        ({ label, value }) => {
                          return value ? (
                            <Box>
                              <Typography variant="caption" color={"primary"}>
                                {label}
                              </Typography>
                              <Typography variant="h3" color={"primary"}>
                                {value || "-"}
                              </Typography>
                            </Box>
                          ) : (
                            <></>
                          );
                        }
                      )
                    ) : (
                      <Typography variant="h6" color={"primary"}>
                        No Scores Available
                      </Typography>
                    )}
                  </Box>
                </Box>
              ) : (
                <Typography variant="h6" color={"primary"}>
                  No Scores Available
                </Typography>
              )}
            </Box>
          </Box>
          <br />
          <Box>
            <Typography variant="subtitle2" color={"gray"}>
              Period Aggregate Scores
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {DimensionScoreRules.CurrentCycleAggregateScore === "E" &&
                (dimension.Periods &&
                dimension.Periods.length > 0 &&
                currentPeriod.Value &&
                currentPeriod.Period ? (
                  <Box
                    sx={{ margin: "0.3em" }}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"0.5em"}
                  >
                    <Box display={"flex"} gap={"0.5em"} alignItems={"center"}>
                      <Typography variant="subtitle1">
                        {currentPeriod.Period.ReviewPeriodType === "I"
                          ? `Interim (${
                              PeriodMap[
                                PeriodMapper(
                                  currentPeriod.Period.ReviewPeriodUnit
                                )
                              ].values[currentPeriod.Period.ReviewPeriodUnit]
                            })`
                          : PeriodMap[currentPeriod.Period.ReviewPeriodType]
                              .values[currentPeriod.Period.ReviewPeriodUnit]}
                      </Typography>
                      <Typography variant="caption">
                        {getDate(currentPeriod.Period.ReviewFromDate) +
                          " - " +
                          getDate(currentPeriod.Period.ReviewTillDate)}
                      </Typography>
                    </Box>
                    <Box display={"flex"} gap={"2em"}>
                      {currentPeriod?.Period?.PeriodAggregateScore.map(
                        (score) => score.AggregateScore
                      ).filter(
                        (score) =>
                          score !== "" && score !== "NaN" && +score !== 0
                      ).length > 0 ? (
                        (currentPeriod?.Period?.PeriodAggregateScore || []).map(
                          ({ ActorType, AggregateScore }) => {
                            return AggregateScore &&
                              ActorType === "Appraiser" &&
                              AggregateScore !== 0 ? (
                              <Box>
                                <Typography variant="caption" color={"primary"}>
                                  {ActorType}
                                </Typography>
                                <Typography variant="h3" color={"primary"}>
                                  {getAggregateScore(AggregateScore) || "-"}
                                </Typography>
                              </Box>
                            ) : (
                              <></>
                            );
                          }
                        )
                      ) : (
                        <Typography variant="h6" color={"primary"}>
                          No Scores Available
                        </Typography>
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Typography variant="h6" color={"primary"}>
                    No Scores Available
                  </Typography>
                ))}
              {/* {DimensionScoreRules.CurrentCycleModeratedScore === "E" && (
                <Box sx={{ margin: "0.3em" }}>
                  <Typography variant="overline">
                    Current cycle moderated score
                  </Typography>
                  <Typography variant="h6" fontWeight={"400"}></Typography>
                </Box>
              )}
              {DimensionScoreRules.PastTwoCycleAggregateScore === "E" && (
                <Box sx={{ margin: "0.3em" }}>
                  <Typography variant="overline">
                    Past 2 cycles aggregated score
                  </Typography>
                  <Typography variant="h6" fontWeight={"400"}></Typography>
                </Box>
              )}
              {DimensionScoreRules.PastTwoCycleModeratedScore === "E" && (
                <Box sx={{ margin: "0.3em" }}>
                  <Typography variant="overline">
                    Past 2 cycles moderated score
                  </Typography>
                  <Typography variant="h6" fontWeight={"400"}></Typography>
                </Box>
              )} */}
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default DimensionScore;
