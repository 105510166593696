import * as Validators from "./library";

async function Evaluate(expression, value, otherDetails) {
  console.log("From Evalutae: ", expression, value);
  expression = expression.split("");
  var stack = [];
  var i = 0;
  var count = 0;
  var method = "";
  while (i < expression.length) {
    if (expression[i] === " " && count % 2 === 0) {
      i++;
      continue;
    }
    if (expression[i] === "[") {
      stack.push("[");
      method = "";
    } else if (expression[i] === "]") {
      if (method !== "") {
        let flag = await evaluateMethod(method, value, otherDetails);
        stack.push(flag);
      }
      stack.push("]");
      method = "";
    } else if (expression[i] === "&" || expression[i] === "|") {
      stack.push(await evaluateMethod(method, value, otherDetails));
      method = "";
      stack.push(expression[i] + expression[i + 1]);
      i += 2;
      continue;
    } else if (expression[i] === "'") {
      count++;
      method += expression[i];
    } else method += expression[i];

    i++;
  }

  // console.log('STack: 1', evaluateStack(stack));
  let data = evaluateStack(stack);
  console.log("before sending :", data);
  return data;
}

function evaluateStack(stack) {
  var result = null;

  // var i=0;
  var count = 0;
  console.log("From stack : ", stack);
  var complete = true;
  while (complete && count < 5000) {
    count++;
    var i = 0;
    console.log("called ", i);
    if (stack.length === 3) {
      stack[0] = stack[1];
      stack.splice(1, 2);
      break;
    }
    while (i < stack.length) {
      console.log("called inside", i);
      if (!stack[i]) {
        complete = false;
        stack = [false];
        break;
      }
      if (stack[i] === "[") {
        if (
          (stack[i + 1] === true || stack[i + 1] === false) &&
          (stack[i + 3] === true || stack[i + 3] === false)
        ) {
          if (result === null) result = stack[i + 1];
          if (stack[i + 2] === "&&") {
            result = result && stack[i + 3];
          } else if (stack[i + 2] === "||") {
            result = result || stack[i + 3];
          }

          stack[i] = result;
          stack.splice(i + 1, 4);
          console.log("STack: ", stack);
          if (stack.length === 1) {
            complete = false;
            break;
          }
        }
      }
      i++;
    }
  }
  console.log("Before pop : ", stack);
  return stack.pop();
}

function evaluateMethod(method, value, otherDetails) {
  method = method.split("");
  var params = "";
  var functionName = "";
  var i = 0;
  var paramsObj = {};
  console.log("From Expression: obj ", method);
  while (i < method.length) {
    if (method[i] === "(") {
      let j = i + 1;

      if (method[j] === "{") {
        let count = 0;
        let objString = "{";
        // let k=j;
        while (method[j++] !== "}" || count === 0) {
          if (method[j] === "{") count++;
          if (method[j] === "}") count--;
          objString += method[j];
        }

        paramsObj = JSON.parse(objString.replaceAll("'", '"'));
      }
      while (method[j] !== ")") params += method[j++];
      i = j + 1;
      continue;
    }
    functionName += method[i];
    i++;
  }
  console.log("All params: ", params);
  // else if(expression[i]==='{'){

  // }

  params = params.split(",").map((ele) => {
    if (ele >= "0" && ele <= "9") return parseInt(ele);
    var temp = ele.split("");
    if (temp[0] === "'") {
      temp.splice(0, 1);
      temp.pop();
      return temp.join("");
    }
    return ele;
  });

  return new Promise((resolve, reject) => {
    var result = Validators[functionName](
      value,
      ...params,
      paramsObj,
      otherDetails
    );
    console.log("From Evaluate Method: obj ", functionName, value, result);
    if (result instanceof Promise) {
      result.then((res) => resolve(res)).catch(() => reject(false));
    } else resolve(result);
  });
}

//"Expression[InputLength(4,6) || [isEqual('Atreya') && testFunc()]]",
export default Evaluate;
