import React, { useEffect, useState } from 'react'

import EditIcon from '@mui/icons-material/Edit';
//import TextField from '@mui/material/TextField'
import TextField from '@mui/material/TextField'
import { useDispatch, useSelector } from 'react-redux'
import HandleCondition from '../../Utils/Condition'
import Evaluate from '../../Utils/Validators/ExpressionHandler'
import useRender from '../utils/useRender'
import store from '../../Redux/Store'

function Input_2({ id, compNumber }) {
  const props = useSelector((state) =>
    JSON.parse(JSON.stringify(state.DataReducer[id][compNumber])),
  )

  var {
    Placeholder,
    suffix,
    label,
    variant,
    renderNot,
    value,
    styles,
    appendStyles,
    MUIProps,
    Validation,
    ErrorMsg,
    Group,
    type,
    validation,
    editMode
  } = props

  const dispatch = useDispatch()
 
  useEffect(() => {
    if (value?.charAt(0) === '#') {
      let temp = value.substring(1, value.length)
      let data = store.getState().DataReducer[id]
      let result="";
      try{
      
       result = eval(temp)
      }catch(e){
        result="";
      }
     
      dispatch({
        type: 'UPDATE_PROPS',
        payload: {
          id,
          componentNumber: compNumber,
          data: result,
          props: 'value',
        },
      })
    }
  }, [])
  console.log('From Input_2: ', id, ' ', compNumber, ' ', ErrorMsg)

  const [error, setError] = useState(false)
  //  const [msg,setMsg]=useState(ErrorMsg);
  const [loading, setLoading] = useState(false)
  function textChange(e) {
    let val = e.target.value
    //  console.log('Text Change ', val, ' ', props, ' ', Evaluate(Validation, val))
    //if(Validation)setError(!Evaluate(Validation,val))

    dispatch({
      type: 'UPDATE_PROPS',
      payload: {
        id,
        componentNumber: compNumber,
        data: val,
        props: 'value',
      },
    })
  }
  function Validate() {
    setError(false)
    if (Validation) {
      console.log('Validationq1 ', Validation)
      setLoading(true)
      Evaluate(Validation, value, { id, compNumber })
        .then((res) => {
          setLoading(false)
          setError(!res)
        })
        .catch((e) => {
          console.log('from Catch ', e)
          setLoading(false)
          setError(true)
          //  setMsg("Network Error")
          //  setError("Network Error! ")
        })
    }
  }

  function changeEditMode(){
    dispatch({
      type: 'UPDATE_PROPS',
      payload: {
        id,
        componentNumber: compNumber,
        data: false,
        props: 'editMode',
      },
    })
  }
  if (!useRender({ id, compNumber })) return <></>
  if(editMode){
    return(
      <>
      <div className='Flex' style={{flexDirection:'row',justifyContent:'space-between'}}>
      {`${label} : ${value}  `}
   
      <span style={{cursor:'pointer'}} onClick={()=>changeEditMode()}> <EditIcon  /></span>
      </div>
      <br />
      </>
    )
  }
  return (
    <>
      <div style={{ margin: 10 }}>
        <TextField
          // type="number"
          onBlur={Validate}
          multiline
          type={type ? type : 'text'}
          helperText={validation?.notValid && validation?.errors?.at(-1)}
          error={validation?.notValid}
          style={{ ...styles, ...appendStyles }}
          value={value ? value : ''}
          onChange={textChange}
          placeholder={Placeholder}
          label={label}
          //  variant={variant?variant:"standard"}
          {...MUIProps}
          inputProps={MUIProps}
          {...props}
        />
        {suffix?.value && (
          <span style={{ ...suffix?.style, marginLeft: 20 }}>
            {suffix.value}
          </span>
        )}
        {loading && (
          <img
            src="https://hackernoon.com/images/0*4Gzjgh9Y7Gu8KEtZ.gif"
            height={100}
            width={200}
            alt="Loading"
          />
        )}
      </div>
      {/* <br /> */}
    </>
  )
}

export default Input_2
