const TempJson = [

    {
        transactionId : "1",
        userId : "user1",
        company : "company1",
        action  : "leaveReq"
    },

    {
        transactionId : "2",
        userId : "user2",
        company : "company1",
        action  : "leaveReq"
    },

    {
        transactionId : "3",
        userId : "user3",
        company : "company1",
        action  : "leaveReq"
    },

    {
        transactionId : "4",
        userId : "user4",
        company : "company1",
        action  : "leaveReq"
    },
    
]

export default TempJson;