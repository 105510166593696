import { Box, Typography } from "@mui/material";
import React, { useCallback } from "react";
import StepAccordian from "../UI/StepAccordian";
import Atomic from "../../../../AComponent/Atomic";
import "../UI/temp.css";
import { Add } from "@mui/icons-material";
import Molecule from "../../../../MComponent/Molecule";
import { Email, N2A, SMS, WhatsApp } from "./Notifications";
import { v4 as uuid } from "uuid";
import NumericInput from "../UI/NumericInput";

const { Radio, Dropdown, Button } = Atomic;

const { Tabs } = Molecule;
const TimePeriodItems = [
  { label: "Months", value: "M" },
  { label: "Hours", value: "H" },
  { label: "Days", value: "D" },
  { label: "Weeks", value: "W" },
];

function EscalationsForm({ initEscalation, onChange }) {
  const {
    AnchorEvent,
    TriggerTime,
    TriggerTimeUnit,
    Frequency,
    RepeatInterval,
    Notifications,
  } = initEscalation;

  const componentList = [
    {
      label: "Email",
      Component: Email,
      props: {
        Notification: Notifications[0] || {},
        onChange: (notification) => {
          Notifications[0] = notification;
          onChange({ ...initEscalation, Notifications });
        },
      },
    },
    {
      label: "SMS",
      Component: SMS,
      props: {
        Notification: Notifications[1] || {},
        onChange: (notification) => {
          Notifications[1] = notification;
          onChange({ ...initEscalation, Notifications });
        },
      },
    },
    {
      label: "WhatsApp",
      Component: WhatsApp,
      props: {
        Notification: Notifications[2] || {},
        onChange: (notification) => {
          Notifications[2] = notification;
          onChange({ ...initEscalation, Notifications });
        },
      },
    },
    {
      label: "Need-2-Act",
      Component: N2A,
      props: {
        Notification: Notifications[3] || {},
        onChange: (notification) => {
          Notifications[3] = notification;
          onChange({ ...initEscalation, Notifications });
        },
      },
    },
  ];

  return (
    <Box maxHeight={"450px"} className="invisble-scroll">
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        alignItems={"flex-end"}
        gap={"1em"}
        paddingLeft={"0.5em"}
      >
        <Typography variant="subtitle2">After</Typography>
        <NumericInput
          value={TriggerTime}
          onChange={(val) => {
            onChange({ ...initEscalation, TriggerTime: val });
          }}
        />
        <Dropdown
          label={"Time Period"}
          menuItems={[...TimePeriodItems]}
          onChange={(value) => {
            onChange({ ...initEscalation, TriggerTimeUnit: value });
          }}
          value={TriggerTimeUnit}
          styles={{ width: "5em" }}
          variant={"standard"}
        />
        <Typography variant="subtitle2">
          of sending escalations, send escalation
        </Typography>
        <Radio
          flex={1}
          menuItems={[
            { label: "Once", value: "Once" },
            { label: "Every (Repeatedly)", value: "Every" },
          ]}
          onChange={(value) => {
            let freq = Frequency;
            if (value === "Every") {
              freq = "R" + freq.charAt(1);
            } else {
              freq = "SI";
            }
            onChange({ ...initEscalation, Frequency: freq });
          }}
          value={
            Frequency ? (Frequency.charAt(0) === "R" ? "Every" : "Once") : -1
          }
        />
        <NumericInput
          value={RepeatInterval}
          onChange={(value) => {
            onChange({ ...initEscalation, RepeatInterval: value });
          }}
        />
        <Dropdown
          label={"Time Period"}
          menuItems={[...TimePeriodItems]}
          onChange={(value) => {
            let freq = Frequency;
            freq = freq.charAt(0) + value;
            onChange({ ...initEscalation, Frequency: freq });
          }}
          value={Frequency ? Frequency.charAt(1) : -1}
          styles={{ width: "5em" }}
          variant={"standard"}
        />
        <Typography variant="subtitle2">using: </Typography>
      </Box>
      <br />
      <Tabs componentList={componentList} />
    </Box>
  );
}

function StepEscalations({ StepEscalations, updateState }) {
  const saveEscalations = useCallback(
    (escalation) => {
      const Escalations = [...StepEscalations];
      const index = StepEscalations.findIndex(
        (n) => n.EscalationID === escalation.EscalationID
      );

      if (index === -1) {
        Escalations.push(escalation);
      } else {
        Escalations[index] = escalation;
      }
      updateState(Escalations, "StepEscalations");
    },
    [StepEscalations, updateState]
  );

  return (
    <Box position={"relative"}>
      <Typography variant="h4" fontWeight={"600"}>
        Escalations
      </Typography>
      <Box
        mt={"1em"}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.6em",
          padding: "0.5em",
        }}
        height={"60vh"}
        className="invisble-scroll"
      >
        {StepEscalations.length === 0 ? (
          <Box className="Flex">No Escalations available</Box>
        ) : (
          StepEscalations.map((escalation) => {
            return (
              <StepAccordian
                Name={escalation.EscalationName}
                onNameChange={(val) => {
                  const updatedEscalation = { ...val };
                  updatedEscalation.EsscalationName = val;
                  saveEscalations(updatedEscalation);
                }}
                key={escalation.EscalationID}
              >
                <EscalationsForm
                  initEscalation={escalation}
                  onChange={saveEscalations}
                />
              </StepAccordian>
            );
          })
        )}
      </Box>
      <br />
      <Button
        style={{ position: "absolute", bottom: "-1.5em", left: 0 }}
        variant={"text"}
        otherProps={{ endIcon: <Add /> }}
        onClick={() => {
          saveEscalations({
            EscalationID: uuid(),
            EscalationName: `Escalation ${StepEscalations.length + 1}`,
            AnchorEvent: "After",
            TriggerTime: "",
            Frequency: "",
            RepeatInterval: "",
            Notifications: [null, null, null, null],
          });
        }}
        title={"Escalation"}
      />
    </Box>
  );
}

export default StepEscalations;
