import React from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    // top: `${top}%`,
    // left: `${left}%`,
    top: "50%",
    left: "50%",
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "80%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const initialProps = {
  Popen: null,
};

function EWModal(props = initialProps) {
  const { Popen, children } = props;
  const dispatch = useDispatch();
  console.log("Children ", children);
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(Popen);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch({
      type: "MODAL_UNSET",
    });
  };

  const body = (
    <>
      {/* <Paper className="Flex" style={{ marginTop: 50 }} elevation={3}> */}

      {children}
      <br />

      <br />
      {/* </Paper> */}
    </>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        style={{ overflow: "scroll", padding: 30 }}
      >
        <div>
          <div className="Flex">
            <Button onClick={handleClose} variant="contained" type="success">
              Close Modal
            </Button>
          </div>
          <br />
          {body}
        </div>
      </Modal>
    </div>
  );
}

export default EWModal;
