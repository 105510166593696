import { Box, Chip, Divider, Typography } from "@mui/material";
import React, { useState } from "react";

const initTrainingNeedsState = {
  enabled: true,
  label: "Training Needs",
  description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
    Proin vitae risus augue. Sed feugiat turpis vel euismod laoreet. 
    Morbi magna dolor, tincidunt quis quam nec, vehicula consectetur augue.`,
  dimensions: ["Goals", "Competency", "Values", "Skills"],
};

const TrainingNeeds = () => {
  const [trainingNeedsState, setTrainingNeedsState] = useState(
    initTrainingNeedsState
  );
  return trainingNeedsState.enabled ? (
    <Box>
      <Typography variant="h4" sx={{ margin: "0.5em 0" }}>
        {trainingNeedsState.label}
      </Typography>
      <Divider />
      <Typography variant="body1" sx={{ margin: "0.5em 0" }}>
        {trainingNeedsState.description}
      </Typography>
      <Typography variant="h5" sx={{ margin: "1em 0 0.5em 0" }}>
        Dimensions Linked
      </Typography>
      <Box sx={{ display: "flex", margin: "1em 0" }}>
        {trainingNeedsState.dimensions.map((dim, idx) => (
          <Chip key={dim} label={dim} sx={{ marginRight: "0.5em" }} />
        ))}
      </Box>
    </Box>
  ) : (
    <>This section is disabled</>
  );
};

export default TrainingNeeds;
