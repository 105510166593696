export const initWorkflowDocument = {
  Steps: [
    {
      StepID: "000",
      StepName: "Step 1 - Initiation",
      StepTAT: "",
      StepTATUnit: "",
      StepScenarios: [
        {
          ScenarioID: "000",
          ScenarioName: "Default Scenario",
          StepOwnerType: "",
          StepOwnerLevel: "",
          Actions: [
            {
              ActionID: "IN",
              ActionLabel: "Submit",
              AutoAction: "D",
              AutoActionTime: "",
              AutoActionTimeUnit: "",
              ActionConversation: "",
              Alerts: "D",
              Notifications: [null, null, null, null],
            },
          ],
        },
      ],
      StepReminders: [],
      StepEscalations: [],
    },
    {
      StepID: "999",
      StepName: "Completed",
      StepTAT: "",
      StepTATUnit: "",
      StepScenarios: [],
      StepReminders: [],
      StepEscalations: [],
    },
  ],
};
