import React from "react";
import Slider from "@mui/material/Slider";
import useProps from "../../Utils/CustomHooks/GetProps";
const initialProps = {
  label: null,
  value: null,
  onChange: null,
};

function EwSlider(props=initialProps) {
  var { label, value, onChange } = useProps(props);
function handleChange(e,val){
    console.log(val);
    onChange(val)
}
  return (
    <>
      <Slider
        getAriaLabel={() => label}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
      />
    </>
  );
}

export default EwSlider;
