import { Actions } from '../Actions/Actiontypes'


const initialState={};

function Workflow(state=initialState,action){
    switch(action.type){
        case Actions.ADD_WORKFLOW:{
            console.log("reducer workflow: ",action.payload)
            return{
                ...state,
                workflow:action.payload
            }
        }
        default: return state
        

    }
}

export default Workflow;