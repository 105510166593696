import { useState, useEffect } from "react";
import { Handle, Position } from "react-flow-renderer";

import {
  Paper,
  Button,
  Modal,
  IconButton,
  Divider,
  Dialog,
} from "@mui/material";
import { conditionsData, constants, stepOwners, actions } from "./stepFormData";
import { useDispatch, useSelector } from "react-redux";
import CableRoundedIcon from "@mui/icons-material/CableRounded";
import { Typography, Chip } from "@mui/material";
import EwAccordion from "../../MComponent/Accordion/Accordion";
import DeleteIcon from "@mui/icons-material/Delete";
//import Applicability from "../../MComponent/Applicability/Applicability4";
import Radio_3 from "../../AComponent/Radio/Radio_3";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";
// import MultiSelect from "../../AComponent/Dropdown/MultiSelect";
import Notification from "../../MComponent/Notification/Notification";
import Atomic from "../../AComponent/Atomic";
import Applicability from "../../MComponent/Applicability/Applicability5";
import { getData, getFeatureDocument } from "../../Utils/Common/Library";
import StepFormModal from "./StepFormModal/StepFormModal";
import FeatureIDs from "../../Utils/Common/FeatureIDs";
const { Dropdown, Input, MultiSelect } = Atomic;
// import Notification from "../../MComponent/Notification/Notification";

const handleStyle = { left: 10 };

const styles = {
  modalStyle: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
    width: "80%",
  },
  paper: {
    width: "60%",
    padding: 50,
    flexDirection: "column",
    display: "flex",
    position: "relative",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function StepForm({ data }) {
  const { id } = data;
  console.log("Step Data: ", data);
  const [modal, setModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [rightCoords, setRightCoords] = useState({
    x: null,
    y: null,
  });
  const workflow = useSelector((state) => {
    console.log("state ", state);
    if (state.Workflow.workflow)
      return JSON.parse(JSON.stringify(state.Workflow.workflow));
    else return {};
  });
  const dispatch = useDispatch();
  //   useEffect(()=>{
  // console.log("Changes: ",transactionData)
  //   },[transactionData])
  // useEffect(() => {
  //   if (workflow.AuthorFeatureID) {
  //     getFeatureDocument({ FeatureID: workflow.AuthorFeatureID }).then(
  //       (res) => {
  //         console.log("From td: ", res);
  //         if (res && res.TransactionDataFields) {
  //           console.log("From td: 1", res.TransactionDataFields);
  //           setTransactionData([...res.TransactionDataFields]);
  //         }
  //       }
  //     );
  //   }
  // }, []);
  function setWorkflow(workflow) {
    dispatch({
      type: "ADD_WORKFLOW",
      payload: workflow,
    });
  }

  function Spacer() {
    return <span style={{ margin: 10 }}></span>;
  }

  function DeleteCase(groupId, caseId) {
    function deleteCase() {
      let obj = workflow;
      let arr = obj[id].groups[groupId].cases;
      console.log("arr: case ", obj[id].groups[groupId]);
      arr.splice(caseId, 1);
      obj[id].groups[groupId].cases = [...arr];
      if (arr.length === 0) {
        let groups = obj[id].groups;
        groups.splice(groupId, 1);
        obj[id].groups = [...groups];
      }
      setWorkflow(obj);
    }
    return (
      <>
        <DeleteIcon
          onClick={deleteCase}
          style={{ color: "red", cursor: "pointer" }}
        />
      </>
    );
  }

  function renderApplicablity(appObj, caseId, groupId, reviewMode) {
    const appId = appObj.value;
    // if (reviewMode)
    //   return (
    //     <>
    //   <Applicability AuthorFeatureID={"WorkflowID"} value={appId}   />
    //       {/* <span>Applicability: {appId && appId}</span> */}
    //     </>
    //   );
    function appIdChanged(id) {
      let obj = workflow;
      obj[id].groups[groupId].cases[caseId] = id;
      setWorkflow(obj);
    }
    return (
      <div className="Flex-row g20">
        <Typography>Applicability</Typography>
        {workflow.AuthorFeatureID && (
          <Applicability
            AuthorFeatureID={workflow.AuthorFeatureID}
            value={appId}
            onChange={appIdChanged}
            ApplicabilityReferenceField="StepApp"
          />
        )}

        {DeleteCase(groupId, caseId)}
      </div>
    );
  }
  function renderDefault(caseId, groupId, defaultValue) {
    function getFilteredMenur(menuItems) {
      let cases = workflow[id].groups[groupId].cases;
      console.log("Before mnue: 1", cases);
      if (!cases || cases.length === 0) return menuItems;
      let set = new Set();
      cases.forEach(({ type }) => {
        set.add(type);
      });

      menuItems = menuItems.filter(({ value }) => {
        if (set.has(value) && value !== constants.TD) return false;
        return true;
      });
      console.log("Before mnue: ", set, " ", menuItems);
      return menuItems;
    }
    return (
      <div className="Flex-row">
        <Dropdown
          menuItems={getFilteredMenur(conditionsData)}
          value={defaultValue ? defaultValue : -1}
          onChange={(val) => {
            let obj = workflow;
            obj[id].groups[groupId].cases[caseId] = {
              type: val,
              value: {},
            };
            setWorkflow({ ...obj });
          }}
        />
        {DeleteCase(groupId, caseId)}
      </div>
    );
  }
  function renderComparator(caseId, groupId, type) {
    let flag = "N";
    let comparators = [
      { label: "Greater Than", value: 0 },
      { label: "Less Than", value: 1 },
      { label: "Greater Than Or Equal To", value: 2 },
      { label: "Less Than Or Equal To", value: 3 },
      { label: "Between", value: 4 },
    ];
    if (type === "string") {
      flag = "S";
      comparators = [
        { label: "Equals To", value: 5 },
        { label: "Not Equals To", value: 6 },
      ];
    }

    let { comparator, from, to, value } =
      workflow[id].groups[groupId]?.cases[caseId].value || {};
    function updateValue(value) {
      let obj = workflow;
      console.log("Before setting: ", obj[id], " ", caseId, " ", groupId);
      let temp = obj[id].groups[groupId].cases[caseId];
      if (!temp) temp = {};
      let prevVal = temp.value;
      if (!prevVal) prevVal = {};
      obj[id].groups[groupId].cases[caseId] = {
        ...temp,
        value: {
          ...prevVal,
          ...value,
        },
      };
      setWorkflow(obj);
    }
    function renderBetween() {
      function updateBetween(type, val) {
        updateValue({
          comparator,
          [type]: val,
        });
      }
      return (
        <div className="Flex-row">
          <div>
            <Input
              label={"From"}
              value={from}
              onChange={(val) => updateBetween("from", val)}
              type={"number"}
            />
          </div>
          <div>
            <Input
              label={"To"}
              value={to}
              onChange={(val) => updateBetween("to", val)}
              type={"number"}
            />
          </div>
        </div>
      );
    }
    function renderNumInput() {
      return (
        <div>
          <Input
            type={"number"}
            label={"Value"}
            value={value}
            onChange={(val) => {
              updateValue({
                comparator,
                value: val,
              });
            }}
          />
        </div>
      );
    }
    function renderStringInput() {
      return (
        <div>
          <Input
            label={"Value"}
            value={value}
            onChange={(val) => {
              updateValue({
                comparator,
                value: val,
              });
            }}
          />
        </div>
      );
    }
    return (
      <>
        <div className="Flex-row g20">
          <div>
            <Dropdown
              Label={""}
              menuItems={comparators}
              value={comparator}
              onChange={(val) => {
                updateValue({
                  comparator: val,
                });
              }}
            />
          </div>
          {comparator && comparator !== -1 && (
            <>
              {comparator === 4
                ? renderBetween()
                : [5, 6].includes(comparator)
                ? renderStringInput()
                : renderNumInput()}
            </>
          )}
        </div>
      </>
    );
  }
  function renderAge(caseId, groupId) {
    return (
      <div className="Flex-row g20">
        <Typography>Age </Typography>
        {renderComparator(caseId, groupId)}
        {DeleteCase(groupId, caseId)}
      </div>
    );
  }
  // function renderAge(ageObj, caseId, groupId, reviewMode) {
  //   const comparator = [
  //     { label: "Greater Than", value: 0 },
  //     { label: "Less Than", value: 1 },
  //     { label: "Greater Than Or Equal To", value: 2 },
  //     { label: "Less Than Or Equal To", value: 3 },
  //     { label: "Between", value: 4 },
  //   ];
  //   let compare = workflow[id].groups[groupId]?.cases[caseId].value?.comparator;
  //   function changeComparator(value) {
  //     let obj = workflow;
  //     obj[id].groups[groupId].cases[caseId] = {
  //       type: constants.Age,
  //       value: {
  //         //...obj[id].groups[groupId].cases[caseId].value,
  //         comparator: value,
  //       },
  //     };
  //     setWorkflow(obj);
  //   }
  //   function RenderBetween() {
  //     var { from, to } = workflow[id].groups[groupId].cases[caseId].value;
  //     const [from1, setFrom] = useState(from);
  //     const [to1, setTo] = useState(to);
  //     function updateBetween(type, value) {
  //       console.log("Between: ", value);
  //       if (value < 0) return;
  //       let obj = workflow;
  //       obj[id].groups[groupId].cases[caseId] = {
  //         type: constants.Age,
  //         value: {
  //           ...obj[id].groups[groupId].cases[caseId].value,
  //           [type === 0 ? "from" : "to"]: value,
  //         },
  //       };
  //       setWorkflow(obj);
  //     }
  //     return (
  //       <>
  //         <Input
  //           label={"From"}
  //           onBlur={(value) => updateBetween(0, value)}
  //           value={from1}
  //           onChange={(val) => setFrom(val)}
  //           type="number"
  //         />
  //         <div style={{ marginTop: 25 }}>
  //           <Input
  //             label={"To"}
  //             onBlur={(value) => updateBetween(1, value)}
  //             value={to1}
  //             onChange={(val) => setTo(val)}
  //             type="number"
  //             error={parseInt(to) < parseInt(from)}
  //             helperText={to < from ? "To should be greater than from" : " "}
  //           />
  //         </div>

  //         <br />
  //       </>
  //     );
  //   }
  //   function RenderAgeInput() {
  //     const age = workflow[id].groups[groupId].cases[caseId].value?.age;

  //     const [localAge, setLocalAge] = useState(age);
  //     function updateAge(value) {
  //       let obj = workflow;
  //       obj[id].groups[groupId].cases[caseId] = {
  //         type: constants.Age,
  //         value: {
  //           ...obj[id].groups[groupId].cases[caseId].value,
  //           age: value,
  //         },
  //       };
  //       setWorkflow(obj);
  //     }
  //     return (
  //       <>
  //         <Input
  //           value={localAge}
  //           label={"Age"}
  //           type="number"
  //           onChange={(val) => setLocalAge(val)}
  //           onBlur={updateAge}
  //         />
  //       </>
  //     );
  //   }
  //   function getCompareValue(comp) {
  //     if (comp === -1) return "";
  //     for (let i = 0; i < comparator.length; i++) {
  //       if (comparator[i].value === comp) return comparator[i].label;
  //     }
  //     return "";
  //   }
  //   var value = workflow[id].groups[groupId].cases[caseId]?.value;
  //   if (reviewMode)
  //     return (
  //       <>
  //         <span>
  //           Age: {getCompareValue(compare)}{" "}
  //           {compare === 4 ? <p>{value.from + " To " + value.to}</p> : value}{" "}
  //         </span>
  //       </>
  //     );
  //   return (
  //     <>
  //       <div className="Flex" style={{ flexDirection: "row" }}>
  //         {renderDefault(caseId, groupId, ageObj.type)}
  //         <span style={{ margin: 10 }}></span>
  //         <Dropdown
  //           menuItems={comparator}
  //           label={"Select Comparator"}
  //           onChange={changeComparator}
  //           value={compare}
  //         />
  //         {compare &&
  //           compare !== -1 &&
  //           (compare === 4 ? RenderBetween() : RenderAgeInput(comparator))}
  //         {DeleteCase(groupId, caseId)}
  //       </div>
  //     </>
  //   );
  // }
  function renderGender(genderObj, caseId, groupId, reviewMode) {
    const { list } = workflow[id].groups[groupId].cases[caseId]?.value;
    const genders = [
      {
        label: "Male",
      },
      { label: "Female" },
      { label: "Other" },
    ];
    function changeGender(val) {
      let obj = workflow;
      obj[id].groups[groupId].cases[caseId] = {
        type: constants.Gender,
        value: {
          list: val,
        },
      };
      setWorkflow(obj);
    }
    return (
      <>
        <div className="Flex-row g20">
          {/* {renderDefault(caseId, groupId, genderObj.type)} */}
          <Typography>Gender</Typography>
          {/* <Spacer /> */}
          {/* <Radio_3
            label={"Gender"}
            menuItems={genders}
            onChange={changeGender}
            value={value}
          /> */}
          <MultiSelect
            featureId={"10158"}
            label={"Gender"}
            mapper={{
              label: "GenderName",
              value: "TransactionID",
            }}
            value={list}
            onChange={changeGender}
          />

          {DeleteCase(groupId, caseId)}
        </div>
      </>
    );
  }
  function renderTenure(groupId, caseId) {
    let { tenureType } = workflow[id].groups[groupId].cases[caseId]?.value;
    let menu = [
      { label: "Current Legal Entity", value: "C" },
      { label: "All Legal Entity", value: "T" },
    ];
    function changeRadio(val) {
      let obj = workflow;
      obj[id].groups[groupId].cases[caseId].value.tenureType = val;
      setWorkflow(obj);
    }
    return (
      <>
        <div className="Flex-row g20">
          <Typography>Tenure </Typography>
          {renderComparator(caseId, groupId)}

          {DeleteCase(groupId, caseId)}
        </div>
        <div className="Flex">
          <Radio_3
            label={"Tenure Type"}
            value={tenureType}
            menuItems={menu}
            onChange={changeRadio}
            flex={1}
          />
        </div>
      </>
    );
  }
  function renderTranscationFields(groupId, caseId) {
    let fields = [
      {
        label: "Claim Amount",
        value: "ClaimAmt",
        type: "number",
      },
    ];

    let transactionData = workflow.TransactionDataFields;
    transactionData = transactionData?.map((ele) => ({
      ...ele,
      label: ele.name,
    }));
    const idOptionMap = {};
    transactionData?.forEach(({ value, type }) => {
      idOptionMap[value] = type;
    });
    console.log("ID to map log: ", idOptionMap);
    function onSelectionChange(val) {
      let obj = workflow;
      obj[id].groups[groupId].cases[caseId] = {
        type: constants.TD,
        value: {
          selectedField: val,
        },
      };
      setWorkflow(obj);
    }
    let selectedField =
      workflow[id].groups[groupId].cases[caseId]?.value?.selectedField;
    if (!transactionData || transactionData?.length === 0) return <>No Data</>;
    return (
      <div className="Flex-row g20">
        <Typography>Transaction Fields</Typography>
        {console.log("Before drop down: ", transactionData)}
        <Dropdown
          menuItems={transactionData}
          value={selectedField}
          onChange={onSelectionChange}
        />
        {selectedField &&
          renderComparator(caseId, groupId, idOptionMap[selectedField])}

        {DeleteCase(groupId, caseId)}
        <br />
        <br />
      </div>
    );
  }
  function RenderGroups({ reviewMode }) {
    function renderGroup(cases, groupId) {
      console.log("Cases Length: ", cases);
      function addCase() {
        let obj = workflow;
        let arr = obj[id].groups[groupId].cases;
        if (arr.length > 0 && arr[arr.length - 1].type === constants.default) {
          return;
        }
        obj[id].groups[groupId].cases = [...arr, { type: constants.default }];
        console.log("added case: ", obj[id].groups[groupId]);
        setWorkflow({ ...obj });
      }
      function renderStepOwnerType() {
        let group =
          groupId !== -1
            ? workflow[id].groups[groupId]
            : workflow[id]?.defaultGroup;
        if (!group) group = {};
        let stepOwner = group?.stepOwner;
        let stepOwnerType = group?.stepOwnerType;
        let stepOwnerLabel = group?.stepOwnerLabel;
        if (!stepOwnerType) stepOwnerType = -1;
        let { stepOwnerLabelOptions } = workflow.config || {};
        function updateState(val, type) {
          let obj = workflow;
          if (type === "stepOwnerType") {
            // /["SU", "TU", "EH", "BH", "FH", "LH"].includes(stepOwnerType)
            // if (["LM", "FM"].includes(val))

            if (groupId !== -1) obj[id].groups[groupId].stepOwner = "0";
            else {
              group.stepOwner = "0";
              obj[id].defaultGroup = group;
            }
          }
          if (groupId !== -1) obj[id].groups[groupId][type] = val;
          else {
            group[type] = val;
            obj[id].defaultGroup = group;
          }

          console.log(obj);
          setWorkflow(obj);
        }

        function renderOwner() {
          if (!stepOwnerType || stepOwnerType === -1) return <></>;
          if (["SU", "TU", "EH", "BH", "FH", "LH"].includes(stepOwnerType))
            return <></>;
          if (["LM", "FM"].includes(stepOwnerType))
            return (
              <>
                <Input
                  label="Level"
                  defaultValue={stepOwner}
                  onBlur={(val) => updateState(val, "stepOwner")}
                  type={"number"}
                  inputProps={{
                    min: 0,
                    max: 99,
                  }}
                />
              </>
            );
          if (stepOwnerType === "OR")
            return (
              <div style={{width:"50%"}}>
                <Dropdown
                  label={"Roles"}
                  featureId={FeatureIDs.Role}
                  mapper={{
                    value: "TransactionID",
                    label: "RoleName",
                  }}
                  onChange={(val) => updateState(val, "stepOwner")}
                  value={stepOwner}
                />
              </div>
            );
        }

        return (
          <div className="Flex">
            <div className="Flex-row g20" style={{ width: "100%" }}>
              <div style={{ width: "50%" }}>
                <Dropdown
                  value={stepOwnerType}
                  label="Step Owner"
                  menuItems={stepOwners}
                  onChange={(val) => updateState(val, "stepOwnerType")}
                />
              </div>
              <div>{renderOwner()}</div>
            </div>
            <br />
            {stepOwnerLabelOptions && (
              <div style={{ width: "30%" }}>
                <Dropdown
                  label={"Step Owner Label"}
                  menuItems={stepOwnerLabelOptions}
                  value={stepOwnerLabel}
                  onChange={(val) => updateState(val, "stepOwnerLabel")}
                />
              </div>
            )}
          </div>
        );
      }
      function ActionSection() {
        let groupObj =
          groupId !== -1
            ? workflow[id].groups[groupId]
            : workflow[id].defaultGroup;
        if (!groupObj) groupObj = {};
        let stepOwner = groupObj?.stepOwner;
        let temp = groupObj?.selectedActions;
        const [selectedActions, setSelectedActions] = useState(
          temp ? temp : []
        );
        //    console.log('Step testing: ', workflow[id].groups[groupId].stepOwner)
        if (!stepOwner) stepOwner = -1;
        // if (!selectedActions) selectedActions = [];

        function changeActions(val) {
          let obj = workflow;
          // if(!obj[id].groups[groupId].selectedActions)obj[id].groups[groupId].selectedActions=[]
          obj[id].groups[groupId].selectedActions = val;
          console.log("value: ", val);
          setWorkflow(obj);
        }
        function attachActions() {
          // let selectedActions = workflow[id].groups[groupId].selectedActions;
          let obj = workflow;
          if (!selectedActions) return;
          let coords = {};

          for (let i = 0; i < workflow.states.nodes.length; i++) {
            if (workflow.states?.nodes[i]?.data?.id === id) {
              coords = workflow.states?.nodes[i].position;
              break;
            }
          }
          let initialNodes = workflow.states.nodes;
          let initialEdges = workflow.states.edges;
          // let totalActionNodesInStep=initialNodes?.filter((item)=>item.id.includes("Step-"+id)).length;
          // if(!totalActionNodesInStep)totalActionNodesInStep=0;
          let includeNodes = [];
          let includeEdges = [];
          let selectedActionIds = [];
          let edges = [],
            nodes = [];
          let x = coords.x + 300 * parseInt(groupId === -1 ? 1 : groupId + 1),
            y = -parseInt(selectedActions.length / 2) * 150;
          selectedActions.map((ele, index) => {
            console.log("Action: ", x, " ", y);
            let nodeId =
              "Step-" +
              id +
              "-Group-" +
              (groupId === -1 ? "default" : groupId) +
              "-Action-" +
              ele;
            let edgeId =
              "Step-" +
              id +
              "-Group-" +
              (groupId === -1 ? "default" : groupId) +
              "-Edge-" +
              ele;
            console.log("new node id: ", nodeId);
            includeNodes.push(nodeId);
            includeEdges.push(edgeId);
            selectedActionIds.push(nodeId);
            nodes.push({
              id: nodeId,
              type: "output",
              position: { x, y },
              data: { id: nodeId, name: ele, coords: { x, y }, stepId: id },
              stepName: id,
            });
            obj[nodeId] = {
              type: "output",
              stepId: id,
              autoAction: 2,
              Notifications: [],
              actionName: ele,
            };
            edges.push({
              id: edgeId,
              source: "Step-" + id,
              target: nodeId,
              label:
                groupId !== -1 ? "Group-" + (groupId + 1) : "Default Group",
            });
            y += 150;
          });

          initialNodes = initialNodes
            .map((ele) => {
              const ids = ele.id.split("-");
              console.log("ids: ", ids, " ");
              if (ids.length > 3) {
                if (
                  !(ids[1] == id && (ids[3] == groupId || ids[3] === "default"))
                )
                  return ele;
              } else return ele;
            })
            .filter((ele) => ele);
          initialEdges = initialEdges
            .map((ele) => {
              const ids = ele.id.split("-");
              if (ids.length > 3) {
                if (
                  !(ids[1] == id && (ids[3] == groupId || ids[3] === "default"))
                )
                  return ele;
              } else return ele;
            })
            .filter((ele) => ele);
          if (groupId !== -1) {
            obj[id].groups[groupId].selectedActions = selectedActions;
            obj[id].groups[groupId].selectedActionIds = selectedActionIds;
          } else {
            groupObj.selectedActions = selectedActions;
            groupObj.selectedActionIds = selectedActionIds;
            obj[id].defaultGroup = groupObj;
          }

          obj.states.nodes = [...initialNodes, ...nodes];
          obj.states.edges = [...initialEdges, ...edges];
          obj.updateParent = true;
          setWorkflow(obj);
        }

        return (
          <>
            <div className="Flex" style={{ flexDirection: "row" }}>
              {stepOwner !== -1 && (
                <MultiSelect
                  label={"Actions"}
                  menuItems={[
                    "Submit",
                    "Recommend",
                    "Approve",
                    "Confirm",
                    "Return",
                    "Deny",
                    "Reject",
                    "Resubmit",
                    "Paid",
                    "Booked",
                    "Verified",
                    "Acknowledge",
                  ]}
                  onChange={(val) => setSelectedActions(val)}
                  //  onBlur={changeActions}
                  value={selectedActions}
                />
              )}
              {stepOwner !== -1 && (
                <Button onClick={attachActions} endIcon={<CableRoundedIcon />}>
                  Attach Actions
                </Button>
              )}
            </div>
          </>
        );
      }
      function deleteGroup() {
        let obj = workflow;
        let arr = obj[id].groups;
        console.log("stateis: ", obj);

        let nodes = obj.states?.nodes;
        let edges = obj.states?.edges;
        nodes = nodes?.filter((item) => {
          let Nid = item.id.split("-");

          if (Nid.length > 3 && Nid[1] == id && Nid[3] == groupId) {
            console.log("Nodes is: matched");
            return false;
          }
          return true;
        });
        edges = edges?.filter((item) => {
          let Nid = item.id.split("-");
          if (Nid.length > 3 && Nid[1] == id && Nid[3] == groupId) return false;
          return true;
        });

        arr.splice(groupId, 1);
        console.log("New Group", arr);
        obj[id].groups = [...arr];
        obj.states.nodes = nodes;
        obj.states.edges = edges;
        console.log("Nodes is: ", nodes, " ");
        obj.updateParent = true;
        setWorkflow(obj);
      }

      if (cases.length === 0 && groupId !== -1) return <></>;
      if (groupId === -1)
        return (
          <>
            {renderStepOwnerType()}
            <br />
            <ActionSection />
          </>
        );
      return (
        <>
          <Button
            onClick={deleteGroup}
            startIcon={<DeleteIcon />}
            color="error"
            style={{ position: "absolute", right: "5%" }}
          >
            Group
          </Button>
          <div className="Flex g20" style={{ gap: 30 }}>
            {cases?.map((ele, index) => {
              switch (ele.type) {
                case constants.Applicability:
                  return renderApplicablity(ele, index, groupId, reviewMode);
                case constants.Age:
                  return renderAge(index, groupId);
                // return renderAge(ele, index, groupId, reviewMode);
                case constants.Gender:
                  return renderGender(ele, index, groupId, reviewMode);
                case constants.TD:
                  return renderTranscationFields(groupId, index);
                case constants.Tenure:
                  return renderTenure(groupId, index);
                // case constants.TD:
                //   return renderTranscationFields(ele,index,groupId)
                case constants.default:
                  return renderDefault(index, groupId);
              }
            })}
            <br />
            <div className="Flex">
              <Button onClick={addCase}> + Add Condition</Button>
            </div>
            <br />
          </div>

          {renderStepOwnerType()}
          <br />
          <ActionSection />
        </>
      );
    }
    console.log("work: ", workflow);
    if (!workflow[id]) return <></>;
    let groups = workflow[id]?.groups;
    if (!groups) groups = [];
    //if (!groups || groups.length === 0) return <> {renderGroup([], -1)}</>;
    let accordians = [];
    groups.forEach((ele, index) => {
      accordians.push({
        label: "Group-" + (index + 1),
        renderComponent: renderGroup,
        params: [ele.cases, index],
      });
    });

    return (
      <>
        <EwAccordion accordians={accordians} />
        <br />
        <div className="flex start">
          <Chip color="primary" label={"Default Group"} />
        </div>
        <br />
        {renderGroup([], -1)}

        {/* {groups.map((ele, index) => {
          console.log("work: 1", workflow);
          return renderGroup(ele.cases, index);
        })} */}
      </>
    );
  }
  function TimeCapture({ arrayName, arrayIndex, variable, label }) {
    console.log("TestTrigger: ", workflow[id][arrayName], " ", arrayName);
    const { triggerTimeType, triggerTime, interval } =
      workflow[id][arrayName][arrayIndex][variable];
    const [data, setData] = useState({
      triggerTime,
      interval,
    });
    let timeOptions = [
      {
        label: "Months",
        value: "T",
      },
      {
        label: "Days",
        value: "D",
      },
      {
        label: "Hours",
        value: "H",
      },
      {
        label: "Minutes",
        value: "M",
      },
    ];
    return (
      <Box>
        {/* <Typography
          variant="subtitle1"
          color={"#8c8c8c"}
          marginBottom={"0.4em"}
        >
          {label}
        </Typography> */}
        {/* <div className="Flex-row">
          <div>
            <Input
              label="Months"
              type="Number"
              value={data.months}
              onChange={(val) => setData((prev) => ({ ...prev, months: val }))}
              style={{ margin: "0" }}
              variant="outlined"
            />
          </div>
          <div>
            <Input
              label="Days"
              type="Number"
              value={data.days}
              onChange={(val) => setData((prev) => ({ ...prev, days: val }))}
              style={{ margin: "0" }}
            />
          </div>
          <div>
            <Input
              label="Hours"
              type="Number"
              value={data.hours}
              onChange={(val) => setData((prev) => ({ ...prev, hours: val }))}
              style={{ margin: "0" }}
            />
          </div>
          <div>
            <Input
              label="Minutes"
              type="Number"
              value={data.minutes}
              onChange={(val) => setData((prev) => ({ ...prev, minutes: val }))}
              style={{ margin: "0" }}
            />
          </div>
        </div> */}
        <div className="Flex-row">
          <div style={{ width: "40%" }}>
            <Dropdown
              menuItems={timeOptions}
              label={"Trigger Time"}
              value={triggerTimeType}
              onChange={(val) => {
                let obj = workflow;
                obj[id][arrayName][arrayIndex][variable].triggerTimeType = val;
                setWorkflow(obj);
              }}
            />
          </div>
          {triggerTimeType && triggerTimeType !== -1 && (
            <div>
              <Input
                label="Time"
                type="number"
                value={data.triggerTime}
                onBlur={() => {
                  let obj = workflow;
                  obj[id][arrayName][arrayIndex][variable].triggerTime =
                    data.triggerTime;
                  setWorkflow(obj);
                }}
                onChange={(val) => {
                  setData((prev) => ({ ...prev, triggerTime: val }));
                }}
              />
            </div>
          )}
        </div>
        <div className="Flex">
          <div>
            <Input
              label="Repeat Interval"
              type="number"
              value={data.interval}
              onChange={(val) => {
                setData((prev) => ({ ...prev, interval: val }));
              }}
            />
          </div>
        </div>
      </Box>
    );
  }

  function StepReminders() {
    const [data, setData] = useState({
      repeatInterval: 0,
      stopTime: 0,
    });
    let obj = workflow;
    if (!obj[id]) obj[id] = {};
    let reminders = obj[id].reminders;
    if (!reminders) {
      obj[id].reminders = [];
      setWorkflow(obj);
    }
    //{triggerTime:{},repeatInterval:"",stopTime:"",notificationId:""}
    function addReminders() {
      obj[id].reminders = [
        ...obj[id].reminders,
        { triggerTime: {}, repeatInterval: 0, stopTime: 0, notificationId: [] },
      ];
      setWorkflow(obj);
    }
    return (
      <>
        {reminders?.map((ele, index) => (
          <>
            <div>
              <div className="Flex" style={{ gap: "1em" }}>
                <TimeCapture
                  label="Trigger Time"
                  arrayName="reminders"
                  arrayIndex={index}
                  variable="triggerTime"
                />

                <Box display={"flex"} gap={"1em"}>
                  <Notification
                    type={"R"}
                    value={obj[id]?.reminders[index]?.notificationId}
                    onChange={(val) => {
                      obj[id].reminders[index].notificationId = val;
                      setWorkflow(obj);
                    }}
                  />
                  <Button
                    onClick={() => {
                      let arr = obj[id].reminders;
                      arr.splice(index, 1);
                      obj[id].reminders = [...arr];
                      setWorkflow(obj);
                    }}
                    color="error"
                    startIcon={<DeleteIcon />}
                  >
                    Step Remainder
                  </Button>
                </Box>
              </div>
            </div>
            <Divider sx={{ margin: "1.5em 0" }} />
          </>
        ))}
        <Box display={"flex"} justifyContent={"center"}>
          <Button onClick={addReminders}>Add Reminders</Button>
        </Box>
      </>
    );
  }
  function StepEscalation() {
    // let escalations = workflow[id].escalations
    const [data, setData] = useState({
      triggerTime: 0,
      repeatInterval: 0,
      selected: {},
    });

    let obj = workflow;
    if (!obj[id]) obj[id] = {};
    let escalations = obj[id].escalations;
    if (!escalations) {
      obj[id].escalations = [];
      setWorkflow(obj);
    }
    function addEscalations() {
      obj[id].escalations = [
        ...obj[id].escalations,
        {
          triggerTime: {},
          repeatInterval: 0,
          stopTime: 0,
          notificationId: [],
          repeatFrequency: "",
        },
      ];
      setWorkflow(obj);
    }
    function changeFrequency(val, index) {
      obj[id].escalations[index].repeatFrequency = val;
      setWorkflow(obj);
    }
    //  let repeatFrequency
    let days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    function deleteEscalation() {}
    return (
      <>
        {escalations?.map((ele, index) => (
          <div className="Flex">
            <div className="Flex-row-up g20">
              <div>
                <TimeCapture
                  arrayName="escalations"
                  arrayIndex={index}
                  variable="triggerTime"
                  label="Trigger Time"
                />
              </div>
            </div>
            <div className="Flex">
              <div style={{ width: "50%" }}>
                {/* <Typography
                  variant="body1"
                  color={"#8c8c8c"}
                  sx={{ marginBottom: "1em" }}
                >
                  Repeat Frequency
                </Typography> */}
                <Dropdown
                  styles={{ margin: "0" }}
                  InputLabelProps={{ shrink: false }}
                  label="Repeat Frequency"
                  value={obj[id].escalations[index].repeatFrequency}
                  menuItems={["Yearly", "Monthly", "Weekly", "Daily", "Hours"]}
                  onChange={(val) => changeFrequency(val, index)}
                />
              </div>
            </div>

            <Box display={"flex"} gap={"1em"}>
              <Notification
                type={"E"}
                value={obj[id]?.escalations[index]?.notificationId}
                onChange={(val) => {
                  obj[id].escalations[index].notificationId = val;
                  setWorkflow(obj);
                }}
              />
              <Button
                onClick={() => {
                  let arr = obj[id].escalations;
                  arr.splice(index, 1);
                  obj[id].escalations = [...arr];
                  setWorkflow(obj);
                }}
                color="error"
                startIcon={<DeleteIcon />}
              >
                Step Escalation
              </Button>
            </Box>
            <Divider sx={{ margin: "2em 0", width: "100%" }} />
          </div>
        ))}
        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
          <Button onClick={addEscalations}>Add Escalation</Button>
        </Box>
      </>
    );
  }

  useEffect(() => {
    if (id == 1) console.log("test: ", workflow);
    let obj = workflow;
    if (obj[id] && obj[id].stepName) {
      setStepName(obj[id].stepName);
    } else {
      obj[id] = {
        stepName: "Step-" + id,
      };
    }

    console.log("USefect default: ", obj);
    setWorkflow({ ...obj });
  }, []);
  function updateStepName() {
    if (stepName === "") return;
    let obj = workflow;
    if (!obj[id])
      obj[id] = {
        stepName: "",
      };
    obj[id].stepName = stepName;
    setWorkflow({ ...obj });
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function deleteStep() {
    let obj = workflow;
    let stepId = "Step-" + id;
    obj[id] = undefined;
    let targets = [];
    let { nodes, edges } = obj.states;
    nodes = nodes.filter((item) => {
      if (item.id.includes(stepId)) {
        targets.push(item.id);
        return false;
      }
      return true;
    });
    edges = edges.filter(({ source, target }) => {
      if (source.includes(stepId)) {
        //if(!["end","Step-1"].includes(target))targets.push(target);
        return false;
      }
      if (target.includes(stepId)) {
        return false;
      }
      return true;
    });
    console.log("Beore Deleting : ", targets);
    targets.forEach((ele) => {
      if (obj[ele]) obj[ele] = undefined;
    });
    obj.states = {
      nodes,
      edges,
    };
    obj.updateParent = true;
    setWorkflow(obj);
  }
  function rightClick(e) {
    e.preventDefault();
    const { clientX, clientY } = e;

    setRightCoords({ x: clientX, y: clientY });
  }

  const [stepName, setStepName] = useState("");
  const [tabindex, setTabIndex] = useState(0);
  useEffect(() => {
    if (stepName === "") setStepName(workflow[id]?.stepName);
  }, [workflow[id]?.stepName]);
  const handleTabIndexChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const { Notifications } = workflow[id] || {};
  if (id === "end")
    return (
      <>
        <Paper
          style={{
            padding: 30,
            border: "2px solid red",
          }}
        >
          <Handle type="target" position={Position.Left} />
          <h2>End Step</h2>
        </Paper>
      </>
    );

  return (
    <Paper
      style={{
        padding: 10,
      }}
    >
      {/* {rightCoords.x && <div style={{position:'absolute',top:rightCoords.y,left:rightCoords.x}}><Menu options={options} /></div>}
       */}
      <Handle type="source" position={Position.Right} />
      {<Handle type="target" position={Position.Left} />}
      <div id={id} className="step-form">
        <Modal
          style={styles.modalStyle}
          open={modal}
          onClose={() => setModal(false)}
        >
          <Paper style={{ padding: 30, marginBottom: 100 }}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabindex}
                  onChange={handleTabIndexChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Conditions" {...a11yProps(0)} />
                  <Tab label="Step Reminder" {...a11yProps(1)} />
                  <Tab label="Step Escalation" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={tabindex} index={0}>
                <>
                  {RenderGroups({ reviewMode: false })}
                  {id === "1" && (
                    <>
                      <Notification
                        type={"A"}
                        value={Notifications}
                        onChange={(val) => {
                          let obj = workflow;
                          obj[id].Notifications = val;
                          setWorkflow(obj);
                        }}
                      />
                    </>
                  )}
                  <Button
                    onClick={() => {
                      let obj = workflow;
                      if (!obj[id]) obj[id] = {};
                      if (!obj[id].groups) obj[id] = { ...obj[id], groups: [] };
                      obj[id].groups = [
                        ...obj[id].groups,
                        {
                          cases: [
                            {
                              type: constants.default,
                            },
                          ],
                        },
                      ];
                      console.log(obj);
                      setWorkflow({ ...obj });
                    }}
                  >
                    Add Group
                  </Button>
                </>
              </TabPanel>
              <TabPanel value={tabindex} index={1}>
                <StepReminders />
              </TabPanel>
              <TabPanel value={tabindex} index={2}>
                <StepEscalation />
              </TabPanel>
            </Box>
          </Paper>
        </Modal>
        {/* <StepFormModal modal={modal} setModal={setModal} /> */}
        <Dialog open={showWarning} onClose={() => setShowWarning(false)}>
          <div style={{ padding: 40 }}>
            <Typography>Delete This Group?</Typography>
            <br />
            <div
              className="Flex-row g20"
              style={{ justifyContent: "flex-end" }}
            >
              <div>
                <Button onClick={() => setShowWarning(false)}>Cancel</Button>
              </div>
              <div>
                <Button color="error" onClick={deleteStep}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
        <div className="Flex-row g20">
          <div>
            <Input
              label="Step Name"
              // label={workflow[id]?.stepName ? workflow[id]?.stepName : ''}
              onBlur={updateStepName}
              value={stepName}
              onChange={(val) => setStepName(val)}
            />
          </div>
          {!["end", "1"].includes(id) && (
            <div className="pointer" onClick={() => setShowWarning(true)}>
              <DeleteIcon color="error" />
            </div>
          )}
        </div>
        <Button onClick={() => setModal(true)}>Add Details</Button>
      </div>

      {/* <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={handleStyle}
      />
      <Handle type="source" position={Position.Bottom} id="b" /> */}
    </Paper>
  );
}

export default StepForm;
