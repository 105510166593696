import { Box, Slider } from "@mui/material";
import React from "react";

const DiscreteScaleSlider = ({ values }) => {
  const marks = [
    ...values.map((val) => ({ value: val.Value, label: val.Label })),
  ];

  function valuetext(value) {
    return `${value}`;
  }

  const step = values[1].Value - values[0].Value;
  const min = values.reduce(
    (mn, val) => (val.Value < mn ? +val.Value : mn),
    +values[0].Value
  );
  const max = values.reduce(
    (mx, val) => (val.Value > mx ? +val.Value : mx),
    +values[0].Value
  );

  console.log(marks, step, min, max);

  return (
    <Box>
      <Slider
        aria-label="Scale values"
        defaultValue={min}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        step={step}
        marks={marks}
        min={min}
        max={max}
      />
    </Box>
  );
};

export default DiscreteScaleSlider;
