import { Box, Divider, Paper, Typography } from "@mui/material";
import React from "react";

const PlanAggregateScore = () => {
  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: "0.5em",
        height: "100%",
      }}
    >
      <Box padding={"1em"}>
        <Box
          sx={{
            height: "4em",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Plan Aggregate Score</Typography>
        </Box>
        {/* <Divider /> */}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ margin: "0.3em" }}>
            <Typography variant="overline">Plan aggregated score</Typography>
            <Typography variant="h6" fontWeight={"400"}>
              25
            </Typography>
          </Box>
          <Box sx={{ margin: "0.3em" }}>
            <Typography variant="overline">Plan moderated score</Typography>
            <Typography variant="h6" fontWeight={"400"}>
              37
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default PlanAggregateScore;
