import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Atomic from "../../../../AComponent/Atomic";
import NumericInput from "../UI/NumericInput";
const { Dropdown, Button } = Atomic;

const userTypeMap = {
  SU: "Subject User",
  TU: "Transaction User",
  LM: "Line Manager",
  FM: "Functional Manager",
  EH: "Business Unit Head",
};

const userTypeValueMap = {
  SU: [0, 0],
  TU: [0, 0],
  LM: [0, 99],
  FM: [0, 99],
  EH: [0, 0],
};

function StepOwnerForm({ closeForm, onAdd, UserType, UserLevel }) {
  const [user, setUser] = useState({
    UserType: UserType || "",
    UserLevel: UserLevel || "",
  });

  return (
    <Box position={"relative"} width={"50vw"} height={"50vh"}>
      <Typography variant="h2" color={"primary"}>
        Select Step Owner
      </Typography>
      <br />
      <Box display={"flex"} gap={"1.5em"}>
        <Box flex={0.33}>
          <Typography variant="subtitle1" sx={{ mb: "0.5em" }}>
            Select User Type
          </Typography>
          <Dropdown
            menuItems={[
              ...Object.entries(userTypeMap).map(([key, value]) => ({
                label: value,
                value: key,
              })),
            ]}
            label={"Range"}
            onChange={(val) => {
              setUser((prevState) => ({ UserType: val, UserLevel: "" }));
            }}
            size={"small"}
            value={user.UserType || -1}
          />
        </Box>
        {user.UserType &&
          userTypeValueMap[user.UserType] &&
          userTypeValueMap[user.UserType][0] !==
            userTypeValueMap[user.UserType][1] && (
            <Box flex={0.33}>
              <Typography variant="subtitle1" sx={{ mb: "0.5em" }}>
                Enter Level
              </Typography>
              <NumericInput
                onChange={(val) => {
                  setUser((prevState) => ({ ...prevState, UserLevel: val }));
                }}
                value={user.UserLevel || 0}
              />
            </Box>
          )}
      </Box>
      <Button
        label={"Add"}
        title={"Add"}
        onClick={() => {
          onAdd && onAdd(user);
          closeForm();
        }}
        style={{ position: "absolute", bottom: 0, right: 0 }}
      />
    </Box>
  );
}

export default StepOwnerForm;
