import EwTable from "./Table/Table";
import EwTabs from "./Tabs/Tabs";
import EwStepper from "./Stepper/Stepper";
import EwModal from "./Modal/Modal";

export default {
  Table: EwTable,
  Tabs: EwTabs,
  Stepper: EwStepper,
  EwModal,
};
// export const Table=EwTable;
// export const Tabs=EwTabs;
// export const Stepper=EwStepper;
