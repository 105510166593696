import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Atomic from "../AComponent/Atomic";
import { getData } from "../Utils/Common/Library";
function TCode() {
  const [name, setName] = useState("");
  const [featureId, setFeatureID] = useState("");
  const dispatch = useDispatch();

  function onSave() {
    dispatch({
      type: "ADD_TENANT_CODE",
      payload: name,
    });
  }
  function getServerData() {
    if (!featureId) return;
    getData({ featureId: featureId })
      .then((res) => {
        console.log("From server: ", res);
      })
      .catch((e) => {
        console.log(e?.response);
      });
  }
  return (
    <div>
      <Atomic.Input
        label={"Tenant Code"}
        value={name}
        onChange={(val) => setName(val)}
      />
      <br />
      <Atomic.Button title={"Save"} onClick={onSave} />
      <br />
      <Atomic.Input
        label={"Feature ID"}
        value={featureId}
        onChange={(val) => setFeatureID(val)}
      />
      <br />
      <Atomic.Button title={"GEtdata"} onClick={getServerData} />
    </div>
  );
}

export default TCode;
