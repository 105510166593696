import React, { useRef, useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Atomic from "../AComponent/Atomic";

const initialProps = {
  heading: null,
  btnLabel: null,
  enableTags: false,
  placeholder: "",
  value: "",
  onChange: () => {},
  onBlur: () => {},
};
export default function Editor3(props = initialProps) {
  const {
    heading,
    btnLabel,
    enableTags,
    tags,
    placeholder,
    height,
    onSaveHandler,
    value,
    onChange,
    onBlur,
  } = props;

  const editorRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);
  function tagSelected(val) {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
    const editor = editorRef.current;
    // console.log("Editor: ", editorRef.current)
    var cursorPos = editor.selection.getRng();
    //
    editor.insertContent(
      `<span style='background-color: #EEEEEE;padding:5px;border:1px solid black' contenteditable=false class='Tag'>${val}</span>`,
      { format: "raw" },
      cursorPos
    );
  }

  function setup(editor) {
    if (!enableTags) return;
    editor.ui.registry.addMenuButton("mybutton", {
      text: "Tags",
      fetch: function (callback) {
        var items = [
          {
            type: "menuitem",
            text: "Tag 1",
            onAction: function () {
              tagSelected("tag1");
            },
          },
          {
            type: "menuitem",
            text: "Tag2",
            onAction: function () {
              tagSelected("tag2");
            },
          },
        ];
        callback(items);
      },
    });
  }
  function onSave() {
    let html = JSON.stringify(editorRef.current.getContent(), (key, value) => {
      if (typeof value === "string") {
        return value.replace(/"/g, "'");
      }
      return value;
    });
    console.log(html);
    onSaveHandler && onSaveHandler(html);
  }
  if (!onChange) return <>Data Missing</>;

  return (
    <>
      {heading && (
        <>
          <div className="Flex">
            <h1>{heading}</h1>
          </div>
          <br />
        </>
      )}
      <Editor
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        value={value}
        onEditorChange={(val) => onChange(val)}
        onBlur={onBlur}
        init={{
          setup: function (editor) {
            setup(editor);
          },
          placeholder: placeholder ? placeholder : "",
          height: height ? height : 500,
          width: "100%",
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount menu",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help mybutton",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <br />
      {/* <Atomic.Button title={btnLabel ? btnLabel : "Save"} onClick={onSave} /> */}
    </>
  );
}
