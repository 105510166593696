import React, { useState } from "react";
import Mpp from "./index";
import EwTable from "../../../MComponent/Table/Table";
import EditIcon from "@mui/icons-material/Edit";
import Atomic from "../../../AComponent/Atomic";
import { useDispatch } from "react-redux";

const { Button } = Atomic;
const MPPFeatureID = "19149";
function MasterPlans() {
  const [openPlan, setOpenPlan] = useState(false);
  function EditPlan() {
    return (
      <div className="pointer" onClick={() => setOpenPlan(true)}>
        <EditIcon />
      </div>
    );
  }
  const heading = ["Master Plan", "Edit"];

  function getEpoch(date) {
    return new Date(+date);
    date = date.split("-").map((ele) => parseInt(ele));
    let changes = new Date(new Date().getFullYear(), date[1], date[0]);
    console.log(
      "new Updated date: ",
      changes,
      date,
      " ",
      new Date().getFullYear()
    );
    return changes;
  }
  const dispatch = useDispatch();
  function SetDocument(doc) {
    console.log("Doc selected: 111", doc);
    let {
      Dimension,
      TransactionID,
      EffectiveFromTimestamp,
      GrowthRoleAssessmentOptions,
      PromotionAssessmentOptions,
      ExitAssessmentOptions,
      PlanStart,
      PlanEnd,
    } = doc;

    // GrowthRoleAssessmentOptions = GrowthRoleAssessmentOptions.map(
    //   (ele) => ele.ID
    // );
    // PromotionAssessmentOptions = PromotionAssessmentOptions.map(
    //   (ele) => ele.ID
    // );
    // ExitAssessmentOptions = ExitAssessmentOptions.map((ele) => ele.ID);
    // let enabledDim = [];
    // Dimension?.forEach((ele) => {
    //   const { DimensionID } = ele;
    //   enabledDim.push({
    //     id: DimensionID,
    //     name: "",
    //   });
    //   doc[DimensionID]=ele
    // });

    doc = {
      ...doc,

      GrowthRoleAssessmentOptions,
      PromotionAssessmentOptions,
      ExitAssessmentOptions,
      PlanStart: getEpoch(PlanStart),
      PlanEnd: getEpoch(PlanEnd),
    };
    console.log("Plan startting: ", doc.PlanStart);
    let Mpp = {};
    let dimObj = {};
    if (TransactionID && TransactionID.trim(" ") !== "")
      Mpp = {
        enabledDim: [],
        editMode: {
          TransactionID,
          EffectiveFromTimestamp,
        },
      };
    console.log("MPP is after edit: ", Mpp);
    if (Dimension.length > 0) {
      let arr = [];

      Dimension.forEach((ele) => {
        if (ele.AggregateScoreRatingScaleID !== "") {
          arr.push({
            id: ele.DimensionID,
            name: "",
          });
          dimObj[ele.DimensionID] = {
            ...ele,
            enabled: ele.DimensionID !== "" ? "E" : "D",
          };
        }
      });
      Mpp.enabledDim = arr;
    }
    console.log("Before setting: ", dimObj);
    dispatch({
      type: "UPDATE_ALL_FIELDS",
      payload: {
        replaceReducer: true,
        data: {
          MPP: doc,
          Mpp,
          ...dimObj,
        },
      },
    });
    setOpenPlan(true);
    return;
    // const {
    //   TransactionID,
    //   GoalSettings,
    //   PeriodicReviews,
    //   InterimReviews,
    //   MultiRater,
    //   ProgressTracking,
    //   CoachingSection,
    //   SelfDevelopmentSection,
    //   TrainingNeedsSection,
    //   ConfidentialSections,
    //   SummarySections,
    //   Dimension,
    //   EffectiveFromTimestamp,
    // } = doc;
    // let mpp = {
    //   Mpp: {
    //     enabledDim: [],
    //     editMode: {
    //       TransactionID,
    //       EffectiveFromTimestamp,
    //     },
    //   },
    //   General: doc,
    //   Coaching: {
    //     ...CoachingSection,
    //     DataColumns: CoachingSection?.DataColumns?.map((ele) => ele.ID),
    //   },
    //   selfDev: {
    //     ...SelfDevelopmentSection,
    //     DataColumns: SelfDevelopmentSection?.DataColumns?.map((ele) => ele.ID),
    //   },
    //   Summary: SummarySections,
    //   training: {
    //     ...TrainingNeedsSection,
    //     DimensionLinkage: TrainingNeedsSection?.DimensionLinkage?.map(
    //       (ele) => ele.ID
    //     ),
    //   },
    //   Confi: {
    //     ...ConfidentialSections,
    //     GrowthRoleReadinessOption:
    //       ConfidentialSections?.GrowthRoleReadinessOption?.map((ele) => ele.ID),
    //     PromotionAssessmentOptions:
    //       ConfidentialSections?.PromotionAssessmentOptions?.map(
    //         (ele) => ele.ID
    //       ),
    //     ExitAssessmentOptions: ConfidentialSections?.ExitAssessmentOptions?.map(
    //       (ele) => ele.ID
    //     ),
    //   },
    //   PeriodicReview: {
    //     ...PeriodicReviews,
    //     PeriodicReviewDimensions:
    //       PeriodicReviews?.PeriodicReviewDimensions?.map((ele) => ele.ID),
    //   },
    //   Interim: {
    //     ...InterimReviews,
    //     InterimDimensions: InterimReviews?.InterimDimensions?.map(
    //       (ele) => ele.ID
    //     ),
    //   },
    //   MultiRater: {
    //     ...MultiRater,
    //     MultiRaterDimensions: MultiRater?.MultiRaterDimensions?.map(
    //       (ele) => ele.ID
    //     ),
    //   },
    //   Progress: {
    //     ...ProgressTracking,
    //     ProgressTrackingDimensions:
    //       ProgressTracking?.ProgressTrackingDimensions?.map((ele) => ele.ID),
    //   },
    //   GoalSettings: {
    //     ...GoalSettings,
    //     GoalSettingsDimensions: GoalSettings?.GoalSettingsDimensions?.map(
    //       (ele) => ele.ID
    //     ),
    //   },
    //   //GoalSettings:doc.
    // };
    // console.log("Before dispatcch: ", mpp);

    // if (Dimension) {
    //   let arr = [];
    //   Dimension.forEach((ele) => {
    //     arr.push({
    //       id: ele.DimensionID,
    //       name: "Test",
    //     });
    //     mpp[ele.DimensionID] = {
    //       ...ele,
    //       enabled:"E"
    //     }
    //   });
    //   mpp.Mpp.enabledDim = arr;
    // }

    // dispatch({
    //   type: "UPDATE_ALL_FIELDS",
    //   payload: {
    //     replaceReducer: true,
    //     data: mpp,
    //   },
    // });
    // setOpenPlan(true);
  }
  function unsetData() {
    dispatch({
      type: "UPDATE_ALL_FIELDS",
      payload: {
        replaceReducer: true,
        data: {},
      },
    });
  }
  if (!openPlan)
    return (
      <div>
        <EwTable
          heading={heading}
          featureId={MPPFeatureID}
          rowToFieldMap={["PlanName"]}
          onDocSelected={SetDocument}
          includeOprations={true}
        />
        <br />
        <div className="Flex">
          <Button title={"Add New"} onClick={() => setOpenPlan(true)} />
        </div>
      </div>
    );
  return (
    <div>
      <div>
        <Button
          variant={"Text"}
          title={"⬅️ Master Plans"}
          onClick={() => {
            setOpenPlan(false);
            unsetData();
          }}
        />
      </div>
      <br />
      <Mpp
        onServerSave={() => {
          setOpenPlan(false);
        }}
      />
    </div>
  );
}

export default MasterPlans;
