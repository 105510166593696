import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DeleteOutline,
  EditOutlined,
  Error,
  ErrorOutlined,
  ExpandLessRounded,
  ExpandMoreRounded,
  QuestionAnswer,
  WarningAmberOutlined,
} from "@mui/icons-material";

const GroupAccordian = ({
  collapse,
  expandable,

  TransactionID,
  type,
  title,
  caption,
  ParameterDescription,
  children,

  permissions,
  rules,

  clickable,
  onClickHandler,

  editModalHandler,
  deleteModalHandler,
  commentsModalHandler,
  dataError,
}) => {
  const [open, setOpen] = useState(!collapse);
  const theme = useTheme();

  useEffect(() => {
    setOpen(!collapse);
  }, [collapse]);

  return (
    <Box sx={{ width: "100%", margin: "" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.6em 0.3em 0.6em 0em",
          cursor: clickable && "pointer",
          borderRadius: "6px",
          boxShadow: "0px 0px 6px #B1AEBB",
          margin: "0.5em",
          marginRight: "0",
          backgroundColor:
            type === "ph" ? theme.palette.primary["50"] : "white",
        }}
        onClick={
          clickable
            ? () => {
                onClickHandler(TransactionID);
              }
            : () => {}
        }
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "0 1em",
            gap: "1em",
          }}
        >
          <IconButton
            sx={{ marginRight: "0.5em" }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {expandable && !open && (
              <ExpandMoreRounded fontSize="large" sx={{ color: "black" }} />
            )}
            {expandable && open && (
              <ExpandLessRounded fontSize="large" sx={{ color: "black" }} />
            )}
          </IconButton>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* <Typography variant="tinytext" color={"primary"}>
              {type === "g2" ? "Perspective" : type === "g1" ? "KRA" : "KPI"}
            </Typography> */}
            <Typography variant={`${type === "g2" ? "h4" : "subtitle"}`}>
              {title || "Unnamed Parameter"}
            </Typography>
            <Typography variant="caption">{ParameterDescription}</Typography>
          </div>
          {dataError && (
            <Tooltip
              children={<WarningAmberOutlined color="error" />}
              title="Rating is missing"
            />
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <IconButton onClick={commentsModalHandler}>
            <QuestionAnswer sx={{ color: "#6c6d6f" }} />
          </IconButton> */}
          {permissions?.editable && (
            <IconButton
              onClick={(event) => editModalHandler(event, TransactionID)}
            >
              <EditOutlined sx={{ color: "#6c6d6f" }} />
            </IconButton>
          )}
          {permissions?.deletable && (
            <IconButton
              onClick={(event) => deleteModalHandler(event, TransactionID)}
            >
              <DeleteOutline sx={{ color: "#6c6d6f" }} />
            </IconButton>
          )}
        </Box>
      </Box>
      {open && (
        <Box
          sx={{
            borderTop: 0,
            paddingLeft: "6em",
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default GroupAccordian;
