import React from "react";

const EWList = (props) => {
    console.log("From EWLIST: ",props);
    const SubItems = props.SubItems;
    let empty = '\xa0';
    let Components = [empty,empty,empty,empty,empty,empty];
    for(var x=0;x<SubItems.length;x++)
    {
        Components[x] = SubItems[x].Label;
    }
    return Components.map((item)=>{
        return <div>{item}</div>
    })
}

export default EWList;