import React from "react";
import { useDispatch, useSelector } from "react-redux";
import WorkflowButton from "../../Workflow/WorkflowButton";
import Atomic from "../../../AComponent/Atomic";

const { MultiSelect, EwSwitch, Dropdown } = Atomic;
const id = "MPP";
const DimensionFeatureId = "19144";
const RatingScaleFeatureID = "19148";
function ProgressTracking() {
  const {
    ProgressTracking,
    ProgressTrackingDimensions,
    dimList,
    ProgressTrackingScaleID,
    ProgressTrackingFrequency,
    ProgressTrackingWorkflowID,
    rateList,
  } = useSelector((state) => {
    if (!state.DataReducer[id]) return {};
    return state.DataReducer[id];
  });
  const dispatch = useDispatch();
  function updateState(val, type) {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: type,
        data: val,
      },
    });
  }
  return (
    <div>
      <div className="Flex">
        <EwSwitch
          label="Progress Tracking"
          value={ProgressTracking === "E"}
          onChange={(val) => updateState(val ? "E" : "D", "ProgressTracking")}
        />
      </div>
      {ProgressTracking === "E" && (
        <>
          <div className="Flex">
            <WorkflowButton
              AuthorFeatureID={"19149"}
              value={ProgressTrackingWorkflowID}
              onChange={(val) => updateState(val, "ProgressTrackingWorkflowID")}
            />
          </div>
          <br />
          <div className="Flex-row">
            <div style={{ width: "50%" }}>
              <MultiSelect
                label={"Dimesnions"}
                mapper={{
                  label: "DimensionName",
                  value: "TransactionID",
                }}
                includeID={true}
                featureId={DimensionFeatureId}
                value={ProgressTrackingDimensions}
                apiResult={dimList}
                storeApiResult={(val) => updateState(val, "dimList")}
                onChange={(val) =>
                  updateState(val, "ProgressTrackingDimensions")
                }
              />
            </div>
            <div style={{ width: "50%" }}>
              <Dropdown
                label={"Rating Scale"}
                mapper={{
                  label: "RatingScaleName",
                  value: "TransactionID",
                }}
                featureId={RatingScaleFeatureID}
                apiResult={rateList}
                storeApiResult={(val) =>
                  updateState(val, "rateList")
                }
                value={ProgressTrackingScaleID}
                onChange={(val) => updateState(val, "ProgressTrackingScaleID")}
              />
            </div>
          </div>
          <br />
          <div className="Flex">
            <div style={{ width: "100%" }}>
              <Dropdown
                label="Progress Tracking Frequency"
                menuItems={[
                  { label: "Half yearly", value: "H" },
                  { label: "Quarterly", value: "Q" },
                  { label: "Monthly", value: "M" },
                ]}
                value={ProgressTrackingFrequency}
                onChange={(val) =>
                  updateState(val, "ProgressTrackingFrequency")
                }
              />
            </div>
          </div>
          <br />
        </>
      )}
    </div>
  );
}

export default ProgressTracking;
