import React from 'react';

function ATextfield(){
    return(
        <div>
            <input type="text" id="convotf" name="content" placeholder="Message"></input>
        </div>
    );
}

export default ATextfield;