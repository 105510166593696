import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import useProps from "../../Utils/CustomHooks/GetProps";
import { styled } from "@mui/material";
const defaultProps = {
  menuItems: null,
  label: null,
  onChange: null,
  value: null,
  flex: null,
  disable: null,
};

const row = {
  display: "flex",
  flexDirection: "row",
};

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  backgroundColor: theme.palette.primary["100"],
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: `radial-gradient(${theme.palette.secondary.main},${theme.palette.secondary.main} 28%,transparent 32%)`,
    content: '""',
  },
}));

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export default function EwRadio(props = defaultProps) {
  var { menuItems, label, onChange, value, flex, containerStyles, disable } =
    useProps(props);

  if (menuItems && typeof menuItems[0] === "string") {
    menuItems = menuItems.map((ele) => ({ value: ele, label: ele }));
  }
  var styles = {};
  if (flex === 1) styles = row;
  if (containerStyles) styles = { ...styles, ...containerStyles };
  return (
    <FormControl component="fieldset" disabled={disable}>
      <FormLabel component="legend">{label}</FormLabel>
      {console.log("value: ", value)}
      <RadioGroup
        style={styles}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {menuItems.map((ele) => {
          const { label, value } = ele;
          return (
            <FormControlLabel
              value={value ? value : label}
              control={<BpRadio />}
              label={label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
