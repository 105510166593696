import React, { useState } from "react";
import Atomic from "../../../AComponent/Atomic";
import SectionEnable from "./SectionEnable";
import { useDispatch, useSelector } from "react-redux";

const id = "MPP";
const { Input, MultiSelect, EwSwitch } = Atomic;
function SelfDev() {
  const options = [
    "Dimension",
    "Second Groups",
    "First Groups",
    "Parameter",
    "Development Area",
    "Development Plan",
    "Milestone",
    "Action Update",
    "Due Date",
    "Development Progress",
  ];
  const {
    SelfDevSectionLabel,
    SelfDevSectionDescription,
    SelfDevDataColumns,
    SelfDevSection,
  } = useSelector((state) => {
    if (!state.DataReducer[id]) return {};
    return state.DataReducer[id];
  });
  const dispatch = useDispatch();
  function updateState(val, type) {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: type,
        data: val,
      },
    });
  }

  return (
    <div>
      <div className="Flex">
        <EwSwitch
          label="Coaching"
          value={SelfDevSection === "E"}
          onChange={(val) => updateState(val ? "E" : "D", "SelfDevSection")}
        />
      </div>
      {SelfDevSection === "E" && (
        <>
          <div className="Flex-row">
            <div>
              <Input
                label={"Section Label"}
                value={SelfDevSectionLabel}
                onChange={(val) => updateState(val, "SelfDevSectionLabel")}
              />
            </div>
            <div>
              <Input
                label={"Section Description"}
                value={SelfDevSectionDescription}
                onChange={(val) =>
                  updateState(val, "SelfDevSectionDescription")
                }
                rows={4}
              />
            </div>
          </div>
          <div className="Flex">
            <MultiSelect
              label="Data Columns"
              menuItems={options}
              value={SelfDevDataColumns}
              onChange={(val) => updateState(val, "SelfDevDataColumns")}
            />
          </div>
        </>
      )}
      <br />
      <br />
    </div>
  );
}

export default SelfDev;
