import { Box, Divider, TableCell, Typography } from "@mui/material";
import React, { useState } from "react";
import Atomic from "../../AComponent/Atomic";
import Molecule from "../../MComponent/Molecule";
import AgendaTable from "./AgendaTable";
import { CoachingDocument } from "./Dimension/Data/CoachingData";

const { Table } = Molecule;
const { Dropdown, MultiSelect } = Atomic;

const CoachingPayloadDocument = {
  AppraisalPlanID: "",
  EmployeePlanID: "",
  AppraiseeUserID: "",
  CoachUserID: "",
  CoachingPlanItems: [],
};
const Coaching = () => {
  const [state, setState] = useState(CoachingPayloadDocument);

  function GeneratePayloadProps(items) {
    const newPlanItems = [...items];
    return newPlanItems.map((item, index) => {
      const newItem = { ...item };
      newItem.PlanEvent = "";
      return newItem;
    });
  }

  console.log(
    JSON.stringify({
      ...state,
      CoachingPlanItems: GeneratePayloadProps(state.CoachingPlanItems),
    }),
    "COACHER"
  );

  return (
    <Box>
      <Typography variant="h4" sx={{ margin: "0.5em 0" }}>
        {CoachingDocument.SectionLabel}
      </Typography>
      <Divider />
      <Typography variant="body1" sx={{ margin: "0.5em 0" }}>
        {CoachingDocument.SectionDescription}
      </Typography>

      <Box>
        <AgendaTable
          SectionID={"Coaching"}
          DataColumns={CoachingDocument.DataColumns}
          onSaveRow={(rows) => {
            setState((prevState) => ({
              ...prevState,
              CoachingPlanItems: [...rows],
            }));
          }}
        />
      </Box>
    </Box>
  );
};

export default Coaching;
