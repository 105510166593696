import { Box, Chip, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import Atomic from "../../../../AComponent/Atomic";
import DataCheckboxes from "../UI/Checkboxes";
import { Close } from "@mui/icons-material";

const {} = Atomic;

const fields = [
  {
    label: "<AloneField1>",
    value: "1",
  },
  {
    label: "<AloneField2>",
    value: "2",
  },
  {
    label: "<AloneField3>",
    value: "3",
  },
  {
    label: "<Lorem Ipsum 1>",
    value: "4",
    children: [
      { label: "<Lorem Ipsum a>", value: "11" },
      { label: "<Lorem Ipsum b>", value: "22" },
      { label: "<Lorem Ipsum c>", value: "33" },
    ],
  },
  {
    label: "<Lorem Ipsum 2>",
    value: "5",
    children: [
      { label: "<Lorem Ipsum d>", value: "44" },
      { label: "<Lorem Ipsum e>", value: "55" },
      { label: "<Lorem Ipsum f>", value: "66" },
    ],
  },
  {
    label: "<AloneField4>",
    value: "6",
  },
];

function Data() {
  const [filledFields, setFilledFields] = useState({});
  const [hiddenFields, setHiddenFields] = useState({});

  function onChangeField(val, setSelected, item) {
    if (!val) {
      setSelected((prevState) => {
        const newState = { ...prevState };
        delete newState[item.value];
        return newState;
      });
    } else {
      setSelected((prevState) => {
        const newState = { ...prevState, [item.value]: item };
        return newState;
      });
    }
  }

  function onChangeChildField(val, setSelected, item, child) {
    if (!val) {
      setSelected((prevState) => {
        const newState = { ...prevState };
        const selectedItemChildren = newState[item.value];
        if (selectedItemChildren.children.hasOwnProperty(child.value)) {
          delete selectedItemChildren.children[child.value];
          if (Object.keys(selectedItemChildren.children).length === 0) {
            delete newState[item.value];
          } else {
            newState[item.value] = selectedItemChildren;
          }
        } else {
          delete newState[item.value];
        }
        return newState;
      });
    } else {
      setSelected((prevState) => {
        const newState = { ...prevState };
        const selectedItemChildren = newState[item.value] || {
          parent: item,
          children: {},
        };
        selectedItemChildren.children[child.value] = child;
        newState[item.value] = selectedItemChildren;
        return newState;
      });
    }
  }

  console.log(filledFields);

  return (
    <Box>
      <Typography variant="h4" fontWeight={"600"}>
        Data
      </Typography>
      <Box
        display={"flex"}
        gap={"1em"}
        marginTop={"1em"}
        alignItems={"flex-start"}
      >
        <Paper sx={{ boxSizing: "border-box", flexBasis: "25vw" }}>
          <Typography
            variant="body1"
            fontWeight={"600"}
            sx={{ marginBottom: "0.5em" }}
          >
            Select fields to be filled
          </Typography>
          <Box height={"60vh"} className="invisble-scroll">
            <DataCheckboxes
              menuItems={[...fields]}
              selected={filledFields}
              onChangeField={({ checked, item }) => {
                onChangeField(checked, setFilledFields, item);
              }}
              onChangeChildField={({ checked, item, child }) => {
                onChangeChildField(checked, setFilledFields, item, child);
              }}
            />
          </Box>
        </Paper>
        <Paper sx={{ boxSizing: "border-box", flexBasis: "25vw" }}>
          <Typography
            variant="body1"
            fontWeight={"600"}
            sx={{ marginBottom: "0.5em" }}
          >
            Select fields to be hidden
          </Typography>
          <Box height={"60vh"} className="invisble-scroll">
            <DataCheckboxes
              menuItems={[...fields]}
              selected={hiddenFields}
              onChangeField={({ checked, item }) => {
                onChangeField(checked, setHiddenFields, item);
              }}
              onChangeChildField={({ checked, item, child }) => {
                onChangeChildField(checked, setHiddenFields, item, child);
              }}
            />
          </Box>
        </Paper>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"1em"}
          flexBasis={"25vw"}
          height={"67vh"}
        >
          <Paper sx={{ boxSizing: "border-box", flex: "1" }}>
            <Typography
              variant="body1"
              fontWeight={"600"}
              sx={{ marginBottom: "0.5em" }}
            >
              Fields to be filled
            </Typography>
            <Box
              height={"25vh"}
              className="invisble-scroll"
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"0.5em"}
            >
              {Object.entries(filledFields).map(([key, value]) => {
                if (value.parent) {
                  return (
                    <>
                      <Typography variant="body2" fontWeight={"400"}>
                        {value.parent.label} :
                      </Typography>
                      <Box
                        display={"flex"}
                        gap={"0.3em"}
                        flexWrap={"wrap"}
                        paddingLeft={"2em"}
                      >
                        {Object.entries(value.children || {}).map(
                          ([key, value]) => {
                            return (
                              <Chip
                                sx={{ backgroundColor: "#E0E0E0" }}
                                size="small"
                                deleteIcon={
                                  <Close sx={{ color: "#272727 !important" }} />
                                }
                                onDelete={() => {}}
                                label={
                                  <Typography variant="small_text">
                                    {value.label}
                                  </Typography>
                                }
                              />
                            );
                          }
                        )}
                      </Box>
                    </>
                  );
                } else {
                  return (
                    <Chip
                      sx={{ backgroundColor: "#E0E0E0" }}
                      size="small"
                      deleteIcon={
                        <Close sx={{ color: "#272727 !important" }} />
                      }
                      onDelete={() => {}}
                      label={
                        <Typography variant="small_text">
                          {value.label}
                        </Typography>
                      }
                    />
                  );
                }
              })}
            </Box>
          </Paper>
          <Paper sx={{ boxSizing: "border-box", flex: "1" }}>
            <Typography
              variant="body1"
              fontWeight={"600"}
              sx={{ marginBottom: "0.5em" }}
            >
              Fields to be hidden
            </Typography>
            <Box
              height={"25vh"}
              className="invisble-scroll"
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              gap={"0.5em"}
            >
              {Object.entries(hiddenFields).map(([key, value]) => {
                if (value.parent) {
                  return (
                    <>
                      <Typography variant="body2" fontWeight={"400"}>
                        {value.parent.label} :
                      </Typography>
                      <Box
                        display={"flex"}
                        gap={"0.3em"}
                        flexWrap={"wrap"}
                        paddingLeft={"2em"}
                      >
                        {Object.entries(value.children || {}).map(
                          ([key, value]) => {
                            return (
                              <Chip
                                sx={{ backgroundColor: "#E0E0E0" }}
                                size="small"
                                deleteIcon={
                                  <Close sx={{ color: "#272727 !important" }} />
                                }
                                onDelete={() => {}}
                                label={
                                  <Typography variant="small_text">
                                    {value.label}
                                  </Typography>
                                }
                              />
                            );
                          }
                        )}
                      </Box>
                    </>
                  );
                } else {
                  return (
                    <Chip
                      sx={{ backgroundColor: "#E0E0E0" }}
                      size="small"
                      deleteIcon={
                        <Close sx={{ color: "#272727 !important" }} />
                      }
                      onDelete={() => {}}
                      label={
                        <Typography variant="small_text">
                          {value.label}
                        </Typography>
                      }
                    />
                  );
                }
              })}
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}

export default Data;
