import { Actions } from "../Actions/Actiontypes";

const initialState = {
  Token: null,
  tenantCode: null,
  userId:"",
  userData: {},
  otherTokens: {},
};

function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_AUTH_TOKEN: {
      return {
        ...state,
        Token: action.payload,
      };
    }
    case Actions.ADD_TENANT_CODE: {
      return {
        ...state,
        tenantCode: action.payload,
      };
    }
    case Actions.ADD_USER_DETAILS: {
      return {
        ...state,
        userData: action.payload,
      };
    }
    case Actions.ADD_OTHER_TOKEN_DETAILS: {
      return {
        ...state,
        otherTokens: action.payload,
      };
    }

    case Actions.ADD_AUTH_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}

export default AuthReducer;
