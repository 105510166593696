import React from "react";
import Grid from "@mui/material/Grid";

const Icons = (props) => {
  const icons = props.props;
  return (
    <Grid container>
      {icons.map((ele) => {
        // let Icon = require('@mui/icons-material/'+ele.AComponent).default
        let Icon = () => <></>;
        return (
          <Grid item xs={6}>
            <Icon fontSize="large" color="action" />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Icons;
