import { Box, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { getUserId, submitPayload } from "../../../../Utils/Common/Library";
import Atomic from "../../../../AComponent/Atomic";
import { WorkflowProcesser } from "../../Dimension/WorkflowProcesser";
import FeatureIDs from "../../../../Utils/Common/FeatureIDs";
import ActionDebouncer from "../../Dimension/ActionDebouncer";
import EwModal from "../../../../MComponent/Modal/Modal";
import AlertMessage from "../../../../Utils/Messages/AlertMessage";

const { Input, Button } = Atomic;

function OverallComments({ EPP, FinalAssessments, handleTabEdit }) {
  const [comments, setComments] = useState({
    Appraiser: EPP.AppraiserOverallComments || "",
    Reviewer: EPP.ReviewerOverallComments || "",
  });

  const AssessmentDocs = { ...FinalAssessments };
  const [modal, setModal] = useState();
  const [actionError, setActionError] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  function getIsValidCurrentStep(label) {
    const DimensionID = EPP.Dimensions[0].DimensionID;
    const doc = AssessmentDocs[DimensionID];
    if (doc.length > 0 && doc[0]) {
      const WorkflowDoc = {
        ConversationID: "",
        WorkflowID: "",
        WorkflowStatus: "",
        WorkflowSteps: "",
      };
      const Workflow = Object.keys(WorkflowDoc).reduce((total, current) => {
        total[current] = doc[0][current];
        return total;
      }, {});

      const workflowProcesser = new WorkflowProcesser(Workflow);
      const CurrentStep = workflowProcesser.getCurrentStepData();

      if (!CurrentStep) {
        return false;
      }

      return (
        CurrentStep.StepOwnerLabel === label &&
        workflowProcesser.getCurrentStepUserID() === getUserId()
      );
    }
    return false;
  }

  function editEPP(val) {
    const edit = {
      TransactionID: EPP.TransactionID,
      EffectiveFromTimestamp: EPP.EffectiveFromTimestamp,
      RequestType: "1",
    };

    return submitPayload({
      featureId: FeatureIDs.EmployeePerformancePlan,
      edit,
      payload: {
        ...EPP,
        AppraiserOverallComments: comments.Appraiser,
        ReviewerOverallComments: comments.Reviewer,
      },
    });
  }

  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: "0.5em",
        padding: "2em",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <Typography variant="h3" color={"primary"}>
        Overall Comments
      </Typography>
      <br />
      <Box>
        <Box display={"flex"} gap={"1em"}>
          <Typography variant="subtitle1">Appraiser</Typography>
          {actionError && (
            <AlertMessage
              {...actionError}
              onClose={() => setActionError(null)}
            />
          )}
        </Box>
        {getIsValidCurrentStep("Appraiser") ? (
          <Input
            label={"Comments"}
            onChange={(val) => {
              handleTabEdit();
              setComments((prevState) => ({ ...prevState, Appraiser: val }));
            }}
            rows={4}
            value={comments.Appraiser}
            styles={{ width: "100%", margin: "1em 0 0 0" }}
          />
        ) : (
          <Typography>{comments.Appraiser || "No Comments added"}</Typography>
        )}
      </Box>
      <br />
      <Box>
        <Typography variant="subtitle1">Reviewer</Typography>
        {getIsValidCurrentStep("Reviewer") ? (
          <Input
            label={"Comments"}
            onChange={(val) => {
              handleTabEdit();
              setComments((prevState) => ({ ...prevState, Reviewer: val }));
            }}
            rows={4}
            value={comments.Reviewer}
            styles={{ width: "100%", margin: "1em 0 0 0" }}
          />
        ) : (
          <Typography>{comments.Reviewer || "No Comments added"}</Typography>
        )}
      </Box>
      {(getIsValidCurrentStep("Appraiser") ||
        getIsValidCurrentStep("Reviewer")) && (
        <Box sx={{ position: "absolute", bottom: "1em", right: "1em" }}>
          <Button
            label={"Save Comments"}
            isLoading={buttonLoading}
            onClick={() => {
              handleTabEdit("save");
              if (!comments.Appraiser) {
                setActionError({
                  type: 1,
                  message: "Appraiser comment is required",
                });
                return;
              }
              setButtonLoading(true);
              editEPP()
                .then(() => {
                  setModal({
                    Component: ActionDebouncer,
                  });
                })
                .finally(() => {
                  setButtonLoading(false);
                });
            }}
            title={"Save Comments"}
          />
        </Box>
      )}
      {modal && (
        <EwModal
          onClose={() => {
            !modal.dontClose && setModal(null);
          }}
          open={modal}
        >
          <modal.Component {...modal.props} />
        </EwModal>
      )}
    </Paper>
  );
}

export default OverallComments;
