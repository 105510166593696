export const DimensionScores = [
  {
    DimensionID: 1,
    DimensionName: "Goals",
    PastTwoCycleAggregateScore: 25,
    CurrentCycleAggregateScore: 12,
    PastTwoCycleModeratedScore: 37,
    CurrentCycleModeratedScore: 18,
  },
  {
    DimensionID: 2,
    DimensionName: "Skills",
    PastTwoCycleAggregateScore: 12,
    CurrentCycleAggregateScore: 25,
    PastTwoCycleModeratedScore: 18,
    CurrentCycleModeratedScore: 37,
  },
  {
    DimensionID: 3,
    DimensionName: "Values",
    PastTwoCycleAggregateScore: 37,
    CurrentCycleAggregateScore: 18,
    PastTwoCycleModeratedScore: 25,
    CurrentCycleModeratedScore: 12,
  },
  {
    DimensionID: 4,
    DimensionName: "Competency",
    PastTwoCycleAggregateScore: 18,
    CurrentCycleAggregateScore: 37,
    PastTwoCycleModeratedScore: 12,
    CurrentCycleModeratedScore: 25,
  },
];
