import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Paper,
  Chip,
  Stack,
  Radio,
  RadioGroup,
  SvgIcon,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Dropdown from "../../AComponent/Dropdown/Dropdown_3";
import useRender from "../../AComponent/utils/useRender";
import { useDispatch, useSelector } from "react-redux";
import { dimensions, payloadMapper, partnerValue } from "./ApplicabilityData";
import Gateway from "../../Utils/Api/Gateway";
import { getTree } from "../../Utils/Common/Library";
import payload from "./payload.json";
import { getData } from "../../Utils/Common/Library";
const location = [
  {
    label: "Kolkata", //
    value: "Kolkata", //id

    list: [
      {
        label: "East 1",
        value: "east1",
        list: [
          {
            label: "Test1",
            value: "test1",
          },
        ],
      },
      {
        label: "East 2",
        value: "east2",
        list: [
          {
            label: "Test2",
            value: "test2",
          },
        ],
      },
    ],
  },
];
const styles = {
  modalStyle: {
    position: "absolute",
    top: "10%",
    left: "30%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  paper: {
    width: "60%",
    padding: 50,
    flexDirection: "column",
    display: "flex",
    position: "relative",
  },
};

function Applicability4() {
  const [parentChild, setParentChild] = useState({});
  const [valueToLabel, setValueToLabel] = useState({});
  const [checked, setChecked] = useState({});
  const [collapse, setCollapse] = useState([]);
  const [valueSelected, setValueSelected] = useState([]);
  const [modal, setModal] = useState(false);
  const [innerModal, setInnerModal] = useState(false);
  const [selectedAll, setSelectedAll] = useState("");
  const [selectedDimension, setSelectedDimension] = useState("");
  const [loadTree, setLoadTree] = useState(true);
  const [loading, setLoading] = useState(true);
  const [tree, SetTree] = useState({});
  const [data, setData] = useState(null);
  const [dropdownValues, setDropdownValue] = useState(dimensions);
  const [finalPayload, setFinalPaylaod] = useState({});
  var bufferParentChild = {};
  var bufferValueToLabel = {};
  var bufferArray = [];
  var dimenshionIdMapping = {};
  const appId = false;

  useEffect(async () => {
    let result = await getData({ featureId: "00103" });
    console.log("Getdata result: ", result);
    if (result.Data) {
      let obj = {};
      Object.keys(result.Data).map((ele) => {
        if (payloadMapper[ele]) {
          obj = { ...obj, [ele]: result.Data[ele] };
        }
      });

      setFinalPaylaod({ ...obj });
    }
    setLoading(false);
    console.log("Fetch result: ", result);
  }, []);
  //useEffect(() => {
  for (let i = 0; i < dimensions.length; i++) {
    dimenshionIdMapping[dimensions[i].value] = dimensions[i].id;
  }
  //}, [])

  function transformAndChangeData(arr) {
    function traverse(arr, parentList) {
      for (let i = 0; i < arr.length; i++) {
        const { label, value, list } = arr[i];
        bufferValueToLabel[value] = label;
        if (list && list.length > 0) {
          let newValue = "all-" + value;
          arr[i].list.unshift({
            label: "All",
            value: newValue,
          });
          if (!bufferParentChild[value])
            bufferParentChild[value] = {
              parent: [...parentList, newValue],
            };
          else {
            bufferParentChild[value].parent = [...parentList, newValue];
          }
          if (!bufferParentChild[newValue])
            bufferParentChild[newValue] = {
              children: [value],
            };
          else {
            bufferParentChild[newValue].children = [
              ...bufferParentChild[newValue].children,
              value,
            ];
          }
          if (!bufferParentChild[newValue].parent)
            bufferParentChild[newValue].parent = parentList;
          else {
            bufferParentChild[newValue].parent = [
              ...bufferParentChild[newValue].parent,
              parentList,
            ];
          }
          traverse(arr[i].list, [...parentList, newValue]);
        } else {
          if (!bufferParentChild[value])
            bufferParentChild[value] = {
              parent: parentList,
            };
          console.log("From buffer: ", parentList, " ", value);
        }
        parentList.map((parent) => {
          console.log("From buffer: ", parent);
          if (!bufferParentChild[parent]) bufferParentChild[parent] = {};
          if (!bufferParentChild[parent].children)
            bufferParentChild[parent].children = [];
          bufferParentChild[parent].children = [
            ...bufferParentChild[parent].children,
            value,
          ];
        });
      }
    }
    traverse(arr, []);
    bufferArray = [...arr];
    console.log("From buffer:11 ", bufferParentChild);
  }

  async function changeDropDownValue(value) {
    setLoadTree(true);
    if (value === -1) {
      //Reset All Values
      setData(null);
      //   setChecked([])
      // setCollapse([])
      setValueSelected(value);
      return;
    }

    //Get Tree Data For The Value
    let payload = {
      function: "dimensionTree",
      FeatureID: dimenshionIdMapping[value],
      FeatureVariantID: "000",
      DimensionID: "*",
      Direction: "B",
    };
    // {
    //   function: "dimensionTree",
    //   //TenantID: '1644570790934',
    //   FeatureID: dimenshionIdMapping[value],
    //   SeedID: "*",
    //   Direction: "*",
    //   FeatureVariantID: "000",
    //   DimensionID:"*"
    // };
    //Get(value)

    let result = await getTree({ payload });
    // await Gateway({
    //   url:
    //     'https://internal.employwise.app/functionHandler',
    //   payload,
    // })
    if (
      !result ||
      !result?.DimensionEntities ||
      result?.DimensionEntities?.length === 0
    ) {
      return;
    }
    console.log("Result: ", result, " ", payload, " ", dimenshionIdMapping);
    bufferParentChild = {};
    bufferValueToLabel = {};
    bufferArray = [];

    transformAndChangeData(result.DimensionEntities);
    setData([...bufferArray]);
    //   setParentChild({ ...bufferParentChild })
    setParentChild((prev) => ({
      ...prev,
      [value]: bufferParentChild,
    }));
    //  setValueToLabel({ ...bufferValueToLabel })
    setValueToLabel((prev) => ({
      ...prev,
      [value]: bufferValueToLabel,
    }));
    setValueSelected(value);
    // setDropdownValue([...tempDrop])
    //  setCollapse([])
    if (!collapse[value])
      setCollapse((prev) => ({
        ...prev,
        [value]: [],
      }));
    //&& finalPayload[value].CoverageType!=="0"
    if (!checked[value] && appId) {
      let childrens = [];
      finalPayload[value][payloadMapper[value].array].map((ele) => {
        const { TreeRule, ID } = ele;
        if (TreeRule === "0") {
          childrens = [...childrens, ...bufferParentChild[ID].children, ID];
        } else {
          childrens = [...childrens, ID];
        }
      });
      console.log("cheked: ", childrens, " ", value, " ", result);
      setChecked((prev) => ({
        ...prev,
        [value]: childrens,
      }));
    } else if (!checked[value])
      setChecked((prev) => ({
        ...prev,
        [value]: [],
      }));
    console.log("After: ", bufferArray);
    setLoadTree(false);
  }
  function onCheckClick(e, selectedDimension) {
    let value = e.target.id;
    let isChecked = e.target.checked;
    let isAll = value.split("-").length > 1;

    let dimenstion = valueSelected;
    if (selectedDimension) dimenstion = selectedDimension;
    let arr = checked[dimenstion];
    console.log("Dimension: ", dimenstion, " ", valueSelected, " ", value, " ");
    if (isAll) {
      let children = parentChild[dimenstion][value].children;
      let parent = [];
      if (parentChild[dimenstion][value].parent)
        parent = parentChild[dimenstion][value].parent;
      if (isChecked) {
        children.map((ele) => {
          arr.push(ele);
        });
      } else {
        children.map((ele) => {
          let index = arr.indexOf(ele);
          if (index !== -1) arr.splice(index, 1);
        });
        parent.map((ele) => {
          let index = arr.indexOf(ele);
          if (index !== -1) arr.splice(index, 1);
        });
      }
    } else {
      let parents = [];
      if (parentChild[dimenstion][value]?.parent)
        parents = parentChild[dimenstion][value].parent;

      if (isChecked) {
        arr.push(value);
      } else {
        parents.map((ele) => {
          let index = arr.indexOf(ele);
          if (index !== -1) arr.splice(index, 1);
        });
        let index = arr.indexOf(value);
        arr.splice(index, 1);
      }
    }

    arr = [...new Set(arr)].filter((ele) => ele);
    console.log("before : ", arr);

    // add selected values to final payload
    let all = [];
    let singleValue = [];
    arr = [...new Set(arr)].filter((ele) => ele);
    console.log("Arr is: ", arr);
    arr.map((ele) => {
      if (ele.split("-").length > 1) {
        all.push(ele);
      } else singleValue.push(ele);
    });
    let allChildrens = [];
    all.map((ele) => {
      allChildrens = [
        ...allChildrens,
        ...parentChild[dimenstion][ele].children,
      ];
    });
    singleValue = singleValue.filter((item) => !allChildrens.includes(item));
    let tempAll = [...all];
    for (let i = 0; i < all.length; i++) {
      let parents = parentChild[dimenstion][all[i]].parent;
      // console.log("Single Value: 1",parents," ",tempAll)
      for (let j = 0; j < parents.length; j++) {
        if (tempAll.includes(parents[j])) {
          let index = tempAll.indexOf(parents[i]);
          tempAll.splice(index, 1);
          break;
        }
      }
    }
    all = [...tempAll];
    console.log("Single Value: ", singleValue, " ", all);
    let obj = {};

    let temp = [];
    let childrens = [];

    all.map((ele) => {
      temp.push({
        TreeRule: "0",
        ID: ele.split("-")[1],
      });
      childrens = [...childrens, ...parentChild[dimenstion][ele]?.children];
    });
    console.log("Children: ", childrens, " ", singleValue);
    singleValue
      .filter((item) => !childrens.includes(item))
      .map((ele) => {
        temp.push({
          TreeRule: "1",
          ID: ele,
        });
      });
    obj[payloadMapper[dimenstion].array] = temp;
    console.log("Test: ", obj);

    setFinalPaylaod((prev) => ({
      ...prev,
      [dimenstion]: {
        ...obj,
        CoverageType: prev[dimenstion].CoverageType,
      },
    }));
    // setChecked([...arr])
    setChecked((prev) => ({
      ...prev,
      [dimenstion]: [...arr],
    }));
  }
  function Tree({ data }) {
    return (
      <>
        {data.map((ele) => {
          const { label, value, list } = ele;

          function renderCollapse() {
            function changeCollapse(flag) {
              if (flag) {
                //    setCollapse((prev) => [...prev, value])
                setCollapse((prev) => ({
                  ...prev,
                  [valueSelected]: [...prev[valueSelected], value],
                }));
              } else {
                let arr = collapse[valueSelected];
                let index = arr.indexOf(value);
                arr.splice(index, 1);
                //    setCollapse([...arr])
                setCollapse((prev) => ({ ...prev, [valueSelected]: [...arr] }));
              }
            }
            if (!collapse[valueSelected]?.includes(value)) {
              return (
                <span onClick={() => changeCollapse(true)}>
                  <AddIcon style={{ cursor: "pointer" }} />
                </span>
              );
            } else {
              return (
                <span>
                  <RemoveIcon
                    onClick={() => changeCollapse(false)}
                    style={{ cursor: "pointer" }}
                  />
                </span>
              );
            }
          }
          if (list?.length > 0) {
            return (
              <div>
                {renderCollapse()}
                {console.log("before chekbox: ", checked)}
                <FormControlLabel
                  control={
                    <Checkbox
                      id={value}
                      //   ref={(el) => (checkref.current[value] = el)}
                      onChange={(e) => onCheckClick(e)}
                    />
                  }
                  checked={checked[valueSelected].includes(value)}
                  label={label}
                />
                <br />
                <div style={{ marginLeft: 30 }}>
                  {collapse[valueSelected]?.includes(value) && (
                    <Tree data={list} />
                  )}
                </div>
              </div>
            );
          } else {
            return (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={value}
                      //   ref={(el) => (checkref.current[value] = el)}
                      onChange={(e) => onCheckClick(e)}
                    />
                  }
                  checked={checked[valueSelected].includes(value)}
                  label={label}
                />
                <br />
              </>
            );
          }
        })}
        {/* {JSON.stringify(data)} */}
      </>
    );
  }
  function Content() {
    // const [renderTreeFlag,setRenderTreeFlag]=useState(true);
    var coverageType = "0";
    if (finalPayload[valueSelected] && finalPayload[valueSelected].CoverageType)
      coverageType = finalPayload[valueSelected].CoverageType;
    var isAny = coverageType === "0";
    if (valueSelected === -1 || ("" + valueSelected).trim(" ") === "")
      return <></>;
    function handleAny(e) {
      //  setRenderTreeFlag(!renderTreeFlag)
      let flag = e.target.checked;
      let obj = finalPayload;
      if (!obj[valueSelected]) obj[valueSelected] = {};
      let tempDrop = dropdownValues;
      let value = valueSelected;
      if (partnerValue[value] && !flag) {
        let val = partnerValue[value];

        for (let i = 0; i < tempDrop.length; i++) {
          if (tempDrop[i].value === val) {
            tempDrop[i].disable = true;
            break;
          }
        }
      } else if (partnerValue[value]) {
        let val = partnerValue[value];

        for (let i = 0; i < tempDrop.length; i++) {
          if (tempDrop[i].value === val) {
            tempDrop[i].disable = false;
            break;
          }
        }
      }

      console.log("tempdrop: ", tempDrop, " ", flag);
      obj[valueSelected].CoverageType = flag ? "0" : "2";
      setFinalPaylaod({ ...obj });
      setDropdownValue([...tempDrop]);
    }
    function changeCoverageType(e) {
      let value = e.target.value;
      setFinalPaylaod((prev) => ({
        ...prev,
        [valueSelected]: {
          ...prev[valueSelected],
          CoverageType: value,
        },
      }));
    }
    return (
      <>
        <FormControlLabel
          control={<Checkbox checked={isAny} onChange={handleAny} />}
          label={"any"}
        />
        {!isAny && (
          <>
            <RadioGroup
              value={coverageType}
              onChange={changeCoverageType}
              name="radio-buttons-group"
              style={{ flexDirection: "row" }}
            >
              <FormControlLabel
                value={"2"}
                control={<Radio />}
                label="Include"
              />
              <FormControlLabel
                value={"1"}
                control={<Radio />}
                label="Exclude"
              />
            </RadioGroup>
            <Tree data={data} />
          </>
        )}
      </>
    );
  }
  function InnerM() {
    //
    if (!parentChild[selectedDimension]) return <></>;
    console.log(
      "Testss: ",
      parentChild[selectedDimension]["all-" + selectedAll],
      " ",
      parentChild[selectedDimension],
      " ",
      selectedDimension
    );

    return (
      <>
        <Modal
          style={styles.modalStyle}
          open={innerModal}
          onClose={() => {
            setInnerModal(false);
          }}
        >
          <Paper
            style={{
              width: "60%",
              padding: 50,
              flexDirection: "column",
              display: "flex",
              position: "relative",
            }}
          >
            <ul>
              {parentChild[selectedDimension][
                "all-" + selectedAll
              ]?.children?.map((ele) => {
                function remove(deleteItem) {
                  var e = {
                    target: {
                      id: ele,
                      checked: false,
                    },
                  };
                  onCheckClick(
                    e,
                    selectedDimension !== valueSelected
                      ? selectedDimension
                      : null
                  );
                  setInnerModal(false);
                  setSelectedAll("");
                }

                return (
                  <li>
                    <Chip
                      label={valueToLabel[selectedDimension][ele]}
                      onDelete={() => remove(ele)}
                    />
                  </li>
                );
              })}
            </ul>
          </Paper>
        </Modal>
      </>
    );
  }
  function renderSelectedList() {
    function renderArray(list, dimenstion) {
      return (
        <div style={{ flexDirection: "row" }}>
          {list?.map((ele) => {
            const { TreeRule, ID } = ele;
            //  if(!ele)return<></>
            function openInnerModal(id, select) {
              setInnerModal(true);
              setSelectedAll(id);
              setSelectedDimension(dimenstion);
            }
            function remove() {
              var e = {
                target: {
                  id: ID,
                  checked: false,
                },
              };
              onCheckClick(e, valueSelected !== dimenstion ? dimenstion : null);
            }
            if (TreeRule === "0") {
              return (
                <Chip
                  label={"All-" + valueToLabel[dimenstion][ID]}
                  variant="outlined"
                  onClick={() => openInnerModal(ID, valueSelected)}
                />
              );
            } else {
              return (
                <Chip label={valueToLabel[dimenstion][ID]} onDelete={remove} />
              );
            }
          })}
        </div>
      );
    }
    return (
      <>
        {Object.keys(finalPayload).map((ele) => {
          console.log("Final Payload: ", finalPayload);
          let list = finalPayload[ele][payloadMapper[ele].array];
          let coverageType = finalPayload[ele].CoverageType;
          if (coverageType === "0" || list?.length === 0) return <></>;

          return (
            <>
              {ele + " :"}
              {renderArray(list, ele)}
            </>
          );
        })}
      </>
    );
  }
  async function save() {
    let data = {
      AuthorFeatureID: "12108",
      AuthorFeatureVariantID: "000",
      AuthorBusinessRuleID: "108",
      ParentApplicabilityID: "*",
      ApplicabilityCoverage: "1",
      EmployeeStatusApplicability: {
        CoverageType: "1",
      },
      ConfirmationStatusApplicability: {
        CoverageType: "1",
      },
    };
    Object.keys(payloadMapper).map((ele) => {
      if (finalPayload[ele]) {
        data[ele] = finalPayload[ele];
      } else {
        data[ele] = {
          CoverageType: "0",
          [payloadMapper[ele].array]: [],
        };
      }
    });

    var applicabiltyPayload = {
      Action: "E",
      Payload: {
        Header: {
          DocumentID: "",
          DocumentType: "3",
          FeatureID: "00103",
          FeatureVariantID: "002",
          FeatureType: "Primary",
          Version: "000",
          TenantID: "1644570790934",
          ModuleID: "12",
          TenantCode: "*",
          TransactionID: "1657178793642",
          CreationTimestamp: "<timeStamp>",
          EffectiveFromTimestamp: "<timeStamp>",
          EffectiveTillTimestamp: "<timeStamp>",
          Status: "1",
        },
        Data: data,
      },
    };
    console.log("Payload: ", JSON.stringify(data));
    let result = await Gateway({
      url: "http://featureprojectapplication-env.eba-f7ufjfkn.ap-south-1.elasticbeanstalk.com/createapplicability",
      payload: applicabiltyPayload,
    });
    console.log(result);
  }
  if (loading) return <>Loading Applicability Data...</>;
  return (
    <>
      <div>
        <div className="Flex">
          <Button onClick={() => setModal(true)}>Add Applicability</Button>
        </div>
        <Modal
          style={styles.modalStyle}
          open={modal}
          onClose={() => setModal(false)}
        >
          <Paper style={styles.paper}>
            {InnerM()}
            <div style={{ position: "absolute", right: 10 }}>
              <Button onClick={() => setModal(false)} color="error">
                Cancel
              </Button>
            </div>
            <div>
              <h1>Applicability</h1>
              <hr />
            </div>
            <Dropdown
              onChange={changeDropDownValue}
              label={"Select From"}
              menuItems={dropdownValues}
            />
            {!loadTree ? <Content /> : <span>Loading Tree...</span>}
            <hr />
            <small>Selected Values:</small>
            {renderSelectedList()}
            {/* <TextField fullWidth label="Add Applicability" id="fullWidth" variant="standard"/> */}
            <hr />
            <Button variant="contained" onClick={save}>
              Save Applicability
            </Button>
          </Paper>
        </Modal>
      </div>
    </>
  );
}

export default Applicability4;
