import React, { useEffect, useState } from "react";
import Molecule from "../../../MComponent/Molecule";
import Atomic from "../../../AComponent/Atomic";
import {
  getData,
  getFullDateFromEpoch,
  generateReport,
  getDateFromEpoch,
  getUserId,
} from "../../../Utils/Common/Library";
import FeatureIDs from "../../../Utils/Common/FeatureIDs";
import { Typography } from "@mui/material";
import axios from "axios";
import Loading from "./loading";
import DownloadIcon from "@mui/icons-material/Download";
import ReportsTable from "./ReportsTable";
import mappings, { periodMapping } from "./data";
import AlertMessage from "../../../Utils/Messages/AlertMessage";
import Applicability6 from "../../../MComponent/Applicability/Applicability5";
import extract from "./ExtractApplicabilityData";

const data = [
  ["1st August, 2023", "Report 1", { Component: DownloadComponent }],
];
const { EwModal, Tabs } = Molecule;
const { Dropdown, Button } = Atomic;
function DownloadComponent({ status }) {
  const [loading, setLoading] = useState(false);
  function downloadFile() {}
  // function downloadFile() {
  //   setLoading(true);
  //   const axiosWithoutAuth = axios.create();
  //   delete axiosWithoutAuth.defaults.headers.common["Authorization"]; //Delete 2.0 Authorization token for aws.
  //   console.log("Headers: ", axiosWithoutAuth.defaults.headers);
  //   axiosWithoutAuth({
  //     url,
  //     method: "GET",
  //     responseType: "blob",
  //   })
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "dummy.json");
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading file:", error);
  //     })
  //     .finally(() => {
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //     });
  // }
  if (status === "P") return <Loading />;
  return (
    <>
      <Typography>
        <span onClick={downloadFile} className="pointer">
          {loading ? "Downloading" : <DownloadIcon />}
        </span>
      </Typography>
    </>
  );
}
function PlanProgress({ onClose, reportType, isAppraiser }) {
  const [PlanList, setPlanList] = useState([]);
  const [selectedPLanPeriod, setSelectedPlanPeriod] = useState(-1);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [DimensionID, setDimensionID] = useState(-1);
  const [period, setPeriod] = useState(-1);
  const [serverData, setServerData] = useState({});
  const [message, setMessage] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [applicabilityFilter, setApplicabilityFilter] = useState(null);

  function planSelected(planId) {
    setSelectedPlan(planId);
    setSelectedPlanPeriod(-1);
    setDimensionID(-1);
    setPeriod(-1);
    setPlanList([]);
    setServerData((prev) => ({ ...prev, periods: null }));
    getData({
      featureId: FeatureIDs.PerformanceCycleInitiation,
      payload: { "Data.MPPID": planId },
    }).then((res) => {
      if (res?.Data) {
        let arr = res.Data.map((ele) => {
          console.log("Test ele is: ", ele.PlanCycleEndDate);
          let planPeriod =
            getDateFromEpoch({ epoch: +ele.PlanCycleStartDate }) +
            " - " +
            getDateFromEpoch({ epoch: +ele.PlanCycleEndDate });
          return {
            label: planPeriod,
            value: ele.TransactionID,
            ReviewFromDate: ele.PlanCycleStartDate,
            ReviewTillDate: ele.PlanCycleEndDate,
          };
        });
        console.log("Array is : ", arr);
        setPlanList([...arr]);
      }
    });
  }
  function onSubmit() {
    let doc = PlanList.find((item) => item.value === selectedPLanPeriod);
    const { plans, periods, dimensions, assessment } = serverData;

    const DimensionIDValue = dimensions?.find(
      (item) => item.value === DimensionID
    )?.label;
    const MasterPerformancePlanIDValue = plans?.find(
      (item) => item.value === selectedPlan
    )?.label;
    const ReviewPeriodUnit = assessment?.find(
      (item) => item.value === period
    ).label;
    console.log(
      "Server data: ",
      DimensionIDValue,
      " ",
      MasterPerformancePlanIDValue
    );
    if (!DimensionIDValue || !MasterPerformancePlanIDValue) return;
    // const planName = plans?.find((item) => {});
    if (!doc) return;
    const { ReviewFromDate, ReviewTillDate } = doc;

    let requiredFields = [
      { DimensionID, DimensionIDValue },
      { MasterPerformancePlanID: selectedPlan, MasterPerformancePlanIDValue },
      {
        ReviewPeriodUnit,
        ReviewPeriodUnitValue: periodMapping[ReviewPeriodUnit]
          ? periodMapping[ReviewPeriodUnit]
          : "Month",
      },
      {
        ReviewFromDate,
        ReviewFromDateValue: getDateFromEpoch({
          epoch: ReviewFromDate,
          format: 1,
        }),
      },
      {
        ReviewTillDate,
        ReviewTillDateValue: getDateFromEpoch({
          epoch: ReviewTillDate,
          format: 1,
        }),
      },
      ...extract(applicabilityFilter),
    ];

    console.log("Get Report: ", requiredFields);

    setMessage({
      type: 3,
      message:
        "The report is in the process of being generated and may take a while. Kindly check downloads tab after some time.",
    });
    setDisableButton(true);
    const payload = {
      queryName: mappings[reportType ? reportType : "PT"],
      requiredFields,
    };
    if (isAppraiser) {
      payload.queryName = mappings[reportType ? "A" + reportType : "APT"];
      payload.AppraiserUserID = getUserId();
    }

    generateReport(payload)
      .then((res) => {
        setMessage({
          type: 0,
          message: "Report Generated",
        });
      })
      .catch((e) => {
        console.log("Error: ", e);
        setMessage({
          type: 1,
          message: "Network Error",
        });
      })
      .finally(() => {
        setDisableButton(false);
      });
  }
  function Report() {
    return (
      <>
        <div className="Flex-row g20" style={{ width: "100%" }}>
          <Dropdown
            styles={{ width: "100%" }}
            label={"Select Plan"}
            featureId={FeatureIDs.MasterPerformancePlan}
            mapper={{ label: "PlanName", value: "TransactionID" }}
            value={selectedPlan}
            onChange={planSelected}
            storeApiResult={(data) =>
              setServerData((prev) => ({ ...prev, plans: data }))
            }
            apiResult={serverData.plans}
          />
          {PlanList?.length > 0 && (
            <Dropdown
              label={"Plan Period"}
              styles={{ width: "100%" }}
              menuItems={PlanList}
              value={selectedPLanPeriod}
              onChange={(val) => {
                console.log("val is: ", val);
                setSelectedPlanPeriod(val);
                setServerData((prev) => ({ ...prev, dimensions: null }));
                setDimensionID(null);
              }}
              mapper={{ label: "planPeriod", value: "TransactionID" }}
              storeApiResult={(data) =>
                setServerData((prev) => ({ ...prev, periods: data }))
              }
              apiResult={serverData.periods}
            />
          )}
        </div>

        <br />
        {selectedPLanPeriod !== -1 && (
          <div className="Flex-row g20">
            <Dropdown
              styles={{ width: "100%" }}
              label={"Dimensions"}
              featureId={FeatureIDs.PerformanceDimensionGrouping}
              mapper={{
                label: "DimensionName",
                value: "TransactionID",
              }}
              value={DimensionID}
              onChange={(val) => {
                setDimensionID(val);
                setPeriod(-1);
                setServerData((prev) => ({ ...prev, assessment: null }));
              }}
              storeApiResult={(data) =>
                setServerData((prev) => ({ ...prev, dimensions: data }))
              }
              apiResult={serverData.dimensions}
            />
            {DimensionID && DimensionID !== -1 && (
              <Dropdown
                styles={{ width: "100%" }}
                featureId={FeatureIDs.PerformanceAssessmentInitiation}
                label={"Select Plan"}
                getDataProps={{
                  payload: {
                    "Data.MPPID": selectedPlan,
                    "Data.DimensionID": DimensionID,
                  },
                }}
                mapper={{
                  label: "AssessmentPeriod",
                  value: "TransactionID",
                }}
                value={period}
                onChange={(val) => setPeriod(val)}
                storeApiResult={(data) =>
                  setServerData((prev) => ({ ...prev, assessment: data }))
                }
                apiResult={serverData.assessment}
              />
            )}
          </div>
        )}
        {message && (
          <>
            {" "}
            <br />
            <AlertMessage {...message} onClose={() => setMessage(null)} />
          </>
        )}
        <br />

        <br />
        {period && period !== -1 && (
          <div className="Flex g20">
            <Applicability6
              onChange={setApplicabilityFilter}
              value={applicabilityFilter}
              passPayload={true}
              AuthorFeatureID={"Reports"}
            />

            <Button
              disable={disableButton}
              title={"Generate New Report"}
              onClick={onSubmit}
            />
          </div>
        )}
      </>
    );
  }
  return (
    <EwModal open={true} onClose={onClose}>
      <div
        className="scroll"
        style={{ width: 900, minHeight: 300, maxHeight: 500 }}
      >
        <div className="Flex">
          <Typography variant="h1">
            {reportType === "AR" ? "Appraisal Report" : "Plan Progress Report"}
          </Typography>
        </div>
        <br />
        <Tabs
          componentList={[
            { label: "Generate Report", Component: Report },
            {
              label: "Download Reports",
              Component: ReportsTable,
              props: {
                type: reportType
                  ? isAppraiser
                    ? "A" + reportType
                    : reportType
                  : isAppraiser
                  ? "APT"
                  : "PT",
                isAppraiser,
              },
            },
          ]}
        />
        {/* <br />
        <Table
          pageLimit={5}
          heading={["Date", "Name", "Downloads"]}
          rows={data}
          removeSearch={true}
        />
        <br /> */}
      </div>
    </EwModal>
  );
}

export default PlanProgress;
