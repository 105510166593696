import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import useProps from "../../Utils/CustomHooks/GetProps";
const initialProps={
    label:null,
    value:null,
    onChange:null
}
export default function EwSwitch(props=initialProps) {
    const {label,value,onChange}=useProps(props)
  return (
    <FormGroup>
      <FormControlLabel control={<Switch checked={value} onChange={(e)=>onChange(e.target.checked)}  />} label={label} {...props}/>
     
    </FormGroup>
  );
}
