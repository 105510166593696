import React from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';



function Radio_1({options,label,onChnage}){
    const [value, setValue] = React.useState('female');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
    return(
        <>
         <FormControl  component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
        {/* <FormControlLabel value="female" control={<Radio />} label="Female" />
        <FormControlLabel value="male" control={<Radio />} label="Male" />
        <FormControlLabel value="other" control={<Radio />} label="Other" />
        <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" /> */}
        {options.map((ele,index)=>{
            return <FormControlLabel value={ele.value} control={<Radio />} label={ele.label} key={index} {...ele.otherProps} />
        })}
      </RadioGroup>
    </FormControl>
        </>
    )
}


export default Radio_1;