import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import useProps from "../../Utils/CustomHooks/GetProps";
const initOptions = {
  label: null,
  value: null,
  onChange: null,
  disabled: null,
};

function Checkboxes(props = initOptions) {
  const { label, value, onChange, disabled } = useProps(props);

  return (
    <div>
      {" "}
      <FormGroup>
        <FormControlLabel
          onClick={(e) => onChange(e.target.checked)}
          control={<Checkbox checked={value} disabled={disabled} />}
          label={label}
        />
      </FormGroup>
    </div>
  );
}

export default Checkboxes;
