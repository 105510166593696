import React from "react";
import EWList from "../../MComponent/Menu/EWList";

const MenuItemCard = (props) => {
    const Title = props.props.Title;
    const test="../../MolecularComponents/"+Title.MComponent;
     console.log("From Menu Item Card: ",Title);
    // const Text=require(test).default;

    const TitleComponent = Title.MComponent !== undefined ? require('../../AComponent/Text/'+Title.MComponent).default : null;
   if(!TitleComponent)return<></>
    return <>
    <TitleComponent {...Title}/>
    <EWList {...props.props}/>
    </>
}

export default MenuItemCard;