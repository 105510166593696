import { Actions } from '../Actions/Actiontypes'

const initialState = {
  open: false,
  componentDetails: {},
}

function TwoFactor(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_2_FACTOR: {
      return {
        open: true,
      }
    }

    case Actions.UNSET_2_FACTOR: {
      return {
        ...state,
        open: false,
      }
    }
    default:
      return state
  }
}

export default TwoFactor
