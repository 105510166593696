import axios from "axios";
import store from "../../Redux/Store";
import Cookies from "js-cookie";
import { performLogout, setTokens } from "../Common/Library";
import URLS from "../../Utils/index";

//const address="http://localhost:5000"
//const address ='http://layoutgeneratorapplication-env.eba-iahmzz6t.ap-south-1.elasticbeanstalk.com/layout-generator'
//const address='http://layoutgenerator-env-1.eba-zmyx3mvx.ap-south-1.elasticbeanstalk.com/layout-generator'
const address = "";
const FILE_NAME = "Gateway.js ";
const {
  AUTH_URL,
  FUNCTION_HANDLER_URL,
  TRANSACTION_WRITER_NO_TOKEN_URL,
  LAYOUT_GENERATOR,
  TAM_URL,
} = URLS;
// const functionhandler =
//   'http://functionhandler-env.eba-nsef3vus.ap-south-1.elasticbeanstalk.com/functionHandler'
//const token='eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ1YTA1bWpFZ0JTcU1GR3FVNG9zYXhOTlQ4OW5Sa2prcEN0c3NQYV9vMkhFIn0.eyJleHAiOjE2NDU2MzI5MjEsImlhdCI6MTY0NTU5NjkyMSwianRpIjoiMTU3OTc4NTMtMTMwYy00NGExLTg0ZTQtYTBlOGZiNTk3YjFmIiwiaXNzIjoiaHR0cHM6Ly9pYW11cGdyYWRlLm15ZW1wbG95d2lzZS5jb20vYXV0aC9yZWFsbXMvQ01NQzUiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiNGYzNDcyYjYtNWJiZi00OGRmLTllNjAtNTY4OWM2MDIzMmZjIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoidGVuMTAwIiwic2Vzc2lvbl9zdGF0ZSI6IjIwZTg2NGIzLTRiZGYtNDM4My04OWU1LWRjM2Y0MWNjMzQ1NyIsImFjciI6IjEiLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiVGVuYW50QWRtaW4iLCJvZmZsaW5lX2FjY2VzcyIsImFkbWluIiwiZGVmYXVsdC1yb2xlcy1jbW1jNSIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJvcGVuaWQgZW1haWwgcHJvZmlsZSIsInNpZCI6IjIwZTg2NGIzLTRiZGYtNDM4My04OWU1LWRjM2Y0MWNjMzQ1NyIsIlRlbmFudElEIjoiMTY0NDU3MDc5MDkzNCIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiTml0YW5zaHUgU2hhcm1hIiwicHJlZmVycmVkX3VzZXJuYW1lIjoibml0YW5zaHUiLCJnaXZlbl9uYW1lIjoiTml0YW5zaHUiLCJmYW1pbHlfbmFtZSI6IlNoYXJtYSIsImVtYWlsIjoibml0YW5zaHUuc2hhcm1hQGVtcGxveXdpc2UuY29tIn0.nvZp7Rk0hmJ3SvnR1_Bas3EpxcHuwwgnx5nZmQhuNgM3xjZEkyjn7B2yw5jS_uTnuxapWMwt7-LZqL-69BVoLk04kYTzZtaSOfK-aHTJ774HDSyS5OwxgjfXIerHI28W2lFre-9qprQ1T-xWgijQfp07MhLf1aZ72bmKIG9oBDm-ML4Nfj67MYT3-o1iZMl_6t4y-0ipzxUHO6oghK63KmfJa0RFvGSGb0ut_TPFH3LGDiLKiwXNHctSvkO26jclbdqleiuW13c08ofNUTAzFveFHCt3zIuYtClJZ34XWjGeLRctmGziXG-QnI1xou1zs7_s7GrgpWLYXcSH_bZ7zw'
const initialProps = {
  payload: null,
  endpoint: null,
  url: null,
  type: null,
  displayError: null,
  displayMsg: null,
  tenantCode: null,
  showModalErrorOnFailure: false,
  params: {}, //For Get Request
};
function Gateway(props = initialProps) {
  console.log(FILE_NAME);
  var TenantCode =
    store.getState().AuthReducer?.tenantCode ||
    document.getElementById("root").dataset.tenantcode;

  var {
    payload,
    config,
    endpoint,
    type,
    url,
    displayError,
    displayMsg,
    tenantCode,
    params,
    showModalErrorOnFailure,
  } = props;
  var URL = "";
  console.log(FILE_NAME, " props", props);
  if (!type) type = "POST";
  if (!endpoint) endpoint = "";
  //Temp will be removed later on when we have single URL
  const isTam = store.getState().AuthReducer.isTam;
  if (url) URL = url;
  else URL = address + endpoint;
  axios.defaults.headers.common = {
    Authorization: `Bearer ${
      store.getState().AuthReducer.Token
    },Refresh ${Cookies.get("EwRefreshToken")}`,
  };
  if (isTam) {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${store.getState().AuthReducer.Token}`,
    };
  }

  console.log("testGateway: ", tenantCode, " ", TenantCode);
  if (tenantCode !== false) {
    if (TenantCode && payload?.Payload) {
      console.log("testGateway: ins", TenantCode, " ", payload);
      payload.TenantCode = TenantCode;
    }
    if (TenantCode && payload && !payload.Payload) {
      payload.TenantCode = TenantCode;
    }
  }

  if (type === "GET") {
    let str = "";
    if (params && Object.keys(params).length > 0) {
      Object.entries(params).forEach(([key, value]) => {
        if (str === "") str = "?";
        else str += "&";
        str += key + "=" + value;
      });
    }
    URL += str;
    console.log("Getting ", URL);
    return new Promise((resolve, reject) => {
      axios
        .get(URL)
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((e) => reject(e));
    });
  } else if (type === "DELETE") {
    return new Promise((resolve, reject) => {
      axios
        .delete(URL, {
          data: payload,
        })
        .then((res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((e) => reject(e));
    });
  }
  console.log(
    FILE_NAME,
    " Before sending: ",
    JSON.stringify(payload),
    URL,
    " ",
    axios.defaults.headers.common
  );

  return new Promise((resolve, reject) => {
    axios
      .post(URL, payload)
      .then((res) => {
        console.log(
          "Response is: ",
          JSON.stringify(res.headers),
          " ",
          res.data
        );
        const { accesstoken, refreshtoken } = res.headers;
        console.log("URLS: ", accesstoken, " ", refreshtoken);
        if (
          AUTH_URL !== URL &&
          URL !== FUNCTION_HANDLER_URL &&
          URL !== TRANSACTION_WRITER_NO_TOKEN_URL &&
          URL !== LAYOUT_GENERATOR &&
          !URL.includes(TAM_URL)
        ) {
          if (
            (accesstoken && accesstoken !== "undefined") ||
            (refreshtoken && refreshtoken !== "undefined")
          ) {
            console.log("URLS: 1");
            setTokens({ accessToken: accesstoken, refreshToken: refreshtoken });
          } else {
            console.log("URLS: 2");
            //   performLogout();
            return;
          }
        }

        console.log(FILE_NAME, JSON.stringify(res.data), " ", res.data);
        if (displayMsg)
          store.dispatch({
            type: "SET_MESSAGE",
            payload: {
              id: 0,
              message: "Success!",
            },
          });
        resolve(res.data);
      })
      .catch((e) => {
        //0->yes,1->no

        console.log(FILE_NAME, " Error: ", e?.response);
        //  console.log("Unauth: ",e.response.data)
        if (e?.response?.data?.code === 401) {
          store.dispatch({
            type: "SET_MESSAGE",
            payload: {
              id: 1,
              message: "Session Timed Out!",
            },
          });
          performLogout({ byPassCheck: true });
        } else if (showModalErrorOnFailure) {
          store.dispatch({
            type: "SET_MESSAGE",
            payload: {
              id: 1,
              message: "Oops Something went wrong!",
            },
          });
        }

        reject(e);
      });
  });
}

export default Gateway;
