import React, { useState,useEffect } from 'react'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import LayoutRenderer from '../Interpreter/LayoutRenderer'
import { useSelector, useDispatch } from 'react-redux'
import { Button,TextField } from '@mui/material'
import Gateway from '../Utils/Api/Gateway'
import {getLayout} from "../Utils/Validators/library"

const Shell = {
  type: 'layout',
  layout: [
    {
      Flex: {
        rowFlex: 'center',
      },
      components: [
        {
          componentName: 'C.Cards.Shell_2',
          componentNumber: '0001',
          props: {},
        },
      ],
    },
  ],
}

function LayoutUpload() {
  const [json, setJson] = useState('')
  const [renderMode, setRenderMode] = useState(false)
  const [oldCopy, setOldCopy] = useState(null)
  const [mouse, setMouse] = useState({ x: 0, y: 0 })
  const [empty,setEmpty]=useState({set:false})
  const layout = useSelector((state) => {
    console.log('From Reducer ', state)
    return state.LayoutReducer.Layout
  })
  const modal = useSelector((state) => {
    return state.LayoutReducer.Modal
  })
  const dispatch = useDispatch()
  function render() {
    setRenderMode(true)
    dispatch({
      type: 'SET',
      payload: JSON.parse(json),
    })
  }
  function reset() {
    setRenderMode(false)
    dispatch({
      type: 'SET',
      payload: {},
    })
    setJson('')
  }
//   useEffect(()=>{

// axios.get("https://www.chipsoft.in/asperm/services/separationM?scope=I&mode=create_separation_form&token=de67e804-d158-4ec1-bed7-35a38b3d502f&device_id=6c76ee9d83ab31e4").then((res)=>{
//   console.log("Mobile: ",res.data)
// }).catch((e)=>{
//   console.log("Mobile: ",e)
// })
//   },[])
  function convert() {
    let data = json
    setOldCopy(data)
    data = data.replaceAll('center', '1')
    data = data.replaceAll('start', '2')
    data = data.replaceAll('end', '3')
    data = data.replaceAll('between', '4')
    data = data.replaceAll('around', '5')
    data = data.replaceAll('"row"', '"1"')
    data = data.replaceAll('"column"', '"2"')
   

    let newJson = {}
    newJson.Header = {
      DocumentID: '',
      DocumentType: '2',
      FeatureID: '00',
      FeatureVariant: '00',
      FeatureType: 'Primary',
      Version: '000',
      TenantID: '00',
      TenantCode: '*',
      TransactionID: '',
      CreationTimestamp: '<timeStamp>',
      EffectiveFromTimestamp: '<timeStamp>',
      EffectiveTillTimestamp: '<timeStamp>',
      Status: '1',
    }
    console.log("Convert data: ",JSON.parse(data))
    newJson.Data = JSON.parse(data)
    setJson(JSON.stringify(newJson,null,2))
  }

  function undoConvert() {
    if (!oldCopy) return
    setJson(oldCopy)
    setOldCopy(null)
  }

  function mouseCoordinate(e) {
    setMouse({
      x: e.screenX,
      y: e.screenY,
    })
  }

function Empty(){
  if(!empty.set)return<></>;
  return<>
  <div style={{width:200,height:200,position:'absolute',left:mouse.x,right:mouse.y}}>
   <Button>Add Component</Button>
   <Button>Add Layout</Button>
  </div>
  </>
}


function changeText(e){
  let val=e.target.value;
  console.log("From change: ",val)
  // if(val.charAt(val.length-1)==='/'){
  //   setEmpty({set:true})
  // }else{
  //   setEmpty({set:false})
  // }
 setJson(val)
}

function onKeypressEvent(e){
  console.log("From key press: ",e.key);
  if(e.key==='/'){
    setEmpty({set:true})
  }else{
    setEmpty({set:false})
  }
}
function uploadDoc(){
  console.log(JSON.parse(json))
  let url='http://functionhandler-env-1.eba-wnefnt9d.ap-south-1.elasticbeanstalk.com/functionHandler/insertfeaturedoc';
  let url2='http://functionhandler-env.eba-nsef3vus.ap-south-1.elasticbeanstalk.com/functionHandler/insertfeaturedoc'
  Gateway({
    url: url,
    payload: JSON.parse(json),
    displayMsg:true,
    displayError:0
  }).then((res)=>{
    console.log("From layout uplaod; ",res)
  })
}
function fetchLayout(){
  var id=document.getElementById("layout").value;
  getLayout({FeatureID:id},true).then((res)=>{
    console.log("Result is: ",res)
    setJson(JSON.stringify(res))
  })
}
// onMouseMove={mouseCoordinate}
  return (
    <div   >
      <div
        className="Flex"
        style={{ flexDirection: 'row', justifyContent: 'space-around' }}
     
      >
        <Button onClick={uploadDoc} variant="contained" type="success">
          Upload
        </Button>
        <Button variant="contained" onClick={render}>
          Render
        </Button>
        <Button variant="contained" onClick={reset}>
          Reset
        </Button>
        <Button variant="contained" onClick={convert}>
          Convert
        </Button>
        <Button variant="contained" onClick={undoConvert}>
          Undo Convert
        </Button>
        <Button variant="contained" onClick={() => setRenderMode(false)}>
          Edit Mode
        </Button>
        <div>
          <TextField label="Id" id="layout" />
        <Button variant="contained" onClick={fetchLayout}>
         Get Layout
        </Button>
        </div>
        
      </div>
      <br />
      <div
        className="Flex"
        style={{ flexDirection: 'row', justifyContent: 'space-around' }}
      >
        <Button
          variant="Add Shell"
          onClick={() => setJson(JSON.stringify(Shell,null,2))}
        >
          Add Shell
        </Button>
      </div>
      {!renderMode ? (
        <div className="Flex">
          <TextareaAutosize
          tabIndex={"0"}
          onKeyPress={onKeypressEvent}
            maxRows={400}
            aria-label="maximum height"
            onChange={changeText}
            value={json}
            defaultValue={JSON.stringify(json)}
            style={{ width: '80%', padding: 30 }}
            spellCheck={false}
            // onBlur={render}
          />
        </div>
      ) : (
        <>
          <LayoutRenderer Data={modal} />
          <LayoutRenderer Data={layout} />
        </>
      )}
{/* <Empty /> */}
      {/* {JSON.stringify(mouse)} */}
    </div>
  )
}

export default LayoutUpload
