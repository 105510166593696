import React from "react";


function Head_1(props){
const {size,Value,styles}=props;
    return (
        <>
    <h4 style={{fontSize:size,...styles}}>{Value}</h4>
        </>
    )
}

export default Head_1;