import React, { useEffect, useState } from "react";
import Atomic from "../../../AComponent/Atomic";
import EwTable from "../../../MComponent/Table/Table";
import EwModal from "../../../MComponent/Modal/Modal";
import EditIcon from "@mui/icons-material/Edit";
const { Input, Button, Dropdown, Radio } = Atomic;

function Rating() {
  const [localState, setLocalState] = useState({
    scaleValues: [],
    RatingScaleModuleID: "19",
  });
  const [localRatingState, setLocalRatingState] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [addMode, setAddMode] = useState(false);
  const {
    RatingScaleName,
    RatingScaleDescription,
    RatingScaleType,
    scaleValues,
    minScale,
    maxScale,
    decimal,
  } = localState;
  function updateState(val, type) {
    setLocalState((prev) => ({ ...prev, [type]: val }));
  }
  function ModalContent() {
    const [localState, setLocalState] = useState(
      selectedIndex !== null ? scaleValues[selectedIndex] : {}
    );
    const { order, label, value, description } = localState;
    function updateModalState(val, type) {
      setLocalState((prev) => ({ ...prev, [type]: val }));
    }
    function onSave() {
      let arr = scaleValues;
      if (selectedIndex !== null) {
        arr[selectedIndex] = { ...localState, order: order ? true : false };
      } else {
        arr.push({ ...localState, order: order ? true : false });
      }

      updateState(arr, "scaleValues");
      setModal(false);
      setSelectedIndex(null);
    }
    return (
      <div>
        <div className="Flex">
          <h3>Add Values</h3>
        </div>
        <br />
        <div className="Flex-row">
          <div
            className="pointer"
            onClick={() => updateModalState(!order, "order")}
          >
            {order ? "Ascending Order ⬆️" : "Descending Order ⬇️"}
          </div>
          <div>
            <Input
              label={"Label"}
              value={label}
              onChange={(val) => updateModalState(val, "label")}
            />
          </div>
          <div>
            <Input
              label={"Value"}
              value={value}
              onChange={(val) => updateModalState(val, "value")}
            />
          </div>
        </div>
        <br />
        <div className="Flex">
          <div>
            <Input
              rows={5}
              label={"Description"}
              value={description}
              onChange={(val) => updateModalState(val, "description")}
            />
          </div>
        </div>
        <br />
        <div className="Flex">
          <Button title={"Save"} onClick={onSave} />
        </div>
      </div>
    );
  }
  const heading = ["Label", "Value", "Edit"];

  function Edit({ index }) {
    return (
      <div
        className="pointer"
        onClick={() => {
          setSelectedIndex(index);
          setModal(true);
        }}
      >
        <EditIcon />
      </div>
    );
  }

  const RatingsTable = () => {
    const heading = ["Name", "Description", "Type", "Widget"];

    const addRatingHandler = () => {
      setAddMode(!addMode);
    };

    console.log(
      ...localRatingState.map((ele, index) => [
        ...Object.entries(ele)
          .map(([key, value]) => value)
          .splice(0, 2),
        {
          Component: Edit,
          props: {
            index,
          },
        },
      ])
    );

    return (
      <div>
        <EwTable
          heading={heading}
          rows={[
            ...localRatingState.map((ele, index) => [
              ...Object.entries(ele).map(([key, value]) => value),
            ]),
          ]}
        />
        <Button title={"Add"} onClick={addRatingHandler} />
      </div>
    );
  };

  return !addMode ? (
    <RatingsTable />
  ) : (
    <div>
      <EwModal open={modal} onClose={() => setModal(false)}>
        <ModalContent />
      </EwModal>
      <div className="Flex-row">
        <div>
          <Input
            label="Rating Scale Name"
            value={RatingScaleName}
            onChange={(val) => updateState(val, "RatingScaleName")}
          />
        </div>
        <div>
          <Input
            label="Rating Scale Description"
            value={RatingScaleDescription}
            onChange={(val) => updateState(val, "RatingScaleDescription")}
          />
        </div>
      </div>
      <div className="Flex">
        <div style={{ width: "100%" }}>
          <Radio
            menuItems={[
              { label: "Discrete", value: "D" },
              { label: "Continuous", value: "C" },
            ]}
            label="Rating Scale Type"
            value={RatingScaleType}
            onChange={(val) => updateState(val, "RatingScaleType")}
          />
        </div>
      </div>

      <div>
        {RatingScaleType === "D" && (
          <div>
            <div>
              {scaleValues?.length > 0 && (
                <EwTable
                  heading={heading}
                  rows={[
                    ...scaleValues.map((ele, index) => [
                      ...Object.entries(ele)
                        .map(([key, value]) => value)
                        .splice(0, 2),
                      {
                        Component: Edit,
                        props: {
                          index,
                        },
                      },
                    ]),
                  ]}
                />
              )}
              <br />
              <div className="Flex">
                <Button title={"Add Values"} onClick={() => setModal(true)} />
              </div>
            </div>
          </div>
        )}
      </div>
      {RatingScaleType === "C" && (
        <div className="Flex-row">
          <div>
            <Input
              label={"Min Scale"}
              value={minScale}
              onChange={(val) => updateState(val, "minScale")}
            />
          </div>
          <div>
            <Input
              label={"Max Scale"}
              value={maxScale}
              onChange={(val) => updateState(val, "maxScale")}
            />
          </div>
          <div>
            <Input
              type="number"
              label={"Value Decimals"}
              value={decimal}
              onChange={(val) => updateState(val, "decimal")}
            />
          </div>
        </div>
      )}
      <br />
      <div className="Flex">
        <Button
          title={"Save"}
          onClick={() => {
            setLocalRatingState((prev) => [
              ...prev,
              {
                name: RatingScaleName,
                description: RatingScaleDescription,
                type: RatingScaleType,
                widget: "Default",
              },
            ]);
            setLocalState({ scaleValues: [] });
            setAddMode(false);
          }}
        />
      </div>
    </div>
  );
}

export default Rating;
