import * as React from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import ContentCut from "@mui/icons-material/ContentCut";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ContentPaste from "@mui/icons-material/ContentPaste";
import Cloud from "@mui/icons-material/Cloud";

// options=[
//   {
//     icon:"",
//     name:"",
//     onClick:""
//   }
// ]

export default function Menu(props) {
  const { options } = props;
  console.log("from Menu: ", props);
  return (
    <div id="right-menu">
      <Paper sx={{ width: 320, maxWidth: "100%" }}>
        <MenuList>
          {options?.map((ele) => {
            if (ele.divider) {
              return <Divider />;
            }
            const { icon, name, onClick, typography } = ele;
            if (!icon || !name || !onClick) return <></>;
            // const Icon=require("@mui/icons-material/"+ele.icon).default;
            const Icon = () => <></>;
            return (
              <MenuItem onClick={onClick}>
                <Icon fontSize="small" />
                <ListItemText>{name}</ListItemText>
                <Typography variant="body2" color="text.secondary">
                  {typography}
                </Typography>
              </MenuItem>
            );
          })}
          {/* <MenuItem>
          <ListItemIcon>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cut</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘X
          </Typography>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘C
          </Typography>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <ContentPaste fontSize="small" />
          </ListItemIcon>
          <ListItemText>Paste</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘V
          </Typography>
        </MenuItem> */}

          {/* <MenuItem>
          <ListItemIcon>
            <Cloud fontSize="small" />
          </ListItemIcon>
          <ListItemText>Web Clipboard</ListItemText>
        </MenuItem> */}
        </MenuList>
      </Paper>
    </div>
  );
}
