export const constants = {
  QueryName: "FindParameterAndTargetsByEmployeePerformancePlanIDAndDimensionID",
  ParameterAssessmentsQueryName:
    "FindParameterAssessmentsByEmployeePerformancePlanIDAndDimensionID",
  ParameterMapQueryName: "FindParameterMapByTransactionID",
  FindUserAndItsOrganisationDetails: "FindUserAndItsOrganisationDetails",
  FindAllEPPWithPersonName: "FindAllEPPWithPersonName2",
  FindAllEPPByReviewerID: "FindAllEPPByReviewerID",
  FindAllEPPByAppraiserID: "FindAllEPPByAppraiserID",
  FindAssessmentsOfEPPByAppraiserIDAndDimensionID:
    "FindAssessmentsOfEPPByAppraiserIDAndDimensionID",
  FindAllEPPByAppraiserOrReviewer: "FindAllEPPByAppraiserOrReviewer2",
  FindAllEPPByAppraiseeUserID: "FindAllEPPByAppraiseeUserID",
  PlanProgressTracker: "Plan Progress Tracker",
  PlanSummaryReport: "Plan Summary",
  AppraiseesPlanSummaryReport: "Appraisees Plan Summary",
  FindAllLineAndFunctionalEPPByAppraiserOrReviewer:
    "FindAllLineAndFunctionalEPPByAppraiserOrReviewer",
  FindAllLineAndFunctionalEPPByAppraiserOrReviewerV2:
    "FindAllLineAndFunctionalEPPByAppraiserOrReviewerV2",
};
