import React from 'react'
import Paper from '@mui/material/Paper'
import '../M.css'

function Date_1(props) {
  const { Type, Day, Month }=props;
  return (
    <>
      <Paper
      elevation={0}
        className="flex"
        style={{
          width: 60,
          height: 80,
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h1 style={{ color: Type === 'Due' ? 'red' : 'black' }}>{Day}</h1>
        <span>{Month}</span>
      </Paper>
    </>
  )
}

export default Date_1
