import {
  ArrowDropDown,
  ArrowRight,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import Atomic from "../../../../AComponent/Atomic";

const { Input } = Atomic;

function StepAccordian({ Name, Description, children, onNameChange }) {
  const [open, setOpen] = useState();

  const [name, setName] = useState(Name);

  return (
    <Paper sx={{ boxSizing: "border-box" }}>
      <Box display={"flex"} alignItems={"center"}>
        <IconButton
          onClick={() => {
            setOpen((prevState) => !prevState);
          }}
        >
          {open && <KeyboardArrowDown />}
          {!open && <KeyboardArrowRight />}
        </IconButton>
        {(!onNameChange || !open) && (
          <Box sx={{ display: "flex", gap: "0.7em" }} width={"100%"}>
            <Typography variant="body1" fontWeight={"600"}>
              {Name}
            </Typography>
            <Typography variant="caption" color={"grey"}>
              {Description}
            </Typography>
          </Box>
        )}
        {open && onNameChange && (
          <Input
            size={"small"}
            label={"Name"}
            onChange={(val) => {
              setName(val);
            }}
            onBlur={() => onNameChange(name)}
            value={name}
          />
        )}
      </Box>
      {open && (
        <Box display={"flex"} alignItems={"center"}>
          <Box sx={{ opacity: "0" }}>
            {!open && (
              <IconButton disabled={true}>
                <KeyboardArrowRight />
              </IconButton>
            )}
            {open && (
              <IconButton disabled={true}>
                <KeyboardArrowDown />
              </IconButton>
            )}
          </Box>
          <Box>{children}</Box>
        </Box>
      )}
    </Paper>
  );
}

export default StepAccordian;
