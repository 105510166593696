import { Chip, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import Atomic from "../../../../AComponent/Atomic";
import { Add } from "@mui/icons-material";
import Editor3 from "../../../../Workspace/Editor3";
import EwModal from "../../../../MComponent/Modal/Modal";
import StepOwnerForm from "./StepOwnerForm";

const { Dropdown, Button } = Atomic;

const SampleSubjectLineMap = [
  "Subject line 1",
  "Subject line 2",
  "Subject line 3",
  "Subject line 4",
  "Subject line 5",
];

const userTypeMap = {
  SU: "Subject User",
  TU: "Transaction User",
  LM: "Line Manager",
  FM: "Functional Manager",
  EH: "Business Unit Head",
};

function ActorPicker({ RecipientType, initActors, onChange }) {
  const [actors, setActors] = useState([...initActors]);
  const [modal, setModal] = useState(null);

  return (
    <Paper
      sx={{
        boxSizing: "border-box",
        display: "flex",
        width: "100%",
        alignItems: "center",
        padding: "0.5em",
        borderRadius: "4px",
        gap: "0.5em",
      }}
    >
      {actors.length === 0 ? (
        <></>
      ) : (
        actors.map((actor) => {
          const { RecipientUserType, RecipientUserLevel } = actor;
          return (
            <Chip
              key={actor.RecipientUserType + RecipientUserLevel}
              label={userTypeMap[RecipientUserType]}
              variant="outlined"
              color="primary"
              onDelete={() => {
                setActors((prevState) => {
                  const index = prevState.find(
                    (p) => p.RecipientUserType === actor.RecipientUserType
                  );
                  if (index !== -1) {
                    prevState.splice(index, 1);
                    onChange([...prevState]);
                    return [...prevState];
                  }
                  return prevState;
                });
              }}
            />
          );
        })
      )}
      <Button
        label={"Select Role"}
        onClick={() => {
          setModal({
            Component: StepOwnerForm,
            props: {
              closeForm: () => {
                setModal(null);
              },
              onAdd: (user) => {
                setActors((prevState) => {
                  onChange([
                    ...prevState,
                    {
                      RecipientType,
                      RecipientUserType: user.UserType,
                      RecipientUserLevel: user.UserLevel,
                    },
                  ]);
                  return [
                    ...prevState,
                    {
                      RecipientType,
                      RecipientUserType: user.UserType,
                      RecipientUserLevel: user.UserLevel,
                    },
                  ];
                });
              },
            },
          });
        }}
        otherProps={{
          endIcon: <Add />,
          size: "small",
        }}
        variant={"outlined"}
        title={"Select Role"}
      />
      {modal && (
        <EwModal onClose={() => setModal(null)} open={modal}>
          <modal.Component {...modal.props} />
        </EwModal>
      )}
    </Paper>
  );
}

export function Email({ Notification, onChange }) {
  const [state, setState] = useState({
    NotificationChannel: Notification.NotificationChannel || "E",
    Recipients: Notification.Recipients || [
      { RecipientType: "T", RecipientUserType: "Next Step Owner" },
    ],
    Subject: Notification.Subject || "",
    Message: Notification.Message || "",
    DataTags: Notification.DataTags || "",
  });

  const To = state.Recipients.filter((r) => r.RecipientType === "T");
  const Cc = state.Recipients.filter((r) => r.RecipientType === "C");
  const Bcc = state.Recipients.filter((r) => r.RecipientType === "B");

  function updateState(value, type, blurred) {
    setState((prevState) => {
      !blurred && onChange({ ...prevState, [type]: value });
      return { ...prevState, [type]: value };
    });
  }

  return (
    <Grid container rowGap={1} alignItems={"center"}>
      <Grid item md="1">
        <Typography variant="subtitle1">To</Typography>
      </Grid>
      <Grid item md="11">
        {false ? (
          <ActorPicker
            RecipientType={"T"}
            initActors={To}
            onChange={(tos) => {
              updateState([...tos, ...Cc, ...Bcc], "Recipients");
            }}
          />
        ) : (
          <Typography>{`<Next Step Owner>`}</Typography>
        )}
      </Grid>
      <Grid item md="1">
        <Typography variant="subtitle1">Cc</Typography>
      </Grid>
      <Grid item md="11">
        <ActorPicker
          RecipientType={"C"}
          initActors={Cc}
          onChange={(ccs) => {
            updateState([...To, ...ccs, ...Bcc], "Recipients");
          }}
        />
      </Grid>
      <Grid item md="1">
        <Typography variant="subtitle1">Bcc</Typography>
      </Grid>
      <Grid item md="11">
        <ActorPicker
          RecipientType={"B"}
          initActors={Bcc}
          onChange={(bccs) => {
            updateState([...To, ...Cc, ...bccs], "Recipients");
          }}
        />
      </Grid>
      <Grid item md="1">
        <Typography variant="subtitle1">Subject</Typography>
      </Grid>
      <Grid item md="11">
        <Paper
          sx={{
            width: "100%",
            borderRadius: "4px",
            padding: "0.5em",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle2">{`[${
            state.Subject && state.Subject !== -1
              ? state.Subject
              : "Select the subject line"
          }]`}</Typography>
          <Dropdown
            label={"Select Fields"}
            menuItems={[...SampleSubjectLineMap]}
            onChange={(val) => {
              updateState(val, "Subject");
            }}
            value={state.Subject}
            size={"small"}
          />
        </Paper>
      </Grid>
      <Grid item md="12">
        <Editor3
          onChange={(val) => {
            updateState(val, "Message", true);
          }}
          onBlur={() => {
            onChange({ ...state });
          }}
          value={state.Message}
          height="300"
        />
      </Grid>
    </Grid>
  );
}

export function SMS({ Notification, onChange }) {
  const [state, setState] = useState({
    NotificationChannel: Notification.NotificationChannel || "E",
    Recipients: Notification.Recipients || [
      { RecipientType: "T", RecipientUserType: "Next Step Owner" },
    ],
    Subject: Notification.Subject || "",
    Message: Notification.Message || "",
    DataTags: Notification.DataTags || "",
  });

  const To = state.Recipients.filter((r) => r.RecipientType === "T");

  function updateState(value, type) {
    setState((prevState) => {
      onChange({ ...prevState, [type]: value });
      return { ...prevState, [type]: value };
    });
  }

  return (
    <Grid container rowGap={1} alignItems={"center"}>
      <Grid item md="1">
        <Typography variant="subtitle1">To</Typography>
      </Grid>
      <Grid item md="11">
        {false ? (
          <ActorPicker
            RecipientType={"T"}
            initActors={To}
            onChange={(tos) => {
              updateState([...tos], "Recipients");
            }}
          />
        ) : (
          <Typography>{`<Next Step Owner>`}</Typography>
        )}
      </Grid>
      <Grid item md="12">
        <Editor3
          onChange={(val) => {
            updateState(val, "Message", true);
          }}
          onBlur={() => {
            onChange({ ...state });
          }}
          value={state.Message}
          height="300"
        />
      </Grid>
    </Grid>
  );
}

export function WhatsApp({ Notification, onChange }) {
  const [state, setState] = useState({
    NotificationChannel: Notification.NotificationChannel || "E",
    Recipients: Notification.Recipients || [
      { RecipientType: "T", RecipientUserType: "Next Step Owner" },
    ],
    Subject: Notification.Subject || "",
    Message: Notification.Message || "",
    DataTags: Notification.DataTags || "",
  });

  const To = state.Recipients.filter((r) => r.RecipientType === "T");

  function updateState(value, type) {
    setState((prevState) => {
      onChange({ ...prevState, [type]: value });
      return { ...prevState, [type]: value };
    });
  }

  return (
    <Grid container rowGap={1} alignItems={"center"}>
      <Grid item md="1">
        <Typography variant="subtitle1">To</Typography>
      </Grid>
      <Grid item md="11">
        {false ? (
          <ActorPicker
            RecipientType={"T"}
            initActors={To}
            onChange={(tos) => {
              updateState([...tos], "Recipients");
            }}
          />
        ) : (
          <Typography>{`<Next Step Owner>`}</Typography>
        )}
      </Grid>
      <Grid item md="12">
        <Editor3
          onChange={(val) => {
            updateState(val, "Message", true);
          }}
          onBlur={() => {
            onChange({ ...state });
          }}
          value={state.Message}
          height="300"
        />
      </Grid>
    </Grid>
  );
}

export function N2A({ Notification, onChange, urgency }) {
  const [state, setState] = useState({
    NotificationChannel: Notification.NotificationChannel || "E",
    Recipients: Notification.Recipients || [
      { RecipientType: "T", RecipientUserType: "Next Step Owner" },
    ],
    Subject: Notification.Subject || "",
    Message: Notification.Message || "",
    DataTags: Notification.DataTags || "",
  });

  const To = state.Recipients.filter((r) => r.RecipientType === "T");

  function updateState(value, type) {
    setState((prevState) => {
      onChange({ ...prevState, [type]: value });
      return { ...prevState, [type]: value };
    });
  }

  return (
    <Grid container rowGap={1} alignItems={"center"}>
      <Grid item md="1">
        <Typography variant="subtitle1">To</Typography>
      </Grid>
      <Grid item md="11">
        {false ? (
          <ActorPicker
            RecipientType={"T"}
            initActors={To}
            onChange={(tos) => {
              updateState([...tos], "Recipients");
            }}
          />
        ) : (
          <Typography>{`<Next Step Owner>`}</Typography>
        )}
      </Grid>
      {urgency && (
        <>
          <Grid item md="1">
            <Typography variant="subtitle1">Urgency</Typography>
          </Grid>
          <Grid item md="3">
            <Dropdown
              menuItems={[
                { label: "Not Urgent", value: "Not Urgent" },
                { label: "Somewhat Urgent", value: "Somewhat Urgent" },
                { label: "Urgent", value: "Urgent" },
              ]}
              onChange={() => {}}
              value={""}
              size={"small"}
              label={"Select Urgency"}
            />
          </Grid>
        </>
      )}
      <Grid item md="12">
        <Editor3
          onChange={(val) => {
            updateState(val, "Message", true);
          }}
          onBlur={() => {
            onChange({ ...state });
          }}
          value={state.Message}
          height="300"
        />
      </Grid>
    </Grid>
  );
}
