import React from 'react';
import Notif from '../../Assets/notif.png';
import Snooze from '../../Assets/snooze.png';
import Task from '../../Assets/task.png';
import Send from '../../Assets/send.png';

const IconMap = {
    Notif : Notif,
    Snooze : Snooze,
    Task : Task,
    Send : Send,
};


function AImg1({imgUrl}){

    const icon = IconMap[imgUrl]
    return(
        <div>
            <img src={icon} alt="notification" height="20px" width="20px"/>
        </div>
    );
}

export default AImg1;