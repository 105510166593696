import React, { useState } from "react";
import Atomic from "../../../AComponent/Atomic";
const { EwSwitch } = Atomic;


function SectionEnable({ children, label }) {
  const [enable, setEnabled] = useState(false);
  return (
    <div>
      <div className="Flex">
        <EwSwitch
          value={enable}
          onChange={(val) => setEnabled(val)}
          label={label}
        />
      </div>
      {enable && children}
    </div>
  );
}

export default SectionEnable;
