export default {
  PS: "Plan Summary",
  AR: "Appraisal Ratings",
  MR: "MapsReport",
  PT: "Plan Progress Tracker",
  APT: "Appraisees Plan Progress Tracker",
  AAR: "Appraisees Appraisal Ratings",
  APS: "Appraisees Plan Summary",
};
export const periodMapping = {
  A: "Annual",
  H1: "First Half",
  H2: "Second Half",
  Q1: "First Quarter",
  Q2: "Second Quarter",
  Q3: "Third Quarter",
  Q4: "Fourth Quarter",
};
export const messages = {
  wait: {
    type: 3,
    message:
      "The report is in the process of being generated and may take a while. Kindly check downloads tab after some time.",
  },
  networkError: {
    type: 1,
    message: "Network Error",
  },
  success: {
    type: 0,
    message: "Report Generated",
  },
};
