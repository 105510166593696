import React, { useState, useEffect } from "react";
import { Paper, Collapse, Divider, Grid } from "@mui/material";
import Atomic from "../AComponent/Atomic";
import * as Validators from "../Utils/Validators/library";
import LayoutRenderer from "../Interpreter/LayoutRenderer";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EWModal from "../MComponent/Modal/Modal";
const { Dropdown, Input, Button, Checkboxes } = Atomic;

const defaultData = {
  type: "layout",
  layout: [
    {
      Flex: {
        rowFlex: "center",
      },
      components: [
        {
          componentName: "C.Cards.Shell_2",
          componentNumber: "0001",
          props: {
            layout: [{}],
          },
        },
      ],
    },
  ],
};
const payload = {
  Header: {
    EffectiveFromTimestamp: "",
    FeatureID: "",
    FeatureVariantID: "000",
    UserId: "",
    TransactionId: "",
    StepId: "",
    ModuleId: "",
    Status: "1",
  },
  payloadSchema: {},
};
const typeToLocationMap = {
  Input: "A.Input.Input_2",
  Button: "A.Buttons.Button_2",
  Checkbox: "A.CheckBox.CheckBox_1",
  Radio: "A.Radio.Radio_2",
  Text: "A.Text.Text_2",
  Date: "A.Picker.Date_2",
  Dropdown: "A.Dropdown.Dropdown_2",
  LineItem: "M.LineItem.LineItem",
};

const commonMap = {
  Group: {
    type: "String",
  },
  fieldname: {
    type: "String",
  },
  renderNot: {
    type: "Boolean",
  },
};

var compPropsMap = {
  Dropdown: {
    menuItems: {
      type: "Array",
    },
    label: {
      type: "String",
    },
    onChange: {
      type: "Method",
    },
  },
  Radio: {
    options: {
      type: "Array",
    },
    label: {
      type: "String",
    },
  },
  Input: {
    fieldname: {
      type: "String",
    },
    Placeholder: {
      type: "String",
    },
    label: {
      type: "String",
      required: true,
    },
    variant: {
      type: "List",
      list: ["Outlined", "Filled", "Standard"],
    },
    Group: {
      type: "String",
    },
  },
  Button: {
    fieldname: {
      type: "String",
    },
    Type: {
      type: "List",
      list: ["contained", "outlined", "text"],
    },
    Title: {
      type: "String",
    },
    onClick: {
      type: "Method",
    },
    Group: {
      type: "String",
    },
    Color: {
      type: "List",
      list: ["primary", "secondary", "success", "error", "info", "warning"],
    },
    disable: {
      type: "Boolean",
    },
  },
  Checkbox: {
    fieldname: {
      type: "String",
    },
    label: {
      type: "String",
    },
    onChange: {
      type: "Method",
    },
    ifTrue: {
      type: "Method",
    },
    ifFalse: {
      type: "Method",
    },
  },
  Text: {
    fieldname: {
      type: "String",
    },
    value: {
      type: "String",
    },
    heading: {
      type: "Boolean",
    },
  },
  Date: {
    fieldname: {
      type: "String",
    },
    label: {
      type: "String",
    },
  },
  LineItem: {
    fieldname: {
      type: "String",
    },
    heading: {
      type: "String",
    },
    columns: {
      type: "Array",
    },
    groupsToBeEnabled: {
      type: "Array",
    },
    emptyMsg: {
      type: "String",
    },
  },
};
let temp = {};
Object.keys(compPropsMap).forEach((ele) => {
  temp[ele] = { ...compPropsMap[ele], ...commonMap };
});
compPropsMap = temp;

function CodeArea({ onChange,value }) {
  function editChange(e) {
    onChange(e.currentTarget.textContent);
  }
  return (
    <div
      onInput={editChange}
      contentEditable
      style={{
        width: 200,
        height: 150,
        backgroundColor: "#FFF2F2",
        padding: 10,
        margin: 30,
      }}
    ></div>
  );
}

function FormBuilder() {
  const [layoutJson, setLayoutJson] = useState(null);
  const [layoutCopy, setLayoutCopy] = useState(null);
  const [copy,setCopy]=useState({})

  function Pannel({ type }) {
    const [layouts, setLatyouts] = useState(
      layoutCopy ? layoutCopy : [{ layoutKey: 1 }]
    );
    const [groups, setGroups] = useState(copy.groups?copy.groups:false);
    const [enabledGroups, setEnabledGroups] = useState(copy.enabledGroups?copy.enabledGroups:"");
    const [parentFieldDependency, setparentFieldDependency] = useState(copy.parentFieldDependency?copy.parentFieldDependency:[{}]);
    const [openFieldModal, setFieldModal] = useState(false);

    function FieldDep() {
      function RenderFields({ index }) {
        const [collapse, setCollapse] = useState(false);
        const [fieldDependency, setFieldDependency] = useState(
          parentFieldDependency ? parentFieldDependency : [{}]
        );
        function onvalueChange(type, value) {
          let arr = fieldDependency;
          if (type === "method" || type === "params") {
            if (!arr[index].condition) {
              arr[index].condition = {
                method: "",
                params: "",
                [type]: value,
              };
            } else {
              if (type === "method") arr[index].condition[type] = value;
              else arr[index].condition[type] = value.split(",");
            }
            setFieldDependency([...arr]);
            return;
          }

          if (type === "ifTrue" || type === "ifFalse") {
            if (value === "true") value = true;
            else if (value === "false") value = false;
          }

          arr[index][type] = value;
          setFieldDependency([...arr]);
        }
        function returnValue(type) {
          if (type === "method" || type === "params") {
            if (
              fieldDependency[index]?.condition &&
              fieldDependency[index]?.condition[type]
            )
              return type === "method"
                ? fieldDependency[index]?.condition[type]
                : fieldDependency[index]?.condition[type]?.join(",");
            return "";
          }
          if (type === "ifTrue" || type === "ifFalse") {
            if (typeof fieldDependency[index][type] === "boolean")
              return "" + fieldDependency[index][type];
          }
          if (fieldDependency[index][type]) return fieldDependency[index][type];
          return "";
        }
        function saveField() {
          const { component, onProps, condition:{method,params} } = fieldDependency[index];
          console.log("when saving : ",fieldDependency[index])
          if (!component || !onProps || !method) return;
          setparentFieldDependency(fieldDependency);
        }
        function deleteDep() {
          let arr = parentFieldDependency;
          arr.splice(index, 1);
          setparentFieldDependency([...arr]);
        }
        return (
          <>
          <div className="Flex-row" style={{justifyContent:'space-around'}}>
            <h4>Field Dep {` (${index + 1})`}</h4>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setCollapse(!collapse)}
            >
              {!collapse ? "Open" : "Close"}
            </span>
            <span style={{ cursor: "pointer" }} onClick={deleteDep}>
              <DeleteIcon color="error" />
            </span>
            </div>
            <Collapse in={collapse}>
              <div>
                <div className="Flex-row">
                  <div>
                    <Input
                      label={"Component"}
                      onChange={(val) => onvalueChange("component", val)}
                      value={returnValue("component")}
                    />
                  </div>
                  <div>
                    <Input
                      label={"onProps"}
                      onChange={(val) => onvalueChange("onProps", val)}
                      value={returnValue("onProps")}
                    />
                  </div>
                </div>
                <div className="Flex-row">
                  <div>
                    <Input
                      label={"ifTrue"}
                      value={returnValue("ifTrue")}
                      onChange={(val) => onvalueChange("ifTrue", val)}
                    />
                  </div>
                  <div>
                    <Input
                      label={"ifFalse"}
                      value={returnValue("ifFalse")}
                      onChange={(val) => onvalueChange("ifFalse", val)}
                    />
                  </div>
                </div>
                <div className="Flex-row">
                  <div>
                    <>
                      <div>
                        <Dropdown
                          menuItems={Object.keys(Validators)}
                          label={"method"}
                          value={returnValue("method")}
                          onChange={(val) => onvalueChange("method", val)}
                        />
                      </div>
                      <div>
                        {fieldDependency[index].condition?.method && (
                          <CodeArea
                            value={returnValue("params")}
                            onChange={(val) => onvalueChange("params", val)}
                          />
                        )}
                      </div>
                      <br />
                    </>
                  </div>
                </div>
                <div className="Flex">
                  <Button title={"Save"} onClick={saveField} />
                </div>
              </div>
            </Collapse>
            <br />
          </>
        );
      }
      return (
        <div
          className="scroll"
          style={{ maxHeight: 500, overflowY: "auto", overflowX: "hidden" }}
        >
          <div className="Flex">
            {parentFieldDependency.map((ele, index) => (
              <RenderFields index={index} />
            ))}
          </div>
          <br />
          <div className="Flex">
            <Button
              title={"Add"}
              onClick={() => setparentFieldDependency((prev) => [...prev, {}])}
            />
          </div>
        </div>
      );
    }
    function Layout({ index, comp, flexprop, layoutKey }) {
      console.log("Layout11: ", index, " ", comp, " ", flexprop);
      const [components, setComponents] = useState(comp ? comp : []);
      const [layoutOpen, setLayoutOpen] = useState(false);
      const [fields, setFields] = useState(-1);

      const [flex, setFlex] = useState(flexprop ? flexprop : {});
      function Component({ type, props, index }) {
        const [compState, setCompState] = useState(props ? props : {});

        const [open, SetOpen] = useState(false);
        let obj = compPropsMap[type];
        let heading = "";
        if (props?.fieldname) heading = `(${props.fieldname})`;
        function areaChange(val, key) {
          let obj = compState;
          obj[key].params = val.split(",");
          setCompState({ ...obj });
        }
        function renderKeyValue(key, value) {
          let Comp = <></>;
          if (value.type === "String") {
            Comp = (
              <Input
                label={key}
                value={compState[key]}
                onChange={(val) => {
                  setCompState((prev) => ({ ...prev, [key]: val }));
                }}
              />
            );
          }
          if (value.type === "Method") {
            Comp = (
              <>
                <div>
                  <Dropdown
                    menuItems={Object.keys(Validators)}
                    label={key}
                    value={compState[key]?.method}
                    onChange={(val) => {
                      if (val === -1) {
                        setCompState((prev) => ({
                          ...prev,
                          [key]: null,
                        }));
                      }
                      setCompState((prev) => ({
                        ...prev,
                        [key]: {
                          method: val,
                          params: [],
                        },
                      }));
                    }}
                  />
                </div>
                <div>
                  {compState[key] && (
                    <CodeArea onChange={(val) => areaChange(val, key)} />
                  )}
                </div>
                <br />
              </>
            );
          }
          if (value.type === "List") {
            Comp = (
              <Dropdown
                label={key}
                menuItems={value.list}
                value={compState[key]}
                onChange={(val) => {
                  setCompState((prev) => ({ ...prev, [key]: val }));
                }}
              />
            );
          }
          if (value.type === "Boolean") {
            Comp = (
              <Checkboxes
                label={key}
                value={compState[key]}
                onChange={(val) => {
                  setCompState((prev) => ({ ...prev, [key]: val }));
                }}
              />
            );
          }
          if (value.type === "Array") {
            Comp = (
              <Input
                label={key}
                value={compState[key] ? compState[key].join(",") : ""}
                onChange={(val) => {
                  console.log(val, " ", val?.split(","));
                  setCompState((prev) => ({ ...prev, [key]: val.split(",") }));
                }}
              />
            );
          }
          return (
            <>
              <div
                className="Flex-row"
                style={{ justifyContent: "space-around" }}
              >
                <div>{Comp}</div>
              </div>
            </>
          );
        }
        function onSave() {
          SetOpen(false);
          let arr = components;
          console.log("On save: ", arr);
          arr[index].props = compState;
          setComponents([...arr]);
        }
        function deleteComp() {
          let arr = components;
          arr.splice(index, 1);
          console.log("Aerr: ", arr);
          setComponents([...arr]);
        }
        function move(type) {
          let arr = components;

          if (type === 0) {
            if (index === components.length) return;
            let temp = arr[index + 1];
            arr[index + 1] = arr[index];
            arr[index] = temp;
          } else {
            if (index === 0) return;
            let temp = arr[index - 1];
            arr[index - 1] = arr[index];
            arr[index] = temp;
          }
          setComponents([...arr]);
        }
        return (
          <>
            <div className="Flex">
              <Grid
                justifyContent="center"
                alignItems="center"
                container
                spacing={2}
                style={{ width: "100%" }}
              >
                <Grid item>
                  <span>{type + heading + " : "}</span>
                </Grid>
                <Grid item>
                  <span
                    onClick={() => SetOpen(!open)}
                    style={{ cursor: "pointer", color: "lightblue" }}
                  >
                    {open ? "Close Props" : "Show Props"}
                  </span>
                </Grid>
                <Grid item>
                  <span style={{ cursor: "pointer" }} onClick={deleteComp}>
                    <DeleteIcon color="error" />
                  </span>
                </Grid>
                <Grid item>
                  <span style={{ cursor: "pointer" }} onClick={() => move(0)}>
                    ⬇️
                  </span>
                </Grid>
                <Grid item>
                  <span style={{ cursor: "pointer" }} onClick={() => move(1)}>
                    ⬆️
                  </span>
                </Grid>
              </Grid>
            </div>
            {/* <div className="Flex-row" style={{ gap: 20 }}>
             
            
             
             
            
            </div> */}
            <Collapse in={open}>
              <Grid container>
                {Object.entries(obj).map(([key, value]) => (
                  <Grid xs={6}>{renderKeyValue(key, value)}</Grid>
                ))}
              </Grid>

              {console.log("Field name: ", props)}

              <div className="Flex">
                <Checkboxes
                  label={"Include in payload"}
                  value={compState.schema}
                  onChange={(val) => {
                    console.log("Checked: ", val);
                    setCompState((prev) => ({ ...prev, schema: val }));
                  }}
                />
              </div>

              <div className="Flex">
                <Button title={"Save"} onClick={onSave} />
              </div>
            </Collapse>
          </>
        );
      }
      function Flex({ type, index }) {
        let flexValues = [
          "start",
          "end",
          "center",
          "top",
          "bottom",
          "arround",
          "between",
        ];
        let directions = ["row", "column"];
        const [value, setValue] = useState(flex[type] ? flex[type] : -1);
        function flexChange(val) {
          let obj = flex;
          obj[type] = val;
          setValue(val);
        }
        function deleteFlex() {
          let obj = flex;
          delete obj[type];
          setFlex({ ...obj });
        }
        return (
          <>
            <div
              className="Flex-row"
              style={{ gap: 20, justifyContent: "flex-start" }}
            >
              <div>{type}</div>
              <div>
                <Dropdown
                  label="Props"
                  menuItems={type === "Direction" ? directions : flexValues}
                  value={value}
                  onChange={flexChange}
                />
              </div>
              {/* <div>delete</div> */}
              <div onClick={deleteFlex} style={{ cursor: "pointer" }}>
                <DeleteIcon color="error" />
              </div>
            </div>
          </>
        );
      }
      function componentAdd(val) {
        setFields(val);
        setComponents((prev) => [...prev, { type: val, props: {} }]);
      }
      useEffect(() => {
        console.log("Layout is: ", layoutOpen);
      }, [layoutOpen]);
      function saveLayout() {
        console.log("clicked");
        setLayoutOpen(false);
        let arr = layouts;
        arr[index] = {
          ...arr[index],
          comp: components,
          flexprop: flex,
        };
        setLatyouts([...arr]);
      }
      function deleteSection() {
        let arr = layouts;
        arr.splice(index, 1);
        setLatyouts([...arr]);
      }
      function move(type) {
        let arr = layouts;

        if (type === 0) {
          if (index === layouts.length) return;
          let temp = arr[index + 1];
          arr[index + 1] = arr[index];
          arr[index] = temp;
        } else {
          if (index === 0) return;
          let temp = arr[index - 1];
          arr[index - 1] = arr[index];
          arr[index] = temp;
        }
        setLatyouts([...arr]);
      }

      return (
        <>
          <h2>
            Section {`(${layoutKey})`}
            <span
              style={{ cursor: "pointer", color: "lightblue" }}
              onClick={() => setLayoutOpen(!layoutOpen)}
            >
              {!layoutOpen ? "  Open" : "  Close"}
            </span>
            <span style={{ cursor: "pointer" }} onClick={deleteSection}>
              {" "}
              <DeleteIcon color="error" />
            </span>
            <span style={{ cursor: "pointer" }} onClick={() => move(0)}>
              ⬇️
            </span>
            <span style={{ cursor: "pointer" }} onClick={() => move(1)}>
              ⬆️
            </span>
          </h2>

          <Collapse in={layoutOpen}>
            <div>
              <div>
                {Object.keys(flex).map((ele, index) => (
                  <>
                    <Flex type={ele} index={index} />
                  </>
                ))}

                <Dropdown
                  menuItems={["Row", "Column", "Direction", "Others"]}
                  label="Flex"
                  onChange={(val) =>
                    setFlex((prev) => ({ ...prev, [val]: {} }))
                  }
                />
              </div>
            </div>
            <div>
              {components.map((ele, index) => (
                <Component key={index} {...ele} index={index} />
              ))}
              <Dropdown
                menuItems={Object.keys(compPropsMap)}
                label="Components"
                value={-1}
                onChange={componentAdd}
              />
            </div>
            <div className="Flex">
              <Button
                variant="outlined"
                color="success"
                title={"Save Section"}
                onClick={saveLayout}
              />
            </div>
            <br />
            <Divider variant="inset" />
            <br />
          </Collapse>
        </>
      );
    }
    function genrateJson() {
      let arr = [];
      const flexTyepMap = {
        Row: "rowFlex",
        Column: "colFlex",
        Direction: "direction",
      };
      let Schema = {};
      for (let i = 0; i < layouts.length; i++) {
        let component = [];
        let flex = {};
        let { comp, flexprop } = layouts[i];
        if (flexprop) {
          flex = Object.keys(flexprop)
            ?.map((ele) => ({
              [flexTyepMap[ele]]: flexprop[ele],
            }))
            .reduce((a, b) => ({ ...a, ...b }), {});
        }
        comp?.forEach(({ type, props }) => {
          let fieldname = props.fieldname;
          let schema = props.schema;

          if (schema) {
            Schema[fieldname] = fieldname;
          }

          let obj = {
            componentName: typeToLocationMap[type],
            props,
            componentNumber: props.fieldname,
          };

          component.push(obj);
        });
        arr.push({
          Flex: flex,
          components: component,
        });
      }

      let finalPayload = defaultData;
      let header = payload;
      header.payloadSchema = Schema;
      let tepProps = {
        layout: arr,
        payload: header,
      };
      if (groups) tepProps.enabledGroups = enabledGroups.split(",");
      if (Object.keys(parentFieldDependency[0]).length > 0)
        tepProps.fieldDependency = parentFieldDependency;
      finalPayload.layout[0].components[0].props = tepProps;
      setLayoutCopy(layouts);
      setLayoutJson(JSON.parse(JSON.stringify(finalPayload)));
      setCopy({
        groups,
        enabledGroups,
        parentFieldDependency
      })
      console.log("Final JSONl; ", finalPayload);
    }
    async function copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        console.log("Text copied to clipboard successfully!");
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    }
    return (
      <>
        <Paper
          className="scroll"
          style={{
            padding: 30,
            width: 500,
            height: 600,
            overflowY: "auto",
            overflowX: "hidden",
          }}
          elevation={2}
        >
          {console.log("Layout is : ", layouts)}
          {layouts.map((ele, index) => (
            <Layout {...ele} index={index} />
          ))}
          <div className="Flex" style={{ margin: 10 }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                setLatyouts((prev) => [...prev, { layoutKey: prev.length + 1 }])
              }
            >
              {" "}
              <AddCircleOutlineIcon fontSize="large" />
            </div>
          </div>
          <div className="Flex-row">
            <div>
              <Checkboxes
                label="Enable Goups"
                value={groups}
                onChange={(flag) => setGroups(flag)}
              />
            </div>
            {groups && (
              <div>
                <Input
                  label="Groups"
                  value={enabledGroups}
                  onChange={(val) => setEnabledGroups(val)}
                />
              </div>
            )}
          </div>
          <br />
          <div className="Flex">
            <Button
              title={"Field Dependecy"}
              color="warning"
              onClick={() => setFieldModal(true)}
            />
          </div>
          <EWModal open={openFieldModal} onClose={() => setFieldModal(false)}>
            <FieldDep />
          </EWModal>
          <br />
          <div className="Flex-row" style={{ gap: 30 }}>
            <div>
              <Button title={"Genrate"} onClick={genrateJson} />
            </div>
            <div>
              <Button
                title={"Re-set"}
                onClick={() => {
                  setLayoutJson(null);
                  setLayoutCopy(null);
                }}
              />
            </div>
            {layoutJson && (
              <div>
                <Button
                  title={"Copy"}
                  onClick={() => copyToClipboard(JSON.stringify(layoutJson))}
                />
              </div>
            )}
            {/* <div>
              <Button
                title={"Reset"}
                onClick={() => layoutCopy(null)}
              />
            </div> */}
          </div>
        </Paper>
      </>
    );
  }
  return (
    <div className="Flex-row" style={{ justifyContent: "space-around" }}>
      <div>
        <Pannel />
      </div>
      {layoutJson && (
        <div>
          <LayoutRenderer Data={layoutJson} />
        </div>
      )}
      {/* {JSON.stringify(layoutJson)} */}
    </div>
  );
}

export default FormBuilder;
