import React, { useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import SvgIcon from '@mui/material/SvgIcon'
import FormGroup from '@mui/material/FormGroup'

function Checkboxes({ label, customeClick, isChecked }) {
 
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked ? isChecked : false}
            onChange={() => {
             
              customeClick(label)
            }}
          />
        }
        label={label}
      />
    </>
  )
}
function Closed() {
  return (
    <>
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }}>
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      </SvgIcon>
    </>
  )
}
function Open() {
  return (
    <>
      <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }}>
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      </SvgIcon>
    </>
  )
}
function AllCheckbox({ customeClick, Groups }) {
  const [checked, setChecked] = useState(false)
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={() => {
              customeClick(Groups, !checked)
              setChecked(!checked)
            }}
          />
        }
        label="All"
      />
    </>
  )
}

function WrappedTree({ children, heading }) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <span
        onClick={() => {
          setOpen(!open)
        }}
      >
        {open ? <Open /> : <Closed />}
        {heading}
      </span>
      <div
        style={{ marginLeft: '5em', display: 'flex', flexDirection: 'column' }}
      >
        {open && children}
      </div>
    </>
  )
}

function Tree2() {
  const [status, setStatus] = useState({
    Filters: {},
    Groups: {},
  })
  function handleChecked(filter) {
    setStatus((prev) => ({
      ...prev,
      Filters: {
        ...prev.Filters,
        [filter]: prev.Filters[filter] ? !prev.Filters[filter] : true,
      },
    }))
  }
  function handleAll(Group, state) {
    Group.map((ele) => {
      setStatus((prev) => ({
        ...prev,
        Filters: {
          ...prev.Filters,
          [ele]: state,
        },
      }))
    })
  }
  return (
    <>
      <WrappedTree heading="SUBTree1">
        <AllCheckbox customeClick={handleAll} Groups={['Filter1', 'Filter2']} />
        <Checkboxes
          customeClick={handleChecked}
          isChecked={status.Filters['Filter1']}
          label="Filter1"
        />
        <Checkboxes
          customeClick={handleChecked}
          isChecked={status.Filters['Filter2']}
          label="Filter2"
        />
        <WrappedTree heading="subtree2">
          <AllCheckbox
            customeClick={handleAll}
            Groups={['Filter3', 'Filter4']}
          />
          <Checkboxes
            customeClick={handleChecked}
            isChecked={status.Filters['Filter3']}
            label="Filter3"
          />
          <Checkboxes
            customeClick={handleChecked}
            isChecked={status.Filters['Filter4']}
            label="Filter4"
          />
        </WrappedTree>
      </WrappedTree>

      <br />
      <span>Filters:{JSON.stringify(status.Filters)}</span>
    </>
  )
}

export default Tree2
