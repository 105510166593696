import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { useDispatch,useSelector } from 'react-redux'
import Gateway from '../../Utils/Api/Gateway'

/*
This Material UI button has 4 properties

Type:   ||String||   contained/outlined/Default(An Empty String/null passed to prop Type);
Color:  ||String||   primary/secondary/Default (An Empty String/null passed to prop Color, Grey color);
Title:  ||String||   Required;
Handler:||Function|| Required;

*/

const FILE_NAME = 'Button_1.js'




function Button_1(props) {
console.log(FILE_NAME," ",)

  var { Type, Color, Title, Handler,conditions,propsDisable,dataReduxId,parentField }=props;


  const disable=useSelector((state)=>{
    if(state.DataReducer[dataReduxId] && state.DataReducer[dataReduxId][parentField]){
      return state.DataReducer[dataReduxId][parentField].disable;
    }
  })
  const renderNot=useSelector((state)=>{
    return false;
    if(state.DataReducer[dataReduxId]){
      return state.DataReducer[dataReduxId][parentField].renderNot
    }
  })
  const dispatch = useDispatch()

  var btnHandle=Handler;
  if (!Title) return <span style={{ color: 'red' }}>Data Missing</span>
  if (!Handler)
    btnHandle = () => {
      console.log('Data Missing')
    }
  if (!Type) Type = ''

  console.log(FILE_NAME + ' ' + Handler?.Type)
  if (Handler?.Type === 0) {
    btnHandle = () => {
      console.log('Callled Handler')
      Gateway({}, {}, Handler.param.endPt, 'GET').then((res) => {
        dispatch({
          type: 'SET',
          payload: res,
        })
      })
    }
  }

  if(renderNot)return<></>
  return (
    <>
  <Button
        onClick={() => btnHandle()}
        color={Color ? Color : ''}
        style={{ fontSize: 10 }}
        variant={Type}
        disabled={disable }
      >
       {Title}
      </Button> 
      

      
    </>
  )
}

export default Button_1
