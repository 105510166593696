import React, { useState } from "react";
import { Typography } from "@mui/material";
import Atomic from "../Atomic";
import Molecule from "../../MComponent/Molecule";
import useWindowDimensions from "../../Utils/CustomHooks/WindowRezise";
import useProps from "../../Utils/CustomHooks/GetProps";
const initialProps = {
  label: null, //required
  message: null, //required
  actions: null,
  Component: null,
  onClick: null, // required if actions not defined
  btnProps: null,
  ButtonComponent: null,
};

function ConfirmationButton(props = initialProps) {
  let {
    label,
    message,
    actions,
    Component,
    onClick,
    btnProps,
    ButtonComponent,
  } = useProps(props);
  if (!actions) {
    actions = [
      {
        title: "Cancel",
        onClick: () => {
          setOpen(false);
        },
      },
      {
        title: "Confirm",
        onClick,
        color: "success",
      },
    ];
  }
  //  else if (actions[0].title !== "Cancel") {
  //   actions.unshift({
  //     title: "Cancel",
  //     onClick: () => {
  //       setOpen(false);
  //     },
  //   });
  // }

  if (!btnProps) btnProps = {};
  const [open, setOpen] = useState(false);
  const { width } = useWindowDimensions();
  return (
    <div>
      <Molecule.EwModal open={open} onClose={() => setOpen(false)}>
        <div
          className="scroll"
          style={{
            width: 0.4 * width,
            minHeight: "20vh",
          }}
        >
          {Component ? (
            <Component />
          ) : (
            <div className="Flex">
              <Typography style={{ padding: 20 }}>{message}</Typography>
              <div
                className="Flex-row g20"
                style={{ justifyContent: "flex-end" }}
              >
                {actions?.map((ele) => (
                  <Atomic.Button
                    {...ele}
                    onClick={() => {
                      ele.onClick();
                      setOpen(false);
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </Molecule.EwModal>
      {ButtonComponent ? (
        <ButtonComponent openModal={setOpen} />
      ) : (
        <>
          <Atomic.Button
            title={label}
            variant={"text"}
            color={"error"}
            onClick={() => {
              setOpen(true);
            }}
            {...btnProps}
          />
        </>
      )}
    </div>
  );
}

export default ConfirmationButton;
