import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useProps from "../../Utils/CustomHooks/GetProps";
import { useTheme } from "@mui/material";
import EwModal from "../Modal/Modal";
import Atomic from "../../AComponent/Atomic";

const { Button } = Atomic;
/*
componentList[Array]:[
  {
    label:"Section title",
    Component: Component
    componentAsFunction : Function
  }
]
*/

function TabPanel(props) {
  const { children, value, index, ...other } = useProps(props);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const initalProps = {
  componentList: [
    {
      label: "",
      Component: null,
      props: {},
    },
  ],
};
export default function EwTabs(props = initalProps) {
  const { componentList, defaultOpenTab } = props;
  const [value, setValue] = React.useState(defaultOpenTab || 0);
  const [tabEdited, setTabEdited] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState({
    open: false,
    tabValue: null,
  });
  console.log("EWtabs: ", componentList);
  const handleChange = (event, newValue) => {
    if (tabEdited) {
      setModalOpen({ open: true, tabValue: newValue });
    } else {
      setValue(newValue);
    }
  };
  if (!componentList) return <></>;

  function handleTabEdit(mode) {
    if (mode === "save") {
      console.log("TAB SAVE OCCURED");
      setTabEdited(false);
    } else if (!tabEdited) {
      console.log("TAB EDITED OCCURED");
      setTabEdited(true);
    }
  }

  return (
    <Box>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {componentList.map((ele, index) => {
            console.log(value, `simple-tab-${index}`, "VALUE IS IT");
            return (
              <Tab
                disabled={ele.disable ? true : false}
                label={ele.label}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </Box>
      {componentList.map((ele, index) => {
        if (!ele.props) ele.props = {};
        return (
          <TabPanel value={value} index={index}>
            {ele.Component ? (
              <ele.Component {...ele.props} handleTabEdit={handleTabEdit} />
            ) : (
              ele.componentAsFunction()
            )}
          </TabPanel>
        );
      })}
      {tabEdited && (
        <EwModal open={modalOpen.open} onClose={() => {}}>
          <Box sx={{ width: "30vw" }}>
            <Typography variant="h2">Important !</Typography>
            <br />
            <Typography variant="body1">
              Have you saved your recent changes? Unsaved modifications may be
              lost if you proceed without saving. Please ensure all edits are
              saved before continuing.
            </Typography>
            <br />
            <Box
              sx={{ display: "flex", gap: "1em", justifyContent: "flex-end" }}
            >
              <Button
                label={"Cancel"}
                onClick={() => {
                  setModalOpen({ open: false, tabValue: null });
                }}
                variant={"text"}
              />
              <Button
                label={"Continue"}
                onClick={() => {
                  setValue(modalOpen.tabValue);
                  setTabEdited(false);
                  setModalOpen({ open: false, tabValue: null });
                }}
                variant={"contained"}
              />
            </Box>
          </Box>
        </EwModal>
      )}
    </Box>
  );
}

EwTabs.prototype = {
  componentList: PropTypes.array,
};
