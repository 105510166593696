import React from "react";
import Tooltip from "@mui/material/Tooltip";

const initialProps = {
  title: "",
  Icon: null,
  iconProps:{}
};
function EwToolTip(props = initialProps) {
  let { title, Icon,iconProps } = props;
  return (
    <span>
      <Tooltip title={title} placement="top">{Icon && <Icon {...iconProps} />}</Tooltip>
    </span>
  );
}

export default EwToolTip;
