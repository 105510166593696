import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  MarkerType,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import StepNode from "./Nodes/StepNode";
import StepEdge from "./Edges/StepEdge";
import { Box, useTheme } from "@mui/material";
import ActionNode from "./Nodes/ActionNode";
import useReduxStore from "../../Utils/CustomHooks/ReduxUpdater";
import { generateIntegerID, rgbaToHex } from "./helpers";
import { initWorkflowDocument } from "./WorkflowDocument";
import ScenarioNode from "./Nodes/ScenarioNode";
import Atomic from "../../AComponent/Atomic";

const { Button } = Atomic;

const FirstStepID = "000";
const LastStepID = "999";

const nodeTypes = {
  stepNode: StepNode,
  actionNode: ActionNode,
  scenarioNode: ScenarioNode,
};

const edgeTypes = { stepEdge: StepEdge };

function FlowChart() {
  const { palette } = useTheme();

  const [state, setState] = useReduxStore({
    id: "Workflow2",
    initialState: {
      data: {
        stepCounter: {
          id: "000",
        },
        steps: {},
        nodes: {
          [`${FirstStepID}_@_@`]: {
            id: `${FirstStepID}_@_@`,
            position: { x: 100, y: 300 },
            draggable: false,
            data: {
              StepID: FirstStepID,
              styles: { color: palette.success.main },
            },
            type: "stepNode",
          },
          [`${LastStepID}_@_@`]: {
            id: `${LastStepID}_@_@`,
            position: { x: 1100, y: 300 },
            draggable: false,
            data: { StepID: LastStepID, styles: { color: palette.error.main } },
            type: "stepNode",
          },
        },
        edges: {},
      },
    },
  });

  const updateState = useCallback(
    (typeValueMaps) => {
      const prevState = { ...state.data };
      typeValueMaps.forEach(({ value, type }) => {
        prevState[type] = { ...value };
      });
      setState(prevState, "data");
    },
    [setState, state]
  );

  useEffect(() => {
    let steps = state.data.steps;
    let nodes = state.data.nodes;
    let edges = state.data.edges;

    initWorkflowDocument.Steps.forEach((step) => {
      steps[step.StepID] = step;
      // if(step.StepID !== "000" && step.StepID !== "999") {

      // }
      // const Scenarios = step.StepScenarios;
    });

    updateState([
      { value: steps, type: "steps" },
      { value: nodes, type: "nodes" },
      { value: edges, type: "edges" },
    ]);
  }, []);

  const [nodes, setNodes, onNodesChange] = useNodesState([
    ...Object.values(state.data.nodes),
  ]);

  const [edges, setEdges, onEdgesChange] = useEdgesState([
    ...Object.values(state.data.edges),
  ]);

  const [connectionLineStyle, setConnectionLineStyle] = useState({});

  useEffect(() => {
    setNodes([...Object.values(state.data.nodes)]);
  }, [setNodes, state.data.nodes]);

  useEffect(() => {
    setEdges([...Object.values(state.data.edges)]);
  }, [setEdges, state.data.edges]);

  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);

  const { project, getNode } = useReactFlow();

  const updateNextStep = useCallback((steps, newStep, nodeId) => {
    const NewStepID = newStep.StepID;
    const [StepID, ScenarioID, ActionID] = nodeId.split("_");

    const step = steps[StepID];
    if (step) {
      const sindex = step.StepScenarios.findIndex(
        (s) => s.ScenarioID === ScenarioID
      );
      const aindex = step.StepScenarios[sindex].Actions.findIndex(
        (a) => a.ActionID === ActionID
      );
      step.StepScenarios[sindex].Actions[aindex].NextStepID = NewStepID;
    }
    steps[StepID] = JSON.parse(JSON.stringify(step));
    return steps;
  }, []);

  const createStepEdge = useCallback(
    (source, target, otherStates) => {
      if (!otherStates) otherStates = [];
      const srcNode = state.data.nodes[source];
      const edges = state.data.edges;
      edges[`${source}$${target}`] = {
        id: `${source}$${target}`,
        source,
        target,
        markerEnd: {
          type: MarkerType.Arrow,
          width: 20,
          height: 20,
          color: rgbaToHex(srcNode.data.styles.color),
        },
        style: {
          strokeWidth: 2,
          stroke: srcNode.data.styles.color,
        },
      };
      updateState([{ value: edges, type: "edges" }, ...otherStates]);
    },
    [state.data.edges, state.data.nodes, updateState]
  );

  const createStepNode = useCallback(
    (event, top, left, nodeId) => {
      const id = generateIntegerID(state.data.stepCounter.id);

      let steps = state.data.steps;
      const nodes = state.data.nodes;

      const newStep = {
        StepID: id,
        StepName: `Step ${Object.keys(steps).length - 1}`,
        StepTAT: "",
        StepTATUnit: "",
        StepScenarios: [
          {
            ScenarioID: "000",
            ScenarioName: "Default Scenario",
            StepOwnerType: "",
            StepOwnerLevel: "",
            Actions: [],
          },
        ],
        StepAlerts: [],
        StepReminders: [],
        StepEscalations: [],
      };

      steps[id] = newStep;

      const newNode = {
        id: `${id}_@_@`,
        position: project({
          x: event.clientX - left,
          y: event.clientY - top - 50,
        }),
        data: { StepID: id, styles: { color: palette.info.main } },
        type: "stepNode",
      };

      nodes[`${id}_@_@`] = newNode;
      nodes[nodeId].data.NextStepID = id;

      steps = updateNextStep(steps, newStep, nodeId); // No state updates happens in this function.
      createStepEdge(connectingNodeId.current, `${id}_@_@`, [
        { value: steps, type: "steps" },
        { value: nodes, type: "nodes" },
        { value: { id }, type: "stepCounter" },
      ]);
    },
    [
      state.data.stepCounter,
      state.data.steps,
      state.data.nodes,
      project,
      palette.info.main,
      updateNextStep,
      createStepEdge,
    ]
  );

  const onConnect = useCallback(
    (connection) => {
      const { source, target } = connection;
      const [SourceStepID, ,] = source.split("_");
      const [TargetStepID, ,] = target.split("_");

      if (SourceStepID === TargetStepID) return;
      const src = getNode(source);
      if (src.data.NextStepID) return;

      const nodes = { ...state.data.nodes };
      let steps = { ...state.data.steps };
      nodes[source].data.NextStepID = target;
      steps = updateNextStep(steps, steps[TargetStepID], source);
      createStepEdge(source, target, [
        { value: steps, label: "steps" },
        { value: nodes, label: "nodes" },
      ]);
    },
    [
      createStepEdge,
      getNode,
      state.data.nodes,
      state.data.steps,
      updateNextStep,
    ]
  );

  const onConnectStart = useCallback(
    (event, { nodeId, handleType }) => {
      if (handleType === "target") {
        connectingNodeId.current = undefined;
      } else {
        const node = getNode(nodeId);
        if (node.data.styles) {
          setConnectionLineStyle({
            stroke: node.data.styles.color,
            strokeWidth: 2,
          });
        }
        connectingNodeId.current = nodeId;
      }
    },
    [getNode]
  );

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains("react-flow__pane");
      if (
        targetIsPane &&
        connectingNodeId.current &&
        !state.data.nodes[connectingNodeId.current].data.NextStepID
      ) {
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
        createStepNode(event, top, left, connectingNodeId.current);
        setConnectionLineStyle({});
      }
    },
    [createStepNode, state.data.nodes]
  );

  console.log(state, "THIS IS STATELMAO");

  return (
    <div
      style={{ width: "90vw", height: "80vh", position: "relative" }}
      ref={reactFlowWrapper}
    >
      <ReactFlow
        onConnectStart={onConnectStart}
        onConnect={onConnect}
        onConnectEnd={onConnectEnd}
        onNodesChange={onNodesChange}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodeDragStop={(event, node, all) => {
          const nodes = state.data.nodes;
          nodes[node.id].position = node.position;
          updateState([{ value: nodes, type: "nodes" }]);
        }}
        edgeTypes={edgeTypes}
        isValidConnection={(...rest) => {
          return true;
        }}
        connectionLineStyle={connectionLineStyle}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <Button
          label={"Save"}
          onClick={() => {
            const Steps = [];
            Object.entries(state.data.steps).forEach(([key, value]) => {
              Steps.push(value);
            });
            const restState = { ...state.data };
            delete restState["steps"];
            const FINAL_PAYLOAD = {
              Steps,
              FrontendState: JSON.stringify(restState),
            };

            console.log(
              JSON.stringify(FINAL_PAYLOAD),
              "WORKFLOW PAYLOAD READY"
            );
          }}
        />
      </Box>
    </div>
  );
}

function Workflow2() {
  return (
    <ReactFlowProvider>
      <FlowChart />
    </ReactFlowProvider>
  );
}

export default Workflow2;
