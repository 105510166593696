import React from "react";
import { Modal, Paper } from "@mui/material";
import { v4 as uuid } from "uuid";
import CloseIcon from "@mui/icons-material/Close";

const initalProps = {
  open: null,
  onClose: null,
  removeCloseIcon: false,
};
function EwModal(props = initalProps) {
  let { children, open, onClose, removeCloseIcon, width } = props;
  // const { width, height } = useWindowDimensions();
  const id = uuid();
  if (!open) open = false;
  function clicked(e) {
    console.log(e.target);
    if (e.target === document.getElementById(id)) onClose();
  }
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div
          id={id}
          onClick={clicked}
          className="Flex"
          style={{ height: "100%" }}
        >
          <div>
            <Paper
              style={{
                padding: 20,
                position: "relative",
                width: width || undefined,
              }}
            >
              <>
                {!removeCloseIcon && (
                  <span
                    className="pointer"
                    style={{ position: "absolute", top: 5, right: 10 }}
                    onClick={onClose}
                  >
                    <CloseIcon />
                  </span>
                )}
                <>{children}</>
              </>
            </Paper>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EwModal;
