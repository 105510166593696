import { useEffect, useState } from "react";
import { isUserDefined } from "../../Helpers/helper";
import { Typography } from "@mui/material";

import Atomic from "../../../../../AComponent/Atomic";
import PeriodAccordian from "../PeriodAccordian";
import { PeriodMap } from "../PeriodMap";
import { getData, submitPayload } from "../../../../../Utils/Common/Library";
import { Check, Clear } from "@mui/icons-material";
import { WorkflowProcesser } from "../../WorkflowProcesser";
import DummyScale from "../../RatingScale.json";

const { Button, Input } = Atomic;

function targetStateReducer(periodTargets, mapTargets, scale, mapRule) {
  console.log(periodTargets, mapTargets, scale, mapRule, "TARGET_REDUCER");

  const result = periodTargets.reduce((total, periodTarget) => {
    const targets =
      periodTarget.MultipleTargets &&
      periodTarget.MultipleTargets.length === scale.ScaleValues.length
        ? periodTarget.MultipleTargets
        : isUserDefined(mapRule)
        ? [
            ...scale.ScaleValues.map((scaleValue) => ({
              ScaleOptionID: scaleValue.OptionID,
              SingleValue: "",
              MinimumRangeValue: "",
              MaximumRangeValue: "",
            })),
          ]
        : mapTargets.map((target) => ({
            ScaleOptionID: target.ScaleOptionID,
            SingleValue: target.TargetValue,
            MinimumRangeValue: target.MinimumTargetValue,
            MaximumRangeValue: target.MaximumTargetValue,
          }));
    if (!total[periodTarget.TargetPeriodType]) {
      total[periodTarget.TargetPeriodType] = {
        [periodTarget.TargetPeriodValue]: {
          targets,
          range: [
            targets[0].MinimumRangeValue,
            targets[targets.length - 1].MaximumRangeValue,
          ],
        },
      };
    } else if (
      !total[periodTarget.TargetPeriodType][periodTarget.TargetPeriodValue]
    ) {
      total[periodTarget.TargetPeriodType][periodTarget.TargetPeriodValue] = {
        targets,
        range: [
          targets[0].MinimumRangeValue,
          targets[targets.length - 1].MaximumRangeValue,
        ],
      };
    }
    return total;
  }, {});
  return result;
}

function AnchoredRatingScale({
  RatingScaleID,
  periodTargets,
  mapTargets,
  mapRule,
  editable,
  parameterTargetDataType,
  Workflow,
  ActionStatusLog,
  CurrentUserID,
  closeForm,
  saveData,
}) {
  const [scale, setScale] = useState(null);
  const [targets, setTargets] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  let WorkflowAction = "Submit";
  const workflowProcesser = new WorkflowProcesser(Workflow);
  const WorkflowStep = workflowProcesser.getCurrentStep();
  const CurrentUserStep = workflowProcesser.getStepByStepUserID(CurrentUserID);
  const CurrentStepID = workflowProcesser.getCurrentStepID();

  // if (CurrentUserStep === WorkflowStep) {
  //   if (CurrentUserStep === 1) {
  //     WorkflowAction = "Submit";
  //   } else {
  //     editable = false;
  //     WorkflowAction = "Approve/Reject";
  //   }
  // } else if (CurrentUserStep < WorkflowStep) {
  //   if (CurrentUserStep === 1) {
  //     editable = false;
  //     WorkflowAction = "Targets under review";
  //   } else {
  //     editable = false;
  //     WorkflowAction = "Awaiting for further approvals";
  //   }
  // } else {
  //   if (CurrentUserStep === 2) {
  //     editable = false;
  //     WorkflowAction = "Waiting for User to set the targets";
  //   } else {
  //     if (WorkflowStep === 1) {
  //       editable = false;
  //       WorkflowAction = "Waiting for User to set the targets";
  //     } else {
  //       editable = false;
  //       WorkflowAction = "Waiting for appraiser to approve this user's targets";
  //     }
  //   }
  // }

  useEffect(() => {
    setLoading(true);
    getData({
      featureId: "19148",
      payload: { "Header.TransactionID": RatingScaleID },
    })
      .then((response) => {
        console.log(response.Data[0], "RATINGSCALE");
        setScale(response.Data[0]);
        setTargets(
          targetStateReducer(
            periodTargets,
            mapTargets,
            response.Data[0],
            mapRule
          )
        );
      })
      .catch((error) => {
        console.log(error, "GoalSetting");
      })
      .finally(setLoading(false));
    // setScale({ ScaleValues: DummyScale });
    // setTargets(
    //   targetStateReducer(
    //     periodTargets,
    //     mapTargets,
    //     { ScaleValues: DummyScale },
    //     mapRule
    //   )
    // );
  }, [RatingScaleID, mapRule, mapTargets, periodTargets]);

  function getScaleValue(ScaleOptionID) {
    return scale.ScaleValues.find(
      (scaleValue) => scaleValue.OptionID === ScaleOptionID
    );
  }

  function onChangeHandler(
    TargetPeriodType,
    TargetPeriodValue,
    ScaleOptionID,
    value,
    type
  ) {
    console.log(type, "TRIGGERED");
    setTargets((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      const oldTargets = newState[TargetPeriodType][TargetPeriodValue].targets;
      const index = oldTargets.findIndex(
        (target) => target.ScaleOptionID === ScaleOptionID
      );
      console.log(newState, oldTargets, index, "TARGET_REDUCER");
      oldTargets[index][type] = value;
      const newTargets = [...oldTargets];
      newState[TargetPeriodType][TargetPeriodValue].targets = newTargets;
      return newState;
    });
  }

  function onChangeRangeHandler(
    TargetPeriodType,
    TargetPeriodValue,
    value,
    index
  ) {
    setTargets((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState[TargetPeriodType][TargetPeriodValue].range[index] = value;
      return newState;
    });
  }

  function validateTargets(targets, range) {
    if (parameterTargetDataType !== "NI" || parameterTargetDataType !== "ND")
      return true;
    const length = targets.length;
    if (+range[0] >= +range[1]) return false;

    for (let i = 0; i < length; i++) {
      if (
        targets[i].MinimumRangeValue !== "*" &&
        targets[i].MaximumRangeValue !== "*" &&
        +targets[i].MinimumRangeValue > +targets[i].MaximumRangeValue
      ) {
        return false;
      }
    }
    return true;
  }

  function onSave() {
    let message = null;
    const finalPeriodTargets = periodTargets.map((periodTarget) => {
      const thisPeriodTargets =
        targets[periodTarget.TargetPeriodType][periodTarget.TargetPeriodValue]
          .targets;
      if (
        ["NI", "ND", "PI", "PD"].includes(parameterTargetDataType) &&
        thisPeriodTargets[0].MinimumRangeValue === ""
      ) {
        thisPeriodTargets[0].MinimumRangeValue = "*";
      }

      if (
        ["NI", "ND", "PI", "PD"].includes(parameterTargetDataType) &&
        thisPeriodTargets[thisPeriodTargets.length - 1].MaximumRangeValue === ""
      ) {
        thisPeriodTargets[thisPeriodTargets.length - 1].MaximumRangeValue = "*";
      }

      const thisPeriodRange =
        targets[periodTarget.TargetPeriodType][periodTarget.TargetPeriodValue]
          .range;
      const isValid = validateTargets(
        JSON.parse(JSON.stringify(thisPeriodTargets)),
        thisPeriodRange
      );
      if (!isValid) {
        if (message === null) {
          message = `Incorrect Targets given for ${
            PeriodMap[periodTarget.TargetPeriodType].values[
              periodTarget.TargetPeriodValue
            ]
          }`;
        } else {
          message =
            message +
            `, ${
              PeriodMap[periodTarget.TargetPeriodType].values[
                periodTarget.TargetPeriodValue
              ]
            }`;
        }
      }

      const finalPayload = {
        ...periodTarget,
        MultipleTargets: thisPeriodTargets,
      };
      return finalPayload;
    });

    console.log(finalPeriodTargets, "GOALSETTINGS_PAYLOAD");

    if (message !== null) {
      console.log(message, "GOALSETTINGS_PAYLOAD");
      setError(message);
      return;
    }

    saveData(finalPeriodTargets);
  }
  console.log(loading || !targets, loading, targets, "BOOLEAN");
  return loading || !targets ? (
    <div className="Flex">
      <Typography>Loading your targets ....</Typography>
    </div>
  ) : WorkflowAction === "UserSetting" ? (
    <div className="Flex">
      <Typography variant="h6">User is setting his targets</Typography>
    </div>
  ) : WorkflowAction === "AppraiserSetting" ? (
    <div className="Flex">
      <Typography variant="h6">
        Appraiser is yet to approve this user's targets
      </Typography>
    </div>
  ) : (
    <div>
      <div>
        {periodTargets.map((periodTarget) => {
          const currentTargets =
            targets[periodTarget.TargetPeriodType][
              periodTarget.TargetPeriodValue
            ].targets;

          const range =
            targets[periodTarget.TargetPeriodType][
              periodTarget.TargetPeriodValue
            ].range;

          console.log("THESE ARE RANGES", range);

          return (
            <PeriodAccordian
              PeriodName={
                PeriodMap[periodTarget.TargetPeriodType].values[
                  periodTarget.TargetPeriodValue
                ]
              }
            >
              {["NI", "ND", "PI", "PD"].includes(parameterTargetDataType) && (
                <div className="Flex-row">
                  <Input
                    label={"Min range"}
                    type={"number"}
                    onChange={(value) => {
                      onChangeRangeHandler(
                        periodTarget.TargetPeriodType,
                        periodTarget.TargetPeriodValue,
                        value,
                        0
                      );
                      onChangeHandler(
                        periodTarget.TargetPeriodType,
                        periodTarget.TargetPeriodValue,
                        currentTargets[0].ScaleOptionID,
                        value,
                        "MinimumRangeValue"
                      );
                    }}
                    value={range[0]}
                    disable={!editable}
                  />
                  <Input
                    label={"Max range"}
                    type={"number"}
                    onChange={(value) => {
                      onChangeRangeHandler(
                        periodTarget.TargetPeriodType,
                        periodTarget.TargetPeriodValue,
                        value,
                        1
                      );
                      onChangeHandler(
                        periodTarget.TargetPeriodType,
                        periodTarget.TargetPeriodValue,
                        currentTargets[currentTargets.length - 1].ScaleOptionID,
                        value,
                        "MaximumRangeValue"
                      );
                    }}
                    value={range[1]}
                    disable={!editable}
                  />
                </div>
              )}
              {currentTargets.map((target, index) => {
                const scaleValue = getScaleValue(target.ScaleOptionID);
                return (
                  <div className="Flex-row" style={{ gap: "1em" }}>
                    <Typography variant="body1" fontWeight={"600"}>
                      {scaleValue?.Label}
                    </Typography>
                    {["NI", "PI"].includes(parameterTargetDataType) && (
                      <div className="Flex-row">
                        <Input
                          label={"Min"}
                          type={"number"}
                          onChange={(value) => {
                            onChangeHandler(
                              periodTarget.TargetPeriodType,
                              periodTarget.TargetPeriodValue,
                              target.ScaleOptionID,
                              value,
                              "MinimumRangeValue"
                            );
                          }}
                          value={
                            index === 0 ? range[0] : target.MinimumRangeValue
                          }
                          disable={true}
                        />
                        <Input
                          label={"Max"}
                          type={"number"}
                          onChange={(value) => {
                            onChangeHandler(
                              periodTarget.TargetPeriodType,
                              periodTarget.TargetPeriodValue,
                              target.ScaleOptionID,
                              value,
                              "MaximumRangeValue"
                            );
                            console.log(
                              index < currentTargets.length - 1,
                              "THIS IS INDEX"
                            );
                            if (index < currentTargets.length - 1) {
                              onChangeHandler(
                                periodTarget.TargetPeriodType,
                                periodTarget.TargetPeriodValue,
                                currentTargets[index + 1].ScaleOptionID,
                                (+value + 1).toString(),
                                "MinimumRangeValue"
                              );
                            }
                          }}
                          value={
                            index === currentTargets.length - 1
                              ? range[1]
                              : target.MaximumRangeValue
                          }
                          disable={
                            index === currentTargets.length - 1
                              ? true
                              : !editable
                          }
                        />
                      </div>
                    )}
                    {["ND", "PD"].includes(parameterTargetDataType) && (
                      <div className="Flex-row">
                        <Input
                          label={"Min"}
                          type={"number"}
                          onChange={(value) => {
                            onChangeHandler(
                              periodTarget.TargetPeriodType,
                              periodTarget.TargetPeriodValue,
                              target.ScaleOptionID,
                              value,
                              "MinimumRangeValue"
                            );
                          }}
                          value={
                            index === 0 ? range[0] : target.MinimumRangeValue
                          }
                          disable={true}
                        />
                        <Input
                          label={"Max"}
                          type={"number"}
                          onChange={(value) => {
                            onChangeHandler(
                              periodTarget.TargetPeriodType,
                              periodTarget.TargetPeriodValue,
                              target.ScaleOptionID,
                              value,
                              "MaximumRangeValue"
                            );
                            console.log(
                              index < currentTargets.length - 1,
                              "THIS IS INDEX"
                            );
                            if (index < currentTargets.length - 1) {
                              onChangeHandler(
                                periodTarget.TargetPeriodType,
                                periodTarget.TargetPeriodValue,
                                currentTargets[index + 1].ScaleOptionID,
                                (+value + 0.1).toFixed(1).toString(),
                                "MinimumRangeValue"
                              );
                            }
                          }}
                          value={
                            index === currentTargets.length - 1
                              ? range[1]
                              : target.MaximumRangeValue
                          }
                          disable={
                            index === currentTargets.length - 1
                              ? true
                              : !editable
                          }
                        />
                      </div>
                    )}
                    {(parameterTargetDataType === "ST" ||
                      parameterTargetDataType === "LT") && (
                      <div>
                        <Input
                          label={"Target"}
                          onChange={(value) => {
                            onChangeHandler(
                              periodTarget.TargetPeriodType,
                              periodTarget.TargetPeriodValue,
                              target.ScaleOptionID,
                              value,
                              "SingleValue"
                            );
                          }}
                          value={target.SingleValue}
                          disable={!editable}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </PeriodAccordian>
          );
        })}
      </div>
      <div className="Flex">
        {WorkflowAction === "Submit" ? (
          <Button
            title={"Save"}
            onClick={() => {
              onSave();
              closeForm();
            }}
            style={{ margin: "2em" }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default AnchoredRatingScale;
