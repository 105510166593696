import store from "../../../Redux/Store";

function throwError(msg) {
  return {
    type: "error",
    message: msg,
  };
}

function checkMissing(arr) {
  let payload = {};
  for (let i = 0; i < arr.length; i++) {
    let { field, message, type } = arr[i];
    if (!field) return throwError(message);
    payload[type] = field;
  }

  return {
    type: "Success",
    payload,
  };
}

function ValidateGeneral(data) {
  if (!data || Object.keys(data).length === 0)
    return throwError("General Section Not Defined");
  let {
    PlanName,
    PlanDescription,
    PlanStart,
    PlanEnd,
    PlanApplicabilityId,
    MinimumTenureUnit,
    MinimumTenure,
    MinimumConfirmationTenureUnit,
    MinimumConfirmationTenure,
    MaximumTenureUnit,
    MaximumTenure,
    MinimumPerformancePeriod,
    //  PlanParticipationBasis,
    // MinimumEmployeeTenure,
    //  MaximumEmployeeTenure,
    // MinimumDaysInPlan,
    MinimumReviewPeriod,
    AggregatePlanScore,
    AggregateRounding,
    AggregateRatingScaleID,
    AggregateScaleDisplay,
    AggregateScoreDisplay,
    AggregateScoreRater,
    AggregateFinalScore,
    AdjustedPlanScore,
    AdjustedMinimumPeriod,
    AdjustedPlanScoreLabelConversion,
    AdjustedPlanScoreRatingScaleID,
    AdjustedPlanScoreDisplay,
    AdjustedPlanFinalScore,
    AdjustedRounding,
    AssessorPlanScore,
    AssessorPlanRater,
    AssessorPlanRatingScaleId,
    AssessorPlanFinalScore,
    ModeratedPlanScore,
    ModeratedPlanScoreRatingId,
    ModeratedPlanScoreLabel,
    ModeratedPlanScoreUploaded,
    ModeratedPlanScoreFinalScore,
  } = data;
  let payload = {
    PlanDescription: PlanDescription || "",
    PlanApplicabilityId: PlanApplicabilityId || "",
    // PlanParticipationBasis: PlanParticipationBasis || "",
    // MinimumEmployeeTenure: MinimumEmployeeTenure || "",
    //   MaximumEmployeeTenure: MaximumEmployeeTenure || "",
    //   MinimumDaysInPlan: MinimumDaysInPlan || "",
    MinimumReviewPeriod: MinimumReviewPeriod || "0",
    MinimumTenureUnit: MinimumTenureUnit || "D",
    MinimumTenure: MinimumTenure || "0",
    MinimumConfirmationTenureUnit: MinimumConfirmationTenureUnit || "D",
    MinimumConfirmationTenure: MinimumConfirmationTenure || "0",
    MaximumTenureUnit: MaximumTenureUnit || "Y",
    MaximumTenure: MaximumTenure || "99",
    MinimumPerformancePeriod: MinimumPerformancePeriod || "30",
  };
  let arr = [
    { field: PlanName, message: "Plan Name Not Defined", type: "PlanName" },
    { field: PlanStart, message: "Plan Start Not Defined", type: "PlanStart" },
    { field: PlanEnd, message: "Plan End Not Defined", type: "PlanEnd" },
  ];
  if (AggregatePlanScore === "E") {
    payload.AggregatePlanScore = "E";
    payload.AggregateRounding = AggregateRounding || "";
    payload.AggregateFinalScore = AggregateFinalScore || "D";
    payload.AggregateScoreDisplay = AggregateScoreDisplay || "";
    arr = [
      ...arr,
      {
        field: AggregateRatingScaleID,
        message: "Select A rating Scale For Aggregate Score.",
        type: "AggregateRatingScaleID",
      },
      {
        field: AggregateScaleDisplay,
        message: "Select A Scale Display Value For Aggregate Score.",
        type: "AggregateScaleDisplay",
      },

      {
        field: AggregateScoreRater,
        message: "Seleact A Rater For Aggrgate Score",
        type: "AggregateScoreRater",
      },
      {
        field: AggregateScoreDisplay,
        message: "Select a Aggregate Score Display value",
        type: "AggregateScoreDisplay",
      },
    ];
  } else {
    payload = {
      ...payload,
      AggregatePlanScore,
      AggregateRounding: "",
      AggregateFinalScore: "",
      AggregateRatingScaleID: "",
      AggregateScaleDisplay: "",
      AggregateScoreDisplay: "",
      AggregateScoreRater: "",
    };
  }
  if (AdjustedPlanScore === "E") {
    payload.AdjustedPlanScore = "E";
    payload.AdjustedMinimumPeriod = AdjustedMinimumPeriod || "";
    payload.AdjustedRounding = AdjustedRounding || "";
    //payload.AdjustedPlanScoreLabelConversion=AdjustedPlanScoreLabelConversion || "";
    payload.AdjustedPlanFinalScore = AdjustedPlanFinalScore || "D";
    arr = [
      ...arr,
      {
        field: AdjustedPlanScoreLabelConversion,
        message: "Label Conversion Not Selected For Adjusted Score.",
        type: "AdjustedPlanScoreLabelConversion",
      },
      {
        field: AdjustedPlanScoreRatingScaleID,
        message: "Rating Scale Not Selected For Adjusted Score",
        type: "AdjustedPlanScoreRatingScaleID",
      },
      {
        field: AdjustedPlanScoreDisplay,
        message: "Plan Score Display Not Defined",
        type: "AdjustedPlanScoreDisplay",
      },
    ];
  } else {
    payload = {
      ...payload,
      AdjustedPlanScore: "D",
      AdjustedMinimumPeriod: "",
      AggregateRatingScaleID: "",
      AggregateScaleDisplay: "",
      AggregateScoreDisplay: "",
      AggregateFinalScore: "",
    };
  }

  if (AssessorPlanScore === "E") {
    payload.AssessorPlanScore = "E";
    payload.AssessorPlanFinalScore = AssessorPlanFinalScore || "D";
    arr = [
      ...arr,
      {
        field: AssessorPlanRater,
        message: "Rater Not Selected In Assessor Plan",
        type: "AssessorPlanRater",
      },
      {
        field: AssessorPlanRatingScaleId,
        message: "Rating Scale Not Selected In Assessor Plan",
        type: "AssessorPlanRatingScaleId",
      },
    ];
  } else {
    payload = {
      ...payload,
      AssessorPlanScore: "D",
      AssessorPlanRater: "",
      AssessorPlanRatingScaleId: "",
      AssessorPlanFinalScore: "",
    };
  }

  if (ModeratedPlanScore === "E") {
    payload.ModeratedPlanScore = "E";
    payload.ModeratedPlanScoreFinalScore = ModeratedPlanScoreFinalScore || "D";
    arr = [
      ...arr,
      {
        field: ModeratedPlanScoreRatingId,
        message: "Rating Scale Not Selected For Moderated Plan Score.",
        type: "ModeratedPlanScoreRatingId",
      },
      {
        field: ModeratedPlanScoreLabel,
        message: "Label Not Defined For Moderated Plan Score.",
        type: "ModeratedPlanScoreLabel",
      },
      {
        field: ModeratedPlanScoreUploaded,
        message: "Moderate Plan Score Upload Type Not Defined.",
        type: "ModeratedPlanScoreUploaded",
      },
    ];
  } else {
    payload = {
      ...payload,
      ModeratedPlanScore: "D",
      ModeratedPlanScoreRatingId: "",
      ModeratedPlanScoreLabel: "",
      ModeratedPlanScoreUploaded: "",
      ModeratedPlanScoreFinalScore: "",
    };
  }
  function getDaysAndMonth(date) {
    date = new Date(date);
    let day = date.getDate();
    let month = date.getMonth();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return `${day}-${month}`;
  }
  let result = checkMissing(arr);
  if (result.type === "error") return result;
  let startMonth = new Date(PlanStart).getMonth();
  let endMonth = new Date(PlanEnd).getMonth();
  if (endMonth < startMonth && startMonth - endMonth !== 1)
    return throwError("Plan Start and end should have a gap of 12 month! 1");

  if (endMonth > startMonth && endMonth - startMonth !== 11)
    return throwError("Plan Start and end should have a gap of 12 month!");

  console.log(
    {
      ...payload,
      ...result.payload,
      //PlanStart: getDaysAndMonth(PlanStart),
      //  PlanEnd: getDaysAndMonth(PlanEnd),
      PlanStart: "" + new Date(PlanStart).getTime(),
      PlanEnd: "" + new Date(PlanEnd).getTime(),
    },
    "result.payload"
  );
  return {
    type: "Success",
    payload: {
      ...payload,
      ...result.payload,
      //PlanStart: getDaysAndMonth(PlanStart),
      //  PlanEnd: getDaysAndMonth(PlanEnd),
      PlanStart: "" + new Date(PlanStart).getTime(),
      PlanEnd: "" + new Date(PlanEnd).getTime(),
    },
  };
}
function ValidateCoaching(data) {
  console.log("From Cocing: ", data.CoachingSection !== "E");
  if (data.CoachingSection !== "E")
    return {
      type: "success",
      payload: {
        CoachingSection: "D",
        CoachingSectionLabel: "",
        CoachingSectionDescription: "",
        CoachingDataColumns: [],
      },
    };

  console.log("From Cocing: After retur");
  let {
    CoachingSectionLabel,
    CoachingSectionDescription,
    CoachingDataColumns,
  } = data;
  let payload = {
    CoachingSectionDescription: CoachingSectionDescription || "",
    CoachingSectionLabel: CoachingSectionLabel || "",
  };
  if (!CoachingDataColumns)
    return throwError("No Columns Selected In Coaching Section");
  if (CoachingDataColumns.length === 0)
    return throwError("No Columns Selected In Coaching Section");
  CoachingDataColumns = CoachingDataColumns.map((ele) => ({ ID: ele }));
  return {
    type: "success",
    payload: {
      ...payload,
      CoachingSection: "E",
      CoachingDataColumns,
    },
  };
}
function ValidateTrainingNeeds(data) {
  if (!data || data?.TrainingSection !== "E")
    return {
      type: "success",
      payload: {
        TrainingSection: "D",
        TrainingSectionLabel: "",
        TrainingSectionDescription: "",
        DimensionLinkage: [],
        Unlink: "",
        LinkTraining: "",
        NeedDescription: "",
      },
    };
  let {
    Section,
    TrainingSectionLabel,
    TrainingSectionDescription,
    DimensionLinkage,
    UnlinkNeeds,
    LinkTraining,
    NeedDescription,
  } = data;
  let result = checkMissing([
    {
      field: TrainingSectionLabel,
      message: "Section Label For Training Needs Not Defined",
      type: "SectionLabel",
    },
    {
      field: DimensionLinkage,
      message: "No Dimensions Linked In Training Needs",
      type: "DimensionLinkage",
    },
    {
      field: NeedDescription,
      message: "Type Of Need Description Not Defined.",
      type: "NeedDescription",
    },
  ]);
  if (result.type === "error") return result;
  if (DimensionLinkage.length == 0)
    return throwError("No Dimensions Linked In Training Needs");
  // DimensionLinkage = DimensionLinkage.map((ele) => ({ ID: ele }));
  return {
    type: "success",
    payload: {
      TrainingSection: "E",
      TrainingSectionLabel,
      TrainingSectionDescription: TrainingSectionDescription || "",
      DimensionLinkage,
      UnlinkNeeds: UnlinkNeeds || "D",
      LinkTraining: LinkTraining || "D",
      NeedDescription,
    },
  };
}
function ValidateConfi(data) {
  if (!data || data?.ConfidentialSection !== "E") {
    return {
      type: "success",
      payload: {
        ConfidentialSection: "D",
        ConfidentialLabel: "",
        ConfidentialDescription: "",
        ConfidentialPerformanceSummary: "",
        ConfidentialPotentialSummary: "",
        GrowthRoleAssessment: "",
        GrowthRoleAssessmentOptions: [],
        PromotionAssessment: "",
        PromotionAssessmentOptions: [],
        ExitAssessment: "",
        ExitAssessmentOptions: [],
      },
    };
  }
  let {
    ConfidentialSection,
    ConfidentialLabel,
    ConfidentialDescription,
    ConfidentialPerformanceSummary,
    ConfidentialPotentialSummary,
    GrowthRoleAssessment,
    PromotionAssessment,
    PromotionAssessmentOptions,
    ExitAssessment,
    ExitAssessmentOptions,
    GrowthRoleAssessmentOptions,
  } = data;

  let result = checkMissing([
    {
      field: ConfidentialLabel,
      message: "Label Not Defined In Confidential Section",
      type: "ConfidentialLabel",
    },
    {
      field: ConfidentialPerformanceSummary,
      message:
        "Performance Summary Options Not Selected In Confidential Section.",
      type: "ConfidentialPerformanceSummary",
    },
    {
      field: ConfidentialPotentialSummary,
      message: "Potential Summary Options Not Selected.",
      type: "ConfidentialPotentialSummary",
    },
    {
      field: GrowthRoleAssessment,
      message: "Select Growth Role Assessment Type.",
      type: "GrowthRoleAssessment",
    },
    {
      field: PromotionAssessment,
      message: "Select Promotion Assessment Type.",
      type: "PromotionAssessment",
    },
  ]);
  if (result.type === "error") return result;
  let payload = {
    ConfidentialSection: "E",
    ConfidentialDescription: ConfidentialDescription || "",
    ...result.payload,
  };
  console.log("For growth: ", GrowthRoleAssessmentOptions, " ");
  if (GrowthRoleAssessment === "M" || GrowthRoleAssessment === "O") {
    if (
      !GrowthRoleAssessmentOptions ||
      GrowthRoleAssessmentOptions?.length === 0
    )
      return throwError("No Options Given For Growth Role");
    payload.GrowthRoleAssessment = GrowthRoleAssessment;
    payload.GrowthRoleAssessmentOptions = GrowthRoleAssessmentOptions;
  }

  if (PromotionAssessment === "M" || PromotionAssessment === "O") {
    if (!PromotionAssessmentOptions || PromotionAssessmentOptions?.length === 0)
      return throwError("No Options Given For Promotion Assessment");
    payload.PromotionAssessment = PromotionAssessment;
    payload.PromotionAssessmentOptions = PromotionAssessmentOptions;
  }

  if (ExitAssessment === "M" || ExitAssessment === "O") {
    if (!ExitAssessmentOptions || ExitAssessmentOptions?.length === 0)
      return throwError("No Options Given For Exit Assessment");
    payload.ExitAssessment = ExitAssessment;
    payload.ExitAssessmentOptions = ExitAssessmentOptions;
  }
  // payload.GrowthRoleAssessmentOptions = GrowthRoleAssessmentOptions?.map(
  //   (ele) => ({
  //     ID: ele,
  //   })
  // );
  // payload.PromotionAssessmentOptions = PromotionAssessmentOptions?.map(
  //   (ele) => ({ ID: ele })
  // );
  // payload.ExitAssessmentOptions = ExitAssessmentOptions?.map((ele) => ({
  //   ID: ele,
  // }));
  return {
    type: "success",
    payload,
  };
}
function ValidateSummar(data) {
  let defaultDim = {
    DimensionID: "",
    PastTwoCycleAggregateScore: "",
    CurrentCycleAggregateScore: "",
    PastTwoCycleModeratedScore: "",
    CurrentCycleModeratedScore: "",
  };
  if (!data || data?.SummarySection !== "E") {
    return {
      type: "success",
      payload: {
        SummarySection: "D",
        SummaryLabel: "",
        SummaryDescription: "",
        SummaryDimensionScores: [],
        SummaryPlanAggregateScoreWidget: "",
        SummaryPlanAggregateScore: "",
        SummaryPlanModerateScore: "",
        SummaryMultiRaterScoreWidget: "",
        SummaryCommentsWidget: "",

        SnapShotWidgetName: "D",
        SnapShotWidgetEmployee: "D",
        SnapShotWidgetRole: "D",
        SnapShotWidgetDesignation: "D",
        SnapShotWidgetFunction: "D",
        SnapShotWidgetLocation: "D",
        SnapShotWidgetGrade: "D",
        SnapShotWidgetEmployeeType: "D",

        AttendanceWidgetDaysWorked: "D",
        AttendanceWidgetPunctualityRecord: "D",
        AttendanceWidgetPunctuality: "D",
        AttendanceWidgetHours: "D",
      },
    };
  }
  let {
    SummarySection,
    SummaryLabel,
    SummaryDescription,
    SummaryDimensionScores,
    SummaryPlanAggregateScoreWidget,
    SummaryPlanAggregateScore,
    SummaryPlanModerateScore,
    SummaryMultiRaterScoreWidget,
    SummaryCommentsWidget,
    SummaryLeaveWidget,
    SnapShotWidgetName,
    SnapShotWidgetEmployee,
    SnapShotWidgetRole,
    SnapShotWidgetDesignation,
    SnapShotWidgetFunction,
    SnapShotWidgetLocation,
    SnapShotWidgetGrade,
    SnapShotWidgetEmployeeType,
    AttendanceWidgetDaysWorked,
    AttendanceWidgetPunctualityRecord,
    AttendanceWidgetPunctuality,
    AttendanceWidgetHours,
  } = data;
  let payload = {
    SummarySection: "E",
    SummaryDescription: SummaryDescription || "",
    SummaryDimensionScores: SummaryDimensionScores || [],
    SummaryPlanAggregateScore: SummaryPlanAggregateScore || "D",
    SummaryPlanModerateScore: SummaryPlanModerateScore || "D",
    SummaryMultiRaterScoreWidget: SummaryMultiRaterScoreWidget || "D",
    SummaryCommentsWidget: SummaryCommentsWidget || "D",
    SnapShotWidgetName: SnapShotWidgetName || "D",
    SnapShotWidgetEmployee: SnapShotWidgetEmployee || "D",
    SnapShotWidgetRole: SnapShotWidgetRole || "D",
    SnapShotWidgetDesignation: SnapShotWidgetDesignation || "D",
    SnapShotWidgetFunction: SnapShotWidgetFunction || "D",
    SnapShotWidgetLocation: SnapShotWidgetLocation || "D",
    SnapShotWidgetGrade: SnapShotWidgetGrade || "D",
    SnapShotWidgetEmployeeType: SnapShotWidgetEmployeeType || "D",
    AttendanceWidgetDaysWorked: AttendanceWidgetDaysWorked || "D",
    AttendanceWidgetPunctualityRecord: AttendanceWidgetPunctualityRecord || "D",
    AttendanceWidgetPunctuality: AttendanceWidgetPunctuality || "D",
    AttendanceWidgetHours: AttendanceWidgetHours || "D",
  };
  console.log("Sumamry test: 1", SummaryDimensionScores);
  if (
    SummaryDimensionScores &&
    Object.keys(SummaryDimensionScores).length > 0
  ) {
    payload.SummaryDimensionScores = Object.keys(SummaryDimensionScores).map(
      (ele) => ({ DimensionID: ele, ...SummaryDimensionScores[ele] })
    );
    //console.log("Sumamry test: ",test)
  } else {
    payload.SummaryDimensionScores = [];
  }

  if (!SummaryLabel)
    return throwError("Label For Summary Section Not Defined.");

  payload.SummaryLabel = SummaryLabel;
  // if (
  //   !SummaryPlanAggregateScoreWidget ||
  //   SummaryPlanAggregateScoreWidget === "D"
  // ) {
  //   payload.SummaryPlanAggregateScore = "D";
  //   payload["SummaryPlanModerateScore"] = "D";
  // } else {
  //   payload["SummaryPlanModerateScore"] = SummaryPlanModerateScore || "D";
  //   payload["SummaryPlanModerateScore"] = SummaryPlanModerateScore || "D";
  // }
  // if (
  //   !SummaryAppraiseeSnapShotWidget ||
  //   Object.keys(SummaryAppraiseeSnapShotWidget).length === 0
  // ) {
  //   payload.SummaryAppraiseeSnapShotWidget = {
  //     Name: "",
  //     Employee: "",
  //     Role: "",
  //     Designation: "",
  //     Function: "",
  //     Location: "",
  //     Grade: "",
  //     EmployeeType: "",
  //   };
  // } else {
  //   payload.SummaryAppraiseeSnapShotWidget = SummaryAppraiseeSnapShotWidget;
  // }

  // if (!SummaryLeaveWidget || Object.keys(SummaryLeaveWidget).length === 0) {
  //   payload.SummaryLeaveWidget = {
  //     DaysWorked: "",
  //     PunctualityRecord: "",
  //     Punctuality: "",
  //     Hours: "",
  //   };
  // } else {
  //   payload.SummaryLeaveWidget = SummaryLeaveWidget;
  // }
  return {
    type: "success",
    payload,
  };
}
function ValidateEvents(state) {
  var payload = {};
  // let { GoalSettingsWorkFlowID, GoalSettingsDimensions } = state["MPP"] || {};
  // if (!GoalSettingsWorkFlowID)
  //   return throwError("Workflow Not Set For Goal Settings.");
  // if (!GoalSettingsDimensions || GoalSettingsDimensions?.length === 0)
  //   return throwError("Select Dimensions For Goal Settings.");
  //GoalSettingsDimensions = GoalSettingsDimensions.map((ele) => ({ ID: ele }));
  // payload = {
  //   GoalSettings: {
  //     GoalSettingsWorkFlowID: GoalSettingsWorkFlowID
  //       ? GoalSettingsWorkFlowID
  //       : "",
  //     GoalSettingsDimensions,
  //   },
  // };
  // const {
  //   PeriodicReview,
  //   PeriodicReviewFrequency,
  //   PeriodicReviewWorkflowID,
  //   PeriodicReviewDimensions,
  // } = state["MPP"] || {};
  // let perPayload = {};
  // if (PeriodicReview === "E") {
  //   let result = checkMissing([
  //     {
  //       field: PeriodicReviewFrequency,
  //       message: "Select Review Frequency For Periodic Review.",
  //       type: "PeriodicReviewFrequency",
  //     },
  //     {
  //       field: PeriodicReviewWorkflowID,
  //       message: "Workflow Not Set For Periodic Review.",
  //       type: "PeriodicReviewWorkflowID",
  //     },
  //     {
  //       field: PeriodicReviewDimensions,
  //       message: "Select Dimensions For Periodic Review.",
  //       type: "PeriodicReviewDimensions",
  //     },
  //   ]);

  //   if (result.type === "error") return result;

  //   if (PeriodicReviewDimensions.length === 0)
  //     return throwError("Select Dimensions For Periodic Review.");
  //   perPayload = result.payload;
  //   perPayload.PeriodicReview = "E";
  //   // perPayload.PeriodicReviewDimensions = PeriodicReviewDimensions.map(
  //   //   (ele) => ({ ID: ele })
  //   // );
  // } else {
  //   perPayload = {
  //     PeriodicReview: "D",
  //     PeriodicReviewFrequency: "",
  //     PeriodicReviewWorkflowID: "",
  //     PeriodicReviewDimensions: PeriodicReviewDimensions || [],
  //   };
  // }
  // const {
  //   InterimReview,
  //   InitiateReview,
  //   InterimWorkFlowID,
  //   InterimDimensions,
  // } = state["MPP"] || {};
  // let interPayload = {};
  // if (InterimReview === "E") {
  //   let result = checkMissing([
  //     {
  //       field: InitiateReview,
  //       message: "Select Event When Interim Review Must Happen.",
  //       type: "InitiateReview",
  //     },
  //     {
  //       field: InterimWorkFlowID,
  //       message: "No Workflow ID Defined For Interim Review",
  //       type: "InterimWorkFlowID",
  //     },
  //     {
  //       field: InterimDimensions,
  //       message: "No Dimension Seleted For Interim Review.",
  //       type: "InterimDimensions",
  //     },
  //   ]);
  //   if (result.type === "error") return result;
  //   if (InterimDimensions.length === 0)
  //     return throwError("No Dimension Seleted For Interim Review.");
  //   interPayload = {
  //     InterimReview: "E",
  //     InitiateReview,
  //     InterimWorkFlowID,
  //     InterimDimensions,
  //   };
  //   // interPayload.InterimDimensions = InterimDimensions.map((ele) => ({
  //   //   ID: ele,
  //   // }));
  // } else {
  //   interPayload = {
  //     InterimReview: "D",
  //     InitiateReview: "",
  //     InterimWorkFlowID: "",
  //     InterimDimensions: InterimDimensions || [],
  //   };
  // }

  const {
    MultiRater,
    MultiRaterDimensions,
    MultiDimList,
    MultiRaterFrequency,
    MultiRaterType,
    MultiRaterRule,
    MultiRaterSelfAssessment,
    MultiRaterConversations,
    MultiRaterScoreDisplay,
    MultiRaterAppraiserSubmission,
  } = state["MPP"] || {};
  let multiPayload = {
    MultiRater: "D",
    MultiRaterDimensions: [],
    MultiRaterFrequency: "",
    MultiRaterType: "",
    MultiRaterRule: "",
    MultiRaterSelfAssessment: "",
    MultiRaterConversations: "",
    MultiRaterScoreDisplay: "",
    MultiRaterAppraiserSubmission: "",
  };
  if (MultiRater === "E") {
    let result = checkMissing([
      {
        field: MultiRaterDimensions,
        message: "No Dimension Selected For Multi Rater.",
        type: "MultiRaterDimensions",
      },
      {
        field: MultiRaterFrequency,
        message: "No Frequency Selected For Multi Rater.",
        type: "MultiRaterFrequency",
      },
      {
        field: MultiRaterType,
        message: "No Rater Type Selected For Multi Rater.",
        type: "MultiRaterType",
      },
      {
        field: MultiRaterRule,
        message: "No Rater Rule Selected For Multi Rater.",
        type: "MultiRaterRule",
      },
      {
        field: MultiRaterRule,
        message: "No Rater Rule Selected For Multi Rater.",
        type: "MultiRaterRule",
      },
      {
        field: MultiRaterScoreDisplay,
        message: "No Rater Score Display Type Selected For Multi Rater.",
        type: "MultiRaterScoreDisplay",
      },
      {
        field: MultiRaterAppraiserSubmission,
        message: "NAppraiser Submission Not Selected For Multi Rater.",
        type: "MultiRaterAppraiserSubmission",
      },
    ]);
    if (result.type === "error") return result;
    if (MultiRaterDimensions.length === 0)
      return throwError("No Dimension Selected For Multi Rater.");
    multiPayload = {
      MultiRater: "E",
      MultiRaterDimensions,
      MultiRaterFrequency,
      MultiRaterType,
      MultiRaterRule,
      MultiRaterSelfAssessment,
      MultiRaterConversations,
      MultiRaterScoreDisplay,
      MultiRaterAppraiserSubmission,
    };
    // multiPayload.MultiRaterDimensions = MultiRaterDimensions.map((ele) => ({
    //   ID: ele,
    // }));
  }

  const {
    ProgressTracking,
    ProgressTrackingDimensions,
    ProgressTrackingScaleID,
    ProgressTrackingFrequency,
    ProgressTrackingWorkflowID,
  } = state["MPP"] || {};

  let ProgressPayload = {
    ProgressTracking: "D",
    ProgressTrackingDimensions: [],
    ProgressTrackingScaleID: "",
    ProgressTrackingFrequency: "",
    ProgressTrackingWorkflowID: "",
  };
  if (ProgressTracking === "E") {
    let result = checkMissing([
      {
        field: ProgressTrackingDimensions,
        message: "No Dimension Selected For Progress Tracking.",
        type: "ProgressTrackingDimensions",
      },
      {
        field: ProgressTrackingScaleID,
        message: "No Rating Scale Selected For Progress Tracking.",
        type: "ProgressTrackingScaleID",
      },
      {
        field: ProgressTrackingWorkflowID,
        message: "No Workflow Is Set For Progress Tracking.",
        type: "ProgressTrackingWorkflowID",
      },
      {
        field: ProgressTrackingFrequency,
        message: "No Frequency Selected For Progress Tracking.",
        type: "ProgressTrackingFrequency",
      },
    ]);
    if (result.type === "error") return result;
    if (ProgressTrackingDimensions.length === 0)
      return throwError("No Dimension Selected For Progress Tracking.");
    ProgressPayload = {
      ProgressTracking: "E",
      ProgressTrackingDimensions,
      ProgressTrackingScaleID,
      ProgressTrackingFrequency,
      ProgressTrackingWorkflowID,
    };
    // ProgressPayload.ProgressTrackingDimensions = ProgressTrackingDimensions.map(
    //   (ele) => ({ ID: ele })
    // );
  }
  return {
    type: "success",
    payload: {
      ...payload,
      //  PeriodicReviews: perPayload,
      // InterimReviews: interPayload,
      MultiRaters: multiPayload,
      ProgressTracking: ProgressPayload,
    },
  };
}
function ValidateDim(data, id) {
  if (!data) return throwError("Dimension Error.");
  const {
    AggregateScoreRatingScaleID,
    PlanDimensionWeight,
    TargetEditing,
    DimensionAggregateScore,
    AggregationRating,
    ScoreRounding,
    RoundingValue,
    ItemNumber,
    enabled,
    DimensionActivities,
  } = data;
  console.log("Before neable: ", DimensionActivities);
  if (enabled !== "E") {
    return {
      type: "success",
      payload: {
        AggregateScoreRatingScaleID: "",
        PlanDimensionWeight: "",
        TargetEditing: "",
        DimensionAggregateScore: "",
        AggregationRating: "",
        ScoreRounding: "",
        RoundingValue: "",
        ItemNumber,
      },
    };
  }
  let payload = {
    DimensionID: id,
  };
  let result = checkMissing([
    {
      field: AggregateScoreRatingScaleID,
      message: "Select A Rating Scale For Aggregate Score In Dimension.",
      type: "AggregateScoreRatingScaleID",
    },
    {
      field: TargetEditing,
      message: "Select A Target Editing Option Dimension.",
      type: "TargetEditing",
    },
    // {
    //   field: ScoreRounding,
    //   message: "Enter A Value For Score Rounding In Dimension.",
    //   type: "ScoreRounding",
    // },
  ]);
  if (result.type === "error") return result;
  payload = {
    ...payload,
    ...result.payload,
    PlanDimensionWeight: PlanDimensionWeight || "",
    DimensionAggregateScore: DimensionAggregateScore || "D",
    RoundingValue: RoundingValue || "",
    DimensionActivities: DimensionActivities || [],
  };
  if (DimensionAggregateScore === "E") {
    if (!AggregationRating || AggregationRating === -1)
      return throwError("Select A Aggregation Rating For Dimension.");
    payload = {
      ...payload,
      ScoreRounding: ScoreRounding || "",
      DimensionAggregateScore: "E",
      AggregationRating,
    };
  }
  if (ItemNumber) payload.ItemNumber = ItemNumber;
  return {
    type: "success",
    payload,
  };
}
function ValidateSelfDev(data) {
  if (!data || data?.SelfDevSection !== "E")
    return {
      type: "success",
      payload: {
        SelfDevSection: "D",
        SelfDevSectionLabel: "",
        SelfDevSectionDescription: "",
        SelfDevDataColumns: [],
      },
    };
  let { SelfDevSectionLabel, SelfDevSectionDescription, SelfDevDataColumns } =
    data;
  let payload = {
    SelfDevSectionDescription: SelfDevSectionDescription || "",
    SelfDevSectionLabel: SelfDevSectionLabel || "",
  };
  if (!SelfDevDataColumns)
    return throwError("No Columns Selected In Self Development Section");
  if (SelfDevDataColumns.length === 0)
    return throwError("No Columns Selected In Self Development Section");
  SelfDevDataColumns = SelfDevDataColumns.map((ele) => ({ ID: ele }));
  return {
    type: "success",
    payload: {
      ...payload,
      SelfDevSection: "E",
      SelfDevDataColumns,
    },
  };
}
export const validate = (state) => {
  let validResult;
  let payload = {};
  let genPayload = ValidateGeneral(state["MPP"]);
  if (genPayload.type === "error") return genPayload;

  let Coaching = ValidateCoaching(state["MPP"]);
  if (Coaching.type === "error") return Coaching;

  let selfDev = ValidateSelfDev(state["MPP"]);
  if (selfDev.type === "error") return selfDev;

  let trainingNedds = ValidateTrainingNeeds(state["MPP"]);
  if (trainingNedds.type === "error") return trainingNedds;

  let confi = ValidateConfi(state["MPP"]);
  if (confi.type === "error") return confi;

  let summary = ValidateSummar(state["MPP"]);
  if (summary.type === "error") return summary;

  let event = ValidateEvents(state);
  if (event.type === "error") return event;

  let enabledDim = store.getState().DataReducer?.Mpp?.enabledDim;
  let arr = [];
  console.log("before dim: ", enabledDim);
  if (enabledDim && enabledDim.length > 0) {
    for (let i = 0; i < enabledDim.length; i++) {
      let { id, name } = enabledDim[i];
      if (id === "") continue;
      let result = ValidateDim(state[id], id);
      console.log("id is: ", id, " ", state[id], " ", result);
      if (result.type === "error") return result;
      arr.push(result.payload);
    }
  } else {
    // arr.push(
    //   DimensionID: "",
    //   AggregateScoreRatingScaleID: "",
    //   PlanDimensionWeight: "",
    //   TargetEditing: "",
    //   DimensionAggregateScore: "",
    //   AggregationRating: "",
    //   ScoreRounding: "",
    //   RoundingValue: "",
    // });
  }
  // payload = {
  //   ...genPayload.payload,
  //   CoachingSection: Coaching.payload,
  //   SelfDevelopmentSection: selfDev.payload,
  //   TrainingNeedsSection: trainingNedds.payload,
  //   ConfidentialSections: confi.payload,
  //   SummarySections: summary.payload,
  //   Dimension: arr,
  //   ...event.payload,
  // };

  const {
    // InterimReviews,
    // PeriodicReviews,
    MultiRaters,
    ProgressTracking,
    GoalSettings,
  } = event.payload;
  payload = {
    ...genPayload.payload,
    ...Coaching.payload,
    ...selfDev.payload,
    ...trainingNedds.payload,
    ...confi.payload,
    ...summary.payload,
    Dimension: arr,
    // ...InterimReviews,
    // ...PeriodicReviews,
    ...MultiRaters,
    ...ProgressTracking,
    ...GoalSettings,
  };
  console.log("Final Payload: ", JSON.stringify(payload));
  //return throwError("Test")
  let edit = store.getState().DataReducer?.Mpp?.editMode;
  console.log("Edit is ", edit, " ", store.getState().DataReducer.MPP);

  return {
    type: "success",
    payload,
    edit,
  };
};
