export const Groups = [
  {
    DocumentID: 1,
    DimensionID: 1,
    GroupLevel: 2,
    GroupName: "Financial",
    GroupShortName: "fintech",
    GroupDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras est turpis, auctor a 
        enim non, ullamcorper commodo turpis. Suspendisse efficitur metus non nunc vulputate, vel feugiat 
        metus egestas. Nunc ornare mauris et dignissim lobortis. Donec tempor diam ut elit ultrices, sed 
        pulvinar ex consectetur.`,
  },
  {
    DocumentID: 2,
    DimensionID: 1,
    GroupLevel: 2,
    GroupName: "Marketing",
    GroupShortName: "fishing",
    GroupDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras est turpis, auctor a 
        enim non, ullamcorper commodo turpis. Suspendisse efficitur metus non nunc vulputate, vel feugiat 
        metus egestas. Nunc ornare mauris et dignissim lobortis. Donec tempor diam ut elit ultrices, sed 
        pulvinar ex consectetur.`,
  },
  {
    DocumentID: 3,
    DimensionID: 1,
    GroupLevel: 1,
    group2Id: 1,
    GroupName: "Product Development",
    GroupShortName: "surfing",
    groupDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras est turpis, auctor a 
        enim non, ullamcorper commodo turpis. Suspendisse efficitur metus non nunc vulputate, vel feugiat 
        metus egestas. Nunc ornare mauris et dignissim lobortis. Donec tempor diam ut elit ultrices, sed 
        pulvinar ex consectetur.`,
  },
  {
    DocumentID: 4,
    DimensionID: 1,
    GroupLevel: 1,
    group2Id: 1,
    GroupName: "Frontend Building",
    GroupShortName: "UI/UX",
    groupDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras est turpis, auctor a 
        enim non, ullamcorper commodo turpis. Suspendisse efficitur metus non nunc vulputate, vel feugiat 
        metus egestas. Nunc ornare mauris et dignissim lobortis. Donec tempor diam ut elit ultrices, sed 
        pulvinar ex consectetur.`,
  },
  {
    DocumentID: 5,
    DimensionID: 1,
    GroupLevel: 1,
    group2Id: 2,
    GroupName: "Sales",
    GroupShortName: "buying",
    groupDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras est turpis, auctor a 
        enim non, ullamcorper commodo turpis. Suspendisse efficitur metus non nunc vulputate, vel feugiat 
        metus egestas. Nunc ornare mauris et dignissim lobortis. Donec tempor diam ut elit ultrices, sed 
        pulvinar ex consectetur.`,
  },
  {
    DocumentID: 6,
    DimensionID: 1,
    GroupLevel: 1,
    group2Id: 0,
    GroupName: "Designing",
    GroupShortName: "figmas",
    groupDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras est turpis, auctor a 
        enim non, ullamcorper commodo turpis. Suspendisse efficitur metus non nunc vulputate, vel feugiat 
        metus egestas. Nunc ornare mauris et dignissim lobortis. Donec tempor diam ut elit ultrices, sed 
        pulvinar ex consectetur.`,
  },
];
