import React, { useState } from "react";
import { Divider, Typography } from "@mui/material";
import Molecule from "../../../../MComponent/Molecule";
import Atomic from "../../../../AComponent/Atomic";
import { isDisabled, isEnabled } from "../Helpers/helper";
import AnchoredRatingScale from "./Scales/AnchoredRatingScale";
import FixedValue from "./Scales/FixedValue";
import ExpectedRatingScale from "./Scales/ExpectedRatingScale";
import store from "../../../../Redux/Store";

const { Button } = Atomic;

const { EwModal } = Molecule;

function GoalSettingModal({
  ParameterTargets,
  MappedTargets,
  TargetsEnabled,
  TargetEditing,
  TargetMapping,
  TargetType,
  TargetDataType,
  RatingScaleID,
  Workflow,
  ActionStatusLog,
  onSave,
  closeForm,
}) {
  function onSaveForm(payload) {
    onSave(payload);
  }
  const userId = store.getState().AuthReducer.userId;
  let canEdit = false;
  if (ActionStatusLog === "PTI") {
    if (TargetMapping === "U") {
      canEdit = true;
    } else if (TargetEditing === "P") {
      canEdit = true;
    }
  } else if (ActionStatusLog === "PTC" && TargetEditing === "P") {
    canEdit = true;
  }
  // console.log("USER ID: ", userId, " ", store.getState().AuthReducer);
  console.log(canEdit, "TARGET TYPE");
  return (
    <div
      className="scroll"
      style={{ gap: "1em", width: "75vw", height: "70vh", padding: "1em" }}
    >
      <Typography variant="h5"> Set Goals </Typography>
      <Divider sx={{ margin: "1.5em 0" }} />
      {isEnabled(TargetsEnabled) ? (
        <>
          {(TargetType === "AR" ||
            TargetType === "RG" ||
            TargetType === "CR") && (
            <AnchoredRatingScale
              periodTargets={ParameterTargets}
              mapRule={TargetMapping}
              mapTargets={MappedTargets}
              editable={canEdit}
              parameterTargetDataType={TargetDataType}
              RatingScaleID={RatingScaleID}
              Workflow={Workflow}
              ActionStatusLog={ActionStatusLog}
              CurrentUserID={userId}
              closeForm={closeForm}
              saveData={onSaveForm}
            />
          )}
          {TargetType === "EV" && (
            <ExpectedRatingScale
              periodTargets={ParameterTargets}
              mapRule={TargetMapping}
              mapTargets={MappedTargets}
              editable={canEdit}
              parameterTargetDataType={TargetDataType}
              RatingScaleID={RatingScaleID}
              Workflow={Workflow}
              ActionStatusLog={ActionStatusLog}
              closeForm={closeForm}
              saveData={onSaveForm}
            />
          )}
          {TargetType === "FV" && (
            <FixedValue
              editable={canEdit}
              parameterTargetDataType={TargetDataType}
              periodTargets={ParameterTargets}
              mapRule={TargetMapping}
              mapTargets={MappedTargets}
              Workflow={Workflow}
              ActionStatusLog={ActionStatusLog}
              closeForm={closeForm}
              saveData={onSaveForm}
            />
          )}
        </>
      ) : (
        <Typography variant="h6">No targets defined</Typography>
      )}
      {isDisabled(TargetsEnabled) && (
        <div className="Flex">
          <Typography variant="h6">No targets defined</Typography>
        </div>
      )}
    </div>
  );
}

function GoalSetting({
  ParameterTargets,
  MappedTargets,
  TargetsEnabled,
  TargetEditing,
  TargetMapping,
  TargetType,
  TargetDataType,
  ActionStatusLog,
  Workflow,
  RatingScaleID,
  onSave,
}) {
  const [modal, setModal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <div style={{ margin: "1em 0" }}>
        {isEnabled(TargetsEnabled) && (
          <Button
            title={"Set Goals"}
            onClick={() => {
              setModal({
                Component: GoalSettingModal,
                props: {
                  ParameterTargets,
                  MappedTargets,
                  TargetsEnabled,
                  TargetEditing,
                  TargetMapping,
                  Workflow,
                  ActionStatusLog,
                  TargetType,
                  TargetDataType,
                  RatingScaleID,
                  onSave,
                  closeForm: () => setModalOpen(false),
                },
              });
              setModalOpen(true);
            }}
          />
        )}
      </div>
      {modal && (
        <EwModal onClose={() => setModalOpen(false)} open={modalOpen}>
          <modal.Component {...modal.props} />
        </EwModal>
      )}
    </div>
  );
}

export default GoalSetting;
