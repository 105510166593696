import React, { useState, useEffect } from "react";
import Atomic from "../../../AComponent/Atomic";
import { getData } from "../../../Utils/Common/Library";
import Molecule from "../../../MComponent/Molecule";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../Utils/CustomHooks/WindowRezise";
const { Input, Button, EwSwitch } = Atomic;
const { Table, EwModal } = Molecule;
const id = "MPP";
const DimensionFeatureId = "19144";

const idTolabelMap = {
  SnapShotWidgetName: "Name",
  SnapShotWidgetEmployee: "Employee",
  SnapShotWidgetRole: "Role",
  SnapShotWidgetDesignation: "Designation",
  SnapShotWidgetFunction: "Function",
  SnapShotWidgetLocation: "Location",
  SnapShotWidgetGrade: "Grade",
  SnapShotWidgetEmployeeType: "Employee Type",
};

function Summary2() {
  const obj = useSelector((state) => {
    if (!state.DataReducer[id])
      return {
        SnapShotWidgetName: "D",
        SnapShotWidgetEmployee: "D",
        SnapShotWidgetRole: "D",
        SnapShotWidgetDesignation: "D",
        SnapShotWidgetFunction: "D",
        SnapShotWidgetLocation: "D",
        SnapShotWidgetGrade: "D",
        SnapShotWidgetEmployeeType: "D",
      };
    return state.DataReducer[id];
  });
  const {
    SummarySection,
    SummaryLabel,
    SummaryDescription,
    SummaryDimensionScores,
    SummaryPlanAggregateScoreWidget,
    SummaryPlanAggregateScore,
    SummaryPlanModerateScore,
    SummaryMultiRaterScoreWidget,
    SummaryCommentsWidget,
    SummaryAppraiseeSnapShotWidget,

    SummaryLeaveWidget,
    SumDimList,
  } = obj;
  const { width } = useWindowDimensions();
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (SummaryDimensionScores && SummaryDimensionScores instanceof Array) {
      let temp = {};
      SummaryDimensionScores.forEach((ele) => {
        temp[ele.DimensionID] = ele;
      });
      updateState(temp, "SummaryDimensionScores");
    }
  }, []);
  useEffect(() => {
    if (SummarySection === "E" && !SumDimList) {
      setLoading(true);
      getData({ featureId: DimensionFeatureId })
        .then((res) => {
          if (res && res.Data) {
            updateState(res.Data, "SumDimList");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [SummarySection]);
  function getTotalList(temp) {
    console.log("Inside Table: ",temp)
    let num = 0;
    Object.keys(temp).forEach((ele) => {
      if (temp[ele] === "E") num++;
    });
    return num;
  }
  function Edit({ id, name }) {
    return (
      <>
        <Button
        
       
          title={"Enable Scores"}
          onClick={() => setModal({ id, name })}
        />
      </>
    );
  }
  let enabledDim = [
    {
      name: "Dim1",
      id: "id1",
    },
    {
      name: "Dim1",
      id: "id2",
    },
  ];
  let rows = SumDimList?.map(({ DimensionName, TransactionID }, index) => [
    index + 1,
    DimensionName,
    { Component: Edit, props: { id: TransactionID, name: DimensionName } },
    // SummaryDimensionScores && SummaryDimensionScores[id]
    //   ? getTotalList(SummaryDimensionScores[id])
    //   : "0",
  ]);

  const dispatch = useDispatch();
  function updateState(val, type) {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: type,
        data: val,
      },
    });
  }
  function ScoreModal() {
    const defaultVal = {
      PastTwoCycleAggregateScore: "D",
      CurrentCycleAggregateScore: "D",
      PastTwoCycleModeratedScore: "D",
      CurrentCycleModeratedScore: "D",
    };
    const labelMap = {
      PastTwoCycleAggregateScore: "Past Two Cycle Aggregate Score",
      CurrentCycleAggregateScore: "Current Cycle Aggregate Score",
      PastTwoCycleModeratedScore: "Past Two Cycle Moderated Score",
      CurrentCycleModeratedScore: "Current Cycle Moderated Score",
    };
    const [scores, setScores] = useState(
      SummaryDimensionScores && SummaryDimensionScores[modal.id]
        ? SummaryDimensionScores[modal.id]
        : defaultVal
    );
    if (!modal) return;
    return (
      <div style={{ width: 0.5 * width }}>
        <h3>{modal.name}</h3>
        <hr />
        <br />
        <div className="Flex">
          <div>
            {Object.keys(scores).map((ele) => (
              <>
                {console.log("Scores: ele", ele)}
                {!["DimensionID", "ItemNumber"].includes(ele) && (
                  <EwSwitch
                    label={labelMap[ele]}
                    value={scores[ele] === "E"}
                    onChange={(val) =>
                      setScores((prev) => ({ ...prev, [ele]: val ? "E" : "D" }))
                    }
                  />
                )}
              </>
            ))}
          </div>
        </div>
        <br />
        <div className="Flex">
          <Button
            title={"Save"}
            onClick={() => {
              let dim = SummaryDimensionScores;
              if (!dim) dim = {};
              dim[modal.id] = scores;
              updateState(dim, "SummaryDimensionScores");
              setModal(null);
            }}
          />
        </div>
      </div>
    );
  }
  if (loading) return <>Loading..</>;
  return (
    <div>
      <EwModal open={modal} onClose={() => setModal(null)}>
        <ScoreModal />
      </EwModal>
      <div className="Flex">
        <EwSwitch
          label="Summary"
          value={SummarySection === "E"}
          onChange={(val) => updateState(val ? "E" : "D", "SummarySection")}
        />
      </div>
      {SummarySection === "E" && (
        <>
          <div className="Flex">
            <div>
              <Input
                label={"Label"}
                value={SummaryLabel}
                onChange={(val) => updateState(val, "SummaryLabel")}
              />
            </div>
            <div>
              <Input
                label={"Description"}
                value={SummaryDescription}
                onChange={(val) => updateState(val, "SummaryDescription")}
                rows={4}
              />
            </div>
          </div>
          <br />
          <div>
            <div
              className="scroll"
              style={{ maxHeight: "80vh", overflowY: "auto" }}
            >
              <Table
                heading={[
                  "S.No",
                  "Dimension Name",
                  "Scores Widget",
                  // "Widgets Enabled",
                ]}
                rows={rows}
              />
            </div>
            <br />
          </div>
          <div className="Flex">
            <div>
              {/* <EwSwitch
                label="Summary Plan Aggregate ScoreWidget"
                value={SummaryPlanAggregateScoreWidget === "E"}
                onChange={(val) =>
                  updateState(
                    val ? "E" : "D",
                    "SummaryPlanAggregateScoreWidget"
                  )
                }
              /> */}
              <h3>Summary Plan Aggregate ScoreWidget</h3>
            </div>

            <>
              <div>
                <EwSwitch
                  label="Summary Plan Aggregate Score "
                  value={SummaryPlanAggregateScore === "E"}
                  onChange={(val) =>
                    updateState(val ? "E" : "D", "SummaryPlanAggregateScore")
                  }
                />
                <EwSwitch
                  label="Summary Plan Moderate Score"
                  value={SummaryPlanModerateScore === "E"}
                  onChange={(val) =>
                    updateState(val ? "E" : "D", "SummaryPlanModerateScore")
                  }
                />
              </div>
            </>
          </div>
          <br />
          <div>
            <EwSwitch
              label="Summary Multi Rater Score Widget"
              value={SummaryMultiRaterScoreWidget === "E"}
              onChange={(val) =>
                updateState(val ? "E" : "D", "SummaryMultiRaterScoreWidget")
              }
            />
            <EwSwitch
              label="Summary Comments Widget"
              value={SummaryCommentsWidget === "E"}
              onChange={(val) =>
                updateState(val ? "E" : "D", "SummaryCommentsWidget")
              }
            />
          </div>
          <br />
          <div className="Flex-row" style={{ alignItems: "flex-start" }}>
            <div className="Flex">
              <div>
                {/* <EwSwitch
                  label="Appraisee Snapshot Widget"
                  value={SummaryAppraiseeSnapShotWidget}
                  onChange={(val) =>
                    updateState(
                      val ? {} : null,
                      "SummaryAppraiseeSnapShotWidget"
                    )
                  }
                /> */}
                <h3>Appraisee Snapshot Widget</h3>
              </div>
              <br />

              <div>
                {[
                  "SnapShotWidgetName",
                  "SnapShotWidgetEmployee",
                  "SnapShotWidgetRole",
                  "SnapShotWidgetDesignation",
                  "SnapShotWidgetFunction",
                  "SnapShotWidgetLocation",
                  "SnapShotWidgetGrade",
                  "SnapShotWidgetEmployeeType",
                ].map((ele) => {
                  return (
                    <EwSwitch
                      label={idTolabelMap[ele]}
                      value={obj[ele] === "E"}
                      onChange={(val) => {
                        updateState(val ? "E" : "D", ele);
                      }}
                    />
                  );
                })}
              </div>

              <br />
            </div>

            <div className="Flex">
              <div>
                {/* <EwSwitch
                  label="Leave & AttendanceWidget"
                  value={SummaryLeaveWidget}
                  onChange={(val) =>
                    updateState(val ? {} : null, "SummaryLeaveWidget")
                  }
                /> */}
                <h3>Leave And Attendance Widget</h3>
              </div>
              <br />

              <div>
                {[
                  {
                    label: "Days Worked",
                    value: "AttendanceWidgetDaysWorked",
                  },
                  {
                    label: "Punctuality Record (Days on time over total days)",
                    value: "AttendanceWidgetPunctualityRecord",
                  },
                  {
                    label:
                      "Punctuality (Average minutes late across days when late)",
                    value: "AttendanceWidgetPunctuality",
                  },
                  {
                    label: "Hours worked over total shift hours",
                    value: "AttendanceWidgetHours",
                  },
                ].map(({ label, value }) => (
                  <EwSwitch
                    label={label}
                    value={obj[value] === "E"}
                    onChange={(val) => {
                      updateState(val ? "E" : "D", value);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Summary2;
