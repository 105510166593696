import React, { useState } from "react";
import { Paper } from "@mui/material";
import EwTabs from "../../../MComponent/Tabs/Tabs";
import useWindowDimensions from "../../../Utils/CustomHooks/WindowRezise";
import { useDispatch, useSelector } from "react-redux";
import EwAccordion from "../../../MComponent/Accordion/Accordion";
import Atomic from "../../../AComponent/Atomic";
import Divider from "@mui/material/Divider";
//import EwTabs from "../../../MComponent/Tabs/Tabs";
const { EwSwitch, Input, Dropdown, Radio, Button } = Atomic;

const id = "dimensions";

function Dimensions({ name }) {
  const [dimension, setDimension] = useState({});
  const { height, width } = useWindowDimensions();
  const dispatch = useDispatch();
  function updateState(type, value) {
    setDimension((prev) => ({ ...prev, [type]: value }));
  }
  function MapsSettings() {
    console.log("from mapconfig ", updateState);
    const { maptype } = dimension;
    const options = [
      {
        label: "yes",
        value: 1,
      },
      {
        label: "No",
        value: 2,
      },
    ];
    function RenderMapConfig() {
      let prevState = useSelector((state) => {
        if (state.DataReducer[id]?.mapConfig)
          return state.DataReducer[id].mapConfig;
        return {};
      });
      const [mapConfig, setMapConfig] = useState(prevState ? prevState : {});

      const {
        paraGroup,
        groupPara,
        secondGroupType,
        firstGroupType,
        paramterType,
        enableWeights,
        minParamWeight,
        maxParamWeights,
        ratingScale,
        commentAbove,
        commentBelow,
        dimensionScoreEnabled,
        aggrating,
      } = mapConfig;
      const options = [
        {
          label: "Enable",
          value: 0,
        },
        {
          label: "Disable",
          value: 1,
        },
      ];
      const userAddOptions = [
        {
          label: "Cannot Add",
          value: 1,
        },
        {
          label: "Can Add From Library",
          value: 2,
        },
        {
          label: "Can Add Their Own",
          value: 3,
        },
      ];
      function changeAddGroupSettings(val, type) {
        setMapConfig((prev) => ({ ...prev, [type]: val }));

        dispatch({
          type: "UPDATE_PROPS",
          payload: {
            id,
            componentNumber: "mapConfig",
            data: { ...mapConfig, [type]: val },
          },
        });
      }
      return (
        <>
          <hr style={{ width: "100%" }} />
          <div className="Flex-row" style={{ gap: 30 }}>
            <div>
              <EwSwitch
                label={"Parameters Grouping"}
                value={paraGroup}
                onChange={(val) => {
                  changeAddGroupSettings(val, "paraGroup");
                  changeAddGroupSettings(false, "groupPara");
                  changeAddGroupSettings(undefined, "firstGroupType");
                }}
              />
            </div>
            {paraGroup && (
              <div>
                <EwSwitch
                  label="Grouping of Parameter Groups"
                  value={groupPara}
                  onChange={(val) => {
                    changeAddGroupSettings(val, "groupPara");
                    changeAddGroupSettings(undefined, "secondGroupType");
                  }}
                />
              </div>
            )}
          </div>
          <br />
          <div
            className="Flex-row"
            style={{ width: "100%", justifyContent: "flex-start" }}
          >
            {groupPara && (
              <div style={{ borderRight: "1px solid black", marginRight: 15 }}>
                <Radio
                  label={"Second Level Group"}
                  menuItems={userAddOptions}
                  value={secondGroupType}
                  onChange={(val) =>
                    changeAddGroupSettings(val, "secondGroupType")
                  }
                />
                <div></div>
              </div>
            )}

            {paraGroup && (
              <div style={{ borderRight: "1px solid black", marginRight: 15 }}>
                <Radio
                  label={"First Level Group"}
                  menuItems={userAddOptions}
                  value={firstGroupType}
                  onChange={(val) =>
                    changeAddGroupSettings(val, "firstGroupType")
                  }
                />
                <div></div>
              </div>
            )}

            <div style={{ borderRight: "1px solid black", marginRight: 15 }}>
              <Radio
                label={"parameter"}
                menuItems={userAddOptions}
                value={paramterType}
                onChange={(val) => changeAddGroupSettings(val, "paramterType")}
              />
              <div></div>
            </div>
          </div>
          <br />
          {console.log("Enabled weigths: ", enableWeights)}
          <div className="Flex">
            <EwSwitch
              label="Weights"
              value={enableWeights}
              onChange={(val) => {
                changeAddGroupSettings(val, "enableWeights");
              }}
            />
          </div>
          {enableWeights && (
            <div style={{ width: "100%" }}>
              <div className="Flex-row">
                <div>
                  <Input
                    label={"Minimum Parameter Weight"}
                    value={minParamWeight}
                    onChange={(val) =>
                      changeAddGroupSettings(val, "minParamWeight")
                    }
                  />
                </div>
                <div>
                  <Input
                    label={"maximum Parameter Weight"}
                    value={maxParamWeights}
                    onChange={(val) =>
                      changeAddGroupSettings(val, "maxParamWeights")
                    }
                  />
                </div>
              </div>
              <h3>Dimension Rating</h3>
              <br />
              <div className="Flex-row">
                <div style={{ width: "40%" }}>
                  <Dropdown
                    label={"Select Rating Scale"}
                    menuItems={["Rating1", "rating2"]}
                    value={ratingScale}
                    onChange={(val) =>
                      changeAddGroupSettings(val, "ratingScale")
                    }
                  />
                </div>
                <div>
                  <Input
                    label={"Comments are required if rating is below"}
                    value={commentBelow}
                    onChange={(val) =>
                      changeAddGroupSettings(val, "commentBelow")
                    }
                  />
                </div>
                <div>
                  <Input
                    label={"Comments are required if rating is Above"}
                    value={commentAbove}
                    onChange={(val) =>
                      changeAddGroupSettings(val, "commentAbove")
                    }
                  />
                </div>
              </div>
              <br />
              <div>
                <h3>Dimension Scoring</h3>
                <br />

                <div>
                  <Radio
                    flex={1}
                    label={"Enable Dimension Score?"}
                    menuItems={[
                      {
                        label: "Yes",
                        value: 1,
                      },
                      {
                        label: "No",
                        value: 2,
                      },
                    ]}
                    value={dimensionScoreEnabled}
                    onChange={(val) =>
                      changeAddGroupSettings(val, "dimensionScoreEnabled")
                    }
                  />
                </div>
                {dimensionScoreEnabled === "1" && (
                  <div>
                    <div className="Flex-row">
                      <div style={{ width: "40%" }}>
                        <Dropdown
                          label={"Aggregation Rating"}
                          menuItems={[
                            "Appraiser",
                            "Reviewer",
                            "Appraisee",
                            "Average",
                          ]}
                          value={aggrating}
                          onChange={(val) =>
                            changeAddGroupSettings(val, "aggrating")
                          }
                        />
                      </div>
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      );
    }

    function mapValueChange(val) {
      setDimension((prev) => ({ ...prev, maptype: val }));
    }
    return (
      <>
        <div className="Flex" style={{ alignItems: "flex-start" }}>
          <Radio
            label={"Inherit From Parent ?"}
            menuItems={options}
            onChange={mapValueChange}
            value={maptype}
            flex={1}
          />

          {maptype === "2" && <RenderMapConfig />}
        </div>
      </>
    );
  }
  function TargetParameter() {
    const [targetSettings, setTargetSettings] = useState({});
    const {
      targetEnabled,
      commonTargetType,
      ratingScale,
      targetApproval,
      targetSettingEvent,
      xdaysBefore,
      xdaysAfter,
      targetEdit,
    } = targetSettings;
    function updateTargetSettings(val, type) {
      setTargetSettings((prev) => ({ ...prev, [type]: val }));
    }
    const targetType = [
      {
        label: "Single Value",
        value: "SV",
      },
      {
        label: "Anchored to Rating Scale Value",
        value: "AR",
      },
      {
        label:
          " Anchored to rating scale targets where achievement is entered and rating computed against target ranges",
        value: "CR",
      },
      {
        label: "Rating Guide",
        value: "RG",
      },
    ];
    const approval = [
      {
        label: "Set by appraiser and accepted by appraisee",
        value: "1",
      },
      {
        label: "Set by appraisee and accepted by appraiser ",
        value: "2",
      },
      {
        label:
          "Set by appraiser, approved by reviewer and accepted by appraisee",
        value: "3",
      },
      {
        label: "Set by appraisee, accepted by appraiser and then by reviewer",
        value: "4",
      },
    ];
    const targetevent = [
      {
        label: "Targets are set for all periods at the start of the plan cycle",
        value: "1",
      },
      {
        label: "Targets for each period are at the start of each period ",
        value: "2",
      },
      {
        label: "Target setting will be initiated by HR",
        value: "3",
      },
      {
        label: "Target setting happens automatically",
        value: "4",
      },
    ];
    const targetEditing = [
      {
        label: "Targets cannot be edited after they are set once",
        value: "1",
      },
      {
        label:
          "Parameters and/or Targets may be changed before a review is due",
        value: "2",
      },
      {
        label:
          "Parameters and/or Targets must be redefined when an Appraiser changes",
        value: "3",
      },
    ];
    return (
      <>
        <div>
          {!targetEnabled ? (
            <div className="Flex">
              <Button
                title={"Enable Target"}
                onClick={() => updateTargetSettings(true, "targetEnabled")}
              />
            </div>
          ) : (
            <div>
              <div className="Flex-row">
                <div
                  className="Flex-row"
                  style={{ width: "40%", justifyContent: "space-around" }}
                >
                  <div style={{ width: "40%" }}>
                    <Dropdown
                      label={"Common Target Type"}
                      menuItems={targetType}
                      value={commonTargetType}
                      onChange={(val) =>
                        updateTargetSettings(val, "commonTargetType")
                      }
                    />
                  </div>
                  <div style={{ width: "40%" }}>
                    {commonTargetType === "RG" && (
                      <Dropdown
                        label={"Rating Scale"}
                        menuItems={["Rating 1"]}
                        value={ratingScale}
                        onChange={(val) =>
                          updateTargetSettings(val, "ratingScale")
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="Flex">
                  <div style={{ width: "40%" }}>
                    <Dropdown
                      menuItems={approval}
                      label="Target setting and approval"
                      value={targetApproval}
                      onChange={(val) =>
                        updateTargetSettings(val, "targetApproval")
                      }
                    />
                  </div>
                </div>
              </div>
              <h3>Target Setting</h3>
              <div className="Flex-row">
                <div style={{ width: "40%" }}>
                  <Dropdown
                    value={targetSettingEvent}
                    menuItems={targetevent}
                    label="Target setting events"
                    onChange={(val) =>
                      updateTargetSettings(val, "targetSettingEvent")
                    }
                  />
                </div>
                {targetSettingEvent === "4" && (
                  <div>
                    <div>
                      <Input
                        label={"Initiate X days before period starts"}
                        value={xdaysBefore}
                        onChange={(val) =>
                          updateTargetSettings(val, "xdaysBefore")
                        }
                      />
                    </div>
                    <div>
                      <Input
                        label={"Initiate X days After period starts"}
                        value={xdaysAfter}
                        onChange={(val) =>
                          updateTargetSettings(val, "xdaysAfter")
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
              <h3>Target Editing</h3>
              <div style={{ width: "40%" }}>
                <Dropdown
                  label={"Target Editing"}
                  menuItems={targetEditing}
                  value={targetEdit}
                  onChange={(val) => updateTargetSettings(val, "targetEdit")}
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
  function ParamterAchievement() {
    return <>ParamterAchievement</>;
  }
  function ParameterAssessments() {
    const [paramterState, setParamterState] = useState({});
    const { reviewFreq, reviewEvent, xdaysBefore, xdaysAfter } = paramterState;
    const freq = [
      {
        label: "Annual",
        value: "1",
      },
      {
        label: "half-yearly",
        value: "2",
      },
      {
        label: "Quarterly",
        value: "3",
      },
      {
        label: "Monthly",
        value: "4",
      },
    ];
    const event = [
      { label: "Reviews will be initiated by HR for each period", value: "1" },
      {
        label: "Reviews are initiated automatically",
        value: 2,
      },
    ];
    function updateState(val, type) {
      setParamterState((prev) => ({ ...prev, [type]: val }));
    }
    return (
      <>
        <div>
          <h3>Parameter Assessments</h3>
          <div>
            <div>
              <Dropdown
                menuItems={freq}
                value={reviewFreq}
                onChange={(val) => updateState(val, "reviewFreq")}
                label="Review Frequency"
              />
            </div>
            <div>
              <Radio
                menuItems={event}
                value={reviewEvent}
                onChange={(val) => updateState(val, "reviewEvent")}
                label="Review Event"
              />
            </div>
          </div>
          {reviewEvent === "2" && (
            <div>
              <div>
                <Input
                  label={"X days before"}
                  value={xdaysBefore}
                  onChange={(val) => updateState(val, "xdaysBefore")}
                />
              </div>
              <div>
                <Input
                  label={"X days After"}
                  value={xdaysAfter}
                  onChange={(val) => updateState(val, "xdaysAfter")}
                />
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
  function MultiRaterAssement() {
    return <></>;
  }
  const accordian = [
    {
      label: "Map Settings",
      Component: MapsSettings,
    },
    {
      label: "Target Settings",
      Component: TargetParameter,
    },
    {
      label: "Paramter Achievement",
      Component: ParamterAchievement,
    },
    {
      label: "Parameter Assessments",
      Component: ParameterAssessments,
    },
  ];
  return (
    <div>
      <div className="Flex">
        <h3>{name}</h3>
        <div
          className="scroll"
          style={{
            width: "100%",
            height: 500,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <EwTabs componentList={accordian} />
          {/* <EwAccordion accordians={accordian} /> */}
        </div>
      </div>
    </div>
  );
}

export default Dimensions;
