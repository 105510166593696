export const DevelopmentDocument = {
  SectionLabel: "Development",
  SectionDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
      Proin vitae risus augue. Sed feugiat turpis vel euismod laoreet. 
      Morbi magna dolor, tincidunt quis quam nec, vehicula consectetur augue.`,
  DataColumns: {
    Dimension: "E",
    SecondGroups: "E",
    FirstGroups: "E",
    Parameter: "E",
    DevelopmentArea: "D",
    DevelopmentPlan: "D",
    DevelopmentMilestone: "D",
    DevelopmentActionUpdate: "D",
    DueDate: "D",
    DevelopmentProgress: "D",
  },
};
