import React, { useState } from "react";
import Paper from '@mui/material/Paper'

function Card_2({placeholder_1,placeholder_2,Placeholder_4,Placeholder_5}){
    const [elvation,setElevation]=useState(1);
    const [Dimension,setDimension]=useState({height:180,width:380});
    const posistion=[{}]
    return(
        <>
<Paper
   elevation={elvation}
   onMouseEnter={()=>{setElevation(3)}}
   onMouseLeave={()=>{setElevation(1)}}
   style={{...Dimension}}
>

</Paper>
        </>
    )
}


export default Card_2;