import store from "../Redux/Store";



function setLayout(layout){

//  store.dispatch({
//         type:'SET',
//         payload: {},
//     })
    setTimeout(() => {
        store.dispatch({
            type:'SET',
            payload: layout,
        })  
    }, 0);
}

export default setLayout;