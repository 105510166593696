import React,{useState,useEffect} from 'react'
import {useSelector,useDispatch} from "react-redux"
import { v4 as uuid } from 'uuid'
import Shell from '../Cards/Shell_2'

function Wizard(props) {
    const {forms}=props
    const [wizardId]=useState(uuid());
    const [shellId]=useState(uuid());

    useEffect(()=>{
        
    })

    return (
        <div>
            {forms.map((ele,index)=>{
                return <Shell {...ele} wizard={{wizardId,shellId,index}} />
            })}
        </div>
    )
}

export default Wizard
