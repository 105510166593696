import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";

function PeriodAccordian({ children, PeriodName }) {
  const [open, setOpen] = useState(true);

  return (
    <Box sx={{ width: "100%", margin: "" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.6em 0.3em 0.6em 0em",
          backgroundColor: "#e8e9eb",
          borderBottom: "1px solid #979797",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            sx={{ marginRight: "0.5em" }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {!open && <ExpandMore />}
            {open && <ExpandLess />}
          </IconButton>
          <Typography>{PeriodName}</Typography>
        </Box>
      </Box>
      {open && (
        <Box
          sx={{
            borderTop: 0,
            backgroundColor: "#f7f7f7",
            margin: "0 auto",
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

export default PeriodAccordian;
