import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import Preference from "./preference";
import { Grid, Typography } from "@mui/material";

const Username = (props) => {
    const name = props.props.Name;
    const preferences = props.props.Preferences;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
  
    return(<div> 
        <Typography variant = "p" style={{color: "black"}}>
          {name}<span><ExpandMoreIcon color="action" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style = {{paddingTop:5}}/></span>
        </Typography>
        <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {preferences.map((option)=> <Preference {...option} onClick = {handleClose}/>)}
      </Menu>
        </div>);
};

export default Username;