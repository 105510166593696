import React, { useState, useEffect } from 'react'
import { Paper, Modal, TextField, Button } from '@mui/material'
import Gateway from '../../Utils/Api/Gateway'
import axios from 'axios'

const url = 'https://ifsc.razorpay.com/'
const data={
    "BRANCH": "Delhi Nagrik Sehkari Bank IMPS",
    "CENTRE": "DELHI",
    "DISTRICT": "DELHI",
    "STATE": "MAHARASHTRA",
    "ADDRESS": "720, NEAR GHANTAGHAR, SUBZI MANDI, DELHI - 110007",
    "CONTACT": "+919560344685",
    "IMPS": true,
    "CITY": "MUMBAI",
    "UPI": true,
    "MICR": "110196002",
    "RTGS": true,
    "NEFT": true,
    "SWIFT": null,
    "ISO3166": "IN-MH",
    "BANK": "Delhi Nagrik Sehkari Bank",
    "BANKCODE": "DENS",
    "IFSC": "YESB0DNB002"
    }
function IFSC() {
  const [ifsc, setIfsc] = useState('')
  const [open, setOpen] = useState(true)
  const [bankDetails, SetBankDetails] = useState(data)
  function renderBank() {
    if (!bankDetails) return <></>
    const list = ['BRANCH', 'STATE', 'ADDRESS']
    return (
      <>
        {list.map((ele) => (
          <>
             <br />
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 0.2 }}>{ele}</div>
              <div style={{ flex: 0.2 }}>{':'}</div>
              <div style={{ flex: 0.6 }}>{bankDetails[ele]}</div>
            </div>
         
          </>
        ))}
      </>
    )
  }
  function getBank(){
    if(ifsc.trim(" ")==="")return;
    axios.get(url+ifsc).then((res)=>{
        console.log(res.data)
    })
    // Gateway({url:url+ifsc,type:'GET'}).then((res)=>{
        
    //     SetBankDetails(res)
    // })
  }
  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div className="Flex" style={{ height: '100vh',zIndex:-1 }}>
          <div>
            <Paper className='Flex' style={{ padding: 20, flex: 1,zIndex:10 }}>
              <div style={{ flex: 0.8 }}>
                {' '}
                <TextField
                  label="IFSC"
                  onChange={(e) => setIfsc(e.target.value)}
                  value={ifsc}
                />
              </div>
              <div style={{ flex: 0.2 }}>
                <Button onClick={getBank}>Submit</Button>
              </div>
              <div>{renderBank()}</div>
            </Paper>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default IFSC
