import React, { useState } from "react";
import Checkboxes from "../../../../AComponent/CheckBox/checkbox_2";
import { Box, Typography } from "@mui/material";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";

function DataChildCheckboxes({ item, selected, onChangeChildField }) {
  const [open, setOpen] = useState(true);

  return (
    <Box>
      <Box display={"flex"} gap={"0.5em"} alignItems={"center"}>
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setOpen((prevState) => !prevState);
          }}
        >
          {open && <ArrowDropDown sx={{ color: "rgba(0, 0, 0, 0.56)" }} />}
          {!open && <ArrowRight sx={{ color: "rgba(0, 0, 0, 0.56)" }} />}
        </Box>
        <Typography variant="body1">{item.label}</Typography>
      </Box>
      <Box paddingLeft={"2em"}>
        {open &&
          item.children.map((child) => {
            return (
              <Checkboxes
                label={child.label}
                value={
                  selected[item.value]?.children?.hasOwnProperty(child.value) ||
                  false
                }
                disabled={child.disabled}
                onChange={(val) => {
                  onChangeChildField({
                    checked: val,
                    item,
                    child,
                  });
                }}
                key={child.value}
              />
            );
          })}
      </Box>
    </Box>
  );
}

function DataCheckboxes({
  menuItems,
  onChangeField,
  onChangeChildField,
  selected,
}) {
  if (!menuItems) menuItems = [];

  return (
    <div>
      {menuItems.map((item) => {
        if (item.children) {
          return (
            <DataChildCheckboxes
              item={item}
              key={item.value}
              selected={selected}
              onChangeChildField={onChangeChildField}
            />
          );
        }
        return (
          <Checkboxes
            label={item.label}
            value={selected.hasOwnProperty(item.value)}
            disabled={item.disabled}
            onChange={(val) => {
              onChangeField({ checked: val, item });
            }}
            key={item.value}
          />
        );
      })}
    </div>
  );
}

export default DataCheckboxes;
