import React, { useEffect, useState } from "react";
import { getData } from "../../../../../Utils/Common/Library";
import PeriodAccordian from "../PeriodAccordian";
import { PeriodMap } from "../PeriodMap";
import { Typography } from "@mui/material";
import Atomic from "../../../../../AComponent/Atomic";
import DummyScale from "../../RatingScale.json";

const { Dropdown, Button } = Atomic;

function targetStateReducer(periodTargets, mapTargets, scale, mapRule) {
  const result = periodTargets.reduce((total, periodTarget) => {
    const targets = periodTarget.SingleTarget
      ? periodTarget.SingleTarget
      : mapTargets;

    if (!total[periodTarget.TargetPeriodType]) {
      total[periodTarget.TargetPeriodType] = {
        [periodTarget.TargetPeriodValue]: targets,
      };
    } else if (
      !total[periodTarget.TargetPeriodType][periodTarget.TargetPeriodValue]
    ) {
      total[periodTarget.TargetPeriodType][periodTarget.TargetPeriodValue] =
        targets;
    }
    return total;
  }, {});
  return result;
}

const ExpectedRatingScale = ({
  periodTargets,
  mapRule,
  mapTargets,
  editable,
  WorkflowStepUserID,
  parameterTargetDataType,
  RatingScaleID,
  closeForm,
  saveData,
}) => {
  const [scale, setScale] = useState(null);
  const [loading, setLoading] = useState(false);

  const [targets, setTargets] = useState(null);

  useEffect(() => {
    setLoading(true);
    getData({
      featureId: "19148",
      payload: { "Header.TransactionID": RatingScaleID },
    })
      .then((response) => {
        setScale(response.Data[0]);
        setTargets(
          targetStateReducer(
            periodTargets,
            mapTargets,
            response.Data[0],
            mapRule
          )
        );
      })
      .catch((error) => {
        console.log(error, "GoalSetting");
      })
      .finally(setLoading(false));
    // setScale({ ScaleValues: DummyScale });
    // setTargets(
    //   targetStateReducer(
    //     periodTargets,
    //     mapTargets,
    //     { ScaleValues: DummyScale },
    //     mapRule
    //   )
    // );
  }, [RatingScaleID, mapRule, mapTargets, periodTargets]);

  function onChangeHandler(TargetPeriodType, TargetPeriodValue, value) {
    setTargets((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState[TargetPeriodType][TargetPeriodValue] = value;
      return newState;
    });
  }

  return loading || !targets ? (
    <div className="Flex">
      <Typography>Loading your targets ...</Typography>
    </div>
  ) : (
    <div>
      {periodTargets.map((periodTarget) => {
        const SingleTarget =
          targets[periodTarget.TargetPeriodType][periodTarget.Target];
        return (
          <PeriodAccordian
            PeriodName={
              PeriodMap[periodTarget.TargetPeriodType].values[
                periodTarget.TargetPeriodValue
              ]
            }
          >
            <div className="Flex-row" style={{ gap: "0.5em" }}>
              <Typography variant="body1" fontWeight={"600"}>
                Target:{" "}
              </Typography>
              <Dropdown
                label={"Expected scale value"}
                menuItems={[
                  ...scale.ScaleValues.map((scaleValue) => ({
                    label: scaleValue.Label,
                    value: scaleValue.OptionID,
                  })),
                ]}
                disable={!editable}
                value={SingleTarget}
                onChange={(value) => {
                  onChangeHandler(
                    periodTarget.TargetPeriodType,
                    periodTarget.TargetPeriodValue,
                    value
                  );
                }}
              />
            </div>
          </PeriodAccordian>
        );
      })}
      <div className="Flex">
        {editable && (
          <Button
            title={"Save"}
            onClick={() => {
              saveData(targets);
              closeForm();
            }}
            style={{ margin: "2em" }}
          />
        )}
      </div>
    </div>
  );
};

export default ExpectedRatingScale;
