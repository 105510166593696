import React, { useState,useEffect } from 'react'
import PhoneInput, { parsePhoneNumber,isValidPhoneNumber } from 'react-phone-number-input'
import Dropdown from '../../AComponent/Dropdown/Dropdown_3'
import 'react-phone-number-input/style.css'
import Button from '@mui/material/Button'
import { fetchData } from '../../Utils/Validators/library'

const phoneType = [
  'PrimaryMobile',
  'OtherMobile',
  'Work1',
  'Work2',
  'Home1',
  'Home2',
  'EmergencyContactMobile',
  'EmergencyContactWork',
  'EmergencyContactHome',
  'EmergencyDoctorMobile',
  'EmergencyDoctorWork',
  'EmergencyDoctorHome',
];
const verification=[
    "NotRequired",
							"Unverified",
							"Verified",
							"Verification",
							"Failed",
]
function Mobile() {
  const [value, setValue] = useState('')
  const [type, setType] = useState(-1)
  const [loading,setLoading]=useState(true);
  const [reviewMode,setReviewMode]=useState(false);
  const [data,setData]=useState({
    PhoneUserID:"",
    PhoneType:-1,
    PhoneCountryID:"",
    PhoneCountryCode:"",
    AreaCode:null,
    SubscriberNumber:"",
    E164Number:"",
    MobileVerified:verification[0],
    MobileVerificationTimestamp:new Date().getTime(),
    MobileOTPVerification:"No",
    PhoneUserID:"1646904885665"
  })

  function changeType(e){
      setData(prev=>({...prev,PhoneType:e}))
  }
  function changeValue(val){
 //   var phoneObj=parsePhoneNumber(val);
    console.log(val)
    setData(prev=>({
    ...prev,
        // PhoneCountryID:phoneObj.country,
        // PhoneCountryCode:phoneObj.countryCallingCode,
        // SubscriberNumber:phoneObj.nationalNumber,
        E164Number:val,
}))
  }

useEffect(()=>{
    fetchData({
        query:"({1644570790934}!{10105,000,*,1,*,*}!{PhoneUserID=@PhoneUserID@}!{Data.PhoneType,Data.PhoneCountryID,Data.SubscriberNumber,Data.E164Number})",
        payload:{
            PhoneUserID:"1646904885665"
        }
      }).then((res)=>{
          console.log("From getda: ",res)
          if(!res){
              setReviewMode(false);
              return;
          }
          setReviewMode(true);
     setData(prev=>({
         ...prev,...res
     }))
     setLoading(false)

      }).catch((e)=>{
       // setLoading(false)
       
      })
},[])

  function submit() {
      if(!isValidPhoneNumber(data.E164Number))
      var phoneObj=parsePhoneNumber(data.E164Number);
      if(data.PhoneType===-1)return;
      var obj={
          ...data,
        PhoneUserID:"",
        PhoneCountryID:phoneObj.country,
        PhoneCountryCode:phoneObj.countryCallingCode,
        AreaCode:null,
        SubscriberNumber:phoneObj.nationalNumber,
        MobileVerified:verification[0],
        MobileVerificationTimestamp:new Date().getTime(),
        MobileOTPVerification:"No",
        PhoneUserID:"1646904885665"
      }
      let payload={
        "FeatureID" : "10105",
        "FeatureVariantID" : "000",
        "UserId":"",
        "TransactionId":"",
        "StepId":"",
        "ModuleId":"",
        "Status":"1",
        "EffectiveFromTimestamp":"",
        Payload:obj
      }
      console.log(payload)
  }
  if(loading)return<>Loading..</>
  if(reviewMode)return<>
  <br />
  <span style={{cursor:'pointer'}} onClick={()=>setReviewMode(false)}>Edit</span><br />
  <span>{"Phone number: "+data.E164Number}</span><br />
  <span>{"Phone Type: "+data.PhoneType}</span><br />

  </>
  return (
    <div  style={{}}>
        <br />
      <div className='Flex' style={{ width: '35%',flexDirection:'row',justifyContent:'space-around' }}>
        <Dropdown
          label={'Phone Type'}
          menuItems={phoneType}
          onChange={changeType}
          value={data.PhoneType}
        />
        <PhoneInput defaultCountry="IN" value={data.E164Number} onChange={changeValue} />
      </div>
      <br />
      <Button onClick={submit}>Submit</Button><br />
      <Button onClick={()=>setReviewMode(true)}>Cancel</Button>
      {/* {JSON.stringify(parsePhoneNumber(value))} */}
      {/* {JSON.stringify(data)} */}
    </div>
  )
}

export default Mobile
