// // import React from 'react';
// // import { useKeycloak } from '@react-keycloak/web'

// // function Header(){
// //     console.log("Header called")
// //     const { keycloak, initialized } = useKeycloak();
// // //  keycloak.loadUserInfo().then((res)=>{
// // //     console.log(res)
// // // })

// //    console.log("ID token ",keycloak.idTokenParsed);
// //    console.log("refresh token ",keycloak.refreshTokenParsed);
// //    console.log("Access token ",keycloak);
// // //    keycloak.loadUserInfo((data)=>{
// // //        console.log("User Info: ",data)
// // //    })

// //     return(
// //         <div className="header">
// //             Header
// //             <button onClick={()=>{keycloak.logout()}}>Logout</button>
// //             <br />
           
// //         </div>
        
// //     );
// // }

// // export default Header;

// import React, { useEffect, useState } from 'react';
// import Chip from '@mui/material/Chip';
// import { makeStyles } from '@mui/material/styles';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import CircularProgress from '@mui/material/CircularProgress';
// import URLS from "./Utils/index"
// import HeadData from "./Testjson/Header/Header.json";
// import actudalData from "./Testjson/Header/actualHeader.json";

// const {PRODUCTION}=URLS;
// const useStyles = makeStyles((theme) => ({
//     masthead : {
//         backgroundColor : "white",
//     },
//     label : {
//       color : "black",
//     },
//     blankspace : {
//       backgroundColor : "black",
//     },
//     title: {
//       display: 'none',
//       [theme.breakpoints.up('sm')]: {
//         display: 'block',
//       },
//       color : "black"
//     }
//   }));

// function Header(){

//     const classes = useStyles();
//     const [data,setData] = useState(false);
//     const [trailcontent,setContent] = useState(HeadData);
//     HeadData.Section_3.Placeholder_2.Item_1.props.Img_url=actudalData.userInfor.userProfilePic;
//     HeadData.Section_3.Placeholder_2.Item_2.props.Name=actudalData.userInfor.userName;
//     HeadData.Section_3.Placeholder_3.props.Img_url=actudalData.userInfor.userCompanyLogo;
//     useEffect(() => 
//     {    
//       setContent(HeadData);
//         // const getContent = async() => 
//         // {
//         //   let content = await getHeaderData();
//         //   console.log("From HEader: ",content)
//         //   setContent(content.data);
//         //   setData(true);
//         // }
//         // getContent();
//         setData(true);
//     },[]);
//     console.log("From HEader: ",trailcontent)
//     const section1 = trailcontent["Section_1"];
//     const section3 = trailcontent["Section_3"];
//     let Section1 = section1 !== undefined ? require("./HeaderComponents/" + section1.MComponent).default : null;
//     let Section3 = section3 !== undefined ? require("./HeaderComponents/" + section3.MComponent).default : null;
//     if(!data)
//       return <CircularProgress />
//     else
//     {
//     return (
//         <div className={classes.root} >
//           <AppBar position="static">
//             <Toolbar className = {classes.masthead}>
//             <div style = {{
//               display : 'flex',
//               width : '100%',
//               flexDirection : 'row',
//               alignItems : 'start',
//               justifyContent : 'space-between'
//             }}>
            
//               <div className='Flex-row' style={{gap:10}}>
//                 <Section1  {...section1}/>
//                 {!PRODUCTION && <Chip label="Test Server" />}
//               </div>
           
//               <div>
//                 <Section3  {...section3}/>
//               </div>
//             </div>
//             </Toolbar>
//           </AppBar>
//         </div>
//       );
//     }
//     }
    
     export default ()=><></>;