import React, { useState, useEffect, useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Chip from "@mui/material/Chip";
import {
  getTree,
  SaveApplicability,
  getData,
} from "../../Utils/Common/Library";
import Atomic from "../../AComponent/Atomic";
import Molecule from "../Molecule";
import { dimensions, payloadMapper, keyToLabelMap } from "./ApplicabilityData2";
import {
  Autocomplete,
  Box,
  ButtonBase,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import DimensionAccordiansGroup from "./ApplicabilityComponents/DimensionAccordiansGroup";
import FeatureIDs from "../../Utils/Common/FeatureIDs";
import { ArrowDropDown, ArrowRight, Close, Search } from "@mui/icons-material";
import SelectedViewAccordian from "./ApplicabilityComponents/SelectedViewAccordian";
import DimensionTypeRadioGroup from "./ApplicabilityComponents/DimensionTypeRadioGroup";
import DimensionAccordian from "./ApplicabilityComponents/DimensionAccordian";
import { makeStyles } from "@mui/material";
const { Button, Checkboxes, Radio, Input } = Atomic;
const { EwModal } = Molecule;

const dimensionsIdMap = {};
const dimensionsValueToLabelMap = {};

dimensions.forEach(({ id, value, label, children }) => {
  dimensionsIdMap[value] = id;
  dimensionsValueToLabelMap[value] = label;
  if (children) {
    children.forEach(
      ({ id: childId, value: childValue, label: childLabel }) => {
        dimensionsIdMap[childValue] = childId;
        dimensionsValueToLabelMap[childValue] = childLabel;
      }
    );
  }
});

var allToChildrenMap = {};
var idToAll = {};

const initialProps = {
  AuthorFeatureID: null,
  ParentApplicabilityID: null,
  value: null,
  onChange: null,
};

function ModalContent(props) {
  const { AuthorFeatureID, value, onChange, ApplicabilityReferenceField } =
    props;

  const [selectedEntity, setSelectedEntity] = useState({});
  const [collapse, setCollapse] = useState(new Set());
  const [loadingTree, setLoadingTree] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [loading, setLoading] = useState(null);
  const [selectList, setSelectList] = useState(null);
  const [appState, setAppState] = useState({});
  const [editMode, setEditMode] = useState(null);
  const {
    selectedDimensions,
    dimensionIdToData,
    selectedValues,
    payload,
    selectedDimensionType,
    ApplicabilityCoverage,
    EmployeeStatusApplicability,
    ConfirmationStatusApplicability,
  } = appState;

  // function setScrollPosition(val) {
  //   updateState(val, "scrollPosition");
  //   console.log("updateing: ", val);
  //   if (myDivRef.current) {
  //     myDivRef.current.scrollTop = +val;
  //   }
  // }

  function reCreateState(idToData, serverData) {
    idToAll = {};
    allToChildrenMap = {};
    let selected = {};
    const reCreateDimensions = [
      ...dimensions.reduce((total, current) => {
        total.push(current);
        if (current.children) {
          current.children.forEach((child) => {
            if (child.value !== current.value) {
              total.push(child);
            }
          });
        }
        return total;
      }, []),
    ];
    reCreateDimensions.forEach((ele) => {
      let dim = ele.value;
      // selected[dim]=new Set();
      let arr = [];
      if (!serverData) return;
      let obj = serverData[dim];

      if (!idToData[dim]) idToData[dim] = {};
      idToData[dim].any = obj.CoverageType;
      obj[payloadMapper[dim].array].forEach(({ TreeRule, ID }) => {
        if (TreeRule === "0") {
          let children = getAllChild(ID, dim, idToData);
          if (!children) children = [];
          arr = [...new Set([...arr, ...children, ID, ID + "-All"])];
          if (!allToChildrenMap[dim]) allToChildrenMap[dim] = {};
          allToChildrenMap[dim][ID + "-All"] = [...children, ID, ID + "-All"];
        } else {
          arr.push(ID);
        }
      });
      console.log("before setting: ", arr, " ", allToChildrenMap);
      selected[dim] = new Set(arr);
    });
    console.log("After coinvert: ", allToChildrenMap);
    setSelectedEntity(selected);
    updateState(idToData, "dimensionIdToData");
  }

  useEffect(() => {
    if (value) {
      setLoading("Fetching Data...");
      getData({
        featureId: FeatureIDs.Applicability,
        payload: {
          "Header.TransactionID": value,
        },
        FeatureVariantID: "002",
      }).then((res) => {
        if (res.Data) {
          setLoading("Fetching Tree...");
          fetchTree()
            .then((newData) => {
              console.log(res.Data[0], "SERVER DATA");
              reCreateState(newData, res.Data[0]);
              setEditMode({
                TransactionID: res.Data[0].TransactionID,
                EffectiveFromTimestamp: res.Data[0].EffectiveFromTimestamp,
              });
            })
            .finally(() => {
              setLoading(null);
            });
        } else {
          setLoading(null);
        }
        console.log("From app: ", res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !ApplicabilityCoverage && updateState("0", "ApplicabilityCoverage");
    !EmployeeStatusApplicability &&
      updateState("0", "EmployeeStatusApplicability");
    !ConfirmationStatusApplicability &&
      updateState([], "ConfirmationStatusApplicability");

    if (expandAll === false) {
      setCollapse(new Set());
    } else if (selectedDimensions && selectedDimensions !== -1) {
      setCollapse(new Set(Object.keys(dimensionIdToData[selectedDimensions])));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function transformData(arr) {
    let obj = {};
    for (let i = 0; i < arr.length; i++) {
      obj[arr[i].entityID] = {
        ...arr[i],
        children: new Set(),
      };
    }

    for (let i = 0; i < arr.length; i++) {
      const { parentEntityID, entityID } = arr[i];
      if (!obj[parentEntityID])
        console.log("Before transform: ", parentEntityID, " ", obj, " ", arr);
      if (parentEntityID && parentEntityID !== "" && obj[parentEntityID]) {
        obj[parentEntityID].children.add(entityID);
      }
    }

    Object.entries(obj).forEach(([key, value]) => {
      obj[key].children = [...obj[key].children];
    });
    console.log("Object before snding: ", obj);
    return obj;
  }
  function updateTreeSturucture(arr, dim) {
    if (dim) {
      let { val, res } = dim;
      let obj = dimensionIdToData;
      if (!obj) obj = {};
      let any = obj[val]?.any;
      if (!any) any = "0";
      obj = {
        ...obj,
        [val]: {
          any,
          ...transformData(res.DimensionEntities),
        },
      };
      console.log("After added: ", obj, " ");
      updateState({ ...obj }, "dimensionIdToData");
      return null;
    } else {
      console.log("Got arr: ", arr);
      let obj = {};
      arr.forEach((ele) => {
        let { Dimension, DimensionEntities } = ele;
        obj = {
          ...obj,
          [Dimension + "Applicability"]: {
            any: "0",
            ...transformData(DimensionEntities),
          },
        };
      });
      return obj;
    }
  }
  function fetchTree(val) {
    let payload = {
      function: "dimensionTree",
      FeatureID: val ? dimensionsIdMap[val] : "*",
      FeatureVariantID: val ? "000" : "*",
      DimensionID: "*",
      Direction: "B",
    };
    return new Promise((resolve, reject) => {
      getTree({ payload })
        .then((res) => {
          if (res && res.DimensionEntities) {
            console.log(JSON.stringify(res), "APPLICABLITY RESPONSE");
            updateTreeSturucture(null, { val, res });
            resolve();
          } else if (res && res.DimensionTrees) {
            let newData = updateTreeSturucture(res.DimensionTrees);
            resolve(newData);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
  function updateState(val, type) {
    console.log("In update State ", val, type);
    if (type === "selectedDimensions" && val !== -1) {
      setLoadingTree(true);
      fetchTree(val).finally(() => setLoadingTree(false));
    }
    setAppState((prev) => ({
      ...prev,
      [type]: val,
    }));
  }
  function checkBoxChanged(selected, id, EntityTree) {
    let selectedList =
      selectedValues && selectedValues[selectedDimensions]
        ? [...selectedValues[selectedDimensions]]
        : [];
    let allValues = selectedValues || {};
    let selectedPayload = payload;
    if (!selectedPayload) selectedPayload = {};
    if (!selectedPayload[selectedDimensions]) {
      selectedPayload[selectedDimensions] = {};
    }
    let list = [];
    if (["AF", "AC", "CF", "CC"].includes(EntityTree)) {
      list = getAllChild(
        id,
        selectedDimensions,
        dimensionIdToData,
        ["AF", "CF"].includes(EntityTree)
      );
    } else {
      list.push(id);
    }
    if (selected === true) {
      selectedList = [...selectedList, ...list];

      selectedPayload[selectedDimensions][id] = {
        selected: true,
        children: list,
        type: EntityTree,
      };
    } else {
      selectedList = selectedList.filter((item) => !list.includes(item));
      selectedPayload[selectedDimensions][id] = {
        selected: false,
        children: [],
        type: null,
      };
    }

    allValues[selectedDimensions] = new Set(selectedList);

    updateState(allValues, "selectedValues");
    updateState(selectedPayload, "payload");
  }

  function getAllChild(id, selecDim, data, includeSelf) {
    console.log("Before call 1", id, data, " ", selecDim);
    let children = [...data[selecDim][id].children];
    let list = [];
    let count = 0;

    while (children.length !== 0 && count < 10000) {
      count++;
      let child = children.pop();
      list.push(child);
      // if (!idToAll[child]) idToAll[child] = [id + "-All"];
      // else idToAll[child].push(id + "-All");
      let grandChildren = [];
      if (data[selecDim][child]?.children?.length > 0) {
        grandChildren = data[selecDim][child]?.children;
        ///  let allChild = child + "-All";
        list = [...list, ...getAllChild(child, selecDim, data)];
      }

      children = [...grandChildren, ...children];
    }
    if (includeSelf) list.push(id);
    return [...new Set(list)];
  }
  function CheckOptionsModal({ val, entityID, checkBoxChanged, closeModal }) {
    console.log(val, entityID, dimensionIdToData, "DATA IS HERE");
    const DimensionName = dimensionsValueToLabelMap[selectedDimensions];
    const EntityName =
      dimensionIdToData[selectedDimensions][entityID].entityName;

    const [option, setOption] = useState("EE");
    return (
      <Box position={"relative"}>
        <Box position={"absolute"} top={0} right={0}>
          <IconButton
            onClick={() => {
              closeModal();
            }}
          >
            <Close />{" "}
          </IconButton>
        </Box>
        <Typography variant="h3" color={"primary"}>
          Select {DimensionName} : {EntityName}
        </Typography>
        <br />
        <Radio
          label={
            <Typography variant="caption" fontWeight={"600"}>
              Would you like to select:
            </Typography>
          }
          value={option}
          onChange={(val) => setOption(val)}
          menuItems={[
            {
              label: (
                <Typography variant="caption">{`"${EntityName}" ${DimensionName.toLowerCase()} only`}</Typography>
              ),
              value: "EE",
            },
            {
              label: (
                <Typography variant="caption">{`All ${DimensionName.toLowerCase()}s within "${EntityName}" with current and future children.`}</Typography>
              ),
              value: "AF",
            },
            {
              label: (
                <Typography variant="caption">{`All ${DimensionName.toLowerCase()}s within "${EntityName}" with current childrens only.`}</Typography>
              ),
              value: "AC",
            },
            {
              label: (
                <Typography variant="caption">{`All ${DimensionName.toLowerCase()}s within "${EntityName}" but not the ${DimensionName.toLowerCase()} "${EntityName}" (Future Childrens Included)`}</Typography>
              ),
              value: "CF",
            },
            {
              label: (
                <Typography variant="caption">{`All ${DimensionName.toLowerCase()}s within "${EntityName}" but not the ${DimensionName.toLowerCase()} "${EntityName}" (Future Childrens NotIncluded)`}</Typography>
              ),
              value: "CC",
            },
          ]}
        />
        <br />
        <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
          <Button
            title={"Save"}
            onClick={() => {
              checkBoxChanged(true, entityID, option);
              closeModal();
            }}
          />
        </Box>
      </Box>
    );
  }
  function RenderTree(data, level, isDialogAllowed) {
    isDialogAllowed = true;
    const [modal, setModal] = useState(null);
    let newData = {};
    if (!data || Object.entries(data).length === 0) return <>No Data</>;

    if (level === 0) {
      Object.entries(data).map(([key, obj]) => {
        console.log("inside RenderTree", obj);
        if (!obj) return <></>;
        const { level, parentEntityID } = obj;
        if (level === 0 || parentEntityID === "0") {
          newData = {
            ...newData,
            [key]: data[key],
          };
        } else {
          console.log(key, obj, "KEY*OBJ");
        }
        return <></>;
      });
    } else {
      data?.forEach((ele) => {
        newData[ele] = dimensionIdToData[selectedDimensions][ele];
      });
    }
    function changeCollapseState(isExpand, id) {
      // setScrollPosition(myDivRef.current?.scrollTop);
      let tempSet = collapse;
      if (isExpand) {
        tempSet.add(id);
      } else {
        tempSet.delete(id);
      }
      setCollapse(new Set([...tempSet]));
    }

    console.log("Render tree: 1", newData, " ", dimensionIdToData);

    return (
      <div>
        <>
          {modal && isDialogAllowed && (
            <EwModal onClose={() => setModal(null)} open={modal !== null}>
              <CheckOptionsModal {...modal} />
            </EwModal>
          )}
          {Object.entries(newData).map(
            ([key, { children, entityName, entityID }]) => {
              let collapsable = children.length > 0;
              let collapsed = collapse.has(entityID);
              // let selected = selectedEntity[selectedDimensions]?.has(entityID);
              let selected = selectedValues
                ? selectedValues[selectedDimensions]?.has(entityID) || false
                : false;
              // let allId = entityID + "-All";

              console.log("Before collapse: 1", collapsed, " ", collapse);
              return (
                <div style={{ position: "relative", zIndex: "0" }}>
                  {collapsable && (
                    <>
                      {!collapsed ? (
                        <div
                          className="pointer"
                          onClick={() => changeCollapseState(true, entityID)}
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                          }}
                        >
                          <ArrowRight
                            fontSize="large"
                            sx={{ color: "rgba(0, 0, 0, 0.56)" }}
                          />
                        </div>
                      ) : (
                        <div
                          className="pointer"
                          onClick={() => changeCollapseState(false, entityID)}
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                          }}
                        >
                          <ArrowDropDown
                            fontSize="large"
                            sx={{ color: "rgba(0, 0, 0, 0.56)" }}
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      // marginLeft: collapsable && "1%",
                    }}
                  >
                    <Checkboxes
                      label={entityName}
                      value={selected}
                      onChange={(val) => {
                        if (val === true) {
                          if (
                            payload &&
                            payload[selectedDimensions] &&
                            payload[selectedDimensions][entityID]
                          ) {
                            checkBoxChanged(true, entityID, "EE");
                          } else {
                            setModal({
                              val,
                              entityID,
                              checkBoxChanged,
                              closeModal: () => setModal(null),
                            });
                          }
                        } else {
                          checkBoxChanged(false, entityID);
                        }
                        // if (isDialogAllowed) {
                        //   setModal({
                        //     val,
                        //     entityID,
                        //     checkBoxChanged,
                        //     closeModal: () => setModal(null),
                        //   });
                        // } else {
                        //   checkBoxChanged(val, entityID);
                        // }
                      }}
                    />
                  </div>
                  {collapsable && collapsed && (
                    <div style={{ marginLeft: "4em" }}>
                      <div>
                        {/* <Checkboxes
                          label={"All"}
                          value={selectedEntity[selectedDimensions]?.has(allId)}
                          onChange={(val) =>
                            checkBoxChanged(val, entityID, true)
                          }
                        /> */}
                        {RenderTree(children, level - 1, isDialogAllowed)}
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          )}
        </>
      </div>
    );
  }

  let any;
  if (
    selectedDimensions &&
    dimensionIdToData &&
    dimensionIdToData[selectedDimensions]
  ) {
    any = dimensionIdToData[selectedDimensions].any;
  }
  function anyChange(flag) {
    let data = dimensionIdToData;
    if (flag === true) {
      data[selectedDimensions].any = "0";
      setSelectedEntity((prev) => ({
        ...prev,
        [selectedDimensions]: new Set(),
      }));
    } else if (flag === false) {
      data[selectedDimensions].any = "1";
    } else {
      data[selectedDimensions].any = flag;
    }
    updateState(data, "dimensionIdToData");
  }
  function getUnique(dimesnsion) {
    let data = [...selectedEntity[dimesnsion]];
    let temp = [];
    let allId = [];
    console.log("Inunique: ", allToChildrenMap);
    if (!allToChildrenMap[dimesnsion]) return data;
    Object.entries(allToChildrenMap[dimesnsion]).forEach(([key, value]) => {
      temp = [...new Set([...temp, ...value])];
      if (value.length > 0) allId.push(key);
    });
    return [...data.filter((item) => !temp.includes(item)), ...allId];
  }
  function onSave() {
    setLoading("Saving Applicability");
    let data = dimensionIdToData;
    let selected = selectedEntity;
    let payload = {};
    let dataPayload = {
      ApplicabilityCoverage: "1",
      AuthorFeatureID,
      ParentApplicabilityID: "*",
      AuthorBusinessRuleID: "*",
      AuthorFeatureVariantID: "000",
    };
    let count = 0;
    Object.entries(selected).forEach(([key, value]) => {
      payload[key] = {};
      let any = data[key].any;
      if (any === "0") {
        payload[key] = {
          CoverageType: "0",
          [payloadMapper[key].array]: [],
        };
      } else {
        let arr = getUnique(key).map((ele) => {
          let isAll = ele.includes("-All");
          if (isAll)
            return {
              TreeRule: "0",
              ID: ele.split("-")[0],
            };
          return {
            TreeRule: "1",
            ID: ele,
          };
        });
        if (arr.length === 0) {
          payload[key].CoverageType = "0";
          payload[key][payloadMapper[key].array] = [];
        } else {
          count++;
          payload[key].CoverageType = any;
          payload[key][payloadMapper[key].array] = arr;
        }

        console.log("From Save: ", arr, " ", payload[key]);
      }
    });
    Object.entries(payloadMapper).forEach(([key, value]) => {
      if (!payload[key]) {
        payload[key] = {
          CoverageType: "0",
          [value.array]: [],
        };
      }
    });
    if (count === 0) dataPayload.ApplicabilityCoverage = "0";
    dataPayload = {
      ...dataPayload,
      ...payload,
      EmployeeStatusApplicability: {
        CoverageType: "0",
      },
      ConfirmationStatusApplicability: {
        CoverageType: "0",
      },
    };

    SaveApplicability({
      payload: dataPayload,
      editMode,
      ApplicabilityReferenceField,
    })
      .then((res) => {
        if (res && res?.Status === "Success") {
          onChange(res.ApplicabilityID);
        }
      })
      .finally((e) => {
        setLoading(null);
      });
  }
  console.log(onSave);
  function SelectedList() {
    let { dimension, id } = selectList;
    let labelData = dimensionIdToData[dimension];
    if (!allToChildrenMap[dimension]) return <></>;
    console.log("All to child ", allToChildrenMap[dimension][id], " ", id);
    return (
      <div className="scroll" style={{ height: "40vh", overflowY: "auto" }}>
        {[...new Set(allToChildrenMap[dimension][id])]
          .filter((item) => !item.includes("-All"))
          .map((ele) => (
            <span style={{ lineHeight: 2.5 }}>
              {labelData[ele] && (
                <span style={{ margin: 5 }}>
                  <Chip
                    label={labelData[ele].entityName}
                    onDelete={() => {
                      checkBoxChanged(false, ele, false, dimension);
                      setSelectList(null);
                    }}
                  />
                </span>
              )}
            </span>
          ))}
      </div>
    );
  }
  function renderSelectedView() {
    const selectedPayload = { ...payload };
    let dimensions = Object.keys(selectedPayload);
    let list = {};
    for (let i = 0; i < dimensions.length; i++) {
      let dimName = dimensions[i];
      if (!list[dimName]) list[dimName] = [];
      Object.entries(selectedPayload[dimName]).forEach(
        ([key, { selected, children, type }]) => {
          if (selected) {
            let isAll = true;
            let arr = [];
            for (let j = 0; j < children.length; j++) {
              if (!selectedValues[dimName].has(children[j])) {
                isAll = false;
              } else {
                arr.push(children[j]);
              }
            }
            list[dimName].push({
              isAll: type === "EE" ? false : isAll,
              children: arr,
              self: key,
            });
          }
        }
      );
    }
    function getLabel(id, dimension) {
      console.log("From getlabel: ", dimensionIdToData[dimension]);
      return dimensionIdToData[dimension][id].entityName;
    }
    return (
      <div style={{ height: 450 }}>
        {/* {JSON.stringify(list)} */}
        {EmployeeStatusApplicability && (
          <Typography variant="caption" sx={{ paddingLeft: "1em" }}>
            {EmployeeStatusOptions.find(
              (option) => option.value === EmployeeStatusApplicability
            )?.label || ""}
          </Typography>
        )}
        {ConfirmationStatusApplicability &&
          ConfirmationStatusApplicability.length > 0 && (
            <>
              <br />
              <SelectedViewAccordian
                AccordianID={"ConfirmationStatusApplicability"}
                key={"ConfirmationStatusApplicability"}
                title={"Confirmation Status"}
              >
                {ConfirmationStatusApplicability.map((ele) => (
                  <div>
                    <Chip
                      sx={{ backgroundColor: "#E0E0E0" }}
                      size="small"
                      deleteIcon={
                        <Close sx={{ color: "#272727 !important" }} />
                      }
                      onDelete={() => {
                        const status = [
                          ...(ConfirmationStatusApplicability || []),
                        ];
                        const index = status.findIndex(
                          (s) => s.CoverageType === ele.CoverageType
                        );
                        status.splice(index, 1);
                        if (index !== -1) {
                          updateState(
                            status,
                            "ConfirmationStatusApplicability"
                          );
                        }
                      }}
                      label={
                        <Typography variant="small_text">
                          {ConfirmationStatusOptions.find(
                            (option) => option.value === ele.CoverageType
                          )?.label || ""}
                        </Typography>
                      }
                    />
                  </div>
                ))}
              </SelectedViewAccordian>
            </>
          )}
        {Object.entries(list).map(([key, value]) => (
          <div key={key} style={{ marginBottom: "1em" }}>
            <SelectedViewAccordian
              AccordianID={key}
              key={key}
              title={keyToLabelMap[key]}
            >
              <div
                className="Flex-row"
                style={{
                  justifyContent: "flex-start",
                  gap: "0.5em",
                  flexWrap: "wrap",
                }}
              >
                {value.map(({ isAll, children, self }) => (
                  <>
                    {isAll ? (
                      <div>
                        {" "}
                        <Chip
                          sx={{ backgroundColor: "#E0E0E0" }}
                          size="small"
                          deleteIcon={
                            <Close sx={{ color: "#272727 !important" }} />
                          }
                          onDelete={() => {
                            checkBoxChanged(false, self);
                          }}
                          label={
                            <Typography variant="small_text">
                              {getLabel(self, key) + "-All"}
                            </Typography>
                          }
                        />
                      </div>
                    ) : (
                      <>
                        {children.map((ele) => (
                          <div>
                            <Chip
                              sx={{ backgroundColor: "#E0E0E0" }}
                              size="small"
                              deleteIcon={
                                <Close sx={{ color: "#272727 !important" }} />
                              }
                              onDelete={() => {
                                checkBoxChanged(false, ele);
                              }}
                              label={
                                <Typography variant="small_text">
                                  {getLabel(ele, key)}
                                </Typography>
                              }
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </>
                ))}
              </div>
            </SelectedViewAccordian>
          </div>
        ))}
      </div>
    );
  }
  function SearchBar({ search, onSearchKeyChange }) {
    const useStyles = makeStyles((theme) => ({
      menuItem: {
        width: "100%",
        display: "block",
        textAlign: "start",
        padding: theme.spacing(1, 2),
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
        "&:focus": {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }));

    const styles = useStyles();

    const [isOpen, setIsOpen] = useState();
    const [modal, setModal] = useState(null);
    const [listening, setListening] = useState(false);
    const menuRef = useRef();
    function openMenu(e) {
      setIsOpen(true);
    }
    function handleMenuClick() {
      setIsOpen(false);
    }
    function listenForOutsideClicks(
      listening,
      setListening,
      menuRef,
      setIsOpen
    ) {
      return () => {
        if (listening) return;
        if (!menuRef.current) return;
        setListening(true);
        [`click`, `touchstart`].forEach((type) => {
          document.addEventListener(`click`, (evt) => {
            const cur = menuRef.current;
            const node = evt.target;
            if (!cur || cur.contains(node)) return;
            handleMenuClick();
          });
        });
      };
    }
    useEffect(
      listenForOutsideClicks(listening, setListening, menuRef, setIsOpen)
    );
    return (
      <Box position={"relative"} width={"100%"} ref={menuRef}>
        {modal && (
          <EwModal onClose={() => setModal(null)} open={modal !== null}>
            <CheckOptionsModal {...modal} />
          </EwModal>
        )}
        {console.log("hey", isOpen)}
        <Input
          label={" "}
          placeholder={"Search"}
          onFocus={(e) => {
            e.target.setAttribute("autocomplete", "off");
            console.log("Hey");
            openMenu(e);
          }}
          onChange={(val) => {
            onSearchKeyChange(val);
          }}
          value={search.key}
          size={"small"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ opacity: "0.56" }} />
              </InputAdornment>
            ),
            sx: { borderRadius: "20px", height: "2em" },
            shrink: false,
          }}
          styles={{
            margin: "0.5em 0",
            width: "100%",
            borderRadius: "20px",
          }}
        />
        {isOpen && (
          <Paper
            className="invisble-scroll"
            sx={{
              position: "absolute",
              top: "3em",
              width: "100%",
              zIndex: "2",
              height: "5em",
            }}
          >
            <Box>
              {search.options.length > 0 ? (
                search.options.map((option) => {
                  return (
                    <Box key={option.entityID}>
                      {/* <Checkboxes
                      label={
                        
                      }
                      onChange={() => {}}
                      value={""}
                    /> */}
                      <ButtonBase
                        className={styles.menuItem}
                        onClick={() => {
                          setModal({
                            val: undefined,
                            entityID: option.entityID,
                            checkBoxChanged,
                            closeModal: () => setModal(null),
                          });
                        }}
                      >
                        <Typography variant="caption">
                          {option.entityName}
                        </Typography>
                      </ButtonBase>
                    </Box>
                  );
                })
              ) : (
                <Box className="Flex">
                  <Typography variant="h5">No matches found</Typography>
                </Box>
              )}
            </Box>
          </Paper>
        )}
        {isOpen && (
          <Box
            sx={{
              position: "absolute",
              top: "3em",
              left: 0,
              height: "7em",
              width: "100%",
              zIndex: "1",
              pointerEvents: "auto",
              backgroundColor: "transparent",
            }}
          ></Box>
        )}
      </Box>
    );
  }
  function FilterComponent({ dimensionTypeTree }) {
    const [type, setType] = useState(null);

    const [search, setSearch] = useState(filterSearchOptions());

    useEffect(() => {
      console.log(search, "search");
    }, [search]);

    function filterSearchOptions(searchKey, entityTypeID) {
      let filteredEntities = [
        ...Object.values(dimensionIdToData[selectedDimensions]),
      ];
      if (entityTypeID) {
        filteredEntities = [...filteredEntities].filter(
          (entity) => entity.entityTypeID === entityTypeID
        );
      }
      console.log(filteredEntities, entityTypeID, "search");
      if (searchKey) {
        const regex = new RegExp(searchKey.trim(), "i");
        filteredEntities = filteredEntities.filter(
          (entity) =>
            typeof entity.entityName === "string" &&
            regex.test(entity.entityName)
        );
      }

      return {
        key: searchKey,
        options: [
          ...filteredEntities.sort((a, b) =>
            a.entityName > b.entityName ? 1 : -1
          ),
        ],
      };
    }

    return (
      <Paper
        elevation={2}
        sx={{
          borderRadius: "4px",
          marginLeft: "0.5em",
          marginTop: "0.5em",
          padding: "5px 20px",
        }}
      >
        <Box>
          <Box display={"flex"} gap={"1em"} alignItems={"center"}>
            <Typography
              variant="caption"
              fontWeight={600}
              sx={{ marginTop: "5px" }}
            >
              Filter By:
            </Typography>
            <Box flex={"1"}>
              <DimensionTypeRadioGroup
                value={type}
                menuItems={[...dimensionTypeTree]}
                onChange={(val) => {
                  setType(val);
                  setSearch(filterSearchOptions("", val));
                }}
              />
            </Box>
          </Box>
          <Box display={"flex"}>
            <Box flex={"1"}>
              <SearchBar
                search={search}
                onSearchKeyChange={(val) => {
                  setSearch(filterSearchOptions(val, type));
                }}
              />
            </Box>
            <Box flex={"1"}> </Box>
          </Box>
        </Box>
      </Paper>
    );
  }
  if (loading) return <>{loading}</>;
  const ApplicabilityCoverageOptions = [
    { label: "Everyone", value: "0" },
    { label: "Teams", value: "2" },
    { label: "Specific Groups", value: "3" },
  ];
  const EmployeeStatusOptions = [
    { label: "Include only Alumni", value: "0" },
    { label: "Include only Active Employees", value: "1" },
  ];
  const ConfirmationStatusOptions = [
    { label: "Probation not Mandatory", value: "0" },
    { label: "Regular Probation", value: "1" },
    { label: "Extended Probation", value: "2" },
    { label: "Confirmed Employees", value: "3" },
  ];
  return (
    <div
      style={{
        width: "60vw",
        padding: "0.8em 1em",
        paddingRight: "0",
        //  height: "70vh",
        position: "relative",
      }}
    >
      <EwModal open={selectList} onClose={() => setSelectList(null)}>
        <SelectedList />
      </EwModal>
      <Typography variant="h2" color={"primary"}>
        Applicability
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={"8"} md={"8"} lg={"8"}>
          <Box width={"40em"} margin={"1em 0.5em"}>
            <Radio
              flex={1}
              menuItems={[...ApplicabilityCoverageOptions]}
              onChange={(val) => {
                updateState(val, "ApplicabilityCoverage");
              }}
              value={ApplicabilityCoverage}
              containerStyles={{ gap: "0.4em" }}
            />
            <Radio
              flex={1}
              menuItems={[...EmployeeStatusOptions]}
              onChange={(val) => {
                updateState(val, "EmployeeStatusApplicability");
              }}
              value={EmployeeStatusApplicability}
            />
          </Box>
          <div
            style={{ height: "55vh", paddingRight: "1em" }}
            className="invisble-scroll"
          >
            <DimensionAccordiansGroup
              menuItems={[
                {
                  Component: () => (
                    <Box marginLeft={"3em"}>
                      {[
                        ...ConfirmationStatusOptions.map((option) => (
                          <>
                            <Checkboxes
                              label={option.label}
                              onChange={(val) => {
                                let status = [
                                  ...(ConfirmationStatusApplicability || []),
                                ];
                                const index = status.findIndex(
                                  (s) => s.CoverageType === option.value
                                );
                                if (val) {
                                  if (index !== -1) {
                                    status[index] = {
                                      CoverageType: option.value,
                                    };
                                  } else {
                                    status.push({ CoverageType: option.value });
                                  }
                                } else {
                                  if (index !== -1) {
                                    status.splice(index, 1);
                                  } else {
                                  }
                                }

                                updateState(
                                  [...status],
                                  "ConfirmationStatusApplicability"
                                );
                              }}
                              value={Boolean(
                                (ConfirmationStatusApplicability || []).find(
                                  (status) =>
                                    status.CoverageType === option.value
                                )
                              )}
                            />
                          </>
                        )),
                      ]}
                    </Box>
                  ),
                  props: { id: "2", title: "Confirmation Status" },
                },
              ]}
            ></DimensionAccordiansGroup>
            <DimensionAccordiansGroup
              openedValue={selectedDimensions}
              openHandler={(val) => {
                console.log("value: ", val);
                const dimensionType = dimensions.find(
                  (dimension) => dimension.value === val && dimension.children
                );
                if (dimensionType) {
                  const value = dimensionType.children[1].value;
                  console.log(dimensionType, value, "dimensionType");
                  let payload = {
                    function: "dimensionTree",
                    FeatureID: value ? dimensionsIdMap[value] : "*",
                    FeatureVariantID: val ? "000" : "*",
                    DimensionID: "*",
                    Direction: "B",
                  };
                  setLoadingTree(true);
                  getTree({ payload })
                    .then((res) => {
                      console.log(
                        [...res.DimensionEntities],
                        "[...res.DimensionEntities]"
                      );
                      updateState(
                        [...res.DimensionEntities],
                        "selectedDimensionType"
                      );
                    })
                    .finally(() => {
                      setLoadingTree(false);
                    });
                }
                updateState(val, "selectedDimensions");
              }}
              menuItems={[
                ...dimensions.map((dimension, index, dimensionsArr) => ({
                  Component: () => (
                    <>
                      {loadingTree ? (
                        <>Loading Tree..</>
                      ) : (
                        <>
                          {dimensionIdToData &&
                            dimensionIdToData[selectedDimensions] && (
                              <>
                                {dimension.children && (
                                  <Paper
                                    elevation={2}
                                    sx={{
                                      borderRadius: "4px",
                                      marginLeft: "0.5em",
                                      padding: "5px 20px",
                                    }}
                                  >
                                    <Box
                                      display={"flex"}
                                      justifyContent={"space-between"}
                                      alignItems={"center"}
                                    >
                                      <Box
                                        display={"flex"}
                                        gap={"1em"}
                                        alignItems={"center"}
                                      >
                                        <Typography
                                          variant="caption"
                                          fontWeight={600}
                                          sx={{ marginTop: "5px" }}
                                        >
                                          Select By
                                        </Typography>
                                        <Radio
                                          flex={1}
                                          value={selectedDimensions}
                                          menuItems={[
                                            ...dimension.children.map(
                                              (child) => ({
                                                label: (
                                                  <Typography variant="caption">
                                                    {child.label}
                                                  </Typography>
                                                ),
                                                value: child.value,
                                              })
                                            ),
                                          ]}
                                          onChange={(val) =>
                                            updateState(
                                              val,
                                              "selectedDimensions"
                                            )
                                          }
                                        />
                                      </Box>
                                      <Box>
                                        <Radio
                                          flex={1}
                                          label={" "}
                                          menuItems={[
                                            {
                                              label: (
                                                <Typography variant="caption">
                                                  Exclude
                                                </Typography>
                                              ),
                                              value: "1",
                                            },
                                            {
                                              label: (
                                                <Typography variant="caption">
                                                  Include
                                                </Typography>
                                              ),
                                              value: "2",
                                            },
                                          ]}
                                          value={any}
                                          onChange={(val) => anyChange(val)}
                                        />
                                      </Box>
                                    </Box>
                                  </Paper>
                                )}
                                {dimension.children &&
                                  !dimension.label.includes("type") &&
                                  (selectedDimensionType ? (
                                    <FilterComponent
                                      dimensionTypeTree={(
                                        [...selectedDimensionType] || []
                                      ).map((t) => {
                                        return {
                                          label: (
                                            <Typography variant="caption">
                                              {t.entityName}
                                            </Typography>
                                          ),
                                          value: t.entityID,
                                        };
                                      })}
                                    />
                                  ) : (
                                    <></>
                                  ))}
                                {
                                  <>
                                    {console.log(
                                      "Before render: ",
                                      selectedEntity[selectedDimensions]
                                    )}
                                    <div style={{ marginLeft: 30 }}>
                                      {RenderTree(
                                        dimensionIdToData[selectedDimensions],
                                        0,
                                        dimension.children ? true : false
                                      )}
                                    </div>
                                  </>
                                }
                              </>
                            )}
                        </>
                      )}
                    </>
                  ),
                  props: {
                    id: dimension.id,
                    title: dimension.label,
                    value: dimension.value,
                  },
                })),
              ]}
            />
          </div>
        </Grid>
        <Grid item sm={"4"} md={"4"} lg={"4"}>
          <Paper
            sx={{
              height: "calc(100% - 2em)",
              paddingLeft: "0.5em",
            }}
          >
            <Typography variant="h6" sx={{ paddingLeft: "1em" }}>
              Selected View
            </Typography>
            <br />
            <Box height={"calc(100% - 3em)"} className="scroll">
              {renderSelectedView()}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
        }}
      >
        <Button
          title={"Save"}
          onClick={() => {
            //   onSave();
            console.log("App save: ", allToChildrenMap, " ", dimensionIdToData);
          }}
        />
      </div>
    </div>
  );
}

function Applicability6(props = initialProps) {
  const { value } = props;
  const [modal, openModal] = useState(false);
  return (
    <div>
      <EwModal open={modal} onClose={() => openModal(false)}>
        <ModalContent {...props} />
      </EwModal>
      <Button
        title={value ? "Edit Applicability" : "Add Applicability"}
        onClick={() => openModal(true)}
      />
    </div>
  );
}

export default Applicability6;
