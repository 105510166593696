import React, { useEffect, useRef, useState } from "react";
import Atomic from "../../../AComponent/Atomic";
import EwTable from "../../../MComponent/Table/Table";
import AlertMessage from "../../../Utils/Messages/AlertMessage";
import {
  submitPayload,
  handleResult,
  getData,
  deactivateDocument,
} from "../../../Utils/Common/Library";
import FeatureIDs from "../../../Utils/Common/FeatureIDs";
import Molecule from "../../../MComponent/Molecule";
import {
  Box,
  Grid,
  IconButton,
  Link,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { Edit, KeyboardArrowLeft } from "@mui/icons-material";
const { Button, Input, Radio, Dropdown, EwSwitch } = Atomic;
const { EwModal } = Molecule;

function FormModal({ mode, closeForm, data }) {
  const [localState, setLocalState] = useState(data ? { ...data } : {});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const {
    DimensionID,
    GroupLevel,
    GroupName,
    GroupShortName,
    TransactionID,
    EffectiveFromTimestamp,
    GroupDescription,
  } = localState;
  function updateState(val, type) {
    setLocalState((prev) => ({ ...prev, [type]: val }));
  }
  function onSave() {
    setLoading(true);
    let payload = localState;
    let edit = null;
    if (TransactionID) {
      edit = {
        TransactionID,
        EffectiveFromTimestamp,
        RequestType: "1",
      };
    }
    submitPayload({
      featureId: FeatureIDs.DimensionParameterGroup,
      payload,
      edit,
    })
      .then((res) => {
        let type = handleResult(res);
        setMessage({
          type: 0,
          message: type === 1 ? "Group Added!" : type === 2 && "Group Edited",
        });
        closeForm();
      })
      .catch((e) => {
        setMessage({
          type: 1,
          message: "Network Error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }
  if (loading) return <>Loading ...</>;
  return (
    <div style={{ padding: "0 0.5em" }}>
      <div style={{ marginBottom: "2em" }}>
        <Typography
          variant="h2"
          sx={{ display: "flex", alignItems: "center", gap: "0.3em" }}
        >
          <KeyboardArrowLeft
            fontSize="large"
            sx={{ cursor: "pointer" }}
            onClick={() => closeForm()}
          />
          {mode === "E" ? "Edit group" : "Add a new group"}
        </Typography>
      </div>
      {message && (
        <AlertMessage type={message.type} message={message.message} />
      )}
      <br />
      <div style={{ display: "flex", gap: "3em" }}>
        <div>
          <div>
            <Dropdown
              label="Select Dimension"
              featureId={"19144"}
              mapper={{
                label: "DimensionName",
                value: "TransactionID",
              }}
              styles={{ width: "100%", margin: "0" }}
              value={DimensionID}
              onChange={(val) => updateState(val, "DimensionID")}
            />
          </div>
          <br />
          <>
            <div className="Flex-row" style={{ gap: "1em" }}>
              <div>
                <Input
                  label="Group Name"
                  value={GroupName}
                  onChange={(val) => updateState(val, "GroupName")}
                  styles={{ width: "100%", margin: "0" }}
                  disable={!DimensionID || DimensionID === -1}
                />
              </div>
              <div>
                <Input
                  label="Group Short Name"
                  value={GroupShortName}
                  onChange={(val) => updateState(val, "GroupShortName")}
                  styles={{ width: "100%", margin: "0" }}
                  disable={!DimensionID || DimensionID === -1}
                />
              </div>
            </div>
            <br />
            <div className="">
              <Input
                label="Group Description"
                value={GroupDescription}
                onChange={(val) => updateState(val, "GroupDescription")}
                styles={{ width: "100%", margin: "0" }}
                disable={!DimensionID || DimensionID === -1}
              />
            </div>
            <br />
            <div>
              <Button
                title={mode === "E" ? "Save changes" : "Add"}
                style={{ textTransform: "none" }}
                onClick={onSave}
                disable={!DimensionID || DimensionID === -1}
              />
            </div>
          </>
        </div>
        <div>
          <Radio
            flex={1}
            label="Group Level"
            menuItems={[
              { label: "Level 1", value: "1" },
              { label: "Level 2", value: "2" },
            ]}
            value={GroupLevel}
            onChange={(val) => updateState(val, "GroupLevel")}
            disable={!DimensionID || DimensionID === -1}
          />
        </div>
      </div>
    </div>
  );
}

function ViewModal({ data, closeForm }) {
  const {
    DimensionID,
    GroupLevel,
    GroupName,
    GroupShortName,
    GroupDescription,
  } = data;

  const [DimensionName, setDimensionName] = useState(null);

  useEffect(() => {
    if (DimensionID) {
      getData({
        featureId: FeatureIDs.PerformanceDimensionGrouping,
        payload: {
          "Header.TransactionID": DimensionID,
        },
        fields: ["Data.DimensionName"],
      }).then((res) => {
        console.log(res.Data[0].DimensionName, "res.Data[0].DimensionName");
        res.Data && res.Data[0] && setDimensionName(res.Data[0].DimensionName);
      });
    }
  }, []);

  return (
    <Box width={"40vw"} height={"35vh"}>
      <Typography
        variant="h2"
        sx={{ display: "flex", alignItems: "center", gap: "0.3em" }}
      >
        <KeyboardArrowLeft
          fontSize="large"
          sx={{ cursor: "pointer" }}
          onClick={() => closeForm()}
        />
        View Group
      </Typography>
      <br />
      <Grid container rowGap={5} sx={{ marginLeft: "3em" }}>
        <Grid item md="6">
          <Box>
            <Typography variant="caption">Dimension</Typography>
            <Typography variant="subtitle1">
              {DimensionName || "N/A"}
            </Typography>
          </Box>
        </Grid>
        <Grid item md="6">
          <Typography variant="caption">Group Level</Typography>
          <Typography variant="subtitle1">{GroupLevel || "N/A"}</Typography>
        </Grid>
        <Grid item md="6">
          <Typography variant="caption">Group Name</Typography>
          <Typography variant="subtitle1">{GroupName || "N/A"}</Typography>
        </Grid>
        <Grid item md="6">
          <Typography variant="caption">Group short name</Typography>
          <Typography variant="subtitle1">{GroupShortName || "N/A"}</Typography>
        </Grid>
        <Grid item md="12">
          <Typography variant="caption">Group Description</Typography>
          <Typography variant="subtitle1">
            {GroupDescription || "N/A"}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

function DPG2({ contentHeight }) {
  const [openForm, setOpenForm] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [activeDocs, setActiveDocs] = useState([]);

  const ref = useRef();
  const [height, setHeight] = useState("40vh");
  const [include, setInclude] = useState(false);

  const [barOpen, setBarOpen] = useState(false);

  function docSelected(data) {
    setOpenForm({
      Component: FormModal,
      props: {
        mode: "E",
        closeForm: () => setOpenForm(null),
        data,
      },
    });
  }

  function docDeactivated({ id, status }) {
    deactivateDocument({ id, FeatureID: FeatureIDs.DimensionParameterGroup })
      .then((res) => {})
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    if (ref.current) {
      setHeight(
        contentHeight -
          ref.current.offsetHeight +
          parseInt(window.getComputedStyle(ref.current).marginBottom)
      );
      console.log(
        contentHeight,
        ref.current.offsetHeight +
          parseInt(window.getComputedStyle(ref.current).marginBottom),
        "contentHeight, totalHeight"
      );
    }
  }, []);

  useEffect(() => {
    if (include) {
      setActiveDocs([...documents]);
    } else {
      setActiveDocs((prevState) => {
        return prevState.filter(filterActive);
      });
    }
  }, [include]);

  function filterActive(item) {
    if (!include && item.Status === "1") return true;
    if (include === true && ["1", "3"].includes(item.Status)) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    getData({
      featureId: FeatureIDs.DimensionParameterGroup,
      fields: ["Header.*", "Data.*"],
      status: "*",
    })
      .then((res) => {
        console.log(res, "DPG2 GROUPS");
        const docs = [...res.Data];
        setDocuments([...docs]);
        setActiveDocs([...docs.filter(filterActive)]);
      })
      .finally(() => {});
  }, []);

  return (
    <>
      <div style={{ marginTop: "2em", marginRight: "3em" }}>
        <div ref={ref}>
          <Typography variant="h2" color={"primary"}>
            Dimension Parameter Groups
          </Typography>
          <br />
          <Typography variant="body1">
            Defining, assessing and rating employees on these dimensions is
            purpose of the organisations’ performance management process/system
            (PMS). In doing so the PMS becomes the tools used to determine
            monetary rewards and growth.
          </Typography>
          <br />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              title={"Add a New Group"}
              style={{ textTransform: "none" }}
              onClick={() =>
                setOpenForm({
                  Component: FormModal,
                  props: {
                    mode: "A",
                    closeForm: () => setOpenForm(null),
                  },
                })
              }
            />
            <EwSwitch
              label={"Include Deactivated"}
              value={include}
              onChange={(val) => setInclude(val)}
            />
          </div>
          <br />
        </div>
        {activeDocs.length === 0 ? (
          <div className="Flex">
            <Typography variant="body1">No data to display</Typography>
          </div>
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"1em"}
            marginLeft={"-1em"}
            paddingLeft={"1em"}
            paddingRight={"3em"}
            paddingTop={"1em"}
            paddingBottom={"1em"}
            height={`calc(${height}px - 10em)`}
            className="invisble-scroll"
          >
            {activeDocs.map((document) => (
              <Paper elevation={4}>
                <Grid container alignItems={"center"}>
                  <Grid item md="4">
                    <Typography variant="subtitle1">
                      {document.GroupName}
                    </Typography>
                  </Grid>
                  <Grid item md="3">
                    {" "}
                    <Typography variant="body1">{`Group level ${document.GroupLevel}`}</Typography>
                  </Grid>
                  <Grid item md="3">
                    <Link
                      color={"primary"}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpenForm({
                          Component: ViewModal,
                          props: {
                            data: document,
                            closeForm: () => setOpenForm(null),
                          },
                        });
                      }}
                    >
                      View
                    </Link>
                  </Grid>
                  <Grid item md="2">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          docSelected(document);
                        }}
                      >
                        <Edit />{" "}
                      </IconButton>
                      <Button
                        variant={"text"}
                        label={"Deactivate"}
                        title={"Deactivate"}
                        onClick={() => {}}
                        style={{ color: "black" }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Box>
        )}
        <br />
        {openForm && (
          <EwModal open={openForm} onClose={() => setOpenForm(null)}>
            <openForm.Component {...openForm.props} />
          </EwModal>
        )}
      </div>
      <Snackbar />
    </>
  );
}

export default DPG2;
