import React from 'react'
//import Data from '../Testjson/Forms.json'

const Fstyles = {
  display: 'flex',
  width: '100%',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection:'column'
 
}

function FormInterpreter({Data}) {

  return (
    <>
      <div style={Fstyles}>
        {Data.Components.map((comp, index) => {

          return (
            <div style={{display:'flex',justifyContent:'space-around'}} >
         { comp.div.map((field, f_index) => {
           console.log("Form1 ",field);
            let location = field.component.split('.')
            let compAddress
            if (location.length === 3)
              compAddress =
                location[0] + 'Component/' + location[1] + '/' + location[2]
            else compAddress = location[0] + 'Component/' + location[1];
            console.log("Form2 ",compAddress);
            let Comp
            
            return (
              <div style={{marginRight:'50'}} >
                {(Comp = require('../' + compAddress).default)}
                {console.log("Form3 ",Comp)}
                <Comp key={index} {...field.props} />
                <br /> <br />
              </div>
            )
          })
        }
          </div>
          )
        })}
      </div>
    </>
  )
}

export default FormInterpreter
