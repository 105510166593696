import React from "react";
import menujson from "../../Testjson/Header/Menu.json";


const EWMenu = () => {

    const options = menujson.Options;
    const length = options.length;
    return <div style = {{
        display : "flex",
        flexDirection : "row",
        justifyContent: "space-between"
    }}>
        {options.map((option,index) => {
            console.log("From options: ",option)
            if(index %2 ==0)
            {
                const item1 = option.Item;
                const item2 = (index+1)<length ? options[index+1].Item : null;
                const Component1 = item1 !== undefined ? require('../../CComponent/Cards/'+item1.MComponent).default : null;
                console.log("From Hamburger Data: ",item2.MComponent)
                const Component2 = item2 !== null ? require('../../CComponent/Cards/'+item2.MComponent).default : null;
              
                return <div style = {{
                    display : "flex",
                    flexDirection : "column",
                    margin : 10,
                }}>
                    <div style = {{margin : 10}}> <Component1 {...item1} /> </div>
                    <div style = {{margin : 10}}>{Component2 !== null && <Component2 {...item2} />}</div>
                </div>
            }
        })}
    </div>
}

export default EWMenu;