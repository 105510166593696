import React from 'react';

const titleStyle = {
    fontSize : '25px',
}

function TaskTitle( {title} ){
    return(
        <div style={titleStyle}>
            {title}
        </div>
    );
}

export default TaskTitle;