import { Handle, MarkerType, Position } from "reactflow";
import {
  Box,
  IconButton,
  Paper,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { SettingsOutlined } from "@mui/icons-material";
import useReduxStore from "../../../Utils/CustomHooks/ReduxUpdater";
import { useCallback, useEffect, useState } from "react";
import EwModal from "../../../MComponent/Modal/Modal";
import StepFormModal from "../StepFormModal/StepFormModal";
import { rgbaToHex } from "../helpers";
import { HandleIcon } from "../../../Utils/Common/Icons";

function StepNode({ data, isConnectable }) {
  const [state, setState] = useReduxStore({ id: "Workflow2" });
  const [modal, setModal] = useState(null);
  const { palette } = useTheme();

  console.log(state, "THIS IS A GKNGSTATE");

  const updateState = useCallback(
    (typeValueMaps) => {
      const prevState = { ...state.data };
      console.log("THIS IS NOT RENDERED", typeValueMaps, prevState);
      typeValueMaps.forEach(({ value, type }) => {
        prevState[type] = { ...value };
      });
      console.log(prevState, "LOLOLOCOCOCO");
      setState(prevState, "data");
    },
    [setState, state]
  );

  const { StepID, styles } = data;
  const StepData = JSON.parse(JSON.stringify(state.data.steps[StepID]));

  const { StepName, StepScenarios } = StepData;

  useEffect(() => {
    if (StepID === "000") {
      createNodesOnStepUpdate(state.data.steps[StepID]);
    }
  }, []);

  const createNodesOnStepUpdate = useCallback(
    (Step) => {
      const Scenarios = Step.StepScenarios;
      const nodes = JSON.parse(JSON.stringify(state.data.nodes));
      const edges = JSON.parse(JSON.stringify(state.data.edges));
      const steps = JSON.parse(JSON.stringify(state.data.steps));

      Object.keys(nodes).forEach((key, index) => {
        const [thisStepID, thisScenarioID, thisActionID] = key.split("_");
        if (thisStepID === StepID && thisScenarioID !== "@") {
          delete nodes[key];
        }
      });

      Object.keys(edges).forEach((key, index) => {
        const [source, destination] = key.split("$");
        const [thisStepID, thisScenarioID, thisActionID] =
          destination.split("_");
        const [srcStepID, srcScenarioID, srcActionID] = source.split("_");
        if (thisStepID === StepID && thisScenarioID !== "@") {
          delete edges[key];
        }
        if (
          srcStepID === StepID &&
          srcScenarioID !== "@" &&
          srcActionID !== "@"
        ) {
          delete edges[key];
        }
      });

      let totalActions = 0;
      Scenarios.forEach((s) => {
        totalActions += s.Actions?.length;
      });

      const length = Scenarios.length;
      const StepHash = `${StepID}_@_@`;
      const parentStepNode = nodes[StepHash];

      let stepY = parentStepNode.position.y;
      let gap =
        (length - 1) * (-25 * totalActions <= -50 ? -25 * totalActions : -50);

      for (let i = 0; i < length; i++) {
        const ScenarioID = Scenarios[i].ScenarioID;
        const ScenarioHash = `${StepID}_${ScenarioID}_@`;
        const Actions = Scenarios[i].Actions;
        const actionsLength = Actions?.length;

        nodes[ScenarioHash] = {
          id: ScenarioHash,
          position: {
            x: parentStepNode.position.x + 250,
            y: stepY + gap,
          },
          data: {
            StepID: StepID,
            ScenarioID: ScenarioID,
            styles: { color: palette.grey[600] },
          },
          type: "scenarioNode",
        };

        gap = gap + (50 * totalActions > 100 ? 50 * totalActions : 100);

        const source = StepHash,
          target = ScenarioHash;
        const StepToScenarioHash = `${source}$${target}`;

        edges[StepToScenarioHash] = {
          id: StepToScenarioHash,
          source,
          target,
          style: {
            strokeWidth: 2,
            stroke: parentStepNode.data.styles.color,
          },
        };

        const parentScenarioNode = nodes[ScenarioHash];
        const y = parentScenarioNode.position.y;
        let start = (actionsLength - 1) * -50;

        for (let j = 0; j < actionsLength; j++) {
          const { ActionID } = Actions[j];
          const ActionHash = `${StepID}_${ScenarioID}_${ActionID}`;
          nodes[ActionHash] = {
            id: ActionHash,
            position: {
              x: parentScenarioNode.position.x + 200,
              y: y + start,
            },
            data: {
              StepID,
              ScenarioID,
              ActionID,
              NextStepID: Actions[j].NextStepID || undefined,
              styles: { color: palette.warning.main },
            },
            type: "actionNode",
          };

          if (Actions[j].NextStepID) {
            const NextStepEdgeHash = `${ActionHash}$${Actions[j].NextStepID}_@_@`;
            console.log(
              Actions[j].NextStepID,
              "ACTIONSERRORSAREHERE",
              NextStepEdgeHash
            );
            if (!edges[NextStepEdgeHash]) {
              console.log(
                Actions[j].NextStepID,
                "ACTIONSERRORSAREHERE",
                NextStepEdgeHash
              );
              edges[NextStepEdgeHash] = {
                id: NextStepEdgeHash,
                source: ActionHash,
                target: `${Actions[j].NextStepID}_@_@`,
                markerEnd: {
                  type: MarkerType.Arrow,
                  width: 20,
                  height: 20,
                  color: rgbaToHex(palette.warning.main),
                },
                style: {
                  strokeWidth: 2,
                  stroke: palette.warning.main,
                },
              };
            }
          }

          start = start + 100;

          const source = ScenarioHash,
            target = ActionHash;
          const StepToScenarioHash = `${source}$${target}`;

          edges[StepToScenarioHash] = {
            id: StepToScenarioHash,
            source,
            target,
            markerEnd: {
              type: MarkerType.Arrow,
              width: 20,
              height: 20,
              color: rgbaToHex(parentStepNode.data.styles.color),
            },
            style: {
              strokeWidth: 2,
              stroke: parentStepNode.data.styles.color,
            },
          };
        }
      }

      console.log("THIS IS NOT RENDERED", edges);
      updateState([
        { value: nodes, type: "nodes" },
        { value: edges, type: "edges" },
        { value: { ...steps, [Step.StepID]: Step }, type: "steps" },
      ]);
    },
    [
      state.data.nodes,
      state.data.edges,
      state.data.steps,
      StepID,
      updateState,
      palette.grey,
      palette.warning.main,
    ]
  );

  return (
    <Paper
      elevation={3}
      sx={{
        border: `2px solid ${alpha(styles.color, 0.5)}`,
        borderRadius: "4px",
        boxSizing: "border-box",
        position: "relative",
        height: "7em",
        minWidth: "12em",
      }}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "1.2em",
          height: "1.2em",
          backgroundColor: `${palette.error.main}`,
          left: "-10px",
        }}
      >
        <HandleIcon />
      </Handle>
      <Box sx={{ position: "absolute", top: "0", right: "0" }}>
        <IconButton
          onClick={() => {
            setModal({
              Component: StepFormModal,
              props: {
                StepData,
                onSaveStepData: (data) => {
                  createNodesOnStepUpdate(data);
                  setModal(null);
                },
              },
            });
          }}
          size="small"
        >
          <SettingsOutlined fontSize="small" />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5em",
          justifyContent: "center",
          justifyItems: "center",
          height: "100%",
        }}
      >
        <Typography
          variant="h4"
          sx={{ color: styles.color }}
          fontWeight={"600"}
        >
          {StepName}
        </Typography>
        {/* {StepOwner && (
          <Typography variant="caption">
            Step Owner : <b>{StepOwner}</b>
          </Typography>
        )}
        {DataFields && DataFields.length > 0 && (
          <Typography variant="caption">
            Data fields ({DataFields.length})
          </Typography>
        )} */}
      </Box>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
        style={{ opacity: 0, right: "5px", pointerEvents: "none" }}
      />
      {modal && (
        <EwModal open={modal} onClose={() => {}}>
          <modal.Component {...modal.props} />
        </EwModal>
      )}
    </Paper>
  );
}

export default StepNode;
