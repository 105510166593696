import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { getData } from "../../Utils/Common/Library";
import "./DataGrid.css";

function DataGrid({ data, columns, featureId, pagination, pageLimit }) {
  const [Data, setData] = useState(data);

  useEffect(() => {
    setData(data);
  }, [data]);

  function onGridReady(params) {
    getData({ featureId })
      .then((response) => {
        params.api.applyTransaction({ add: response.Data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function generateColumns() {
    return columns.map((col) => ({
      headerName: col.label,
      field: col.value.Component ? col.label : col.value,
      cellRendererFramework: col.value.Component
        ? (params) => (
            <col.value.Component params={params} {...col.value.props} />
          )
        : undefined,
    }));
  }

  console.log(Data, generateColumns(), "DATA_GRID");

  const defaultColumnDef = {
    flex: 1,
    lockPosition: "left",
    headerClass: "data-grid-header",
  };

  return (
    <div className="ag-theme-alpine" style={{ maxHeight: 500, width: "100%" }}>
      <AgGridReact
        columnDefs={generateColumns()}
        rowData={Data}
        onGridReady={featureId ? onGridReady : () => setData(data)}
        defaultColDef={defaultColumnDef}
        domLayout="autoHeight"
        pagination={pagination}
        paginationPageSize={pageLimit}
      />
    </div>
  );
}

export default DataGrid;
