var Filters = {
    "LastYear" : {
        displayKey : 'lastyear',
        displayName : 'Last Year',
        test : (filterValue,cellValue) => {
          if (cellValue == null) return true;
  
          var parts = cellValue.split('/');
          var cellDate = new Date(
            Number(parts[2]),
            Number(parts[1] - 1),
            Number(parts[0])
          );
          var today = new Date();
          var diff = today.getFullYear() - cellDate.getFullYear();
          return diff == 1
        },
        hideFilterInput: true
      },
    "LastMonth" : {
        displayKey : 'lastmonth',
        displayName : 'Last Month',
        test : (filterValue,cellValue) => {
          if (cellValue == null) return true;
  
          var parts = cellValue.split('/');
          var cellDate = new Date(
            Number(parts[2]),
            Number(parts[1] - 1),
            Number(parts[0])
          );
          var today = new Date();
          var diff = today.getMonth() - cellDate.getMonth();
          var ydiff = today.getFullYear() - cellDate.getFullYear();
          return diff == 1 && ydiff == 0
        },
        hideFilterInput: true
      },
    "LastQuarter":{
      displayKey : 'lastquarter',
      displayName : 'Last Quarter',
      test : (filterValue,cellValue) => {
        if(cellValue == null) return true;
        var parts = cellValue.split('/');
        var cellDate = new Date(
          Number(parts[2]),
          Number(parts[1] - 1),
          Number(parts[0])
        );
        var today = new Date();
        var ydiff = today.getFullYear() - cellDate.getFullYear();
        var mdiff = today.getMonth() - cellDate.getMonth();
        return ydiff ==0 && mdiff == 4
      },
      hideFilterInput : true
    },
    "CurrentYear" : {
      displayKey : 'currentyear',
      displayName : 'Current Year',
      test : (filterValue,cellValue) => {
        if (cellValue == null) return true;

        var parts = cellValue.split('/');
        var cellDate = new Date(
          Number(parts[2]),
          Number(parts[1] - 1),
          Number(parts[0])
        );
        var today = new Date();
        var diff = today.getFullYear() - cellDate.getFullYear();
        return diff == 0
      },
      hideFilterInput: true
    },
    "CurrentMonth" : {
      displayKey : 'currentmonth',
      displayName : 'Current Month',
      test : (filterValue,cellValue) => {
        if (cellValue == null) return true;

        var parts = cellValue.split('/');
        var cellDate = new Date(
          Number(parts[2]),
          Number(parts[1] - 1),
          Number(parts[0])
        );
        var today = new Date();
        var diff = today.getMonth() - cellDate.getMonth();
        var ydiff = today.getFullYear() - cellDate.getFullYear();
        return diff == 0 && ydiff == 0
      },
      hideFilterInput: true
    },
    "CurrentQuarter" : {
      displayKey : 'lastmonth',
      displayName : 'Last Month',
      test : (filterValue,cellValue) => {
        if (cellValue == null) return true;

        var parts = cellValue.split('/');
        var cellDate = new Date(
          Number(parts[2]),
          Number(parts[1] - 1),
          Number(parts[0])
        );
        var today = new Date();
        var cquarter = today.getMonth()/4;
        var cellquarter = cellDate.getMonth()/4;
        var diff = cquarter - cellquarter;
        return diff == 1 
      },
      hideFilterInput: true
    },
}

export default Filters;