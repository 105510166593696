export const dimensions = [
  {
    label: "Legal enitity",
    value: "LegalEntityApplicability",
    id: "12106",
  },
  {
    label: "Legal enitity type",
    value: "LegalEntityTypeApplicability",
    id: "",
    disable: true,
  },
  {
    label: "Grade",
    value: "GradeLevelApplicability",
    id: "12117",
  },
  {
    label: "Business Unit Type",
    value: "BusinessUnitTypeApplicability",
    id: "12111",
  },
  {
    label: "Business Unit",
    value: "BusinessUnitApplicability",
    id: "12112",
  },
  {
    label: "Employee Type",
    value: "EmployeeTypeApplicability",
    id: "12123",
  },
  {
    label: "Function Type",
    value: "FunctionTypeApplicability",
    id: "12113",
  },
  {
    label: "Function",
    value: "FunctionApplicability",
    id: "12114",
  },
  {
    label: "Location Type",
    value: "LocationTypeApplicability",
    id: "12115",
  },
  {
    label: "Location",
    value: "LocationApplicability",
    id: "12116",
  },
  // {
  //   label: 'Work Location',
  //   value: 'WorkLocationApplicability',
  //   id:'12120'
  // },
  {
    label: "Grade",
    value: "GradeApplicability",
    id: "12117",
  },
  // {
  //   label: 'Grade Level',
  //   value: 'GradeTypeApplicability',
  //   id:"12118"
  // },
  // {
  //   label: 'Role Group',
  //   value: 'RoleGroupApplicability',
  //   id:"",
  //   disable:true
  // },
  {
    label: "Role",
    value: "RoleApplicability",
    id: "12122",
  },
  // {
  //   label: 'User Group',
  //   value: 'UserGroupApplicability',
  //   id:"",
  //   disable:true
  // },
  {
    label: "Team",
    value: "TeamApplicability",
    id: "12125",
  },
  {
    label: "Project",
    value: "ProjectApplicability",
    id: "22124",
  },
  // {
  //   label: 'Account',
  //   value: 'AccountApplicability',
  //   id:"",
  //   disable:true
  // },
  {
    label: "Work Place",
    value: "WorkplaceApplicability",
    id: "12120",
  },
];
export const dimensionForReport = [
  {
    label: "Legal enitity",
    value: "LegalEntityApplicability",
    id: "12106",
  },

  {
    label: "Grade",
    value: "GradeLevelApplicability",
    id: "12117",
  },

  {
    label: "Business Unit",
    value: "BusinessUnitApplicability",
    id: "12112",
  },

  {
    label: "Function",
    value: "FunctionApplicability",
    id: "12114",
  },

  {
    label: "Location",
    value: "LocationApplicability",
    id: "12116",
  },

  {
    label: "Role",
    value: "RoleApplicability",
    id: "12122",
  },
  {
    label: "Employee Type",
    value: "EmployeeTypeApplicability",
    id: "12123",
  },
];
export const payloadMapper = {
  // AccountApplicability: {
  //   coverage: 'AccountApplicabilityCoverageType',
  //   array: 'Accounts',
  //   id: 'AcountID',
  //   rule: 'rule',
  // },
  ProjectApplicability: {
    coverage: "ProjectApplicabilityCoverageType",
    array: "Project",
    id: "TeamID",
    rule: "rule",
  },
  WorkplaceApplicability: {
    array: "Workplace",
  },
  TeamApplicability: {
    coverage: "TeamApplicabilityCoverageType",
    array: "Team",
    id: "TeamID",
    rule: "rule",
  },
  // UserGroupApplicability: {
  //   coverage: 'UserGroupApplicabilityCoverageType',
  //   array: 'UserGroups',
  //   id: 'UserGroupID',
  //   rule: 'UserGroupTreeRule',
  // },
  RoleApplicability: {
    coverage: "RoleApplicabilityCoverageType",
    array: "Role",
    id: "UserGroupID",
    rule: "RoleTreeRule",
  },
  // RoleGroupApplicability: {
  //   coverage: 'RoleGroupApplicabilityCoverageType',
  //   array: 'RoleGroups',
  //   id: 'RoleGroupID',
  //   rule: 'RoleGroupTreeRule',
  // },
  GradeApplicability: {
    coverage: "GradeApplicabilityCoverageType",
    array: "Grade",
    id: "GradeID",
    rule: "GradeTreeRule",
  },
  GradeLevelApplicability: {
    coverage: "GradeTypeApplicabilityCoverageType",
    array: "GradeLevel",
    id: "GradeTypeID",
    rule: "GradeTypeTreeRule",
  },
  // WorkLocationApplicability: {
  //   coverage: 'WorkLocationApplicabilityCoverageType',
  //   array: 'WorkLocation',
  //   id: 'WorkLocationID',
  //   rule: 'WorkLocationTreeRule',
  // },
  LocationApplicability: {
    coverage: "LocationApplicabilityCoverageType",
    array: "Location",
    id: "LocationID",
    rule: "LocationTreeRule",
  },
  LocationTypeApplicability: {
    coverage: "LocationTypeApplicabilityCoverageType",
    array: "LocationType",
    id: "LocationTypeID",
    rule: "LocationTypeTreeRule",
  },
  FunctionApplicability: {
    coverage: "FunctionApplicabilityCoverageType",
    array: "Function",
    id: "FunctionID",
    rule: "FunctionTreeRule",
  },
  LegalEntityTypeApplicability: {
    coverage: "LegalEntityTypeApplicabilityCoverageType",
    array: "LegalEntityType",
    id: "LegalEntityTypeID",
    rule: "LegalEntityTypeTreeRule",
  },
  LegalEntityApplicability: {
    coverage: "LegalEntityApplicabilityCoverageType",
    array: "LegalEntity",
    id: "LegalEntityID",
    rule: "LegalEntityTreeRule",
  },
  BusinessUnitTypeApplicability: {
    coverage: "BusinessUnitTypeApplicabilityCoverageType",
    array: "BusinessUnitType",
    id: "BusinessUnitTypeID",
    rule: "BusinessUnitTypeTreeRule",
  },
  BusinessUnitApplicability: {
    coverage: "BusinessUnitApplicabilityCoverageType",
    array: "BusinessUnit",
    id: "BusinessUnitID",
    rule: "BusinessUnitTreeRule",
  },
  FunctionTypeApplicability: {
    coverage: "FunctionTypeApplicabilityCoverageType",
    array: "FunctionType",
    id: "FunctionTypeID",
    rule: "FunctionTypeTreeRule",
  },
  EmployeeTypeApplicability: {
    coverage: "EmployeeTypeApplicabilityCoverageType",
    array: "EmployeeType",
    id: "EmployeeTypeID",
    rule: "EmployeeTypeTreeRule",
  },
};
export const partnerValue = {
  LegalEntityApplicability: "LegalEntityTypes",
  LegalEntityTypes: "LegalEntityApplicability",
  BusinessUnitTypeApplicability: "BusinessUnitApplicability",
  BusinessUnitApplicability: "BusinessUnitTypeApplicability",
  FunctionApplicability: "FunctionTypeApplicability",
  FunctionTypeApplicability: "FunctionApplicability",
  LocationApplicability: "LocationTypeApplicability",
  LocationTypeApplicability: "LocationApplicability",
};

// export const payloadArr={
//   BusinessUnitApplicability:"BusinessUnit"
// }
