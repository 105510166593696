// import React, { useEffect, useState } from 'react'
// import Button from '@mui/material/Button'
// import { Input } from '@mui/material'
// import * as XLSX from 'xlsx'
// import { Multiselect } from 'multiselect-react-dropdown'
// import { makeStyles } from '@mui/material/styles'
// import { TransitionGroup } from 'react-transition-group';
// import * as BackValidations from "./BackValidation";
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import InputLabel from '@mui/material/InputLabel';
// //Table Imports
// import Table from '@mui/material/Table'
// import TableBody from '@mui/material/TableBody'
// import TableCell from '@mui/material/TableCell'
// import TableContainer from '@mui/material/TableContainer'
// import TableHead from '@mui/material/TableHead'
// import TableRow from '@mui/material/TableRow'
// import Paper from '@mui/material/Paper'

// const useStyles = makeStyles({
//   table: {
//     minWidth: 650,
//   },
// });

// const sheets=[
//   'application/vnd.ms-excel',
//   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//   'text/csv'
// ];
// const validations={
//   phone:'^[789]\\d{9}$',
//   email:'^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',

// }

// function ReactExcel() {
//   const [file, setFile] = useState('');
//   const [fileJson, setFileJson] = useState({
//     data: [],
//     columns: [],
//   });
// const [backError,setBackError]=useState('');
//   const [payload,setPayload]=useState({
//     data:[]
//   })
//   const [table, setTable] = useState(false)
//   const [displaySchema, setDisplaySchema] = useState(false)
//   const [selectedValue, setSelectedVal] = useState('Select validators');
//   const [showPayload,setShowPayload]=useState(false);
//   const [selectErrorRow,setSelectErrorRow]=useState(false)
//   const[Fields,setFields]=useState([
//     {label:"EMP",matched:true,mappedTo:false},
//     {label:"EMp_name",matched:true,mappedTo:false},
//     {label:"abc",matched:true,mappedTo:false},
//     {label:'phone',matched:true,validators:['phone'],mappedTo:false},
//     {label:'email',matched:true,validators:['email'],mappedTo:false}
//   ]);
//   const [multiOption,setMultioption]=useState([]);
//   const [validObj,setValidObj]=useState({});
//   const [tableError,setTableError]=useState({});
//   function init(){
//     setBackError('')
//     setFile('');
//     setFileJson({
//       data: [],
//       columns: [],
//     });
//  setTable(false);
//  setDisplaySchema(false);
//  setShowPayload(false);
//   }
//   // useEffect(()=>{
//   //   console.log("useEffect ",fileJson)

//   //   for(let i=0;i<fileJson.data.length;i++){
//   //     console.log("inside for ")
//   //     callBack(fileJson.data[i],i);
//   //   }
//   // },[])
// useEffect(()=>{

// Fields.map((ele)=>{
// if(ele.validators?.length>0){

//   setValidObj(prev=>({
//     ...prev,
//     [ele.label]:ele.validators
//   }))
// }
// })
// },[])
//   useEffect(() => {
//     if (file !== '') readFile()
//   }, [file])
//   function filePathset(e) {
//     e.stopPropagation()
//     e.preventDefault()
//     var file = e.target.files[0]
//     console.log(file)
//     this.setState({ file })

//     console.log(this.state.file)
//   }

//   function readFile() {
//     var f = file
//     var name = f.name
//     const reader = new FileReader()
//     reader.onload = (evt) => {
//       const bstr = evt.target.result
//       const wb = XLSX.read(bstr, { type: 'binary' })
//       const wsname = wb.SheetNames[0]
//       const ws = wb.Sheets[wsname]
//       const data = XLSX.utils.sheet_to_csv(ws, { header: 1 })
//       console.log('Data>>>' + data)
//      // console.log(convertToJson(data));

//       setFileJson((prev) => ({
//         ...prev,
//         data: JSON.parse(convertToJson(data))
//       }));
//       setPayload({
//         data:JSON.parse(convertToJson(data))
//       })
//       setDisplaySchema(true)
//     }
//     reader.readAsBinaryString(f)
//   }

//   function convertToJson(csv) {
//     var lines = csv.split('\n')

//     var result = []

//     var headers = lines[0].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
//     setFileJson((prev) => ({
//       ...prev,
//       columns: headers,
//     }))
//     for (var i = 1; i < lines.length; i++) {
//       var obj = {}
//       var currentline = lines[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
//        //console.log("Header ",currentline);
//       for (var j = 0; j < headers.length; j++) {
//         obj[headers[j]] = currentline[j]
//       }

//       result.push(obj)
//     }

//     //return result; //JavaScript object
//     return JSON.stringify(result) //JSON
//   }

//   function onUploadFile(e) {
//     init();
//     e.stopPropagation()
//     e.preventDefault()
//     var file = e.target.files[0];
//     console.log(file.type)
//     if(!sheets.includes(file.type)){
//       alert('Only Excel sheets');
//       return;
//     }
//     console.log(file)
//     if(!file)return;
//     setFile(file)
//   }
// function SchemaMapper({filed}){
//   const [done,setDone]=useState(false);
//   const [matched,setMatched]=useState(false);

//   return(
//     <>
//     {done ?<>

//     </>:
//     <>
//     <Paper>
//       <div></div>

//       <div></div>
//     </Paper>

//     </>}
//     </>
//   )
// }

//   function RenderSchema() {
// const [submit,setSubmit]=useState(0);
// const [open,setOpen]=useState({count:0});
// let obj={};
// for(let i=0;i<fileJson.columns.length;i++){
//   obj={
//     ...obj,
//     [fileJson.columns[i]]:false
//   }
// }
// useEffect(()=>{
// setOpen(prev=>({
//   ...prev,
//   ...obj
// }))
// },[])

//     function MiniCard({ name }) {
//       const [selectedValue, setSelectedVal] = useState('Select Validators')
//       const [selectCount, setSelectCount] = useState(1);
//       const [paperSize, setPaperSize] = useState({
//         height: 180,
//         width: 400,
//       });

//       const [done,setDone]=useState(false);
//       const [matched,setMatched]=useState('');
//       const [ignored,setIgnored]=useState(false);
//       const [showDrop,setShowDrop]=useState(false);
//       function onSelectValue(e){
//         console.log(e.target)
//         setSelectedVal(e.target.value)
// let i=e.target.value;
// let arr=Fields;
// arr[i]={...arr[i],mappedTo:true};
// console.log(arr)
// setFields(arr);
// mapping();
//       }
//       function mapping(){
//         for(let i=0;i<Fields.length;i++){
//           if(i===Fields.length-1){
//             setShowDrop(true);
//           }
//           if(Fields[i].label.toLowerCase()===(""+name).toLowerCase() || Fields[i].mappedTo){
//             //console.log("Test ",Fields[i].label," ",name)
//             setMatched(Fields[i].label);
//             let arr=Fields;
//             arr[i].matched=false;
//            setFields(arr);

//             break;
//           }

//         }
//       }
//    useEffect(()=>{
//     mapping();
//    },[Fields]);

//       const options = [
//         { name: 'Not Null', id: 1 },
//         { name: 'Email', id: 2 },
//         { name: 'unique', id: 3 },
//       ];
//       function onSelect(a, b) {
//         console.log(a," ",b);
//         setFields(prev=>([
//           ...prev,
//           {label:[b.label],matched:false}
//         ]))
//         // setSelectCount(selectCount + 1);
//         // if (selectCount % 3 === 0 && selectCount !== 0) {
//         //   setPaperSize((prev) => ({
//         //     height: prev.height + 30,
//         //     width: prev.width + 100,
//         //   }))
//         // }
//         //  console.log(a," ",b);
//       }
//       function onRemove(a, b) {
//         console.log(a, ' ', b)
//       }

//       return (
//         <>
//          {!open[name]? <Paper
//             style={{
//               display: 'flex',
//               height: paperSize.height,
//               width: paperSize.width,
//               alignItems: 'center',
//               padding:20
//             }}
//           >
//             <div
//               style={{
//                 display: 'flex',
//                 flexDirection: 'row',
//                 width: '100%',
//                 justifyContent: 'space-around',
//                 alignItems: 'center',
//               }}
//             >
//               <div style={{width:'40%'}}>
//                 <h6>{name}</h6>
//               </div>
//               <div>

//                   {/* <li>{matched!==''?<span>{"✔ Mathed with field name "}<span style={{backgroundColor:'#dbe5f0',color:'#1d2830'}}>{matched}</span> </span> :<span>{"❌ Unable to automatically match"}</span>}</li>
//                   <li></li> */}
//                   {matched!==''?
//                   <ul style={{listStyle:'none'}}>
//                     <li><span>{"✔ Mathed with field name "}<span style={{backgroundColor:'#dbe5f0',color:'#1d2830'}}>{matched}</span> </span></li>
//                     <li><span>{"✔ All values pass validation for this field"}</span></li><br />
//                     <li><div> <Button variant="contained" size='small' style={{backgroundColor:'#5cb85c',color:'white'}} onClick={()=>{setSubmit(submit+1);setOpen(prev=>({...prev,[name]:true,count:prev.count+1}));console.log("Opened ",open)}}>Confirm</Button> <Button onClick={()=>{setOpen(prev=>({...prev,[name]:true,count:prev.count+1}));setIgnored(true);setSubmit(submit+1);}}>Ignore</Button></div></li>
//                 </ul>
//                 :<ul style={{listStyle:'none'}}>
// <li>
// <span>{"❌ Unable to automatically match"}</span>
// </li>
// <li>

//   {/* { showDrop &&  <Multiselect
//                   placeholder={'Lookup Matching Fields'}
//                   // options={Fields.filter((fil)=>{
//                   //   console.log("From Filter ",fil)
//                   //   return fil.matched})}
//                   options={Fields.filter(fil=>fil.matched)}
//                   hidePlaceholder={true}
//                   closeOnSelect={false}
//                   selectedValues={[]}
//                   onSelect={onSelect}
//                   onRemove={onRemove}
//                   // singleSelect={true}
//                   selectionLimit={1}
//                   displayValue="label"
//                 />
//   } */}
//  {showDrop && <>
//  <br />
//   <InputLabel >{"Validators"}</InputLabel>
// <Select
// value={selectedValue}
// onChange={onSelectValue}
// style={{width:'100%'}}
// >
// {
//   Fields.map((fi,index)=>{
//     if(fi.matched){
//       return(
//         <MenuItem value={index}>{fi.label}</MenuItem>
//       )
//     }
//   })
// }
// </Select>
//  </>}
// </li><br />
// <li><Button onClick={()=>{setOpen(prev=>({...prev,[name]:true,count:prev.count+1}));setIgnored(true);setSubmit(submit+1);}} >Ignore</Button></li>
//                 </ul>
//                 }

//               </div>
//             </div>
//           </Paper>:
//           <Paper
//           style={{
//             width:400,
//             height:80
//           }}
//           >
// <div style={{width:'100%',display:'flex',justifyContent:'space-around'}}>
//   <span>{name}</span>
// <span>{ignored ? <span>{" Ignored Column "+name} </span>:<span>{"✔ Column "+name+" Confirmed"}</span>}</span>
// </div>
// <div style={{width:'100%',justifyContent:'center',display:'flex',height:'100%',alignItems:'center'}}><br />
//   <Button onClick={()=>{setOpen(prev=>({...prev,[name]:false,count:prev.count-1}));setIgnored(false);setSubmit(submit-1);}}>Revert</Button>
// </div>

//           </Paper>

//           }
//         </>
//       )
//     }

//     return (
//       <>
//         {fileJson.columns.map((ele) => {
//           return (
//             <>
//               <MiniCard name={ele} />
//               <br />
//             </>
//           )
//         })}

//         <Button
//         variant="contained" color="primary"
//           onClick={() => {
//             setTable(true);
//             backCall();
//           }}
//           disabled={open.count!==fileJson.columns.length}
//         >
//           Apply
//         </Button>
//       </>
//     )
//   }
//  function backCall(){
//    BackValidations.BackUnique(fileJson.data,(result)=>{
// if(result.Error){
//   setBackError(result.Error)
// }
//    })
//  }

//   function RenderTable() {
//     var errorCount=0;

//     return (
//       <div style={{ width: '90%',marginTop:30 }}>
//         <TableContainer component={Paper}>
//           <Table aria-label="simple table">
//             <TableHead>
//               <TableRow>
//                 {/* <TableCell>{"Validate"}</TableCell> */}
//                 {fileJson.columns.map((ele) => {
//                   return (
//                     <>
//                       <TableCell align="left">{ele}</TableCell>
//                     </>
//                   )
//                 })}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {fileJson.data.map((ele, index) => (

//                 <TableRow key={index}>
//                   {/* <TableCell>{rowValidate[index]?"❌":"✔"}</TableCell> */}

//                   {Object.keys(ele).map((item) => {
//                     var lowerItem=item.toLowerCase();
//                     var flag=false;
//                    if(validObj[lowerItem]){
//                     let valid=validObj[lowerItem];

//                     valid.map((val)=>{

//                       var pattern=new RegExp(validations[val]);
//                       if(!pattern.test(ele[item])){
//                         flag=true;
//                        errorCount++;
//                       }

//                     })
//                    }

//                     return (
//                     <>

//                     <TableCell align="left"><input style={{border:0,color:flag?'red':'black'}} onChange={(e)=>{

//                       flag=false;
//                       errorCount--;
//                      // setErrorCount(errorCount-1);
//                       if(validObj[lowerItem]){
//                         let valid=validObj[lowerItem];

//                         valid.map((val)=>{
//                           console.log("Called ",val," ",validations)

//                           var pattern=new RegExp(validations[val]);
//                           if(!pattern.test(e.target.value)){
//                            // console.log("Regex ",pattern," ",ele[item]," ",pattern.test(ele[item]));
//                             flag=true;
//                             errorCount++;
//                           //  setErrorCount(errorCount+1);
//                           }

//                         })
//                        }
//                       setFileJson(prev=>{
//                         let obj=prev.data;
//                         obj[index][item]=e.target.value;

//                         return(
//                           {
//                             ...prev,
//                             data:obj
//                           }
//                         )

//                       })
//                     }} value={ele[item]} /></TableCell>
//                     </>)
//                   })}
//                 </TableRow>

//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <div style={{width:'100%',display:'flex',justifyContent:'center',marginTop:30}}>
//           <Button variant="contained" color='default' onClick={()=>{setTable(false)}} >{"< "}Back</Button>
//         <Button variant="contained" color="primary" style={{marginLeft:20}} onClick={()=>{setShowPayload(true)}} disabled={errorCount!==0}>Upload</Button>
//         <Button variant="contained" style={{backgroundColor:'green',color:'white',marginLeft:20}} >Export</Button>
//         </div>
//       </div>
//     )
//   }

//   return (
//     <>
//       <input  type="file" onChange={onUploadFile} />
//       <div
//         style={{
//           display: 'flex',
//           width: '100%',
//           alignItems: 'center',
//           marginTop: 50,
//           flexDirection: 'column',
//         }}
//       >

//         {displaySchema && !table && <RenderSchema/>}

//         {/* {JSON.stringify(tableError)!=='{}' && <div style={{width:'100%',backgroundColor:'red',color:'white'}}>
//         <ul >
//        {Object.keys(tableError).map((err)=>{
//          if(err==='')return (<></>);

//          return(
//              <li>{tableError[err]}</li>
//          )
//        })}
//         </ul>
//        </div>
//        } */}

//        {backError!=='' && <span>{backError}</span>}
//         {table && <RenderTable />}
//         {showPayload && <div>{JSON.stringify(fileJson.data)}</div>}

//       </div>
//       <br />
//       <br />
//     </>
//   )
// }

export default () => <></>;
