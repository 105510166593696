import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import Molecule from "../../MComponent/Molecule";
import "./pms.css";
import Coaching from "./Coaching";
import SelfDevelopment from "./SelfDevelopment";
import TrainingNeeds from "./TrainingNeeds";
import Confidential from "./Confidential";
import Dimension from "./Dimension/Dimension";
import Summary from "./Summary/Summary";
import { useLocation } from "react-router-dom";
import { getActions, getData, getUserId } from "../../Utils/Common/Library";
import PTA from "../pms/Dimension/PTA.json";
import FeatureIDs from "../../Utils/Common/FeatureIDs";
import { useHistory } from "react-router-dom";

const { Tabs, EwModal } = Molecule;

function getSection(type, eppData) {
  switch (type) {
    case "SummarySection":
      return {
        Component: Summary,
        props: {
          EPP: eppData,
        },
      };
    case "CoachingPlan":
      return {
        Component: Coaching,
      };
    case "SelfDevelopment":
      return {
        label: "Self Development",
        Component: SelfDevelopment,
      };
    case "TrainingNeeds":
      return {
        Component: TrainingNeeds,
      };
    case "ConfidentialSection":
      return {
        Component: Confidential,
        props: {
          EPP: eppData,
        },
      };
    default:
      return {
        Component: () => {
          return (
            <div className="Flex">
              <Typography variant="h4">Invalid Section Type</Typography>
            </div>
          );
        },
      };
  }
}

export default function PMS() {
  const [modal, setModal] = useState(null);
  const [MppDescription, setMPPDescription] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const Actions = getActions();

  const [Plan, setPlanData] = useState(location?.state?.Plan);
  const [isLoading, setIsLoading] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (location?.state?.Plan) {
      console.log(Plan, location?.state?.Plan, "EPP_NAV_DATA");
      sessionStorage.setItem("EPP_NAV_DATA", Plan?.TransactionID);
    } else if (
      sessionStorage.getItem("EPP_NAV_DATA") &&
      typeof sessionStorage.getItem("EPP_NAV_DATA") === "string"
    ) {
      const EPPID = sessionStorage.getItem("EPP_NAV_DATA");
      setIsLoading(true);
      getData({
        featureId: FeatureIDs.EmployeePerformancePlan,
        payload: {
          "Header.TransactionID": EPPID,
        },
      })
        .then((res) => {
          if (typeof res === "string") {
            sessionStorage.clear();
            history.push("/myteamplan");
          } else {
            console.log(res, "!location.state && sessionStorage.getItem");
            setPlanData(res.Data[0]);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      history.push("/myteamplan");
    }

    const MPPID = (location?.state?.Plan || PTA[0].EmployeePerformancePlanData)
      ?.MasterPerformancePlanID;
    if (MPPID) {
      getData({
        featureId: FeatureIDs.MasterPerformancePlan,
        payload: {
          "Header.TransactionID": MPPID,
        },
        fields: ["Data.PlanDescription"],
      }).then((res) => {
        if (res.Data && res.Data[0]) {
          console.log("MPP daat: ", res.Data);
          setMPPDescription(res.Data[0].PlanDescription);
        }
      });
      return () => {
        dispatch({
          type: Actions.ADD_SIDE_COMPONENT,
          payload: null,
        });
      };
    }
  }, []);

  const Dimensions = [
    ...(Plan?.Dimensions || []).map((dimension) =>
      dimension.DimensionID
        ? {
            label: dimension.DimensionName || "Unnamed Dimension",
            Component: Dimension,
            props: {
              EmployeePerformancePlanID: Plan?.TransactionID,
              DimensionID: dimension.DimensionID,
              DimensionName: dimension.DimensionName || "Unnamed Dimension",
              DimensionPhase: "G",
            },
          }
        : undefined
    ),
  ].filter((dimension) => dimension !== undefined);

  const Sections = [
    ...(Plan?.Sections || []).map((section) => {
      return section.SectionType === "ConfidentialSection" &&
        getUserId() === Plan?.AppraiseeUserID
        ? undefined
        : {
            label: section.SectionName || "Unnamed Section",
            ...getSection(section.SectionType, Plan),
          };
    }),
  ].filter((section) => section !== undefined);

  if (Dimensions.length === 0 && Sections.length === 0) {
    Dimensions.push({
      label: "Notice",
      Component: () => {
        return (
          <div className="Flex">
            <Typography variant="h6">
              All the sections and dimensions are disabled!
            </Typography>
          </div>
        );
      },
    });
  }

  console.log(Plan, "EPP DATA", getUserId());

  return (
    <>
      <EwModal
        open={modal !== null ? true : false}
        onClose={() => setModal(null)}
      >
        {modal}
      </EwModal>
      <div>
        <Box className="Flex" sx={{ alignItems: "normal", gap: "1em" }}>
          <Typography variant="h2" color={"primary"}>
            Performance Plan
          </Typography>
          <Typography variant="caption">{MppDescription}</Typography>
          {isLoading === true ? (
            <>Reloading Performance plan</>
          ) : (
            <Tabs componentList={[...Sections, ...Dimensions]} />
          )}
        </Box>
      </div>
    </>
  );
}
