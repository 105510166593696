import React, { useState, useEffect } from "react";
import Atomic from "../../../AComponent/Atomic";
import EwTable from "../../../MComponent/Table/Table";
import AlertMessage from "../../../Utils/Messages/AlertMessage";
import { submitPayload, handleResult } from "../../../Utils/Common/Library";
const { Button, Input, Radio, Dropdown } = Atomic;

const FeatureID = "19145";
const fieldList = {
  GroupLevel: {},
  GroupName: {},
  DimensionID: {},
  GroupShortName: {},
};
function DPG() {
  const [localState, setLocalState] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const {
    DimensionID,
    GroupLevel,
    GroupName,
    GroupShortName,
    TransactionID,
    EffectiveFromTimestamp,
    GroupDescription,
  } = localState;
  function updateState(val, type) {
    setLocalState((prev) => ({ ...prev, [type]: val }));
  }
  useEffect(() => {
    if (openForm === false) {
      setLocalState({});
    }
  }, [openForm]);
  function onSave() {
    setLoading(true);
    let payload = localState;
    let edit = null;
    if (TransactionID) {
      edit = {
        TransactionID,
        EffectiveFromTimestamp,
        RequestType: "1",
      };
    }
    submitPayload({
      featureId: FeatureID,
      payload,
      edit,
    })
      .then((res) => {
        let type = handleResult(res);
        setMessage({
          type: 0,
          message: type === 1 ? "Group Added!" : type === 2 && "Group Edited",
        });
        setOpenForm(false);
      })
      .catch((e) => {
        setMessage({
          type: 1,
          message: "Network Error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function docSelected(data) {
    setLocalState(data);
    setOpenForm(true);
  }
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
  }, [message]);
  const heading = ["Group Name", "Edit"];
  if (loading) return <>Loading..</>;
  return (
    <>
      {message && (
        <AlertMessage type={message.type} message={message.message} />
      )}
      <br />
      {!openForm ? (
        <div>
          <EwTable
            heading={heading}
            featureId={FeatureID}
            rowToFieldMap={["GroupName"]}
            onDocSelected={docSelected}
            includeOprations={true}
          />
          <br />
          <div className="Flex">
            <Button title={"Add New"} onClick={() => setOpenForm(true)} />
          </div>
        </div>
      ) : (
        <div>
          <div>
            <Button
              variant={"Text"}
              title={"⬅️ Groups"}
              onClick={() => setOpenForm(false)}
            />
          </div>
          <br />
          <div className="Flex">
            <div style={{ width: "80%" }}>
              <Dropdown
                label="Select Dimension"
                featureId={"19144"}
                mapper={{
                  label: "DimensionName",
                  value: "TransactionID",
                }}
                //  menuItems={dropdownList}
                value={DimensionID}
                onChange={(val) => updateState(val, "DimensionID")}
              />
            </div>
          </div>
          <br />
          {DimensionID && DimensionID !== -1 && (
            <>
              <div className="Flex">
                <Radio
                  flex={1}
                  label="Group Level"
                  menuItems={[
                    { label: "Level 1", value: "1" },
                    { label: "Level 2", value: "2" },
                  ]}
                  value={GroupLevel}
                  onChange={(val) => updateState(val, "GroupLevel")}
                />
              </div>
              <br />
              <div className="Flex-row">
                <div>
                  <Input
                    label="Group Name"
                    value={GroupName}
                    onChange={(val) => updateState(val, "GroupName")}
                  />
                </div>
                <div>
                  <Input
                    label="Group Short Name"
                    value={GroupShortName}
                    onChange={(val) => updateState(val, "GroupShortName")}
                  />
                </div>
              </div>
              <br />
              <div className="Flex">
                <Input
                  label="Group Description"
                  value={GroupDescription}
                  onChange={(val) => updateState(val, "GroupDescription")}
                  rows={4}
                />
              </div>
              <br />
              <div className="Flex">
                <Button title={"Save"} onClick={onSave} />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default DPG;
