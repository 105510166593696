import React, { useState, useEffect } from "react";
import useReduxStore from "../../Utils/CustomHooks/ReduxUpdater";
import Molecule from "../../MComponent/Molecule";
import Atomic from "../../AComponent/Atomic";
import DateRangeIcon from "@mui/icons-material/DateRange";
import useWindowDimensions from "../../Utils/CustomHooks/WindowRezise";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import {
  submitPayload,
  handleResult,
  getData,
} from "../../Utils/Common/Library";
import AlertMessage from "../../Utils/Messages/AlertMessage";
import FeatureIDs from "../../Utils/Common/FeatureIDs";

const { EwModal } = Molecule;
const { Dropdown, Button } = Atomic;
const MasterPlanFeatureID = "19149";
const FeatureID = "19163";
const PlanCycleFeatureID = "19154";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const mapper = {
  A: "Annual",
  H: "Half Yearly",
  Q: "Quaterly",
  M: "Monthly",
};
function Assesment({ onClose }) {
  const [data, updateState] = useReduxStore({ id: "Assement" });
  const [modal, setModal] = useState(true);
  const [typeList, setTypeList] = useState([]);
  const [message, setMessage] = useState(null);
  const [dimensions, setDimensions] = useState([]);
  const [dimensionactivitiesMap, setDimensionActivity] = useState(null);
  const { width, height } = useWindowDimensions();
  const {
    AssessmentFrom,
    AssessmentTill,
    ReviewLaunchDateAndTime,
    AssessmentType,
    PlanPeriodFrom,
    MPPID,
    PlanPeriodTill,
    AssessmentPeriodType,
    AssessmentPeriod,
    selectedDoc,
    planCycleObj,
    periodList,
    planCycleListIdMap,
    planCycleList,
    selectedPlanCycle,
    allcycles,
    DimensionID,
  } = data;
  useEffect(() => {
    let arr = [];
    let planDocument;
    if (planCycleListIdMap)
      planDocument = planCycleListIdMap[selectedPlanCycle];

    if (MPPID && planDocument && DimensionID)
      getData({
        featureId: FeatureID,
        payload: {
          "Data.MPPID": MPPID,
          "Data.PlanPeriodFrom": planDocument.PlanCycleStartDate,
          "Data.PlanPeriodTill": planDocument.PlanCycleEndDate,
          "Data.DimensionID": DimensionID,
        },
      })
        .then((res) => {
          console.log("THis is result: res is", planDocument);
          if (res.Data && res.Data.length > 0) {
            res.Data.forEach(({ AssessmentPeriod }) => {
              arr.push(AssessmentPeriod);
            });
            console.log("THis is result: ", res, " ", planDocument, " ", arr);
            setTypeList([...arr]);
          } else {
            setTypeList([]);
            console.log("THis is result: ", "No result");
          }
        })
        .catch((e) => {
          console.log("THis is result: ", e);
        });
  }, [selectedPlanCycle]);
  function getPlanCycle(id, doc) {
    console.log("DOc is: ", doc);
    let obj = {};
    doc?.Dimension?.forEach(({ DimensionID, DimensionActivities }) => {
      let activityIndex = DimensionActivities?.findIndex(
        ({ ActivityType }) => ActivityType === "PR"
      );
      if (activityIndex !== -1) {
        obj[DimensionID] = {
          activity: DimensionActivities[activityIndex],
        };
      }
    });
    console.log("Dimension object: 1", obj);
    //  setDimensionActivity({...obj});
    getData({
      featureId: FeatureIDs.PerformanceDimensionGrouping,
      payload: {
        "Header.Status": "1",
      },
    })
      .then((res) => {
        if (res?.Data) {
          res.Data.forEach(({ TransactionID, DimensionName }) => {
            if (obj[TransactionID]) {
              obj[TransactionID].name = DimensionName;
            }
            //   arr.push({ label: DimensionName, value: TransactionID });
          });
          console.log("Dimension object: ", obj);
          //setDimensions(arr);
          setDimensionActivity({ ...obj });
        }
      })
      .catch((e) => {
        console.log(e);
        //    setDimensions(null);
      });
    getData({
      featureId: PlanCycleFeatureID,
      payload: {
        "Data.MPPID": id,
      },
    }).then((res) => {
      console.log("Result: ", res);
      if (res.Data && res.Data[0]) {
        // updateState(res.Data[0], "planCycleObj");
        // getList(doc, res.Data[0]);
        let menuItems = [];
        let obj = {};
        res.Data.forEach((ele) => {
          menuItems.push({
            label: ele.PlanCycle,
            value: ele.TransactionID,
          });
          obj[ele.TransactionID] = ele;
        });
        updateState(obj, "planCycleListIdMap");
        updateState(menuItems, "planCycleList");
      } else {
        updateState(undefined, "planCycleObj");
        updateState(undefined, "planCycleList");
        updateState(undefined, "planCycleListIdMap");
        setMessage({
          type: 1,
          message: "Plan Cycle Not Started For This Master Plan !",
        });
      }
    });
  }
  function getDayString(date) {
    let month = date.getMonth();
    let day = date.getDate();
    let year = date.getFullYear();
    return `${months[month]} ${day}, ${year}`;
  }

  function getMonthList(startDate, endDate, num) {
    let startMonth = startDate.getMonth();
    let endMonth = endDate.getMonth();
    let startYear = startDate.getFullYear();
    let endYear = endDate.getFullYear();
    let arr = [];
    let tempMonths = months.map((ele) => ele + " " + startYear);
    if (startMonth > endMonth) {
      tempMonths = [];
      for (let i = startMonth; i < 12; i++)
        tempMonths.push(months[i] + " " + startYear);
      for (let i = 0; i <= endMonth; i++)
        tempMonths.push(months[i] + " " + endYear);
    }
    console.log("Month test: ", tempMonths);

    if (num === 1) {
      return tempMonths.map((ele, index) => ({
        label: ele,
        value: "M" + (index + 1),
      }));
    } else if (num === 3) {
      let k = 1;
      for (let i = 0; i + 2 < 12; i = i + 3) {
        arr.push({
          label: `${tempMonths[i]}-${tempMonths[i + 2]}`,
          value: "Q" + k,
        });
        k++;
      }
      return arr;
    } else {
      let k = 1;
      for (let i = 0; i + 5 < 12; i = i + 6) {
        arr.push({
          label: `${tempMonths[i]}-${tempMonths[i + 5]}`,
          value: "H" + k,
        });
        k++;
      }
      return arr;
    }
  }
  function getList(doc, plan) {
    //  const { PeriodicReviewFrequency: freq } = doc;

    const { ActivityFrequency: freq } =
      dimensionactivitiesMap[DimensionID].activity;
    if (!freq) return;
    let { PlanCycleStartDate: start, PlanCycleEndDate: end } = plan;

    start = new Date(+start);
    end = new Date(+end);

    let arr = [];
    if (freq === "PE" || freq === "PS") {
      arr.push({
        label: `${getDayString(start)} - ${getDayString(end)}`,
        value: "A",
      });
    } else if (freq === "HA") {
      arr = getMonthList(start, end, 6);
      //   arr = [
      //     {
      //       label: "First Half",
      //       value: "H1",
      //     },
      //     {
      //       label: "Second Half",
      //       value: "H2",
      //     },
      //   ];
    } else if (freq === "QT") {
      arr = getMonthList(start, end, 3);
      //   arr = [
      //     { label: "Quater 1", value: "Q1" },
      //     { label: "Quater 2", value: "Q2" },
      //     { label: "Quater 2", value: "Q3" },
      //     { label: "Quater 4", value: "Q4" },
      //   ];
    } else {
      arr = getMonthList(start, end, 1);
      //   for (let i = 0; i < 12; i++) {
      //     // arr.push({
      //     //   label: months[i],
      //     //   value: "M" + (i + 1),
      //     // });
      //   }
    }
    updateState(arr, "periodList");
  }
  function getDaysInMonth(year, month) {
    year = +year;
    month = +month;
    let date = new Date(year, month + 1, 0).getDate();

    console.log(
      "Month and year : ",
      month,
      " ",
      month + 1,
      " ",
      year,
      " ",
      date,
      " ",
      new Date(year, month + 1, 0)
    );

    return date;
  }

  function onStart(label, value) {
    //let freq = selectedDoc.PeriodicReviewFrequency;
    const freqMapper = {
      PE: "A",
      MO: "M",
      QT: "Q",
      HA: "H",
      PS: "A",
    };
    let { ActivityFrequency: freq } =
      dimensionactivitiesMap[DimensionID].activity;
    if (!freq) return;
    freq = freqMapper[freq];
    let { PlanCycleEndDate, PlanCycleStartDate } = planCycleObj;
    let payload = {
      AssessmentFrom,
      AssessmentTill,
      ReviewLaunchDateAndTime: "",
      AssessmentType: "P",
      PlanPeriodFrom: PlanCycleStartDate,
      MPPID,
      PlanPeriodTill: PlanCycleEndDate,
      AssessmentPeriodType: freq,
      AssessmentPeriod: value,
      DimensionID,
    };
    if (freq === "A") {
      payload.AssessmentFrom = PlanCycleStartDate;
      payload.AssessmentTill = PlanCycleEndDate;
    } else if (freq === "H" || freq === "Q") {
      let [start, end] = label.split("-");
      start = start.split(" ");
      end = end.split(" ");
      console.log(
        "Start and end: ",
        end[1],
        " ",
        months.indexOf(end[0]),
        " ",
        getDaysInMonth(end[1], months.indexOf(end[0]))
      );
      payload.AssessmentFrom =
        "" + new Date(start[1], months.indexOf(start[0])).getTime();
      payload.AssessmentTill =
        "" +
        new Date(
          end[1],
          months.indexOf(end[0]),
          getDaysInMonth(end[1], months.indexOf(end[0]))
        ).getTime();
    } else {
      let [month, year] = label.split(" ");
      let date = new Date(year, months.indexOf(month)).getTime();
      let date2 = new Date(
        year,
        months.indexOf(month),
        getDaysInMonth(year, months.indexOf(month))
      ).getTime();
      payload.AssessmentFrom = "" + date;
      payload.AssessmentTill = "" + date2;
    }
    console.log("Start and end: 1", payload);

    submitPayload({ featureId: FeatureID, payload })
      .then((res) => {
        if (handleResult(res) === 1) {
          let arr = typeList;
          arr.push(value);
          setTypeList([...arr]);
          setMessage({
            type: 0,
            message: "Assessment Started! For " + label,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setMessage({
          type: 1,
          message: "Network Error !",
        });
      });
  }

  return (
    <div>
      <EwModal
        open={modal}
        onClose={() => {
          updateState(undefined, "MPPID");
          updateState(undefined, "selectedDoc");
          updateState(undefined, "planCycleObj");
          updateState(undefined, "planCycleList");
          updateState(undefined, "DimensionID");
          updateState(undefined, "planCycleListIdMap");
          updateState(-1, "selectedPlanCycle");
          setModal(false);

          if (onClose) onClose();
        }}
      >
        <div
          style={{
            minWidth: 0.7 * width,
            minHeight: 0.8 * height,
            width: 0.7 * width,
          }}
        >
          <Typography variant="h3">Assessment Initiation</Typography>
          <hr />
          <br />
          <div>
            <Dropdown
              label={"Master Plans"}
              featureId={MasterPlanFeatureID}
              mapper={{
                label: "PlanName",
                value: "TransactionID",
              }}
              value={MPPID}
              onChange={(val, doc) => {
                updateState(val, "MPPID");
                updateState(doc, "selectedDoc");
                updateState(undefined, "planCycleObj");
                updateState(undefined, "planCycleList");
                updateState(undefined, "planCycleListIdMap");
                updateState(-1, "selectedPlanCycle");

                getPlanCycle(val, doc);

                //  getList(doc);
              }}
              //filter={(item) => item.PeriodicReview === "E"}
              fields={[
                "Header.*",
                "Data.PlanName",
                "Data.PlanStart",
                "Data.PlanEnd",
                "Data.Dimension",
                // "Data.PeriodicReview",
                // "Data.PeriodicReviewFrequency",
              ]}
            />
          </div>
          <br />
          {message && (
            <>
              <AlertMessage {...message} onClose={() => setMessage(null)} />
              <br />
            </>
          )}
          <br />
          {dimensionactivitiesMap && (
            <Dropdown
              label="Dimensions"
              menuItems={Object.entries(dimensionactivitiesMap).map(
                ([key, { name }]) => ({ label: name, value: key })
              )}
              value={DimensionID}
              onChange={(val) => updateState(val, "DimensionID")}
            />
          )}
          <br />
          {planCycleList && DimensionID && DimensionID !== -1 && (
            <Dropdown
              label={"Select Plan Cycle"}
              menuItems={planCycleList}
              value={selectedPlanCycle}
              onChange={(val) => {
                updateState(val, "selectedPlanCycle");
                if (!val || val == -1) {
                  updateState(undefined, "planCycleObj");
                  return;
                }
                let details = planCycleListIdMap[val];

                updateState(details, "planCycleObj");
                getList(selectedDoc, details);
              }}
            />
          )}

          <br />
          {selectedDoc && planCycleObj && (
            <div>
              <Typography variant="h6">
                Assessment Period Type:{" "}
                {mapper[selectedDoc.PeriodicReviewFrequency]}
              </Typography>
              <br />

              <div className="Flex">
                <Grid
                  className="scroll"
                  style={{ overflowY: "auto", height: "45vh", width: "70%" }}
                  container
                  spacing={2}
                >
                  {periodList &&
                    periodList?.map(({ label, value }) => (
                      <>
                        <Grid item xs={5}>
                          <Typography
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            {label}
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          {typeList.includes(value) ? (
                            <Button
                              title={"Stop Assessment"}
                              color={"error"}
                              onClick={() => {}}
                            />
                          ) : (
                            <Button
                              title={"Start Assessment"}
                              variant={"outlined"}
                              onClick={() => onStart(label, value)}
                            />
                          )}
                        </Grid>
                        <br />
                      </>
                    ))}
                </Grid>
              </div>
            </div>
          )}
        </div>
      </EwModal>
    </div>
  );
}

export default Assesment;
