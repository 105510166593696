import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";

const map = {
  0: "success",
  1: "error",
  2: "info",
  3: "warning",
};

function AlertMessage(
  props = {
    type: null,
    message: null,
    onClose: null,
    autoClose: false,
    autoCloseTime: 3000,
  }
) {
  var { type, message, onClose, autoClose, autoCloseTime } = props;
  if (!type && type !== 0) type = 1;
  console.log("From Alert: ", props);
  useEffect(() => {
    if (autoClose) {
      setTimeout(() => {
        onClose();
      }, autoCloseTime);
    }
  }, [message]);
  return (
    <Alert onClose={onClose} severity={map[type]}>
      {message}
    </Alert>
  );
}

export default AlertMessage;
