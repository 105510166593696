import { useState, useEffect } from "react";

function getWindowDimensions() {
  //const { innerWidth: width, innerHeight: height } = window;
  return {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  };
}

export default function useWindowDimensions(
  props = {
    onResize: false,
  }
) {
  if (!props) props = {};
  const { onResize } = props;
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    if (onResize) window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
