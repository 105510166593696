import React from 'react';
import ChatJson from '../Testjson/APIs/TransactionChat.json';

function AChatWindow(){

    // fetch the chat json

    return(
        <div className="chatWindow">
            {
                ChatJson['Chats'].map((index,i) => {
                    console.log(index);
                    return  <div className="chatbox">
                                <div className={`${index.sender}`}>
                                    {index.content}
                                    <br/>
                                </div>
                            </div>
                })
            }
        </div>
    );
}

export default AChatWindow;