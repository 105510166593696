import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const initalProps = {
  accordians: [
    {
      label: null,
      Component: null,
      subHeading: null,
      disable: false,
      renderComponent: null,
      subHeading: null,
      params:[]
    },
  ],
};

export default function EwAccordion(props = initalProps) {
  const { accordians } = props;
  return (
    <div>
      {accordians?.map(
        (
          { Component, label, disable, subHeading, props, renderComponent,params },
          index
        ) => {
          console.log("Cases Length: 1",params)
          if (typeof disable === "undefined") disable = false;
          if (!props) props = {};
          if(!params)params=[]
          return (
            <Accordion disable={disable}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="content"
                id={"header" + index}
              >
                <Typography>{label}</Typography>
                {subHeading && (
                  <Typography sx={{ color: "text.secondary" }}>
                    {subHeading}
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails>
                {renderComponent ? (
                  <>{renderComponent(...params)}</>
                ) : (
                  <Component {...props} />
                )}
              </AccordionDetails>
            </Accordion>
          );
        }
      )}
    </div>
  );
}
