import React, { useEffect, useState } from "react";
import GroupAccordian from "../GroupAccordian";
import Molecule from "../../../../MComponent/Molecule";
import { getData } from "../../../../Utils/Common/Library";
import { Typography } from "@mui/material";
import ActorAccordian from "./ActorAccordian";
import Atomic from "../../../../AComponent/Atomic";

const { EwModal, Table, Tabs } = Molecule;
const { Button } = Atomic;

const actorMap = {
  S: "Self",
  A: "Appraiser",
  R: "Reviewer",
};

function HistoryModal({ ParameterAssessment, TargetRule, RatingScaleID }) {
  const [loading, setLoading] = useState(false);
  const [scale, setScale] = useState(null);

  console.log(
    ParameterAssessment,
    TargetRule,
    RatingScaleID,
    "PROPS FOR HISTORY"
  );

  useEffect(() => {
    if (RatingScaleID) {
      setLoading(true);
      getData({
        featureId: "19148",
        payload: { "Header.TransactionID": RatingScaleID },
      })
        .then((response) => {
          setScale(response.Data[0]);
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
  }, [RatingScaleID]);

  return loading || !scale ? (
    <div className="Flex">Loading...</div>
  ) : (
    <div
      className="scroll"
      style={{
        height: "60vh",
        width: "50vw",
        padding: "0.6em",
        paddingRight: "1.3em",
      }}
    >
      <Typography variant="h5" fontWeight={"600"} sx={{ mb: "2em" }}>
        History
      </Typography>
      {ParameterAssessment.ActorAssessments.filter(
        (item) => item.Achievement && item.Achievement !== ""
      ).map((assessment) => {
        const { Achievement, RatingValue, RatingScaleValueID, Score } =
          assessment;
        return (
          <ActorAccordian
            ActorName={actorMap[assessment.ActorType]}
            key={assessment.TransactionDocumentID}
          >
            <div>
              <div
                className="Flex-row"
                style={{
                  alignItems: "flex-start",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <Typography variant="h6">Achievement</Typography>
                  <Typography variant="overline">
                    {Achievement || "N/A"}
                  </Typography>
                </div>
                <div>
                  <Typography variant="h6">Rating</Typography>
                  <Typography variant="overline">
                    {TargetRule.targetType === "FV"
                      ? RatingValue
                      : (scale?.ScaleValues || []).find(
                          (value) => value.OptionID === RatingScaleValueID
                        )?.Label || "N/A"}
                  </Typography>
                </div>
                <div>
                  <Typography variant="h6">Score</Typography>
                  <Typography variant="overline">{Score || "N/A"}</Typography>
                </div>
              </div>
            </div>
          </ActorAccordian>
        );
      })}
    </div>
  );
}

function History({ ParameterAssessment, TargetRule, RatingScaleID }) {
  const [modal, setModal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setModal({
            Component: HistoryModal,
            props: { ParameterAssessment, TargetRule, RatingScaleID },
          });
          setModalOpen(true);
        }}
        title={"History"}
      />
      {modal && (
        <EwModal onClose={() => setModalOpen(false)} open={modalOpen}>
          <modal.Component {...modal.props} />
        </EwModal>
      )}
    </>
  );
}

export default History;
