// import React from "react";
// import Gateway from "../Utils/Api/Gateway";
// import Data4 from "../Testjson/Layout/layout5.json";
// import Data5 from "../Testjson/Homepage/NTA.json";
// import Data6 from "../Testjson/Shell/TenantForm.json";
// import profile from "../Testjson/Layout/profile.json";
// import Education from "../Testjson/Common/Education.json";
// import Workflow from "../Testjson/Workflow/workflow.json";
// import LayoutRenderer from "../Interpreter/LayoutRenderer";
// import { useSelector, useDispatch } from "react-redux";
// import Button_1 from "../AComponent/Buttons/Button_1";
// //import { Container, Button, Link as Lk } from 'react-floating-action-button'
// import FLoatAction from "../Utils/FloatAction";
// function Layout() {
//   const layout = useSelector((state) => {
//     console.log("From Reducer ", state);
//     return state.LayoutReducer.Layout;
//   });
//   const modal = useSelector((state) => {
//     return state.LayoutReducer.Modal;
//   });

//   const dispatch = useDispatch();
//   console.log("Modal is ", modal);
//   //   if(JSON.stringify(modal)!=='{}'){
//   //        <LayoutRenderer Data={modal} />
//   //   }
//   console.log("Profile: ", profile);
//   return (
//     <>
//       <Button_1
//         Title={"Layout 1"}
//         Handler={() => {
//           dispatch({
//             type: "SET",
//             payload: profile,
//           });
//         }}
//       />
//       <Button_1
//         Title={"Layout 2"}
//         Handler={() => {
//           Gateway({}, {}, "/layout2", "GET").then((res) => {
//             dispatch({
//               type: "SET",
//               payload: res,
//             });
//           });
//         }}
//       />
//       <Button_1
//         Title={"Workflow"}
//         Handler={() => {
//           // dispatch({
//           //     type:'clear'
//           // })
//           dispatch({
//             type: "SET",
//             payload: Workflow,
//           });
//         }}
//       />
//       <Button_1
//         Title={"Layout 4"}
//         Handler={() => {
//           dispatch({
//             type: "SET",
//             payload: Education,
//           });
//         }}
//       />
//       <Button_1
//         Title={"Layout 5"}
//         Handler={() => {
//           dispatch({
//             type: "SET",
//             payload: Data4,
//           });
//         }}
//       />
//       <Button_1
//         Title={"Layout 6"}
//         Handler={() => {
//           dispatch({
//             type: "SET",
//             payload: Data5,
//           });
//         }}
//       />
//       <Button_1
//         Title={"Layout 7"}
//         Handler={() => {
//           dispatch({
//             type: "clear",
//           });
//           dispatch({
//             type: "SET",
//             payload: Data6,
//           });
//         }}
//       />
//       <Button_1
//         Title={"Homepage"}
//         Handler={() => {
//           Gateway({}, {}, "/homepage", "GET").then((res) => {
//             dispatch({
//               type: "SET",
//               payload: res,
//             });
//           });
//         }}
//       />
//       {/* <LayoutRenderer Data={Data} /><br />
//     <LayoutRenderer Data={Data1} /> */}
//       <LayoutRenderer Data={modal} />
//       <LayoutRenderer Data={layout} />
//       <FLoatAction />
//     </>
//   );
// }
function Layout() {
  return <>Layout</>;
}

export default Layout;
