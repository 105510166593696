import { Actions } from '../Actions/Actiontypes'

const initialState = {
  EnabledGroups:[]
}

function WizardReducer(state = initialState, action) {
  switch (action.type) {
  
    default:
      return state
  }
}

export default WizardReducer
