import React from "react";


function EWHead({size,Value}){

    return (
        <>
    <h4 style={{fontSize:size}}>{Value}</h4>
        </>
    )
}

export default EWHead;