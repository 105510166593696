import { Actions } from '../Actions/Actiontypes'

const initialState = {
  id: null,
  message: '',
}

function MessageReducer(state = initialState, action) {
 
  switch (action.type) {
    case Actions.SET_MESSAGE: {
        const {id,message}=action.payload
      return {
       id,
       message
      }
    }
    case Actions.UNSET_MESSAGE: {
      return {
        id: null,
        message: '',
      }
    }
    default:
      return state
  }
}

export default MessageReducer
