import React from "react";
const initialProps = {
  textStyle: {},
  Value: null,
  heading: null,
  textContainerStyle: {},
};

function Text_1(props = initialProps) {
  var { textStyle, Value, heading, textContainerStyle, textArray } = props;
  //   if (heading) {
  //     return  <div style={{width:"100%",...textContainerStyle}}><h2 style={textStyle}>{heading}</h2></div>
  //   }

  return (
    <>
      {textArray?.map((ele) => {
        //   console.log("Text Array: ",React.createElement(ele.type,ele.props?ele.props:{},value))
        if (ele.type === "icon") {
          if (!ele.icon) return <></>;
          let type = "p";
          if (ele.textType) type = ele.textType;

          // let Icon = require('@mui/icons-material/' + ele.icon).default
          let Icon =()=> <></>;
          let Comp = React.createElement(
            type,
            ele.props
              ? ele.props
              : {
                  style: {
                    fontSize: "30px",
                  },
                },
            ele.value
          );
          console.log("Icon; ", Comp);
          return (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  margin: 10,
                }}
              >
                <Icon />
                <span style={{ marginLeft: 10, fontSize: 20 }}>
                  {ele.value}
                </span>
              </div>
            </div>
          );
        }
        let Comp = React.createElement(
          ele.type,
          ele.props ? ele.props : {},
          ele.value
        );
        return <div className="test123">{Comp}</div>;
      })}
      <div style={{ ...textContainerStyle }}>
        {heading && <h2 style={textStyle}>{heading}</h2>}
        <span style={textStyle}>{Value}</span>
      </div>
    </>
  );
}

export default Text_1;
