import * as React from "react";
import Button from "@mui/material/Button";
import useProps from "../../Utils/CustomHooks/GetProps";
import useRender from "../../Utils/CustomHooks/RenderComponent";
import LoadingButton from "@mui/lab/LoadingButton";

const initProps = {
  title: null, //required
  onClick: null, //required
  variant: null,
  disable: null,
  otherProps: null,
  style: null,
  color: null,
  label: null,
  startIcon: () => {},
  isLoading:false,
};

export default function EwButton(props = initProps) {
  var {
    title,
    onClick,
    otherProps,
    variant,
    disable,
    style,
    color,
    label,
    id,
    group,
    isLoading,
    startIcon,
  } = useProps(props);
  if (!style) style = {};
  if (label) title = label;
  if (useRender({ id, group })) return <></>;
  if (!title || !onClick)
    return (
      <>
        <span style={{ color: "red" }}>Button Data Missing!</span>
      </>
    );
  if (!variant) variant = "contained";
  if (isLoading) return <LoadingButton loading  variant="outlined" >Loading</LoadingButton>;
  return (
    <>
      <Button
        style={style}
        color={color}
        onClick={onClick}
        disabled={disable || isLoading}
        variant={variant}
        startIcon={startIcon}
        {...otherProps}
      >
        {isLoading ? "Loading.." : title}
      </Button>
    </>
  );
}
