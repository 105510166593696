import * as React from "react";

import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import useProps from "../../Utils/CustomHooks/GetProps";
import useRender from "../../Utils/CustomHooks/RenderComponent";
/* 
type:
0- Date
1-Time
2-Date and Time

*/
const initialProps = {
  label: null,
  value: null,
  onChange: null,
  type: null,
  views: undefined,
  muiProps: null,
};
export default function EwDate(props1 = initialProps) {
  let props = useProps(props1);
  var { label, value, onChange, type, muiProps, id, group } = props;
  if (!type) type = 0;
  //if (!value) props.value = new Date().getTime();
  if (!muiProps) muiProps = {};
  console.log("Date: ", props);
  const handleChange = (newValue) => {
    newValue = new Date(newValue).getTime();
    console.log("new value date: ", newValue);
    onChange(newValue);
  };
  if (useRender({ id, group })) return <></>;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {type === 0 && (
        <>
          <DesktopDatePicker
            label={label}
            inputFormat="MM/dd/yyy"
            value={(value && new Date(value)) || new Date()}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
            {...muiProps}
          />
        </>
      )}

      {type === 1 && (
        <>
          <TimePicker
            label={label}
            value={value}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
            {...muiProps}
          />
        </>
      )}
      {type === 2 && (
        <>
          <DateTimePicker
            label={label}
            value={value}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
            {...muiProps}
          />
        </>
      )}
    </LocalizationProvider>
  );
}
