import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Gateway from "../Utils/Api/Gateway";
import { useDispatch } from "react-redux";
import menuData from "../Testjson/Header/Menu1.json";
import { useHistory } from "react-router-dom";
const menu = menuData.menu;
const payload = {
  TenantID: "00",
  FeatureVariantID: "000",
  UserId: "",
  UserRole: "",
  TransactionId: "",
  StepId: "",
  ModuleId: "0",
  Status: "1",
};
// const menu = [
//   {
//     Header: {
//       Title: 'Menu 1',
//       Icon: 'AccessAlarm',
//     },
//     Options: [
//       { Value: 'Homepage', params: { endPt: '/homepage' } },
//       { Value: 'Option 2', params: {} },
//       { Value: 'Option 3', params: {} },
//       { Value: 'Option 4', params: {} },
//     ],
//   },
//   {
//     Header: {
//       Title: 'Menu 2',
//       Icon: 'AssignmentOutlined',
//     },
//     Options: [
//       { Value: 'Option 1', params: {} },
//       { Value: 'Option 2', params: {} },
//       { Value: 'Option 3', params: {} },
//     ],
//   },
//   {
//     Header: {
//       Title: 'Menu 3',
//       Icon: 'BusinessOutlined',
//     },
//     Options: [
//       { Value: 'Option 1', params: {} },
//       { Value: 'Option 2', params: {} },
//       { Value: 'Option 3', params: {} },
//     ],
//   },
//   {
//     Header: {
//       Title: 'Menu 3',
//       Icon: 'BusinessOutlined',
//     },
//     Options: [
//       { Value: 'Option 1', params: {} },
//       { Value: 'Option 2', params: {} },
//       { Value: 'Option 3', params: {} },
//     ],
//   },
//   {
//     Header: {
//       Title: 'Menu 3',
//       Icon: 'BusinessOutlined',
//     },
//     Options: [
//       { Value: 'Option 1', params: {} },
//       { Value: 'Option 2', params: {} },
//       { Value: 'Option 3', params: {} },
//     ],
//   },
//   {
//     Header: {
//       Title: 'Menu 3',
//       Icon: 'BusinessOutlined',
//     },
//     Options: [
//       { Value: 'Option 1', params: {} },
//       { Value: 'Option 2', params: {} },
//       { Value: 'Option 3', params: {} },
//     ],
//   },

// ]

function MenuList(props) {
  const { data } = props;
  const dispatch = useDispatch();
  //const history = useHistory();
  while (data.Options.length !== 4) {
    data.Options.push({ Value: "" });
  }
  function getIcon(name) {
    // const Icon = require("@mui/icons-material/" + name).default;
    const Icon = ()=><></>
    return <Icon style={{ fontStyle: "small" }} />;
  }
  function handleClick(params) {
    if (params.endPt) {
      window.open("http://localhost:3000/#" + params.endPt,"_self");
      return;
    }
    if (params.FeatureID) {
      let payloadSchema = payload;
      payloadSchema.FeatureID = params.FeatureID;
      console.log("From Menu: ", payloadSchema);
      Gateway(payloadSchema)
        .then((res) => {
          dispatch({
            type: "SET",
            payload: res.Data,
          });
          dispatch({
            type: "SET_MESSAGE",
            payload: {
              id: 0,
              message: "Layout Called!",
            },
          });
        })
        .catch((e) => {
          console.log("Error ", payloadSchema, " ", e);
          dispatch({
            type: "SET_MESSAGE",
            payload: {
              id: 1,
              message: "Oops Something went wrong!",
            },
          });
        });
      return;
    }

    Gateway({}, {}, params.endPt, "GET").then((res) => {
      dispatch({
        type: "SET",
        payload: res,
      });
    });
  }
  return (
    <>
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span>{getIcon(data.Header.Icon)}</span>
          <h5>{data.Header.Title} </h5>
        </div>
        <div style={{ padding: 10 }}>
          {data.Options.map((ele, index) => {
            return (
              <>
                <span
                  key={index}
                  onClick={() => handleClick(ele.params)}
                  style={{ cursor: "pointer" }}
                >
                  {ele.Value}
                </span>
                <br />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

function Menu(props) {
  //const {menu}=props;
  const [elevation, setElevation] = useState(1);

  return (
    <>
      <Paper
        elevation={elevation}
        onMouseEnter={() => {
          setElevation(3);
        }}
        onMouseLeave={() => {
          setElevation(1);
        }}
        style={{ padding: 15 }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          {menu.map((ele, index) => {
            if (index % 2 !== 0) return <> </>;
            return (
              <>
                <div style={{ marginRight: 20 }}>
                  <MenuList data={ele} key={index} />
                  {menu[index + 1] && (
                    <MenuList data={menu[index + 1]} key={index} />
                  )}
                </div>
              </>
            );
          })}
        </div>
      </Paper>
    </>
  );
}

export default Menu;
