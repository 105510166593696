import React from 'react';
import { makeStyles } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useDispatch,useSelector} from "react-redux";
import HandleCondition from '../../Utils/Condition';


/*
This Material UI button has 2 properties

label:      ||String|| Required; label for Dropdown
menuItem:   ||Array || Required; Array of MenuObject


MenuObject: ||Object|| {value,label}; label for dropdown options
*/



const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));


function Dropdown_1(props){
  const {label,menuItem,dataReduxId,parentField,fieldDependency,dataReduxerState}=props;
    const classes = useStyles();
    const [value, setValue] = React.useState('');
    const dispatch=useDispatch();
  
    const handleChange = (event) => {
      setValue(event.target.value);
      let val=event.target.value;
     dispatch({
       type:'SET_FIELD_DATA',
       payload:{
         id:dataReduxId,
         prop:'value',
         field:parentField,
         data:val
       }
     });
     dispatch(HandleCondition(fieldDependency,dataReduxerState,dataReduxId))
    };
    const valueFromRedux=useSelector((state)=>{
      if(state.DataReducer[dataReduxId] &&  state.DataReducer[dataReduxId][parentField])
        return state.DataReducer[dataReduxId][parentField].value
    });
   
    console.log("From Dropdown: ",valueFromRedux)
    return(
        <>
        
<FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={valueFromRedux}
          onChange={handleChange}
          
        >
          {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
          {menuItem.map((ele,index)=>{
              return <MenuItem  style={{width:100}} value={ele.value} key={index}>{ele.label}</MenuItem>
          })}
        </Select>
      </FormControl>
        </>
    )
}



export default Dropdown_1;