import React, { useEffect } from 'react'
import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import useRender from '../utils/useRender'
import { useSelector, useDispatch } from 'react-redux'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'


export default function BasicDatePicker({ id, compNumber }) {
  // const [value, setValue] = React.useState(null);
  const { value, label, defaultValue, dateAndTime } = useSelector(
    (state) => state.DataReducer[id][compNumber],
  )
  console.log('DateInput2: ', value, ' ', new Date(value))
  const dispatch = useDispatch()
  useEffect(() => {
    if (!value || value.length == 0) {
      console.log('Inside date2: sfdv', new Date().getTime())
      dispatch({
        type: 'UPDATE_PROPS',
        payload: {
          id,
          componentNumber: compNumber,
          data: new Date().getTime(),
          props: 'value',
        },
      })
    }
  }, [])
  function valueChnage(newValue) {
    console.log('DateInput2: new: ', new Date(newValue).getTime())
    dispatch({
      type: 'UPDATE_PROPS',
      payload: {
        id,
        componentNumber: compNumber,
        data: new Date(newValue).getTime(),
        props: 'value',
      },
    })
  }
  if (!useRender({ id, compNumber })) return <></>
  if (dateAndTime) {
    return (
      <>
        <div className="" style={{ margin: 10 }}>
       <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          label={label ? label : 'Date'}
          value={
            value ? new Date(Number(value)).getTime() : new Date().getTime()
          }
          onChange={valueChnage}
          renderInput={(params) => <TextField {...params} />}
        />
        </LocalizationProvider>
        </div>
      </>
    )
  }
  return (
    <div className="" style={{ margin: 10 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label ? label : 'Date'}
          value={
            value ? new Date(Number(value)).getTime() : new Date().getTime()
          }
          onChange={valueChnage}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <br />
    </div>
  )
}
