import React, { useState, useEffect } from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import LayoutRenderer from "../Interpreter/LayoutRenderer";
import { useSelector, useDispatch } from "react-redux";
import Data from "../Testjson/Shell/TenantForm.json";
import Grid from "@mui/material/Grid";
import setLayout from "../Utils/setLayout";
import { v4 as uuid } from "uuid";

/* Layout JSONS  */
//import Legal from "../Testjson/Organization/legalEntity.json";
import Legal from "../Testjson/Organization/LegalEntity2.json"
import BusinessUnit from "../Testjson/Organization/BusinessUnit.json";
import BusinessUnitType from "../Testjson/Organization/BusinessUnitType.json";
import Project from "../Testjson/Organization/Project.json";
import functionType from "../Testjson/Organization/FunctionType.json";
import functionjson from "../Testjson/Organization/function.json";
import locationType from "../Testjson/Organization/LocationType.json";
import location from "../Testjson/Organization/Location.json";
import workLocation from "../Testjson/Organization/workLocation.json";
import Grade from "../Testjson/Organization/Grade.json";
import Role from "../Testjson/Organization/Role.json";
import EmployeeType from "../Testjson/Organization/Employee_type.json";
import GradeLevel from "../Testjson/Organization/Grade_level.json";
import CostCenter from "../Testjson/Organization/Cost_center.json";
import LeagHead from "../Testjson/Organization/LegalEntityHead.json";
import BusinessHead from "../Testjson/Organization/BusinessUnitHead.json";
import locationHead from "../Testjson/Organization/LocationHead.json";
import RoleTag from "../Testjson/Organization/Role_tag.json";
import Mpp from "../NComponent/pms/MPP";
import MasterPlans from "../NComponent/pms/MPP/MasterPlans";
import Applicability from "../MComponent/Applicability/Applicability3";
import { Button } from "@mui/material";
import Tree from "../MComponent/Tree/Tree";
import DPG from "../NComponent/pms/Settings/DPG";
import PDG from "../NComponent/pms/Settings/PDG";
import DMP from "../NComponent/pms/Settings/DMP";
import DPP from "../NComponent/pms/Settings/DPP";
import Rating from "../NComponent/pms/Settings/Rating";
import ParamterMap from "../NComponent/pms/Settings/ParamterMap";
import RatingScales from "../NComponent/pms/Settings/Rating/RatingScales";
// import Rating from "../NComponent/pms/Settings/Rating";

const data = [
  {
    label: "Organisation Dimensions",
    children: [
      {
        label: "Legal Entity",
      },
    ],
  },
];

const menuList = {
  "Organisation Dimensions": [
    { label: "Legal Entity", layout: Legal },
    { label: "Business Unit Type", layout: BusinessUnitType },
    { label: "Business Unit", layout: BusinessUnit },
    { label: "Function Type", layout: functionType },
    { label: "Function", layout: functionjson },
    { label: "Location Type", layout: locationType },
    { label: "Location", layout: location },
    { label: "Work Location", layout: workLocation },
    { label: "Employee Type", layout: EmployeeType },
    { label: "Grade", layout: Grade },
    { label: "Grade Level", layout: GradeLevel },
    { label: "Role Group Tag", layout: RoleTag },
    { label: "Role", layout: Role },
    // { label: 'Project', layout: Project },
    { label: "Cost Center", layout: CostCenter },
    { label: "Legal Entity Head", layout: LeagHead },
    { label: "Business Unit Head", layout: BusinessHead },
    { label: "Location Head", layout: locationHead },
  ],
  "Identiy And Security": [{ label: "Login", layout: Legal }],
  Organization: [],
  "Asset Management": [],
  "Employee Profile": [],
  "Asset Management": [],
  Documents: [],
  Hiring: [],
  "Employee Onboarding": [],
  Leave: [],
  Attendance: [],
  "Time Tracking": [],
  Compensation: [],
  "Payroll & Taxes": [],
  "Travel & Expense": [],
  Performance: [],
  Learning: [],
  "Employee Separation": [],
};
var temp = Object.keys(menuList).map((ele) => {
  let arr = menuList[ele].map((item) => {
    if (item.layout) {
      let onClick = {
        type: 0,
        args: {
          layout: item.layout,
        },
      };
      item.onClick = onClick;
    }
    delete item.layout;

    return item;
  });

  return {
    label: ele,
    children: arr,
  };
});

function Settings(props) {
  const { settingsMenu } = props;
  const [Component, setComponent] = useState(null);
  const layout = useSelector((state) => {
    console.log("From Reducer ", state);
    return state.LayoutReducer.Layout;
  });
  const modal = useSelector((state) => {
    return state.LayoutReducer.Modal;
  });
  const dispatch = useDispatch();

  function changeLayout(layout) {
    setLayout(layout);
  }
  function renderInnerMenu(heading, menu, i) {
    i++;
    return (
      <>
        <TreeItem nodeId={getRandom()} label={heading}>
          {menu?.map((ele, index) => (
            <TreeItem
              onClick={() => changeLayout(ele.layout ? ele.layout : Data)}
              nodeId={getRandom()}
              label={ele.label}
            />
          ))}
        </TreeItem>
      </>
    );
  }
  function getRandom() {
    return uuid();
    //return '' + Math.floor(Math.random() * 1000)
  }

  function renderOuterMenu(listObj, parent, i) {
    console.log(listObj);
    return (
      <>
        {parent ? (
          <TreeItem nodeId={getRandom()} label={parent}>
            {Object.keys(listObj).map((ele, index) => {
              if (Array.isArray(listObj[ele])) {
                return renderInnerMenu(ele, listObj[ele], index);
              } else {
                return renderOuterMenu(listObj[ele], ele, index);
              }
            })}
          </TreeItem>
        ) : (
          <>
            {Object.keys(listObj).map((ele, index) => {
              if (Array.isArray(listObj[ele])) {
                return renderInnerMenu(ele, listObj[ele], index);
              } else {
                return renderOuterMenu(listObj[ele], ele, index);
              }
            })}
          </>
        )}
      </>
    );
  }
  function BusinessType() {
    const list = [
      {
        label: "BU1",
        children: [
          {
            label: "BU2",
            children: [
              {
                label: "BU3",
                children: [
                  {
                    label: "BU4",
                  },
                ],
              },
            ],
          },
        ],
      },
    ];
    return <Tree data={list} iconType={0} />;
  }
  function callDimenstion() {
    setComponent(<MasterPlans />);
  }
  function callDPG() {
    setComponent(<DPG />);
  }
  function callPDG() {
    setComponent(<PDG />);
  }
  function callDMP() {
    setComponent(<ParamterMap />);
  }
  function callDPP() {
    setComponent(<DPP />);
  }
  function callRating() {
    setComponent(<RatingScales />);
  }
  if (temp[temp.length - 1].label !== "performance Management")
    temp.push({
      label: "performance Management",

      children: [
        {
          label: "Performance Dimension",
          onClick: {
            type: 1,
            args: {
              callParent: callPDG,
            },
          },
        },
        {
          label: "Dimenstion Paramter Group",
          onClick: {
            type: 1,
            args: {
              callParent: callDPG,
            },
          },
        },
        {
          label: "Dimension Performance Parameter",
          onClick: {
            type: 1,
            args: {
              callParent: callDPP,
            },
          },
        },
        {
          label: "Dimension Paramter Map",
          onClick: {
            type: 1,
            args: {
              callParent: callDMP,
            },
          },
        },
        {
          label: "Master Plans",
          onClick: {
            type: 1,
            args: {
              callParent: callDimenstion,
            },
          },
        },
        {
          label: "Rating",
          onClick: {
            type: 1,
            args: {
              callParent: callRating,
            },
          },
        },
     
      ],
    });
  return (
    <>
      <Grid style={{ padding: 30 }} container spacing={1}>
        <Grid className="Flex" style={{ alignItems: "flex-start" }} item xs={5}>
          {/* <TreeView
            className="scroll"
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{
              height: 600,
              flexGrow: 1,
              maxWidth: 400,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {renderOuterMenu(menuList)}
          </TreeView> */}

          <Tree data={temp} />
        </Grid>
        <Grid item xs={7}>
          {console.log("Component is: ", Component)}

          {!Component ? (
            <>
              <LayoutRenderer Data={modal} />
              <LayoutRenderer Data={layout} />
            </>
          ) : (
            <>{Component}</>
          )}
          {/* <BusinessType /> */}
        </Grid>
      </Grid>
      {/* <Applicability />
      <Button color='error' >cancel</Button> */}
    </>
  );
}

export default Settings;
