const FeatureIDs = {
  Tenant: "10101",
  Workflow: "00102",
  Applicability: "00103",
  Email: "10104",
  Phone: "10105",
  LegalEntity: "12106",
  UploadDocument: "10107",
  "2FA": "11108",
  User: "12109",
  UserTeam: "12110",
  BusinessUnitType: "12111",
  BusinessUnit: "12112",
  FunctionType: "12113",
  Function: "12114",
  LocationType: "12115",
  Location: "12116",
  Grade: "12117",
  GradeLevel: "12118",
  Authentication: "10119",
  Worklocation: "12120",
  RoleTag: "12121",
  Role: "12122",
  EmployeeType: "12123",
  Project: "22124",
  Team: "12125",
  EmployeeOrganisation: "12126",
  Address: "10127",
  Workex: "10128",
  Person: "12129",
  Education: "10130",
  FamilyRelationship: "10131",
  UserProject: "22132",
  MedicalInformation: "10133",
  LegalEntityHead: "12134",
  BusinessUnitHead: "12135",
  LocationHead: "12136",
  CostCenter: "12137",
  RoleTagging: "12138",
  DesignationOld: "12139",
  JobDescription: "12140",
  ModuleSubscription: "00141",
  DesignationNew: "12142",
  Notification: "00143",
  PerformanceDimensionGrouping: "19144",
  DimensionParameterGroup: "19145",
  DimensionPerformanceParameter: "19146",
  DimensionParameterMap: "19147",
  RatingScale: "19148",
  MasterPerformancePlan: "19149",
  DataAnalyticsQueries: "00150",
  EmployeePerformancePlan: "19151",
  ParameterAsseessment: "19152",
  ParameterAndTargets: "19153",
  PerformanceCycleInitiation: "19154",
  DesignationLayout: "00155",
  Title: "10156",
  MaritialStatus: "10157",
  Gender: "10158",
  NINConfiguration: "10159",
  SelfDevelopmentPlan: "19160",
  CoachingPlan: "19161",
  TrainingNeeds: "19162",
  PerformanceAssessmentInitiation: "19163",
  InterimPerformanceAssessmentInitiation: "19164",
  ConfidentialAssessment: "19165",
};
export default FeatureIDs;
