import React, { useEffect, useState } from "react";
//import Button from '@mui/material/Button'
import Button from "@mui/material/Button";

import { useDispatch, useSelector } from "react-redux";
import methodHandler from "../utils/EventHandler";
import useRender from "../utils/useRender";

/*
This Material UI button has 4 properties

Type:   ||String||   contained/outlined/Default(An Empty String/null passed to prop Type);
Color:  ||String||   primary/secondary/Default (An Empty String/null passed to prop Color, Grey color);
Title:  ||String||   Required;
Handler:||Function|| Required;

*/

const FILE_NAME = "Button_2.js";

function Button_2({ id, compNumber }) {
  var {
    Type,
    Color,
    Title,
    Handler,
    renderNot,
    styles,
    disable,
    appendStyles,
    onClick,
    variant,
  } = useSelector((state) => state.DataReducer[id][compNumber]);
  console.log(FILE_NAME, " ", Type);
  const [loading, setLoading] = useState(false);
  var btnHandle = Handler;

  if (!Type) Type = "";

  function onClickHandler() {
    // if(onClick){
    //   Methods[onClick.createPaylaod](...onClick)
    // }
    function handleMethod(methodObj) {
      console.log("Method Obj: ", methodObj);
      return new Promise((resolve, reject) => {
        methodHandler(methodObj, id)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    }

    if (onClick) {
      if (Array.isArray(onClick)) {
        onClick.map(async (ele, index) => {
          await handleMethod(ele);
          if (index === onClick.length) {
            setLoading(false);
          }
        });
      } else {
        handleMethod(onClick)
          .then((res) => {
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    }
  }

  if (!useRender({ id, compNumber })) return <></>;
  if (!Title) return <span style={{ color: "red" }}>Data Missing</span>;
  return (
    <>
      <Button
        onClick={onClickHandler}
        color={Color ? Color : "primary"}
        style={{ fontSize: 10, ...styles, ...appendStyles }}
        variant={Type}
        disabled={disable || loading}
      >
        {Title}
      </Button>
      <br />
    </>
  );
}

export default Button_2;
