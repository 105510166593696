import React, { useState } from "react";
import Atomic from "../../../AComponent/Atomic";
import EwTable from "../../../MComponent/Table/Table";
import EditIcon from "@mui/icons-material/Edit";
import EwModal from "../../../MComponent/Modal/Modal";
import EwAccordion from "../../../MComponent/Accordion/Accordion";
import useWindowDimensions from "../../../Utils/CustomHooks/WindowRezise";
import SectionEnable from "./SectionEnable";

const { Button, Input, Checkboxes, EwSwitch } = Atomic;

function Summary() {
  const [summaryState, setSummaryState] = useState({});
  const { width } = useWindowDimensions();
  const [modalOpen, setModalOpne] = useState(null);
  const {
    sectionEnabled,
    sectionLabel,
    sectionDescription,
    dimensionSelected,
    enablePlanWidget,
    enableAggregatedScore,
    enableModrateScore,
    multiRaterWidget,
    overAllCommentWidget,
    appraiseSnapshotWidget,
  } = summaryState;

  function updateState(val, type) {
    setSummaryState((prev) => ({ ...prev, [type]: val }));
  }
  function DimensionSelector({ id }) {
    function chnageHandler(val) {
      let obj = dimensionSelected;
      if (!obj) obj = {};
      obj[id] = val;
      setSummaryState((prev) => ({ ...prev, dimensionSelected: { ...obj } }));
    }
    let value = dimensionSelected ? dimensionSelected[id] : false;
    return (
      <div style={{ padding: 20 }}>
        <Checkboxes value={value} onChange={chnageHandler} />
      </div>
    );
  }
  function AppraiseeSnapShot() {
    const [enable, setEnable] = useState(false);
    const [localState, setLocalState] = useState({});
    function onChange(val, type) {
      let obj = localState;
      if (!obj) obj = {};
      obj[type] = val;
      setLocalState({ ...obj });
      //   setSummaryState((prev) => ({
      //     ...prev,
      //     appraiseSnapshotWidget: { ...obj },
      //   }));
    }
    const checkList = [
      {
        label: "Employee Code",
        type: "empCode",
      },
      {
        label: "Role",
        type: "role",
      },
      {
        label: "Designation",
        type: "designation",
      },
      {
        label: "Function",
        type: "function",
      },
      {
        label: "Location",
        type: "location",
      },
      {
        label: "Grade and Grade Level",
        type: "grade",
      },
      {
        label: "Employee Type",
        type: "empType",
      },
    ];
    return (
      <div>
        {!enable ? (
          <div>
            <Button title={"Enable Section"} onClick={() => setEnable(true)} />
          </div>
        ) : (
          <div className="Flex">
            <Button
              title={"Disable Section"}
              onClick={() => setEnable(false)}
              color="error"
            />
            <br />
            <br />
            <h5>Name Enabled</h5>
            <div className="Flex">
              <div>
                {checkList.map((ele) => (
                  <div>
                    <EwSwitch
                      value={localState[ele.type]}
                      onChange={(val) => onChange(val, ele.type)}
                      label={ele.label}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="Flex">
              <Button title={"Save"} onClick={() => {}} />
            </div>
          </div>
        )}
      </div>
    );
  }
  function LeaveWidget() {
    const [enable, setEnable] = useState(false);
    const [localState, setLocalState] = useState({});
    function onChange(val, type) {
      setLocalState((prev) => ({ ...prev, [type]: val }));
    }
    const checkList = [
      {
        label: "",
        type: "",
      },
    ];
    return (
      <>
        {!enable ? (
          <div className="Flex">
            <Button title={"Enable Section"} onClick={() => setEnable(true)} />
          </div>
        ) : (
          <div>
            <div className="Flex">
              <Button
                color="error"
                title={"Disable Section"}
                onClick={() => setEnable(false)}
              />
            </div>
            <div></div>
          </div>
        )}
      </>
    );
  }
  function EditDimSettings({ id }) {
    return (
      <>
        <div onClick={() => setModalOpne(id)}>
          <EditIcon className="pointer" color="info" />
        </div>
      </>
    );
  }
  function ModalContent() {
    const [localState, setlocalState] = useState({});
    function onChange(val, type) {
      setlocalState((prev) => ({ ...prev, [type]: val }));
    }
    const checkList = [
      {
        label: "Past Two Cycle Aggregate Score",
        type: "past2AS",
      },
      {
        label: "Current Cycle Aggregate Score",
        type: "currentAS",
      },
      {
        label: "Past Two Cycle Moderated Score",
        type: "past2MS",
      },
      {
        label: "Current Cycle Moderated Score",
        type: "currentMS",
      },
    ];
    return (
      <>
        <div style={{ width: 0.7 * width, height: 300 }}>
          <div className="Flex">
            {" "}
            <h3>{modalOpen}</h3>
          </div>

          <div className="Flex">
            <div>
              {checkList.map(({ label, type }) => (
                <div>
                  <EwSwitch
                    label={label}
                    value={localState[type]}
                    onChange={(val) => onChange(val, type)}
                  />
                </div>
              ))}
            </div>
          </div>
          <br />
          <div className="Flex">
            <Button title={"Save"} onClick={() => {}} />
          </div>
        </div>
      </>
    );
  }
  const heading = ["S.No", "Dimensions"];
  const rows = [
    [
      {
        Component: DimensionSelector,
        props: {
          id: "dim1",
        },
      },
      "Dim1",
      {
        Component: EditDimSettings,
        props: {
          id: "dim1",
        },
      },
    ],
    [
      {
        Component: DimensionSelector,
        props: {
          id: "dim2",
        },
      },
      "Dim2",
      {
        Component: EditDimSettings,
        props: {
          id: "dim2",
        },
      },
    ],
  ];
  const accordian = [
    {
      label: "Appraisee Snapshot Widget",
      Component: AppraiseeSnapShot,
    },
  ];
  return (
    <div
      className="scroll"
      style={{
        height: 500,
        overflowY: "auto",
        overflowX: "hidden",
        padding: 20,
      }}
    >
      <EwModal open={modalOpen} onClose={() => setModalOpne(null)}>
        <ModalContent />
      </EwModal>
      <SectionEnable label={"Summary"}>
        <div>
          <div className="Flex-row">
            <div>
              <Input
                label={"Section Label"}
                value={sectionLabel}
                onChange={(val) => updateState(val, "sectionLabel")}
              />
            </div>
            <div>
              <Input
                label={"Section Description"}
                value={sectionDescription}
                onChange={(val) => updateState(val, "sectionDescription")}
              />
            </div>
          </div>
          <br />
          <div className="Flex">
            <h3>Dimension Score Widgets</h3>
            <div
              className="scroll"
              style={{ width: "80%", maxHeight: 300, overflowY: "auto" }}
            >
              <EwTable heading={heading} rows={rows} />
            </div>
          </div>
          <hr style={{ width: "100%" }} />
          <br />
          <div>
            <EwSwitch
              label="Dimension Score Widgets"
              value={enablePlanWidget}
              onChange={(val) => updateState(val, "enablePlanWidget")}
            />
          </div>
          {enablePlanWidget && (
            <div className="Flex-row">
              <div>
                <EwSwitch
                  label="Plan Aggregated Score"
                  value={enableAggregatedScore}
                  onChange={(val) => updateState(val, "enableAggregatedScore")}
                />
              </div>
              <div>
                <EwSwitch
                  label="Plan Moderated Score"
                  value={enableModrateScore}
                  onChange={(val) => updateState(val, "enableModrateScore")}
                />
              </div>
            </div>
          )}
          <hr style={{ width: "100%" }} />
          <div className="Flex">
            <div>
              <EwSwitch
                label="Multi-rater scores widget"
                value={multiRaterWidget}
                onChange={(val) => updateState(val, "multiRaterWidget")}
              />
            </div>
            <div>
              <EwSwitch
                label="Overall Comments Widget"
                value={overAllCommentWidget}
                onChange={(val) => updateState(val, "overAllCommentWidget")}
              />
            </div>
          </div>
          <div>
            <EwAccordion accordians={accordian} />
          </div>
        </div>
      </SectionEnable>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Summary;
