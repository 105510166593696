import React, { useState } from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import setLayout from "../../Utils/setLayout";
import EWMenu from "../Menu/Menu";
import {
  ArrowDropDown,
  ArrowRight,
  Edit,
  EditOutlined,
} from "@mui/icons-material";
import { Typography, useTheme } from "@mui/material";

function Tree({ data, onItemClick }) {
  const [expanded, setExpanded] = useState(new Set());
  const [anchor, setAnchor] = useState(null);
  const palette = useTheme().palette;

  function treeClicked(clickObj, id) {
    setExpanded((prev) => {
      let tempSet = new Set(prev);
      if (tempSet.has(id)) {
        tempSet.delete(id);
      } else {
        tempSet.add(id);
      }
      return tempSet;
    });

    if (!clickObj) {
      return;
    }

    let { type, args } = clickObj;

    if (type === 0) {
      setLayout(args.layout);
    }

    if (type === 1) {
      console.log("Function : ", args.callParent);
      args.callParent();
    }
  }

  function rightClick(e) {
    e.preventDefault();
    setAnchor(e.target);
  }

  function renderItemList(list, parentIndex) {
    return (
      <>
        {list?.map((ele, index) => {
          let id = ele.label;
          console.log("ids: ", id, " ", ele.label);
          if (ele?.children)
            return (
              <TreeItem
                key={parentIndex + index}
                onContextMenu={rightClick}
                onClick={() => {
                  onItemClick(ele, index);
                  treeClicked(ele?.onClick, id);
                }}
                nodeId={id}
                label={<Typography variant="body1">{ele.label}</Typography>}
                ContentProps={{
                  style: {
                    padding: "1em",
                  },
                }}
                sx={{
                  "& .MuiTreeItem-content.Mui-selected": {
                    backgroundColor: "white",
                    color: `${palette.primary.main}`,
                    fontWeight: "600",
                  },
                  "& .MuiTreeItem-content.Mui-selected:hover": {
                    backgroundColor: "white",
                    color: `${palette.primary.main}`,
                    fontWeight: "600",
                  },
                  "& .MuiTreeItem-content.Mui-focused": {
                    backgroundColor: "white",
                  },
                }}
              >
                {renderItemList(ele.children, index)}
              </TreeItem>
            );

          return (
            <div>
              <TreeItem
                key={parentIndex + index}
                onContextMenu={rightClick}
                onClick={() => {
                  onItemClick(ele, index);
                  treeClicked(ele?.onClick, id);
                }}
                ContentProps={{
                  style: {
                    padding: "0.5em",
                  },
                }}
                sx={{
                  "& .MuiTreeItem-content.Mui-selected": {
                    backgroundColor: "white",
                    color: `${palette.primary.main}`,
                    fontWeight: "600",
                  },
                  "& .MuiTreeItem-content.Mui-selected:hover": {
                    backgroundColor: "white",
                    color: `${palette.primary.main}`,
                    fontWeight: "600",
                  },
                  "& .MuiTreeItem-content.Mui-focused": {
                    backgroundColor: "white",
                  },
                }}
                nodeId={id}
                label={<Typography variant="subtitle2">{ele.label}</Typography>}
              />
            </div>
          );
        })}
      </>
    );
  }

  return (
    <div>
      <EWMenu
        anchor={anchor}
        menuList={[
          {
            label: "Edit",
          },
        ]}
        onMenuSelect={(value) => {
          setAnchor(null);
        }}
      />
      {console.log("Expanded: ", [...expanded])}
      <TreeView
        aria-label="Tree View"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={[...expanded]}
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {renderItemList(data, 0)}
      </TreeView>
    </div>
  );
}

export default Tree;
