import React, { useState, useEffect } from "react";
import Atomic from "../../../AComponent/Atomic";
import Download from "@mui/icons-material/Download";
import DownloadingIcon from "@mui/icons-material/Downloading";
import axios from "axios";

function DownloadReport({ url, fileName, onError }) {
  const [loading, setLoading] = useState(false);
  // const [url, setUrl] = useState(null);
  // useEffect(() => {
  //   if (mapping[type]) {
  //     setLoading(true);
  //     checkReportFile({ reportName: mapping[type] })
  //       .then((res) => {
  //         console.log("report is: ", res);
  //         if (res && res.success === "1" && res.url !== "") {
  //           setUrl(res.url);
  //           // downloadFile(res.url);
  //         }
  //       })
  //       .catch((e) => {
  //         console.log("Error: ", e);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // }, []);
  function downloadFile() {
    setLoading(true);
    const axiosWithoutAuth = axios.create();
    delete axiosWithoutAuth.defaults.headers.common["Authorization"]; //Delete 2.0 Authorization token for aws.
    console.log("Headers: ", axiosWithoutAuth.defaults.headers);
    axiosWithoutAuth({
      url,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        onError("Network Error");
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  }

  // if (loading) return <>Loading</>;
  if (!url) return <></>;
  return (
    <div>
      <span className="pointer" onClick={downloadFile}>
        {loading ? <DownloadingIcon /> : <Download />}
      </span>
      {/* <Atomic.Button
        isLoading={loading}
        startIcon={<Download />}
        title={"Existing Report"}
        color={"success"}
        onClick={downloadFile}
      /> */}
    </div>
  );
}

export default DownloadReport;
