import axios from "axios";


export const BackUnique=(val,cb)=>{
    var sendData={
        type:'Exists',
        val
    }
axios.post('http://localhost:5000/a',sendData).then((res)=>{
   // console.log("From Back ",res.data);
    cb(res.data);
})
}


export const sendPayload=(data,cb)=>{
axios.post('http://localhost:5000/b',data).then((res)=>{
    cb(res.data);
})
}

export const onsave=(data,cb)=>{
    axios.post('http://localhost:5000/d',data).then((res)=>{
        cb(res.data);
    })   
}

export const sendRow=(data,cb)=>{
    axios.post('http://localhost:5000/c',data).then((res)=>{
        cb(res.data);
    })
    }