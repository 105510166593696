import React,{useEffect} from 'react';
import fun2 from "./main"
import "./style.css"

function AGgrid(){

    useEffect(()=>{
     
        const script = document.createElement('script');
        const script2=document.createElement('script');
        script2.id="dtm";
        script2.src = "https://unpkg.com/@ag-grid-enterprise/all-modules@25.2.0/dist/ag-grid-enterprise.min.js";
       script.src="http://127.0.0.1:5500/dist/bundle.js"
       script.async = true;
       script2.async = true;
       document.body.appendChild(script2);
        document.body.appendChild(script);
        fun2();
      

    },[])

  
    return(
        <>
     
        <div id="myGrid" className="ag-theme-alpine"  style={{height:'70vh',width:'60%'}} >
		</div>
		<script>var __basePath = './';</script>
		<script src="https://unpkg.com/@ag-grid-enterprise/all-modules@25.2.0/dist/ag-grid-enterprise.min.js">
		</script> 
 <script src="./dist/bundle.js">
		</script>
       
        </>
    )
}



export default AGgrid;