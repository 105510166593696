import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import ContentLoader from 'react-content-loader'

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width={400}
    height={130}
    viewBox="0 0 400 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
    <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
    <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
    <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
    <circle cx="20" cy="20" r="20" />
  </ContentLoader>
)

// function Card_3({ Elements }) {
//   const [elvation, setElevation] = useState(1)
//   var styleArray = [
//     { position: 'absolute', top: 10, left: 30 },
//     { position: 'absolute', top: 30, right: 50 },
//   ]
//   return (
//     <>
//       <Paper
//         elevation={elvation}
//         onMouseEnter={() => {
//           setElevation(3)
//         }}
//         onMouseLeave={() => {
//           setElevation(1)
//         }}
//         style={{
//           height: 100,
//           width: 400,
//           position: 'relative',
//         }}
//       >
//         {Elements.map((ele, index) => {
//           let compArr = ele.Component.split('.')
//           let compAddress
//           if (compArr.length === 3)
//             compAddress =
//               compArr[0] + 'Component/' + compArr[1] + '/' + compArr[2]
//           let Comp

//           return (
//             <div style={styleArray[index]}>
//               {(Comp = require('../../' + compAddress).default)}
//               <Comp key={index} {...ele.props} />
//             </div>
//           )
//         })}
//       </Paper>
//       <br />
//     </>
//   )
// }

function Card_3({ params,Cstyle }) {
  console.log('From card3', params)
  const [loading, setloading] = useState(true)
  const [elvation, setElevation] = useState(1)
  var styleArray = [
    { position: 'absolute', top: 10, left: 30 },
    { position: 'absolute', top: 30, right: 50 },
  ]
  var ran = parseInt(Math.random() * 10) * 1000
 
  useEffect(() => {
    setTimeout(() => {
      setloading(false)
    }, ran)
  })
  return (
    <>
      <Paper
        elevation={elvation}
        onMouseEnter={() => {
          setElevation(3)
        }}
        onMouseLeave={() => {
          setElevation(1)
        }}
        style={{
          height: 100,
          width: 400,
          position: 'relative',
          margin: 10,
          padding: 5,
          ...Cstyle
        }}
      >
        {loading ? (
          <MyLoader />
        ) : (
          <>
            <div style={styleArray[0]}>
              <span>Image</span>
            </div>
            <div style={styleArray[1]}>
              <span>Para</span>
            </div>
          </>
        )}
      </Paper>
    </>
  )
}

export default Card_3
