export const appraiseeSnapshotData = {
  name: "Surya Teja",
  employeeCode: 1987,
  role: "Product Engineering",
  designation: "Intern",
  functioning: "Remote",
  location: "Andhra Pradesh",
  grade: "A",
  employType: "Hard",
};
