import React from 'react'

function Comp(props) {
    const {}=props
    return (
        <>
            
        </>
    )
}

export default Comp
