import React, { useState,useEffect } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { isPhoneNumber } from '../../Utils/Validators/library'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from 'react-phone-number-input'
import {genrateOTP} from "./Utils/PhoneApi"
import './field.css'
import 'react-phone-number-input/style.css'


function Phone(props) {
  //var {label}=props
  const dispatch = useDispatch()
  const [error, setError] = useState({ isError: false, msg: '' })
  const [otpMode, setOtpMode] = useState(false)
  const [otpValue, setOtpValue] = useState('')
  const [phoneValue,setPhoneValue]=useState(null);

  useEffect(() => {
    console.log("Phone Value ",phoneValue)
  
  }, [phoneValue])
  const { id, compNumber } = props
  var { renderNot, value, OTP,phoneType } = useSelector((state) =>
    JSON.parse(JSON.stringify(state.DataReducer[id][compNumber])),
  )

  function submit() {
    if (!isPhoneNumber(value)) {
      setError({
        isError: true,
        msg: 'Enter a Valid Mobile Number',
      })
      return
    }
    if (OTP) {
      setOtpValue('')
      setOtpMode(true)
    }
  }
  function HandleChange(e) {
    let val = e.target.value
    setError({isError:false})
    if (otpMode) {
      setOtpValue(val)
      return
    }
    dispatch({
      type: 'UPDATE_PROPS',
      payload: {
        id,
        componentNumber: compNumber,
        data: val,
        props: 'value',
      },
    })
  }
  function verify() {
    setOtpMode(false)
  }
  if(renderNot)return <></>
  if (otpMode) {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <input
            className="partitioned"
            value={otpValue}
            helperText={error.isError && error.msg}
            error={error.isError}
            onChange={HandleChange}
            id="standard-basic"
            label={'Mobile Number'}
            variant="standard"
            maxLength={6}
          />
          <br />
          <span
            onClick={verify}
            style={{ cursor: 'pointer', color: 'blue', marginLeft: 20 }}
          >
            Verify
          </span>
        </div>
        <br />
      </>
    )
  }
  return (
    <div>
      {/* <TextField
        value={value}
        helperText={error.isError && error.msg}
        onBlur={submit}
        error={error.isError}
        onChange={HandleChange}
        id="standard-basic"
        label={'Mobile Number'}
        variant="standard"
      /> */}
    
        <PhoneInput
        
        country='IN'
      placeholder="Enter phone number"
      value={value}
      onChange={setPhoneValue}/>
      <br />
    </div>
  )
}

export default Phone
