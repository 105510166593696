import React from "react";
import PlanProgress from "./PlanProgress";

function AppraisalReport({ onClose, isAppraiser }) {
  return (
    <PlanProgress
      reportType={"AR"}
      onClose={onClose}
      isAppraiser={isAppraiser}
    />
  );
}

export default AppraisalReport;
