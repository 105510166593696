import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import ContentLoader from 'react-content-loader'
import Loader from '../../Utils/Loader'

//Elements
import Date_1 from '../../MComponent/Date/Date_1'
import BtnArray from '../../MComponent/ButtonArray/BtnArray'
import Head_1 from '../../AComponent/Heading/Head_1'
import Text_1 from '../../AComponent/Text/Text_1'

/*
E1->
*/

import Data from "../../Testjson/Homepage/NTAData.json";

function Card_4(props) {
  const { Styles, ElementMap } = props
  const [cardData, setCardData] = useState(Data)

  var elementPosition = {
    E1: {
      component: Date_1,
      style: { position: 'absolute', top: 10, left: 10 },
    },
    E2: {
      component: BtnArray,
      style: { position: 'absolute', bottom: 10, width: '100%' },
     
    },
    E3: {
      component: Head_1,
      style: { position: 'absolute', top: 10, left: 120 },
    },
    E4: {
      component: Text_1,
     
      style: { position: 'absolute', top: 100, left: 20, width: '75%' },
    },
  }

  return (
    <>
      <Paper
        style={{
          height: 180,
          width: 350,
          position: 'relative',
          margin: 10,
          padding: 5,
          ...Styles,
        }}
      >
        {ElementMap.map((ele) => {
          var element = ele.Element
          var Component = elementPosition[element].component;
          return <div style={{...elementPosition[element].style}}> <Component styles={ele.styles} {...cardData[ele.mapTo]} /></div>
        })}
      </Paper>
    </>
  )
}

export default Card_4
