import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import HelperTip from "../Tooltip/HelperTip";
import useProps from "../../Utils/CustomHooks/GetProps";
import useRender from "../../Utils/CustomHooks/RenderComponent";

const defaultProps = {
  label: null,
  onChange: null,
  type: null,
  onBlur: null,
  rows: null,
  value: null,
  styles: null,
  InputProps: null,
  error: null,
  disable: null,
  InputProps: null,
  maxChars: null,
  regex: null,
  errorMsg: null,
  containerStyles: {},
  helperText: null,
};

function Input(props = defaultProps) {
  const [Error, setError] = useState(null);
  var {
    label,
    onChange,
    type,
    value,
    onBlur,
    rows,
    InputProps,
    variant,
    error,
    styles,
    disable,
    tooltip,
    maxChars,
    id,
    group,
    regex,
    errorMsg,
    containerStyles,
    helperText,
  } = useProps(props);
  console.log("Onchange is: ", group);
  if (!onChange) {
    onChange = () => {};
  }
  if (!onBlur) {
    onBlur = () => {};
  }
  if (!variant) {
    variant = "outlined";
  }
  function onBlurCalled(val) {
    if (regex) {
      let regexExp = new RegExp(regex);
      console.log(
        "Password reges: ",
        regexExp.test(val),
        " ",
        regex,
        /^[0-9]{10}$/.test("1234567890")
      );
      if (!regexExp.test(val)) {
        if (errorMsg) setError(errorMsg);
        else setError("Error");
      } else {
        setError(null);
      }
    }
    onBlur(val);
  }
  if (useRender({ id, group })) return <></>;
  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1 },
        ...containerStyles,
      }}
      noValidate
      //autoComplete="off"
    >
      <TextField
        style={styles}
        multiline={rows}
        rows={rows}
        error={error || Error ? true : false}
        helperText={(error && error) || (Error && Error) || helperText}
        {...props}
        onBlur={(e) => onBlurCalled(e.target.value)}
        value={value}
        type={type}
        onChange={(e) => onChange(e.target.value)}
        label={label}
        variant={variant}
        InputProps={{
          disabled: disable,
          ...InputProps,
        }}
        inputProps={{
          maxLength: maxChars ? maxChars : 1000,
        }}
      />
    </Box>
  );
}

export default Input;
