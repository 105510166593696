import React, { useState, useEffect } from "react";
import WorkflowButton from "../../Workflow/WorkflowButton";
import { useDispatch, useSelector } from "react-redux";
import Atomic from "../../../AComponent/Atomic";

const { MultiSelect } = Atomic;
const id = "MPP";
const DimensionFeatureId = "19144";
function GoalSettings() {
  const { GoalSettingsWorkFlowID, GoalSettingsDimensions, dimList } =
    useSelector((state) => {
      if (!state.DataReducer[id]) return {};
      return state.DataReducer[id];
    });
    const dispatch=useDispatch()
  function updateState(val, type) {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: type,
        data: val,
      },
    });
  }
  return (
    <div>
      <div className="Flex">
        <WorkflowButton value={GoalSettingsWorkFlowID} AuthorFeatureID={"19153"} onChange={(val)=>{
          console.log("Work Mpp: ",val)
          updateState(val,"GoalSettingsWorkFlowID")
        }}/>
      </div>
      <div className="Flex">
        <MultiSelect
          label={"Dimesnions"}
          mapper={{
            label: "DimensionName",
            value: "TransactionID",
          }}
          includeID={true}
          featureId={DimensionFeatureId}
          value={GoalSettingsDimensions}
          apiResult={dimList}
          storeApiResult={(val) => updateState(val, "dimList")}
          onChange={(val) => updateState(val, "GoalSettingsDimensions")}
        />
      </div>
    </div>
  );
}

export default GoalSettings;
