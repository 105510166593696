import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  InputBase,
  IconButton,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
//import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LoadingButton from "@mui/lab/LoadingButton";

import { Modal } from "@mui/material";
import {
  fetchData,
  remveGroup,
  deactivateDocument,
} from "../../Utils/Validators/library";

import Loader from "../../Utils/Loaders/Loader_1";

import "./lineitem.css";
const FILE_NAME = "LineItem.js: ";

function LineItem({ id, compNumber }) {
  const {
    value,
    heading,
    groupsToBeEnabled,
    editMode,
    emptyMsg,
    mapping,
    columns,
    getData,
    renderNot,
    pageLimit,
  } = useSelector((state) => state.DataReducer[id][compNumber]);
  console.log("From lineitem: ", value);
  const tenantCode = document.getElementById("root").dataset.tenantcode ;
  const dispatch = useDispatch();
  const [disableAdd, setDisableAdd] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [includeDeactivate, setIncludeDeactivate] = useState(false);
  const [search, setSearch] = useState("");
  const [valueCopy, setValueCopy] = useState(null);
  const [deactivateingDoc, setDeactivatingDoc] = useState(false);
  const [confirmModal, setConfirmModal] = useState(null);
  const [dataView, setDataView] = useState(null);
  const [pagination, setPagination] = useState({
    limit: pageLimit ? pageLimit : 10,
    start: 0,
    end: 0,
  });
  useEffect(() => {
    console.log("searching: ", search, " ", data);
    if (search === "" && data) {
      let list = data;
      if (list.length > pagination.limit) {
        setDataView(list.slice(0, pagination.limit));
      } else {
        setDataView(list);
      }
      setPagination((prev) => ({
        ...prev,
        end: Math.ceil(list.length / pagination.limit),
      }));
      dispatch({
        type: "UPDATE_PROPS",
        payload: {
          id,
          componentNumber: compNumber,
          data: list,
          props: "value",
        },
      });
    }

    if (search !== "" && (data?.length > 0 || valueCopy?.length > 0)) {
      let list = data;
      console.log("searchin11 ", list);
      list = list.filter((item) => {
        console.log("Seracing: ", JSON.stringify(item), " ", search);
        return JSON.stringify(item)
          .toLowerCase()
          .includes(search.toLowerCase());
      });
      if (list.length > pagination.limit) {
        setDataView(list.slice(0, pagination.limit));
      } else {
        setDataView(list);
      }
      setPagination((prev) => ({
        ...prev,
        start:0,
        end: Math.ceil(list.length / pagination.limit),
      }));
      console.log("Matched: ", list);
      dispatch({
        type: "UPDATE_PROPS",
        payload: {
          id,
          componentNumber: compNumber,
          data: list,
          props: "value",
        },
      });
    }
  }, [search, data]);
  useEffect(() => {
    if (data && data.length > 0) {
      var end = 1;
      if (data.length > pagination.limit) {
        let arr = data;
        arr = arr.slice(0, pagination.limit);
        setDataView([...arr]);
        end = Math.ceil(data.length / pagination.limit);
      } else {
        setDataView(data);
      }
      setPagination((prev) => ({
        ...prev,
        start: 0,
        end,
      }));
    }
  }, [data]);
  function fetchDatacall(allowDataFetch) {
    // console.log("fetchDatacall: ",getData && (!value || value.length === 0 || allowDataFetch)," ",getData," ",allowDataFetch)
    if (getData && (!value || value.length === 0 || allowDataFetch)) {
      let query = getData.query;
      console.log("Ten code from lineitem: ",tenantCode)
      query = query.replace("tenantCode", "" + tenantCode);

      getData.query = query;
      //     console.log('From Lineitem: ',query," ",tenantId)
      fetchData({ ...getData, list: true })
        .then((res) => {
          console.log("Result: ", res);
          setValueCopy(res);
          res = res.filter((a) => a.Status === "1");

          // if (res.length > pagination.limit) {
          //   setData(res.slice(0, pagination.limit));
          // } else {
          setData(res);
          // }
          setPagination((prev) => ({
            ...prev,
            end: Math.ceil(res.length / pagination.limit),
          }));

          dispatch({
            type: "UPDATE_PROPS",
            payload: {
              id,
              componentNumber: compNumber,
              data: res,
              props: "value",
            },
          });
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        })
        .finally(() => {
          //  setAllowDataFetch(false)
        });
    } else {
      setLoading(false);
    }
  }
  useEffect(() => {
    console.log("From dispatch : ", value);
    fetchDatacall();
    if (!renderNot) {
      dispatch({
        type: "EDIT_MODE",
        payload: {
          id,
          header: {
            TransactionID: "",
            EffectiveFromTimestamp: "",
          },
        },
      });
    }
  }, [renderNot]);
  function deactivateDoc() {
    setDeactivatingDoc(true);
    // setAllowDataFetch(true);
    deactivateDocument({ TransactionID: confirmModal, id })
      .then(() => {
        dispatch({
          type: "SET_MESSAGE",
          payload: {
            id: 0,
            message: "Success!",
          },
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_MESSAGE",
          payload: {
            id: 1,
            message: "Oops Something Went Wrong!",
          },
        });
      })
      .finally(() => {
        setConfirmModal(null);
        setDeactivatingDoc(false);
        fetchDatacall(true);
      });
    //   console.log("Deactivate: ",confirmModal)
    //   setDeactivatingDoc(true);
    // let header=store.getState().DataReducer[id]?.submitPayload?.Header;
    // return new Promise((resolve,reject)=>{
    //   if(!header){
    //     setDeactivatingDoc(false);
    //     reject();
    //   }

    // })
  }
  function Pagination() {
    function setNewData(startIndex) {
      let endIndex = startIndex + pagination.limit - 1;
      if (endIndex < data.length) {
        setDataView(data.slice(startIndex, endIndex + 1));
      } else {
        setDataView(data.slice(startIndex, data.length));
      }
    }
    function next() {
      if (pagination.start + 1 >= pagination.end) return;
      let startIndex = (pagination.start + 1) * pagination.limit;
      console.log("Next: ", startIndex);
      setNewData(startIndex);
      setPagination((prev) => ({
        ...prev,
        start: pagination.start + 1,
      }));
    }
    function prev() {
      if (pagination.start === 0) return;
      let startIndex = (pagination.start - 1) * pagination.limit;
      setNewData(startIndex);
      setPagination((prev) => ({
        ...prev,
        start: pagination.start - 1,
      }));
    }
    return (
      <>
        <span>
          <ArrowBackIosNewIcon onClick={prev} style={{ cursor: "pointer" }} />{" "}
          {pagination.start + 1 + " / " + pagination.end}{" "}
          <ArrowForwardIosIcon onClick={next} style={{ cursor: "pointer" }} />
        </span>
      </>
    );
  }
  function renderBack(index, editMode) {
   /// index = pagination.start * pagination.limit + index;
    Object.keys(dataView[index])?.map((key) => {
      dispatch({
        type: "UPDATE_PROPS",
        payload: {
          id,
          componentNumber: key,
          data: dataView[index][key],
          props: "value",
        },
      });
    });
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: compNumber,
        data: true,
        props: "renderNot",
      },
    });

    console.log("Test: editmode ", dataView[index]);
    if (editMode)
      dispatch({
        type: "EDIT_MODE",
        payload: {
          id,
          header: {
            TransactionID: dataView[index].TransactionID,
            EffectiveFromTimestamp: dataView[index]?.EffectiveFromTimestamp,
            RequestType: "1",
          },
        },
      });

    enableAllGroups();
  }

 

  function enableAllGroups() {
    groupsToBeEnabled.map((ele) => {
      dispatch({
        type: "ADD_GROUP",
        payload: {
          id,
          groupName: ele,
        },
      });
    });
  }

  function openForm() {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: compNumber,
        data: true,
        props: "renderNot",
      },
    });

    if (value && value[0])
      Object.keys(value[0])?.map((key) => {
        dispatch({
          type: "UPDATE_PROPS",
          payload: {
            id,
            componentNumber: key,
            data: "",
            props: "value",
          },
        });
      });
    //setDisableAdd(true);
    //if(editMode)return;
    enableAllGroups();
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: compNumber,
        data: null,
        props: "editMode",
      },
    });
  }
  function cancel() {
    groupsToBeEnabled.map((ele) => {
      remveGroup(ele,id );
    });
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: compNumber,
        data: false,
        props: "renderNot",
      },
    });
  }
  function checkboxChanged(check) {
    let arr = valueCopy;
    if(!arr)return;
    arr = check
      ? arr.filter((a) => a.Status === "1" || a.Status === "3")
      : arr.filter((a) => a.Status === "1");
    console.log("Arr: ", arr, " ", check);
    setData([...arr]);
    setIncludeDeactivate(check);
    //setSearch("")
  }

  function renderConfirmModal() {
    return (
      <>
        <Modal
          open={confirmModal !== null}
          onClose={() => setConfirmModal(null)}
        >
          <div className="Flex" style={{ height: "100%" }}>
            <Paper style={{ padding: 20 }}>
              <div className="Flex">
                <div>
                  <h5>Are you sure you want to deactivate this document?</h5>
                </div>
                <br />
                <div className="Flex-row">
                  <div>
                    <Button onClick={() => setConfirmModal(null)}>
                      Cancel
                    </Button>
                  </div>
                  <div>
                    {/* <Button>Yes</Button> */}
                    <LoadingButton
                      loading={deactivateingDoc}
                      onClick={deactivateDoc}
                    >
                      Yes
                    </LoadingButton>
                  </div>
                </div>
              </div>
            </Paper>
          </div>
        </Modal>
      </>
    );
  }

  if (loading)
    return (
      <div style={{ width: "100%", padding: 50 }}>
        <Loader />
      </div>
    );
  if (renderNot)
    return (
      <div className="Flex">
        <Button variant="contained" color="primary" onClick={cancel}>
          {"< Back"}
        </Button>
        <br />
      </div>
    );
  if (!value)
    return (
      <>
        <div className="Flex">
          <h3>{emptyMsg ? emptyMsg : "List Item Is Empty!"}</h3>
          <br />

          <Button onClick={openForm}>{"Add"}</Button>
        </div>
      </>
    );
  return (
    <>
      {renderConfirmModal()}
      <Paper className="Flex-row">
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          onChange={(e) => setSearch(e.target.value)}
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      <br />
      {dataView &&<div className="Flex">
        <FormControlLabel
          control={
            <Checkbox
              checked={includeDeactivate}
              onChange={(e) => {
                checkboxChanged(e.target.checked);
              }}
            />
          }
          label="Include Deactivated Document"
        />
      </div>}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns?.map((ele) => (
                <TableCell>{Object.keys(ele)[0]}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {/* {value.length > 0 && */}

            {dataView &&
              dataView?.map((row, index) => {
                //if (!inactive && row.Status === '3' ) return <></>
                if (row.Status !== "1" && row.Status !== "3") return <></>;
                if (!includeDeactivate && row.Status === "3") return <></>;
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/* {Object.keys(row).map((ele, cellIndex) => {
                  if (cellIndex > 3) return <></>
                  return (
                    <TableCell component="th" scope="row">
                      {row[ele]}
                    </TableCell>
                  )
                })} */}

                    {columns?.map((ele) => (
                      <TableCell component="th" scope="row">
                        {row[ele[Object.keys(ele)[0]]]}
                      </TableCell>
                    ))}
                    <TableCell>
                      {row.Status === "1" && (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => renderBack(index, true)}
                        >
                          {" "}
                          <EditIcon />
                        </div>
                      )}
                    </TableCell>
                    {row.Status === "1" ? (
                      <TableCell
                        onClick={() => setConfirmModal(row.TransactionID)}
                        style={{ cursor: "pointer" }}
                      >
                        <span style={{ color: "red" }}> Deactivate</span>
                      </TableCell>
                    ) : (
                      <TableCell>Deactivated</TableCell>
                    )}
                    {/* {row.Status && (
                      <TableCell>
                        <FormGroup>
                          {row.Status === '1' && (
                            <ToggleOffIcon
                              style={{ cursor: 'pointer' }}
                              color="info"
                            />
                          )}
                          {row.Status === '2' && (
                            <ToggleOnIcon style={{ cursor: 'pointer' }} />
                          )}
                        </FormGroup>
                      </TableCell>
                    )} */}
                    <TableCell
                      style={{ cursor: "pointer" }}
                      // onClick={() => deleteItem(index)}
                    >
                      {/* <DeleteIcon sx={{ color: 'red' }} /> */}

                      <Button
                        startIcon={<ContentCopyIcon />}
                        color="primary"
                        onClick={() => renderBack(index, false)}
                      ></Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <div className="Flex">
        {data?.length >= pagination.limit && pagination.end!==0 && <Pagination />}
      </div>
      <br />
      <div className="Flex">
        <Button onClick={openForm}>{"Add"}</Button>
      </div>
    </>
  );
}

export default LineItem;
