import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useProps from "../../Utils/CustomHooks/GetProps";
/*
{
    value: [number Required] from 0-100 (percentage)
    label: [string optional] 
    size:  [number optional] in pixel
}
*/
const initialProps={
  size:null,
  value:null,
  label:null
}
function Progress(props=initialProps) {
  const { size, value, label } = useProps(props);
 

  return (
    <div>
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size={size ? size : 150}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(value)}%`}</Typography>
        </Box>
      </Box>
      <div className="Flex">
        <Typography variant="caption" component="div" color="text.secondary">
          {label}
        </Typography>
      </div>
    </div>
  );
}
export default Progress;
