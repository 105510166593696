'use strict';
const path = require( 'path' );
module.exports = {
    // https://webpack.js.org/configuration/entry-context/
    entry: './req1.js',
    // https://webpack.js.org/configuration/output/
    output: {
        path: path.resolve( __dirname, 'dist' ),
        filename: 'bundle.js'
    },
    module: {
        rules: [
            {
                test: /ckeditor5-[^/\\]+[/\\]theme[/\\]icons[/\\][^/\\]+\.svg$/,
                use: [ 'raw-loader' ]
            },
            {
                test: /ckeditor5-[^/\\]+[/\\]theme[/\\].+\.css$/,
                use: [
                    {
                        loader: 'style-loader',
                        options: {
                            injectType: 'singletonStyleTag',
                            attributes: {
                                'data-cke': true
                            }
                        }
                    }
                    // },
                    // {
                    //     loader: 'postcss-loader',
                    //     options: styles.getPostCssConfig( {
                    //         themeImporter: {
                    //             themePath: require.resolve( '@ckeditor/ckeditor5-theme-lark' )
                    //         },
                    //         minify: true
                    //     } )
                    // }
                ]
            }
        ]
    },
    // Useful for debugging.
    devtool: 'source-map',
    // By default webpack logs warnings if the bundle is bigger than 200kb.
    performance: { hints: false }
};