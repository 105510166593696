import React from "react";
import EwToolTip from "./Tooltip";
//import HelpIcon from "@mui/icons-material/Help";

// function Wrapper() {
//   return <HelpIcon fontSize="small" />;
// }

function HelperTip({ title }) {
  return (
    <EwToolTip
      title={title}
      Icon={<></>}
      iconProps={{ fontSize: "small", color: "primary" }}
    />
  );
}

export default HelperTip;
