import { styled } from "@mui/material";
import Menu  from "@mui/icons-material/Menu";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

//Components
import PlanInit from "../../NComponent/pms/PlanInit";
import Assesment from "../../NComponent/pms/Assesment";

const mapper = {
  PlanInit: PlanInit,
  Assessment: Assesment,
};

const PopoverContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "auto",
  padding: "8px",
});

const PopoverRow = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  gap: "2em",
});

function Hamburger({ Sections, setComponent }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const history = useHistory();

  return (
    <div style={{ display: "flex" }}>
      <IconButton edge="start" aria-label="menu" onClick={handleClick}>
        <Menu />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{ style: { padding: "0 1em" } }}
      >
        <PopoverContent>
          <PopoverRow>
            {Sections.map((section, index) => {
              // const SectionIcon = require("@mui/icons-material/" +
              //   section.Icon).default;
              const SectionIcon =()=><></>
              return index % 2 === 0 ? (
                <List>
                  <div
                    style={{
                      display: "flex",
                      gap: "1em",
                      alignItems: "center",
                      borderBottom: "1.5px solid #C5C5C5",
                      paddingBottom: "0.5em",
                    }}
                  >
                    <SectionIcon />
                    <Typography variant="h6">{section.Title}</Typography>
                  </div>
                  {section.Options.map((option, index2) => (
                    <ListItem key={option.OptionID} disablePadding>
                      <ListItemButton
                        sx={{ padding: "0.2em 0.3em" }}
                        onClick={() => {
                          console.log(history, "Hehe");
                          handleClose();
                          console.log("Ham clicked:", option.params);
                          if (option.params.component) {
                            if (mapper[option.params.component]) {
                              console.log("Ham clicked: 1", mapper);
                              setComponent(option.params.component);
                            }
                          } else {
                            history.push(option.params.route, {
                              state: option.params.layout,
                            });
                          }
                        }}
                      >
                        <ListItemText primary={option.Name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <></>
              );
            })}
          </PopoverRow>
          <PopoverRow>
            {Sections.map((section, index) => {
              // const SectionIcon = require("@mui/icons-material/" +
              //   section.Icon).default;
              const SectionIcon =()=><></>
              return index % 2 !== 0 ? (
                <List>
                  <div
                    style={{
                      display: "flex",
                      gap: "1em",
                      alignItems: "center",
                      borderBottom: "1.5px solid #C5C5C5",
                    }}
                  >
                    <SectionIcon />
                    <Typography variant="h6">{section.Title}</Typography>
                  </div>
                  {section.Options.map((option, index2) => (
                    <ListItem key={option.OptionID} disablePadding>
                      <ListItemButton
                        sx={{ padding: "0.2em 0.3em" }}
                        onClick={() => {
                          console.log(history, "Hehe");
                          handleClose();
                          if (option.params.component) {
                            if (mapper[option.params.component]) {
                              console.log("Ham clicked: 1", option.params.component);
                              setComponent(option.params.component);
                            }
                          } else {
                            history.push(option.params.route, {
                              state: option.params.layout,
                            });
                          }
                        }}
                      >
                        <ListItemText primary={option.Name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <></>
              );
            })}
          </PopoverRow>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default Hamburger;
