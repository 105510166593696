import { useSelector } from "react-redux";

function useRender({ id, group }) {
  const enabledGroups = useSelector((state) => {
    if (!id) return null;
    return state.DataReducer[id]?.otherData?.enabledGroups;
  });
  if(!id)return false;
  console.log("Inside userender: ", id, " ", group, " ", enabledGroups);
  if (!group || !enabledGroups) return false;
  if (enabledGroups.length === 0) return false;
  return !enabledGroups.includes(group);
}

export default useRender;
