export function rgbaToHex(rgba) {
  rgba = rgba.replace(/\s/g, "").toLowerCase();
  if (!/^rgba?\([\d,]+\)$/.test(rgba)) {
    console.error("Invalid RGBA color format");
    return;
  }

  const components = rgba
    .substring(rgba.indexOf("(") + 1, rgba.lastIndexOf(")"))
    .split(",");

  const r = parseInt(components[0]);
  const g = parseInt(components[1]);
  const b = parseInt(components[2]);
  const rgbHex = ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0");

  let alphaHex = "";
  if (components.length === 4) {
    const alpha = parseFloat(components[3]);
    const alphaInt = Math.round(alpha * 255);
    alphaHex = alphaInt.toString(16).padStart(2, "0");
  }

  const hexCode = `#${rgbHex}${alphaHex}`;

  return hexCode;
}

export function generateIntegerID(str) {
  const numericPart = str.match(/\d*$/)[0];

  if (numericPart === "") {
    return str + "1";
  }
  const incrementedNumericPart = (parseInt(numericPart) + 1).toString();
  const paddedNumericPart = incrementedNumericPart.padStart(
    numericPart.length,
    "0"
  );
  return str.replace(/\d*$/, paddedNumericPart);
}
