import React, { useState } from "react";
import Atomic from "../../../AComponent/Atomic";
import Molecule from "../../../MComponent/Molecule";
import { Typography } from "@mui/material";
import FeatureIDs from "../../../Utils/Common/FeatureIDs";
import DownloadReport from "./DownloadReport";
import { generateReport } from "../../../Utils/Common/Library";
import AlertMessage from "../../../Utils/Messages/AlertMessage";
import Mapping, { messages } from "./data";
import ReportsTable from "./ReportsTable";
const { EwModal, Tabs } = Molecule;
const { Dropdown, Radio, Button } = Atomic;

function Maps({ onClose, isAppraiser }) {
  const [DimensionID, setDimensionID] = useState(-1);
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null);
  const [serverData, setServerData] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  function GenerateReport() {
    function submit() {
      const DimensionIDValue = serverData.find(
        (item) => item.value === DimensionID
      ).label;
      setMessage(messages.wait);
      setDisableButton(true);
      generateReport({
        queryName: Mapping.MR,
        requiredFields: [
          { DimensionID: DimensionID, DimensionIDValue },
          {
            Status: status,
            StatusValue: status === "1" ? "Active" : "Inactive",
          },
        ],
      })
        .then((res) => {
          setMessage(messages.success);
        })
        .catch((e) => {
          setMessage(messages.networkError);
        })
        .finally(() => {
          setDisableButton(false);
        });
    }
    return (
      <>
        {isAppraiser && "Appraiser view"}
        <div>
          <Dropdown
            styles={{ width: "100%" }}
            label={"Dimensions"}
            featureId={FeatureIDs.PerformanceDimensionGrouping}
            mapper={{
              label: "DimensionName",
              value: "TransactionID",
            }}
            value={DimensionID}
            onChange={(val) => {
              setDimensionID(val);
              setStatus(-1);
            }}
            storeApiResult={(data) => setServerData(data)}
            apiResult={serverData}
          />
        </div>
        <br />
        {DimensionID !== -1 && (
          <Radio
            flex={1}
            label={"Document Status"}
            menuItems={[
              { label: "Active", value: "1" },
              { label: "Inactive", value: "2" },
              // { label: "Both", value: "%" },
            ]}
            value={status}
            onChange={(val) => setStatus(val)}
          />
        )}
        {message && (
          <AlertMessage
            {...message}
            onClose={() => {
              setMessage(null);
            }}
          />
        )}
        <br />
        {status && status !== -1 && (
          <div className="Flex-row g20">
            <Button
              disable={disableButton}
              label={"Genrate New Report"}
              onClick={submit}
            />
            <br />
          </div>
        )}
      </>
    );
  }
  return (
    <div className="Flex-row">
      <EwModal open={true} onClose={onClose}>
        <div
          className="scroll"
          style={{ width: 800, minHeight: 300, maxHeight: 500 }}
        >
          <div className="Flex">
            <Typography variant="h1">Paramter Map Report</Typography>
          </div>
          <br />
          <Tabs
            componentList={[
              { label: "Generate Report", Component: GenerateReport },
              {
                label: "Download Reports",
                Component: ReportsTable,
                props: { type: "MR" },
              },
            ]}
          />
        </div>
      </EwModal>
    </div>
  );
}

export default Maps;
