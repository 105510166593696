import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";

function DimensionAccordian({ AccordianID, open, setOpen, title, children }) {
  return (
    <Box sx={{ width: "100%", margin: "" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.3em 0.3em 0.3em 0em",
          borderRadius: "6px",
          boxShadow: "0px 0px 6px #B1AEBB",
          margin: "0.5em",
          marginRight: "0",
          backgroundColor: "white",
        }}
        onClick={() => {}}
      >
        <Box sx={{ display: "flex", alignItems: "center", paddingLeft: "1em" }}>
          <IconButton
            sx={{ marginRight: "0.5em" }}
            onClick={(e) => {
              e.preventDefault();
              setOpen(AccordianID);
            }}
          >
            {!open && <KeyboardArrowRight sx={{ color: "black" }} />}
            {open && <KeyboardArrowDown sx={{ color: "black" }} />}
          </IconButton>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant={"h5"}>{title}</Typography>
            <Typography variant="tinytext"></Typography>
          </div>
        </Box>
      </Box>
      {open && (
        <Box
          sx={{
            borderTop: 0,
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

export default DimensionAccordian;
