import { Box } from "@mui/material";
import React, { useState } from "react";
import DimensionAccordian from "./DimensionAccordian";

function DimensionAccordiansGroup({ openHandler, menuItems, openedValue }) {
  console.log(
    openedValue,
    "OPENED VALUE",
    menuItems.find((item) => item.props.value === openedValue)
  );
  if (!menuItems) menuItems = [];
  const [opened, setOpened] = useState(
    menuItems.find((item) => item.props.value === openedValue)?.props.id || null
  );

  return (
    <>
      <Box>
        {menuItems.map(({ Component, props }) => {
          return (
            <Box key={props.id}>
              <DimensionAccordian
                title={props.title}
                AccordianID={props.id}
                open={props.id === opened}
                setOpen={(AccordianID) => {
                  console.log(AccordianID, opened, "ACCORIDAN DATA");
                  if (opened === AccordianID) {
                    setOpened(null);
                  } else {
                    setOpened(AccordianID);
                    openHandler && openHandler(props.value);
                  }
                }}
              >
                <Component {...props} />
              </DimensionAccordian>
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export default DimensionAccordiansGroup;
