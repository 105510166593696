import React from "react";
import Grid from '@mui/material/Grid';
// import { v4 as uuidv4 } from 'uuid';

const Section3 = (props) => {
    // const components = props.components;
    const placeholder1 = props.Placeholder_1;
    const Component1 = placeholder1 !== undefined ? require('./'+placeholder1.MComponent).default : null;
    const placeholder2 = props.Placeholder_2;
    const Component2 = placeholder2 !== undefined ? require('./'+placeholder2.MComponent).default : null;
    const placeholder3 = props.Placeholder_3;
    const Component3 = placeholder3 !== undefined ? require('./'+placeholder3.AComponent).default : null;
    return(
        <div style = {{
            display : 'flex',
            width : '100%',
            flexDirection : 'row',
            alignItems : 'start',
            justifyContent : 'space-evenly'
        }}>
            <div>
                <Component1 {...placeholder1}  />
            </div>
            <div>
                <Component2 {...placeholder2}  />
            </div>
            <div>
                <Component3 {...placeholder3}  />
            </div>
        </div>
    )
};

export default Section3;