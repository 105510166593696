import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper'
import Dropdown from '../../AComponent/Dropdown/Dropdown_3'
import Input from '../../AComponent/Input/Input_3'
import axios from 'axios'

function NI() {
  const menuItems = [{ label: 'India' }, { label: 'US' }]
  const [country, setCountry] = useState(-1)
  const [countryData, setCountryData] = useState(null)
  const [NInumber, setNInumber] = useState('')

  function callApi(country) {
    if (country === -1) {
        setCountryData(null)
        return
    }
    axios.post('http://localhost:5000/NI', {country}).then((res) => {
      setCountryData(res.data)
    })
  }

  function onCountryChange(e) {
      console.log("From NI: ",e.target.value)
    setCountry(e.target.value)
    callApi(e.target.value)
  }
  return (
    <Paper style={{ padding: 50 }}>
      <Dropdown
        label="Country"
        menuItems={menuItems}
        onChange={onCountryChange}
        value={country}
      />
      <br />
      {countryData && (
        <Input label={countryData.label} onChange={setNInumber} />
      )}
    </Paper>
  )
}

export default NI
