import React from 'react';

// components
// import EWHead from '../../Molecular/EWHead';
// import EWText from '../../Molecular/EWText';
// // import EWButtonArray from '../../Molecular/EWButtonArray';
// import EWDueDate from '../../Molecular/EWDueDate';

// const componentMap = {
//     EWHead : EWHead,
//     EWText : EWText,
//     EWButtonArray : EWButtonArray,
//     EWDueDate : EWDueDate,
// }

function Card_1( { cardInfo } ){
    
    // const title = cardInfo.props.Placeholder_1;
    // const desc = cardInfo.props.Placeholder_2;
    // const buttonArea = cardInfo.props.Placeholder_3;
    // const dueDate = cardInfo.props.Placeholder_4;

    // const TitleComp = componentMap[title["Mcomponent"]];
    // const DescComp = componentMap[desc["Mcomponent"]];
    // const ButtinAreaComp = componentMap[buttonArea["Mcomponent"]];
    // const DueDateComp = componentMap[dueDate["Mcomponent"]];


    return(
        // <div className="card_1">

        //     <div className="card_1Row1">
        //         <div>
        //             <TitleComp label={title.Props.Label}/>
        //         </div>
        //     </div>

        //     <div className="card_1Row2">
        //         <DescComp label={desc.props.Label}/>
        //     </div>

        //     <div className="card_1Row3">
        //         <ButtinAreaComp buttons={buttonArea.props}/>
        //     </div>

        //     <div className="card_1Row4">
        //         <DueDateComp label = {"Due "} dueDate={dueDate.props}/>
        //     </div>

        // </div>
        <>
        </>
    );
}

export default Card_1;