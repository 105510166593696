import React, { useState } from "react";
import Atomic from "../../../AComponent/Atomic";
import SectionEnable from "./SectionEnable";
import { useDispatch, useSelector } from "react-redux";

const id = "MPP";
const { Input, MultiSelect, EwSwitch } = Atomic;
function Coaching() {
  const options = [
    "Dimension",
    "Second Groups",
    "First Groups",
    "Parameter",
    "Coaching  Area",
    "Coaching  Plan",
    "Coaching Milestone",
    "CoachingAction Update",
    "Due Date",
    "Coaching  Progress",
  ];
  const { CoachingSectionLabel, CoachingSectionDescription, CoachingDataColumns, CoachingSection } =
    useSelector((state) => {
      if (!state.DataReducer[id]) return {};
      return state.DataReducer[id];
    });
  const dispatch = useDispatch();
  function updateState(val, type) {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: type,
        data: val,
      },
    });
  }

  return (
    <div>
      <div className="Flex">
        <EwSwitch
          label="Coaching"
          value={CoachingSection === "E"}
          onChange={(val) => updateState(val ? "E" : "D", "CoachingSection")}
        />
      </div>
      <br />
      {CoachingSection === "E" && (
        <>
          <div className="Flex-row">
            <div>
              <Input
                label={"Section Label"}
                value={CoachingSectionLabel}
                onChange={(val) => updateState(val, "CoachingSectionLabel")}
              />
            </div>
            <div>
              <Input
                label={"Section Description"}
                value={CoachingSectionDescription}
                onChange={(val) => updateState(val, "CoachingSectionDescription")}
                rows={4}
              />
            </div>
          </div>
          <div className="Flex">
            <MultiSelect
              label="Data Columns"
              menuItems={options}
              value={CoachingDataColumns}
              onChange={(val) => updateState(val, "CoachingDataColumns")}
            />
          </div>
        </>
      )}
      <br />
      <br />
    </div>
  );
}

export default Coaching;
