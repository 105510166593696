export const PeriodMap = {
  A: {
    label: "Annual",
    values: { A: "Annual" },
  },
  H: {
    label: "Half Yearly",
    values: {
      H1: "First Half",
      H2: "Second Half",
    },
  },
  Q: {
    label: "Quarterly",
    values: {
      Q1: "First Quarter",
      Q2: "Second Quarter",
      Q3: "Third Quarter",
      Q4: "Fourth Quarter",
    },
  },
  M: {
    label: "Monthly",
    values: {
      M1: "January",
      M2: "February",
      M3: "March",
      M4: "April",
      M5: "May",
      M6: "June",
      M7: "July",
      M8: "August",
      M9: "September",
      M10: "October",
      M11: "November",
      M12: "December",
    },
  },
  I: {
    label: "Interim",
    values: {
      I: "Interim",
    },
  },
};

export const PeriodTypesMap = {
  Q: ["Q1", "Q2", "Q3", "Q4"],
  H: ["H1", "H2"],
};

export function PeriodMapper(PeriodValue) {
  if (PeriodValue.includes("Q")) {
    return "Q";
  } else if (PeriodValue.includes("H")) {
    return "H";
  } else if (PeriodValue.includes("A")) {
    return "A";
  } else if (PeriodValue.includes("M")) {
    return "M";
  } else {
    return "";
  }
}

export function getFinalPeriod(PeriodType) {
  switch (PeriodType) {
    case "A":
      return "A";
    case "H":
      return "H2";
    case "Q":
      return "Q4";
    case "M":
      return "M12";
    default:
      return "";
  }
}
