import React, { useState, useEffect } from "react";
import Atomic from "../../../AComponent/Atomic";
import Applicability4 from "../../../MComponent/Applicability/Applicability4";
import EwTabs from "../../../MComponent/Tabs/Tabs";
import EwTable from "../../../MComponent/Table/Table";
import PMS from "../PMS";
import Mapping from "./Mapping";

const { Radio, Dropdown, EwSwitch } = Atomic;
const DimensionFeature = "19144";
const MapRules = ["MMM", "UUU", "DMM", "DUU", "DDM","DDU","MMU","MUU"];
const abbrMap = {
  D: "Disabled",
  E: "Enabled",
  N: "Not applicable",
  U: "User Defined",
  M: "Mapped",
  R: "Restricted",
  P: "Permitted",
};
const weightAbbrMap = {
  N: "No Weights",
  D: "Defined In Maps",
  A: "Aggregated",
  U: "User Defined",
};
const discardAndAddRules = {
  EEE: ["PPP", "RPP", "RRP", "RRR"],
  DEE: ["RPP", "RRP", "RRR"],
  DDE: ["RRR", "RRP"],
  EEU: ["RRN", "RPN", "PPN"],
  EUU: ["PNN", "RNN"],
};
const weightRule = {
  EEE: ["NNN", "DAU", "DNN", "ADN", "AAD", "AAU"],
  EEU: ["AAU", "ADU", "DDU", "ADU", "DDN"],
  DEE: ["NAU", "NAD", "NDN", "NDU", "NDD"],
  DDE: ["NND", "NNU"],
};
function DMP() {
  const [localState, setLocalState] = useState({
    level: 3,
    weightState: {
      wtallowed: [],
      wtselected: [],
    },
    map: {},
    targetRule: {},
    dimesionData: null,
  });
  const [openForm, setOpenForm] = useState(false);
  const [selected, setSelected] = useState([]);
  const [allowed, setAllowed] = useState([]);
  // const [tabs, setTabs] = useState([]);
  const {
    level,
    mapStructure,
    DimensionID,
    dimList,
    weightState,
    discarRules,
    addingRules,
    enableTarget,
    input,
    enableAchive,
  } = localState;

  function updateState(val, type) {
    console.log("Vlaue called: ", val, " ", type);
    setLocalState((prev) => ({ ...prev, [type]: val }));
  }
  useEffect(() => {
    let arr = Array.from({ length: 3 }, (ele, index) =>
      Array.from({ length: 3 }, () => {
        return null;
        //  if(index===0)return "EDU";else return "PR"
      })
    );
    console.log(arr);
    let select = arr.map((row) => row.map((val) => val));
    if (level === 3) {
      arr[0][0] = "ED";
    }
    if (level === 2 || level === 1) {
      arr[0][0] = "D";
      select[0][0] = "D";
      arr[0][1] = "ED";
    }
    if (level === 1) {
      arr[0][1] = "D";
      select[0][1] = "D";
      arr[0][2] = "E";
    }

    // select[0][0] = null;
    setSelected([...select]);
    setAllowed([...arr]);
  }, []);
  function getMenuItems(str) {
    return str.split("").map((ele) => ({
      label: abbrMap[ele],
      value: ele,
    }));
  }
  function getPossibleWeightValues(arr, i, prev) {
    if (!arr) return;
    var set = new Set();
    if (i === 0) {
      arr.forEach((ele) => {
        set.add(ele.charAt(i));
      });
    } else {
      arr.forEach((ele) => {
        if (ele.charAt(i - 1) === prev) set.add(ele.charAt(i));
      });
    }
    return [...set].join("");
  }

  function onValueChange(val, i, j) {
    let arr = allowed;
    let select = selected;
    select[i][j] = val;
    if (j === 2 && i !== 0) {
      if (i === 1) updateState(selected[i].join(""), "discarRules");
      else updateState(selected[i].join(""), "addingRules");
    }
    function getAllowed(j, type, struture, next) {
      console.log("from getallowed: ", j, type, struture, " ", next);
      var set = new Set();
      let arr = [];
      if (type === 0) {
        arr = MapRules;
      } else {
        arr = discardAndAddRules[struture];
      }
      arr?.forEach((ele) => {
        if (next) {
          set.add(ele.charAt(j));
        } else if (ele.charAt(j) === val) {
          set.add(ele.charAt(j + 1));
        }
      });
      return [...set].join("");
    }
    for (let k = i; k < 3; k++) {
      for (let l = k === i ? j + 1 : 0; l < 3; l++) {
        arr[k][l] = null;
        select[k][l] = null;
      }
    }
    if (i === 0) {
      if (j + 1 === 3) {
        let struture = select[0].join("");
        let wtallowed = weightState.wtallowed;
        let wtselected = weightState.wtselected;

        if (!wtallowed) wtallowed = [];
        if (!wtselected) wtselected = [];

        arr[1][0] = getAllowed(0, 1, struture, true);
        wtallowed[0] = getPossibleWeightValues(weightRule[struture], 0);
        if (!wtallowed[0]) wtselected = ["N", "N", "N"];
        updateState(struture, "mapStructure");
        console.log("From inside: ", { wtallowed, wtselected });
        updateState({ wtallowed, wtselected }, "weightState");
      } else {
        arr[i][j + 1] = getAllowed(j, 0);
      }
    } else {
      if (j + 1 === 3) {
        if (i + 1 !== 3) {
          arr[i + 1][0] = getAllowed(0, 1, mapStructure, true);
        }
      } else {
        arr[i][j + 1] = getAllowed(j, 1, mapStructure);
        if (arr[i][j + 1] === "N" && i === 1) {
          arr[2][0] = arr[1][0];
        }
        if (arr[i][j + 1] === "N" && j === 0) {
          arr[i][2] = "N";
        }
      }
    }

    setAllowed([...arr]);
    setSelected([...select]);
    if (i === 0) {
      updateState({}, "map");
      // updateState({wtallowed:[],wtselected:[]}, "weightState");
    }
    if (i === 0 && j !== 2) {
      updateState("", "mapStructure");
      updateState({ wtallowed: [], wtselected: [] }, "weightState");
    }
  }
  function RenderStructure() {
    if (allowed.length === 0) return <></>;
    return (
      <div>
        <div className="Flex">
          <div style={{ width: "100%" }}>
            <Dropdown
              apiResult={dimList}
              storeApiResult={(val) => updateState(val, "dimList")}
              label="Dimensions"
              featureId={DimensionFeature}
              mapper={{
                label: "DimensionName",
                value: "TransactionID",
              }}
              value={DimensionID}
              onChange={(val) => {
                updateState(val, "DimensionID");
                updateState({}, "map");
              }}
            />
            {console.log("Dimid: ", DimensionID)}
          </div>
        </div>
        {/* {JSON.stringify(allowed)}
        <br />
        {JSON.stringify(selected)} */}

        {DimensionID &&
          DimensionID !== -1 &&
          allowed.map((row, i) => {
            let heading = "Map Structure";
            if (i == 1) heading = "Discarding Map values";
            if (i == 2) heading = "Adding Values By User";
            return (
              <div>
                <div className="Flex">
                  {" "}
                  {allowed[i][0] && <h3>{heading}</h3>}
                </div>
                <br />
                <div
                  className="Flex-row"
                  style={{ gap: 40, alignItems: "flex-start" }}
                >
                  {row.map((ele, j) => {
                    if (!ele) return <></>;
                    let label = "Group-" + (3 - (j + 1));
                    if (j === row.length - 1) label = "Parameter";
                    let values = getMenuItems(ele);

                    if (values[0]?.value === "N")
                      return (
                        <div>
                          <div>{label}</div>
                          <br />
                          <div> Not Applicable</div>
                        </div>
                      );

                    return (
                      <div>
                        <Radio
                          label={label}
                          value={selected[i][j]}
                          menuItems={values}
                          onChange={(val) => onValueChange(val, i, j)}
                        />
                      </div>
                    );
                  })}
                </div>
                <br />
              </div>
            );
          })}
        <br />
        <div>{mapStructure?.length === 3 && <div>{RenderWeight()}</div>}</div>
        <br />

        <div className="Flex">{/* <Applicability4 /> */}</div>
      </div>
    );
  }
  function targetAndAchivement() {
    const inputList = [
      {
        label: "User Defined",
        value: "U",
      },
      {
        label: "Mapped",
        value: "M",
      },
    ];
    return (
      <>
        <div className="Flex">
          <div>
            <EwSwitch
              label="Target"
              value={enableTarget}
              onChange={(val) => updateState(val, "enableTarget")}
            />
          </div>
          {enableTarget && (
            <div>
              <Radio
                label="Input Type"
                menuItems={inputList}
                value={input}
                onChange={(val) => updateState(val, "input")}
              />
            </div>
          )}
        </div>
        <div className="Flex">
          <div>
            <EwSwitch
              label="Achievement"
              value={enableAchive}
              onChange={(val) => updateState(val, "enableAchive")}
            />
          </div>
        </div>
      </>
    );
  }
  function RenderWeight() {
    let { wtallowed, wtselected } = weightState;
    console.log("Render weight: ", weightState);

    function getValue(str) {
      return str
        .split("")
        .map((ele) => ({ value: ele, label: weightAbbrMap[ele] }));
    }
    function valueChanged(val, i) {
      wtselected[i] = val;
      for (let j = i + 1; j < 3; j++) {
        wtallowed[j] = null;
        wtselected[j] = null;
      }
      if (i === 2) {
        updateState({ wtallowed, wtselected }, "weightState");
        return;
      }
      console.log("On value changes: ", wtselected, " ", wtallowed);
      wtallowed[i + 1] = getPossibleWeightValues(
        weightRule[mapStructure],
        i + 1,
        val
      );
      updateState({ wtallowed, wtselected }, "weightState");
    }

    return (
      <>
        {mapStructure && wtallowed?.length !== 0 && (
          <>
            <div className="Flex">
              {" "}
              <h3>Weight Rules</h3>
            </div>
            <div
              className="Flex-row"
              style={{ gap: 40, alignItems: "flex-start" }}
            >
              {wtallowed.map((ele, index) => {
                if (!ele) return <></>;
                let label = "Group " + (index === 0 ? "2" : "1");
                if (index === 2) label = "Parameter";
                let values = getValue(ele);
                return (
                  <Radio
                    menuItems={values}
                    label={label}
                    value={wtselected[index]}
                    onChange={(val) => valueChanged(val, index)}
                  />
                );
              })}
            </div>
          </>
        )}

        <br />
        <hr />
      </>
    );
  }

  const tabs = [
    {
      Component: RenderStructure,
      label: "Structure",
      props: {
        parentState: localState,
        updateParentState: updateState,
      },
    },

    {
      Component: Mapping,
      label: "Map",
      props: {
        parentState: localState,
        updateParentState: updateState,
      },
    },
  ];
  return (
    <div style={{ marginLeft: -80 }}>
      {/* {JSON.stringify(localState)} */}
      <EwTabs componentList={tabs} />
    </div>
  );
}

export default DMP;
