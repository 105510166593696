import { Box } from "@mui/material";
import React, { useState } from "react";
import Atomic from "../../../AComponent/Atomic";
import { useDispatch, useSelector } from "react-redux";
import {
  AggregateScoreRatingScaleItems,
  AggregationRatingItems,
  DimensionAggregateScoreItems,
  ParameterAlignmentItems,
  ScoreRoundingItems,
  TargetEditingItems,
} from "./Items";

const { Dropdown, Input, Radio } = Atomic;

const initDocument = {
  AggregateScoreRatingScaleID: "",
  PlanDimensionWeight: "",
  ParameterAlignment: "",
  TargetEditing: "",
  DimensionAggregateScore: "",
  AggregationRating: "",
  ScoreRounding: "",
  RoundingValue: "",
};

const Dimensions = () => {
  const AggregatePlanScore = true;
  const [document, setDocument] = useState(initDocument);
  const [scales, setScales] = useState(null);

  const onChangeHandler = (key, value) => {
    setDocument((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <Box>
      <Box display={"flex"} alignItems={"center"} gap={"1em"}>
        <Box flex={1}>
          <Dropdown
            featureId={"19148"}
            apiResult={scales}
            storeApiResult={(val) => setScales(val)}
            mapper={{
              label: "RatingScaleName",
              value: "TransactionID",
            }}
            label={"Aggregate score rating Scale"}
            value={document.AggregateScoreRatingScaleID}
            onChange={(value) =>
              onChangeHandler("AggregateScoreRatingScaleID", value)
            }
            styles={{ width: "100%" }}
          />
        </Box>
        {AggregatePlanScore && (
          <Box flex={1}>
            <Input
              label={"Plan Dimension Weight"}
              onChange={(value) =>
                onChangeHandler("PlanDimensionWeight", value)
              }
              value={document.PlanDimensionWeight}
              styles={{ width: "100%" }}
            />
          </Box>
        )}
      </Box>
      <Box display={"flex"} alignItems={"center"} gap={"1em"} margin={"2em 0"}>
        <Box flex={1.2}>
          <Radio
            label={"Parameter Alignment"}
            menuItems={ParameterAlignmentItems}
            value={document.ParameterAlignment}
            onChange={(value) => onChangeHandler("ParameterAlignment", value)}
          />
        </Box>
        <Box flex={1}>
          <Radio
            label={"Target Editing"}
            menuItems={TargetEditingItems}
            value={document.TargetEditing}
            onChange={(value) => onChangeHandler("TargetEditing", value)}
          />
        </Box>
        <Box flex={1}>
          <Radio
            label={"Dimension Aggregate Score"}
            menuItems={DimensionAggregateScoreItems}
            value={document.DimensionAggregateScore}
            onChange={(value) =>
              onChangeHandler("DimensionAggregateScore", value)
            }
          />
        </Box>
      </Box>
      <Box display={"flex"} alignItems={"center"} gap={"1em"}>
        <Box flex={1}>
          <Dropdown
            menuItems={AggregationRatingItems}
            label={"Aggregation Rating"}
            value={document.AggregationRating}
            onChange={(value) => onChangeHandler("AggregationRating", value)}
          />
        </Box>
        <Box flex={1}>
          <Dropdown
            menuItems={ScoreRoundingItems}
            label={"Score Rounding"}
            value={document.ScoreRounding}
            onChange={(value) => onChangeHandler("ScoreRounding", value)}
          />
        </Box>
        {document.ScoreRounding === "RO" && (
          <Box flex={1}>
            <Input
              label={"Rounding Value"}
              type={"number"}
              onChange={(value) => onChangeHandler("RoundingValue", value)}
              value={document.RoundingValue}
              styles={{ width: "100%" }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Dimensions;
