import React from 'react';

function AText1({label}){
    return(
        <div>
            {label}
        </div>
    );
}

export default AText1;