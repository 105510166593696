import Input from "./Input/Input_3";
import Button from "./Buttons/Button_3";
import Checkboxes from "./CheckBox/checkbox_2";
import Progress from "./CircularProgress/Progress";
import Dropdown from "./Dropdown/Dropdown_3";
import Date from "./Picker/Date_1";
import Radio from "./Radio/Radio_3";
import MultiSelect from "./Dropdown/MultiSelect";
import EwSwitch from "./Switch/EwSwitch";
import EwSlider from "./Slider/Slider";
import EwStepper from "./Stepper/Stepper";
import EwToolTip from "./Tooltip/Tooltip";
import ConfirmationButton from "./Buttons/ConfirmationButton";

export default {
  Input,
  Button,
  Checkboxes,
  Progress,
  Dropdown,
  Date,
  Radio,
  MultiSelect,
  EwSwitch,
  EwSlider,
  EwStepper,
  EwToolTip,
  ConfirmationButton,
};

// export const Input;
// export const Button = Button;
// export const Checkboxes = Checkboxes;
// export const Progress = Progress;
// export const Dropdown = Dropdown;
// export const Date = Date;
// export const Radio = Radio;
