import { createStore, applyMiddleware } from "redux";
import rootReducer from "./Reducers/index";

const debug = document.getElementById("root").dataset.debug;

let store;
if (debug === "true") {
  store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  store = createStore(rootReducer);
}

export default store;
