import React, { useEffect, useState } from "react";
import { Divider, Typography } from "@mui/material";
import DimensionPeriod from "./DimensionPeriod";
import DimensionPeriodAccordian from "./DimensionPeriodAccordian";
import PTA from "./PTA.json";
import { complexQuery, getDateFromEpoch } from "../../../Utils/Common/Library";
import { constants } from "./Data/QueryConstants";

function Dimension({
  EmployeePerformancePlanID,
  DimensionID,
  DimensionName,
  DimensionPhase,
  handleTabEdit,
}) {
  const [loading, setLoading] = useState(false);
  const [dimensionPeriods, setDimensionPeriods] = useState(null);

  useEffect(() => {
    setLoading(true);
    complexQuery({
      RequiredFields: [{ EmployeePerformancePlanID }, { DimensionID }],
      QueryName: constants.QueryName,
    })
      .then((response) => {
        console.log(JSON.stringify(response), "THESE ARE THE PERIODS");
        setDimensionPeriods(response);
      })
      .finally(() => {
        setLoading(false);
      });
    // setDimensionPeriods([...PTA]);
  }, []);

  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return loading || !dimensionPeriods ? (
    <div className="Flex">
      <Typography variant="body1">
        Please wait while we prepare your parameters ...
      </Typography>
    </div>
  ) : (
    <div>
      {dimensionPeriods.map((dimensionPeriod) => {
        console.log(dimensionPeriod, "THIS IS DIMENSIONPERIOD");
        dimensionPeriod.DimensionName = DimensionName;
        dimensionPeriod.DimensionPhase = DimensionPhase; //Need to remove this after the workflow is set

        // const startDate = new Date(
        //   parseInt(dimensionPeriod.PeriodStartTimestamp)
        // );
        // const endDate = new Date(parseInt(dimensionPeriod.PeriodEndTimestamp));

        // const PeriodStartDate = `${startDate.getDate()}${nth(
        //   startDate.getDate()
        // )} ${startDate.toLocaleString("default", {
        //   month: "long",
        // })} ${startDate.toLocaleString("default", { year: "numeric" })}`;

        // const PeriodEndDate = `${endDate.getDate()}${nth(
        //   endDate.getDate()
        // )} ${endDate.toLocaleString("default", {
        //   month: "long",
        // })} ${endDate.toLocaleString("default", { year: "numeric" })}`;
        const PeriodStartDate = getDateFromEpoch({
          epoch: dimensionPeriod.PeriodStartTimestamp,
          returnNthString: true,
        });
        const PeriodEndDate = getDateFromEpoch({
          epoch: dimensionPeriod.PeriodEndTimestamp,
          returnNthString: true,
        });
        return (
          <DimensionPeriodAccordian
            PeriodTime={`${PeriodStartDate} - ${PeriodEndDate}`}
          >
            <DimensionPeriod
              DimensionPeriodData={dimensionPeriod}
              key={dimensionPeriod.TransactionID}
              handleTabEdit={handleTabEdit}
            />
          </DimensionPeriodAccordian>
        );
      })}
    </div>
  );
}

export default Dimension;
