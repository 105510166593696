import React from 'react'
import Button_1 from "../../AComponent/Buttons/Button_1";
import '../M.css'

function BtnArray({ Actions }) {
  console.log("From BTN arr ",Actions);
  if(!Actions){
    console.log("NO actions")
    return;
  }
  return (
    <>
      <div className="flex" style={{ justifyContent: 'space-around',width:'100%' }}>
        {Actions.map((ele, index) => {
        
       

          return (
            <Button_1
              Type={ele.Type}
              Color={ele.Color}
              Title={ele.Title}
              Handler={ele.Handler}
            />
          )
        })}
      </div>
    </>
  )
}

export default BtnArray;
