import axios from "axios";
import store from "../../Redux/Store";
import Gateway from "../Api/Gateway";
import payloads from "./Payloads.json";
import setLayout from "../setLayout";
import URLS from "../index";
const {
  TRANSACTION_WRITER_URL,
  FUNCTION_HANDLER_URL,
  LAYOUT_GENERATOR,
  TRANSACTION_WRITER_NO_TOKEN_URL,
} = URLS;
//const URL ="http://functionhandler-env.eba-nsef3vus.ap-south-1.elasticbeanstalk.com/functionHandler";

// const URL = "https://internal.employwise.app/functionHandler";
const URL = FUNCTION_HANDLER_URL;
//const payload_url =  "https://76wsmmdvl8.execute-api.ap-south-1.amazonaws.com/dev/transaction-writer/insert?datasource=f";

// const payload_url =
//   "https://internal.employwise.app/transaction-writer/insert?datasource=f";

const payload_url = TRANSACTION_WRITER_URL;
//const layoutUrl = "http://layoutgeneratorapplication-env.eba-iahmzz6t.ap-south-1.elasticbeanstalk.com/layout-generator";

//const layoutUrl = "https://internal.employwise.app/layout-generator";
const layoutUrl = LAYOUT_GENERATOR;

export const isEmpty = (value) => {
  let res = null;
  if (value) res = false;
  res = true;

  return res;
};
const header = {
  EffectiveFromTimestamp: "",
  FeatureID: "",
  FeatureVariantID: "000",
  UserId: "",
  TransactionID: "",
  StepId: "",
  ModuleId: "",
  Status: "1",
  RequestType: "0",
  EffectiveTillTimestamp: "",
  ActionType: "",
};
export const fetchData = (...args) => {
  var { query, payload, list } = args[0];
  if (!payload) payload = {};
  var newPayload = "{";
  Object.keys(payload).map((ele) => {
    newPayload += ele + ":" + payload[ele] + ",";
  });
  newPayload += "}";
  return new Promise((resolve, reject) => {
    const data = {
      function: "getData",
      expression: query,
      // payload:JSON.stringify(payload)
      payload: newPayload,
    };

    console.log("From getdata: axios ", data);
    Gateway({
      url: URL,
      payload: data,
    })
      .then((res) => {
        console.log("From getdata: ", res);
        if (res === "wrong tenantcode") reject({ error: "wrong tenantcode" });
        if (!list) resolve(res?.Data[0]);
        else resolve(res?.Data);
      })
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const isNotEmpty1 = (...args) => {
  const [value, error] = args;
  const otherDetail = args[3];
  let res = null;
  if (!value) res = false;
  else res = true;
  console.log("isnotempty: ", args, " ", res);
  updateValidation(res, error, otherDetail);
  return res;
};
export const enableGroups = (...args) => {
  const id = args.pop();
  console.log("argsis: ", args);
  store.dispatch({
    type: "ADD_GROUPS",
    payload: {
      id,
      groupsName: args,
    },
  });
};

export const fieldsEmpty = (...value) => {
  for (let i = 0; i < value.length; i++) {
    if (!value[i] || value[i] === "") return true;
  }
  return false;
};
export const returnValue = (...args) => {
  console.log("From return value: ", args);
  let value = args[0];
  let { id } = args[3];

  var data = store.getState()?.DataReducer[id];
  if (value.charAt(0) === "#") {
    let temp = value.substring(1, value.length);
    let result = "";
    try {
      // console.log('From return value: ', eval("data['0019']"))
      result = eval(temp);
    } catch (e) {
      result = "";
    }
    return result;
  }
  return "" + value;
};
export const isNotEmpty = (value) => {
  if (value) return true;
  return false;
};
export const dateDiff = (date1, date2) => {
  date1 = new Date(date1);
  date2 = new Date(date2);
  date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
  date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  console.log("From Date Diff: ", date1, " ", date2, " ", diffDays);
  return "" + diffDays;
};
export const isEqual = (...args) => {
  console.log("From IS equal ", args);
  var [a, b] = args;
  const id = args.pop();
  console.log("From IS equal 1", id);
  const data = store.getState()?.DataReducer[id];

  if (a.charAt(0) === "#") {
    a = a.substring(1, a.length).split(".");
    let temp = data[a[0]][a[1]];
    console.log("IsEqual called: ", temp === b, " ", b, " ", a);
    return temp === b;
  }
  return a === b;
};

export const InputLength = (value, min, max) => {
  return value.length >= min && value.length <= max;
};

export const Unique = (Tenant, para) => {
  const payload = {
    function: "unique",
    lookupField: "Data.TenantSubdomain",
    lookupValue: Tenant,
    scopeParameter: "",
    tenantID: "00",
    featureID: "00101",
    featureVariantID: "000",
    documentType: "3",
    status: "",
    datefrom: null,
    dateto: null,
  };

  console.log("Unique: ", para);
  return Gateway({
    url: URL,
    payload,
  });
  return new Promise((resolve, reject) => {
    axios
      .post(URL, payload)
      .then((result) => {
        console.log("From Unique: ", result.data);
        resolve(result.data);
      })
      .catch((e) => {
        console.log("From Unique: ", e);
        reject(e);
      });
  });
};

export const backendValidator = (...args) => {
  const value = args[0];
  const paramsObj = args[2];
  const { payloadId, payloadType, fields, valueField } = paramsObj;
  const url =
    "http://functionhandler-env.eba-nsef3vus.ap-south-1.elasticbeanstalk.com/functionHandler";

  var payload = payloads[payloadId].payloads[payloadType].payload;
  payload = { ...payload, ...fields };
  payload[valueField] = value;
  console.log("From BackendValidation: ", payload);

  return Gateway({
    url,
    payload,
  });
};

// export const createPayload = (id) => {
//   var DataToSubmit = {
//     TenantID: "00",
//     FeatureID: "00101",
//     FeatureVariantID: "000",
//     UserId: "",
//     TransactionId: "",
//     StepId: "",
//     ModuleId: "",
//     Status: "1",
//   };

//   console.log("From Store: ", store.getState(), " ", id);
//   var data = store.getState()?.DataReducer[id];
//   var submitPayload = data.submitPayload;
//   function getValue() {}
//   var payload = {};
//   Object.keys(submitPayload).map((ele) => {
//     payload[ele] = data[submitPayload[ele]].value;
//   });
//   payload.TenantCode = payload.TenantCode + "123";
//   DataToSubmit.Payload = payload;

//   return Gateway({
//     url: payload_url,
//     payload: DataToSubmit,
//   });
//   return new Promise((resolve, reject) => {
//     if (!data || !submitPayload) reject();

//     // payload.TenantCode = 'ggsl'

//     console.log("before sending: ", DataToSubmit);
//     axios
//       .post(payload_url, DataToSubmit)
//       .then((res) => {
//         console.log("From Backend: ", res);
//         resolve();
//       })
//       .catch((e) => {
//         console.log("From Backend: ", e);
//         reject();
//       });
//   });
// };

// export const testFunc = (...args) => {
//   console.log("From Test Func: ", args);
//   store.dispatch({
//     type: "SET_MESSAGE",
//     payload: {
//       id: 0,
//       message: "Submit Success!",
//     },
//   });
//   return true;
// };

export const isEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  var res = re.test(email);
  console.log("from EMail validation: ", email, res);
  return res;
};

export const isPhoneNumber = (phoneNumber) => {
  return String(phoneNumber)
    .toLowerCase()
    .match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
};

export const genrateRandomNum = (...args) => {
  var str = "";
  for (let i = 0; i < parseInt(args[1]); i++) {
    str += Math.floor(Math.random() * 11);
  }
  console.log("From Random: ", str, " ", args);
  return str;
};

export const inRange = (...args) => {
  console.log("Inrange: ", args);
  var [value, min, max] = args;
  value = parseInt(value);
  return value >= min && value <= max;
};

export const updateProps = (...args) => {
  const METHOD_NAME = "updateProps";
  console.log(METHOD_NAME, " ", args);
  const [id, propsToUpdate] = args;
  propsToUpdate.map((ele) => {
    store.dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: ele.componentNumber,
        data: ele.data,
        props: ele.prop,
      },
    });
  });
};

export const saveLineItem = (...args) => {
  const [obj] = args;
  const id = args.pop();
  console.log("From lineitem : ", obj);
  const Data = store.getState().DataReducer[id];
  const groupArray = Data.groupRef[obj.Group];
  const except = obj.Except;
  const lineItem = obj.compNumber;
  const inEditMode = Data[lineItem]?.editMode;

  if (!groupArray) return;
  var payload = {};
  groupArray.map((ele) => {
    if (!except.includes(ele)) {
      payload[ele] = Data[ele].value;
    }
  });

  if (inEditMode) {
    let arr = Data[lineItem].value;
    arr[inEditMode.index] = payload;
    store.dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: lineItem,
        data: [...arr],
        props: "value",
      },
    });
    return;
  }
  var arr = [...Data[lineItem].value, payload];
  store.dispatch({
    type: "UPDATE_PROPS",
    payload: {
      id,
      componentNumber: lineItem,
      data: arr,
      props: "value",
    },
  });
  if (Data[lineItem].mapping) {
    let mapping = Data[lineItem].mapping;
    arr = arr.map((ele) => {
      let obj = {};
      Object.keys(ele).map((key) => {
        obj[mapping[key]] = ele[key];
      });
      return obj;
    });
  }
  store.dispatch({
    type: "UPDATE_PROPS",
    payload: {
      id,
      componentNumber: lineItem,
      data: arr,
      props: "payload",
    },
  });
  console.log("After conversion: ", arr);
};

export const remveGroup = (...args) => {
  const [groupName] = args;
  console.log("removing: ", args);
  const id = args.pop();
  store.dispatch({
    type: "REMOVE_GROUP",
    payload: {
      id,
      groupName,
    },
  });
};
export const clearValues = (...args) => {
  const [obj] = args;
  const id = args.pop();
  const Data = store.getState().DataReducer[id];
  const groupName = obj.Group;
  const groupArray = Data.groupRef[groupName];
  console.log("from Clearvalues ", groupArray);
  groupArray.map((ele) => {
    store.dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: ele,
        data: "",
        props: "value",
      },
    });
  });
};

export const fetchLayoutInModal = (...args) => {
  const [id, obj] = args;
  axios.get("http://localhost:5000" + obj.layout).then((res) => {
    let layout = res.data;
    layout.pageType = "Modal";
    console.log("Layout from 5000 ", layout);
    setLayout(layout);
    // store.dispatch({
    //   type:'SET',
    //   payload:layout
    // })
  });
};

export const lookup = (...args) => {
  const [value, lookupField, featureID, error] = args;
  console.log("lookup; ", args);
  const otherDetails = args[5];
  const payload = {
    function: "lookup",
    returnType: "boolean",
    lookupField: lookupField,
    lookupValue: value,
    scopeParameter: "1",
    featureID: featureID,
    featureVariantID: "000",
    documentType: "3",
    status: "1",
    datefrom: "Null",
    dateto: "Null",
  };

  console.log("From Lookup: ", payload);

  Gateway({
    url: URL,
    payload,
    displayError: 1,
  })
    .then((res) => {
      console.log("From inside lookup: ", res);

      updateValidation(!res, error, otherDetails);
      return true;
    })
    .catch((e) => {
      return false;
    });
};

function setLoader(id, loading) {
  store.dispatch({
    type: "SET_SHELL_LOADING",
    payload: {
      id,
      loading,
    },
  });
}
export const deactivateDocument = (args) => {
  var { TransactionID, id } = args;
  return new Promise((resolve, reject) => {
    let header = store.getState().DataReducer[id]?.submitPayload?.Header;
    if (!header) reject();
    header.TransactionID = TransactionID;
    header.EffectiveTillTimestamp = "-1";
    header.RequestType = "2";
    Gateway({
      url: TRANSACTION_WRITER_NO_TOKEN_URL,
      payload: {
        ...header,
      },
      displayMsg: false,
    })
      .then((res) => {
        //  setLoader(id, false)
        resolve();
      })
      .catch((e) => {
        //  setLoader(id, false)
        reject();
      });
  });
};
export const submitPayload = (...args) => {
  var [id] = args;
  let submitPayload = {};
  console.log("Calling data: ");
  let data = store.getState().DataReducer[id];
  console.log("Calling data:2 ", data);
  let obj = data.submitPayload;
  submitPayload = {
    ...data.submitPayload.Header,
    FeatureID: obj.Header.FeatureID,
  };
  console.log("From submit: 1", JSON.stringify(submitPayload), " ");
  if (
    obj.editMode &&
    obj.editMode?.header?.TransactionID &&
    obj.editMode?.header?.TransactionID !== ""
  ) {
    console.log("Edit mode: 111", obj.editMode);
    submitPayload = { ...submitPayload, ...obj.editMode?.header };
  }
  let schema = obj.payloadSchema;
  let payload = {};
  Object.keys(schema).map((ele) => {
    //console.log('From eval: ', schema[ele], ' ', data[schema[ele]]?.value)
    if (schema[ele].charAt(0) === "#") {
      let temp = schema[ele].substring(1, schema[ele].length);
      let result = "";
      //  console.log("Inside if: ",eval("data['LegalEntityCountry'].menuItems[data['LegalEntityCountry'].selectedIndex].Alpha2code"))
      try {
        //   console.log('From submit:0 ', eval(temp))
        result = eval(temp);
      } catch (e) {
        console.log("Eval Failed: ", e);
      } finally {
        payload[ele] = result;
      }
      // console.log("From eval: ",temp," ",data)
      // console.log("From eval: ",eval(temp))
    } else {
      if (schema[ele].split(".").length === 1 && data[schema[ele]]?.value)
        payload[ele] = "" + data[schema[ele]].value;
      else {
        payload[ele] = "";
        console.log("Failed: ", payload[ele], " ", ele, " ", data);
      }
      if (schema[ele].split(".").length === 2) {
        let arr = schema[ele].split(".");

        payload[ele] = data[arr[0]][arr[1]];
        console.log("Inside split: ", payload);
      }
    }
  });
  submitPayload.Payload = payload;

  console.log("From submit: ", JSON.stringify(submitPayload), " ", schema);

  setLoader(id, true);
  let isDesignation = submitPayload.FeatureID === "12142";
  

  Gateway({
    url: isDesignation ? TRANSACTION_WRITER_NO_TOKEN_URL : payload_url,
    payload: submitPayload,
    displayMsg: true,
    displayError: 0,
  })
    .then((res) => {
      setLoader(id, false);
    })
    .catch((e) => {
      setLoader(id, false);
    });
};

function updateValidation(flag, msg, otherdetails) {
  const { id, compNumber } = otherdetails;
  const { validation } = store.getState().DataReducer[id][compNumber];

  let set = validation?.errors;
  if (!set) {
    set = [];
  }
  console.log("Called updatevalidatio ", set);
  if (!flag && !set.includes(msg)) set.push(msg);
  if (flag && set.includes(msg)) {
    let index = set.indexOf(msg);
    set.splice(index, 1);
  }
  console.log("Before updation: ", set, " ", flag, " ", msg);
  store.dispatch({
    type: "UPDATE_PROPS",
    payload: {
      id,
      componentNumber: compNumber,
      data: {
        notValid: set.length !== 0,
        errors: set,
      },
      props: "validation",
    },
  });
}

export const alphaNumeric = (...args) => {
  console.log("Alpha nUmeric: ", args);
  const [value, error] = args;
  const otherDetail = args[3];
  let result = /^[a-zA-Z()]+$/.test(value);
  updateValidation(!result, error, otherDetail);
  return result;
};

export const validateFields = (...args) => {
  const [validation, state] = args;

  for (let i = 0; i < validation.validFields?.length; i++) {
    let field = validation.validFields[i];
    if (state[field].validation?.notValid) return false;
  }

  for (let i = 0; i < validation.required?.length; i++) {
    let field = validation.required[i];
    if (!state[field].value || !state[field].value?.trim(" ") === "")
      return false;
  }
  console.log("From validateFields; true");
  return true;
};

function updateSingleProp(data, props, otherDetails) {
  console.log("From updateSIngle Props: ", data, " ", props, " ", otherDetails);
  store.dispatch({
    type: "UPDATE_PROPS",
    payload: {
      id: otherDetails.id,
      componentNumber: otherDetails.compNumber,
      data,
      props,
    },
  });
}

export const fetchList = (...args) => {
  console.log("fetch list: ", args);
  let { payload } = args[2];
  let otherDetails = args[3];
  Gateway({
    url: URL,
    payload,
    displayError: 1,
  })
    .then((res) => {
      updateSingleProp(res, "menuItems", otherDetails);
    })
    .catch((e) => {
      updateSingleProp([], "menuItems", otherDetails);
    });

  return;
};
function getValue(otherDetails, prop) {
  let value = "value";
  if (prop) value = prop;
  console.log("From getValue ", otherDetails, " ", value);
  return store.getState().DataReducer[otherDetails.id][otherDetails.compNumber][
    value
  ];
}

export const isValueSelectedFromList = (...args) => {
  //const value=args[0];
  const otherDetails = args[3];
  otherDetails.compNumber = args[0];
  let value = getValue(otherDetails);
  console.log("From value selected: ", value, " ", args);
  if (value === -1) return false;
  return true;
};

export const updateList = (...args) => {
  console.log("updateList: ", args);
  //return;
  const [obj] = args;
  const id = args.pop();
  const { componentToUpdate, componentFromValue, payload } = obj;
  //dont comment "data" as it is used inside eval()
  let data = store.getState().DataReducer[id][componentFromValue];
  try {
    payload.CountryCode = eval(payload.CountryCode);
  } catch (e) {
    return;
  }
  console.log("updateLIst: eval ", payload);

  Gateway({
    url: URL,
    payload,
  })
    .then((res) => {
      console.log("updateLIst: result ", res);
      updateSingleProp(res, "menuItems", { id, compNumber: componentToUpdate });
    })
    .catch((e) => {
      console.log("updateLIst: error ", e);
      updateSingleProp([], "menuItems", { id, compNumber: componentToUpdate });
    });
  //   return;
  // let value=getValue({id,compNumber:obj.value});

  // if(value===-1){
  //   updateSingleProp(true,"renderNot",{id,compNumber:obj.updateList});
  //   return;
  // }
  // let argsToSend=[];
  // argsToSend[2]={endPt:obj.endPt,selectedvalue:value};
  // argsToSend[3]={id,compNumber:obj.updateList}

  // fetchList(...argsToSend);
  // updateSingleProp(false,"renderNot",{id,compNumber:obj.updateList});
  // updateSingleProp(-1,"value",{id,compNumber:obj.updateList})
};

export const getLayout = (...args) => {
  const [obj, returnValue] = args;

  let payload = {
    FeatureID: obj.FeatureID,
  };
  return new Promise((resolve) => {
    Gateway({
      url: layoutUrl,
      payload,
    }).then((res) => {
      console.log("Test 1111: ",res)
      if (returnValue) {
        resolve(res.Data);
      } else {
        store.dispatch({
          type: "SET",
          payload: res.Data,
        });
      }
    });
  });
};

export const testServlet = (...args) => {
  Gateway({
    payload: {},
    url: "https://localhost:9443/ermWeb/servlet/ggs.erm.servlet.setup1.pastAttendenceN?srfPreventionSalt=FJkbjjzJepSHML7EWShk&mode=pastinsert&startdate=01/06/2022&indatetime=2022-06-01 05:03:00&todate=01/06/2022&exclude_weekly_off_holiday=N&exclude_leave_days=N&applied_by=1",
  }).then((res) => {
    console.log(res);
  });
};
