import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import {
  Add,
  ExpandLessRounded,
  ExpandMoreRounded,
  QuestionAnswer,
  RemoveSharp,
} from "@mui/icons-material";
import { LockIcon } from "../../../Utils/Common/Icons";

const PhaseAccordian = ({
  collapse,
  expandable,
  locked,
  completed,
  TransactionID,
  title,
  caption,

  children,

  clickable,
  onClickHandler,

  commentable,
  commentsModalHandler,
}) => {
  const [open, setOpen] = useState(!collapse);
  const theme = useTheme();
  console.log("Compled workflow: ", completed);
  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "0px 0px 6px #B1AEBB",
        borderRadius: `6px`,
        marginBottom: "0.5em",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.25em",
          cursor: clickable && "pointer",
          backgroundColor: theme.palette.primary["50"],
        }}
        onClick={
          clickable
            ? () => {
                onClickHandler(TransactionID);
              }
            : () => {}
        }
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "0.5em",
            gap: "1em",
          }}
        >
          <IconButton
            onClick={() => {
              setOpen(!open);
            }}
            disabled={locked ? true : false}
          >
            {expandable ? (
              !open ? (
                <ExpandMoreRounded fontSize="large" sx={{ color: "black" }} />
              ) : (
                <ExpandLessRounded fontSize="large" sx={{ color: "black" }} />
              )
            ) : locked ? (
              <LockIcon />
            ) : !open ? (
              <Add sx={{ color: "black" }} />
            ) : (
              <RemoveSharp sx={{ color: "black" }} />
            )}
          </IconButton>
          <Typography
            variant={"h4"}
            sx={{
              color: locked
                ? "rgba(158, 158, 158, 1)"
                : completed
                ? "#04991C"
                : "",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: "rgba(158, 158, 158, 1)" }}
          >
            {caption}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {commentable && (
            <IconButton onClick={commentsModalHandler}>
              <QuestionAnswer sx={{ color: "#6c6d6f" }} />
            </IconButton>
          )}
        </Box>
      </Box>
      {open && (
        <Box
          sx={{
            backgroundColor: "white",
            padding: "1em",
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default PhaseAccordian;
