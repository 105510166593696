import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { getData } from "../../Utils/Common/Library";
import useProps from "../../Utils/CustomHooks/GetProps";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const initialProps = {
  value: null,
  menuItems: null,
  label: null,
  onChange: null,
  featureId: null,
  mapper: null,
  apiResult: null,
  filter: null,
  getAllStatus: null,
  storeApiResult: null,
  size: null,
  variant: null,
  includeID: false,
};
export default function MultiSelect(props = initialProps) {
  var {
    value,
    menuItems,
    label,
    disable,
    onBlur,
    onChange,
    featureId,
    mapper,
    getAllStatus,
    size,
    variant,
    apiResult,
    storeApiResult,
    filter,
    includeID,
  } = useProps(props);
  if (!featureId && menuItems && !menuItems[0].label)
    menuItems = menuItems.map((ele) => ({ label: ele, value: ele }));
  var arr = [];
  console.log("After menu: 1", menuItems, value, " ");
  function init(menuItems) {
    if (value && value[0]?.ItemNumber) {
      arr = value;
      let temp = {};
      let newValue = value.map((ele) => {
        temp[ele.ID] = ele.ItemNumber;
        return ele.ID;
      });
      for (let i = 0; i < menuItems.length; i++) {
        const { value: id } = menuItems[i];
        if (temp[id]) {
          menuItems[i].ItemNumber = temp[id];
        }
      }
      console.log("After menu: ", menuItems, value, " ", temp);
      return [menuItems, newValue];
    }
    return [menuItems, value];
  }
  if (menuItems) {
    [menuItems] = init(menuItems);
  }
  const [selectedValues, setSelectedValues] = React.useState(value || []);
  const [labelToValue, setLabelToValue] = React.useState({});
  const [valueWithItemNumber, setValueWith] = React.useState(arr);
  const [list, setList] = React.useState(
    menuItems ? menuItems : [{ label: "Loading", value: "-1" }]
  );
  function filterData(item) {
    console.log("From Filter: ", item);
    if (filter) return filter(item);
    if (getAllStatus) return true;
    if (item.Status === "1") return true;

    return false;
  }
  React.useEffect(() => {
    if (featureId && !apiResult) {
      getData({ featureId })
        .then((res) => {
          if (res.Data) {
            let { label, value } = mapper;
            let arr = res.Data.filter(filterData).map((ele) => ({
              label: ele[label],
              value: ele[value],
            }));
            arr = init(arr)[0];
            console.log("Arr: ", arr);
            setList([...arr]);
            if (storeApiResult) storeApiResult(arr);
          }
        })
        .catch((e) => {
          console.log("Multiselect werror: ", e);
          setList([
            {
              label: "Network Error",
              value: -1,
            },
          ]);
        });
    } else if (apiResult) {
      setList([...apiResult]);
    }
    if (
      JSON.stringify(list) !== JSON.stringify(menuItems) &&
      menuItems?.length > 0
    ) {
      setList(menuItems);
    }
  }, [menuItems]);
  React.useEffect(() => {
    if (list.length > 0) {
      let obj = {};
      list.map(({ label, value }) => {
        obj[value] = label;
      });
      setLabelToValue({ ...obj });
    }
  }, [list]);
  if (!onBlur) onBlur = () => {};
  const handleChange = (event, child) => {
    console.log("handle change multiselct:  ", child);
    const {
      target: { value },
    } = event;
    const {
      props: { index, value: selectValue },
    } = child;
    let arr = selectedValues;
    let checked = value.includes(selectValue);
    if (index || index === 0) {
      if (arr[index]) arr[index].ID = checked ? selectValue : "";
    } else {
      if (checked) {
        console.log("Test 111: ", includeID, " ", arr);
        if (includeID) {
          arr.push({ ID: selectValue });
        } else {
          arr.push(selectValue);
        }
      } else {
        arr = arr.filter((item) =>
          includeID ? value.includes(item.ID) : value.includes(item)
        );
      }
    }

    console.log(
      "Val: multi ",
      arr,
      " ",
      index,
      " ",
      includeID,
      " ",
      selectedValues
    );
    if (onChange) onChange(arr);
    setSelectedValues([...arr]);
  };
  function returnValues() {
    let value = selectedValues;
    if (value)
      return value
        .map((ele) => {
          if (ele?.ID === "") return null;
          if (ele.ID && ele.ID !== "") return ele.ID;
          else return ele;
        })
        .filter((item) => item);

    return [];
  }
  if (!list || (list?.length === 0 && !featureId)) return <>List Error</>;

  let newValues = returnValues();
  console.log("returnde value: ", returnValues());
  return (
    <div>
      <FormControl sx={{ width: 300 }} size={size || ""}>
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
        <Select
          disabled={disable}
          labelId="checkbox-label"
          id="multi-checkbox"
          multiple
          name="test"
          value={newValues}
          onChange={handleChange}
          onBlur={() => {
            onBlur(selectedValues);
          }}
          variant={variant}
          // input={<OutlinedInput label={label} />}
          //  renderValue={(selected) => selected.join(", ")}
          renderValue={(vals) =>
            vals.map((ele) => labelToValue[ele]).join(", ")
          }
          MenuProps={MenuProps}
        >
          {/* {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedValues.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))} */}

          {list.map((ele, index) => {
            console.log("Multi list: ", list);
            var { label, value, Component, ItemNumber } = ele;

            if (Component) return <Component />;
            return (
              <MenuItem index={ItemNumber} key={value} value={value}>
                <Checkbox checked={newValues.indexOf(value) > -1} />
                <ListItemText primary={label} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
