import React, { useState, useEffect } from 'react'
import Alert from '@mui/material/Alert'
import { useSelector, useDispatch } from 'react-redux'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button';

const map = {
  0: 'success',
  1: 'error',
  2: 'info',
  3: 'warning',
}

function Message(props) {
  const { id, message } = useSelector((state) =>
    JSON.parse(JSON.stringify(state.MessageReducer)),
  )
  const dispatch = useDispatch()
  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log('Unsetting')
  //     if (id || id === 0)
  //       dispatch({
  //         type: 'UNSET_MESSAGE',
  //       })
  //   }, 5000)
  // }, [id])
  if (!id && id !== 0) return <></>
  //  const [show, setShow] = useState(true)
function closeModal(){
  dispatch({
    type: 'UNSET_MESSAGE',
  })
}
  return (
    <Modal  open={message ? true : false} onClose={closeModal}>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:"100vh"}}>
      <Paper  style={{padding:40,}}  className="Modal-paper Flex" elevation={3}>
        <div style={{ width: '100%',display:'flex',justifyContent:'center',flexDirection:'column' }}>
          <Alert severity={map[id]}>{message}</Alert>
          <br />
          <Button onClick={closeModal} variant="contained">Cancel</Button>

        </div>
      </Paper>
      </div>
    </Modal>
  )
}

export default Message
