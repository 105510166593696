import React from 'react'

function JsonInterpreter({ Components }) {
  return (
    <>
      {Components.map((ele, index) => {
        let compArr = ele.Component.split('.')
        // let compAddress="NComponent/NeedToAct";
        let compAddress
        if (compArr.length === 2)
          compAddress = compArr[0] + 'Component/' + compArr[1]
        else
          compAddress =
            compArr[0] + 'Component/' + compArr[1] + '/' + compArr[2]
        let Comp
        console.log('From Interpretor ', compAddress)
        return (
          <>
            {(Comp = require('../' + compAddress).default)}
            <Comp key={index} {...ele.props} />
           
          </>
        )
      })}
    </>
  )
}

export default JsonInterpreter
