import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/material/styles'
//import InputLabel from '@mui/material/InputLabel'
import InputLabel from '@mui/material/InputLabel'
//import MenuItem from '@mui/material/MenuItem'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
//import FormControl from '@mui/material/FormControl'
//import Select from '@mui/material/Select'
import Select from '@mui/material/Select'
import { useDispatch, useSelector } from 'react-redux'
import useRender from '../utils/useRender'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import Evaluate from '../../Utils/Validators/ExpressionHandler'
import methodHandler from '../utils/EventHandler'

/*
This Material UI button has 2 properties

label:      ||String|| Required; label for Dropdown
menuItem:   ||Array || Required; Array of MenuObject


MenuObject: ||Object|| {value,label}; label for dropdown options
*/

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

function Dropdown_2(props) {
  const { id, compNumber } = props

  var {
    label,
    menuItems,
    value,
    onChange,
    renderNotIfEmpty,
    mapping,
  } = useSelector((state) => state.DataReducer[id][compNumber])
  const dispatch = useDispatch()
  const [flag,setFlag]=useState(true)
  //const [value, setValue] = useState('Select')
  
  const classes = useStyles()

  function handleChange(e) {
    let val = e.target.value
//selectedIndex
    dispatch({
      type: 'UPDATE_PROPS',
      payload: {
        id,
        componentNumber: compNumber,
        data: val,
        props: 'value',
      },
    })
    if (onChange) {
      methodHandler(onChange, id)
    }
  }

  useEffect(() => {
    if (typeof menuItems === 'string') {
      Evaluate(menuItems, null, { id, compNumber })
    }else if(menuItems?.length>0 && !menuItems[0].label){
      var obj=menuItems.map((ele)=>({label:ele}))
      dispatch({
        type: 'UPDATE_PROPS',
        payload: {
          id,
          componentNumber: compNumber,
          data: obj,
          props: 'menuItems',
        },
      })
    }
    if (!value) {
      dispatch({
        type: 'UPDATE_PROPS',
        payload: {
          id,
          componentNumber: compNumber,
          data: -1,
          props: 'value',
        },
      })
    }
  
    // if(value && renderNotIfEmpty){
    //   console.log("Calling update:")
    //   dispatch({
    //     type: 'UPDATE_PROPS',
    //     payload: {
    //       id,
    //       componentNumber: compNumber,
    //       data: false,
    //       props: 'renderNotIfEmpty',
    //     },
    //   })
    // }
  }, [])

  if (!useRender({ id, compNumber })) return <></>
  if (typeof menuItems === 'string') return <>Fetching List...</>
  console.log("Onchnage2: ",value," ",onChange," ",menuItems)
  if( value!==-1 && onChange){
    console.log("Onchnage1: ")
    methodHandler(onChange, id)
  }
  if ((menuItems?.length === 0 && renderNotIfEmpty) || !menuItems) return <></>
  function getValue(ele) {
    if (ele.value) return ele.value
    if (ele.label) return ele.label
    if(!mapping)return "";
    if (ele[mapping.label]) return ele[mapping.label]
  }

  function getLabel(ele) {
    if (ele.label) return ele.label
    if(!mapping)return "";
    if (ele[mapping.label]) return ele[mapping.label]
  }

  function setSelectedIndex(index){
    dispatch({
      type: 'UPDATE_PROPS',
      payload: {
        id,
        componentNumber: compNumber,
        data: index,
        props: 'selectedIndex',
      },
    })
  }
  return (
    <>
    <div style={{margin:10}}>
      <Box sx={{ minWidth: 160 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{label}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value ? value : -1}
            onChange={handleChange}
          >
            <MenuItem value={-1}>{'Select a value'}</MenuItem>
            {console.log("Menuitems1: ",menuItems," ",value)}
            {menuItems?.map((ele, index) => {
              if(value===getValue(ele) && flag){
                setFlag(false);
                setSelectedIndex(index)
                if(onChange){
                  methodHandler(onChange, id)
                }
              }
              return (
                <MenuItem onClick={()=>setSelectedIndex(index)} value={getValue(ele)} key={index}>
                  {getLabel(ele)}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <br />
      </Box>
      </div>
    </>
  )
}

export default Dropdown_2
