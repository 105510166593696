import { Box, Divider, TableCell, Typography } from "@mui/material";
import React, { useState } from "react";
import Atomic from "../../AComponent/Atomic";
import Molecule from "../../MComponent/Molecule";
import AgendaTable from "./AgendaTable";
import { DevelopmentDocument } from "./Dimension/Data/SelfDevelopmentData";

const { Table } = Molecule;
const { Dropdown, MultiSelect } = Atomic;

const SelfDevelopmentPayloadDocument = {
  AppraisalPlanID: "",
  EmployeePlanID: "",
  AppraiseeUserID: "",
  PlanItems: [],
};

const SelfDevelopment = () => {
  const [state, setState] = useState(SelfDevelopmentPayloadDocument);

  function GeneratePayloadProps(items) {
    const newPlanItems = [...items];
    console.log(newPlanItems, "DEVELOPER");
    return newPlanItems.map((item, index) => {
      const newItem = { ...item };
      console.log(newItem, "DEVELOPER");
      for (let [key, value] of Object.entries(newItem)) {
        console.log(key, !key.includes("Column"), "DEVELOPER");
        if (
          !key.includes("Column") &&
          !key.includes("ID") &&
          !key.includes("PlanEvent")
        ) {
          delete newItem[key];
        }
      }
      newItem.PlanEvent = "";
      return newItem;
    });
  }
  console.log(
    JSON.stringify({
      ...state,
      PlanItems: GeneratePayloadProps(state.PlanItems),
    }),
    "DEVELOPER"
  );

  return (
    <Box>
      <Typography variant="h4" sx={{ margin: "0.5em 0" }}>
        {DevelopmentDocument.SectionLabel}
      </Typography>
      <Divider />
      <Typography variant="body1" sx={{ margin: "0.5em 0" }}>
        {DevelopmentDocument.SectionDescription}
      </Typography>

      <Box>
        <AgendaTable
          SectionID={"Development"}
          DataColumns={DevelopmentDocument.DataColumns}
          onSaveRow={(rows) => {
            setState((prevState) => ({
              ...prevState,
              PlanItems: [...rows],
            }));
          }}
        />
      </Box>
    </Box>
  );
};

export default SelfDevelopment;
