import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { SettingsOutlined } from "@mui/icons-material";
import Atomic from "../AComponent/Atomic";
import Molecule from "../MComponent/Molecule";
import DashboardGenerator from "./DashboardGenerator";
import PlanSummaryReportDashboard from "./PlanSummaryReportDashboard";
import { generateReport, getUserId } from "../Utils/Common/Library";
import { constants } from "../NComponent/pms/Dimension/Data/QueryConstants";
import { useLocation } from "react-router-dom";

const { Button } = Atomic;
const { EwModal } = Molecule;

function Dashboard({ onClose, isAppraiser }) {
  const location = useLocation();
  const [dashboard, setDashboard] = useState({ component: null, props: {} });
  const [model, setModel] = useState(null);
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  console.log("APPRAISERID", location?.state?.AppraiserID);

  // function performPlanProgressTrackerQuery(data, plansList, details, info) {
  //   const document = plansList.find(
  //     (item) => item.value === details.PlanPeriod
  //   );
  //   const { Plans, Dimensions, Assessments } = data;

  //   const DimensionIDValue = Dimensions?.find(
  //     (item) => item.value === details.PlanPeriodDimension
  //   )?.label;
  //   const MasterPerformancePlanIDValue = Plans?.find(
  //     (item) => item.value === details.Plan
  //   )?.label;
  //   const ReviewPeriodUnit = Assessments?.find(
  //     (item) => item.value === details.PlanPeriodDimensionAssessment
  //   ).label;

  //   if (!DimensionIDValue || !MasterPerformancePlanIDValue) return;
  //   if (!document) return;
  //   const { ReviewFromDate, ReviewTillDate } = document;

  //   generateReport({
  //     RequiredFields: [
  //       { TenantCode },
  //       { DimensionID: details.PlanPeriodDimension, DimensionIDValue },
  //       { MasterPerformancePlanID: details.Plan, MasterPerformancePlanIDValue },
  //       {
  //         ReviewPeriodUnit,
  //         ReviewPeriodUnitValue: periodMapping[ReviewPeriodUnit]
  //           ? periodMapping[ReviewPeriodUnit]
  //           : "Month",
  //       },
  //       {
  //         ReviewFromDate,
  //         ReviewFromDateValue: getDateFromEpoch({
  //           epoch: ReviewFromDate,
  //           format: 1,
  //         }),
  //       },
  //       {
  //         ReviewTillDate,
  //         ReviewTillDateValue: getDateFromEpoch({
  //           epoch: ReviewTillDate,
  //           format: 1,
  //         }),
  //       },
  //       { Function: [] },
  //       { Location: [] },
  //       { Grade: [] },
  //       { Role: [] },
  //       { Company: [] },
  //       { EmployeeType: [] },
  //       { BusinessUnit: [] },
  //     ],
  //     QueryName: constants.PlanProgressTracker,
  //   });
  // }

  function performPlanSummaryReportQuery(data, plansList, details, info) {
    setLoading(true);
    const MasterPerformancePlanIDValue = data?.Plans?.find(
      (item) => item.value === details.Plan
    )?.label;
    const PlanPeriod = plansList?.find(
      (item) => item.value === details.PlanPeriod
    )?.period;

    if (!MasterPerformancePlanIDValue || !PlanPeriod) return;

    generateReport({
      requiredFields: [
        {
          MasterPerformancePlanID: details.Plan,
          MasterPerformancePlanIDValue,
        },
        { PlanPeriod, PlanPeriodValue: PlanPeriod },
        { Function: [] },
        { Location: [] },
        { Grade: [] },
        { Role: [] },
        { Company: [] },
        { EmployeeType: [] },
        { BusinessUnit: [] },
      ],
      queryName: isAppraiser
        ? constants.AppraiseesPlanSummaryReport
        : constants.PlanSummaryReport,
      toJSON: true,
      AppraiserUserID: isAppraiser ? getUserId() : null,
    })
      .then((response) => {
        console.log(response, "QUERYRESULTS");
        setDetails(info);
        setDashboard({
          component: PlanSummaryReportDashboard,
          props: { queryResults: response.returnData || [] },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getGeneratedDashboardData(data, plansList, details, info) {
    setModel(null);
    if (details.DashboardType === "PPT") {
    }
    if (details.DashboardType === "PSR") {
      performPlanSummaryReportQuery(data, plansList, details, info);
    }
  }

  const DashboardComponent = dashboard.component;

  useEffect(() => {
    const initZoom = document.documentElement.style.zoom;
    document.documentElement.style.zoom = 1;

    return () => {
      document.documentElement.style.zoom = initZoom;
    };
  }, []);

  return (
    <EwModal onClose={onClose} open={true}>
      <Box
        padding={"1em 0 0 1em"}
        height={"90vh"}
        width={"90vw"}
        className="scroll"
      >
        <Box sx={{ display: "flex", gap: "2em" }}>
          <Typography variant="h2" color={"primary"}>
            Dashboard
          </Typography>
          <Button
            label={"Generate Dashboard"}
            onClick={() => {
              setModel(true);
            }}
            style={{ textTransform: "none" }}
            otherProps={{ endIcon: <SettingsOutlined /> }}
            variant={"text"}
          />
        </Box>
        {details && (
          <>
            <Box padding={"1em 0"}>
              <Typography variant="p2" sx={{ display: "flex", gap: "1em" }}>
                <b>{details.DashboardType} Dashboard</b>
                <>
                  ( {details.Plan}
                  {" > "}
                  {details.PlanPeriod}
                  {details.PlanPeriodDimension !== -1 && (
                    <>
                      {" > "}
                      {details.PlanPeriodDimension}
                    </>
                  )}
                  {details.PlanPeriodDimensionAssessment !== -1 && (
                    <>
                      {" > "}
                      {details.PlanPeriodDimensionAssessment}
                    </>
                  )}{" "}
                  )
                </>
              </Typography>
            </Box>
          </>
        )}
        <br />
        <Box height={"100%"}>
          {loading ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
              <CircularProgress size={25} color="info" />
              <Typography variant="form_text1">
                Generating requsted dashboard. This process may take some time
              </Typography>
            </Box>
          ) : DashboardComponent ? (
            <DashboardComponent {...dashboard.props} />
          ) : (
            <Box>
              <Typography variant="form_text1"></Typography>
            </Box>
          )}
        </Box>
        {model && (
          <EwModal
            open={model}
            onClose={() => {
              setModel(null);
            }}
          >
            <DashboardGenerator
              getGeneratedDashboardData={getGeneratedDashboardData}
            />
          </EwModal>
        )}
      </Box>
    </EwModal>
  );
}

export default Dashboard;
