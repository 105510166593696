import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Atomic from "../../../../AComponent/Atomic";

const { Input, Button, Dropdown } = Atomic;

const GroupEditForm = ({
  initName = "",
  initShortName = "",
  initDescription = "",
}) => {
  const [name, setName] = useState(initName);
  const [shortName, setShortName] = useState(initShortName);
  const [description, setDescription] = useState(initDescription);

  const nameChangeHandler = (value) => setName(value);
  const shortNameChangeHandler = (value) => setShortName(value);
  const descriptionChangeHandler = (value) => setDescription(value);

  const formSubmitHandler = () => {
    const updatedData = {
      name,
      shortName,
      description,
    };

    console.log(updatedData);
  };

  return (
    <Box
      sx={{
        width: "40vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Typography variant="h6" sx={{ marginBottom: "1em" }}>
          Edit Group
        </Typography>
        <Input
          type={"text"}
          label={"Name"}
          onChange={nameChangeHandler}
          value={name}
          styles={{ width: "100%", margin: "1em 0" }}
        />
        <Input
          type={"text"}
          label={"Short Name"}
          onChange={shortNameChangeHandler}
          value={shortName}
          styles={{ width: "100%", margin: "1em 0" }}
        />
        <Input
          type={"text"}
          label={"Description"}
          onChange={descriptionChangeHandler}
          value={description}
          styles={{ width: "100%", margin: "1em 0" }}
          rows={5}
        />
        <Button
          onClick={formSubmitHandler}
          title={"Submit"}
          variant={"contained"}
          style={{ marginTop: "2em", float: "right" }}
        />
      </Box>
    </Box>
  );
};

const ParameterEditForm = ({
  initType = "",
  initTarget = "",
  initWeight = "",
}) => {
  const [type, setType] = useState(initType);
  const [target, setTarget] = useState(initTarget);
  const [weight, setWeight] = useState(initWeight);

  const typeChangeHandler = (value) => setType(value);
  const targetChangeHandler = (value) => setTarget(value);
  const weightChangeHandler = (value) => setWeight(value);

  console.log(type);

  const formSubmitHandler = () => {
    const updatedData = {
      type,
      target,
      weight,
    };

    console.log(updatedData);
  };

  let targetType = [
    {
      label: "Small Alpha Numeric",
      value: "ST",
    },
    {
      label: "Large Alpha Numeric",
      value: "LT",
    },
    {
      label: "Integer",
      value: "NI",
    },
    {
      label: "Decimal",
      value: "ND",
    },
    {
      label: "Percentage",
      value: "PC",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "40vh",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Typography variant="h6" sx={{ marginTop: "1em" }}>
          Edit Parameter
        </Typography>
        <Dropdown
          label="Target Type"
          menuItems={targetType}
          value={type}
          onChange={typeChangeHandler}
        />
        {type && type !== -1 && (
          <Input
            type={"text"}
            label={"Target"}
            onChange={targetChangeHandler}
            value={target}
            styles={{ width: "100%", margin: "1em 0" }}
          />
        )}
        <Input
          type={"number"}
          label={"Weight"}
          onChange={weightChangeHandler}
          value={weight}
          styles={{ width: "100%", margin: "1em 0" }}
        />
        <Button
          onClick={formSubmitHandler}
          title={"Submit"}
          variant={"contained"}
          style={{ marginTop: "2em", float: "right" }}
        />
      </Box>
    </Box>
  );
};

const EditForm = ({
  type,
  name = "",
  shortName = "",
  description = "",
  targetType = "",
  target = "",
  weight,
}) => {
  return type === "g2" || type === "g1" ? (
    <GroupEditForm
      initName={name}
      initShortName={shortName}
      initDescription={description}
    />
  ) : (
    <ParameterEditForm
      initType={targetType}
      initTarget={target}
      initWeight={weight}
    />
  );
};

export default EditForm;
