import React, { useState } from "react";
import EwTable from "../../../MComponent/Table/Table";
import EditIcon from "@mui/icons-material/Edit";
import Checkboxes from "../../../AComponent/CheckBox/checkbox_2";
import EwModal from "../../../MComponent/Modal/Modal";
import Dimensions from "./Dimensions";

const heading = ["S.no", "Dimension Name"];

function DimensionSection() {
  const [dimSelected, setDimSelected] = useState({});
  const [openDim, setOpenDim] = useState(null);
  function EditDim({ name }) {
    return (
      <>
        <div onClick={() => setOpenDim(<Dimensions name={name} />)}>
          <EditIcon className="pointer" color="info" />
        </div>
      </>
    );
  }
  function DimSelector({ id }) {
    function selectionChange(id, val) {
      setDimSelected((prev) => ({ ...prev, [id]: val }));
    }
    return (
      <>
        <div style={{ padding: 20 }}>
          <Checkboxes
            value={dimSelected[id]}
            onChange={(val) => selectionChange(id, val)}
          />
        </div>
      </>
    );
  }
  const rows = [
    [
      { Component: DimSelector, props: { id: "dim1" } },
      "Dim1",
      { Component: EditDim, props: { name: "dim1" } },
    ],
  ];
  return (
    <div className="Flex">
      <EwModal open={openDim ? true : false} onClose={() => setOpenDim(null)}>
        {openDim}
      </EwModal>
      <div style={{ width: "80%" }}>
        <EwTable heading={heading} rows={rows} removeSearch={true} />
      </div>
    </div>
  );
}

export default DimensionSection;
