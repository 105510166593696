import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function ActionDebouncer({ message }) {
  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 20);
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      window.location.reload();
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box
      height={"30vh"}
      width={"30vw"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={"1em"}
      >
        <Typography color={"info"}>{message}</Typography>
        <Typography>
          Your action has been submitted, Please wait until the window reloads
        </Typography>
        {progress < 100 && <CircularProgressWithLabel value={progress} />}
      </Box>
    </Box>
  );
}
