import React from "react";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

function Date_1(props) {
  const { id, compNumber } = props;
  const { label, value, styles, renderNot } = useSelector((state) =>
    JSON.parse(JSON.stringify(state.DataReducer[id][compNumber]))
  );
  const dispatch = useDispatch();
  if (!value) {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: compNumber,
        data: new Date(),
        props: "value",
      },
    });
  }
  function changeDate(value) {
    console.log("Date changed: ", value);
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: compNumber,
        data: value,
        props: "value",
      },
    });
  }
  if (renderNot) return <></>;
  return (
    <div style={{padding:10,...styles}}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={label}
          value={value ? value : new Date()}
          //minDate={new Date('2017-01-01')}
          onChange={changeDate}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </div>
  );
}

export default Date_1;
