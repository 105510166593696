export const MPP = {
  dimensions: [
    {
      dimensionId: "1",
      dimensionName: "Goals",
      dimensionDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras est turpis, auctor a 
      enim non, ullamcorper commodo turpis. Suspendisse efficitur metus non nunc vulputate, vel feugiat 
      metus egestas. Nunc ornare mauris et dignissim lobortis. Donec tempor diam ut elit ultrices, sed 
      pulvinar ex consectetur.`,
      dimensionWeight: 0, // Plan aggregate score is disabled.
      dimensionParameters: {
        inherit: true, // Disabled which means it will not inherit from DMP.

        // Groups & Parameters
        dimensionStructure: "EEE",
        discardRule: "PPR",
        addRule: "LLU",
        minimumParameters: 5,
        maximumParameters: 10,
        parameterUOM: true, // Enabled which means the measurement for parameters is enabled.

        // Targets
        targets: true, // Enabled
        targetsType: "SV", // Single Value
        targetsDataType: "NI", // Numeric target expressed as an Integer
        targetEditing: "P",

        // Weights
        weights: true, // Enabled that means weights can be defined for groups or parameters.
        minimumParameterWeight: 10,
        maximumParameterWeight: 30,
        weightRule: "AAU", // Aggregated Aggregated User-defined

        // Achievements
        achievement: false,
        achievementDataType: "",
        achievementSelection: false,
      },
      dimensionRating: {
        ratingScale: 5,
        comments: [],
      },
      dimensionScoring: {
        aggregateScore: true,
        aggregationRating: "Appraiser", // Determines which rating will be used to determine the aggregate score
        scoreRounding: "RO", // Round Off to one decimal place.
        roundingValue: 10,
        aggregateScoreRatingScale: 5,
      },
    },
  ],
};
