import React, { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import * as Validators from '../../Utils/Validators/library'

import LayoutRenderer from '../../Interpreter/LayoutRenderer'
import Loader from '../../Utils/Loaders/Loader_1'

function Shell(props) {
  console.log('Component is: ', props)
  const { payload } = props
  const Data = JSON.parse(JSON.stringify(props))
  const [componentInstanceId] = useState(uuid())
  const [loading, setLoading] = useState(true)
  //   useEffect(()=>{
  //     console.log("Data changed")
  //     clearData();
  // setId(uuid());
  // setLoading(false);
  //   },[props.payload])
  //setId(uuid());
  const dispatch = useDispatch()
  //const [Data,setData]=useState(JSON.parse(JSON.stringify(props)));
  function clearData() {
    console.log('Clear data called')
    dispatch({
      type: 'REMOVE_SHELL_DATA',
      payload: {
        id: componentInstanceId,
      },
    })
  }

  useEffect(() => {
    if (Data.getData) {
      Validators.fetchData(Data.getData).then((res) => {
        console.log('From shell2 data: ', res)
        dispatch({
          type: 'SET_FORM',
          payload: {
            id: componentInstanceId,
            data: res,
          },
        })
        if (res.TransactionID)
          dispatch({
            type: 'STORE_PAYLOAD',
            payload: {
              id: componentInstanceId,
              submitPayload: {
                Header: {
                  TransactionId: res.TransactionID,
                  EffectiveFromTimestamp: '' + new Date().getTime(),
                },
              },
            },
          })
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
    return () => {
      clearData()
    }
  }, [])
  Data.type = 'layout'

  console.log('From Shell_2 ', ' ', componentInstanceId)
  if (Data.payload) {
    dispatch({
      type: 'STORE_PAYLOAD',
      payload: {
        id: componentInstanceId,
        submitPayload: Data.payload,
      },
    })
  }
  if (Data.enabledGroups) {
    dispatch({
      type: 'ENABLED_GROUPS',
      payload: {
        id: componentInstanceId,
        data: Data.enabledGroups,
      },
    })
  }

  function DependencyHandler({ ele }) {
    console.log('Form dep: ', ele)
    const state = useSelector((state) => state.DataReducer[componentInstanceId])
    const dispatch = useDispatch()

    let params = []
    if (typeof ele.condition.params === 'object') {
      params = ele.condition.params
    } else {
      for (let i = 0; i < ele.condition.params.length; i++) {
        let par = ele.condition.params[i]
        let splitValue = par.split('.')

        if (splitValue.length == 2) {
          console.log('New dependency: ', splitValue, ' ', state)

          params.push(state[splitValue[0]][splitValue[1]])
        } else params.push(par)
      }
    }
    // let decision = Validators[ele.condition.method](...params, state, payload, {
    //   id: componentInstanceId,
    // })
    let decision = Validators[ele.condition.method](...params, state, payload,componentInstanceId)
    console.log('New dependency: 1', decision, ' ', ele, ' ', ele.component)
    // if (ele.ifTrue || ele.ifTrue === false) {
    //   decision = decision ? ele.ifTrue : ele.ifFalse
    // }
    if(ele.ifTrue !== undefined){
      decision = decision===true ? ele.ifTrue : ele.ifFalse
    }
    console.log('After decision ', decision, ' ', ele)

    if (
      JSON.stringify(state[ele.component][ele.onProps]) !==
      JSON.stringify(decision)
    ) {
      dispatch({
        type: 'UPDATE_PROPS',
        payload: {
          id: componentInstanceId,
          componentNumber: ele.component,
          props: ele.onProps,
          data: decision,
        },
      })
    }

    return <></>
  }
  const Sloading = useSelector(
    (state) => state.DataReducer[componentInstanceId]?.loading,
  )
  if (loading || Sloading)
    return (
      <Paper style={{ padding: 100,width:'50%' }}>
        <Loader />
      </Paper>
    );

    
  return (
    <>
      <Paper style={{ padding: 100 }}>
        <LayoutRenderer Shell={{ id: componentInstanceId }} Data={Data} />
      </Paper>
      {props.fieldDependency?.map((ele) => (
        <DependencyHandler ele={ele} />
      ))}
    </>
  )
}

export default Shell
