import { Typography } from "@mui/material";
import React, { useState } from "react";
import Atomic from "../../../AComponent/Atomic";
import Molecule from "../../../MComponent/Molecule";
import FeatureIDs from "../../../Utils/Common/FeatureIDs";
import ReportsTable from "./ReportsTable";
import {
  getData,
  getFullDateFromEpoch,
  generateReport,
  getUserId,
} from "../../../Utils/Common/Library";
import DownloadReport from "./DownloadReport";
import mappings, { messages } from "./data";
import AlertMessage from "../../../Utils/Messages/AlertMessage";
import Applicability6 from "../../../MComponent/Applicability/Applicability5";
import extract from "./ExtractApplicabilityData";

const { Dropdown, Button } = Atomic;
const { EwModal, Tabs } = Molecule;

function PlanSummary({ onClose, isAppraiser }) {
  const [PlanList, setPlanList] = useState([]);
  const [selectedPlanPeriod, setSelectedPlanPeriod] = useState(-1);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [message, setMessage] = useState(null);
  const [serverData, setServerData] = useState({});
  const [disableButton, setDisableButton] = useState(false);
  const [applicabilityFilter, setApplicabilityFilter] = useState(null);

  function planSelected(planId) {
    setSelectedPlan(planId);
    setSelectedPlanPeriod(-1);
    setPlanList([]);
    getData({
      featureId: FeatureIDs.PerformanceCycleInitiation,
      payload: { "Data.MPPID": planId },
    }).then((res) => {
      if (res?.Data) {
        let arr = res.Data.map((ele) => {
          console.log("Test ele is: ", ele.PlanCycleEndDate);
          let startDate = +ele.PlanCycleStartDate;
          let endDate = +ele.PlanCycleEndDate;
          let planPeriod =
            getFullDateFromEpoch({ epoch: startDate }) +
            "-" +
            getFullDateFromEpoch({ epoch: endDate });
          return {
            label: planPeriod,
            value: ele.TransactionID,
            period: `${new Date(startDate).getFullYear()}-${new Date(endDate)
              .getFullYear()
              ?.toString()
              ?.substring(2, 4)}`,
          };
        });
        console.log("Array is : ", arr);
        setPlanList([...arr]);
      }
    });
  }
  function submit() {
    const MasterPerformancePlanIDValue = serverData?.plans?.find(
      (item) => item.value === selectedPlan
    )?.label;
    const PlanPeriod = PlanList?.find(
      (item) => item.value === selectedPlanPeriod
    )?.period;
    if (!MasterPerformancePlanIDValue || !PlanPeriod) return;
    let requiredFields = [
      {
        MasterPerformancePlanID: selectedPlan,
        MasterPerformancePlanIDValue,
      },
      { PlanPeriod, PlanPeriodValue: PlanPeriod },
      ...extract(applicabilityFilter),
    ];
    setMessage(messages.wait);
    setDisableButton(true);
    const payload = {
      queryName: mappings.PS,
      requiredFields,
    };
    if (isAppraiser) {
      payload.queryName = mappings.APS;
      payload.AppraiserUserID = getUserId();
    }
    generateReport(payload)
      .then((res) => {
        setMessage(messages.success);
      })
      .catch((e) => {
        console.log("Error: ", e);
        setMessage(messages.networkError);
      })
      .finally(() => {
        setDisableButton(false);
      });
  }
  function Report() {
    return (
      <>
        <div className="Flex-row g20" style={{ width: "100%" }}>
          <Dropdown
            styles={{ width: "100%" }}
            label={"Select Plan"}
            featureId={FeatureIDs.MasterPerformancePlan}
            mapper={{ label: "PlanName", value: "TransactionID" }}
            value={selectedPlan}
            onChange={planSelected}
            storeApiResult={(data) =>
              setServerData((prev) => ({ ...prev, plans: data }))
            }
            apiResult={serverData.plans}
          />
          {PlanList?.length > 0 && (
            <Dropdown
              styles={{ width: "100%" }}
              menuItems={PlanList}
              value={selectedPlanPeriod}
              onChange={(val) => {
                console.log("val is: ", val);
                setSelectedPlanPeriod(val);
              }}
              mapper={{ label: "planPeriod", value: "TransactionID" }}
              storeApiResult={(data) =>
                setServerData((prev) => ({ ...prev, period: data }))
              }
              apiResult={serverData.period}
            />
          )}
        </div>
        {message && (
          <>
            <br />
            <AlertMessage {...message} onClose={() => setMessage(null)} />
          </>
        )}
        <br />
        {selectedPlanPeriod !== -1 && (
          <div className="Flex g20">
            <Applicability6
              onChange={setApplicabilityFilter}
              value={applicabilityFilter}
              passPayload={true}
              AuthorFeatureID={"Reports"}
            />

            <Button
              disable={disableButton}
              title={"Generate New Report"}
              onClick={submit}
            />
          </div>
        )}
      </>
    );
  }
  return (
    <EwModal open={true} onClose={onClose}>
      <div
        className="scroll"
        style={{ width: 800, minHeight: 300, maxHeight: 500 }}
      >
        <div className="Flex">
          <Typography variant="h1">Plan Summary</Typography>
        </div>
        <br />
        <Tabs
          componentList={[
            { label: "Generate Reports", Component: Report },
            {
              label: "Download Reports",
              Component: ReportsTable,
              props: { type: isAppraiser ? "APS" : "PS", isAppraiser },
            },
          ]}
        />
      </div>
    </EwModal>
  );
}

export default PlanSummary;
