import { Box, Divider, Paper, Typography, Stack } from "@mui/material";

import React, { useState } from "react";
import { Link } from "react-router-dom";
import DataGrid2 from "../../MComponent/Table/DataGrid2";
import { getDateFromEpoch, getUserId } from "../../Utils/Common/Library";
import { constants } from "./Dimension/Data/QueryConstants";
import store from "../../Redux/Store";
import Atomic from "../../AComponent/Atomic";
import Molecule from "../../MComponent/Molecule";
import { useHistory } from "react-router-dom";

const { Button } = Atomic;
const { Tabs } = Molecule;
function ViewBox({ rowData }) {
  console.log(rowData, "PLAN");
  const Plan = rowData;
  if (!Plan.Dimensions) Plan.Dimensions = [];
  if (!Plan.Sections) Plan.Sections = [];

  return (
    <div>
      <Link to={{ pathname: "/pms", state: { Plan } }}>View</Link>
    </div>
  );
}

function UserType({ rowData }) {
  console.log("Dta is: ", rowData);
  const dimensions = rowData?.Dimensions;
  const userId = getUserId();
  let type = [];
  if (dimensions?.length > 0) {
    for (let i = 0; i < dimensions.length; i++) {
      const { AppraiserID, ReviewerID } = dimensions[i];
      if (userId === AppraiserID) {
        type.push("Appraiser");
      }
      if (userId === ReviewerID) {
        type.push("Reviewer");
      }
    }
  }
  type = [...new Set(type)];
  return (
    <Stack justifyContent={"center"} height={"100%"}>
      <span>{type.join(",") || "N/A"}</span>
    </Stack>
  );
}
const MyTeamPlan = () => {
  const history = useHistory();

  const [plans, setPlans] = useState([]);
  let { role, userId } = store.getState()?.AuthReducer;
  // const isButonViewable = filterData(plans)?.length > 0;

  function filterData(queryData, role) {
    if (!queryData) return [];
    if (role) {
      const userId = getUserId();
      queryData = queryData.filter((item) => {
        let type = [];
        console.log("items is: ", item);
        for (let i = 0; i < item.Dimensions.length; i++) {
          const { AppraiserID, ReviewerID } = item.Dimensions[i];
          if (userId === AppraiserID) {
            type.push("Appraiser");
          }
          if (userId === ReviewerID) {
            type.push("Reviewer");
          }
        }
        return type.includes(role === "R" ? "Reviewer" : "Appraiser");
      });
    }

    const statusArray = [
      "Assessment Initiated",
      "Appraiser Assessment Due",
      "Reviewer Assessment Due",
      "Moderator Action Due",
      "Appraisee Acceptance Due",
      "Assessment Closed",
    ];
    console.log(queryData, "Before MYTEAMPLAN_QUERYDATA");
    const dataMap = {};
    for (let i = 0; i < queryData.length; i++) {
      const doc = queryData[i];
      const documentId = doc.DocumentID;
      if (dataMap[documentId]) {
        const prevDoc = dataMap[documentId];
        const currDoc = doc;

        const prevStatus = prevDoc.CurrentStatus;
        const currStatus = currDoc.CurrentStatus;

        const prevIndex = statusArray.findIndex((s) => s === prevStatus);
        const currIndex = statusArray.findIndex((s) => s === currStatus);

        if (prevIndex !== -1 && currIndex !== -1) {
          if (currIndex > prevIndex) {
            dataMap[documentId] = currDoc;
          }
        }
      } else {
        dataMap[documentId] = doc;
      }
    }
    queryData = Object.values(dataMap);

    console.log(queryData, "After MYTEAMPLAN_QUERYDATA");
    return queryData;
    // return queryData.filter((data) => {
    //   let isAppraisee = getUserId() === data.AppraiseeUserID;
    //   let isAppraiser = false;
    //   let isReviewer = false;
    //   let isModerator = (store.getState()?.AuthReducer?.role || []).includes(
    //     "M"
    //   );

    //   const Dimensions = data.Dimensions;

    //   Dimensions.forEach((d) => {
    //     if (d.AppraiserID === getUserId()) {
    //       isAppraiser = true;
    //     }
    //     if (d.ReviewerID === getUserId()) {
    //       isReviewer = true;
    //     }
    //   });

    //   return isModerator || isAppraisee || isAppraiser || isReviewer;
    // });
  }
  // const queryObj = false
  //   ? { QueryName: constants.FindAllEPPWithPersonName, RequiredFields: [] }
  //   : {
  //       QueryName: constants.FindAllEPPByAppraiserOrReviewer,
  //       RequiredFields: [
  //         {
  //           UserID: userId,
  //         },
  //       ],
  //     };
  const queryObj = {
    QueryName: constants.FindAllLineAndFunctionalEPPByAppraiserOrReviewerV2,
    otherData: {
      AppraiserUserID: getUserId(),
    },
    RequiredFields: [],
  };
  console.log("From myteam plans: ", queryObj);
  const DataGridProps = {
    saveServerData: (data) => {
      setPlans(data);
    },
    query: queryObj,
    heading: [
      "Plan Period",
      "Employee code",
      "Employee Name",
      "Plan Status",
      "Start Date",
      "End Date",
      "User Type",
    ],
    valueFormatter: {
      PlanStartDate: (val) => getDateFromEpoch({ epoch: val }),
      PlanEndDate: (val) => getDateFromEpoch({ epoch: val }),
    },
    rowToFieldMap: [
      "PlanPeriod",
      "AppraiseeUserEmployeeCode",
      "AppraiseeUserFullName",
      "CurrentStatus",
      "PlanStartDate",
      "PlanEndDate",
      {
        Component: UserType,
        props: {},
      },
    ],
    onRowClicked: ({ data }) => {
      console.log(data, "THISISROWDATA");
      const Plan = data;
      if (!Plan.Dimensions) Plan.Dimensions = [];
      if (!Plan.Sections) Plan.Sections = [];
      history.push({ pathname: "/pms", state: { Plan } });
    },
    filterQueryData: (queryData) => {
      // console.log(queryData, "queryData", getUserId());
      // return queryData;
      return filterData(queryData);
    },
  };
  const tabList = [
    {
      label: "Appraiser",
      Component: DataGrid2,
      props: {
        ...DataGridProps,
        filterQueryData: (queryData) => {
          return filterData(queryData, "A");
        },
      },
    },
    {
      label: "Reviewer",
      Component: DataGrid2,
      props: {
        ...DataGridProps,
        filterQueryData: (queryData) => {
          return filterData(queryData, "R");
        },
      },
    },
    {
      label: "All",
      Component: DataGrid2,
      props: DataGridProps,
    },
  ];
  return (
    <div className="Flex">
      <div>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h4">My Team Plan</Typography>
          {/* {isButonViewable && (
            <Button
              label={"View Score Distribution"}
              onClick={() => {
                history.push("/dashboard");
              }}
              variant={"text"}
              title={"View Score Distribution"}
            />
          )} */}
        </Box>
        <Divider sx={{ margin: "2em 0" }} />
        <Paper sx={{ width: "75vw" }}>
          <Tabs componentList={tabList} />
          {/* <DataGrid2
            // featureId={"19151"}
            saveServerData={(data) => {
              setPlans(data);
            }}
            query={queryObj}
            heading={[
              "Plan Period",
              "Start Date",
              "End Date",
              "Employee Name",
              "Employee code",
              "User Type",
              "Plan Status",
              "View",
            ]}
            valueFormatter={{
              PlanStartDate: (val) => getDateFromEpoch({ epoch: val }),
              PlanEndDate: (val) => getDateFromEpoch({ epoch: val }),
            }}
            rowToFieldMap={[
              "PlanPeriod",
              "PlanStartDate",
              "PlanEndDate",
              "AppraiseeUserFullName",
              "AppraiseeUserEmployeeCode",
              {
                Component: UserType,
                props: {},
              },
              "CurrentStatus",
              {
                Component: ViewBox,
                props: {},
              },
            ]}
            filterQueryData={(queryData) => {
              // console.log(queryData, "queryData", getUserId());
              // return queryData;
              return filterData(queryData);
            }}
          /> */}
        </Paper>
      </div>
    </div>
  );
};

export default MyTeamPlan;
