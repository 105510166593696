import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const initialProps={
    open:false,
    buttons:null,
    title:"",
    body:null,
    handleClose:null,
    component:null
}

export default function AlertDialog(props=initialProps) {
    //const [open, setOpen] = React.useState(false);
    var { open, buttons, title, body, handleClose,component } = props;
    if(!handleClose)return<></>
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    //   const handleClose = () => {
    //     setOpen(false);
    //   };

    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby={title}
                aria-describedby={body}
            >
                <DialogTitle id={title}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        {body?body:component}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {buttons?.map((ele) => ele)}
                </DialogActions>
            </Dialog>
        </div>
    );
}
