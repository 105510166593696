//export const BASE_URL="http://functionhander-env1.eba-53dg5xbf.ap-south-1.elasticbeanstalk.com/functionHandler"
//const testServer = document.getElementById("root").dataset.testServer;
//const PRODUCTION = testServer !== "true";

const PRODUCTION = !window.location.href.includes("test=true");
//const PRODUCTION = window.Production;
const PRODUCTION_BASE_URL = "https://internal.employwise.app";

let FUNCTION_HANDLER_URL =
  "http://functionhander-env1.eba-53dg5xbf.ap-south-1.elasticbeanstalk.com/functionHandler";
let TRANSACTION_WRITER_URL =
  "https://76wsmmdvl8.execute-api.ap-south-1.amazonaws.com/dev/transaction-writer/insert?datasource=f";
let LAYOUT_GENERATOR =
  "http://layoutgenerator-env.eba-kjcpppwd.ap-south-1.elasticbeanstalk.com/layout-generator";
// let APPLICABILITY_URL =
//   "http://applicability-env1.eba-ynszfc27.ap-south-1.elasticbeanstalk.com/applicability/createapplicability";
let APPLICABILITY_URL =
  "http://applicability-env-1.eba-ynszfc27.ap-south-1.elasticbeanstalk.com/applicability/createapplicability";
let COMPLEX_QUERY =
  "http://dataandanalytics-env.eba-dhx54aty.ap-south-1.elasticbeanstalk.com/DataAndAnalytics/execute-query";
// let AUTH_URL =
// //   "http://keycloakapplication-env.eba-p77skiep.ap-south-1.elasticbeanstalk.com/keycloak/login";
// let AUTH_URL =
//   "http://keycloak-env.eba-phbkjcms.ap-south-1.elasticbeanstalk.com/keycloak/login";
let AUTH_URL =
  "http://keycloakapplication-env.eba-p77skiep.ap-south-1.elasticbeanstalk.com/keycloak/login";
let TRANSACTION_WRITER_NO_TOKEN_URL =
  "https://76wsmmdvl8.execute-api.ap-south-1.amazonaws.com/dev/transaction-writer/insert?datasource=i";
let TAM_URL =
  "http://face-recognition-tam-env.eba-3zh3fmag.ap-south-1.elasticbeanstalk.com/";
let DATA_ANALYTICS =
  "http://dataandanalytics-env.eba-dhx54aty.ap-south-1.elasticbeanstalk.com/DataAndAnalytics/api";
let APPRAISER_REPORT =
  "https://internal.employwise.app/DataAndAnalytics/generateReportBy/Appraiser";
let LOGGER_URL = PRODUCTION_BASE_URL + "/logger";

if (PRODUCTION) {
  FUNCTION_HANDLER_URL = PRODUCTION_BASE_URL + "/functionHandler";
  TRANSACTION_WRITER_URL =
    PRODUCTION_BASE_URL + "/transaction-writer/insert?datasource=f";
  LAYOUT_GENERATOR = PRODUCTION_BASE_URL + "/layout-generator";
  APPLICABILITY_URL =
    PRODUCTION_BASE_URL + "/applicability/createapplicability";
  COMPLEX_QUERY = PRODUCTION_BASE_URL + "/DataAndAnalytics/execute-query";
  AUTH_URL = PRODUCTION_BASE_URL + "/keycloak/login";
  TRANSACTION_WRITER_NO_TOKEN_URL =
    PRODUCTION_BASE_URL + "/transaction-writer/insert?datasource=i";
  TAM_URL = PRODUCTION_BASE_URL + "/";
  DATA_ANALYTICS = PRODUCTION_BASE_URL + "/DataAndAnalytics";
}

export default {
  FUNCTION_HANDLER_URL,
  TRANSACTION_WRITER_URL,
  LAYOUT_GENERATOR,
  PRODUCTION,
  APPLICABILITY_URL,
  COMPLEX_QUERY,
  AUTH_URL,
  TRANSACTION_WRITER_NO_TOKEN_URL,
  TAM_URL,
  DATA_ANALYTICS,
  LOGGER_URL,
  APPRAISER_REPORT,
};
