import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Atomic from "../../AComponent/Atomic";
import ConfirmationButton from "../../AComponent/Buttons/ConfirmationButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuid } from 'uuid'

const initialProps = {
  value: null,
  AuthorFeatureID: null,
  onChange: null,
  config:{
    stepOwnerLabelOptions:[]
  } //optional
};
function WorkflowButton(props = initialProps) {
  let { value, AuthorFeatureID, onChange,config } = props;
  const id=uuid();
  console.log("From Workflow button: ",config," ",props)
  let isTest = window.location.href.includes("test=true");
  let link = "/#/workflow";
  if (isTest) link += "?test=true";
  let title = "Add Workflow";
  if (value) {
    title = "Edit Workflow";
    //link += "&workflowID=" + value;
  }
  // useEffect(()=>{
  //   window.addEventListener("storage",(e)=>{
  //     console.log("STorege triggered: ",e)
  //   })
  // },[])
  const history = useHistory();
  if (!AuthorFeatureID) return <>No Author Feature ID Defined</>;
  return (
    <div className="Flex-row">
      <Atomic.Button
        title={title}
        onClick={() => {
          let location = window.location.href;
          if (location.includes("ewwebapp")){
            link = "/ewwebapp/#/workflow";
            if (isTest) link += "?test=true";
          }
         
          const newTab = window.open(link, "_blank");

          const channel = new BroadcastChannel("EW-data");
          newTab.addEventListener("load", () => {
            channel.postMessage({
              workflowID: value,
              AuthorFeatureID,
              config,
              WorkflowButtonID:id
            });
          });

          channel.addEventListener("message", (event) => {
            if (event.data.WorkflowId) {
              console.log("Workflow ID: ", event.data.WorkflowId," ",AuthorFeatureID," ",event.data.WorkflowButtonID);
              if(id===event.data.WorkflowButtonID)onChange(event.data.WorkflowId);
            }
          });
          history.push(newTab);
        }}
      />
      {value && (
        <div className="pointer" onClick={() => onChange("")}>
          <DeleteIcon />
        </div>
      )}
    </div>
  );
}

export default WorkflowButton;
