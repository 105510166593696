import React, { useState } from "react";
import Atomic from "../../../AComponent/Atomic";
import EwTable from "../../../MComponent/Table/Table";
import EwModal from "../../../MComponent/Modal/Modal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import useWindowDimensions from "../../../Utils/CustomHooks/WindowRezise";

const { Input, Button, Dropdown } = Atomic;

function ColumnsAdd({ updateParent }) {
  const [openModal, setModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const { width } = useWindowDimensions();
  function ModalContent() {
    const [localState, setLocalState] = useState({
      list: [],
    });
    const { title, type, list } = localState;
    function onChange(val, type) {
      setLocalState((prev) => ({ ...prev, [type]: val }));
    }
    function onListChange(val, index) {
      let arr = list;
      arr[index] = val;
      onChange(arr, "list");
    }
    function addEmpty() {
      if (list.length === 5) return;
      let arr = list;
      arr.push("");
      onChange(arr, "list");
    }
    function deleteOption(index) {
      let arr = list;
      arr.splice(index, 1);
      onChange(arr, "list");
    }
    function save() {
      let arr = columns;
      if (!arr) arr = [];
      arr.push({ title, type, list });
      //updateState(arr, "columns");
      setColumns([...arr]);
      updateParent(arr);
      setModal(false);
    }
    const menu = [
      "Text",
      "Date",
      "Numeric",
      "Percentage",
      "Select",
      "Multiple Select",
      "Dimension",
      "Parameters",
    ];
    return (
      <div
        className="scroll"
        style={{ width: 0.7 * width, height: 300, overflowY: "auto" }}
      >
        <div className="Flex">
          <h2>Add Column Details</h2>
        </div>
        <div className="Flex-row">
          <div>
            <Input
              label={"Column Title"}
              value={title}
              onChange={(val) => onChange(val, "title")}
            />
          </div>
          <div style={{ width: "30%" }}>
            <Dropdown
              label={"Column Type"}
              menuItems={menu}
              value={type}
              onChange={(val) => onChange(val, "type")}
            />
          </div>
        </div>
        <div className="Flex">
          {(type === "Select" || type === "Multiple Select") && (
            <>
              <div>
                {list?.map((ele, index) => (
                  <div className="Flex-row" style={{ gap: 20 }}>
                    <Input
                      label="Option Name"
                      value={ele}
                      onChange={(val) => onListChange(val, index)}
                    />
                    <div
                      className="pointer"
                      onClick={() => deleteOption(index)}
                    >
                      <DeleteIcon color="error" />
                    </div>
                  </div>
                ))}
              </div>
              <div className="Flex">
                <div className="pointer" onClick={addEmpty}>
                  <AddCircleOutlineIcon />
                </div>
              </div>
            </>
          )}
        </div>
        <br />
        <div className="Flex">
          <Button title={"Save"} onClick={save} />
        </div>
      </div>
    );
  }
  const heading = ["Title", "Type"];
  let rows;
  if (columns?.length > 0) {
    console.log("Columns: ", columns);
    rows = columns?.map(({ title, type, list }) => {
      return [title, type];
    });
  } else {
    rows = [];
  }
  return (
    <div>
      <EwModal open={openModal} onClose={() => setModal(false)}>
        <ModalContent />
      </EwModal>

      <div className="Flex" style={{width:'100%'}}>
        <EwTable heading={heading} rows={rows} />
      </div>

      <br />
      <div className="Flex">
        <Button title={"Add Columns"} onClick={() => setModal(true)} />
      </div>
    </div>
  );
}

export default ColumnsAdd;
