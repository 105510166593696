import { Box, Typography } from "@mui/material";
import React, { useCallback } from "react";
import StepAccordian from "../UI/StepAccordian";
import Atomic from "../../../../AComponent/Atomic";
import "../UI/temp.css";
import { Add } from "@mui/icons-material";
import Molecule from "../../../../MComponent/Molecule";
import { Email, N2A, SMS, WhatsApp } from "./Notifications";
import { v4 as uuid } from "uuid";
import NumericInput from "../UI/NumericInput";

const { Radio, Dropdown, Button } = Atomic;
const { Tabs } = Molecule;
const TimePeriodItems = [
  { label: "Months", value: "M" },
  { label: "Hours", value: "H" },
  { label: "Days", value: "D" },
  { label: "Weeks", value: "W" },
];

function ReminderForm({ initReminder, onChange }) {
  const {
    AnchorEvent,
    TriggerTime,
    TriggerTimeUnit,
    Frequency,
    RepeatInterval,
    Notifications,
  } = initReminder;

  const componentList = [
    {
      label: "Email",
      Component: Email,
      props: {
        Notification: Notifications[0] || {},
        onChange: (notification) => {
          Notifications[0] = notification;
          onChange({ ...initReminder, Notifications });
        },
      },
    },
    {
      label: "SMS",
      Component: SMS,
      props: {
        Notification: Notifications[1] || {},
        onChange: (notification) => {
          Notifications[1] = notification;
          onChange({ ...initReminder, Notifications });
        },
      },
    },
    {
      label: "WhatsApp",
      Component: WhatsApp,
      props: {
        Notification: Notifications[2] || {},
        onChange: (notification) => {
          Notifications[2] = notification;
          onChange({ ...initReminder, Notifications });
        },
      },
    },
    {
      label: "Need-2-Act",
      Component: N2A,
      props: {
        Notification: Notifications[3] || {},
        onChange: (notification) => {
          Notifications[3] = notification;
          onChange({ ...initReminder, Notifications });
        },
      },
    },
  ];

  return (
    <Box maxHeight={"450px"} className="invisble-scroll">
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        alignItems={"flex-end"}
        gap={"1em"}
        paddingLeft={"0.5em"}
      >
        <Radio
          flex={1}
          menuItems={[
            { label: "Before", value: "Before" },
            { label: "After", value: "After" },
          ]}
          onChange={(value) => {
            onChange({
              ...initReminder,
              AnchorEvent: value === -1 ? "" : value,
            });
          }}
          value={AnchorEvent || -1}
        />
        <NumericInput
          onChange={(val) => {
            onChange({ ...initReminder, TriggerTime: val });
          }}
          value={TriggerTime}
        />
        <Dropdown
          label={"Time Period"}
          menuItems={[...TimePeriodItems]}
          onChange={(val) => {
            onChange({ ...initReminder, TriggerTimeUnit: val });
          }}
          value={TriggerTimeUnit}
          styles={{ width: "5em" }}
          variant={"standard"}
        />
        <Typography variant="subtitle2">
          the given time to complete action, send reminder
        </Typography>
        <Radio
          flex={1}
          menuItems={[
            { label: "Once", value: "Once" },
            { label: "Every (Repeatedly)", value: "Every" },
          ]}
          onChange={(value) => {
            let freq = Frequency;
            if (value === "Every") {
              freq = "R" + freq.charAt(1);
            } else {
              freq = "SI";
            }
            onChange({ ...initReminder, Frequency: freq });
          }}
          value={
            Frequency ? (Frequency.charAt(0) === "R" ? "Every" : "Once") : -1
          }
        />
        {Frequency && Frequency !== "SI" && (
          <>
            <NumericInput
              value={RepeatInterval}
              onChange={(value) => {
                onChange({ ...initReminder, RepeatInterval: value });
              }}
            />
            <Dropdown
              label={"Time Period"}
              menuItems={[...TimePeriodItems]}
              onChange={(value) => {
                let freq = Frequency;
                freq = freq.charAt(0) + value;
                onChange({ ...initReminder, Frequency: freq });
              }}
              value={Frequency ? Frequency.charAt(1) : -1}
              styles={{ width: "5em" }}
              variant={"standard"}
            />
          </>
        )}
        <Typography variant="subtitle2">to Step Owner using: </Typography>
      </Box>
      <br />
      <Tabs componentList={componentList} />
    </Box>
  );
}

function StepReminders({ StepReminders, updateState }) {
  const saveReminders = useCallback(
    (reminder) => {
      const Reminders = [...StepReminders];
      const index = StepReminders.findIndex(
        (n) => n.ReminderID === reminder.ReminderID
      );

      if (index === -1) {
        Reminders.push(reminder);
      } else {
        Reminders[index] = reminder;
      }
      updateState(Reminders, "StepReminders");
    },
    [StepReminders, updateState]
  );

  return (
    <Box position={"relative"}>
      <Typography variant="h4" fontWeight={"600"}>
        Reminders
      </Typography>
      <Box
        mt={"1em"}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.6em",
          padding: "0.5em",
        }}
        height={"60vh"}
        className="invisble-scroll"
      >
        {StepReminders.length === 0 ? (
          <Box className="Flex">No Remainders available</Box>
        ) : (
          StepReminders.map((reminder) => {
            return (
              <StepAccordian
                Name={reminder.ReminderName}
                onNameChange={(val) => {
                  const updatedReminder = { ...val };
                  updatedReminder.ReminderName = val;
                  saveReminders(updatedReminder);
                }}
                key={reminder.ReminderID}
              >
                <ReminderForm
                  initReminder={reminder}
                  onChange={saveReminders}
                />
              </StepAccordian>
            );
          })
        )}
      </Box>
      <br />
      <Button
        style={{ position: "absolute", bottom: "-1.5em", left: 0 }}
        variant={"text"}
        otherProps={{ endIcon: <Add /> }}
        onClick={() => {
          saveReminders({
            ReminderID: uuid(),
            ReminderName: `Reminder ${StepReminders.length + 1}`,
            AnchorEvent: "",
            TriggerTime: "",
            TriggerTimeUnit: "",
            Frequency: "",
            RepeatInterval: "",
            Notifications: [null, null, null, null],
          });
        }}
        title={"Reminder"}
      />
    </Box>
  );
}

export default StepReminders;
