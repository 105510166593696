import { useState } from "react";
import Atomic from "../../../../../AComponent/Atomic";
import { isUserDefined } from "../../Helpers/helper";
import PeriodAccordian from "../PeriodAccordian";
import { PeriodMap } from "../PeriodMap";

const { Input, Button } = Atomic;

function targetStateReducer(periodTargets, mapTargets, mapRule) {
  const result = periodTargets.reduce((total, periodTarget) => {
    const targets = periodTarget.SingleTarget
      ? periodTarget.SingleTarget
      : isUserDefined(mapRule)
      ? ""
      : mapTargets;
    if (!total[periodTarget.TargetPeriodType]) {
      total[periodTarget.TargetPeriodType] = {
        [periodTarget.TargetPeriodValue]: targets,
      };
    } else if (
      !total[periodTarget.TargetPeriodType][periodTarget.TargetPeriodValue]
    ) {
      total[periodTarget.TargetPeriodType][periodTarget.TargetPeriodValue] =
        targets;
    }
    return total;
  }, {});
  return result;
}

function FixedValue({
  periodTargets,
  mapTargets,
  editable,
  parameterTargetDataType,
  mapRule,
  Workflow,
  ActionStatusLog,
  closeForm,
  saveData,
}) {
  const [targets, setTargets] = useState(
    targetStateReducer(periodTargets, mapTargets, mapRule)
  );

  function onChangeHandler(TargetPeriodType, TargetPeriodValue, value) {
    setTargets((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState[TargetPeriodType][TargetPeriodValue] = value;
      return newState;
    });
  }

  function onSave() {
    console.log(targets, "GOALSETTINGS_PAYLOAD");
    const finalPeriodTargets = periodTargets.map((periodTarget) => {
      const thisPeriodTargets =
        targets[periodTarget.TargetPeriodType][periodTarget.TargetPeriodValue];
      const finalPayload = {
        ...periodTarget,
        SingleTarget: thisPeriodTargets,
      };
      return finalPayload;
    });

    console.log(finalPeriodTargets, "GOALSETTINGS_PAYLOAD");

    saveData(finalPeriodTargets);
  }

  return (
    <div>
      {periodTargets.map((periodTarget) => {
        const target =
          targets[periodTarget.TargetPeriodType][
            periodTarget.TargetPeriodValue
          ];
        return (
          <PeriodAccordian
            PeriodName={
              PeriodMap[periodTarget.TargetPeriodType].values[
                periodTarget.TargetPeriodValue
              ]
            }
          >
            <div>
              {(parameterTargetDataType === "NI" ||
                parameterTargetDataType === "PI") && (
                <div className="Flex-row">
                  <Input
                    label={"Target"}
                    type={"number"}
                    onChange={(value) => {
                      onChangeHandler(
                        periodTarget.TargetPeriodType,
                        periodTarget.TargetPeriodValue,
                        value
                      );
                    }}
                    value={target}
                    disable={!editable}
                  />
                </div>
              )}
              {(parameterTargetDataType === "ND" ||
                parameterTargetDataType === "PD") && (
                <div className="Flex-row">
                  <Input
                    label={"Target"}
                    type={"number"}
                    onChange={(value) => {
                      onChangeHandler(
                        periodTarget.TargetPeriodType,
                        periodTarget.TargetPeriodValue,
                        value
                      );
                    }}
                    value={target}
                    disable={!editable}
                  />
                </div>
              )}
              {(parameterTargetDataType === "ST" ||
                parameterTargetDataType === "LT") && (
                <div>
                  <Input
                    label={"Target"}
                    onChange={(value) => {
                      onChangeHandler(
                        periodTarget.TargetPeriodType,
                        periodTarget.TargetPeriodValue,
                        value
                      );
                    }}
                    value={target}
                    disable={!editable}
                  />
                </div>
              )}
            </div>
          </PeriodAccordian>
        );
      })}
      <div className="Flex">
        <Button
          title={"Save"}
          onClick={() => {
            onSave();
            closeForm();
          }}
          style={{ margin: "2em" }}
        />
      </div>
    </div>
  );
}

export default FixedValue;
