import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AlertMessage from "../../Utils/Messages/AlertMessage";
import {
  getData,
  deactivateDocument,
  updateGroups,
} from "../../Utils/Common/Library";
import useRender from "../../Utils/CustomHooks/RenderComponent";
import Input from "../../AComponent/Input/Input_3";
//import Atomic from "../../AComponent/Atomic";
//import Atomic from "../../AComponent/Atomic";
import useProps from "../../Utils/CustomHooks/GetProps";
import store from "../../Redux/Store";
import { Actions } from "../../Redux/Actions/Actiontypes";

/* 
            <EwTable
            includeOprations={true}
            heading={heading}
            featureId={FeatureID}
            rowToFieldMap={["ParameterName"]}
            onDocSelected={docSelected}
               />
*/

const TransactionID = "TransactionID";
const FromTimeStamp = "EffectiveFromTimestamp";

const initialProps = {
  heading: null,
  rows: [
    {
      Component: null,
    },
  ],
  disableEdit: false,
  pageLimit: 10,
  featureId: null,
  rowToFieldMap: null,
  tableOptions: true,
  onDocSelected: null,
  currentPageNumber: 1,
  setCurrentPageNumber: null,
  refresh: null,
  removeSearch: false,
  includeOprations: [], //can be true for all or array of these values: "edit","deactivate","copy"
};

export default function EwTable(props = initialProps) {
  const { Button, EwSwitch, ConfirmationButton } =
    require("../../AComponent/Atomic").default;
  var {
    heading,
    rows,
    pageLimit,
    featureId,
    rowToFieldMap,
    tableOptions,
    onDocSelected,
    disableEdit,
    filterRows,
    filterServerData,
    refresh,
    includeOprations,
    id,
    group,
    containerStyles,
    fromLayoutJson,
    groupsToBeEnabled,
    removeSearch,
  } = useProps(props);
  if (!tableOptions) tableOptions = true;
  if (!pageLimit) pageLimit = 10;
  if (!rowToFieldMap) rowToFieldMap = [];
  if (!containerStyles)
    containerStyles = { width: "100%", display: "contents", height: "100%" };

  // To replace the row elements with components that are present in the rowToFieldMap.
  if (rows) {
    rowToFieldMap.forEach((fields, index) => {
      if (!fields.Component) return;

      rows = rows.map((row) => {
        row.splice(index, 0, {
          Component: fields.Component,
          props: fields.props || {},
        });
        return row;
      });
    });
  }

  useEffect(() => {
    setView(rows ? [...rows.slice(0, pageLimit)] : []);
  }, [rows, pageLimit]);

  const [view, setView] = useState(rows ? [...rows.slice(0, pageLimit)] : []);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [includeDoc, setIncludeDoc] = useState(false);
  const [totalPages, setTotalPages] = useState(
    rows ? rows.length / pageLimit : 1
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [tableRows, setTableRows] = useState(rows ? rows : []);
  const [serverData, setServerData] = useState(null);
  const [searchStr, setSearchStr] = useState("");

  useEffect(() => {
    let start = (currentPage - 1) * pageLimit;
    let end = start + pageLimit;
    if (rows && rows.length < end) end = rows.length;
    setView(rows ? [...rows.slice(start, end)] : []);
  }, [rows, pageLimit]);

  useEffect(() => {
    if (refresh === true) {
    }
  }, [refresh]);

  console.log("EWtable: ", heading, " ", rows);
  function setLayoutJsonData(doc, id) {
    if (!groupsToBeEnabled || groupsToBeEnabled.length === 0) return;
    let data = store.getState().DataReducer[id];
    if (!data) return;
    Object.entries(doc).forEach(([key, value]) => {
      if (data[key]) {
        data[key].value = value;
      }
    });
    if (!data.otherData) return;
    data.otherData.editMode = {
      TransactionID: doc.TransactionID,
      EffectiveFromTimestamp: doc.EffectiveFromTimestamp,
    };
    store.dispatch({
      type: Actions.UPDATE_ALL_FIELDS,
      payload: {
        data,
        id,
      },
    });
    updateGroups({ id, groups: groupsToBeEnabled });
  }
  function Edit({ id, doc }) {
    console.log("From edit: ", doc);
    function onSelect() {
      if (!doc) return;
      if (fromLayoutJson) {
        setLayoutJsonData(doc, fromLayoutJson.id);
        return;
      }
      if (!onDocSelected) return;
      onDocSelected(doc);
      // setLoading(true);
      // getData({
      //   featureId,
      //   payload: {
      //     "Header.TransactionID": id,
      //   },
      // })
      //   .then((res) => {
      //     console.log("result: is ", res);
      //     onDocSelected(res.Data[0]);
      //   })
      //   .catch((e) => {
      //     console.log("Error is :", e);

      //     NetworkError();
      //   })
      //   .finally(() => {
      //     setLoading(false);
      //   });
    }
    if (doc.Status !== "1") return <></>;
    return (
      <div className="pointer" onClick={onSelect}>
        <EditIcon color="info" />
      </div>
    );
  }
  function Deactivate({ id, status }) {
    function onDeactivated() {
      deactivateDocument({ id, FeatureID: featureId })
        .then((res) => {
          console.log("res deactivate: ", res);
          setMessage("Deactivated");
        })
        .catch((e) => {
          console.log(e);
        });
    }
    function openWarning() {
      //   <Button
      //   title={"Deactivate "}
      //   color={"error"}
      //   variant={"Text"}
      //   onClick={onDeactivated}
      // />
    }
    if (status === "1")
      return (
        <ConfirmationButton
          label={"Deactivate"}
          message={
            "Are you sure you want to deactivate this document? You cannot re-activate it."
          }
          btnProps={{
            variant: "Text",
            color: "error",
          }}
          actions={[
            {
              title: "Cancel",
              onClick: () => {},
            },
            {
              title: "Deactivate",
              onClick: onDeactivated,
              color: "error",
            },
          ]}
        />
      );
    return (
      <Button
        title={"Deactivated "}
        color={"error"}
        variant={"Text"}
        disable={true}
        onClick={() => {}}
      />
    );
  }
  function Copy({ doc }) {
    function copy() {
      doc.TransactionID = "";
      doc.EffectiveFromTimestamp = "";
      if (fromLayoutJson) {
        setLayoutJsonData(doc, fromLayoutJson.id);
        return;
      }
      onDocSelected(doc);
    }
    return (
      <>
        <div className="pointer" onClick={copy}>
          <ContentCopyIcon color="primary" />
        </div>
      </>
    );
  }
  function NetworkError() {
    setMessage({
      type: 1,
      message: "Network Error!",
    });
  }
  function filterData(item) {
    if (filterServerData) return filterServerData(item);

    if (!includeDoc && item.Status === "1") return true;
    if (includeDoc === true && ["1", "3"].includes(item.Status)) {
      return true;
    }
    return false;
  }
  function IncludeSwitch() {
    return (
      <div className="Flex">
        <EwSwitch
          label={"Include Deactivated"}
          value={includeDoc}
          onChange={(val) => setIncludeDoc(val)}
        />
      </div>
    );
  }
  function fillTableData(data) {
    let arr = [...setRows(data)];
    setTableRows([...arr]);
    nextPage(1, arr);
  }
  useEffect(() => {
    if (serverData) {
      fillTableData(serverData);
    }
  }, [includeDoc]);
  function setRows(data) {
    console.log("Data is : table ", data?.filter(filterData));
    if (!data || data.length === 0) return [];
    return data?.filter(filterData).map((ele) => [
      ...rowToFieldMap.map((fields) => {
        if (!fields.Component)
          return ele[fields] ? ele[fields] : "(Missing Data)";
        if (!fields.props) fields.props = {};
        return <fields.Component {...fields.props} rowData={ele} />;
      }),
      (includeOprations === true || includeOprations?.includes("edit")) && {
        Component: Edit,
        props: {
          id: ele[TransactionID],
          doc: ele,
        },
      },
      (includeOprations === true ||
        includeOprations?.includes("deactivate")) && {
        Component: Deactivate,
        props: {
          id: ele[TransactionID],
          status: ele.Status,
        },
      },
      (includeOprations === true || includeOprations?.includes("copy")) && {
        Component: Copy,
        props: {
          doc: ele,
        },
      },
    ]);
  }

  useEffect(() => {
    if (featureId) {
      getData({
        featureId,
        fields: ["Header.*", "Data.*"],
        status: "*",
      })
        .then((res) => {
          if (res.Data) {
            // let arr = res.Data?.filter(filterData).map((ele) => [
            //   ...rowToFieldMap.map((fields) => {
            //     if (!fields.Component)
            //       return ele[fields] ? ele[fields] : "(Missing Data)";
            //     if (!fields.props) fields.props = {};
            //     return <fields.Component {...fields.props} rowData={ele} />;
            //   }),
            //   !disableEdit && {
            //     Component: Edit,
            //     props: {
            //       id: ele[TransactionID],
            //     },
            //   },
            //   !disableEdit && {
            //     Component: Deactivate,
            //     props: {
            //       id: ele[TransactionID],
            //     },
            //   },
            // ]);
            let arr = setRows(res.Data);
            setServerData(res.Data);

            console.log("Rows are: 1", arr);
            setTableRows(arr);
            nextPage(1, arr);
          }
        })
        .catch((e) => {
          console.log("Error is :", e);
          NetworkError();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);
  function nextPage(e, arr) {
    // console.log("E is: ", e?.target);
    // if (e?.target && !e.target.innerText) {
    //   return;
    // }
    let rows = tableRows;
    let page = e;
    // setPageNumber(page);
    if (page === undefined) return;

    if (arr) rows = arr;

    setCurrentPage(page);
    let start = (page - 1) * pageLimit;
    let end = start + pageLimit;
    if (rows.length < end) end = rows.length;
    setTotalPages(rows.length / pageLimit);
    console.log("Rows are: ", [...rows.slice(start, end)]);
    setView([...rows.slice(start, end)]);
  }
  function search(str) {
    setSearchStr(str);
    if (serverData) {
      if (!str || str?.trim(" ") === "") setRows(serverData);
      console.log("View : ", view);

      let arr = serverData?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(str?.toLowerCase())
      );
      console.log("searched arr: ", arr);
      fillTableData(arr);
    } else {
      let arr = rows?.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(str?.toLowerCase())
      );
      setView([...arr]);
    }
  }
  useEffect(() => {
    console.log("View changed: ", view);
  }, [view]);
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
  }, [message]);

  if (useRender({ id, group })) return <></>;
  if (loading) return <>Loading table...</>;
  console.log("Views is: ", view);
  if (!view || view.length === 0) {
    if (searchStr !== "" && removeSearch !== true)
      return (
        <>
          {" "}
          <div className="Flex" style={{ width: "100%" }}>
            <Input
              placeholder="Search"
              onChange={search}
              variant="outlined"
              styles={{ width: "70%" }}
              value={searchStr}
            />
          </div>
        </>
      );
    return <></>;
  }

  return (
    <div className="scroll" style={{ ...containerStyles }}>
      {message && (
        <AlertMessage type={message.type} message={message.message} />
      )}
      <br />
      {(includeOprations === true ||
        includeOprations?.includes("deactivate")) && <IncludeSwitch />}
      <br />
      {removeSearch !== true && view.length > 0 && (
        <div className="Flex" style={{ width: "100%" }}>
          <Input
            placeholder="Search"
            onChange={search}
            variant="outlined"
            styles={{ width: "100%" }}
            value={searchStr}
          />
        </div>
      )}
      <TableContainer component={Paper}>
        <Table aria-label="Ew table">
          <TableHead>
            <TableRow>
              {heading.map((ele) =>
                ele.Component ? (
                  <TableCell align="left">
                    <ele.Component />
                  </TableCell>
                ) : (
                  <TableCell align="left">{ele}</TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {view.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {console.log("From table: ", row)}
                {row.map((ele) => {
                  if (!ele || ele?.hidden) return <></>;
                  if (ele.Component && !ele.props) ele.props = {};
                  console.log("In Row: ", row);
                  return ele.Component ? (
                    <TableCell align="left">
                      <ele.Component {...ele.props} />
                    </TableCell>
                  ) : (
                    <TableCell align="left">{ele}</TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <div className="Flex">
        {totalPages > 1 && (
          <Pagination
            count={Math.ceil(totalPages)}
            variant="outlined"
            color="primary"
            onChange={(e, pageNumber) => nextPage(pageNumber)}
          />
        )}
      </div>
    </div>
  );
}
