import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../Redux/Actions/Actiontypes";
function useSelectorActions() {
  return {
    useDispatch,
    useSelector,
    Actions,
  };
}

export default useSelectorActions;
