import React, { useState, useEffect } from "react";
import {
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
  Divider,
  LinearProgress,
  Typography,
  Switch,
  Box,
} from "@mui/material";
import {
  Edit,
  Delete,
  AddCircle,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import Molecule from "../../MComponent/Molecule";
import Atomic from "../../AComponent/Atomic";
import "./pms.css";
import Coaching from "./Coaching";
import SelfDevelopment from "./SelfDevelopment";
import TrainingNeeds from "./TrainingNeeds";
import Confidential from "./Confidential";
import Dimension from "./Dimension/Dimension";
import { MPP } from "./Dimension/Data/MPP";
import Summary from "./Summary/Summary";

const { Progress, Input, Button, Dropdown, EwSwitch } = Atomic;
const { Tabs, Stepper, EwModal } = Molecule;

// const goalData = {
//   mapStructure: "EED",
//   discardRule: "PPP",
//   addRule: "PPP",
//   Group2: [
//     {
//       name: "G2-A",
//       minWeight: 10,
//       maxWeight: 15,
//       id: "g2a",
//     },
//     {
//       name: "G2-B",
//       minWeight: 10,
//       maxWeight: 15,
//       id: "g2b",
//     },
//     {
//       name: "G2-C",
//       minWeight: 10,
//       maxWeight: 15,
//       id: "g2c",
//     },
//     {
//       name: "G2-C",
//       minWeight: 10,
//       maxWeight: 15,
//       id: "g2c",
//     },
//     {
//       name: "G2-C",
//       minWeight: 10,
//       maxWeight: 15,
//       id: "g2c",
//     },
//   ],
//   Group1: [
//     {
//       name: "G1-A",
//       minWeight: 10,
//       maxWeight: 15,
//       group2Id: "g2a",
//       id: "g1a",
//     },
//     {
//       name: "G1-B",
//       minWeight: 10,
//       maxWeight: 15,
//       group2Id: "g2a",
//       id: "g1b",
//     },
//     {
//       name: "G1-C",
//       minWeight: 10,
//       maxWeight: 15,
//       group2Id: "g2a",
//       id: "g1c",
//     },
//   ],
//   parameter: [
//     {
//       name: "P1",
//       weight: 0,
//       group1Id: "g1a",
//       id: "p1",
//     },
//     {
//       name: "P2",
//       weight: 0,
//       group1Id: "g1b",
//       id: "p2",
//     },
//   ],
// };

export default function PrevPMS() {
  const [modal, setModal] = useState(null);
  const [collapse, setCollapse] = useState({});
  const [totalWeight, setTotalWeight] = useState(0);
  const [listView, setListView] = useState(false);
  const [idToWeightMap, setIdToWeightMap] = useState({});
  const [goalState, setGoalState] = useState({
    total: 0,
    step: 0,
    G1: {
      active: false,
      selected: null,
    },
    G2: {
      active: true,
      selected: null,
    },
    parameter: {
      active: false,
      selected: null,
    },
    data: {
      mapStructure: "EEE",
      discardRule: "PPP",
      addRule: "PPP",
      weightRule: "NNU",
      Group2: [
        {
          name: "G2-A",
          minWeight: 30,
          maxWeight: 35,
          id: "g2a",
        },
        {
          name: "G2-B",
          minWeight: 10,
          maxWeight: 15,
          id: "g2b",
        },
        {
          name: "G2-C",
          minWeight: 10,
          maxWeight: 15,
          id: "g2c",
        },
        {
          name: "G2-D",
          minWeight: 10,
          maxWeight: 15,
          id: "g2d",
        },
        {
          name: "G2-E",
          minWeight: 10,
          maxWeight: 15,
          id: "g2e",
        },
      ],
      Group1: [
        {
          name: "G1-A",
          minWeight: 10,
          maxWeight: 15,
          group2Id: "g2a",
          id: "g1a",
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        {
          name: "G1-B",
          minWeight: 10,
          maxWeight: 15,
          group2Id: "g2a",
          id: "g1b",
        },
        {
          name: "G1-C",
          minWeight: 10,
          maxWeight: 15,
          group2Id: "g2a",
          id: "g1c",
        },
      ],
      parameter: [
        {
          name: "P1",
          weight: 0,
          group1Id: "g1a",
          id: "p1",
        },
        {
          name: "P2",
          weight: 0,
          group1Id: "g1b",
          id: "p2",
        },
        {
          name: "P3",
          weight: 0,
          group1Id: "g1a",
          id: "p3",
        },
        {
          name: "P4",
          weight: 0,
          group1Id: "g1a",
          id: "p4",
        },
      ],
    },
  });
  const [steps, setSteps] = useState(["Set Targets", "Weightages"]);

  function renderSummary() {
    return <></>;
  }
  function ParamList() {
    return (
      <>
        <div style={{ width: 300 }}>
          <div className="Flex">
            <h1>Weights</h1>
          </div>
          <hr />
          <div
            className="scroll"
            style={{ height: 350, overflowY: "auto", overflowX: "hidden" }}
          >
            {goalState.data.parameter.map((ele) => (
              <div
                className="Flex-row"
                style={{ justifyContent: "space-around" }}
              >
                <div>
                  <h3>{ele.name}</h3>
                </div>

                <div>
                  <Progress
                    label={" "}
                    value={idToWeightMap[ele.id]?.weight}
                    style={{
                      color:
                        ele.weight < 25
                          ? "red"
                          : ele.weight < 75
                          ? "blue"
                          : "green",
                    }}
                  />
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
  function Lists(props) {
    const { type, rule, parentRule } = props;

    const [operators, setOperators] = useState({
      delete: false,
      add: false,
    });
    const [items, setItems] = useState([]);
    useEffect(() => {
      let item = [];
      let goalData = goalState.data;
      const [g2Discard, g1Discard, pDiscard] = goalData.discardRule.split("");
      const [g1Add, g2Add, pAdd] = goalData.addRule.split("");
      let active = parentRule === "D";
      let { G1, G2, parameter } = goalState;
      let discard = false;
      let add = false;
      if (type === "G2") {
        item = goalData.Group2;
        discard = g2Discard === "P" || g2Discard === "N";
        add = g2Add === "P" || g2Add === "N";
      } else if (type === "G1") {
        let g2Selected = goalState.G2.selected;
        G1.active = active;
        item =
          parentRule === "D"
            ? goalData.Group1
            : goalData.Group1.filter((item) => item.group2Id === g2Selected);
        discard = g1Discard === "P" || g1Discard === "N";
        add = g1Add === "P" || g1Add === "N";
        console.log("Form G1 ", item);
      } else {
        let g1Selected = goalState.G1.selected;
        parameter.active = active;
        item =
          parentRule === "D"
            ? goalData.parameter
            : goalData.parameter.filter((item) => item.group1Id === g1Selected);
        discard = pDiscard === "P" || pDiscard === "N";
        add = pAdd === "P" || pAdd === "N";
        console.log(
          "Form para ",
          item,
          " ",
          goalState[type].active,
          " ",
          parentRule
        );
      }
      setItems([...item]);
      setOperators({ delete: discard, add });
    }, []);
    function optionSelected({ id }, e) {
      console.log(id, "aaa ");
      // if (![...document.getElementsByClassName("paper")].includes(e.target))
      //   return;
      let { G1, G2, parameter } = goalState;
      if (type === "G1") {
        G1.selected = id;
        parameter.active = true;
        setGoalState((prev) => ({
          ...prev,
          G1,
          G2,
          parameter,
        }));
      } else if (type === "G2") {
        G2.selected = id;
        G1.active = true;
        G1.selected = null;
        parameter.active = false;

        setGoalState((prev) => ({
          ...prev,
          G1,
          G2,
          parameter,
        }));
      } else {
        let wt = 0;
        let arr = goalState.data.parameter;
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].id === id) {
            wt = arr[i].weight;
            break;
          }
        }
        setModal(<TargetScreen id={id} wt={wt} />);
      }
    }
    function addList() {
      setModal(<AddList type={type} />);
    }
    function edit(e) {
      stopEventBuble(e);
    }
    function deleteClick(e) {
      stopEventBuble(e);
    }
    function stopEventBuble(event) {
      event.cancelBubble = true;
      if (event.stopPropagation) event.stopPropagation();
    }
    if ((!goalState[type].active && parentRule === "D") || rule === "D")
      return <></>;

    return (
      <>
        <div
          className="Flex-row"
          style={{ minWidth: 300, justifyContent: "space-around" }}
        >
          <h3>
            {type === "G1" ? "Group 1" : type === "G2" ? "Group 2" : "Paramter"}
          </h3>
          {/* <Progress label={"Weight"} value={50} size={50} /> */}
        </div>
        <br />
        <div className="list-container scroll">
          <div className="Flex ">
            {items.length === 0 && operators.add && <h4>Add Parameters</h4>}
            {items.map((ele) => {
              let heading = ele.name;
              if (ele.minWeight && ele.maxWeight) {
                if (ele.minWeight === ele.maxWeight) {
                  heading += " [" + ele.minWeight + "]";
                } else {
                  heading += " [" + ele.minWeight + "-" + ele.maxWeight + "]";
                }
              }
              //   heading += "--(" + idToWeightMap[ele.id].weight + ")%";
              let currWeight = idToWeightMap[ele.id].weight;
              return (
                <>
                  <div
                    style={{ margin: 0, padding: 0 }}
                    onClick={(e) => optionSelected(ele, e)}
                  >
                    <Paper
                      style={{
                        padding: 10,
                        width: 300,
                        margin: 5,
                        height: currWeight > 0 ? "9vh" : "7vh",
                        cursor: "pointer",
                        overflowY: "auto",
                        resize: ele.description ? "both" : "none",
                        border:
                          ele.id === goalState[type].selected
                            ? "2px solid red"
                            : "none",
                      }}
                    >
                      <div>
                        <div
                          className="Flex-row paper"
                          style={{
                            justifyContent: "space-between",
                            height: "100%",
                          }}
                        >
                          <div>{heading}</div>
                          <div className="Flex-row">
                            {operators.add && (
                              <div onClick={edit}>
                                <Edit
                                  style={{ cursor: "pointer", zIndex: 10 }}
                                />
                              </div>
                            )}
                            {operators.add && operators.delete && (
                              <span> | </span>
                            )}
                            {operators.delete && (
                              <div onClick={deleteClick}>
                                <Delete
                                  style={{ color: "red", cursor: "pointer" }}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        {currWeight !== 0 && (
                          <div
                            className="Flex-row"
                            style={{ justifyContent: "space-between" }}
                          >
                            {" "}
                            <div style={{ width: "100%" }}>
                              <LinearProgress
                                style={{ marginTop: 5 }}
                                variant="determinate"
                                value={currWeight}
                              />
                            </div>
                            <Typography
                              style={{ marginLeft: 10 }}
                              variant="body2"
                              color="text.secondary"
                            >
                              {currWeight + "%"}
                            </Typography>
                          </div>
                        )}
                        <div className="Flex" style={{ margin: 10 }}>
                          {ele.description && (
                            <Typography variant="body2" color="text.secondary">
                              {ele.description}
                            </Typography>
                          )}
                        </div>
                      </div>
                    </Paper>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="Flex">
          {operators.add && (
            <div onClick={addList}>
              {" "}
              <AddCircle
                fontSize="large"
                style={{ cursor: "pointer", color: "#89C4E1" }}
              />
            </div>
          )}
        </div>
      </>
    );
  }
  function CollapseList(props) {
    var { type, ids, parentId, gParentId } = props;
    var nextType;
    var groupId;
    function Arrow({ icon }) {
      console.log("Icon: ", icon);
      if (type === "parameter") return <></>;
      if (icon === true) {
        return <ExpandLess />;
      } else {
        return <ExpandMore />;
      }
    }

    const [g2, g1, pa] = goalState.data.mapStructure.split("");
    if (!type) {
      if (g2 !== "D") {
        type = "Group2";
      } else if (g1 !== "D") {
        type = "Group1";
      } else {
        type = "parameter";
      }
    }
    if (!ids) {
      ids = [];
      if (g2 === "D") ids.push(undefined);
      if (g1 === "D") ids.push(undefined);
    }
    nextType =
      type === "Group2" ? "Group1" : type === "Group1" ? "parameter" : "null";
    console.log("From list ", type);
    if (type === "null") return <></>;
    function btnClicked(id, wt) {
      if (type === "parameter") {
        setModal(
          <TargetScreen
            id={id}
            wt={wt}
            propsFromList={[gParentId, parentId, id]}
          />
        );
      }
    }
    function InnerComp({ ele }) {
      const [coll1, setColl1] = useState(true);
      let coll;
      let pl = 0;
      if (collapse[ele.id] === undefined) coll = true;
      else coll = collapse[ele.id];
      if (type === "Group1") pl = 50;
      if (type === "parameter") pl = 100;

      console.log("Collpase: ", ele.id, " ", coll);
      return (
        <>
          <div className="Flex-row" style={{ marginLeft: pl }}>
            <ListItemButton
              onClick={() => btnClicked(ele.id, ele.weight)}
              sx={{
                backgroundColor: type === "parameter" ? "#F8F4EA" : "white",
              }}
            >
              <ListItemIcon>
                <Progress
                  label=""
                  value={idToWeightMap[ele.id]?.weight}
                  size={30}
                />
              </ListItemIcon>
              <ListItemText primary={ele.name} />
            </ListItemButton>
            <div onClick={() => setColl1(!coll1)} style={{ cursor: "pointer" }}>
              <Arrow icon={coll1} />
            </div>
          </div>
          <Divider variant="inset" component="li" />

          {coll1 && (
            <CollapseList
              type={nextType}
              parentId={ele.id}
              gParentId={parentId}
            />
          )}
          {/* <Collapse in={coll} timeout="auto" unmountOnExit>
            <CollapseList type={nextType} />
          </Collapse> */}
        </>
      );
    }
    return (
      <>
        <List sx={{ maxWidth: 500, width: "100%" }} component="nav">
          {goalState.data[type]?.map((ele) => {
            let groupId =
              type === "Group1"
                ? "group2Id"
                : type === "parameter"
                ? "group1Id"
                : null;
            if (parentId && ele[groupId] !== parentId) return <></>;
            return <InnerComp ele={ele} />;
          })}
        </List>
      </>
    );
  }

  function TargetScreen(props) {
    var { wt, id, propsFromList } = props;
    const [type, setType] = useState(-1);
    const [target, setTarget] = useState("");
    const [weight, setWeight] = useState(idToWeightMap[id].weight);
    const [error, setError] = useState(null);
    //   if(!propsFromList)propsFromList=[];
    let targetType = [
      {
        label: "Small Alpha Numeric",
        value: "ST",
      },
      {
        label: "Large Alpha Numeric",
        value: "LT",
      },
      {
        label: "Integer",
        value: "NI",
      },
      {
        label: "Decimal",
        value: "ND",
      },
      {
        label: "Percentage",
        value: "PC",
      },
    ];
    function validateWeight(
      g2 = goalState.G2.selected,
      g1 = goalState.G1.selected,
      pId = id
    ) {
      let idMap = JSON.parse(JSON.stringify(idToWeightMap));
      /// idMap[pId].weight = 10000;

      let val = Math.ceil(weight);
      let diff = val - idMap[pId].weight;
      var flag = true;
      if (totalWeight + diff > 100) {
        setError("Total Weight Cannot be more than 100%");
        return;
      }
      console.log(
        "Map: ",
        " ",
        " ",
        val,
        " ",
        idMap[g1].weight,
        " ",
        g1,
        " ",
        idMap
      );
      idMap[pId].weight = val;
      let add = totalWeight + diff;

      if (g1) {
        let { weight, minWeight, maxWeight } = idMap[g1];
        //   let diffG1=weight+diff;
        weight += diff;
        console.log("Loggin: ", weight, " ", minWeight, " ", maxWeight);
        if (weight <= maxWeight) {
          idMap[g1].weight = weight;
        } else {
          if (minWeight === maxWeight)
            setError(
              "Group Weight Should be " + minWeight + " but it is " + weight
            );
          else
            setError(
              "Group 1 Weight should be in range of " +
                minWeight +
                " - " +
                maxWeight +
                ", but is : " +
                weight
            );
          flag = false;
          return;
        }
        if (g2) {
          let g2Weight = idMap[g2].weight;
          let g2Min = idMap[g2].minWeight;
          let g2Max = idMap[g2].maxWeight;
          g2Weight += diff;
          if (g2Weight <= g2Max) {
            idMap[g2].weight = g2Weight;
          } else {
            if (g2Min === g2Max)
              setError(
                "Group Weight Should be " + g2Min + " but it is " + g2Weight
              );
            else
              setError(
                "Group 2 Weight should be in range of " +
                  g2Min +
                  " - " +
                  g2Max +
                  ", but is : " +
                  g2Weight
              );
            flag = false;
            return;
          }
        }
      }
      if (flag) {
        console.log("setting...");
        setIdToWeightMap({ ...idMap });
        setTotalWeight(add);
      }
    }
    return (
      <>
        <div className="ewModal">
          <div className="Flex">
            <h1>Target</h1>
          </div>
          <div className="Flex-row">
            <div style={{ width: "30%" }}>
              <Dropdown
                label="Target Type"
                menuItems={targetType}
                value={type}
                onChange={(val) => setType(val)}
              />
            </div>
            {type !== -1 && (
              <div>
                <Input
                  label="Enter Target"
                  value={target}
                  onChange={setTarget}
                />
              </div>
            )}
          </div>
          <hr />
          {goalState.data.weightRule.charAt(2) === "U" && (
            <div className="Flex">
              {" "}
              <h1>Weights</h1>
              <div>
                <Input
                  label="Enter Weight"
                  //  defaultValue={idToWeightMap[id].weight}
                  value={weight}
                  onChange={(val) => {
                    setError(null);
                    setWeight(parseFloat(val));
                  }}
                  onBlur={() =>
                    propsFromList
                      ? validateWeight(...propsFromList)
                      : validateWeight()
                  }
                  error={error}
                  type="number"
                />
              </div>
            </div>
          )}
          <br />

          <div className="Flex">
            <Button title="Save" onClick={() => {}} disable={type === -1} />
          </div>
        </div>
      </>
    );
  }
  function AddList(props) {
    const { type } = props;
    const [groupName, setGroupName] = useState("");
    const [groupShortName, setGroupShortName] = useState("");
    const [pShortName, setPShortName] = useState("");
    const [pDescription, setDescription] = useState("");
    const [uom, setUom] = useState("");
    function save() {
      if (type === "parameter") {
      } else {
        if (!groupName || groupName.trim(" ") === "") return;
        let data = goalState.data;
        if (type === "G1") data.Group1.push({});
      }
    }
    if (type === "parameter")
      return (
        <>
          <div className="ewModal">
            <div className="Flex">
              <h1>Add Parameter</h1>
              <hr />
            </div>
            <div className="Flex-row">
              <div>
                <Input
                  value={pShortName}
                  onChange={setPShortName}
                  label="Short Name"
                />
              </div>
              <div>
                <Input
                  value={pDescription}
                  onChange={setDescription}
                  label="Description"
                />
              </div>
            </div>
            <div className="Flex">
              <Input value={uom} onChange={setUom} label="Enter UOM" />
              <Button title={"Save"} onClick={() => {}} />
            </div>
          </div>
        </>
      );

    return (
      <>
        <div className="ewModal">
          <div className="Flex">
            <h1>Create New Group</h1>
          </div>
          <div className="Flex-row">
            <div>
              <Input
                value={groupName}
                onChange={setGroupName}
                label="Group Name"
              />
            </div>
            <div>
              <Input
                value={groupShortName}
                onChange={setGroupShortName}
                label="Group Short Name"
              />
            </div>
          </div>
          <div className="Flex">
            <Button title="Save" onClick={() => {}} />
          </div>
        </div>
      </>
    );
  }

  function Goals() {
    const [g2, g1, pa] = goalState.data.mapStructure.split("");
    useEffect(() => {
      if (Object.keys(idToWeightMap).length === 0) {
        let obj = {};
        const { Group1, Group2, parameter } = goalState.data;
        let arr = [...Group1, ...Group2, ...parameter];
        arr?.map((ele) => {
          obj[ele.id] = {
            ...ele,
            minWeight: ele.minWeight ? ele.minWeight : 0,
            maxWeight: ele.maxWeight ? ele.maxWeight : 100,
            weight: ele.weight ? ele.weight : 0,
          };
          if (ele.weight > 0) {
            if (ele.group1Id && obj[ele.group1Id]) {
              obj[ele.group1Id].weight = obj[ele.group1Id].weight + ele.weight;
              let g2Id = obj[ele.group1Id].group2Id;
              if (g2Id && obj[g2Id]) {
                obj[g2Id].weight = obj[g2Id].weight + ele.weight;
              }
            }
          }
        });

        // console.log("called ",  goalState.data.parameter);
        setIdToWeightMap({ ...obj });
        let total = goalState.data.parameter.reduce((a, b) => a + b.weight, 0);
        console.log("called ", total);

        setTotalWeight(total);
      }
    }, []);
    return (
      <>
        <div style={{ minWidth: 500 }}>
          {/* <Stepper steps={steps} activeStep={goalState.step} /> */}
          <div className="Flex" style={{ alignItems: "flex-end" }}>
            <EwSwitch
              label={"List View"}
              value={listView}
              onChange={setListView}
            />
          </div>
          <div
            className="Flex-row"
            style={{ margin: 20, alignItems: "flex-start" }}
          >
            {!listView ? (
              <>
                <div>
                  <Lists type="G2" rule={g2} parentRule={"NA"} />
                </div>
                <div>
                  <Lists type="G1" rule={g1} parentRule={g2} />
                </div>
                <div>
                  <Lists type="parameter" rule={pa} parentRule={g1} />
                </div>
              </>
            ) : (
              <div style={{ width: 500 }} className="list-container scroll">
                <CollapseList />
              </div>
            )}

            <div
              onClick={() => {
                setModal(<ParamList />);
              }}
              style={{ alignSelf: "center", cursor: "pointer" }}
            >
              <Progress label={"Total Weight"} value={totalWeight} />
            </div>
          </div>

          <div className="Flex">
            <Button title={"Next"} onClick={() => {}} />
          </div>
        </div>
        <div className="Flex "></div>
      </>
    );
  }
  let compList = [
    {
      label: "Summary",
      Component: Summary,
      props: {
        appraiseeSnapshot: true,
        dimensionScore: true,
        leaveAndAttendance: true,
        planAggregateScore: true,
      },
    },
    {
      label: MPP.dimensions[0].dimensionName,
      Component: Dimension,
      props: {
        dimensionId: MPP.dimensions[0].dimensionId,
        dimensionName: MPP.dimensions[0].dimensionName,
        dimensionDescription: MPP.dimensions[0].dimensionDescription,
        dimensionStructure:
          MPP.dimensions[0].dimensionParameters.dimensionStructure,
        discardRule: MPP.dimensions[0].dimensionParameters.discardRule,
        addRule: MPP.dimensions[0].dimensionParameters.addRule,
        weightRule: MPP.dimensions[0].dimensionParameters.weightRule,
      },
    },
    {
      label: "Coaching",
      Component: Coaching,
    },
    {
      label: "Self Development",
      Component: SelfDevelopment,
    },
    {
      label: "Training Needs",
      Component: TrainingNeeds,
    },
    {
      label: "Confidential",
      Component: Confidential,
    },
  ];
  return (
    <>
      <EwModal
        open={modal !== null ? true : false}
        onClose={() => setModal(null)}
      >
        {modal}
      </EwModal>
      <div className="Flex">
        <Box sx={{ width: "100%" }}>
          <Paper style={{ padding: 20, margin: "0 5em" }}>
            <Tabs componentList={compList} />
          </Paper>
        </Box>
      </div>
    </>
  );
}

/* 
  function Groups() {
    const [steps, setSteps] = useState([
      "Define Kpi",
      "Set Targets",
      "Weightages",
    ]);
    const [modal, setModal] = useState({
      open: false,
      children: null,
    });
    const [selectedGroup1, setSelectedGroup1] = useState([]);
    const [selectedGroup2, setSelectedGroup2] = useState([]);
    const [group1, setGroup1] = useState([{ Component: Custom }, "Test"]);
    const [group2, setGroup2] = useState([{ Component: Custom }, "Group2"]);
    const [activeStep, setActiveStep] = useState(0);

    const [inp, setInp] = useState("");
    function onChange(val) {
      console.log("value ", val, " ", inp);
      setInp(val);
    }
    function Custom() {
      function openModal() {
        setModal({ open: true });
      }
      return (
        <>
          <MenuItem onClick={openModal}>Custom</MenuItem>
        </>
      );
    }

    function Target() {
      const [option, setOption] = useState(null);
      const [option2, setOption2] = useState(null);

      return (
        <>
          <div className="Flex-row" style={{ justifyContent: "flex-start"}}>
            <div
              className="Flex scroll"
              style={{
                alignItems: "flex-start",
                height: 200,
                overflowY: "scroll",
                width: 150,
              //  justifyContent: "flex-start",
              marginRight:10
              }}
            >
              {selectedGroup1?.map((ele) => (
                <MenuItem
                  onClick={() => setOption(ele)}
                  style={{
                    minWidth: 100,

                    scrollPadding: 20,
                  }}
                >
                  {ele}
                </MenuItem>
              ))}
            </div>
            <br />
            {option && (
              <div
                className="Flex scroll"
                style={{
                  alignItems: "flex-start",
                  height: 200,
                  overflowY: "scroll",
                  width: 150,
                  marginRight:10
                 // justifyContent: "flex-start",
                }}
              >
                {selectedGroup2?.map((ele) => (
                  <MenuItem
                  onClick={()=>setOption2(ele)}
                    style={{
                      minWidth: 100,
                      scrollPadding: 20,
                    }}
                  >
                    {ele}
                    
                  </MenuItem>
                ))}
              </div>
            )}
            <br />
            {option && option2 && (
              <>
                <Paper style={{ padding: 10, width: 700, height: 500 }}>
                  <div className="Flex">
                    <h3>Targets</h3>
                  </div>
                </Paper>
              </>
            )}
          </div>
        </>
      );
    }

    return (
      <>
        <EwModal
          open={modal.open}
          onClose={() => setModal({ open: false, children: null })}
        >
          <>
            <div className="Flex">
              <Input label="Custom Group" value={inp} onChange={onChange} />
              <br />
              <Button
                title="Add"
                onClick={() => {
                  let arr = group1;
                  arr.push(inp);
                  console.log("Group Changed: arr", arr);
                  setGroup1([...arr]);
                  setInp("");
                  setModal({ open: false, children: null });
                }}
              />
            </div>
          </>
        </EwModal>

        <div style={{ minWidth: 500 }}>
          <Stepper steps={steps} activeStep={activeStep} />
          <br />
          {activeStep > 0 && (
            <Link
              onClick={() => setActiveStep(activeStep - 1)}
              underline="none"
            >
              {"< Back"}
            </Link>
          )}
          {activeStep === 0 && (
            <div className="Flex-row">
              <MultiSelect
                value={selectedGroup1}
                onChange={setSelectedGroup1}
                menuItems={group1}
                label="Group1"
              />
              {selectedGroup1.length > 0 && (
                <MultiSelect
                  value={selectedGroup2}
                  onChange={setSelectedGroup2}
                  menuItems={group2}
                  label="Group2"
                />
              )}
            </div>
          )}
          {activeStep === 1 && (
            <>
              <Target />
            </>
          )}
          <br />
          <div className="Flex">
          <Button title="Next" onClick={() => setActiveStep(1)} />
          </div>
        </div>
      </>
    );
  }
  function Goals() {
    return (
      <>
        <Groups />
      </>
    );
  }

*/
