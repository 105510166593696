import { Actions } from '../Actions/Actiontypes'

const initialState = {
  ComponentState: [],
  FeatureJSON:{},
}

function WorkspaceReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.PAGE_STATE: {
      let ComponentState = state.ComponentState.push(action.payload);
      return {
        ...state,
        ComponentState,
      }
    }
    case Actions.RESET: {
      return {
        ...state,
        ComponentState: [],
      }
    }
    
  }
}

export default WorkspaceReducer;