import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import useProps from "../../Utils/CustomHooks/GetProps";
const initialProps = {
  activeStep: 1,
  steps: [],
  onClick:null
};

export default function EwStepper(props = initialProps) {
  let { activeStep, steps, onClick } = useProps(props);
  function onStepperClick(index) {
    if (onClick) onClick(index);
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper className="pointer" activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step
            onClick={() => onStepperClick(index)}
            className="pointer"
            key={label}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
