import {useSelector,useDispatch} from "react-redux"


function useRender({id,compNumber}){

   const {Group,renderNot}= useSelector((state) =>
    JSON.parse(JSON.stringify(state.DataReducer[id][compNumber])),
  )
  const enabledGroup=useSelector((state)=>state.DataReducer[id].enabledGroups);
  const dispatch=useDispatch()

  if(enabledGroup && Group && !enabledGroup.includes(Group))return false
  if(!Group && enabledGroup && !enabledGroup.includes('Default'))return false
  if (renderNot) return false
  return true
}

export default useRender