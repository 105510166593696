import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Atomic from "../../../AComponent/Atomic";
import Molecule from "../../../MComponent/Molecule";
import store from "../../../Redux/Store";
import { Typography } from "@mui/material";
import WorkflowButton from "../../Workflow/WorkflowButton";
import useWindowDimensions from "../../../Utils/CustomHooks/WindowRezise";
import ConfirmationButton from "../../../AComponent/Buttons/ConfirmationButton";
import EditIcon from "@mui/icons-material/Edit";
import FeatureIDs from "../../../Utils/Common/FeatureIDs";
const { Dropdown, Input, EwSwitch, Radio, Button } = Atomic;
const { Table, EwModal } = Molecule;
const RatingScaleID = "19148";
const ActivityTypeOptions = [
  {
    label: "Parameter Target setting",
    value: "PT",
  },
  {
    label: "Target Revision",
    value: "TR",
  },
  {
    label: "Periodic Assessment",
    value: "PR",
  },
  {
    label: "Interim Review",
    value: "IR",
  },
  {
    label: "Final Review",
    value: "FR",
  },
];
const { ParameterAndTargets, ParameterAsseessment } = FeatureIDs;
const activityTypeToIDmapper = {
  PT: ParameterAndTargets,
  TR: ParameterAndTargets,
  PR: ParameterAsseessment,
  IR: ParameterAsseessment,
  FR: ParameterAsseessment,
};
const activityNameMapper = {
  PT: "Parameter Target setting",
  TR: "Target Revision",
  PR: "Periodic Assessment",
  IR: "Interim Review",
  FR: "Final Review",
};
const ActivityFrequencyOptions = [
  {
    label: "Plan Start",
    value: "PS",
  },
  {
    label: "Plan End",
    value: "PE",
  },
  {
    label: "Monthly",
    value: "MO",
  },
  {
    label: "Quarterly",
    value: "QT",
  },
  {
    label: "Half yearly",
    value: "HA",
  },
];
const ActivityTriggeringEvents = [
  {
    label: "The Master Performance Plan applicable to an appraisee changes",
    value: "ActivityTriggerMP",
  },
  {
    label: "Parameter Map Changed",
    value: "ActivityTriggerPM",
  },
  {
    label: "Role Change",
    value: "ActivityTriggerER",
  },
  {
    label: "Location Change",
    value: "ActivityTriggerEL",
  },
  {
    label: "Function Change",
    value: "ActivityTriggerEF",
  },
  {
    label: "Line Manager Change",
    value: "ActivityTriggerELM",
  },
  {
    label: "Functional Manager Change",
    value: "ActivityTriggerEFM",
  },
  {
    label: "The appraiser in an employee plan changes",
    value: "ActivityTriggerAC",
  },
  {
    label: "Appraiser Separation",
    value: "ActivityTriggerAS",
  },
];
const ActivityInitiationOptions = [
  {
    label: "Initiated by a designated user",
    value: "UI",
  },
  {
    label: "Atomatic",
    value: "AS",
  },
];

function Dimension2({ id, name }) {
  const DimObject = useSelector((state) => {
    if (!state.DataReducer[id]) return {};
    return state.DataReducer[id];
  });
  const {
    DimensionName,
    enabled,
    AggregateScoreRatingScaleID,
    PlanDimensionWeight,
    TargetEditing,
    DimensionAggregateScore,
    AggregationRating,
    ScoreRounding,
    RoundingValue,
    dimList,
    ParameterRevisions,
    MinimumRevisionPeriod,
    DimensionActivities,
  } = DimObject;
  const [Modal, setModal] = useState(null);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  function updateState(val, type) {
    if (type === "enabled") {
      let data = store.getState().DataReducer?.Mpp;
      if (!data) data = {};
      let arr = data.enabledDim;
      if (!arr) arr = [];
      if (val === "E") {
        arr.push({
          id,
          name,
        });
      } else {
        /// arr = arr.filter((item) => item.id !== id);
      }
      dispatch({
        type: "UPDATE_PROPS",
        payload: {
          id: "Mpp",
          componentNumber: "enabledDim",
          data: arr,
        },
      });
    }
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: type,
        data: val,
      },
    });
  }
  function ActivityForm({ index }) {
    const [state, setState] = useState(
      (DimensionActivities && DimensionActivities[index]) || {}
    );
    const {
      ActivityType,
      ActivityFrequency,
      ActivityInitiation,
      ActivityInitiationScheduledDays,
      ActivityWorkflow,
    } = state;
    function updateStateActivity(val, type) {
      if (type === "ActivityType" && val === "IR") {
        updateStateActivity("ET", "ActivityInitiation");
        updateStateActivity("", "ActivityInitiationScheduledDays");
      } else if (type === "ActivityType") {
        updateStateActivity(undefined, "ActivityInitiation");
      }
      setState((prev) => ({ ...prev, [type]: val }));
    }
    function onSave() {
      if (!ActivityType || !ActivityFrequency || !ActivityInitiation) {
        return;
      }
      for (let i = 0; i < ActivityTriggeringEvents.length; i++) {
        if (
          state[ActivityTriggeringEvents[i].value] !== "E" ||
          ActivityInitiation !== "ET"
        ) {
          state[ActivityTriggeringEvents[i].value] = "D";
        }
      }
      let arr = DimensionActivities || [];
      console.log("Indesx is: ", state);
      if (index !== undefined && index !== -1)
        arr[index] = { ...arr[index], ...state };
      else arr.push(state);
      console.log("Indesx is: ", arr);
      updateState(arr, "DimensionActivities");
      setModal(null);
    }
    return (
      <div className="Flex" style={{ height: "100%", width: "100%" }}>
        <br />

        <div className="Flex-row g20" style={{ width: "100%" }}>
          <div style={{ width: "50%" }}>
            <Dropdown
              menuItems={ActivityTypeOptions}
              value={ActivityType}
              onChange={(val) => updateStateActivity(val, "ActivityType")}
              label="Activity Type"
            />
          </div>
          <div style={{ width: "50%" }}>
            <Dropdown
              menuItems={ActivityFrequencyOptions}
              value={ActivityFrequency}
              onChange={(val) => updateStateActivity(val, "ActivityFrequency")}
              label="Activity Frequency"
            />
          </div>
        </div>
        <br />
        {ActivityType && ActivityType !== "IR" && (
          <div className="Flex-row g20" style={{ width: "100%" }}>
            <div style={{ width: "50%" }}>
              <Dropdown
                menuItems={ActivityInitiationOptions}
                value={ActivityInitiation}
                onChange={(val) =>
                  updateStateActivity(val, "ActivityInitiation")
                }
                label="Activity Type"
              />
            </div>
            <div style={{ width: "50%" }}>
              <Input
                label="Activity Initiation Scheduled Days"
                type={"number"}
                value={ActivityInitiationScheduledDays}
                onChange={(val) =>
                  updateStateActivity(val, "ActivityInitiationScheduledDays")
                }
              />
            </div>
          </div>
        )}
        <br />
        {ActivityInitiation === "ET" && (
          <div className="Flex">
            <div>
              {ActivityTriggeringEvents.map(({ label, value }) => (
                <EwSwitch
                  label={label}
                  value={state[value] === "E"}
                  onChange={(val) =>
                    updateStateActivity(val ? "E" : "D", value)
                  }
                />
              ))}
            </div>
          </div>
        )}
        <br />
        {ActivityType && (
          <div className="Flex">
            <WorkflowButton
              AuthorFeatureID={activityTypeToIDmapper[ActivityType]}
              value={ActivityWorkflow}
              onChange={(val) => updateStateActivity(val, "ActivityWorkflow")}
              config={{
                stepOwnerLabelOptions: [
                  "Appraisee",
                  "Appraiser",
                  "Reviewer",
                  "Moderator",
                  "Multi-Rater",
                ],
              }}
            />
          </div>
        )}
        <br />
        <div className="Flex">
          <Button title="Save" onClick={onSave} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <EwModal open={Modal ? true : false} onClose={() => setModal(null)}>
        <div className="scroll" style={{ width: 0.7 * width, minHeight: 300 }}>
          {Modal}
        </div>
      </EwModal>
      <div className="Flex">
        <EwSwitch
          label={name}
          value={enabled === "E"}
          onChange={(val) => updateState(val ? "E" : "D", "enabled")}
        />
      </div>
      {enabled === "E" && (
        <>
          <div className="Flex-row g20">
            <div style={{ width: "40%" }}>
              <Dropdown
                label={"Aggregate Score Rating Scales"}
                featureId={RatingScaleID}
                value={AggregateScoreRatingScaleID}
                onChange={(val) =>
                  updateState(val, "AggregateScoreRatingScaleID")
                }
                mapper={{
                  label: "RatingScaleName",
                  value: "TransactionID",
                }}
                apiResult={dimList}
                storeApiResult={(val) => updateState(val, "dimList")}
              />
            </div>
            <div>
              <Radio
                label="Target Editing By User"
                menuItems={[
                  { label: "Restricted", value: "R" },
                  { label: "Permitted ", value: "P" },
                ]}
                value={TargetEditing}
                onChange={(val) => updateState(val, "TargetEditing")}
              />
            </div>
          </div>
          <br />
          <div className="Flex"></div>
          <br />
          <div className="Flex">
            <EwSwitch
              label="Dimension Aggregate Score"
              value={DimensionAggregateScore === "E"}
              onChange={(val) =>
                updateState(val ? "E" : "D", "DimensionAggregateScore")
              }
            />
          </div>
          <br />
          {DimensionAggregateScore === "E" && (
            <div>
              <div className="Flex-row">
                <div>
                  <Input
                    label={"Dimension Weight"}
                    value={PlanDimensionWeight}
                    onChange={(val) => updateState(val, "PlanDimensionWeight")}
                    type="number"
                    placeholder="%"
                  />
                </div>
                <div>
                  <Dropdown
                    label="Aggregation Rating"
                    menuItems={[
                      { label: "Appraiser", value: "AR" },
                      { label: "Reviewer", value: "RR" },
                      { label: "Appraisee", value: "AE" },
                      { label: "Average", value: "AV" },
                    ]}
                    value={AggregationRating}
                    onChange={(val) => updateState(val, "AggregationRating")}
                  />
                </div>
              </div>
              <br />
              <div className="Flex-row">
                <div>
                  <Dropdown
                    label={"Score Rounding"}
                    menuItems={[
                      { label: "Round off to 1 decimal place", value: "RO" },
                      { label: "Round up to the nearest value", value: "RU" },
                      { label: "Round-down to the nearest value", value: "RD" },
                    ]}
                    value={ScoreRounding}
                    onChange={(val) => updateState(val, "ScoreRounding")}
                  />
                </div>
                {ScoreRounding === "RO" && (
                  <div>
                    <Input
                      label={"Rounding Value"}
                      type="number"
                      value={RoundingValue}
                      onChange={(val) => updateState(val, "RoundingValue")}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <br />
          <Typography variant="h3">Dimension Activities</Typography>
          <hr />
          <br />
          <div style={{ padding: 10, marginRight: 10 }}>
            <Table
              removeSearch
              heading={["Activity Type", "Edit", "Delete"]}
              rows={
                DimensionActivities
                  ? [
                      ...DimensionActivities.map(({ ActivityType }, index) => [
                        activityNameMapper[ActivityType],
                        <div
                          className="pointer"
                          onClick={() =>
                            setModal(<ActivityForm index={index} />)
                          }
                        >
                          <EditIcon color="primary" />
                        </div>,
                        <ConfirmationButton
                          label={"Delete"}
                          message={"Are sure you want to delete this activity?"}
                          onClick={() => {
                            let arr = DimensionActivities;
                            arr.splice(index, 1);
                            updateState(arr, "DimensionActivities");
                          }}
                        />,
                      ]),
                    ]
                  : []
              }
            />
          </div>
          <div className="Flex">
            <Button
              title="Add Activity"
              onClick={() => {
                setModal(<ActivityForm index={-1} />);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Dimension2;
