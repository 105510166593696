import React, { useState } from "react";
import { isEnabled } from "./Dimension/Helpers/helper";
import Atomic from "../../AComponent/Atomic";
import Molecule from "../../MComponent/Molecule";
import { IconButton, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { v4 as uuid } from "uuid";
import DeleteAlert from "./Dimension/Modals/DeleteAlert";
import { useDispatch, useSelector } from "react-redux";

const { Button, Dropdown, Input } = Atomic;
const { Table, EwModal } = Molecule;

const DimensionFeatureId = "19144";
const GroupsFeatureId = "19145";
const ParameterFeatureId = "19146";
const REDUX_ID = "PMS";

function TitleGenerator(key) {
  const result = key.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

function getColumnType(col) {
  if (
    col.toLowerCase().includes("dimension") ||
    col.toLowerCase().includes("secondgroup") ||
    col.toLowerCase().includes("firstgroup") ||
    col.toLowerCase().includes("parameter")
  )
    return "dropdown";
  if (
    col.toLowerCase().includes("area") ||
    col.toLowerCase().includes("plan") ||
    col.toLowerCase().includes("milestone") ||
    col.toLowerCase().includes("actionupdate")
  )
    return "input-text";
  if (col.toLowerCase().includes("duedate")) return "input-date";
  if (col.toLowerCase().includes("progress")) return "input-number";
}

function DimensionDropdown({ onChangeHandler, value }) {
  return (
    <Dropdown
      featureId={DimensionFeatureId}
      label={"Dimension"}
      mapper={{ label: "DimensionName", value: "DimensionName" }}
      value={value}
      onChange={(value) => {
        onChangeHandler(value);
      }}
    />
  );
}

function SecondGroupsDropdown({ DimensionID, value, onChangeHandler }) {
  return (
    <Dropdown
      featureId={GroupsFeatureId}
      label={"Second Group"}
      mapper={{ label: "GroupName", value: "GroupName" }}
      filter={(item) => item.GroupLevel === "2"}
      value={value}
      onChange={(value) => {
        onChangeHandler(value);
      }}
    />
  );
}

function FirstGroupsDropdown({ SecondGroupID, value, onChangeHandler }) {
  return (
    <Dropdown
      featureId={GroupsFeatureId}
      label={"First Group"}
      mapper={{ label: "GroupName", value: "GroupName" }}
      filter={(item) => item.GroupLevel === "1"}
      value={value}
      onChange={(value) => {
        onChangeHandler(value);
      }}
    />
  );
}

function ParameterDropdown({ ParameterGroupID, value, onChangeHandler }) {
  return (
    <Dropdown
      featureId={ParameterFeatureId}
      label={"Parameter"}
      mapper={{ label: "ParameterName", value: "ParameterName" }}
      value={value}
      onChange={(value) => {
        onChangeHandler(value);
      }}
    />
  );
}

function InputBox({ label, type, value, onChangeHandler, onBlurHandler }) {
  const [defaultValue, setDefaultValue] = useState(value);
  return (
    <Input
      label={label}
      value={onChangeHandler ? value : defaultValue}
      onBlur={onBlurHandler ? onBlurHandler : () => {}}
      onChange={(val) => {
        onChangeHandler ? onChangeHandler(val) : setDefaultValue(val);
      }}
      type={type}
      styles={{ margin: 0, width: "100%" }}
    />
  );
}

function EditBox({ onClick }) {
  return (
    <IconButton onClick={onClick}>
      <Edit />
    </IconButton>
  );
}

function DeleteBox({ onClick }) {
  return (
    <IconButton onClick={onClick}>
      <Delete color="red" />
    </IconButton>
  );
}

function empty(object) {
  const keys = Object.entries(object).map(([key, value]) => key);
  const objects = keys.map((key) => ({ key, value: "" }));
  return objects.reduce(
    (obj, item) => Object.assign(obj, { [item.key]: item.value }),
    {}
  );
}

function RowForm({ inputs, initData, onSave, DataColumns, SectionID }) {
  const [inputRowData, setInputRowData] = useState(
    initData || { ...empty(DataColumns) }
  );

  function onChangeHandler(value, type) {
    setInputRowData((prevState) => ({ ...prevState, [type]: value }));
  }

  return (
    <div
      className="Flex scroll"
      style={{ gap: "2em", width: "40vw", maxHeight: "70vh", padding: "1em 0" }}
    >
      <div>
        <Typography variant="h5">Add row</Typography>
      </div>
      {inputs.map((input) => {
        if (input.Component) {
          return (
            <div key={input.props.InputKey} style={{ width: "70%" }}>
              <input.Component
                {...input.props}
                value={inputRowData[input.props.InputKey]}
                onChangeHandler={(value) => {
                  onChangeHandler(value, input.props.InputKey);
                  onChangeHandler(
                    input.props.InputKey,
                    `${input.props.Column}Label`
                  );
                  onChangeHandler(value, `${input.props.Column}Value`);
                }}
              />
            </div>
          );
        } else {
          return (
            <div key={input.props.InputKey}>
              <Typography variant="overline">{input}</Typography>
            </div>
          );
        }
      })}
      <Button
        title={"Save"}
        onClick={() => {
          onSave(inputRowData);
        }}
      />
    </div>
  );
}

function AgendaTable({ SectionID, DataColumns, DataRows, onSaveRow }) {
  const [modal, setModal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const { [SectionID]: rows } = useSelector((state) => {
    if (!state.DataReducer[REDUX_ID] || !state.DataReducer[REDUX_ID][SectionID])
      return { [SectionID]: [] };
    return JSON.parse(JSON.stringify(state.DataReducer[REDUX_ID]));
  });
  const dispatch = useDispatch();

  function updateState(value, type) {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id: REDUX_ID,
        componentNumber: type,
        data: value,
      },
    });
  }

  function onSave(row) {
    if (row.ID) {
      const newState = [...rows];
      const index = newState.findIndex((state) => state.ID === row.ID);
      newState[index] = { ...row };
      onSaveRow && onSaveRow(newState);
      updateState(newState, SectionID);
    } else {
      updateState([...rows, { ...row, ID: uuid() }], SectionID);
      onSaveRow && onSaveRow([...rows, { ...row, ID: uuid() }]);
    }
    setModalOpen(false);
  }

  const Heading = [
    ...Object.entries(DataColumns)
      .map(([key, value]) => {
        if (isEnabled(value)) return TitleGenerator(key).trim();
        else return undefined;
      })
      .filter((key) => typeof key !== "undefined"),
    "Edit",
    "Delete",
  ];

  function generateInputRow() {
    let count = 0;
    return [
      ...Object.entries(DataColumns)
        .map(([key, value], index) => {
          if (isEnabled(value)) {
            if (key === "Dimension") {
              count = count + 1;
              return {
                Component: DimensionDropdown,
                props: {
                  InputKey: key,
                  Column: `Column${count}`,
                },
              };
            } else if (key === "SecondGroups") {
              count = count + 1;
              return {
                Component: SecondGroupsDropdown,
                props: {
                  InputKey: key,
                  Column: `Column${count}`,
                },
              };
            } else if (key === "FirstGroups") {
              count = count + 1;
              return {
                Component: FirstGroupsDropdown,
                props: {
                  InputKey: key,
                  Column: `Column${count}`,
                },
              };
            } else if (key === "Parameter") {
              count = count + 1;
              return {
                Component: ParameterDropdown,
                props: { InputKey: key, Column: `Column${count}` },
              };
            } else if (getColumnType(key) === "input-text") {
              count = count + 1;
              return {
                Component: InputBox,
                props: {
                  label: TitleGenerator(key).trim(),
                  type: "text",
                  InputKey: key,
                  Column: `Column${count}`,
                },
              };
            } else if (getColumnType(key) === "input-number") {
              count = count + 1;
              return {
                Component: InputBox,
                props: {
                  label: TitleGenerator(key).trim(),
                  type: "number",
                  InputKey: key,
                  Column: `Column${count}`,
                },
              };
            } else if (getColumnType(key) === "input-date") {
              count = count + 1;
              return {
                Component: InputBox,
                props: {
                  type: "date",
                  InputKey: key,
                  Column: `Column${count}`,
                },
              };
            }
          }
          return undefined;
        })
        .filter((key) => typeof key !== "undefined"),
    ];
  }

  function generateDataRow(row) {
    return [
      ...Object.entries(DataColumns)
        .map(([key, value]) => {
          if (isEnabled(value)) {
            if (key.toLowerCase().includes("update")) {
              return {
                Component: InputBox,
                props: {
                  label: TitleGenerator(key).trim(),
                  type: "text",
                  value: row[key],
                  onBlurHandler: (value) => {
                    const newState = [...rows];
                    const index = newState.findIndex(
                      (state) => state.ID === row.ID
                    );
                    newState[index][key] = value;
                    updateState(newState, SectionID);
                  },
                },
              };
            } else if (key.toLowerCase().includes("progress")) {
              return {
                Component: InputBox,
                props: {
                  label: TitleGenerator(key).trim(),
                  type: "number",
                  value: row[key],
                  onBlurHandler: (value) => {
                    const newState = [...rows];
                    const index = newState.findIndex(
                      (state) => state.ID === row.ID
                    );
                    newState[index][key] = value;
                    updateState(newState, SectionID);
                  },
                },
              };
            }
            return row[key];
          } else return undefined;
        })
        .filter((key) => typeof key !== "undefined"),
      {
        Component: EditBox,
        props: {
          onClick: () => {
            setModal({
              Component: RowForm,
              props: {
                inputs: generateInputRow(),
                initData: row,
                onSave,
              },
            });
            setModalOpen(true);
          },
        },
      },
      {
        Component: DeleteBox,
        props: {
          onClick: () => {
            setModal({
              Component: DeleteAlert,
              props: {
                name: " this row",
                closeAlert: () => setModalOpen(false),
                onDelete: () => {
                  const newState = [...rows];
                  updateState(
                    newState.filter((state) => state.ID !== row.ID),
                    SectionID
                  );
                },
              },
            });
            setModalOpen(true);
          },
        },
      },
    ];
  }

  function generateDataRows(rows) {
    return [
      ...rows.map((row) => {
        return generateDataRow(row);
      }),
    ];
  }

  return (
    <div>
      <Table heading={Heading} rows={[...generateDataRows(rows)]} />
      <div className="Flex" style={{ margin: "1em 0" }}>
        <Button
          title={"Add Row"}
          onClick={() => {
            setModal({
              Component: RowForm,
              props: {
                inputs: generateInputRow(),
                onSave,
                DataColumns: SectionID === "Coaching" ? DataColumns : {},
                SectionID,
              },
            });
            setModalOpen(true);
          }}
        />
      </div>
      {modal && (
        <EwModal onClose={() => setModalOpen(false)} open={modalOpen}>
          <modal.Component {...modal.props} />
        </EwModal>
      )}
    </div>
  );
}

export default AgendaTable;
