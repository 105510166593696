import {
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";

function GroupDropdown({
  menuItems,
  value,
  onChange,
  label,
  onBlur,
  size,
  variant,
  disable,
  required,
  styles,
}) {
  function MyListSubheader(props) {
    const { muiSkipListHighlight, ...other } = props;
    return (
      <ListSubheader {...other} style={{ borderBottom: "1px solid #757575" }} />
    );
  }

  const list = [{ label: "Select a value", value: -1 }];
  menuItems.forEach((menuItem) => {
    list.push({
      label: menuItem.GroupName,
      value: menuItem.GroupName,
      header: true,
    });
    menuItem.Items.forEach((item) => {
      list.push({ label: item.label, value: item.value });
    });
  });

  return (
    <Box sx={{ minWidth: 120, ...styles }}>
      <FormControl size={size || ""} fullWidth variant={variant}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          disabled={disable}
          value={value || -1}
          style={{ zIndex: 10 }}
          onBlur={onBlur}
          onChange={(e) => {
            let val = e.target.value;
            console.log(val, "MANDATOIRY CHANGEZS");
            onChange(val);
          }}
          required={required}
          MenuProps={{
            PaperProps: {
              sx: { maxHeight: "10em" },
              className: "invisble-scroll",
            },
          }}
        >
          {list.map((item) => {
            return item.header ? (
              <MyListSubheader muiSkipListHighlight>
                {item.label}
              </MyListSubheader>
            ) : (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default GroupDropdown;
