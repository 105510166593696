import React, { useState } from "react";
import EwTable from "../../../MComponent/Table/Table";
import EditIcon from "@mui/icons-material/Edit";
import Atomic from "../../../AComponent/Atomic";

function DimensionList(props) {
  const { children } = props;
  const [dimList, setDimList] = useState([{
    name:"Dim1",
    paramLabel:"test",
    firstGroup:true,
    firstGroupLabel:'Group 1',
    secondGroup:true,
    secondGroupLabel:"Group 2",
  }]);
  const [formMode, setFormMode] = useState(null);
  const heading = ["Dimension", "Edit"];

  function Edit({ index }) {
    return <div className="pointer" onClick={() => setFormMode(index)}>
        <EditIcon />
    </div>;
  }
  function getRows() {
    return dimList.map((ele, index) => [
      ...Object.entries(ele).map(([key, value]) => value).splice(0,1),
      {
        Component: Edit,
        props: {
          index,
        },
      },
    ]);
  }
  return (
    <div>
     
      {formMode===null ? (
        <div className="Flex">
          {dimList.length > 0 && <EwTable heading={heading} rows={getRows()} />}
        </div>
      ) : (<>
      <Atomic.Button variant={"Text"} title="⬅️ Dimensions" onClick={()=>setFormMode(null)} />
        <div>{React.cloneElement(children, { data: dimList[formMode] })}</div>
        </>
      )}
    </div>
  );
}

export default DimensionList;
