import React, { useEffect, useState } from "react";
import DMP2 from "./DMP2";
import EwTabs from "../../../MComponent/Tabs/Tabs";
import EwTable from "../../../MComponent/Table/Table";
import Atomic from "../../../AComponent/Atomic";
import { useDispatch, useSelector } from "react-redux";
import ListView from "../Dimension/ListView";
import MapList from "./MapList";
import { complexQuery, getData } from "../../../Utils/Common/Library";
import { constants } from "../Dimension/Data/QueryConstants";

const { Radio, Dropdown, EwSwitch, Button, Input } = Atomic;
const FeatureID = "19147";
const RatingScaleFeatureId = "19148";
function ParamterMap() {
  const [openForm, setOpenForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const heading = ["Map Name", "Edit"];
  const dispatch = useDispatch();
  useEffect(() => {
    if (openForm === false) {
      dispatch({
        type: "UPDATE_ALL_FIELDS",
        payload: {
          id: "DMP",
          data: {},
        },
      });
    }
  }, [openForm]);
  function setForm(data) {
    console.log("Data uis: ", data);
    dispatch({
      type: "UPDATE_ALL_FIELDS",
      payload: {
        id: "DMP",
        data,
      },
    });
    setOpenForm(true);
  }
  async function getRatingScaleObject(id) {
    if (!id) return;
    let obj = await getData({
      featureId: RatingScaleFeatureId,
      payload: { "Header.TransactionID": id },
    });
    if (obj?.Data) return obj.Data[0];
    else return null;
  }
  return (
    <div>
      {!openForm ? (
        loading ? (
          <>Loading ...</>
        ) : (
          <div>
            <EwTable
              includeOprations={true}
              heading={heading}
              featureId={FeatureID}
              rowToFieldMap={["MapName"]}
              onDocSelected={async (doc) => {
                if (!doc.FirstGroups) {
                  doc.FirstGroups = undefined;
                } else if (
                  doc.FirstGroups.length === 0 ||
                  doc.FirstGroups[0].FirstGroupID === ""
                ) {
                  doc.FirstGroups = undefined;
                }

                if (!doc.SecondGroups) {
                  doc.SecondGroups = undefined;
                } else if (
                  doc.SecondGroups.length === 0 ||
                  doc.SecondGroups[0].SecondGroupID === ""
                ) {
                  doc.SecondGroups = undefined;
                }

                console.log("Doc is: ", doc);
                setLoading(true);
                complexQuery({
                  RequiredFields: [{ TransactionID: doc.TransactionID }],
                  QueryName: constants.ParameterMapQueryName,
                })
                  .then(async (response) => {
                    console.log(
                      JSON.stringify(response),
                      "THIS IS MAP FROM BACKEND"
                    );

                    if (response[0]) {
                      console.log("Parma test: ", response[0].RatingScaleID);
                      if (response[0].RatingScaleID) {
                        response[0].SelectedRatingScale =
                          await getRatingScaleObject(response[0].RatingScaleID);
                        console.log(
                          "Parma test: 1",
                          response[0].SelectedRatingScale
                        );
                      }
                      setForm(response[0]);
                    }
                  })
                  .finally(() => {
                    setLoading(false);
                  });
                console.log("From Map: ", doc);
              }}

              // onDocSelected={docSelected}
            />
            <br />
            <div className="Flex">
              <Button title={"Add New"} onClick={() => setOpenForm(true)} />
            </div>
          </div>
        )
      ) : (
        <div>
          <Button
            title={"⬅️ Paramter Maps"}
            onClick={() => {
              setOpenForm(false);
            }}
            variant="Text"
          />
          <br />
          <EwTabs
            componentList={[
              { label: "Structure", Component: DMP2 },
              {
                label: "Map",
                Component: MapList,
                props: {
                  afterSave: () => {
                    setOpenForm(false);
                  },
                },
              },
            ]}
          />
        </div>
      )}
    </div>
  );
}

export default ParamterMap;
