const Themes = {
    lightTheme : {
        "--primary" : "rgb(128, 128, 128)",
        "--secondary" : "rgb(190, 187, 187)",
    },
    darkTheme : {
        "--primary" : "rgb(29, 29, 29)",
        "--secondary" : "rgb(155, 155, 155)",
    },
    colorTheme: {
        "--primary" : "rgb(149, 253, 52)",
        "--secondary" : "rgb(155, 155, 155)",
    }

}

export default Themes;