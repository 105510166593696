import { useSelector, useDispatch } from "react-redux";

export default function useReduxStore(
  props = {
    id: null,
    initialState: null,
  }
) {
  const { id, initialState } = props;

  const dispatch = useDispatch();
  const data = useSelector((state) => {
    if (state.DataReducer && state.DataReducer[id])
      return state.DataReducer[id];
    return initialState || {};
  });
  function updateValue(value, type) {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: type,
        data: value,
      },
    });
  }

  return [data, updateValue];
}
