import { Box, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListView from "../Dimension/ListView";
import useWindowDimensions from "../../../Utils/CustomHooks/WindowRezise";
import {
  extractAddRules,
  extractDimensionStructure,
  extractDiscardRules,
  extractWeightRules,
  isDisabled,
  isEnabled,
  isNA,
  isRestricted,
  isUserDefined,
} from "../Dimension/Helpers/helper";
import Atomic from "../../../AComponent/Atomic";
import { validateWeights } from "./WeightValidator";
import {
  getData,
  submitPayload,
  handleResult,
} from "../../../Utils/Common/Library";
import Molecule from "../../../MComponent/Molecule";
import AlertDialog from "../../../Utils/Dialog/Dialog";
import DeleteAlert from "../Dimension/Modals/DeleteAlert";
import AlertMessage from "../../../Utils/Messages/AlertMessage";

const { Button, Checkboxes, Dropdown, Input, EwSwitch, Radio } = Atomic;
const { Table, EwModal } = Molecule;

const id = "DMP";
const RatingScaleFeatureID = "19148";
const ParameterMapFeatureID = "19147";
const numericDataType = [
  { label: "Numeric", value: "NI" },
  { label: "Numeric (Decimal)", value: "ND" },
  { label: "Percentage", value: "PI" },
  { label: "Percentage (Decimal)", value: "PD" },
];
const allDataType = [
  { label: "Alpha Numeric Text (200 chars) ", value: "ST" },
  { label: "Alpha Numeric Large Text", value: "LT" },
  ...numericDataType,
];
const dataTypes = {
  ST: { label: "Alpha Numeric Text (200 chars) ", value: "ST" },
  LT: { label: "Alpha Numeric Large Text", value: "LT" },
  NI: { label: "Numeric", value: "NI" },
  ND: { label: "Numeric (Decimal)", value: "ND" },
  PI: { label: "Percentage", value: "PI" },
  PD: { label: "Percentage (Decimal)", value: "PD" },
};

function TargetModal({
  TargetDataType,
  ParameterTargets,
  Achievements,
  AchievementDataType,
  TargetRule: test,
  onSave,
  closeForm,
}) {
  //const [ratingScale, setRatingScale] = useState(null);

  const { width } = useWindowDimensions();
  const {
    RatingScaleID,
    ParameterTargetsObj,
    SelectedRatingScale: ratingScale,
  } = useSelector((state) => {
    if (!state.DataReducer[id]) return {};
    return JSON.parse(JSON.stringify(state.DataReducer[id]));
  });

  const [state, setState] = useState({
    TargetDataType,
    ParameterTargets,
    Achievements,
    AchievementDataType,
    TargetRule: test,
    error: null,
    minRange:
      ParameterTargets && ParameterTargets[0]?.MinimumTargetValue !== "*"
        ? ParameterTargets[0]?.MinimumTargetValue
        : "",
    maxRange:
      ParameterTargets && ParameterTargets[0]?.MaximumTargetValue !== "*"
        ? ParameterTargets[0]?.MaximumTargetValue
        : "",
  });

  function updateState(val, type) {
    // if (type === "TargetDataType") {
    //   updateState(undefined, "AchievementDataType");
    //   updateState(undefined, "ParameterTargets");
    // }
    setState((prevState) => ({ ...prevState, [type]: val }));
  }
  // useEffect(() => {
  //   if (RatingScaleID && RatingScaleID !== -1) {
  //     getData({
  //       featureId: RatingScaleFeatureID,
  //       payload: {
  //         "Header.TransactionID": RatingScaleID,
  //       },
  //     }).then((res) => {
  //       if (res?.Data && res.Data[0]) {
  //         setRatingScale(res.Data[0]);
  //       } else {
  //       }
  //     });
  //   }
  // }, [RatingScaleID]);
  //if (!TargetRule) return <>Target Rule Not Defined</>;
  if (!RatingScaleID) return <>Targets Disabled</>;
  let { TargetRule } = state;
  let rule = TargetRule;
  if (!rule) rule = "D";
  let [enable, type1, type2, setBy] = rule.split("");
  if (!type1) type1 = "_";
  if (!type2) type2 = "_";
  let targetType = type1 + type2;
  // let targetType =TargetType
  // if (targetType === "__") return <>Select A Target Type</>;
  function renderTargetSettings() {
    const radioOptions = [
      { label: "Mapped", value: "M" },
      { label: "User Defined", value: "U" },
    ];
    const userTargetType = [
      { label: "Fixed Value", value: "FV" },
      { label: "Anchored Rating Scale Computed Rating", value: "CR" },
      { label: "Rating Guide", value: "RG" },
    ];
    const mappedTargetType = [
      { label: "Expected Scale Value", value: "EV" },
      { label: "Anchored to Rating Scale Value", value: "AR" },
      { label: "Anchored Rating Scale Computed Rating", value: "CR" },
      { label: "Rating Guide", value: "RG" },
    ];

    return (
      <>
        <br />
        {/* <h3>Targets And Achievement</h3>
        <hr /> */}
        <div className="Flex">
          <EwSwitch
            label={"Targets"}
            value={enable === "E"}
            onChange={(flag) => updateState(flag ? "E" : "D", "TargetRule")}
          />
        </div>
        {enable === "E" && (
          <div>
            <div className="Flex-row g20">
              <div>
                <Radio
                  label={"Target Set By"}
                  menuItems={radioOptions}
                  value={setBy}
                  onChange={(val) =>
                    updateState(enable + "__" + val, "TargetRule")
                  }
                />
              </div>
              {setBy && (
                <div style={{ width: "40%" }}>
                  <Dropdown
                    label="Target Type"
                    menuItems={
                      setBy === "M" ? mappedTargetType : userTargetType
                    }
                    value={targetType === "__" ? undefined : targetType}
                    onChange={(val) =>
                      updateState(enable + val + setBy, "TargetRule")
                    }
                  />
                </div>
              )}
            </div>
            <br />
            {/* <div className="Flex">{renderRatingScale()}</div> */}
            {/* <div className="Flex-row g20">
              {renderTargetDataType()}
              {renderAchivementDataType()}
            </div> */}
          </div>
        )}
        <br />
      </>
    );
  }

  // if (
  //   ["EV", "AR", "CR", "RG"].includes(targetType) &&
  //   (!RatingScaleID || RatingScaleID === -1)
  // )
  //   return <>Select A Rating Scale</>;
  function renderTargetDataType() {
    if (enable !== "E" || !TargetRule) return <></>;
    if (TargetRule.charAt(1) === "_") return <></>;
    // if (targetType === "AR") return <></>;
    let allowedOptions = allDataType;
    if (targetType === "CR") allowedOptions = numericDataType;

    return (
      <div style={{ width: "50%" }}>
        <Dropdown
          label={"Target Data Type"}
          menuItems={allowedOptions}
          value={state.TargetDataType}
          onChange={(val) => {
            updateState(val, "TargetDataType");
            updateState(undefined, "ParameterTargets");
          }}
        />
      </div>
    );
  }
  function renderTargetInput() {
    if (
      !targetType ||
      targetType === -1 ||
      setBy === "U" ||
      !state.TargetDataType ||
      state.TargetDataType === -1 ||
      enable !== "E"
    )
      return <></>;
    let options = ratingScale?.ScaleValues?.map((ele) => ({
      label: ele.Label,
      value: ele.OptionID,
    }));

    let targets = state.ParameterTargets;
    console.log("afetr target : ", targets);
    if (!options) options = [];
    if (!targets) {
      targets = [];
      for (let i = 0; i < options.length; i++) {
        targets[i] = {};
      }
    }
    console.log("IUn targets: ", targets, " ", options, " ", width);
    if (targetType === "EV") {
      let {
        ScaleOptionID,
        TargetValue,
        MinimumTargetValue,
        MaximumTargetValue,
      } = targets[0];

      if (!options) return <>No Values In Rating Scale</>;

      return (
        <>
          <div className="Flex-row">
            <div>
              <Radio
                menuItems={options}
                label="Select Expected Value"
                value={ScaleOptionID}
                onChange={(val) => {
                  let arr = [
                    {
                      ...targets[0],
                      ScaleOptionID: val,
                    },
                  ];
                  updateState(arr, "ParameterTargets");
                }}
              />
            </div>
            <div>
              <Input
                label="Target"
                value={TargetValue}
                onChange={(val) => {
                  let arr = [
                    {
                      ...targets[0],
                      TargetValue: val,
                    },
                  ];
                  updateState(arr, "ParameterTargets");
                }}
                // type={[""]}
              />
            </div>
          </div>
        </>
      );
    }
    function updateTarget(val, type, index, id) {
      let arr = targets;

      if (type === "TargetValue") {
        arr[index] = {
          ScaleOptionID: id,
          TargetValue: val,
          MinimumTargetValue: "",
          MaximumTargetValue: "",
        };
      } else {
        arr[index] = {
          ...arr[index],
          ScaleOptionID: id,
          TargetValue: "",
          [type]: val,
        };
      }
      updateState(arr, "ParameterTargets");
    }
    function updateMax(val, index, value) {
      updateTarget(val, "MaximumTargetValue", index, value);
      let nextValue;
      if (["NI", "PI"].includes(state.TargetDataType)) {
        nextValue = parseInt(val) + 1;
      } else {
        nextValue = parseFloat(val) + 0.1;
        nextValue = nextValue.toFixed(1);
      }
      if (index !== options.length - 1)
        updateTarget(nextValue, "MinimumTargetValue", index + 1, value);
    }

    return (
      targets &&
      targets.length !== 0 && (
        <>
          {!["ST", "LT"].includes(state.TargetDataType) && (
            <div className="Flex-row">
              <div>
                <Input
                  label={"Min Range"}
                  type="number"
                  value={state.minRange}
                  onChange={(val) => updateState(val, "minRange")}
                />
              </div>
              <div>
                <Input
                  label={"Max Range"}
                  type="number"
                  value={state.maxRange}
                  onChange={(val) => updateState(val, "maxRange")}
                />
              </div>
            </div>
          )}
          <br />
          <Grid container>
            {options?.map((ele, index) => {
              const {
                ScaleOptionID,
                TargetValue,
                MinimumTargetValue,
                MaximumTargetValue,
              } = targets[index];
              return (
                <>
                  <Grid xs={2} item></Grid>
                  <Grid
                    className="Flex start scroll"
                    style={{ overflowX: "auto" }}
                    item
                    xs={2}
                  >
                    <Typography variant="h5">{ele.label}</Typography>
                  </Grid>

                  {["ST", "LT"].includes(state.TargetDataType) ? (
                    <>
                      <Grid xs={6} item>
                        <Input
                          label={"Target"}
                          value={TargetValue}
                          onChange={(val) =>
                            updateTarget(val, "TargetValue", index, ele.value)
                          }
                        />
                      </Grid>
                      <Grid xs={2} item></Grid>
                    </>
                  ) : (
                    <>
                      <Grid xs={3} item>
                        {console.log("before index ", state, " ", index)}
                        {index === 0 &&
                          (!state.minRange || state.minRange === "") && (
                            <div
                              className="Flex"
                              style={{ alignItems: "center", height: "100%" }}
                            >
                              <Typography>Upto</Typography>
                            </div>
                          )}
                        {index === 0 && state.minRange && (
                          <Input
                            disable={true}
                            variant="filled"
                            value={state.minRange}
                            type="number"
                          />
                        )}
                        {index !== 0 && (
                          <Input
                            variant="filled"
                            placeholder={"Min"}
                            disable={true}
                            value={MinimumTargetValue || ""}
                            type="number"
                            onChange={(val) =>
                              updateTarget(
                                val,
                                "MinimumTargetValue",
                                index,
                                ele.value
                              )
                            }
                          />
                        )}
                      </Grid>
                      <Grid xs={3} item>
                        {index === options.length - 1 &&
                          (!state.maxRange || state.maxRange === "") && (
                            <div
                              className="Flex"
                              style={{ alignItems: "center", height: "100%" }}
                            >
                              <Typography>And Above</Typography>
                            </div>
                          )}
                        {index === options.length - 1 && state.maxRange && (
                          <Input
                            disable={true}
                            variant="filled"
                            value={state.maxRange}
                            type="number"
                          />
                        )}
                        {index !== options.length - 1 && (
                          <Input
                            label={"Max"}
                            value={MaximumTargetValue || ""}
                            type="number"
                            onChange={(val) => updateMax(val, index, ele.value)}
                            disable={index === options.length - 1}
                            variant={
                              index === options.length - 1 ? "filled" : ""
                            }
                          />
                        )}
                      </Grid>
                      <Grid xs={2} item></Grid>
                    </>
                  )}
                </>
              );
            })}
          </Grid>
        </>
      )
    );
  }
  function renderAchivementDataType() {
    if (
      enable !== "E" ||
      !TargetRule ||
      !state.TargetDataType ||
      state.TargetDataType === -1
    )
      return <></>;
    let allowedOptions = [];
    const { LT, ST, ND, NI, PD, PI } = dataTypes;
    if (["ST", "LT"].includes(state.TargetDataType)) allowedOptions = [LT, ST];
    else if (["NI", "ND"].includes(state.TargetDataType)) {
      if (targetType === "FV") allowedOptions = numericDataType;
      else allowedOptions = [NI, ND];
    } else if (["PI", "PD"].includes(state.TargetDataType))
      allowedOptions = [PI, PD];

    return (
      <>
        <h3>Achievement</h3>
        <hr />
        <br />
        <div className="Flex">
          <div>
            <EwSwitch
              label="Achievement"
              value={state.Achievements === "E"}
              onChange={(val) => updateState(val ? "E" : "D", "Achievements")}
            />
          </div>
        </div>
        <br />
        {state.Achievements === "E" && (
          <div style={{ width: "100%" }}>
            <Dropdown
              label="Achivement Data Type"
              menuItems={allowedOptions}
              value={state.AchievementDataType}
              onChange={(val) => updateState(val, "AchievementDataType")}
            />
          </div>
        )}
        <br />
        <br />
      </>
    );
  }
  function validateAndSave() {
    const {
      TargetDataType,
      ParameterTargets,
      AchievementDataType,
      Achievements,
      minRange,
      maxRange,
      TargetRule,
    } = state;
    if (!TargetRule || (TargetRule[0] === "E" && TargetRule?.length !== 4)) {
      console.log("Target rule: ", TargetRule);
      updateState("Targets Rules Not Set ", "error");
      return;
    }
    const [enable, type1, type2, setBy] = TargetRule.split("");
    let type = type1 + type2;
    let arr = ParameterTargets;
    if (setBy === "M" && enable === "E") {
      if (!arr) {
        updateState("Targets Not Defined ", "error");
        return;
      }
      if (
        ["AR", "CR", "RG"].includes(type) &&
        !["ST", "LT"].includes(TargetDataType)
      ) {
        console.log(
          "Before valie: ",
          minRange,
          " ",
          arr[0].MaximumTargetValue,
          " ",
          arr[0].MaximumTargetValue <= minRange
        );
        if (minRange && +arr[0].MaximumTargetValue <= +minRange) {
          updateState(
            "Max value cannot be less than or eqaul to min value for value" +
              arr[0].MaximumTargetValue,
            "error"
          );
          return;
        }
        arr[0].MinimumTargetValue =
          "" + (minRange && minRange !== "" ? minRange : "*");
        arr[0].TargetValue = "";
        for (let i = 1; i < arr.length - 1; i++) {
          const {
            ScaleOptionID,
            TargetValue,
            MinimumTargetValue,
            MaximumTargetValue,
          } = arr[i];
          if (!MinimumTargetValue || !MaximumTargetValue) {
            console.log("Targets: for: ", arr[i]);
            updateState("Target not defined.", "error");
            return;
          }
          if (+MaximumTargetValue <= +MinimumTargetValue) {
            updateState(
              "Max value cannot be less than or equal to min value for value " +
                MaximumTargetValue,
              "error"
            );
            return;
          }
          arr[i].MinimumTargetValue = "" + MinimumTargetValue;
          arr[i].TargetValue = "";
        }
        let lastIndex = arr.length - 1;
        arr[lastIndex].MaximumTargetValue =
          maxRange && maxRange !== "" ? maxRange : "*";
        arr[lastIndex].MinimumTargetValue =
          "" + arr[lastIndex].MinimumTargetValue;
        if (
          +arr[lastIndex].MaximumTargetValue <=
          +arr[lastIndex].MinimumTargetValue
        ) {
          updateState(
            "Max value cannot be less than or equal to min value for value " +
              arr[lastIndex].MaximumTargetValue,
            "error"
          );
          return;
        }
        arr[lastIndex].TargetValue = "";
      }
      if (
        ["AR", "CR", "RG"].includes(type) &&
        ["ST", "LT"].includes(TargetDataType)
      ) {
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].TargetValue || arr[i]?.TargetValue?.trim(" ") === "") {
            updateState("Targets Not Defined ", "error");
            return;
          }
        }
      }
    } else if (setBy === "U" && enable === "E") {
      onSave(TargetDataType, [], AchievementDataType, Achievements, TargetRule);
      closeForm();
      return;
    } else {
      onSave("", [], "", "D", "DNAN");
      closeForm();
      return;
    }
    console.log("Before Save: ", arr);
    onSave(TargetDataType, arr, AchievementDataType, Achievements, TargetRule);
    closeForm();
  }

  return (
    <div
      className="scroll"
      style={{ width: 0.7 * width, height: "80vh", overflowY: "auto" }}
    >
      <h2>Targets</h2>
      <hr />
      <br />
      <div className="Flex">{renderTargetSettings()}</div>
      <br />
      <div className="Flex">{renderTargetDataType()}</div>
      <br />
      <div className="Flex">{renderTargetInput()}</div>
      <br />
      {renderAchivementDataType()}
      <br />
      {state.error && (
        <>
          <AlertMessage
            message={state.error}
            onClose={() => updateState(null, "error")}
          />
          <br />
        </>
      )}
      <div className="Flex">
        <Button onClick={validateAndSave} title={"Save"} />
      </div>
    </div>
  );
}

const LibraryModal = ({
  level,
  DimensionID,
  weightRule,
  featureId,
  rowToFieldMap,
  onSave,
  minCount,
  maxCount,
  selectedRecords,
}) => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  const [alert, setAlert] = useState({ isOpen: false, message: "" });

  useEffect(() => {
    setLoading(true);
    getData({ featureId })
      .then((res) => {
        const recordsData = res.Data.filter(
          (data) => data.GroupLevel === level.toString()
        );
        const filteredData = level === 0 ? res.Data : recordsData;
        const finalRecords = filteredData.filter(
          (record) =>
            record.DimensionID === DimensionID &&
            // These filter removes the selected parameters from the table.
            !selectedRecords.find(
              (sr) => sr.TransactionID === record.TransactionID
            )
        );

        setRecords(finalRecords);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onCheck = (value) => {
    setSelected((prev) => [
      ...prev,
      {
        ...value,
        Weight: "",
        MinimumWeight: "0",
        MaximumWeight: "100",
      },
    ]);
    // setSelected((prevState) => {
    //   const newState = [...prevState];
    //   newState.push();
    //   return newState;
    // });
  };

  const onUnCheck = (value) => {
    setSelected((prevState) => {
      const newState = [...prevState];
      return newState.filter(
        (state) => state.TransactionID !== value.TransactionID
      );
    });
  };

  function weightChangeHandler(TransactionID, key, value) {
    setSelected((prevState) => {
      const newState = [...prevState];
      const changedRecordIndex = newState.findIndex(
        (state) => state.TransactionID === TransactionID
      );
      newState[changedRecordIndex][key] = value;
      if (weightRule === "M") {
        newState[changedRecordIndex]["MinimumWeight"] = value;
        newState[changedRecordIndex]["MaximumWeight"] = value;
      }
      return newState;
    });
  }

  const heading = [...rowToFieldMap.map((field) => field.label)];
  const rows = [
    ...records.map((record) => [
      ...rowToFieldMap.map((field) => {
        if (field.label === "Select") {
          return {
            Component: CheckBox,
            props: {
              isChecked: selected.find(
                (s) => s.TransactionID === record.TransactionID
              )
                ? true
                : false,
              record,
              onCheck,
              onUnCheck,
              disable:
                maxCount && (+maxCount - selected.length > 0 ? false : true),
            },
          };
        } else if (
          field.label === "Weight" ||
          field.label === "Minimum Weight" ||
          field.label === "Maximum Weight"
        ) {
          const checked = selected.find(
            (sec) => sec.TransactionID === record.TransactionID
          );
          return !checked
            ? () => <></>
            : {
                Component: InputBox,
                props: {
                  TransactionID: checked.TransactionID,
                  label: field.label,
                  keyName: field.value,
                  value: checked[field.value] || "",
                  onChange: weightChangeHandler,
                },
              };
        } else {
          return record[field.value];
        }
      }),
    ]),
  ];

  return loading ? (
    <Box>Loading ...</Box>
  ) : (
    <Box height={"50vh"} width={"50vw"} className="scroll" paddingRight={"2em"}>
      <Typography variant="h5">
        {level === 0 ? "Parameter" : "Group"} Library
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={"1em"}
      >
        <div className="Flex" style={{ margin: "1em 0" }}>
          <Typography variant="body1" color={"red"}>
            {maxCount && (+maxCount - selected.length > 0 ? false : true)
              ? "Maximum Selections reached"
              : ""}
          </Typography>
        </div>
        <Table rows={rows} heading={heading} pageLimit={10} />
        <Button
          disable={minCount && selected.length < +minCount}
          title={"Save"}
          onClick={() => {
            onSave(selected);
          }}
        />
        <Typography variant="body1" color={"red"}>
          {minCount && selected.length < +minCount
            ? `Please select atleast ${minCount} items`
            : ""}
        </Typography>
      </Box>
      <AlertDialog
        title={alert.message}
        open={alert.isOpen}
        handleClose={() => setAlert({ isOpen: false, message: "" })}
      />
    </Box>
  );
};

const WeightsFormModal = ({
  TransactionID,
  WeightRule,
  Weight,
  MinimumWeight,
  MaximumWeight,
  onSave,
}) => {
  const [weights, setWeights] = useState({
    WeightRule,
    Weight,
    MinimumWeight,
    MaximumWeight,
  });

  const onChangeHandler = (key, value) =>
    setWeights((prevState) => ({ ...prevState, [key]: value }));

  return (
    <Box>
      <Typography variant="h6">Configure Weights</Typography>
      {weights.WeightRule === "M" && (
        <Box width={"100%"}>
          <Input
            label={"Weight"}
            value={weights.Weight}
            onChange={(value) => {
              onChangeHandler("Weight", value);
              onChangeHandler("MinimumWeight", value);
              onChangeHandler("MaximumWeight", value);
            }}
            styles={{ width: "100%", margin: "0.5em 0" }}
          />
        </Box>
      )}
      {weights.WeightRule === "E" && (
        <Box display={"flex"} alignItems={"center"} gap={"2em"}>
          <Box flex={"1"}>
            <Input
              label={"Weight"}
              value={weights.Weight}
              onChange={(value) => {
                onChangeHandler("Weight", value);
              }}
            />
          </Box>
          <Box width={"100%"} flex={2}>
            <Box width={"100%"}>
              <Box flex={"1"}>
                <Input
                  label={"Minimum Weight"}
                  value={weights.MinimumWeight || "0"}
                  onChange={(value) => {
                    onChangeHandler("MinimumWeight", value);
                  }}
                />
              </Box>
              <Box flex={"1"}>
                <Input
                  label={"Maximum Weight"}
                  value={weights.MaximumWeight || "100"}
                  onChange={(value) => {
                    onChangeHandler("MaximumWeight", value);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {weights.WeightRule === "U" && (
        <Box display={"flex"} justifyContent={"center"}>
          <Box width={"100%"} flex={2}>
            <Box width={"100%"}>
              <Box flex={"1"}>
                <Input
                  label={"Minimum Weight"}
                  value={weights.MinimumWeight || "0"}
                  onChange={(value) => {
                    onChangeHandler("MinimumWeight", value);
                  }}
                />
              </Box>
              <Box flex={"1"}>
                <Input
                  label={"Maximum Weight"}
                  value={weights.MaximumWeight || "100"}
                  onChange={(value) => {
                    onChangeHandler("MaximumWeight", value);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {WeightRule === "D" && (
        <div className="Flex">
          <Typography variant="overline">Weights are disabled</Typography>
        </div>
      )}
      <Box className="Flex" marginTop={"1em"}>
        <Button
          title={"Save"}
          onClick={() => {
            onSave(TransactionID, weights);
          }}
        />
      </Box>
    </Box>
  );
};

const CheckBox = ({ record, isChecked, onCheck, onUnCheck, disable }) => {
  const changeHandler = (value) => {
    if (value) {
      onCheck(record);
    } else {
      onUnCheck(record);
    }
  };

  return (
    <Checkboxes onChange={changeHandler} value={isChecked} disabled={disable} />
  );
};

function InputBox({ TransactionID, label, keyName, onChange, value }) {
  return (
    <Input
      label={" "}
      onChange={(val) => onChange(TransactionID, keyName, val)}
      value={value}
      size={"small"}
      styles={{ width: "6em", margin: 0 }}
    />
  );
}

const MapList = ({ afterSave }) => {
  const [error, setError] = useState(null);
  const {
    DimensionID,
    TransactionID,
    EffectiveFromTimestamp,
    MapApplicabilityID,
    MapStructure,
    editMode,
    DiscardRule,
    AddRule,
    MapName,
    MapShortName,
    MapDescription,
    MinimumFirstGroup,
    MaximumFirstGroup,
    MinimumSecondGroup,
    MaximumSecondGroup,
    MinimumParameters,
    MaximumParameters,
    FirstGroupWeightRule,
    SecondGroupWeightRule,
    ParameterWeightRule,
    RatingScaleID,
    SecondGroups,
    FirstGroups,
    Parameters,
    ParameterArray,
    UserTargetOff,
    UserTargetST,
    UserTargetLT,
    UserTargetNI,
    UserTargetND,
    UserTargetPI,
    UserTargetPD,
    DefaultRating,
  } = useSelector((state) => {
    if (!state.DataReducer[id]) return {};
    return JSON.parse(JSON.stringify(state.DataReducer[id]));
  });

  const filteredSecondGroups = (SecondGroups || [])
    .filter((sg) => sg.SecondGroupID !== "")
    .map((sg) => ({
      ...sg,
      TransactionID: sg.SecondGroupID || sg.TransactionID,
      GroupName: sg.SecondGroupName || sg.GroupName,
    }));
  const filteredFirstGroups = (FirstGroups || [])
    .filter((fg) => fg.FirstGroupID !== "")
    .map((fg) => ({
      ...fg,
      FirstGroupID: fg.TransactionID || fg.FirstGroupID,
      TransactionID: fg.FirstGroupID || fg.TransactionID,
      GroupName: fg.FirstGroupName || fg.GroupName,
    }));
  const filteredParameters = (Parameters || [])
    .filter(
      (p) =>
        p.TransactionID !== "" ||
        p.ParameterID !== "" ||
        p.ParameterGroupID !== ""
    )
    .map((p) => ({
      ...p,
      TransactionID: p.ParameterID || p.TransactionID,
      ParameterID: p.TransactionID || p.ParameterID,
      ParameterName:
        ParameterArray &&
        ParameterArray.find((p1) => p1.ParameterID === p.ParameterID)
          ?.ParameterName
          ? ParameterArray.find((p1) => p1.ParameterID === p.ParameterID)
              ?.ParameterName
          : p.ParameterName,
    }));

  const dispatch = useDispatch();
  const [modal, setModal] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  }, [error]);
  function updateState(value, type) {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: type,
        data: value,
      },
    });
  }

  const groupStruct = extractDimensionStructure(MapStructure || "MMM");
  const addStruct = extractAddRules(AddRule || "LLL");
  const deleteStruct = extractDiscardRules(DiscardRule || "PPP");

  function generateWeightColumns(rule) {
    switch (rule) {
      case "M":
        return [{ label: "Weight", value: "Weight" }];
      case "E":
        return [
          { label: "Weight", value: "Weight" },
          { label: "Minimum Weight", value: "MinimumWeight" },
          { label: "Maximum Weight", value: "MaximumWeight" },
        ];
      case "U":
        return [
          { label: "Minimum Weight", value: "MinimumWeight" },
          { label: "Maximum Weight", value: "MaximumWeight" },
        ];
      default:
        return [];
    }
  }

  const secondGroup = {
    list: filteredSecondGroups,
    disabled: isDisabled(groupStruct.group2),
    props: {
      clickable: false,
      onClickHandler: () => {},
      permissions: {
        addable:
          !isDisabled(groupStruct.group2) &&
          !isUserDefined(groupStruct.group2) &&
          (!SecondGroups ||
            !MaximumSecondGroup ||
            SecondGroups.length < MaximumSecondGroup),
        editable: true,
        deletable: true,
      },
      rules: {
        addRule: addStruct.group2,
        deleteRule: deleteStruct.group2,
        weightRule: SecondGroupWeightRule,
      },
      addModalHandler: () => {
        setModal({
          Component: LibraryModal,
          props: {
            featureId: "19145",
            level: 2,
            DimensionID,
            selectedRecords: filteredSecondGroups,
            weightRule: SecondGroupWeightRule,
            minCount: MinimumSecondGroup,
            maxCount: MaximumSecondGroup,
            rowToFieldMap: [
              { label: "Select", value: "" },
              { label: "Level", value: "GroupLevel" },
              { label: "Group Name", value: "GroupName" },
              ...generateWeightColumns(SecondGroupWeightRule),
            ],
            onSave: (records) => {
              updateState(
                [
                  ...(SecondGroups || []),
                  ...records.map((sg) => ({
                    ...sg,
                    TransactionID: sg.SecondGroupID || sg.TransactionID,
                    GroupName: sg.SecondGroupName || sg.GroupName,
                  })),
                ],
                "SecondGroups"
              );
              setModalOpen(false);
            },
          },
        });
        setModalOpen(true);
      },
      editModalHandler: (event, TransactionID) => {
        event.stopPropagation();
        const record = SecondGroups.find(
          (group) =>
            (group.TransactionID || group.SecondGroupID) === TransactionID
        );
        setModal({
          Component: WeightsFormModal,
          props: {
            TransactionID,
            WeightRule: SecondGroupWeightRule,
            Weight: record.Weight,
            MinimumWeight: record.MinimumWeight,
            MaximumWeight: record.MaximumWeight,
            onSave: (TransactionID, weights) => {
              let records = SecondGroups ? [...SecondGroups] : [];
              const newState = [...records];
              const index = newState.findIndex(
                (item) => item.TransactionID === TransactionID
              );

              newState[index].Weight = weights.Weight;
              newState[index].MinimumWeight = weights.MinimumWeight;
              newState[index].MaximumWeight = weights.MaximumWeight;
              updateState(newState, "SecondGroups");
              setModalOpen(false);
            },
          },
        });
        setModalOpen(true);
      },
      deleteModalHandler: (event, TransactionID) => {
        event.stopPropagation();
        const record = SecondGroups.find(
          (record) =>
            (record.TransactionID || record.SecondGroupID) === TransactionID
        );
        setModal({
          Component: DeleteAlert,
          props: {
            name: record.GroupName || record.SecondGroupName,
            closeAlert: () => setModalOpen(false),
            onDelete: () => {
              const secondGroups = [...(SecondGroups || [])];
              const firstGroups = [...(FirstGroups || [])];
              const parameters = [...(Parameters || [])];

              if (record.ItemNumber) {
                const firstGroupIDs = [];
                const index = secondGroups.findIndex(
                  (sg) =>
                    (sg.TransactionID || sg.SecondGroupID) === TransactionID
                );
                if (index !== -1) {
                  secondGroups[index].SecondGroupID = "";
                  secondGroups[index].TransactionID = "";
                }
                firstGroups.forEach((fg) => {
                  if (fg.SecondGroupID === TransactionID) {
                    fg.SecondGroupID = "";
                    firstGroupIDs.push(fg.FirstGroupID);
                    fg.FirstGroupID = "";
                    fg.TransactionID = "";
                  }
                });
                parameters.forEach((p) => {
                  if (firstGroupIDs.includes(p.ParameterGroupID)) {
                    p.ParameterGroupID = "";
                    p.ParameterID = "";
                    p.TransactionID = "";
                  }
                });
                updateState(secondGroups, "SecondGroups");
                updateState(firstGroups, "FirstGroups");
                updateState(parameters, "Parameters");
              } else {
                const firstGroupIDs = [];

                updateState(
                  secondGroups.filter(
                    (group) => group.TransactionID !== TransactionID
                  ),
                  "SecondGroups"
                );

                firstGroups.length > 0 &&
                  updateState(
                    firstGroups.filter((group) => {
                      if (group.SecondGroupID === TransactionID)
                        firstGroupIDs.push(group.TransactionID);
                      return group.SecondGroupID !== TransactionID;
                    }),
                    "FirstGroups"
                  );
                parameters.length > 0 &&
                  updateState(
                    parameters.filter(
                      (parameter) =>
                        !firstGroupIDs.includes(parameter.ParameterGroupID)
                    ),
                    "Parameters"
                  );
              }
            },
          },
        });
        setModalOpen(true);
      },
      infoModalHandler: () => {},
    },
  };

  const firstGroup = {
    list: filteredFirstGroups,
    disabled: isDisabled(groupStruct.group1),
    props: {
      clickable: false,
      onClickHandler: () => {},
      permissions: {
        addable:
          !isDisabled(groupStruct.group1) &&
          !isUserDefined(groupStruct.group1) &&
          (!FirstGroups ||
            !MaximumFirstGroup ||
            FirstGroups.length < MaximumFirstGroup),
        editable: true,
        deletable: true,
      },
      rules: {
        addRule: addStruct.group1,
        deleteRule: deleteStruct.group1,
        weightRule: FirstGroupWeightRule,
      },
      addModalHandler: (SecondGroupID) => {
        setModal({
          Component: LibraryModal,
          props: {
            featureId: "19145",
            level: 1,
            DimensionID,
            selectedRecords: filteredFirstGroups,
            weightRule: FirstGroupWeightRule,
            minCount: MinimumFirstGroup,
            maxCount: MaximumFirstGroup,
            rowToFieldMap: [
              { label: "Select", value: "" },
              { label: "Level", value: "GroupLevel" },
              { label: "Group Name", value: "GroupName" },
              ...generateWeightColumns(FirstGroupWeightRule),
            ],
            onSave: (records) => {
              updateState(
                [
                  ...(FirstGroups || []),
                  ...records.map((record) => ({ ...record, SecondGroupID })),
                ].map((fg) => ({
                  ...fg,
                  TransactionID: fg.FirstGroupID || fg.TransactionID,
                  GroupName: fg.FirstGroupName || fg.GroupName,
                })),
                "FirstGroups"
              );
              setModalOpen(false);
            },
          },
        });
        setModalOpen(true);
      },
      editModalHandler: (event, TransactionID) => {
        event.stopPropagation();
        const record = (FirstGroups || []).find(
          (group) =>
            (group.TransactionID || group.FirstGroupID) === TransactionID
        );
        setModal({
          Component: WeightsFormModal,
          props: {
            TransactionID,
            WeightRule: FirstGroupWeightRule,
            Weight: record.Weight,
            MinimumWeight: record.MinimumWeight,
            MaximumWeight: record.MaximumWeight,
            onSave: (TransactionID, weights) => {
              let records = FirstGroups ? [...FirstGroups] : [];
              const newState = [...records];
              const index = newState.findIndex(
                (item) => item.TransactionID === TransactionID
              );
              newState[index].Weight = weights.Weight;
              newState[index].MinimumWeight = weights.MinimumWeight;
              newState[index].MaximumWeight = weights.MaximumWeight;
              updateState(newState, "FirstGroups");
              setModalOpen(false);
            },
          },
        });
        setModalOpen(true);
      },
      deleteModalHandler: (event, TransactionID) => {
        event.stopPropagation();
        const record = FirstGroups.find(
          (record) =>
            (record.TransactionID || record.FirstGroupID) === TransactionID
        );
        setModal({
          Component: DeleteAlert,
          props: {
            name: record.GroupName || record.FirstGroupName,
            closeAlert: () => setModalOpen(false),
            onDelete: () => {
              const firstGroups = [...(FirstGroups || [])];
              const parameters = [...(Parameters || [])];

              if (record.ItemNumber) {
                const index = firstGroups.findIndex(
                  (fg) =>
                    (fg.TransactionID || fg.FirstGroupID) === TransactionID
                );
                if (index !== -1) {
                  firstGroups[index].FirstGroupID = "";
                  firstGroups[index].SecondGroupID = "";
                }
                parameters.forEach((p) => {
                  if (p.ParameterGroupID === TransactionID) {
                    p.ParameterGroupID = "";
                    p.ParameterID = "";
                  }
                });
                updateState(firstGroups, "FirstGroups");
                updateState(parameters, "Parameters");
              } else {
                updateState(
                  firstGroups.filter(
                    (group) => group.TransactionID !== TransactionID
                  ),
                  "FirstGroups"
                );
                parameters.length > 0 &&
                  updateState(
                    parameters.filter(
                      (parameter) =>
                        parameter.ParameterGroupID !== TransactionID
                    ),
                    "Parameters"
                  );
              }
            },
          },
        });
        setModalOpen(true);
      },
      infoModalHandler: () => {},
    },
  };

  const parameter = {
    list: filteredParameters,
    disabled: isDisabled(groupStruct.parameter),
    props: {
      clickable: true,
      onClickHandler: (TransactionID) => {
        const parameters = [...Parameters];
        const index = parameters.findIndex(
          (parameter) =>
            (parameter.TransactionID || parameter.ParameterID) === TransactionID
        );
        if (index < 0) return;
        setModal({
          Component: TargetModal,
          props: {
            TargetDataType: parameters[index].ParameterTargetDataType,
            ParameterTargets: parameters[index].ParameterTargets,
            Achievements: parameters[index].Achievements,
            AchievementDataType: parameters[index].AchievementDataType,
            TargetRule: parameters[index].TargetRule,
            onSave: (
              ParameterTargetDataType,
              ParameterTargets,
              AchievementDataType,
              Achievements,
              TargetRule
            ) => {
              parameters[index] = {
                ...parameters[index],
                ParameterTargetDataType,
                ParameterTargets,
                AchievementDataType,
                Achievements,
                TargetRule,
              };
              console.log(
                index,
                ParameterTargetDataType,
                ParameterTargets,
                AchievementDataType,
                Achievements,
                TargetRule,
                "ON SUBMIT VALUE"
              );
              updateState(parameters, "Parameters");
            },
            closeForm: () => setModalOpen(false),
          },
        });
        setModalOpen(true);
      },
      permissions: {
        addable:
          !isDisabled(groupStruct.parameter) &&
          !isUserDefined(groupStruct.parameter) &&
          (!Parameters ||
            !MaximumParameters ||
            Parameters.length < MaximumParameters),
        editable: true,
        deletable: true,
      },
      rules: {
        addRule: addStruct.parameter,
        deleteRule: deleteStruct.parameter,
        weightRule: ParameterWeightRule,
      },
      addModalHandler: (ParameterGroupID) => {
        setModal({
          Component: LibraryModal,
          props: {
            featureId: "19146",
            level: 0,
            DimensionID,
            selectedRecords: filteredParameters,
            weightRule: ParameterWeightRule,
            minCount: MinimumParameters,
            maxCount: MaximumParameters,
            rowToFieldMap: [
              { label: "Select", value: "" },
              { label: "Parameter Name", value: "ParameterName" },
              ...generateWeightColumns(ParameterWeightRule),
            ],
            onSave: (records) => {
              updateState(
                [
                  ...(Parameters || []),
                  ...records.map((record) => ({ ...record, ParameterGroupID })),
                ].map((p) => ({
                  ...p,
                  ParameterID: p.TransactionID || p.ParameterID,
                  ParameterName:
                    ParameterArray &&
                    ParameterArray.find(
                      (p1) => p1.ParameterID === p.ParameterID
                    )?.ParameterName
                      ? ParameterArray.find(
                          (p1) => p1.ParameterID === p.ParameterID
                        )?.ParameterName
                      : p.ParameterName,
                })),
                "Parameters"
              );
              setModalOpen(false);
            },
          },
        });
        setModalOpen(true);
      },
      editModalHandler: (event, TransactionID) => {
        event.stopPropagation();
        const record = Parameters.find(
          (group) =>
            (group.TransactionID || group.ParameterID) === TransactionID
        );
        setModal({
          Component: WeightsFormModal,
          props: {
            TransactionID,
            WeightRule: ParameterWeightRule,
            Weight: record.Weight,
            MinimumWeight: record.MinimumWeight,
            MaximumWeight: record.MaximumWeight,
            onSave: (TransactionID, weights) => {
              let records = Parameters ? [...Parameters] : [];
              const newState = [...records];
              const index = newState.findIndex(
                (item) =>
                  (item.TransactionID || item.ParameterID) === TransactionID
              );
              console.log(
                newState,
                TransactionID,
                index,
                "THESE ARE FOR TESTING"
              );
              newState[index].Weight = weights.Weight;
              newState[index].MinimumWeight = weights.MinimumWeight;
              newState[index].MaximumWeight = weights.MaximumWeight;

              updateState(newState, "Parameters");
              setModalOpen(false);
            },
          },
        });
        setModalOpen(true);
      },
      deleteModalHandler: (event, ParameterID) => {
        event.stopPropagation();
        const record = Parameters.find(
          (record) => record.ParameterID === ParameterID
        );
        setModal({
          Component: DeleteAlert,
          props: {
            name: record.ParameterName,
            closeAlert: () => setModalOpen(false),
            onDelete: () => {
              const parameters = [...(Parameters || [])];

              if (record.ItemNumber) {
                const index = parameters.findIndex(
                  (p) => p.ParameterID === ParameterID
                );
                if (index !== -1) {
                  parameters[index].ParameterID = "";
                  parameters[index].ParameterGroupID = "";
                  parameters[index].TransactionID = "";
                }
                console.log(parameters, "THESE ARE THE PARAMETERS");
                updateState(parameters, "Parameters");
              } else {
                console.log(
                  parameters.filter(
                    (parameter) => parameter.ParameterID !== ParameterID
                  ),
                  "THESE ARE THE PARAMETERS"
                );
                updateState(
                  parameters.filter(
                    (parameter) => parameter.ParameterID !== ParameterID
                  ),
                  "Parameters"
                );
              }
            },
          },
        });
        setModalOpen(true);
      },
      infoModalHandler: () => {},
    },
  };

  function isValid() {
    if (!DimensionID) return false;
    if (!MapStructure || MapStructure?.length !== 3) return false;
    const [group2, group1, param] = MapStructure.split("");
    if (
      (group2 !== "D" && !SecondGroupWeightRule) ||
      (group1 !== "D" && !FirstGroupWeightRule) ||
      (param !== "D" && !ParameterWeightRule)
    )
      return false;
    return true;
  }

  function onSave() {
    let wtValidation = validateWeights(
      SecondGroups,
      FirstGroups,
      Parameters,
      SecondGroupWeightRule + FirstGroupWeightRule + ParameterWeightRule,
      MapStructure
    );
    let UserTargetRule = {
      UserTargetOff,
      UserTargetST,
      UserTargetLT,
      UserTargetNI,
      UserTargetND,
      UserTargetPI,
      UserTargetPD,
    };
    console.log("Weight : valid", wtValidation);
    if (AddRule.charAt(2) !== "R") {
      if (UserTargetOff === "E") {
        Object.keys(UserTargetRule).forEach((ele) => {
          UserTargetRule[ele] = "D";
        });
      } else {
        Object.keys(UserTargetRule).forEach((ele) => {
          UserTargetRule[ele] = UserTargetRule[ele] ? UserTargetRule[ele] : "D";
        });
      }
    } else {
      Object.keys(UserTargetRule).forEach((ele) => {
        UserTargetRule[ele] = "D";
      });
    }

    // if (!MapApplicabilityID) {
    //   setError("Applicability Not Defined");
    //   return;
    // }
    if (wtValidation.status) {
    }
    if (!wtValidation.status) {
      setError("Weight Validation Failed");
      return;
    }

    if (!MapName) {
      setError("Please Enter Map Name");
      return;
    }
    if (!DiscardRule || (DiscardRule && DiscardRule.length !== 3)) {
      setError("Discard Rules Not Defined");
      return;
    }
    if (!AddRule || (AddRule && AddRule.length !== 3)) {
      setError("Add Rules Not Defined");
      return;
    }
    let defaultSecondGroup = [
      {
        MaximumWeight: "",
        MinimumWeight: "",
        SecondGroupID: "",
        Weight: "",
      },
    ];
    let defaultFirstGroup = [
      {
        FirstGroupID: "",
        MaximumWeight: "",
        MinimumWeight: "",
        SecondGroupID: "",
        Weight: "",
      },
    ];
    const DMP_PAYLOAD = {
      MapName,
      MapShortName: MapShortName || "",
      MapDescription: MapDescription || "",
      DimensionID,
      MapApplicabilityID: "",
      MapStructure,
      MaximumSecondGroups: MaximumSecondGroup || "",
      MinimumSecondGroups: MinimumSecondGroup || "",
      MaximumFirstGroups: MaximumFirstGroup || "",
      MinimumFirstGroups: MinimumFirstGroup || "",
      MaximumParameters: MaximumParameters || "",
      MinimumParameters: MinimumParameters || "",
      DefaultRating: DefaultRating || "",
      MapApplicabilityID: MapApplicabilityID,
      Weights: "",
      ParameterWeightRule,
      RatingScaleID,
      ...UserTargetRule,
      UserTargetOff: UserTargetOff ? UserTargetOff : "D",
      SecondGroups: SecondGroups
        ? SecondGroups.map((group) => {
            const finalSecondGroup = {
              SecondGroupID: group.TransactionID || group.SecondGroupID,
              Weight: group.Weight || "",
              MinimumWeight: group.MinimumWeight || "",
              MaximumWeight: group.MaximumWeight || "",
            };
            if (group.ItemNumber) {
              finalSecondGroup.ItemNumber = group.ItemNumber;
            }
            return finalSecondGroup;
          })
        : [],
      FirstGroups: FirstGroups
        ? FirstGroups.map((group) => {
            const finalFirstGroup = {
              FirstGroupID: group.TransactionID || group.FirstGroupID,
              SecondGroupID: group.SecondGroupID,
              Weight: group.Weight || "",
              MinimumWeight: group.MinimumWeight || "",
              MaximumWeight: group.MaximumWeight || "",
            };
            if (group.ItemNumber) {
              finalFirstGroup.ItemNumber = group.ItemNumber;
            }
            return finalFirstGroup;
          })
        : [],
      Parameters: Parameters
        ? Parameters.map((parameter) => {
            const finalParameter = {
              ParameterID: parameter.ParameterID || parameter.TransactionID,
              ParameterGroupID: parameter.ParameterGroupID || "",
              // ParentParameterID: parameter.ParentParameterID || "",
              // ParameterLinking: parameter.ParameterLinking || "",
              // ParameterAlignment: parameter.ParameterAlignment || "",
              ParentParameterID: "",
              ParameterLinking: "",
              ParameterAlignment: "",
              Weight:
                ParameterWeightRule === "D" ? "1" : parameter.Weight || "",
              MinimumWeight: parameter.MinimumWeight || "",
              MaximumWeight: parameter.MaximumWeight || "",
              Achievements: parameter.Achievements || "",
              AchievementsDataType: parameter.AchievementDataType || "",
              ParameterTargetDataType: parameter.ParameterTargetDataType || "",
              TargetRule: parameter.TargetRule ? parameter.TargetRule : "DNAN",
            };
            if (parameter.ParameterTargets) {
              finalParameter.ParameterTargets = parameter.ParameterTargets;
            }
            if (parameter.ItemNumber) {
              finalParameter.ItemNumber = parameter.ItemNumber;
            }
            return finalParameter;
          })
        : [],
      DiscardRule,
      AddRule,
      FirstGroupWeightRule: FirstGroupWeightRule || "D",
      SecondGroupWeightRule: SecondGroupWeightRule || "D",
    };
    let edit = null;
    if (TransactionID) {
      edit = {
        TransactionID: TransactionID,
        EffectiveFromTimestamp: EffectiveFromTimestamp,
        RequestType: "1",
      };
    }

    console.log(edit, "THIS IS EDIT");
    console.log("Final Payload: ", JSON.stringify(DMP_PAYLOAD));
    // return;

    submitPayload({
      featureId: ParameterMapFeatureID,
      payload: DMP_PAYLOAD,
      edit,
    })
      .then((res) => {
        if (handleResult(res) === 1) {
          setError("Success");
        } else {
          setError("Error");
        }
      })
      .catch((e) => {
        setError("Network Error " + JSON.stringify(e));
      });
    afterSave();
  }
  return (
    <Box>
      {error && <AlertMessage type={1} message={error} />}
      <br />
      {isValid() ? (
        <>
          <ListView
            secondGroups={secondGroup}
            firstGroups={firstGroup}
            parameters={parameter}
          />
          <Button
            style={{ marginTop: "2em" }}
            onClick={onSave}
            title={"Save"}
            variant={"contained"}
          />
          <EwModal
            onClose={() => {
              setModalOpen(false);
            }}
            open={modalOpen}
          >
            {modal && <modal.Component {...modal.props} />}
          </EwModal>
        </>
      ) : (
        <div className="Flex">
          <Typography variant="h6" fontWeight={"400"} color={"#AAA7AC"}>
            Please complete the Map Structure
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default MapList;
