import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../Utils/CustomHooks/WindowRezise";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Chip from "@mui/material/Chip";
import {
  getTree,
  SaveApplicability,
  getData,
} from "../../Utils/Common/Library";
import Atomic from "../../AComponent/Atomic";
import Molecule from "../Molecule";
import {
  dimensions,
  payloadMapper,
  dimensionForReport,
} from "./ApplicabilityData";
import { Stack, Typography } from "@mui/material";
const { Button, Dropdown, Checkboxes, Radio } = Atomic;
const { EwModal } = Molecule;

///const testId = "1680171474941";
const id = "Applicability";
const applicabilityFeatureID = "00103";
const dimensionsIdMap = {};
const dimensionsValueToLabelMap = {};
dimensions.forEach(({ id, value, label }) => {
  dimensionsIdMap[value] = id;
  dimensionsValueToLabelMap[value] = label;
});
var allToChildrenMap = {};
var idToAll = {};
const initialProps = {
  AuthorFeatureID: null,
  ParentApplicabilityID: null,
  value: null,
  onChange: null,
  passPayload: false,
};

function Applicability5(props = initialProps) {
  const {
    AuthorFeatureID,
    ParentApplicabilityID,
    value,
    onChange,
    ApplicabilityReferenceField,
    passPayload,
  } = props;

  //const [selectedS]
  const { width } = useWindowDimensions();
  const [modal, openModal] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState({});
  const [collapse, setCollapse] = useState(new Set());
  const [scrollPos, setScrollPos] = useState(null);
  const [loadingTree, setLoadingTree] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [loading, setLoading] = useState(null);
  const [selectList, setSelectList] = useState(null);
  const [appState, setAppState] = useState({});
  const [editMode, setEditMode] = useState(null);
  const [employeeType, setEmployeeType] = useState(null);
  // const [scrollPosition, setScrollPosition] = useState(0);
  const myDivRef = useRef(null);
  const { selectedDimensions, dimensionIdToData, scrollPosition } = appState;
  // const [idToAll, setIdToAll] = useState({});
  // const { selectedDimensions, dimensionIdToData, scrollPosition } = useSelector(
  //   (state) => {
  //     if (!state.DataReducer[id]) return {};
  //     return state.DataReducer[id];
  //   }
  // );
  function setScrollPosition(val) {
    updateState(val, "scrollPosition");
    // document.getElementById("ewScroll").scrollTop = val+20;
    console.log("updateing: ", val);
    if (myDivRef.current) {
      myDivRef.current.scrollTop = +val;
    }
  }
  const dispatch = useDispatch();
  function reCreateState(idToData, serverData) {
    idToAll = {};
    allToChildrenMap = {};
    let selected = {};
    dimensions.forEach((ele) => {
      let dim = ele.value;
      // selected[dim]=new Set();
      let arr = [];
      if (!serverData) return;
      let obj = serverData[dim];
      console.log(
        "idtodata: ",
        idToData,
        " ",
        dim,
        " ",
        idToData[dim],
        " ",
        serverData
      );
      if (!idToData[dim]) idToData[dim] = {};
      idToData[dim].any = obj.CoverageType;
      obj[payloadMapper[dim].array].forEach(({ TreeRule, ID }) => {
        if (TreeRule === "0") {
          let children = getAllChild(ID, dim, idToData);
          if (!children) children = [];
          arr = [...new Set([...arr, ...children, ID, ID + "-All"])];
          if (!allToChildrenMap[dim]) allToChildrenMap[dim] = {};
          allToChildrenMap[dim][ID + "-All"] = [...children, ID, ID + "-All"];
        } else {
          arr.push(ID);
        }
      });
      console.log("before setting: ", arr, " ", allToChildrenMap);
      selected[dim] = new Set(arr);
    });
    console.log("After coinvert: ", selected);
    setSelectedEntity(selected);
    if (selected["EmployeeTypeApplicability"]?.size > 0) {
      getEmployeeType("EmployeeTypeApplicability", true, idToData).then(
        (data) => {
          updateState(data, "dimensionIdToData");
        }
      );
    } else {
      updateState(idToData, "dimensionIdToData");
    }
  }
  useEffect(() => {
    if (value && passPayload !== true) {
      setLoading("Fetching Data...");
      getData({
        featureId: applicabilityFeatureID,
        payload: {
          "Header.TransactionID": value,
        },
        FeatureVariantID: "002",
      }).then((res) => {
        if (res.Data) {
          setLoading("Fetching Tree...");
          fetchTree()
            .then((newData) => {
              reCreateState(newData, res.Data[0]);
              setEditMode({
                TransactionID: res.Data[0].TransactionID,
                EffectiveFromTimestamp: res.Data[0].EffectiveFromTimestamp,
              });
            })
            .finally(() => {
              setLoading(null);
            });
        } else {
          setLoading(null);
        }
        console.log("From app: ", res);
      });
    }
  }, []);
  useEffect(() => {
    if (modal === true && passPayload === true) {
      setLoading("Fetching Tree...");
      fetchTree()
        .then((newData) => {
          reCreateState(newData, value);
        })
        .finally(() => {
          setLoading(null);
        });
      return;
    }
  }, [modal]);
  // useEffect(()=>{
  //   if (scrollPosition) {
  //     if (myDivRef.current) {
  //       myDivRef.current.scrollTop = scrollPosition;
  //     }
  //   }
  // },[selectedEntity])
  useEffect(() => {
    // setScrollPosition(myDivRef.current?.scrollTop);
    //myDivRef.current?.scrollTop=scrollPosition
    console.log("Scroll pos: ", scrollPosition);
    if (scrollPosition) {
      if (myDivRef.current) {
        myDivRef.current.scrollTop = scrollPosition;
      }
    }
  }, [scrollPosition, selectedEntity, collapse]);

  useEffect(() => {
    //
    if (expandAll === false) {
      setCollapse(new Set());
    } else if (selectedDimensions && selectedDimensions !== -1) {
      setCollapse(new Set(Object.keys(dimensionIdToData[selectedDimensions])));
    }
  }, [expandAll]);
  function transformData(arr) {
    let obj = {};
    for (let i = 0; i < arr.length; i++) {
      obj[arr[i].entityID] = {
        ...arr[i],
        children: new Set(),
      };
    }
    //console.log("Before transform: ", obj);
    for (let i = 0; i < arr.length; i++) {
      const { parentEntityID, entityID } = arr[i];
      if (!obj[parentEntityID])
        console.log("Before transform: ", parentEntityID, " ", obj, " ", arr);
      if (parentEntityID && parentEntityID !== "" && obj[parentEntityID]) {
        obj[parentEntityID].children.add(entityID);
      }
    }
    //Redux Does Not Work Well With Sets hence conver all sets to array
    Object.entries(obj).forEach(([key, value]) => {
      obj[key].children = [...obj[key].children];
    });
    // for (let i = 0; i < arr.length; i++) {
    //   const { parentEntityID } = arr[i];
    //   console.log("Object before snding: 1", obj[parentEntityID]);
    //   if (obj[parentEntityID])
    //     obj[parentEntityID].children = [...obj[parentEntityID].children];
    // }
    console.log("Object before snding: ", obj);
    return obj;
  }
  function updateTreeSturucture(arr, dim) {
    console.log("From tree: ", arr, " ", dim);
    if (dim) {
      let { val, res } = dim;
      let obj = dimensionIdToData;
      if (!obj) obj = {};
      let any = obj[val]?.any;
      if (!any || passPayload) any = "1";

      obj = {
        ...obj,
        [val]: {
          any,
          ...transformData(res.DimensionEntities),
        },
      };
      console.log("After added: ", obj, " ", res.DimensionEntities);
      updateState({ ...obj }, "dimensionIdToData");
      return null;
    } else {
      console.log("Got arr: ", arr);
      let obj = {};
      arr.forEach((ele) => {
        let { Dimension, DimensionEntities } = ele;
        obj = {
          ...obj,
          [Dimension + "Applicability"]: {
            any: "0",
            ...transformData(DimensionEntities),
          },
        };
      });
      //  updateState({ ...obj }, "dimensionIdToData");
      return obj;
    }
  }
  function fetchTree(val) {
    let payload = {
      function: "dimensionTree",
      FeatureID: val ? dimensionsIdMap[val] : "*",
      FeatureVariantID: val ? "000" : "*",
      DimensionID: "*",
      Direction: "B",
    };
    return new Promise((resolve, reject) => {
      getTree({ payload })
        .then((res) => {
          console.log("After fetching: ", res);
          if (res && res.DimensionEntities) {
            updateTreeSturucture(null, { val, res });
            resolve();
          } else if (res && res.DimensionTrees) {
            let newData = updateTreeSturucture(res.DimensionTrees);
            resolve(newData);
          }
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  }
  function getEmployeeType(val, returnValue, mapping) {
    const transFormArray = (arr) => {
      if (arr.length === 0) return {};
      let obj = {};
      arr?.forEach(({ TransactionID, EmployeeTypeName }) => {
        obj[TransactionID] = {
          children: [],
          entityID: TransactionID,
          entityName: EmployeeTypeName,
        };
      });
      return obj;
    };
    return new Promise((resolve, reject) => {
      getData({
        featureId: dimensionsIdMap[val],
        fields: ["Header.TransactionID", "Data.EmployeeTypeName"],
      })
        .then((res) => {
          if (res.Data) {
            const dimensionMapping = mapping || dimensionIdToData;
            dimensionMapping[val] = {
              any: "1",
              ...transFormArray(res.Data),
            };
            if (returnValue) {
              resolve(dimensionMapping);
              return;
            }
            updateState({ ...dimensionMapping }, "dimensionIdToData");
            setEmployeeType(res.Data);
            resolve();
          } else {
            reject();
          }
        })
        .catch((e) => {
          console.log(e);
          reject();
        });
    });
  }
  function updateState(val, type) {
    console.log("In update State ", val, type);

    if (type === "selectedDimensions" && val !== -1) {
      setLoadingTree(true);
      setEmployeeType(null);
      if (val === "EmployeeTypeApplicability") {
        getEmployeeType(val).finally(() => setLoadingTree(false));
      } else {
        fetchTree(val).finally(() => setLoadingTree(false));
      }
    }
    setAppState((prev) => ({
      ...prev,
      [type]: val,
    }));
    // dispatch({
    //   type: "UPDATE_PROPS",
    //   payload: {
    //     id,
    //     componentNumber: type,
    //     data: val,
    //   },
    // });
  }
  function checkBoxChanged(val, id, isAll, selecDim, onlyAll) {
    setScrollPosition(myDivRef.current?.scrollTop);
    if (!selecDim) selecDim = selectedDimensions;
    if (isAll) {
      console.log("Before call ", selecDim, id);

      let children = getAllChild(id, selecDim, dimensionIdToData);
      if (!children) children = [];

      children = [...children, id, id + "-All"];
      if (onlyAll) children = children.filter((item) => item.includes("-All"));
      if (!allToChildrenMap[selecDim]) allToChildrenMap[selecDim] = {};

      console.log("Children: 1", children);
      let selectSet = selectedEntity[selecDim];
      if (!selectSet) selectSet = [];
      if (val === true) {
        children
          .filter((item) => item.includes("-All"))
          .map((ele) => {
            allToChildrenMap[selecDim][ele] = [];
          });
        allToChildrenMap[selecDim][id + "-All"] = children;
        console.log("ON all true: ", allToChildrenMap);
        selectSet = [...selectSet, ...children];
      } else {
        console.log("Unselect: ", selectSet, " ", children);
        allToChildrenMap[selecDim][id + "-All"] = [];
        selectSet = [...selectSet].filter((item) => !children.includes(item));
        console.log("Unselect: 1", selectSet);
      }

      setSelectedEntity((prev) => ({
        ...prev,
        [selecDim]: new Set([...selectSet]),
      }));
      //setScrollPos(document.getElementById("ewScroll").scrollTop);
      return;
    }
    let selectSet = selectedEntity[selecDim];
    if (!selectSet) selectSet = new Set();
    if (val === true) {
      console.log("Select is: ", selectSet, " ", selectSet instanceof Set);
      selectSet.add(id);
    } else if (selectSet.has(id)) {
      if (selectSet.has(id + "-All")) {
        checkBoxChanged(false, id, true, selecDim, true);
        return;
      } else {
        selectSet.delete(id);
        let arr = idToAll[id];
        if (arr && arr.length > 0) {
          arr.forEach((ele) => selectSet.delete(ele));
        }
        idToAll[id] = [];
        console.log("Before: ", selectSet, " ", id);
        if (allToChildrenMap[selecDim]) {
          Object.entries(allToChildrenMap[selecDim]).forEach(([key, value]) => {
            if (value.includes(id)) {
              allToChildrenMap[selecDim][key] = [];
            }
          });
        }
        console.log(
          "is all present: ",
          selectSet.has(id + "-All"),
          " ",
          selectSet
        );
      }
    }

    console.log("Before collapse: ", selectedEntity);
    setSelectedEntity((prev) => ({
      ...prev,
      [selecDim]: new Set([...selectSet]),
    }));
    // setScrollPos(document.getElementById("ewScroll").scrollTop);
  }
  function getAllChild(id, selecDim, data) {
    // let data = dimensionIdToData;
    console.log("Before call 1", id, " ", selecDim);
    let children = [...data[selecDim][id].children];
    let list = [];
    //let obj = idToAll;
    let count = 0;

    while (children.length !== 0 && count < 10000) {
      count++;
      let child = children.pop();
      list.push(child);
      if (!idToAll[child]) idToAll[child] = [id + "-All"];
      else idToAll[child].push(id + "-All");
      let grandChildren = [];
      if (data[selecDim][child]?.children?.length > 0) {
        grandChildren = data[selecDim][child]?.children;
        let allChild = child + "-All";
        list = [...list, ...getAllChild(child, selecDim, data), allChild];
      }

      children = [...grandChildren, ...children];
    }

    return [...new Set(list)];
  }
  function renderTree(data, level) {
    let newData = {};
    console.log("inside renderTree", data);
    if (!data || Object.entries(data).length === 0) return <>No Data</>;

    if (level === 0) {
      Object.entries(data).map(([key, obj]) => {
        console.log("inside renderTree", obj);
        if (!obj) return;
        const { level, parentEntityID } = obj;
        if (level === 0 || parentEntityID === "0") {
          newData = {
            ...newData,
            [key]: data[key],
          };
        } else {
        }
      });
    } else {
      data?.forEach((ele) => {
        newData[ele] = dimensionIdToData[selectedDimensions][ele];
      });
    }
    function changeCollapseState(isExpand, id) {
      setScrollPosition(myDivRef.current?.scrollTop);
      let tempSet = collapse;
      if (isExpand) {
        tempSet.add(id);
      } else {
        tempSet.delete(id);
      }
      setCollapse(new Set([...tempSet]));
      // setScrollPos(document.getElementById("ewScroll").scrollTop);
    }

    console.log("Render tree: 1", newData, " ", dimensionIdToData);

    return (
      <div>
        <>
          {Object.entries(newData).map(
            ([key, { children, entityName, entityID }]) => {
              let collapsable = children.length > 0;
              let collapsed = collapse.has(entityID);
              let selected = selectedEntity[selectedDimensions]?.has(entityID);
              let allId = entityID + "-All";

              console.log("Before collapse: 1", collapsed, " ", collapse);
              return (
                <div style={{ position: "relative" }}>
                  {collapsable && (
                    <>
                      {!collapsed ? (
                        <div
                          className="pointer"
                          onClick={() => changeCollapseState(true, entityID)}
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                          }}
                        >
                          <AddIcon />
                        </div>
                      ) : (
                        <div
                          className="pointer"
                          onClick={() => changeCollapseState(false, entityID)}
                          style={{
                            display: "inline-block",
                            verticalAlign: "middle",
                          }}
                        >
                          <RemoveIcon />
                        </div>
                      )}
                    </>
                  )}
                  <div
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginLeft: collapsable && "1%",
                    }}
                  >
                    <Checkboxes
                      label={entityName}
                      value={selected}
                      onChange={(val) => checkBoxChanged(val, entityID)}
                    />
                  </div>
                  {collapsable && collapsed && (
                    <div style={{ marginLeft: "3.5%" }}>
                      {passPayload !== true && (
                        <div>
                          <Checkboxes
                            label={"All"}
                            value={selectedEntity[selectedDimensions]?.has(
                              allId
                            )}
                            onChange={(val) =>
                              checkBoxChanged(val, entityID, true)
                            }
                          />
                        </div>
                      )}
                      {renderTree(children, level - 1)}
                    </div>
                  )}
                </div>
              );
            }
          )}
        </>
      </div>
    );
  }
  function renderEmployeeType() {
    if (!Array.isArray(employeeType) || employeeType?.length === 0)
      return <></>;

    return (
      <Stack marginLeft={3}>
        {employeeType.map(({ TransactionID, EmployeeTypeName }) => {
          let selected = selectedEntity[selectedDimensions]?.has(TransactionID);
          return (
            <Stack gap={2}>
              <Checkboxes
                label={EmployeeTypeName}
                onChange={(val) => checkBoxChanged(val, TransactionID)}
                value={selected}
              />
            </Stack>
          );
        })}
      </Stack>
    );
  }
  function ModalContent() {
    let any;
    if (
      selectedDimensions &&
      dimensionIdToData &&
      dimensionIdToData[selectedDimensions]
    ) {
      any = dimensionIdToData[selectedDimensions].any;
    }
    function anyChange(flag) {
      let data = dimensionIdToData;
      if (flag === true) {
        data[selectedDimensions].any = "0";
        setSelectedEntity((prev) => ({
          ...prev,
          [selectedDimensions]: new Set(),
        }));
      } else if (flag === false) {
        data[selectedDimensions].any = "1";
      } else {
        data[selectedDimensions].any = flag;
      }
      updateState(data, "dimensionIdToData");
    }
    function getUnique(dimesnsion) {
      let data = [...selectedEntity[dimesnsion]];
      let temp = [];
      let allId = [];
      console.log("Inunique: ", allToChildrenMap);
      if (!allToChildrenMap[dimesnsion]) return data;
      Object.entries(allToChildrenMap[dimesnsion]).forEach(([key, value]) => {
        // value = [...new Set(value)];
        temp = [...new Set([...temp, ...value])];
        if (value.length > 0) allId.push(key);
      });
      // console.log("Inunique: ", data, " ", temp, " ", allToChildrenMap);
      return [...data.filter((item) => !temp.includes(item)), ...allId];
    }
    function onSave() {
      setLoading("Saving Applicability");
      let data = dimensionIdToData;
      let selected = selectedEntity;
      let payload = {};
      let dataPayload = {
        ApplicabilityCoverage: "1",
        AuthorFeatureID,
        ParentApplicabilityID: "*",
        AuthorBusinessRuleID: "*",
        AuthorFeatureVariantID: "000",
      };
      let count = 0;
      Object.entries(selected).forEach(([key, value]) => {
        payload[key] = {};
        let any = data[key].any;
        if (any === "0") {
          payload[key] = {
            CoverageType: "0",
            [payloadMapper[key].array]: [],
          };
        } else {
          let arr = getUnique(key).map((ele) => {
            let isAll = ele.includes("-All");
            if (isAll)
              return {
                TreeRule: "0",
                ID: ele.split("-")[0],
              };
            return {
              TreeRule: "1",
              ID: ele,
            };
          });
          if (arr.length === 0) {
            payload[key].CoverageType = "0";
            payload[key][payloadMapper[key].array] = [];
          } else {
            count++;
            payload[key].CoverageType = any;
            payload[key][payloadMapper[key].array] = arr;
          }

          console.log("From Save: ", arr, " ", payload[key]);
        }
      });
      Object.entries(payloadMapper).forEach(([key, value]) => {
        if (!payload[key]) {
          payload[key] = {
            CoverageType: "0",
            [value.array]: [],
          };
        }
      });
      if (count === 0) dataPayload.ApplicabilityCoverage = "0";
      dataPayload = {
        ...dataPayload,
        ...payload,
        EmployeeStatusApplicability: {
          CoverageType: "0",
        },
        ConfirmationStatusApplicability: {
          CoverageType: "0",
        },
      };
      // console.log("Final ", dataPayload);
      if (passPayload) {
        onChange(dataPayload);
        openModal(false);
        return;
      }
      SaveApplicability({
        payload: dataPayload,
        editMode,
        ApplicabilityReferenceField,
      })
        .then((res) => {
          if (res && res?.Status === "Success") {
            onChange(res.ApplicabilityID);
          }
        })
        .finally((e) => {
          setLoading(null);
        });
    }
    function SelectedList() {
      let { dimension, id } = selectList;
      let selected = selectedEntity[dimension];
      let labelData = dimensionIdToData[dimension];
      if (!allToChildrenMap[dimension]) return <></>;
      let alltoChildData = allToChildrenMap[dimension];
      console.log("All to child ", allToChildrenMap[dimension][id], " ", id);
      return (
        <div
          className="scroll"
          style={{ width: 0.5 * width, height: "40vh", overflowY: "auto" }}
        >
          {[...new Set(allToChildrenMap[dimension][id])]
            .filter((item) => !item.includes("-All"))
            .map((ele) => (
              <span style={{ lineHeight: 2.5 }}>
                {labelData[ele] && (
                  <span style={{ margin: 5 }}>
                    <Chip
                      label={labelData[ele].entityName}
                      onDelete={() => {
                        checkBoxChanged(false, ele, false, dimension);
                        setSelectList(null);
                      }}
                    />
                  </span>
                )}
              </span>
            ))}
        </div>
      );
    }
    if (loading) return <>{loading}</>;
    return (
      <div
        id="ewScroll"
        ref={myDivRef}
        className="scroll"
        style={{ width: 0.6 * width, overflowY: "auto", maxHeight: "70vh" }}
      >
        <EwModal open={selectList} onClose={() => setSelectList(null)}>
          <SelectedList />
        </EwModal>
        <h3>Applicability</h3>
        <hr />
        <br />
        <div>
          <Dropdown
            label="Select A Dimension"
            menuItems={passPayload === true ? dimensionForReport : dimensions}
            value={selectedDimensions}
            onChange={(val) => updateState(val, "selectedDimensions")}
          />
        </div>
        <br />
        {loadingTree ? (
          <>Loading Tree..</>
        ) : (
          <>
            <div>
              {employeeType ? (
                <>{renderEmployeeType()}</>
              ) : (
                <>
                  {dimensionIdToData &&
                    dimensionIdToData[selectedDimensions] && (
                      <>
                        <div
                          className="Flex-row"
                          style={{ justifyContent: "space-between" }}
                        >
                          {passPayload !== true && (
                            <Checkboxes
                              label={"Any"}
                              value={any === "0"}
                              onChange={(val) => anyChange(val)}
                            />
                          )}
                          <div>
                            <Button
                              variant={"Text"}
                              title={!expandAll ? "Expand All" : "Collapse All"}
                              onClick={() => setExpandAll(!expandAll)}
                            />
                          </div>
                        </div>
                        {any !== "0" && (
                          <>
                            <div>
                              <Radio
                                flex={1}
                                label={" "}
                                menuItems={
                                  passPayload !== true
                                    ? [
                                        { label: "Exclude", value: "1" },
                                        { label: "Include", value: "2" },
                                      ]
                                    : [{ label: "Include", value: "2" }]
                                }
                                value={any}
                                onChange={(val) => anyChange(val)}
                              />
                            </div>
                            <br />
                            {console.log(
                              "Before render: ",
                              selectedEntity[selectedDimensions]
                            )}
                            <div style={{ marginLeft: 30 }}>
                              {renderTree(
                                dimensionIdToData[selectedDimensions],
                                0
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                </>
              )}
              <br />

              {Object.keys(selectedEntity).length > 0 && (
                <span>Selected Values:</span>
              )}
              <hr />
              <br />
              {dimensionIdToData &&
                Object.keys(selectedEntity).map((ele) => {
                  if (selectedEntity[ele]?.size === 0) return <></>;

                  // let list = [...selectedEntity[ele]].filter(
                  //   (item) => !item.includes("All")
                  // );
                  let list = getUnique(ele);
                  console.log("From list: ", dimensionIdToData);
                  let data = dimensionIdToData[ele];

                  function deleteValue(id, dim, isAll) {
                    setScrollPosition(myDivRef.current?.scrollTop);
                    checkBoxChanged(false, id, isAll, dim);
                  }
                  return (
                    <div style={{ marginBottom: 20 }}>
                      <span
                        className="pointer"
                        onClick={() => updateState(ele, "selectedDimensions")}
                      >
                        {dimensionsValueToLabelMap[ele] + " :"}
                      </span>
                      <span style={{ lineHeight: 2.5 }}>
                        {list.map((id) => (
                          <>
                            <span>{"  "}</span>
                            {id.includes("-All") &&
                            allToChildrenMap[ele] &&
                            allToChildrenMap[ele][id]?.length > 0 ? (
                              <span
                                onClick={() =>
                                  setSelectList({ dimension: ele, id })
                                }
                                className="pointer"
                              >
                                <Chip
                                  className="pointer"
                                  style={{ backgroundColor: "#D8D8D8" }}
                                  label={
                                    data[id.split("-")[0]]?.entityName +
                                    " (All Values)"
                                  }
                                  onDelete={() =>
                                    deleteValue(id.split("-")[0], ele, true)
                                  }
                                />
                              </span>
                            ) : (
                              <>
                                {data[id] && (
                                  <Chip
                                    label={data[id].entityName}
                                    onDelete={() => deleteValue(id, ele)}
                                  />
                                )}
                              </>
                            )}
                          </>
                        ))}
                      </span>
                      <br />
                    </div>
                  );
                })}
            </div>
          </>
        )}
        <br />
        <br />
        <div
          style={{ width: "100%", position: "sticky", bottom: 0 }}
          className="Flex"
        >
          <Button
            style={{ width: "100%" }}
            title={"Save Applicability"}
            onClick={() => {
              onSave();
              console.log(
                "App save: ",
                allToChildrenMap,
                " ",
                dimensionIdToData
              );
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div>
      <EwModal open={modal} onClose={() => openModal(false)}>
        <ModalContent />
      </EwModal>
      {/* <Typography className="Flex-row g20">
        Applicable to Everyone <Checkboxes label={""} onChange={()=>{}} />
      </Typography>
      <Typography className="Flex">or</Typography> */}
      <br />
      <Button
        title={value ? "Edit Applicability" : "Add Applicability"}
        onClick={() => openModal(true)}
      />
    </div>
  );
}

export default Applicability5;
