import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  InputAdornment,
  IconButton,
  Input as MInput,
  FormControl,
  InputLabel,
  OutlinedInput,
  Grid,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import useWindowDimensions from "./Components/Utils/CustomHooks/WindowRezise";
import Molecule from "./Components/MComponent/Molecule";
import Atomic from "./Components/AComponent/Atomic";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { makeStyles } from "@mui/styles";

import {
  EmploywiseIcon,
  AppleStoreIcon,
  GooglePlayStoreIcon,
} from "./Components/Utils/Common/Icons";
import {
  performLogin,
  tamLogin,
  performChangePassword,
  getAuthDocument,
  validatePassword,
  getTokenFromCode,
  deleteTokenFromCookies,
} from "./Components/Utils/Common/Library";
import AlertMessage from "./Components/Utils/Messages/AlertMessage";
import ErrorBoundary from "./ErrorBoundary";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

import "./Login.css";
import { Actions } from "./Components/Redux/Actions/Actiontypes";

const SSOURL = "/Direct";

const BackIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.55199 8.25L7.19824 7.60375L5.09908 5.5L7.19824 3.39625L6.55199 2.75L3.80199 5.5L6.55199 8.25Z"
      fill="black"
      fill-opacity="0.87"
    />
  </svg>
);

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});
//console.log("Test excluded: ", window.Excluded);
const Excluded = window.Excluded;
const { EwModal } = Molecule;
const { Input, Button } = Atomic;
function SecurityWrapper(props) {
  let { children } = props;
  const url = new URL(window.location.href);
  const host = url.host;
  const list = host.split(".");
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);
  const [userFieldLabel, setLabel] = useState("Email");
  const [loaders, setLoaders] = useState({
    loadPage: true,
    loadLoginView: false,
  });
  let subDomainFromUrl = getSubDomain();
  if (window.location.href.includes("logoff=Y")) {
    let tokenFromCookie = Cookies.get("EwAccessToken");

    if (tokenFromCookie) {
      deleteTokenFromCookies();
    }
  }
  const [loginState, setLoginState] = useState({
    CustomerCode: subDomainFromUrl, //customer coder and subdomain are same thing
    UserName: "",
    Password: "",
    showPassword: false,
    otp: false,
    forgotPassword: false,
    next: 0,
    otpValues: [],
    codeNeeded: subDomainFromUrl === "" ? true : false,
    showChangePass: false,
    uniqueUserId: "", //needed For Set Password
    continue: true,
    isTam: false,
    passwordPolicy: null,
    fromMobile: false,
    authCode: null,
    isSso: false,
  });

  function updateState(val, type) {
    setLoginState((prev) => ({ ...prev, [type]: val }));
  }
  let token = Cookies.get("EwAccessToken");
  const idToken = Cookies.get("idToken");
  if (token?.trim(" ") === "undefined") token = null;
  const { width, height } = useWindowDimensions();
  const { loadPage, loadLoginView } = loaders;
  const isWidthBelow900 = width < 900;
  const inputWidth = "90%";

  function getSubDomain() {
    let isLocalHost = host.includes("localhost");
    if (!isLocalHost && list.length < 2) return "";
    if (isLocalHost && list.length === 1) return "";
    // let custCodeFromUrl = new URL(window.location.href).searchParams.get(
    //   "custCode"
    // );

    // if (custCodeFromUrl && custCodeFromUrl !== "") return custCodeFromUrl;
    if (list[0].toLowerCase() === "www") return "";
    if (list[0].toLowerCase() === "atreya") return "";
    // let temp=[...list];
    // temp.shift()
    // let newHost='www.'+temp.join(".");

    // window.history.pushState({path: url}, '', url);
    return list[0];
  }
  function TypingEffect() {
    const strings = [
      "Leave & Attendance",
      "Travel & Expense",
      "Performance Management",
      "Payroll & Taxation",
      "Hiring & Onboarding",
      "Employee Separation",
      "Learning & Development",
      "Timesheets",
      "Mobile App",
    ];

    //const [currentStringIndex, setCurrentStringIndex] = useState(0);
    //const [currentString, setCurrentString] = useState("");
    const [str, setStr] = useState("");
    //  const [currentLetterIndex, setCurrentLetterIndex] = useState(0);
    const isMountedRef = useRef(true); // Create a mutable ref

    useEffect(() => {
      function typeNextLetter(
        currentStringIndex,
        currentLetterIndex,
        currentString
      ) {
        let timmer = 100;

        const string = strings[currentStringIndex];

        currentString += string.charAt(currentLetterIndex);
        setStr(currentString);
        currentLetterIndex += 1;
        if (currentLetterIndex >= string.length) {
          currentStringIndex += 1;
          currentLetterIndex = 0;
          currentString = "";
          timmer = 2000;
        }

        if (currentStringIndex >= strings.length) {
          currentStringIndex = 0;
          currentLetterIndex = 0;
          currentString = "";
        }

        setTimeout(() => {
          typeNextLetter(currentStringIndex, currentLetterIndex, currentString);
        }, timmer);
      }

      // Start typing the first string

      typeNextLetter(0, 0, "");

      return () => {
        // Set the isMountedRef to false in the cleanup function
        isMountedRef.current = false;
      };
    }, []); // run only once
    // "#3EA347" }}>Employ</span>
    // <span style={{ color: "#646464"
    return (
      <div>
        <Typography variant="h4" color={"primary"} sx={{ textAlign: "center" }}>
          {str}
          <span className="blink_me">{" |"}</span>
        </Typography>
      </div>
    );
  }
  function reDirect(comp_code) {
    console.log("Redirect: ", comp_code);
    if (Excluded.includes(comp_code)) {
      getAuthConfig(comp_code);
      return;
    }

    let location = window.location;
    if (comp_code == "wehr") {
      location.href =
        "https://www.myemploywise.com/asperm/servlet/ggs.erm.SSOLogin.SSO_LoginRequestS?customer_code=" +
        comp_code;
    }
    if (comp_code == "blackberrys") {
      location.href =
        "https://fs.blackberrys.com/adfs/oauth2/authorize?response_type=code&resource=https://www.myemploywise.com/&client_id=1001&state=blackberrys&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OauthLoginS";
    } else if (comp_code == "altimetrik") {
      location.href =
        "https://sso.altimetrik.com/adfs/oauth2/authorize?response_type=code&resource=https://myemploywise.com/&client_id=EwWEfOgUTH01AsjK&state=altimetrik&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OauthLoginS";
    } else if (comp_code == "agilent") {
      location.href =
        "https://iam.myemploywise.com/auth/realms/agilent/protocol/openid-connect/auth?response_type=code&resource=https://www.myemploywise.com/&client_id=myemploywise&state=agilent&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OpenIdConnectS&scope=openid";
    } else if (comp_code == "altimetrik-test") {
      location.href =
        "https://iam.myemploywise.com/auth/realms/altimetrik/protocol/openid-connect/auth?response_type=code&resource=https://www.myemploywise.com/&client_id=myemploywise&state=altimetrik-test&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OpenIdConnectS&scope=openid";
    } else if (comp_code == "sinarmas") {
      location.href =
        "https://iam.myemploywise.com/auth/realms/sinarmas/protocol/openid-connect/auth?response_type=code&resource=https://www.myemploywise.com/&client_id=myemploywise&state=sinarmas&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OpenIdConnectS&scope=openid";
    } else if (comp_code == "avalara") {
      location.href =
        "https://iam.myemploywise.com/auth/realms/avalara/protocol/openid-connect/auth?response_type=code&resource=https://www.myemploywise.com/&client_id=myemploywise&state=avalara&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OpenIdConnectS&scope=openid";
    } else if (comp_code == "nabilbank") {
      location.href =
        "https://iam.myemploywise.com/auth/realms/nabilbank/protocol/openid-connect/auth?response_type=code&resource=https://www.myemploywise.com/&client_id=myemploywise&state=nabilbank&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OpenIdConnectS&scope=openid";
    } else if (comp_code == "totalitglobal") {
      location.href =
        "https://iam.myemploywise.com/auth/realms/totalitglobal/protocol/openid-connect/auth?response_type=code&resource=https://www.myemploywise.com/&client_id=myemploywise&state=totalitglobal&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OpenIdConnectS&scope=openid";
    } else if (comp_code == "teqfocus") {
      location.href =
        "https://iam.myemploywise.com/auth/realms/teqfocus/protocol/openid-connect/auth?response_type=code&resource=https://www.myemploywise.com/&client_id=myemploywise&state=teqfocus&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OpenIdConnectS&scope=openid";
    } else if (comp_code == "indinfravit") {
      location.href =
        "https://iam.myemploywise.com/auth/realms/indinfravit/protocol/openid-connect/auth?response_type=code&resource=https://www.myemploywise.com/&client_id=myemploywise&state=indinfravit&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OpenIdConnectS&scope=openid";
    } else if (comp_code == "indinfravit2") {
      location.href =
        "https://iam.myemploywise.com/auth/realms/indinfravit2/protocol/openid-connect/auth?response_type=code&resource=https://www.myemploywise.com/&client_id=myemploywise&state=indinfravit2&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OpenIdConnectS&scope=openid";
    } else if (comp_code == "verint") {
      location.href =
        "https://iam.myemploywise.com/auth/realms/verint/protocol/openid-connect/auth?response_type=code&resource=https://www.myemploywise.com/&client_id=myemploywise&state=verint&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OpenIdConnectS&scope=openid";
    } else if (comp_code == "keysight") {
      location.href =
        "https://iam.myemploywise.com/auth/realms/keysight/protocol/openid-connect/auth?response_type=code&resource=https://www.myemploywise.com/&client_id=myemploywise&state=keysight&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OpenIdConnectS&scope=openid";
    } else if (comp_code == "mbfsi") {
      location.href =
        "https://iam.myemploywise.com/auth/realms/mbfsi/protocol/openid-connect/auth?response_type=code&resource=https://www.myemploywise.com/&client_id=myemploywise&state=mbfsi&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OpenIdConnectS&scope=openid";
    } else if (comp_code == "ttpl") {
      location.href =
        "https://iam.myemploywise.com/auth/realms/ttpl/protocol/openid-connect/auth?response_type=code&resource=https://www.myemploywise.com/&client_id=myemploywise&state=ttpl&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OpenIdConnectS&scope=openid";
    } else if (comp_code == "bdx") {
      location.href =
        "https://iam.myemploywise.com/auth/realms/bdx/protocol/openid-connect/auth?response_type=code&resource=https://www.myemploywise.com/&client_id=myemploywise&state=bdx&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OpenIdConnectS&scope=openid";
    } else if (comp_code === "hsil") {
      location.href = "http://Hcm.hindware.com";
    } else if (comp_code == "interise") {
      location.href =
        "https://iam.myemploywise.com/auth/realms/indinfravit/protocol/openid-connect/auth?response_type=code&resource=https://www.myemploywise.com/&client_id=myemploywise&state=Interise&redirect_uri=https://www.myemploywise.com/asperm/servlet/ggs.erm.servlet.setup3.OpenIdConnectS&scope=openid";
    } else {
      let updatedHost = host;
      if (host.split(".").length === 2) {
        updatedHost = "www." + host;
      }
      let ew1Url = `https://${updatedHost}/asperm/servlet/ggs.erm.servlet.setup3.LoginS?customer_code=${comp_code}`;
      location.href = replaceSubdomain(ew1Url, "www");
      console.log("Redirect: ", comp_code, " ", test);
      //www.myemploywise.com
      //location.href
    }
  }

  console.log("Security: ", list);
  function getRole(roles) {
    let arr = [];
    ["O", "T", "M"].forEach((ele) => {
      if (roles.includes(ele)) arr.push(ele);
    });

    return arr;
  }
  function dispatchToken(token, refreshToken, loggedIn, isTam, subDomain) {
    if (window.location.href.includes("mobile=true")) {
      window.location.href =
        window.location.href.split("?")[0] + "?AuthToken=" + token;
      return;
    }
    if (isTam) {
      let obj = {
        Token: token,
        tenantCode: "TAM_EW",
        userId: "",
        subDomain: "tam",
        isTam: true,
      };
      const decodedIdToken = jwt_decode(token);
      console.log("TOken is: ", decodedIdToken);
      if (decodedIdToken["custom:UserRole"] === "TA") {
        dispatch({
          type: Actions.ADD_AUTH_DATA,
          payload: obj,
        });
        Cookies.set("idToken", token);
        Cookies.set("tamRefreshToken", refreshToken);
      } else {
        setMessage("Unauthorized");
      }

      return;
    }
    let cookieOptions;
    if (host.includes("chipsoft")) {
      cookieOptions = {
        domain: ".chipsoft.in",
      };
    } else if (host.includes("myemploywise")) {
      cookieOptions = {
        domain: ".myemploywise.com",
      };
    }

    Cookies.set("EwAccessToken", token, cookieOptions);
    if (refreshToken)
      Cookies.set("EwRefreshToken", refreshToken, cookieOptions);
    if (loginState.CustomerCode !== "")
      Cookies.set("subDomain", loginState.CustomerCode, cookieOptions);
    if (subDomain && subDomain !== "")
      Cookies.set("subDomain", subDomain, cookieOptions);
    //Cookies.set(cookieName, cookieValue, { domain: '.abc.com' });
    if (window.redirect) {
      if (new URL(window.location.href.replace("#/", "")).pathname === "/") {
        console.log(":Setting loader: ");
        setLoaders({ loadPage: true });
        // let custCode=loginState.CustomerCode;
        // if(custCode.trim(" ")!=="" && host.toLowerCase().includes("www")){
        //   host.split(".")
        // }
        //  const newHost = host.replace(/^[^.]*/, "www");

        const url = `https://${host}/asperm/servlet/ggs.erm.servlet.setup3.LoginS`;
        window.location.href = replaceSubdomain(url, "www");
        return;
      }
    }
    const decodedToken = jwt_decode(token);
    console.log("Decoded token : ", decodedToken);
    let tenantCode = decodedToken.iss.split("/").pop();
    //  let userRole=decodedToken

    console.log("Dispacthing: ");
    let obj = {
      Token: token,
      tenantCode,
      userId: decodedToken.UserID,
      subDomain: loginState.CustomerCode,
      role: getRole(decodedToken.realm_access?.roles),
    };
    dispatch({
      type: Actions.ADD_AUTH_DATA,
      payload: obj,
    });
    dispatch({
      type: Actions.ADD_USER_DETAILS,
      payload: decodedToken,
    });
    setLoaders({ loadLoginView: false, loadPage: false });
  }
  function replaceSubdomain(url, toSubdomain) {
    const replace = "://" + toSubdomain + ".";

    // Prepend http://
    if (!/^\w*:\/\//.test(url)) {
      url = "https://" + url;
    }

    // Check if we got a subdomain in url
    if (url.match(/\.\w*\b/g)?.length > 1) {
      return url.replace(/(:\/\/\w+\.)/, replace);
    }

    return url.replace(/:\/\/(\w*\.)/, `${replace}$1`);
  }
  function deleteToken() {
    // localStorage.removeItem("Ewtoken");
    Cookies.remove("EwAccessToken");
    Cookies.remove("EwRefreshToken");
  }
  function validateToken(token) {
    return true;
  }
  function getAuthConfig(subDomain) {
    const nameToLabelMap = {
      E: "Email",
      M: "Mobile",
      C: "Employee Code",
    };
    //Get Auth Document and in that document get field "LoginMethod"
    console.log("Setting loading false: 6");
    setLoaders({
      loadLoginView: true,
    });
    let SSO = false;
    getAuthDocument({ subDomain })
      .then((res) => {
        const { LoginMethod, LoginName, UIRedirectURL } = res;
        console.log("GEt Auth is: ", res);
        if (LoginName && nameToLabelMap[LoginName]) {
          setLabel(nameToLabelMap[LoginName]);
        }
        if (LoginMethod && LoginMethod !== "") {
          if (LoginMethod === "L") {
            updateState(false, "codeNeeded");
          } else if (
            LoginMethod === "S" &&
            UIRedirectURL &&
            UIRedirectURL !== ""
          ) {
            window.location.href = UIRedirectURL;
            SSO = true;
            setLoginState((prev) => ({ ...prev, isSso: true }));
            return;
            //open sso page
          }

          updateState(res, "passwordPolicy");
        } else {
          updateState(false, "codeNeeded");
          // setMessage({
          //   message: "Could Not Get Info",
          // });
        }
        updateState(subDomain, "CustomerCode");
        console.log("Document is: ", res);
      })
      .catch((e) => {
        console.log("Error: ", e);
        setMessage({
          message: "Network Error",
        });
      })
      .finally(() => {
        console.log("Setting loading false: 4");
        if (!SSO)
          setLoaders({
            loadPage: false,
            loadLoginView: false,
          });
      });
    //   let LoginMethod = "L";
    // if(url.includes("?")){

    // }

    // Init(true);
    // setLoaders({
    //   loadPage: false,
    //   loadLoginView: false,
    // });
  }

  function setTokenFromCode(value, subDomain) {
    getTokenFromCode({
      code: value,
      subDomain: subDomain || loginState.CustomerCode,
    }).then((res) => {
      if (res.access_token && res.refresh_token) {
        window.history.replaceState({}, "", "/");
        dispatchToken(
          res.access_token,
          res.refresh_token,
          false,
          false,
          subDomain
        );
      }
    });
  }
  function Init() {
    const toStoreInCookies = {
      url_doc: "sso_url_doc",
      user_id: "sso_user_id",
      role_id: "sso_role_id",
      cc: "sso_cc",
    };

    const search = window.location.search;
    const urlParams = new URLSearchParams(search);
    // const entries = urlParams.entries();
    const host = window.location.host;
    let doesSSOUrlExist = false;
    let cookieOptions = {};
    if (host.includes("chipsoft")) {
      cookieOptions = {
        domain: ".chipsoft.in",
      };
    } else if (host.includes("myemploywise")) {
      cookieOptions = {
        domain: ".myemploywise.com",
      };
    }
    for (let [key, value] of urlParams.entries()) {
      const keys = Object.keys(toStoreInCookies);
      if (keys.includes("url_doc")) doesSSOUrlExist = true;
      if (keys.includes(key)) {
        Cookies.set(toStoreInCookies[key], value, cookieOptions);
      }
    }
    if (!doesSSOUrlExist) {
      Cookies.remove(toStoreInCookies.url_doc, cookieOptions);
    }

    if (token) {
      console.log("Token is: inside", token, " ", typeof token);
      if (validateToken(token)) {
        dispatchToken(token);
        if (window.redirect) return;
      } else {
        deleteToken();
      }
    } else if (idToken) {
      updateState(true, "isTam");
      dispatchToken(idToken, null, false, true);
    } else if (url.href.includes("?")) {
      console.log("Key,vlaue: ");

      for (let [key, value] of urlParams.entries()) {
        console.log("Key,vlaue: ", key, " ", value);
        if (key === "access") {
          //setToken(value);
          dispatchToken(value);
        } else if (key === "user") {
          updateState(true, "setPassword");
        } else if (key === "userId") {
          console.log("Inside userid: ", value);
          setLoginState((prev) => ({
            ...prev,
            uniqueUserId: value,
            showChangePass: true,
          }));
        } else if (key === "tam") {
          console.log("Inside Tam");
          updateState(false, "codeNeeded");
          updateState(true, "isTam");
        } else if (key === "mobile" && value === "true") {
          updateState(true, "fromMobile");
        } else if (key === "code") {
          // let subDomain = params
          //   .find((item) => item.includes("state="))
          //   ?.split("=")[1];
          let subDomain = urlParams.get("state");

          if (subDomain && subDomain !== "") {
            setTokenFromCode(value, subDomain);
            setLoginState((prev) => ({ ...prev, authCode: value }));
          }

          return;
        }
      }
      // let params = url.href.split("?")[1].split("&");
      // console.log("Cookies key,value: params",params," ",url.href)

      // params.forEach((ele) => {
      //   let [key, value] = ele.split("=");
      //   console.log("Cookies key,value: ",key," ",value)

      // });
    } else {
      // if (loginState.CustomerCode.trim(" ") !== "") {
      //   getAuthConfig(loginState.CustomerCode);
      // }
    }
    if (subDomainFromUrl.trim(" ") !== "") {
      reDirect(subDomainFromUrl);
      return;
    }
    console.log("Setting loading false: 5");
    setLoaders({
      loadPage: false,
      loadLoginView: false,
    });
  }
  useEffect(() => {
    console.log("Token is: ", token);
    let custCodeFromUrl = new URL(window.location.href).searchParams.get(
      "custCode"
    );

    if (custCodeFromUrl && custCodeFromUrl !== "") {
      setLoginState((prev) => ({ ...prev, CustomerCode: custCodeFromUrl }));
    }
    if (
      window.location.href.includes("logoff=Y") &&
      custCodeFromUrl === "e-emphasys"
    ) {
      setLoaders({ loadPage: true });

      window.location.href = "https://e-emphasys.com/";
    } else {
      Init();
    }
  }, []);
  function onNextClick(subDomain) {
    console.log("Subdomain: ", subDomain);
    if (subDomain.trim(" ") === "") return;
    if (loginState.authCode) {
      setTokenFromCode(loginState.authCode, subDomain);
      return;
    }
    //Get Document based on subDomain
    reDirect(subDomain?.toLowerCase());
    window.history.replaceState({}, "", "/");
  }
  function LoginPage() {
    const {
      CustomerCode,
      UserName,
      Password,
      otp,
      otpValues,
      next,
      codeNeeded,
      showChangePass,
      uniqueUserId,
      isTam,
      forgotPassword,
      passwordPolicy,
      OldPassword,
      ConfirmPassword,
      isSso,
    } = loginState;

    function PasswordInput({ val, onChange, label, onKeyDown }) {
      const [showPassword, setShowPassword] = useState(false);
      if (!onKeyDown) onKeyDown = () => {};
      return (
        <FormControl sx={{ width: inputWidth }} variant="standard">
          <InputLabel htmlFor={label + "password"}>{label}</InputLabel>
          <MInput
            id={label + "Password"}
            variant="standard"
            value={val}
            onKeyDown={onKeyDown}
            onChange={(e) => onChange(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility Emplowise"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            type={showPassword ? "text" : "password"}
          />
        </FormControl>
      );
    }
    function onLogin(username, password) {
      if (!username || username?.trim(" ") === "") return;
      if (!password || password?.trim(" ") === "") return;

      // dispatchToken(username);
      // return;
      console.log("Setting loading false: 1");
      setLoaders({
        loadPage: false,
        loadLoginView: true,
      });
      if (isTam) {
        tamLogin({ username, password })
          .then((res) => {
            console.log("res from login: tam", res);
            if (res?.data?.idToken) {
              dispatchToken(
                res.data.idToken,
                res.data.refreshToken,
                false,
                true
              );
            }
          })
          .catch((e) => {
            setMessage({
              message: "Network Error",
            });
          })
          .finally(() => {
            console.log("Setting loading false: 2");
            setLoaders({
              loadPage: false,
              loadLoginView: false,
            });
          });
        return;
      }
      performLogin({ name: username, password, subDomain: CustomerCode })
        .then((res) => {
          if (res.Error || res.error) {
            setMessage({
              message: res.Error || res.error_description,
            });
            setLoaders({
              loadPage: false,
              loadLoginView: false,
            });
          } else {
            //access_token //
            dispatchToken(res.access_token, res.refresh_token, true);
            updateState(false, "continue");
          }
          console.log("reault is: ", res);
        })
        .catch((e) => {
          let msg = e.message ? e.message : "Netowrk Error";

          console.log("perform lofgin: err ", e);
          setMessage({
            message: msg,
          });
          console.log("Setting loading false: 10");
          setLoaders({
            loadPage: false,
            loadLoginView: false,
          });
        })
        .finally(() => {
          console.log("Setting loading false: 3");
          if (!window.redirect) {
            setLoaders({
              loadPage: false,
              loadLoginView: false,
            });
          }
          console.log("Setting username: ", username);
          updateState(username, "UserName");
          updateState(password, "Password");
        });
    }
    function OTPInput() {
      let arr = Array.from(Array(4).keys());

      const classes = useStyles();
      console.log("arr: ");
      return (
        <div className="Flex-row">
          {arr.map((ele) => (
            <div>
              <Input
                variant="standard"
                autoFocus={ele === next}
                label={<></>}
                styles={{ width: "6ch" }}
                maxChars={1}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                type={"number"}
                className={classes.input}
                value={otpValues[ele]}
                onChange={(val) => {
                  if (("" + val).length === 1) {
                    let arr1 = otpValues;
                    arr1[ele] = val;

                    if (ele < arr.length - 1) {
                      updateState(ele + 1, "next");
                    }
                    updateState(arr1, "otpValues");
                  }
                }}
              />
            </div>
          ))}
        </div>
      );
    }

    function ChangePassword({ setPasswordCase }) {
      console.log("Show passwrod: ", setPasswordCase);
      const [passwordState, setPassState] = useState({
        userName: UserName || "",
        password: Password || "",
        confirmPassword: ConfirmPassword || "",
        oldPassword: OldPassword || "",
        showPassword: false,
        message: null,
        load: false,
      });
      const {
        userName,
        showPassword,
        password,
        confirmPassword,
        oldPassword,
        message,
        load,
      } = passwordState;
      function updateState(val, type) {
        console.log("State updated: ", passwordState);

        setPassState((prev) => ({ ...prev, [type]: val }));
      }
      function isEmpty(...args) {
        console.log("args: ", args);
        for (let i = 0; i < args.length; i++) {
          if (!args[i] || args[i]?.trim(" ") === "") return args[i];
        }
        return null;
      }

      function onSave() {
        console.log("ON save");
        // setLoginState((prev) => ({
        //   ...prev,
        //   UserName: userName,
        //   Password: password,
        //   OldPassword: oldPassword,
        //   ConfirmPassword: confirmPassword,
        // }));
        let emptyField = !setPasswordCase
          ? isEmpty(userName, password, confirmPassword, oldPassword)
          : isEmpty(password, confirmPassword);
        if (emptyField !== null) {
          console.log("Update state: ", emptyField);
          updateState({ message: `Fields cannot be empty` }, "message");
          return;
        }
        if (password !== confirmPassword) {
          console.log("Update state: ", confirmPassword);
          updateState({ message: `Password Does Not Match` }, "message");
          return;
        }
        if (passwordPolicy) {
          let isValid = validatePassword(passwordPolicy, password);
          if (isValid.error) {
            setMessage({ message: isValid.error[0] });
            return;
          }
        }
        updateState(true, "load");
        performChangePassword({
          userName,
          password,
          oldPassword,
          subDomain: loginState.CustomerCode,
          uniqueId: setPasswordCase ? uniqueUserId : null,
        })
          .then((res) => {
            if (res.message || res.Message) {
              updateState(
                { message: res.message || res.Message, type: 0 },
                "message"
              );
              window.location.href = window.location.href.split("?")[0];
              // setTimeout(() => {
              //   setLoginState((prev) => ({ ...prev, showChangePass: false }));
              // }, 1500);
            } else if (res.Error) {
              updateState({ message: res.Error }, "message");
            }
            console.log("From change pass: ", res);
          })
          .catch((e) => {
            console.log("Error : ", e);
          })
          .finally(() => {
            updateState(false, "load");
          });
      }

      return (
        <>
          {message && (
            <span style={{ marginBottom: 20 }}>
              <AlertMessage
                {...message}
                onClose={() => updateState(null, "message")}
              />
            </span>
          )}

          <div className="Flex">
            {!setPasswordCase && (
              <>
                <span
                  className="Flex-row pointer"
                  onClick={() =>
                    setLoginState((prev) => ({
                      ...prev,
                      showChangePass: false,
                    }))
                  }
                  style={{ justifyContent: "flex-start", width: "90%" }}
                >
                  <BackIcon />
                  <Typography>Back</Typography>
                </span>
                <>
                  <Input
                    containerStyles={{ width: inputWidth, margin: 0 }}
                    styles={{ width: "100%", margin: 0 }}
                    label={userFieldLabel}
                    value={userName}
                    onChange={(val) => updateState(val, "userName")}
                    variant="standard"
                  />
                  <div style={{ margin: 8 }}></div>
                  <PasswordInput
                    label="Old Password"
                    value={oldPassword}
                    onChange={(val) => updateState(val, "oldPassword")}
                  />
                  <div style={{ margin: 8 }}></div>
                </>
              </>
            )}

            <PasswordInput
              label="New Password"
              value={password}
              onChange={(val) => updateState(val, "password")}
            />
            <div style={{ margin: 8 }}></div>

            <PasswordInput
              label="Confirm Password"
              value={confirmPassword}
              onChange={(val) => updateState(val, "confirmPassword")}
            />

            <div style={{ margin: 8 }}></div>
            <div>
              <Button
                style={{ width: 112 }}
                disable={load}
                title={"Save"}
                onClick={onSave}
              />
            </div>
          </div>
        </>
      );
    }
    function SubDomain() {
      const [subdomain, setSubdomain] = useState(
        CustomerCode ? CustomerCode : ""
      );
      function handleSubmit(event) {
        if (event.key === "Enter") {
          event.preventDefault();
          onNextClick(subdomain);
        }
      }
      return (
        <>
          <div className="Flex g20">
            <Input
              containerStyles={{ width: "90%" }}
              styles={{ width: "90%" }}
              variant="standard"
              label="Customer"
              value={subdomain}
              onChange={(val) => setSubdomain(val)}
              onKeyDown={handleSubmit}
            />

            <div className="Flex">
              <Button
                style={{ width: 112 }}
                title={"Next"}
                onClick={() => onNextClick(subdomain)}
              />
            </div>
          </div>
        </>
      );
    }
    function UserLogin() {
      const [state, setState] = useState({
        userName: UserName || "",
        password: Password || "",
        showPassword: false,
      });
      const { userName, password, showPassword } = state;
      function handleSubmit(event) {
        console.log("KEy entered ", event.key);
        if (event.key === "Enter") {
          event.preventDefault();
          onLogin(userName, password);
        }
      }
      let leftAlign = !isWidthBelow900 ? { alignItems: "flex-start" } : {};
      return (
        <>
          <>
            {!loginState.isTam && (
              <></>
              // <div
              //   className="Flex"
              //   style={{
              //     alignItems: "flex-start",
              //     width: "85%",
              //     marginBottom: 20,
              //   }}
              // >
              //   {/* <div
              //     onClick={() => updateState(true, "codeNeeded")}
              //     className="Flex-row pointer"
              //   >
              //     <BackIcon />
              //     <Typography variant="small_text1">Code</Typography>
              //   </div> */}
              //   {/* <Button
              //     startIcon={<BackIcon />}
              //     title={"⬅️ Code"}
              //     variant={"text"}
              //     onClick={() => {
              //       updateState(true, "codeNeeded");
              //     }}
              //   /> */}
              // </div>
            )}
          </>
          {/* <Typography className="Flex" variant="h4" sx={{ marginBottom: 5 }}>
            Enter Login Details
          </Typography> */}
          <div
            className="Flex g20"
            style={{ paddingLeft: isWidthBelow900 ? 0 : 30 }}
          >
            <Input
              containerStyles={{ width: inputWidth, margin: 0 }}
              styles={{ width: inputWidth, margin: 0 }}
              variant="standard"
              label={isTam ? "Mobile No." : userFieldLabel}
              value={userName}
              onKeyDown={handleSubmit}
              onChange={(val) => {
                setState((prev) => ({ ...prev, userName: val }));
              }}
            />
            <div style={{ width: inputWidth }}>
              <PasswordInput
                label="Password"
                value={password}
                onKeyDown={handleSubmit}
                onChange={(val) =>
                  setState((prev) => ({ ...prev, password: val }))
                }
              />
              {isTam && <br />}
              {!loginState.isTam && !isSso && (
                <div
                  className="Flex pointer"
                  style={{ alignItems: "flex-start", marginTop: 10, gap: 3 }}
                >
                  <Typography
                    onClick={() => updateState(true, "forgotPassword")}
                    variant="link_small"
                    color="#01579B"
                  >
                    Forgot Password?
                  </Typography>

                  <Typography
                    onClick={() => {
                      updateState(true, "showChangePass");
                    }}
                    variant="link_small"
                    color="#01579B"
                  >
                    Change Password
                  </Typography>
                </div>
              )}
              <br />
            </div>
          </div>

          <div className="Flex">
            <Button
              style={{ width: 112 }}
              title={"Login"}
              onClick={() => onLogin(userName, password)}
            />
          </div>
        </>
      );
    }
    function ForgetPassword() {
      const [username, setUsername] = useState("");
      const [message, setMessage] = useState(null);
      const [loading, setLoading] = useState(false);
      function sendLink() {
        if (username.trim(" ") === "") {
          setMessage({ message: "Please Enter A Username" });
          return;
        }
        setLoading(true);
        performLogin({ name: username, subDomain: CustomerCode, mode: "SE" })
          .then((res) => {
            if (res.Error) {
              setMessage({ message: res.Error });
            } else {
              setMessage({ message: "Email Sent!", type: 0 });
            }
          })
          .catch((e) => {
            console.log("Error: ", e);
          })
          .finally(() => {
            setLoading(false);
          });
      }
      return (
        <div className="Flex g20">
          {message && (
            <>
              <AlertMessage {...message} onClose={() => setMessage(null)} />
              <br />
            </>
          )}
          {/* <Button
            variant={"text"}
            title={"⬅️ Login"}
            onClick={() => updateState(false, "forgotPassword")}
          /> */}
          <div
            onClick={() => updateState(false, "forgotPassword")}
            className="Flex-row pointer"
            style={{ justifyContent: "flex-start", width: "90%" }}
          >
            <BackIcon />
            <Typography variant="small_text1"> Login</Typography>
          </div>
          <Input
            variant="standard"
            styles={{ width: "90%" }}
            containerStyles={{ width: "90%" }}
            label={userFieldLabel}
            value={username}
            onChange={(val) => setUsername(val)}
          />

          <Button
            disable={loading}
            title={"Send Reset Link"}
            onClick={sendLink}
          />
        </div>
      );
    }
    function renderRightView() {
      if (codeNeeded) return <SubDomain />;
      if (forgotPassword) return <ForgetPassword />;
      if (otp)
        return (
          <>
            <div className="Flex g20">
              <OTPInput />
              <Button
                variant="outlined"
                title={"Submit Otp"}
                onClick={() => {}}
              />
            </div>
          </>
        );
      if (showChangePass)
        return (
          <ChangePassword
            setPasswordCase={uniqueUserId && uniqueUserId !== "" ? true : false}
          />
        );

      return <UserLogin />;
    }
    console.log("width: ", width);
    const headingFontSize = width > 900 ? 70 : 40;
    //div className="Flex-row" style={{ height: "100%" }}

    return (
      <>
        <Grid container style={{ width: "100%", minHeight: 600 }}>
          <Grid item lg={8} md={12} sm={12} xs={12}>
            <div
              className="Flex g20"
              style={{
                borderRight: !isWidthBelow900 ? "2px solid black" : "",
                borderBottom: isWidthBelow900 ? "2px solid black" : "",

                height: "100%",
                // padding: 10,
                flex: 0.6,
              }}
            >
              {/* <Typography variant="h4">Welcome To</Typography> */}
              <EmploywiseIcon isWidthBelow900={isWidthBelow900} />
              <Typography
                variant={isWidthBelow900 ? "h3" : "h1"}
                sx={{ textAlign: "center" }}
              >
                <span>Welcome to </span>
                <span style={{ color: "#3EA347" }}>Employ</span>
                <span style={{ color: "#646464" }}>Wise</span>
              </Typography>
              {/* <div>
                <img
                  src={
                    "https://d1myhw8pp24x4f.cloudfront.net/software_logo/1492604458_employwise2_mid.jpg"
                  }
                  alt="TenantLogo"
                  height={100}
                />
              </div> */}

              <TypingEffect />

              <div className="Flex-row g20" style={{ flexWrap: "wrap" }}>
                <div
                  className="pointer"
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.employwise.ggs.employwise&hl=en_US",
                      "_blank"
                    )
                  }
                >
                  {/* <GoogleIcon fontSize="medium" /> */}
                  <GooglePlayStoreIcon />
                </div>
                <div
                  className="pointer"
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/in/app/employwise/id1191418553",
                      "_blank"
                    )
                  }
                >
                  <AppleStoreIcon />
                  {/* <AppleIcon fontSize="medium" /> */}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <div
              className="Flex"
              style={{ height: "100%", marginTop: isWidthBelow900 ? 20 : 0 }}
            >
              {message && (
                <div className="Flex">
                  <AlertMessage
                    {...message}
                    onClose={() => {
                      setMessage(null);
                    }}
                  />
                </div>
              )}
              {loadLoginView ? (
                <div className="Flex">Loading...</div>
              ) : (
                renderRightView()
              )}
            </div>
          </Grid>
        </Grid>
      </>
    );
  }

  function checkToken(token) {
    console.log("Token is local: ", token);
    if (idToken) return false;
    // if (loginState.continue === false) return true;
    const pathname = new URL(window.location.href.replace("#/", "")).pathname;
    console.log("Pathname: ", pathname);
    if (window.redirect && pathname === "/") return true;
    if (!token || token === NaN) return true;
    return false;
  }
  if (loadPage) return <>Loading...</>;
  if (checkToken(token))
    return (
      <div>
        {console.log("Loading: ", loadPage)}
        <EwModal
          removeCloseIcon={true}
          open={true}
          onClose={() => {}}
          width="70vw"
        >
          <div>
            <LoginPage />
          </div>
          {/* <div className="Flex">
            {error && <span color="red"> {error} </span>}
            <Input
              type="password"
              label={"Password"}
              value={password}
              onChange={setPasswrod}
            />
            <Button title={"Login"} onClick={onLogin} />
          </div> */}
        </EwModal>
      </div>
    );

  return <ErrorBoundary>{children}</ErrorBoundary>;
}

export default SecurityWrapper;
