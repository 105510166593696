import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Atomic from "../../../AComponent/Atomic";

const { MultiSelect, EwSwitch, Input, Dropdown, Radio } = Atomic;
const id = "MPP";
const DimensionFeatureId = "19144";
const stepOwners = [
  {
    label: "Subject User",
    value: "SU",
  },
  {
    label: "Transaction User",
    value: "TU",
  },
  {
    label: "Line Manager",
    value: "LM",
  },
  {
    label: "Functional Manager",
    value: "FM",
  },
  {
    label: "Legal Entity Head",
    value: "EH",
  },
  {
    label: "Business Unit Head",
    value: "BH",
  },
  {
    label: "Function Head",
    value: "FH",
  },
  {
    label: "Location Head",
    value: "LH",
  },
  {
    label: "Organisation Role",
    value: "OR",
  },
  {
    label: "Employee Role",
    value: "ER",
  },
  {
    label: "Employee Role In Location",
    value: "RL",
  },

  {
    label: "Employee Role In LegalEntity",
    value: "EL",
  },
  {
    label: "Employee Role In Business Unit",
    value: "EB",
  },
  {
    label: "Manager In Grade",
    value: "ManagerInGrade",
  },
  {
    label: "Role In Grade",
    value: "LG",
  },
  {
    label: "Team",
    value: "TE",
  },
];

function MultiRaters() {
  const {
    MultiRater,
    MultiRaterDimensions,
    dimList,
    MultiRaterFrequency,
    MultiRaterType,
    MultiRaterRule,
    MultiRaterSelfAssessment,
    MultiRaterConversations,
    MultiRaterScoreDisplay,
    MultiRaterAppraiserSubmission,
  } = useSelector((state) => {
    if (!state.DataReducer[id]) return {};
    return state.DataReducer[id];
  });
  const dispatch = useDispatch();
  function updateState(val, type) {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: type,
        data: val,
      },
    });
  }
  return (
    <div>
      <div className="Flex">
        <EwSwitch
          label="Multi Rater feedback"
          value={MultiRater === "E"}
          onChange={(val) => updateState(val ? "E" : "D", "MultiRater")}
        />
      </div>
      {MultiRater === "E" && (
        <>
          <div className="Flex">
            <div style={{ width: "100%" }}>
              <MultiSelect
                style={{ width: "100%" }}
                label={"Dimesnions"}
                mapper={{
                  label: "DimensionName",
                  value: "TransactionID",
                }}
                includeID={true}
                featureId={DimensionFeatureId}
                value={MultiRaterDimensions}
                apiResult={dimList}
                storeApiResult={(val) => updateState(val, "dimList")}
                onChange={(val) => updateState(val, "MultiRaterDimensions")}
              />
            </div>
          </div>
          <br />
          <br />
          <div className="Flex-row g20">
            <div>
              <Radio
                label="Frequency"
                menuItems={[
                  { label: "Along with periodic reviews", value: "P" },
                  { label: "Annual", value: "A" },
                ]}
                value={MultiRaterFrequency}
                onChange={(val) => updateState(val, "MultiRaterFrequency")}
              />
            </div>
            <div style={{ width: "50%" }}>
              <Dropdown
                label="Multi Rater Type"
                menuItems={stepOwners}
                value={MultiRaterType}
                onChange={(val) => updateState(val, "MultiRaterType")}
              />
            </div>
          </div>
          <br />
          <div className="Flex-row">
            <Radio
              label="Rater Rule"
              menuItems={[
                { label: "Raters must rate all parameters", value: "A" },
                { label: "Optional", value: "O" },
              ]}
              value={MultiRaterRule}
              onChange={(val) => updateState(val, "MultiRaterRule")}
            />

            <div
              className="Flex"
              style={{ alignItems: "flex-start", width: "50%" }}
            >
              <div>
                <EwSwitch
                  label="View Appraisee Self Assessment"
                  value={MultiRaterSelfAssessment === "E"}
                  onChange={(val) =>
                    updateState(val ? "E" : "D", "MultiRaterSelfAssessment")
                  }
                />
              </div>
              <div>
                <EwSwitch
                  label="View Appraisee Conversations"
                  value={MultiRaterConversations === "E"}
                  onChange={(val) =>
                    updateState(val ? "E" : "D", "MultiRaterConversations")
                  }
                />
              </div>
            </div>
          </div>
          <br />
          <div className="Flex-row">
            <div>
              <Radio
                label="Multi Rater Score Display"
                value={MultiRaterScoreDisplay}
                menuItems={[
                  { label: "Show Score across all raters", value: "AA" },
                  { label: "Show Score across type of raters", value: "AC" },
                  { label: "Show Score of each raters", value: "AR" },
                ]}
                onChange={(val) => updateState(val, "MultiRaterScoreDisplay")}
              />
            </div>
            <div>
              <Radio
                label="Appraiser Submission"
                value={MultiRaterAppraiserSubmission}
                menuItems={[
                  {
                    label: "Allow without awaiting Multi Rater Assessments",
                    value: "A",
                  },
                  { label: "revent appraiser from rating", value: "R" },
                ]}
                onChange={(val) =>
                  updateState(val, "MultiRaterAppraiserSubmission")
                }
              />
            </div>
          </div>
        </>
      )}
      <br />
    </div>
  );
}

export default MultiRaters;
