import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Atomic from "../../../AComponent/Atomic";
import WorkflowButton from "../../Workflow/WorkflowButton";

const { MultiSelect, EwSwitch, Input, Dropdown, Radio } = Atomic;
const id = "MPP";
const DimensionFeatureId = "19144";

function InterimReview() {
  const {
    InterimReview,
    InitiateReview,
    InterimWorkFlowID,
    InterimDimensions,
    dimList,
  } = useSelector((state) => {
    if (!state.DataReducer[id]) return {};
    return state.DataReducer[id];
  });
  const dispatch = useDispatch();
  function updateState(val, type) {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: type,
        data: val,
      },
    });
  }
  return (
    <div>
      <div className="Flex">
        <EwSwitch
          label="Interim Reviews"
          value={InterimReview === "E"}
          onChange={(val) => updateState(val ? "E" : "D", "InterimReview")}
        />
      </div>
      {InterimReview === "E" && (
        <>
          <div className="Flex-row">
            <WorkflowButton
              AuthorFeatureID={"19152"}
              value={InterimWorkFlowID}
              onChange={(val) => updateState(val, "InterimWorkFlowID")}
            />
          </div>
          <br />
          <div className="Flex-row">
            <div style={{ width: "50%" }}>
              <Dropdown
                label="Event to initiate Interim Review"
                menuItems={[
                  { label: "The role of the employee changes", value: "R" },
                  {
                    label: "An employee relocates to a new location",
                    value: "L",
                  },
                  {
                    label: "An appraiser's separation request gets accepted",
                    value: "S",
                  },
                  {
                    label: "The appraiser in an employee plan changes",
                    value: "A",
                  },
                  { label: "An employee's function changes", value: "F" },
                  {
                    label:
                      "Change in the applicability rule of the master plan or change in the applicability parameter values of the appraisee",
                    value: "P",
                  },
                ]}
                value={InitiateReview}
                onChange={(val) => updateState(val, "InitiateReview")}
              />
            </div>
            <div style={{ width: "50%" }}>
              <MultiSelect
                label={"Dimesnions"}
                mapper={{
                  label: "DimensionName",
                  value: "TransactionID",
                }}
                includeID={true}
                featureId={DimensionFeatureId}
                value={InterimDimensions}
                apiResult={dimList}
                storeApiResult={(val) => updateState(val, "dimList")}
                onChange={(val) => updateState(val, "InterimDimensions")}
              />
            </div>
          </div>
        </>
      )}

      <br />
    </div>
  );
}

export default InterimReview;
