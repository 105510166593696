import React from 'react';

function Card1({ cardInfo }){

    var Temp;

    const [cardClicked, setCardClicked] = React.useState(false);

    const handleCardClicked = () => {
        setCardClicked(true);
    }

    const ShowActionComponent = () => {
        return(
            <div>
                {/* {Temp = require("../Modal").default} */}
                <Temp 
                    setCardClicked={setCardClicked} 
                    cardInfo={cardInfo}
                />
            </div>
        );
    }

    return(
        <div className="card1">

            <div>
                transaction : {cardInfo.transactionId}
            </div>

            <div>
                user : {cardInfo.userId}
            </div>

            <div>
                {cardInfo.company}
            </div>

            <div>
                <button 
                    onClick={handleCardClicked}>
                        {cardInfo.action}
                </button>
            </div>

            {
                cardClicked ? <ShowActionComponent/> : null
            }
            
        </div>
    );
}

export default Card1;