import React, { useRef, useState } from "react";
import Tree from "../../MComponent/Tree/Tree";
import { componentMap, settings } from "./SettingsData";
import Tree2 from "../../MComponent/Tree/Tree2";
import LayoutRenderer from "../../Interpreter/LayoutRenderer";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { v4 as uuid } from "uuid";

function Settings2({ appBarHeight }) {
  const [component, setComponent] = useState(null);
  const ref = useRef();
  function onItemClick(ele, index) {
    console.log(ele, "SETTINGS2");
    if (ele.children) return;
    if (ele.layout) {
      setComponent({
        label: ele.label,
        Component: LayoutRenderer,
        props: { Data: ele.layout, containerId: uuid() },
      });
      return;
    }
    if (ele.layoutId) {
      setComponent({
        label: ele.label,
        Component: LayoutRenderer,
        props: { Modal: { id: ele.layoutId, containerId: uuid() } },
      });
      return;
    }
    if (ele.Component) {
      setComponent({
        label: ele.label,
        Component: componentMap[ele.Component],
        props: {},
      });
      return;
    }
    setComponent(null);
    return;
  }

  return (
    <Box sx={{ display: "flex", gap: "0.7em", margin: "0 1em 1em 0em" }}>
      <Box
        className="invisble-scroll"
        elevation={0}
        style={{
          height: `calc(100vh - ${appBarHeight}px - 3em)`,
          position: "sticky",
          top: appBarHeight,
          flexBasis: "300px",
          flexShrink: "0",
        }}
      >
        <Tree2 data={[...settings]} onItemClick={onItemClick}>
          Surya
        </Tree2>
      </Box>
      <Paper
        elevation={10}
        className="invisble-scroll"
        ref={ref}
        sx={{
          height: `calc(100vh - ${appBarHeight}px - 3em)`,
          flexShrink: "0",
          flexBasis: "1044px",
          paddingRight: "4em",
          paddingLeft: "3em",
          flexGrow: "1",
        }}
      >
        {component && (
          <Box sx={{ maxWidth: "100%" }}>
            <component.Component
              {...component.props}
              contentHeight={
                ref.current?.offsetHeight +
                parseInt(window.getComputedStyle(ref.current)?.marginBottom)
              }
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
}

export default Settings2;
