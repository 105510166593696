import { Box, Typography } from "@mui/material";
import React from "react";
import Atomic from "../../../../AComponent/Atomic";

const { Button } = Atomic;

const DeleteAlert = ({ name, closeAlert, onDelete }) => {
  return (
    <Box sx={{ padding: "0.5em" }}>
      <Typography variant="h6" fontWeight={"400"}>
        Are you sure you want to delete  ?
        {/* <b>{name}</b> */}
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "2em",
        }}
      >
        <Button
          color={"error"}
          variant={"text"}
          title={"Cancel"}
          onClick={closeAlert}
        />
        <Button
          color={"error"}
          variant={"contained"}
          style={{ marginLeft: "1em" }}
          title={"Delete"}
          onClick={() => {
            onDelete();
            closeAlert();
          }}
        />
      </Box>
    </Box>
  );
};

export default DeleteAlert;
