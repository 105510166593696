import { Box, Divider, Paper, Typography, Stack } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import DataGrid2 from "../../MComponent/Table/DataGrid2";
import { getDateFromEpoch, getUserId } from "../../Utils/Common/Library";
import { constants } from "./Dimension/Data/QueryConstants";
import store from "../../Redux/Store";
import Atomic from "../../AComponent/Atomic";
import { useHistory } from "react-router-dom";

const { Button } = Atomic;

function ViewBox({ rowData }) {
  console.log(rowData, "PLAN");
  const Plan = rowData;
  if (!Plan.Dimensions) Plan.Dimensions = [];
  if (!Plan.Sections) Plan.Sections = [];

  return (
    <div>
      <Link to={{ pathname: "/pms", state: { Plan } }}>View</Link>
    </div>
  );
}

function UserType({ rowData }) {
  console.log("Dta is: ", rowData);
  const dimensions = rowData?.Dimensions;
  const userId = getUserId();
  let type = [];
  if (dimensions?.length > 0) {
    for (let i = 0; i < dimensions.length; i++) {
      const { AppraiserID, ReviewerID } = dimensions[i];
      if (userId === AppraiserID) {
        type.push("Appraiser");
      }
      if (userId === ReviewerID) {
        type.push("Reviewer");
      }
    }
  }
  type = [...new Set(type)];
  return (
    <Stack justifyContent={"center"} height={"100%"}>
      <span>{type.join(",") || "N/A"}</span>
    </Stack>
  );
}
const MyTeamPlan = () => {
  const history = useHistory();

  const [plans, setPlans] = useState([]);
  let { role, userId } = store.getState()?.AuthReducer;
  // const isButonViewable = filterData(plans)?.length > 0;

  function filterData(queryData) {
    if (!queryData) return [];
    return queryData;
    // return queryData.filter((data) => {
    //   let isAppraisee = getUserId() === data.AppraiseeUserID;
    //   let isAppraiser = false;
    //   let isReviewer = false;
    //   let isModerator = (store.getState()?.AuthReducer?.role || []).includes(
    //     "M"
    //   );

    //   const Dimensions = data.Dimensions;

    //   Dimensions.forEach((d) => {
    //     if (d.AppraiserID === getUserId()) {
    //       isAppraiser = true;
    //     }
    //     if (d.ReviewerID === getUserId()) {
    //       isReviewer = true;
    //     }
    //   });

    //   return isModerator || isAppraisee || isAppraiser || isReviewer;
    // });
  }
  const queryObj = role.includes("M")
    ? { QueryName: constants.FindAllEPPWithPersonName, RequiredFields: [] }
    : {
        QueryName: constants.FindAllEPPByAppraiserOrReviewer,
        RequiredFields: [
          {
            UserID: userId,
          },
        ],
      };

  console.log("From myteam plans: ", queryObj);
  return (
    <div className="Flex">
      <div>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h4">HR View</Typography>
          {/* {isButonViewable && (
            <Button
              label={"View Score Distribution"}
              onClick={() => {
                history.push("/dashboard");
              }}
              variant={"text"}
              title={"View Score Distribution"}
            />
          )} */}
        </Box>
        <Divider sx={{ margin: "2em 0" }} />
        <Paper sx={{ width: "70vw" }}>
          <DataGrid2
            // featureId={"19151"}
            saveServerData={(data) => {
              setPlans(data);
            }}
            query={queryObj}
            heading={[
              "Plan Period",
              "Employee code",
              "Employee Name",
              "Start Date",
              "End Date",
              "User Type",
            ]}
            valueFormatter={{
              PlanStartDate: (val) => getDateFromEpoch({ epoch: val }),
              PlanEndDate: (val) => getDateFromEpoch({ epoch: val }),
            }}
            rowToFieldMap={[
              "PlanPeriod",
              "AppraiseeUserEmployeeCode",
              "AppraiseeUserFullName",
              "PlanStartDate",
              "PlanEndDate",
              {
                Component: UserType,
                props: {},
              },
            ]}
            filterQueryData={(queryData) => {
              // console.log(queryData, "queryData", getUserId());
              // return queryData;
              return filterData(queryData);
            }}
            onRowClicked={({ data }) => {
              console.log(data, "THISISROWDATA");
              const Plan = data;
              if (!Plan.Dimensions) Plan.Dimensions = [];
              if (!Plan.Sections) Plan.Sections = [];
              history.push({ pathname: "/pms", state: { Plan } });
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default MyTeamPlan;
