import React, { useState, useEffect } from "react";
import Atomic from "../../../AComponent/Atomic";
import EwTable from "../../../MComponent/Table/Table";
import EditIcon from "@mui/icons-material/Edit";
import AlertMessage from "../../../Utils/Messages/AlertMessage";
import { submitPayload, getData } from "../../../Utils/Common/Library";

const { Button, Input, EwSwitch, Dropdown } = Atomic;

const FINE_NAME = "PDG.js";
const FeatureID = "19144";

function PDG() {
  const [localState, setLocalState] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const {
    DimensionName,
    ParameterLabel,
    DimensionType,
    FirstGrouping,
    FirstGroupingLabel,
    SecondGrouping,
    SecondGroupingLabel,
    TransactionID,
    EffectiveFromTimestamp,
    DimensionDescription,
    FirstGroupDescription,
    SecondGroupDescription,
  } = localState;
  function updateState(val, type) {
    setLocalState((prev) => ({ ...prev, [type]: val }));
  }
  useEffect(() => {
    if (openForm === false) {
      setLocalState({});
    }
  }, [openForm]);

  function onSave() {
    setLoading(true);

    let payload = {
      DimensionName,
      SecondGroupingLabel,
      SecondGrouping: SecondGrouping ? "Enabled" : "Disabled",
      FirstGrouping: FirstGrouping ? "Enabled" : "Disabled",
      FirstGroupingLabel,
      ParameterLabel,
      DimensionType: DimensionType || "",
      DimensionDescription: DimensionDescription || "",
      FirstGroupDescription: FirstGroupDescription || "",
      SecondGroupDescription: SecondGroupDescription || "",
    };
    if (!FirstGrouping) {
      payload.SecondGrouping = "Disabled";
      payload.SecondGroupingLabel = "";
      payload.SecondGroupDescription = "";
    }
    let edit = null;
    if (TransactionID) {
      edit = {
        TransactionID,
        EffectiveFromTimestamp,
        RequestType: "1",
      };
    }
    console.log("Payload: ", payload);

    submitPayload({ featureId: "19144", payload, edit })
      .then((res) => {
        if (res.message === "Document inserted") {
          setMessage({
            type: 0,
            message: "New Dimension Created!",
          });
          setOpenForm(false);
        } else if (res.message === "Document Edited") {
          setMessage({
            type: 0,
            message: "Dimension Edited!",
          });
        }
        setOpenForm(false);
      })
      .catch((e) => {
        setMessage({
          type: 1,
          message: "Network Error!",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }
  // function Form({ data }) {

  //   useEffect(() => {

  //     if (data) {
  //       setAddNew(true)
  //       setLocalState({ ...data });
  //     }
  //     setLoading(false);
  //   }, []);

  //   // if (loading) return <>Loading...</>;
  //   return (

  //   );
  // }
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
  }, [message]);

  function docSelected(data) {
    console.log("Sleected data: ", data);
    let { FirstGrouping, SecondGrouping } = data;
    data.FirstGrouping = FirstGrouping === "Enabled";
    data.SecondGrouping = SecondGrouping === "Enabled";
    setLocalState(data);
    setOpenForm(true);
  }

  const heading = ["Dimension"];
  const dimType = [
    {
      label: "Performance",
      value: "PE",
    },
    {
      label: "Potential",
      value: "PO",
    },
  ];
  if (loading) return <>Loading...</>;
  return (
    <>
      {message && (
        <AlertMessage type={message.type} message={message.message} />
      )}
      {!openForm ? (
        <div>
          <EwTable
            heading={heading}
            featureId={FeatureID}
            rowToFieldMap={["DimensionName"]}
            onDocSelected={docSelected}
            includeOprations={true}
          />
          <br />
          <div className="Flex">
            <Button title={"Add New"} onClick={() => setOpenForm(true)} />
          </div>
        </div>
      ) : (
        <div>
          <div>
            <Button
              variant={"Text"}
              title={"⬅️ Dimensions"}
              onClick={() => setOpenForm(false)}
            />
          </div>
          <br />
          <div className="Flex-row">
            <div>
              <Input
                value={DimensionName}
                onChange={(val) => updateState(val, "DimensionName")}
                label="Dimension Name"
              />
            </div>
            <div style={{ width: "35%" }}>
              <Dropdown
                label={"Dimension Type"}
                menuItems={dimType}
                value={DimensionType}
                onChange={(val) => updateState(val, "DimensionType")}
              />
            </div>
            <div>
              <Input
                value={ParameterLabel}
                onChange={(val) => updateState(val, "ParameterLabel")}
                label="Paramater Label"
              />
            </div>
          </div>
          <br />
          <div className="Flex">
            <Input
              value={DimensionDescription}
              onChange={(val) => updateState(val, "DimensionDescription")}
              label="Dimension Description"
              rows={4}
            />
          </div>
          <br />
          <div className="Flex-row" style={{ gap: 50 }}>
            <div>
              <div>
                <EwSwitch
                  value={FirstGrouping}
                  onChange={(val) => updateState(val, "FirstGrouping")}
                  label="First Group"
                />
              </div>
              <div>
                {FirstGrouping && (
                  <Input
                    value={FirstGroupingLabel}
                    onChange={(val) => updateState(val, "FirstGroupingLabel")}
                    label="First Group Label"
                  />
                )}
              </div>
              <div>
                {FirstGrouping && (
                  <Input
                    value={FirstGroupDescription}
                    onChange={(val) =>
                      updateState(val, "FirstGroupDescription")
                    }
                    label="First Group Description"
                    rows={4}
                  />
                )}
              </div>
            </div>
            <div>
              {FirstGrouping && (
                <div>
                  <EwSwitch
                    value={SecondGrouping}
                    onChange={(val) => updateState(val, "SecondGrouping")}
                    label="Second Group"
                  />
                </div>
              )}
              <div>
                {SecondGrouping && FirstGrouping && (
                  <Input
                    value={SecondGroupingLabel}
                    onChange={(val) => updateState(val, "SecondGroupingLabel")}
                    label="Second Group Label"
                  />
                )}
              </div>
              <div>
                {SecondGrouping && FirstGrouping && (
                  <Input
                    value={SecondGroupDescription}
                    onChange={(val) =>
                      updateState(val, "SecondGroupDescription")
                    }
                    label="Second Group Description"
                    rows={4}
                  />
                )}
              </div>
            </div>
          </div>
          <br />
          <div className="Flex">
            <Button title={"Save"} onClick={onSave} />
          </div>
        </div>
      )}
    </>
  );
}

export default PDG;
