import { Box, Typography } from "@mui/material";
import React from "react";
import Progress from "../../../../AComponent/CircularProgress/Progress";

const InfoPage = ({ type, Name, ShortName, weight = 25, Description }) => {
  console.log(ShortName);
  return (
    <Box sx={{ width: "40vw" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#F8F4EA",
          padding: "1em",
          marginBottom: "2em",
        }}
      >
        <Box>
          <Typography variant="h4">{Name}</Typography>
          <Typography variant="body1">{ShortName}</Typography>
        </Box>
        <Progress label={"Weight"} size={50} value={weight} />
      </Box>
      <Box>
        <Typography variant="body1">{Description}</Typography>
        {type === "p" && (
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Box>
              <Typography variant="h6">Target Type</Typography>
              <Typography variant="body1">Single Value</Typography>
            </Box>
            <Box>
              <Typography variant="h6">Target</Typography>
              <Typography variant="body1">25</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default InfoPage;
