import React from 'react';
import AButton1 from '../Atmoic/AButton1';
import AText1 from '../Atmoic/AText1';
import AImg1 from '../Atmoic/AImg1';

const atomicComponentMap = {
    AButton1 : AButton1,
    AText1 : AText1,
    AImg1 : AImg1,
}

function Card4({ cardInfo }){

    const Ph1 = atomicComponentMap[cardInfo.Placeholder1.AtomicComponent]; 

    const Ph2 = atomicComponentMap[cardInfo.Placeholder2.AtomicComponent];

    const Ph3 = atomicComponentMap[cardInfo.Placeholder3.AtomicComponent];
    
    

    return(
        <div className="card4">            

            {/* place holder 1  */}
            <div className="Card4Placeholder1">
                <Ph1 label={cardInfo.Placeholder1.props.text}/>
            </div>


            {/* place holder 2 */}
            <div className="Card4Placeholder2">
                <Ph2 label={cardInfo.Placeholder2.props.text}/>
            </div>

            {/* place holder 3 */}
            <div>
                <Ph3/>
            </div>
        </div>
    );
}

export default Card4;