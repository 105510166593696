import React from 'react'
import TextField from '@mui/material/TextField'

function AInput_1({ Variant, FieldProps, Label }) {
  return (
    <>
      <TextField variant={Variant} {...FieldProps} label={Label} />
    </>
  )
}

export default AInput_1
