import React from "react";
import  TextField  from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
// import { DatePicker,MuiPickersUtilsProvider } from '@material-ui/pickers'

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }));



function DateInput_1(){
    const classes = useStyles();
    function disableWeekends(date){
      console.log(date.target.value)
    }
    return(
        <>
          <TextField
        id="date"
        label="Birthday"
        type="date"
        defaultValue="2017-05-24"
       
        InputLabelProps={{
          shrink: true,
         
        }}
        inputProps={{
          min: "2020-10-10",
          
        }}
        onChange={disableWeekends}
      
      />
      {/* <MuiPickersUtilsProvider>
      
      <DatePicker hintText="Random Dates Disabled" shouldDisableDate={disableWeekends} />
      </MuiPickersUtilsProvider> */}
   
        </>
    )
}


export default DateInput_1;