import React, { useEffect, useState } from "react";
import EWModal from "./utils/EWModal";
import Menu from "../Utils/RightClickMenu/Menu";
import Input from "../AComponent/Input/Input_3";
import Button from "@mui/material/Button";
import AlertDialog from "../Utils/Dialog/Dialog";
import { Paper } from "@mui/material";
import Button_2 from "../AComponent/Buttons/Button_2";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../Utils/Messages/AlertMessage";
import { v4 as uuid } from "uuid";
import Atomic from "../AComponent/Atomic";
import Molecule from "../MComponent/Molecule";
import { Actions } from "../Redux/Actions/Actiontypes";
import store from "../Redux/Store";

var count = 0;
var groupRef = {};

function CompTest({ containerId, children, Data }) {
  console.log("test called");
  function deleteMessage() {
    store.dispatch({
      type: Actions.UPDATE_PROPS,
      payload: {
        id: containerId,
        fieldName: "otherData",
        data: null,
        props: "layoutMessage",
      },
    });
  }
  const otherData = useSelector((state) => {
    if (
      !state.DataReducer[containerId] ||
      !state.DataReducer[containerId]?.otherData
    )
      return {};
    return state.DataReducer[containerId].otherData;
  });
  const { layoutLoading, layoutMessage, enabledGroups } = otherData;
  console.log("Other data: ", otherData);
  function setEnabledGroup(groupsArr) {
    if (containerId && groupsArr) {
      store.dispatch({
        type: Actions.UPDATE_PROPS,
        payload: {
          data: groupsArr,
          id: containerId,
          fieldName: "otherData",
          props: "enabledGroups",
        },
      });
    }
  }
  useEffect(() => {
    console.log("Other data: enabled", Data.enabledGroups, " ");
    if (Data.enabledGroups && !enabledGroups) {
      setEnabledGroup(Data.enabledGroups);
    }
  }, [containerId, layoutLoading]);
  if (layoutLoading) return <>Loading...</>;
  return (
    <>
      {layoutMessage && (
        <AlertMessage {...layoutMessage} onClose={deleteMessage} />
      )}
      {children}
    </>
  );
}
function HOC(props) {
  const { Data, containerId } = props;

  return (
    <div id="layout-hoc">
      {/* <CompTest containerId={containerId} > */}
      <CompTest containerId={containerId} Data={Data}>
        <LayoutRenderer {...props} />
      </CompTest>
    </div>
  );
}

function LayoutRenderer(props) {
  const { Data, Shell, containerId } = props;
  const [menuCoords, setMenuCoords] = useState(null);
  const [openComment, setOpenComment] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Shell?.id) {
      dispatch({
        type: "GROUP_REFERNECE",
        payload: {
          id: Shell.id,
          groupRef,
        },
      });
    }

    window.addEventListener("click", (e) => {
      console.log("clicked: ", menuCoords);
      setMenuCoords(null);
    });
  }, []);
  useEffect(() => {
    return () => {
      store.dispatch({
        type: Actions.REMOVE_SHELL_DATA,
        payload: {
          id: containerId,
        },
      });
    };
  }, []);
  if (!Data?.layout) return <></>;
  function getStylesFromFlex(flex) {
    const mapping = {
      start: "flex-start",
      end: "flex-end",
      center: "center",
      top: "flex-start",
      bottom: "flex-end",
      around: "space-around",
      between: "space-between",
    };

    const style = {};
    if (flex.rowFlex) {
      style.justifyContent = mapping[flex.rowFlex];
    }
    if (flex.colFlex) {
      style.alignItems = mapping[flex.colFlex];
    }
    if (flex.direction) {
      style.flexDirection = flex.direction;
    }
    if (flex.width) {
      style.width = flex.width;
    }
    if (flex.height) {
      style.height = flex.height;
    }

    return style;
  }
  function Div(props) {
    let { children } = props;
    //    delete props["children"];

    return (
      <div {...props} children="">
        {children}
      </div>
    );
  }
  function getComponentByName(compDetails) {
    var name = compDetails.componentName;
    //if()
    if (name === "break") return () => <br />;
    if (compDetails.Repeater) {
      return require("../Utils/Repeater/Repeater").default;
    }

    let compArr = name.split(".");
    let compAddress;
    if (compArr.length === 2) {
      if (["A", "M"].includes(compArr[0])) {
        let componentList = Atomic;
        if (compArr[0] === "M") componentList = Molecule;
        return componentList[compArr[1]];
      } else {
        compAddress = compArr[0] + "Component/" + compArr[1];
      }
    } else
      compAddress = compArr[0] + "Component/" + compArr[1] + "/" + compArr[2];
    console.log("Layout Renderer address: ", compAddress);
    try {
      return require("../" + compAddress).default;
    } catch (e) {
      console.log("Layout111: ", e, " ", compAddress);
      return <>Error</>;
    }
  }
  function DialogCompoennt() {
    return (
      <>
        <Input label="Remarks" />
        <Button onClick={() => setOpenComment(false)}>Add</Button>
      </>
    );
  }
  function Render(layout) {

    return (
      <>
        {
          <AlertDialog
            open={openComment}
            title="Add Comment"
            component={DialogCompoennt()}
            handleClose={() => setOpenComment(false)}
          />
        }
        {layout.map((ele, index) => {
          console.log("EleFlex ", ele);
          let style = {
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          };
          if (ele.Flex && Object.keys(ele.Flex).length > 0) {
            style = { ...style, ...getStylesFromFlex(ele.Flex) };
          } else {
            style = {};
          }
          let Container = Div;

          if (ele.paper === true) {
            Container = Paper;
          }
          // if (ele.enabledGroups) {
          //   setEnabledGroup(ele.enabledGroups);
          // }
          // if(Data.fieldDependency){
          //   fieldDependencyHandler(Data.fieldDependency,Shell.id);
          // }
          function rightClick(e, isAtomic) {
            if (!isAtomic) return;
            e.preventDefault();
            const { pageX, pageY } = e;
            // console.log("coords: ",clientX," ",clientY)
            setMenuCoords({
              x: pageX,
              y: pageY,
            });
          }
          function openCommentDialog() {
            setOpenComment(true);
          }
          const options = [
            {
              icon: "Comment",
              name: "Add Comment",
              onClick: openCommentDialog,
            },
          ];
          console.log("Styles: ", style, " ", ele);

          return (
            <>
              {console.log("Inside menu: ", style, " ", ele.components)}
              {/* {menuCoords && <div style={{position:'absolute',top:menuCoords.y,left:menuCoords.x,zIndex:100}}> <Menu options={options}  /></div>} */}
              <Container
                className="Flex"
                id={containerId}
                style={style}
                key={index}
              >
                {console.log("New Layout ", ele)}
                {ele.components.map((comp, cIndex) => {
                  if (comp.type === "layout") {
                    //return LayoutRenderer(comp)
                    return (
                      <>
                        {" "}
                        <LayoutRenderer
                          Shell={{ id: Shell?.id }}
                          Data={comp}
                          containerId={containerId}
                        />{" "}
                        <br />
                      </>
                    );
                  }

                  const Cname = comp.componentName;
                  if (Cname === "Line") return <hr style={{ width: "100%" }} />;
                  const Component = getComponentByName(comp);
                  const isAtomic = comp.componentName.split(".")[0] === "A";
                  if (Cname === "C.Cards.Shell_2")
                    console.log("Component is: ", Component);

                  if (Shell?.id) {
                    if (comp.props?.Group) {
                      let group = comp.props.Group;
                      if (!groupRef[group]) {
                        groupRef[group] = [comp.componentNumber];
                      } else {
                        if (!groupRef[group].includes(comp.componentNumber)) {
                          groupRef[group].push(comp.componentNumber);
                        }
                      }
                    }

                    dispatch({
                      type: "SET_FORM_DATA",
                      payload: {
                        id: Shell.id,
                        componentNumber: comp.componentNumber,
                        data: comp.props,
                      },
                    });
                    console.log("from Shel render: ", Shell, " ", Cname);
                    return (
                      <div
                        style={{ width: "100%" }}
                        onContextMenu={(e) => {
                          rightClick(e, isAtomic);
                        }}
                      >
                        {" "}
                        <Component
                          key={cIndex}
                          id={Shell.id}
                          compNumber={comp.componentNumber}
                        />
                      </div>
                    );
                  }
                  console.log("Before render: ", comp.props);
                  return (
                    <Component
                      props={comp.props}
                      {...comp.props}
                      componentName={Cname}
                      //  key={cIndex}
                      key={containerId}
                      id={containerId}
                      Cstyle={comp.styles}
                    />
                  );
                })}
              </Container>
            </>
          );
        })}
      </>
    );
  }

  return (
    <>
      {Data?.pageType === "Modal" ? (
        <EWModal Popen={true}>
          <div>{Render(Data.layout)}</div>
        </EWModal>
      ) : (
        <>{Render(Data.layout)}</>
      )}
    </>
  );
}

export default HOC;
