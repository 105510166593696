import React, { useState, useEffect } from "react";
import Atomic from "../../../AComponent/Atomic";
import EwTable from "../../../MComponent/Table/Table";
import AlertMessage from "../../../Utils/Messages/AlertMessage";
import { submitPayload, handleResult } from "../../../Utils/Common/Library";

const { Dropdown, Input, Button } = Atomic;

const FeatureID = "19146";

function DPP() {
  const [localState, setLocalState] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const {
    DimensionID,
    ParameterDescription,
    ParameterShortName,
    ParameterUOM,
    ParameterName,
    TransactionID,
    EffectiveFromTimestamp,
  } = localState;
  function updateState(val, type) {
    setLocalState((prev) => ({ ...prev, [type]: val }));
  }
  useEffect(() => {
    if (openForm === false) {
      setLocalState({});
    }
  }, [openForm]);
  function onSave() {
    setLoading(true);
    let payload = localState;
    let edit = null;
    if (TransactionID) {
      edit = {
        TransactionID,
        EffectiveFromTimestamp,
        RequestType: "1",
      };
    }
    submitPayload({
      featureId: FeatureID,
      payload,
      edit,
    })
      .then((res) => {
        let type = handleResult(res);
        setMessage({
          type: 0,
          message:
            type === 1 ? "Paramter Added!" : type === 2 && "Paramter Edited",
        });
        setOpenForm(false);
      })
      .catch((e) => {
        setMessage({
          type: 1,
          message: "Network Error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function docSelected(data) {
    setLocalState(data);
    setOpenForm(true);
  }
  const heading = ["Parameter Name", "Edit"];
  return (
    <>
      {message && (
        <AlertMessage type={message.type} message={message.message} />
      )}
      <br />
      {!openForm ? (
        <div>
          <EwTable
            includeOprations={true}
            heading={heading}
            featureId={FeatureID}
            rowToFieldMap={["ParameterName"]}
            onDocSelected={docSelected}
          />
          <br />
          <div className="Flex">
            <Button title={"Add New"} onClick={() => setOpenForm(true)} />
          </div>
        </div>
      ) : (
        <div>
          <div>
            <Button
              variant={"Text"}
              title={"⬅️ Paramter"}
              onClick={() => setOpenForm(false)}
            />
          </div>
          <div className="Flex">
            <div style={{ width: "100%" }}>
              <Dropdown
                label="Dimensions"
                featureId={"19144"}
                mapper={{
                  label: "DimensionName",
                  value: "TransactionID",
                }}
                value={DimensionID}
                onChange={(val) => updateState(val, "DimensionID")}
              />
            </div>
          </div>

          {DimensionID && DimensionID !== -1 && (
            <div>
              <div className="Flex-row">
                <div>
                  <Input
                    label={"Paramter Name"}
                    value={ParameterName}
                    onChange={(val) => updateState(val, "ParameterName")}
                  />
                </div>

                <div>
                  <Input
                    label={"Paramter Short Name"}
                    value={ParameterShortName}
                    onChange={(val) => updateState(val, "ParameterShortName")}
                  />
                </div>
              </div>
              <div className="Flex">
                <div>
                  <Input
                    label={"Paramter Description"}
                    value={ParameterDescription}
                    onChange={(val) => updateState(val, "ParameterDescription")}
                    rows={4}
                  />
                </div>
                <div>
                  <Input
                    label={"UOM"}
                    value={ParameterUOM}
                    onChange={(val) => updateState(val, "ParameterUOM")}
                  />
                </div>
              </div>

              <div className="Flex">
                <div>
                  <div>
                    <Button title={"Save"} onClick={onSave} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default DPP;
