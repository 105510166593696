import React from "react";
import Grid from '@mui/material/Grid';

import { v4 as uuidv4 } from 'uuid';

const Profile = (props) => {
    const item1 = props.Item_1;
    const Component1 = item1!==undefined ? require('./'+item1.AComponent).default : null;
    const item2 = props.Item_2;
    const Component2 = item2 !== undefined ? require('./'+item2.AComponent).default : null;
    return (
        <div style = {{
            display : 'flex',
            width : '100%',
            alignItems : 'center',
            justifyContent : 'center'
        }}>
            <div>
                <Component1 key = {new uuidv4()} {...item1} />
            </div>
            <div>
                <Component2 key = {new uuidv4()} {...item2} />
            </div>
        </div>
//     <Grid container spacing = {4}>
//     <Grid item xs = {2}>
//         <Component1 key = {new uuidv4()} {...item1} />
//     </Grid>
//     <Grid item xs = {10}>
//         <Component2 key = {new uuidv4()} {...item2} />
//     </Grid>
//   </Grid>
  );
};

export default Profile;