import Legal from "../../Testjson/Organization/legalEntity.json";
import BusinessUnit from "../../Testjson/Organization/BusinessUnit.json";
import BusinessUnitType from "../../Testjson/Organization/BusinessUnitType.json";
import functionType from "../../Testjson/Organization/FunctionType.json";
import functionjson from "../../Testjson/Organization/function.json";
import locationType from "../../Testjson/Organization/LocationType.json";
import location from "../../Testjson/Organization/Location.json";
import workLocation from "../../Testjson/Organization/workLocation.json";
import Grade from "../../Testjson/Organization/Grade.json";
import Role from "../../Testjson/Organization/Role.json";
import EmployeeType from "../../Testjson/Organization/Employee_type.json";
import GradeLevel from "../../Testjson/Organization/Grade_level.json";
import CostCenter from "../../Testjson/Organization/Cost_center.json";
import LeagHead from "../../Testjson/Organization/LegalEntityHead.json";
import BusinessHead from "../../Testjson/Organization/BusinessUnitHead.json";
import locationHead from "../../Testjson/Organization/LocationHead.json";
import RoleTag from "../../Testjson/Organization/Role_tag.json";
import PDG from "../../NComponent/pms/Settings/PDG";
import DPG from "../../NComponent/pms/Settings/DPG";
import DPP from "../../NComponent/pms/Settings/DPP";
import ParamterMap from "../../NComponent/pms/Settings/ParamterMap";
import Mpp from "../../NComponent/pms/MPP";
import MasterPlans from "../../NComponent/pms/MPP/MasterPlans";
import RatingScales from "../../NComponent/pms/Settings/Rating/RatingScales";
import DPG2 from "../../NComponent/pms/Settings/DPG2";

export const settings = [
  {
    label: "Organisation Dimensions",
    children: [
      { label: "Legal Entity", layout: Legal },
      { label: "Business Unit Type", layout: BusinessUnitType },
      { label: "Business Unit", layout: BusinessUnit },
      { label: "Function Type", layout: functionType },
      { label: "Function", layout: functionjson },
      { label: "Location Type", layout: locationType },
      { label: "Location", layout: location },
      { label: "Work Location", layout: workLocation },
      { label: "Employee Type", layout: EmployeeType },
      { label: "Grade", layout: Grade },
      { label: "Grade Level", layout: GradeLevel },
      { label: "Role Group Tag", layout: RoleTag },
      { label: "Role", layout: Role },
      { label: "Cost Center", layout: CostCenter },
      { label: "Legal Entity Head", layout: LeagHead },
      { label: "Business Unit Head", layout: BusinessHead },
      { label: "Location Head", layout: locationHead },
    ],
  },
  {
    label: "Identity and Security",
    children: [],
  },
  {
    label: "Organisation",
    children: [],
  },
  {
    label: "Asset Management",
    children: [],
  },
  {
    label: "Employee Profile",
    children: [],
  },
  {
    label: "Documents",
    children: [],
  },
  {
    label: "Hiring",
    children: [],
  },
  {
    label: "Employee Onboarding",
    children: [],
  },
  {
    label: "Leave",
    children: [],
  },
  {
    label: "Attendance",
    children: [],
  },
  {
    label: "Time Tracking",
    children: [],
  },
  {
    label: "Compensation",
    children: [],
  },
  {
    label: "Payroll & Taxes",
    children: [],
  },
  {
    label: "Travel & Expense",
    children: [],
  },
  {
    label: "Performance",
    children: [],
  },
  {
    label: "Employee Separation",
    children: [],
  },
  {
    label: "Performance Management",
    children: [
      {
        label: "Performance Dimension",
        Component: "PDG",
      },
      {
        label: "Dimenstion Paramter Group",
        Component: "DPG",
      },
      {
        label: "Dimension Performance Parameter",
        Component: "DPP",
      },
      {
        label: "Dimension Parameter Map",
        Component: "DMP",
      },
      {
        label: "Master Plans",
        Component: "MPP",
      },
      {
        label: "Rating",
        Component: "RS",
      },
    ],
  },
];

export const componentMap = {
  PDG: PDG,
  DPG: DPG,
  DPP: DPP,
  DMP: ParamterMap,
  MPP: MasterPlans,
  RS: RatingScales,
};
