import * as React from "react";
import { useHistory } from "react-router-dom";
import { styled, useTheme, makeStyles } from "@mui/material/styles";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";

import ListItemIcon from "@mui/material/ListItemIcon";

import {
  InputBase,
  Tooltip,
  Menu,
  MenuItem,
  Paper,
  Avatar,
} from "@mui/material";

// import { Search as SearchIcon} from "@mui/icons-material";
import  SearchIcon  from "@mui/icons-material/Search";

import Header from "./Header";
import Cookies from "js-cookie";

import PlanInit from "./NComponent/pms/PlanInit";
import Assesment from "./NComponent/pms/Assesment";
import store from "./Redux/Store";

const drawerWidth = 240;
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: 12,
  boxShadow: "0px 0px 4px",
  border: "1px solid black",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "white",
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: 244,
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "black",
}));

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  border: "none",
  boxShadow: "none",
  backgroundColor: "white",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,

  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "black",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  border: "none",
}));
const StyledPaper = styled(Paper)(({ theme }) => ({
  position: "fixed",
  elevation: 0,
  top: "10%",
  right: 5,
  width: 340, // set a fixed width for the Paper component
  height: "88%", // set the height to match the viewport
  display: "flex",
  // marginTop: '-45%',
  //   alignItems: "center",
  //   justifyContent: "center",
  zIndex: theme.zIndex.drawer - 1, // make sure the Paper component is on top of other elements
  // marginRight:"10px"
}));

const PMSIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4714 20.9172C11.7632 21.2078 12.2366 21.2078 12.5284 20.9172L14.7784 18.6672C15.0339 18.3695 15.0163 17.9265 14.7397 17.6488C14.462 17.3722 14.019 17.3547 13.7214 17.6101L12.7499 18.5769V12.8886C12.7499 12.475 12.4136 12.1386 11.9999 12.1386C11.5862 12.1386 11.2499 12.475 11.2499 12.8886V18.5769L10.2784 17.609V17.6101C9.98075 17.3547 9.53777 17.3722 9.26006 17.6488C8.98349 17.9265 8.96591 18.3695 9.22138 18.6672L11.4714 20.9172Z"
        fill="black"
      />
      <path
        d="M9 3.88855C9 5.10145 9.73125 6.19597 10.8516 6.66004C11.9731 7.12411 13.2633 6.86746 14.121 6.00964C14.9788 5.15182 15.2354 3.86158 14.7714 2.74024C14.3073 1.61992 13.2128 0.888672 11.9999 0.888672C10.3429 0.888672 9 2.23153 9 3.88855ZM13.5 3.88855C13.5 4.49557 13.1344 5.04169 12.5742 5.27488C12.0141 5.50691 11.3684 5.37801 10.9395 4.94911C10.5106 4.5202 10.3816 3.87451 10.6137 3.31435C10.8469 2.75419 11.393 2.38858 12 2.38858C12.3973 2.38858 12.7793 2.54678 13.0606 2.82802C13.3418 3.10926 13.5 3.49126 13.5 3.88855Z"
        fill="black"
      />
      <path
        d="M9.37502 10.6386C9.57424 10.6386 9.76526 10.56 9.90587 10.4194C10.0465 10.2788 10.125 10.0878 10.125 9.88857C10.125 9.4749 10.4613 9.13857 10.875 9.13857H13.125C13.3242 9.13857 13.5152 9.21708 13.6559 9.35771C13.7965 9.49833 13.875 9.68936 13.875 9.88856C13.875 10.3022 14.2113 10.6386 14.625 10.6386C15.0387 10.6386 15.375 10.3022 15.375 9.88856C15.375 8.64638 14.3672 7.63856 13.125 7.63856H10.875C9.63282 7.63856 8.625 8.64638 8.625 9.88856C8.625 10.0878 8.70352 10.2788 8.84414 10.4194C8.98477 10.56 9.17582 10.6386 9.37502 10.6386Z"
        fill="black"
      />
      <path
        d="M3.37512 19.6386C4.58802 19.6386 5.68254 18.9073 6.14661 17.787C6.61068 16.6655 6.35404 15.3753 5.49621 14.5176C4.63839 13.6598 3.34815 13.4031 2.22681 13.8672C1.10649 14.3313 0.375244 15.4258 0.375244 16.6387C0.375244 17.4344 0.691654 18.1973 1.25415 18.7598C1.81665 19.3223 2.57943 19.6386 3.37512 19.6386ZM3.37512 15.1386C3.98214 15.1386 4.52826 15.5042 4.76145 16.0643C4.99349 16.6245 4.86458 17.2702 4.43568 17.6991C4.00677 18.128 3.36108 18.2569 2.80092 18.0249C2.24076 17.7917 1.87515 17.2455 1.87515 16.6385C1.87515 15.81 2.54661 15.1386 3.37512 15.1386Z"
        fill="black"
      />
      <path
        d="M0.750018 23.3885C0.949236 23.3885 1.14026 23.31 1.28087 23.1694C1.42148 23.0288 1.50001 22.8378 1.50001 22.6386C1.50001 22.2249 1.83634 21.8886 2.25001 21.8886H4.50001C4.69923 21.8886 4.89025 21.9671 5.03086 22.1077C5.17147 22.2483 5.25 22.4393 5.25 22.6385C5.25 23.0522 5.58633 23.3885 6 23.3885C6.41367 23.3885 6.75 23.0522 6.75 22.6385C6.75 21.3964 5.74218 20.3885 4.5 20.3885H2.25C1.00782 20.3885 0 21.3964 0 22.6385C0 22.8378 0.0785159 23.0288 0.219141 23.1694C0.359766 23.31 0.550818 23.3885 0.750018 23.3885Z"
        fill="black"
      />
      <path
        d="M20.625 19.6386C21.8379 19.6386 22.9324 18.9073 23.3965 17.787C23.8606 16.6655 23.6039 15.3753 22.7461 14.5176C21.8883 13.6598 20.598 13.4031 19.4767 13.8672C18.3564 14.3313 17.6251 15.4258 17.6251 16.6387C17.6251 17.4344 17.9415 18.1973 18.504 18.7598C19.0665 19.3223 19.8293 19.6386 20.625 19.6386ZM20.625 15.1386C21.232 15.1386 21.7781 15.5042 22.0113 16.0643C22.2434 16.6245 22.1145 17.2702 21.6856 17.6991C21.2567 18.128 20.611 18.2569 20.0508 18.0249C19.4906 17.7917 19.125 17.2455 19.125 16.6385C19.125 15.81 19.7965 15.1386 20.625 15.1386Z"
        fill="black"
      />
      <path
        d="M18 23.3885C18.1992 23.3885 18.3903 23.31 18.5309 23.1694C18.6715 23.0288 18.75 22.8378 18.75 22.6386C18.75 22.2249 19.0863 21.8886 19.5 21.8886H21.75C21.9492 21.8886 22.1402 21.9671 22.2809 22.1077C22.4215 22.2483 22.5 22.4393 22.5 22.6385C22.5 23.0522 22.8363 23.3885 23.25 23.3885C23.6637 23.3885 24 23.0522 24 22.6385C24 21.3964 22.9922 20.3885 21.75 20.3885H19.5C18.2578 20.3885 17.25 21.3964 17.25 22.6385C17.25 22.8378 17.3285 23.0288 17.4691 23.1694C17.6098 23.31 17.8008 23.3885 18 23.3885Z"
        fill="black"
      />
    </svg>
  );
};

const menuList = [
  {
    Icon: PMSIcon,
    tooltip: "Performance Management",
    menu: [
      {
        heading: "PMS",
        list: [
          {
            label: "My Plan",
            route: "/myplan",
          },
          {
            label: "My Team Plan",
            route: "/myteamplan",
          },
          {
            label: "Start Plan Cycle",
            Component: "PlanInit",
            allowedRoles: [2],
          },
          {
            label: "Start Assessment",
            Component: "Assessment",
            allowedRoles: [2],
          },
        ],
      },
      {
        heading: "PMS Settings",
        list: [
          {
            label: "Settings",
            route: "/Settings2",
            allowedRoles: [3],
          },
        ],
      },
    ],
  },
];

export default function SideNav({ setComponent }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [avatarAnchor, setAvatarAnchor] = React.useState(null);
  const [menu, setMenu] = React.useState(null);
  const Component = useSelector((state) => state.SideNavReducer.Component);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const history = useHistory();
  function changeUrl(url) {
    history.push(url);
  }
  function openMenu(e, menu) {
    setMenuAnchor(e.target);
    setMenu(menu);
  }
  function handleMenuClick({ route, Component }) {
    if (route) {
      history.push(route);
    }
    if (Component) {
      setComponent(Component);
    }
    setMenu(null);
    setMenuAnchor(null);
  }
  function deleteToken() {
    // localStorage.removeItem("Ewtoken");
    Cookies.remove("EwAccessToken");
    Cookies.remove("EwRefreshToken");
    window.location.reload();
  }
  const userName = store.getState().AuthReducer?.userData?.preferred_username;
  const role = store.getState().AuthReducer.role;
  return (
    <>
      {/* <Header setComponent={setComponent} /> */}
      <Box
        sx={{
          display: "flex",
          zIndex: -10,
          border: "none",
          marginTop: 10,
          boxShadow: "none",
        }}
      >
        {/* <CssBaseline /> */}
        <AppBar style={{ boxShadow: "none" }} position="fixed" open={false}>
          <Toolbar>
            {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton> */}

            <img
              src={
                "https://d1myhw8pp24x4f.cloudfront.net/software_logo/1492604458_employwise2_mid.jpg"
              }
              height={40}
            />

            <Box
              sx={{ flexGrow: 1, justifyContent: "center", display: "flex" }}
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex", color: "black" } }}>
              <Typography className="pointer" variant="overline">
                <span onClick={() => changeUrl("/")}> Home |</span>
              </Typography>
              <Typography className="pointer" variant="overline">
                Support |
              </Typography>
              <Typography className="pointer" variant="overline">
                <span onClick={() => deleteToken()}> Logout |</span>
              </Typography>
            </Box>
            <div id="ew-avt" style={{ padding: 5 }}>
              <Avatar
                sx={{
                  bgcolor: "purple",
                  height: 30,
                  width: 30,
                  cursor: "pointer",
                }}
              >
                {userName && userName[0].toUpperCase()}
              </Avatar>
              {console.log("Avatar: ", avatarAnchor)}
              {/* <Menu
                id="EW-side-nav-Avatar"
                anchorEl={document.getElementById("ew-avt")}
                open={avatarAnchor ? true : false}
                onClose={() => setAvatarAnchor(false)}
                MenuListProps={{
                  "aria-labelledby": "menu-button-avatar",
                }}
              >
                <MenuItem>{userName}</MenuItem>
              </Menu> */}
            </div>
          </Toolbar>
        </AppBar>
        <br />
        <Paper
          elevation={3}
          variant="permanent"
          style={{
            height: 702,
            position: "fixed",
            left: 0,
            width: 45,
          }}
        >
          <br />

          <br />

          <br />
          {menuList.map(({ Icon, tooltip, menu }) => (
            <List>
              <Tooltip title={tooltip}>
                <ListItemIcon
                  onClick={(e) => {
                    console.log("Menu is: ",menu)
                    openMenu(e, menu);
                  }}
                  className="pointer"
                  sx={{
                    justifyContent: "center",
                  }}
                >
                  <Icon />
                </ListItemIcon>
              </Tooltip>
            </List>
          ))}
          <Menu
            id="EW-side-nav-menu"
            anchorEl={menuAnchor}
            open={menu ? true : false}
            onClose={() => setMenu(null)}
            MenuListProps={{
              "aria-labelledby": "menu-button",
            }}
          >
            {menu &&
              menu.map(({ heading, list }) => (
                <div
                  className="Flex"
                  style={{ padding: 10, alignItems: "flex-start" }}
                >
                  <Typography
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                    variant="h6"
                  >
                    {heading}
                  </Typography>
                  <hr style={{ width: "100%" }} />
                  {list?.map((ele) => (
                    <>
                    {console.log("Allowed roles: ",ele.allowedRoles)}
                      {(!ele.allowedRoles ||
                        ele.allowedRoles?.includes(role)) && (
                        <MenuItem
                          style={{ width: "100%" }}
                          onClick={() => handleMenuClick(ele)}
                        >
                          {ele.label}
                        </MenuItem>
                      )}
                    </>
                  ))}
                </div>
              ))}
          </Menu>
          {/* <Divider /> */}
          {/* <List>
            {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
              <ListItem key={text} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {["All mail", "Trash", "Spam"].map((text, index) => (
              <ListItem key={text} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List> */}
        </Paper>
      </Box>
      {Component && (
        <div>
          {/* <StyledPaper>
            <div>
              <Component />
              <div style={{ position: "absolute", bottom: 5, right: 0 }}>
                <Typography variant="subtitle2">Powered By</Typography>
                <img
                  src={
                    "https://d1myhw8pp24x4f.cloudfront.net/software_logo/1492604458_employwise2_mid.jpg"
                  }
                  height={40}
                />
              </div>
            </div>
          </StyledPaper> */}
        </div>
      )}
    </>
  );
}
