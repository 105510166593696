import React from 'react';
import "../index.css"

function Loader(){
    return(
    <div className="loader" >

    </div>
    )
}


export default Loader