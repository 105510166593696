import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Chip,
  Autocomplete,
  TextField,
  Paper,
} from "@mui/material";
import DataGrid2 from "../../MComponent/Table/DataGrid2";
import {
  Bar,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ComposedChart,
  BarChart,
  Sector,
  Pie,
  PieChart,
  Cell,
  Line,
} from "recharts";
import Atomic from "../../AComponent/Atomic";

const { Dropdown } = Atomic;

const categoryMap = {
  overallAggregateScore: "Overall Aggregate Score",
  overallAdjustedScore: "Overall Adjused Score",
  overallReviewerScore: "Overall Reviewer Score",
  growthRoleAssessment: "Growth Role Assessment",
  exitRiskAssessment: "Exit Risk Assessment",
};

function OverallDashboard({ queryResults }) {
  const [chartData, setChartData] = useState({
    overallAggregateScore: [],
    overallAdjustedScore: [],
    overallReviewerScore: [],
    growthRoleAssessment: [],
    exitRiskAssessment: [],
  });
  const [activeChart, setActiveChart] = useState({
    scores: "overallAggregateScore",
    assessments: "growthRoleAssessment",
  });
  const [records, setRecords] = useState(queryResults);
  const [selectedFilters, setSelectedFilters] = useState({
    function: [],
    location: [],
    grade: [],
    role: [],
    category: "All",
    gender: [],
  });
  const [availableFilters, setAvailableFilters] = useState({
    functions: [],
    locations: [],
    roles: [],
    grades: [],
    genders: [],
  });

  useEffect(() => {
    const graphWorker = new Worker("dashboard.worker.js");
    graphWorker.postMessage({
      module: "PSRD",
      type: "CREATE_OVERALL_RECORDS",
      filters: selectedFilters,
      records: queryResults,
    });
    graphWorker.onmessage = (event) => {
      const {
        oagsResults,
        oadsResults,
        orwsResults,
        growthRoleResults,
        exitRiskResults,
        functions,
        locations,
        roles,
        grades,
        genders,
      } = event.data;

      console.log(event.data, "EVENT>DATAPSR");

      setChartData({
        overallAggregateScore: oagsResults,
        overallAdjustedScore: oadsResults,
        overallReviewerScore: orwsResults,
        growthRoleAssessment: growthRoleResults,
        exitRiskAssessment: exitRiskResults,
      });
      setAvailableFilters({ functions, locations, roles, grades, genders });
    };
  }, []);

  function applyFiltersToRecords(
    currentFilters,
    currentRecords,
    updateChart = true
  ) {
    const graphWorker = new Worker("dashboard.worker.js");
    graphWorker.postMessage({
      module: "PSRD",
      type: "FILTER_OVERALL_RECORDS",
      filters: currentFilters,
      records: currentRecords,
    });

    graphWorker.onmessage = (event) => {
      const {
        oagsResults,
        oadsResults,
        orwsResults,
        growthRoleResults,
        exitRiskResults,
        filteredRecords,
      } = event.data;

      // updateChart &&
      setChartData({
        overallAggregateScore: oagsResults,
        overallAdjustedScore: oadsResults,
        overallReviewerScore: orwsResults,
        growthRoleAssessment: growthRoleResults,
        exitRiskAssessment: exitRiskResults,
      });
      setRecords(filteredRecords);
    };
  }

  const COLORS = ["#00C49F", "#FFBB28", "#FF8042", "#0078ff", "	#bd00ff"];
  const renderCustomizedLabel = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          style={{ outline: "none", pointerEvents: "none" }}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
          style={{ outline: "none", pointerEvents: "none" }}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
          style={{ pointerEvents: "none" }}
        />
        <circle
          cx={ex}
          cy={ey}
          r={2}
          fill={fill}
          stroke="none"
          style={{ pointerEvents: "none" }}
        />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
          style={{ outline: "none", pointerEvents: "none" }}
        >{`${payload.value || "Assessment due"}`}</text>
        {/* <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
          style={{ outline: "none", pointerEvents: "none" }}
        >
          {`${
            payload.value === "No Data Available"
              ? 0
              : payload.numberOfEmployees
          } employees`}
        </text> */}
      </>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    console.log(active, payload, label, "THESEARETHEPROPRIJHGTHERE");
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            backgroundColor: "white",
            padding: "1em",
            border: "1px solid #CCCCCC",
            textAlign: "start",
          }}
        >
          <Typography variant="form_text1">Score Range: {label}</Typography>
          <br />
          <Typography variant="form_text1" sx={{ color: payload[0].fill }}>
            Number Of Employees : {payload[0].payload.numberOfEmployees}
          </Typography>
          <br />
          <Typography variant="form_text1">
            Total Employees : {records.length}
          </Typography>
        </Box>
      );
    }

    return null;
  };

  const CustomPieTooltip = ({ active, payload }) => {
    if (active && payload) {
      console.log(payload, "PAYLOADOFPIECHART");
      return (
        <Box
          sx={{
            backgroundColor: "white",
            padding: "1em",
            border: "1px solid #CCCCCC",
            textAlign: "start",
          }}
        >
          <Typography
            variant="form_text1"
            sx={{ color: payload[0].payload.fill }}
          >
            Number Of Employees : {payload[0].payload.numberOfEmployees}
          </Typography>
          <br />
          <Typography variant="form_text1">
            Total Employees : {records.length}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  console.log(
    "selectedFilters",
    selectedFilters,
    selectedFilters.category.split("=")
  );

  return (
    <Box>
      <br />
      <Box
        sx={{
          display: "flex",
          gap: "1em",
          alignItems: "center",
          justifyContent: "space-around",
        }}
        className="scroll"
      >
        <Box>
          <Typography variant="form_text1">Filter By Function</Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            options={["All", ...availableFilters.functions]}
            sx={{ width: "15em", marginTop: "0.5em" }}
            onChange={(event, value) => {
              setSelectedFilters((prevState) => {
                if (prevState.function.includes(value[0])) return prevState;
                const newFilters = {
                  ...prevState,
                  function:
                    value[0] === "All" ? [] : [...prevState.function, ...value],
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            multiple
            value={[]}
            renderInput={(params) => <TextField {...params} />}
            disableClearable={true}
          />
        </Box>
        <Box>
          <Typography variant="form_text1">Filter By Location</Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            options={["All", ...availableFilters.locations]}
            sx={{ width: "15em", marginTop: "0.5em" }}
            onChange={(event, value) => {
              setSelectedFilters((prevState) => {
                if (prevState.location.includes(value[0])) return prevState;
                const newFilters = {
                  ...prevState,
                  location:
                    value[0] === "All" ? [] : [...prevState.location, ...value],
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            multiple
            value={[]}
            renderInput={(params) => <TextField {...params} />}
            disableClearable={true}
          />
        </Box>
        <Box>
          <Typography variant="form_text1">Filter By Grade</Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            options={["All", ...availableFilters.grades]}
            sx={{ width: "15em", marginTop: "0.5em" }}
            onChange={(event, value) => {
              setSelectedFilters((prevState) => {
                if (prevState.grade.includes(value[0])) return prevState;
                const newFilters = {
                  ...prevState,
                  grade:
                    value[0] === "All" ? [] : [...prevState.grade, ...value],
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            multiple
            value={[]}
            renderInput={(params) => <TextField {...params} />}
            disableClearable={true}
          />
        </Box>
        <Box>
          <Typography variant="form_text1">Filter By Role</Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            options={["All", ...availableFilters.roles]}
            sx={{ width: "15em", marginTop: "0.5em" }}
            onChange={(event, value) => {
              setSelectedFilters((prevState) => {
                if (prevState.role.includes(value[0])) return prevState;
                const newFilters = {
                  ...prevState,
                  role: value[0] === "All" ? [] : [...prevState.role, ...value],
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            multiple
            value={[]}
            renderInput={(params) => <TextField {...params} />}
            disableClearable={true}
          />
        </Box>
        <Box>
          <Typography variant="form_text1">Filter By Gender</Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            options={["All", ...availableFilters.genders]}
            sx={{ width: "15em", marginTop: "0.5em" }}
            onChange={(event, value) => {
              setSelectedFilters((prevState) => {
                if (prevState.gender.includes(value[0])) return prevState;
                const newFilters = {
                  ...prevState,
                  gender: value[0] === "All" ? [] : [...value],
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            multiple
            value={[]}
            renderInput={(params) => <TextField {...params} />}
            disableClearable={true}
          />
        </Box>
      </Box>
      <br />
      <Box
        sx={{
          width: "100%",
          // border: "1px solid #0288d1",
          borderRadius: "5px",
          padding: "1em",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "1em",
        }}
      >
        <Typography variant="form_h1">Applied Filters: </Typography>
        {Object.values(selectedFilters).filter(
          (val) => val === "All" || val.length === 0
        ).length === 6 && (
          <Typography variant="form_text1">No filters selected</Typography>
        )}
        {selectedFilters.function.length !== 0 &&
          selectedFilters.function.map((functionValue) => {
            return (
              <Chip
                label={`Function: ${functionValue}`}
                onDelete={() => {
                  setSelectedFilters((prevState) => {
                    const functions = prevState.function.filter(
                      (f) => f !== functionValue
                    );
                    const newState = { ...prevState, function: functions };
                    applyFiltersToRecords(newState, queryResults);
                    return newState;
                  });
                }}
                variant="outlined"
                color="primary"
              />
            );
          })}
        {selectedFilters.location.length !== 0 &&
          selectedFilters.location.map((locationValue) => {
            return (
              <Chip
                label={`Location: ${locationValue}`}
                onDelete={() => {
                  setSelectedFilters((prevState) => {
                    const locations = prevState.location.filter(
                      (f) => f !== locationValue
                    );
                    const newState = { ...prevState, location: locations };
                    applyFiltersToRecords(newState, queryResults);
                    return newState;
                  });
                }}
                variant="outlined"
                color="primary"
              />
            );
          })}
        {selectedFilters.grade.length !== 0 &&
          selectedFilters.grade.map((gradeValue) => {
            return (
              <Chip
                label={`Grade: ${gradeValue}`}
                onDelete={() => {
                  setSelectedFilters((prevState) => {
                    const grades = prevState.grade.filter(
                      (f) => f !== gradeValue
                    );
                    const newState = { ...prevState, grade: grades };
                    applyFiltersToRecords(newState, queryResults);
                    return newState;
                  });
                }}
                variant="outlined"
                color="primary"
              />
            );
          })}
        {selectedFilters.role.length !== 0 &&
          selectedFilters.role.map((roleValue) => {
            return (
              <Chip
                label={`Role: ${roleValue}`}
                onDelete={() => {
                  setSelectedFilters((prevState) => {
                    const roles = prevState.role.filter((f) => f !== roleValue);
                    const newState = { ...prevState, role: roles };
                    applyFiltersToRecords(newState, queryResults);
                    return newState;
                  });
                }}
                variant="outlined"
                color="primary"
              />
            );
          })}
        {selectedFilters.gender.length !== 0 &&
          selectedFilters.gender.map((genderValue) => {
            return (
              <Chip
                label={`Gender: ${genderValue}`}
                onDelete={() => {
                  setSelectedFilters((prevState) => {
                    const genders = prevState.gender.filter(
                      (f) => f !== genderValue
                    );
                    const newState = { ...prevState, gender: genders };
                    applyFiltersToRecords(newState, queryResults);
                    return newState;
                  });
                }}
                variant="outlined"
                color="primary"
              />
            );
          })}
        {selectedFilters.category !== "All" && (
          <Chip
            label={`${
              categoryMap[selectedFilters.category.split("=")[0]]
            } with ${
              selectedFilters.category.split("=")[0].includes("Score")
                ? "Range"
                : ""
            }: ${selectedFilters.category.split("=")[1] || "Assessment Due"}`}
            onDelete={() => {
              setSelectedFilters((prevState) => {
                const newFilters = {
                  ...prevState,
                  category: "All",
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            variant="outlined"
            color="primary"
          />
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: "1em",
          paddingTop: 0,
        }}
      >
        <Typography variant="form_text1">
          Viewing <b>{records.length}</b> out of {queryResults.length} records
        </Typography>
      </Box>
      <br />
      <br />
      <Box
        sx={{
          display: "flex",
          gap: "1em",
          marginBottom: "5em",
          alignItems: "flex-start",
        }}
      >
        <Box
          height={"45vh"}
          sx={{
            display: "flex",
            width: "40%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Dropdown
            value={activeChart.scores}
            menuItems={[
              {
                label: "Overall Aggregate Score",
                value: "overallAggregateScore",
              },
              {
                label: "Overall Adjusted Score",
                value: "overallAdjustedScore",
              },
              {
                label: "Overall Reviewer Score",
                value: "overallReviewerScore",
              },
            ]}
            onChange={(value) => {
              setActiveChart((prevState) => ({ ...prevState, scores: value }));
            }}
            size={"small"}
            styles={{
              width: "15em",
            }}
          />
          <br />
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={chartData[activeChart.scores]}
              margin={{
                top: 5,
                right: 30,
                left: 10,
                bottom: 25,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="range"
                label={{
                  value: "Score Range",
                  position: "insideMiddle",
                  dy: 30,
                }}
              />
              <YAxis
                dataKey={"numberOfEmployees"}
                label={{
                  value: "Number of Employees",
                  angle: -90,
                  position: "insideMiddle",
                  dx: -30,
                }}
              />
              <Tooltip content={<CustomTooltip />} />

              <Bar
                dataKey="numberOfEmployees"
                fill="#00C49F"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                style={{ cursor: "pointer" }}
                onClick={({ payload }, index, event) => {
                  setSelectedFilters((prevState) => {
                    const newFilters = {
                      ...prevState,
                      category: `${activeChart.scores}=${payload.range}`,
                    };
                    applyFiltersToRecords(newFilters, queryResults, false);
                    return newFilters;
                  });
                }}
              />
            </BarChart>
          </ResponsiveContainer>
          <br />
          <Typography variant="p2">Overall Scores</Typography>
        </Box>
        <Box
          height={"60vh"}
          sx={{
            display: "flex",
            width: "60%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Dropdown
            menuItems={[
              {
                label: "Growth Role Assessment",
                value: "growthRoleAssessment",
              },
              {
                label: "Exit Risk Assessment",
                value: "exitRiskAssessment",
              },
            ]}
            onChange={(value) => {
              setActiveChart((prevState) => ({
                ...prevState,
                assessments: value,
              }));
            }}
            size={"small"}
            styles={{
              width: "15em",
            }}
            value={activeChart.assessments}
          />
          <br />
          <ResponsiveContainer width="100%" height="100%">
            <PieChart style={{ outline: "none" }}>
              <Tooltip content={<CustomPieTooltip />} />
              <Pie
                style={{ outline: "none" }}
                data={chartData[activeChart.assessments]}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={90}
                fill="#8884d8"
                dataKey="numberOfEmployees"
                onClick={({ payload }, flag, event) => {
                  setSelectedFilters((prevState) => {
                    const newFilters = {
                      ...prevState,
                      category: `${activeChart.assessments}=${payload.value}`,
                    };
                    console.log(newFilters, "selectedFilters");
                    applyFiltersToRecords(newFilters, queryResults);
                    return newFilters;
                  });
                }}
              >
                {chartData[activeChart.assessments].map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    style={{ cursor: "pointer" }}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <br />
          <Typography variant="p2">Assessments</Typography>
        </Box>
      </Box>
      <Paper sx={{ boxSizing: "border-box", height: "100%" }}>
        <DataGrid2
          heading={[
            "Employee ID",
            "Employee Full Name",
            "Plan Name",
            "Plan Period",
            "Reviewer ID",
            "Reviewer Full Name",
            "Growth Role Assessment",
            "Exit Risk Assessment",
            "Overall Reviewer Score",
            "Overall Adjusted Score",
            "Overall Aggregate Score",
            "Function",
            "Location",
            "Role",
            "Grade",
            "Grade Level",
          ]}
          rowsData={records}
          minColumnWidth={300}
          rowToFieldMap={[
            "Employee ID",
            "Employee Full Name",
            "Plan Name",
            "Plan Period",
            "Reviewer ID",
            "Reviewer Full Name",
            "Growth Role Assessment",
            "Exit Risk Assessment",
            "Overall Reviewer Score",
            "Overall Adjusted Score",
            "Overall Aggregate Score",
            "Function",
            "Location",
            "Role",
            "Grade",
            "Grade Level",
          ]}
        />
        <br />
        <br />
      </Paper>
    </Box>
  );
}

export default OverallDashboard;
