import React, { useState, useEffect } from "react";
import FileUpload from "../../MComponent/FileUpload/Uploader";
import EwTable from "../../MComponent/Table/Table";

function BulkUploader() {
  const [heading, setHeading] = useState([]);
  const [table, setTable] = useState([]);

  function HeadingCell(props) {
    var { name } = props;
    return <tr>{"Before: " + name}</tr>;
  }

  function onUpload(data) {
    console.log("Inside Onupload: ", data);
    let head = data.shift();
    head = head.map((ele) => ({ Component: <HeadingCell name={ele} /> }));
    console.log("head: ", head);
    setHeading([...head]);
    setTable([...data]);
  }
  return (
    <div>
      <FileUpload onUpload={onUpload} />
      {heading.length > 0 && <EwTable heading={heading} rows={table} />}
    </div>
  );
}

export default BulkUploader;
