import { Box, Divider, Paper, Typography } from "@mui/material";
import React from "react";

const OutOfCircle = ({ numerator, denominator, label, type }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        margin: "0.5em 1em",
      }}
    >
      <Typography variant="overline">{label}</Typography>
      <Box
        sx={{
          height: "5em",
          // width: "5em",
          // borderRadius: "50%",
          // backgroundColor: "#f7f7f7",
          // display: "flex",
          // alignItems: "baseline",
          // justifyContent: "center",
          // border: "1px solid #cfcfcf",
        }}
      >
        <Typography variant="h5">{numerator}</Typography>
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <Typography variant="overline" sx={{ marginRight: "0.5em" }}>
            out of
          </Typography>
          <Typography variant="body2" fontWeight={"600"}>
            {denominator} {type}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const LeaveAndAttendance = ({
  workDays,
  workHours,
  punctualityRecord,
  punctuality,
}) => {
  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: "0.5em",
        height: "100%",
      }}
    >
      <Box padding={"1em"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            padding: "0.7em 0.3em",
            height: "2.5em",
          }}
        >
          <Typography variant="h6">Leave & Attendance</Typography>
        </Box>
        {/* <Divider /> */}
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {workDays && (
              <OutOfCircle
                numerator={25}
                denominator={31}
                label={"Work Days"}
                type={"Days"}
              />
            )}
            {workHours && (
              <OutOfCircle
                numerator={8}
                denominator={9}
                label={"Work Hours"}
                type={"Hours"}
              />
            )}
            {punctualityRecord && (
              <OutOfCircle
                numerator={31}
                denominator={31}
                label={"Punctuality record"}
                type={"Days"}
              />
            )}
            {punctuality && (
              <OutOfCircle
                numerator={0}
                denominator={1440}
                label={"Punctuality"}
                type={"Minutes"}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default LeaveAndAttendance;
