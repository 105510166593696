import React, { Component, useState, useEffect } from "react";
import AlertMessage from "../../../Utils/Messages/AlertMessage";
import Coaching from "./Coaching";
import EwAccordion from "../../../MComponent/Accordion/Accordion";
import SelfDev from "./SelfDev";
import TrainingNeeds from "./TrainingNeeds";
import ConfidentialSection from "./ConfidentialSection";
import General from "./General";
import GoalSettings from "./GoalSettings";
import PeriodicReview from "./PeriodicReview";
import MultiRaters from "./MultiRaters";
import InterimReview from "./IntermReview";
import ProgressTracking from "./ProgressTracking";
import Summary2 from "./Summary2";
import Dimension2 from "./Dimension2";
import Atomic from "../../../AComponent/Atomic";
import {
  getData,
  submitPayload,
  handleResult,
} from "../../../Utils/Common/Library";
import store from "../../../Redux/Store";
// import Dimensions from "./Dimensions";
import Dimensions from "./NewDimensions";
import { validate } from "./Validate";
const MPPID = "19149";
var list = [
  {
    label: "General",
    Component: General,
  },
  {
    label: "Summary Section",
    Component: Summary2,
  },
  {
    label: "Coaching",
    Component: Coaching,
  },
  {
    label: "Self Development",
    Component: SelfDev,
  },
  {
    label: "Training Needs",
    Component: TrainingNeeds,
  },
  {
    label: "Confidential Section",
    Component: ConfidentialSection,
  },
  // {
  //   label: "Goal Settings",
  //   Component: GoalSettings,
  // },
  // {
  //   label: "Periodic Review",
  //   Component: PeriodicReview,
  // },
  {
    label: "Multi Rater Feedback",
    Component: MultiRaters,
  },
  // {
  //   label: "Interim Review",
  //   Component: InterimReview,
  // },
  {
    label: "Progress Tracking",
    Component: ProgressTracking,
  },
];
const DimensionFeatureID = "19144";
function Mpp({ onServerSave }) {
  const [accordianList, setAccordianList] = useState(list);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [localState, setLocalState] = useState({
    general: {},
    dimensions: [],
    summary: {},
    coaching: {},
    selfDev: {},
    training: {},
    confidential: {},
  });
  // useEffect(() => {
  //   if (error) {
  //     setTimeout(() => {
  //       setError(null);
  //     }, 3000);
  //   }
  // }, [error]);
  useEffect(() => {
    getData({ featureId: DimensionFeatureID }).then((res) => {
      if (res.Data) {
        let arr = res.Data?.map(({ TransactionID, DimensionName }) => {
          return {
            Component: Dimension2,
            label: DimensionName,
            props: {
              id: TransactionID,
              name: DimensionName,
            },
          };
        });
        if (arr?.length > 0) {
          let finarr = [list[0], ...arr, ...list.slice(1, list.length)];
          setAccordianList([...finarr]);
        }
      }
    });
  }, []);
  function updateLocalState(val, type) {
    setLocalState((prev) => ({ ...prev, [type]: val }));
  }

  function onSave() {
    let result = validate(store.getState().DataReducer);
    setLoading(true);
    if (result.type === "error") {
      setError(result.message);
      setLoading(false);
    } else {
      //  console.log(JSON.stringify(result.payload));

      submitPayload({
        featureId: MPPID,
        payload: result.payload,
        edit: result.edit,
      })
        .then((res) => {
          if (handleResult(res) === 1 || handleResult(res) === 2) {
            setLoading(false);
            onServerSave();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
  if (loading) return <>loading...</>;
  return (
    <>
      {/* <EwTabs componentList={list} /> */}
      <br />
      <EwAccordion accordians={accordianList} />
      <br />
      {error && (
        <AlertMessage onClose={() => setError(null)} type={1} message={error} />
      )}
      <div className="Flex" style={{ width: "100%" }}>
        <Atomic.Button title={"Save"} onClick={onSave} />
      </div>
    </>
  );
}

export default Mpp;
