import React from "react";
import { useDispatch, useSelector } from "react-redux";
import WorkflowButton from "../../Workflow/WorkflowButton";
import Atomic from "../../../AComponent/Atomic";

const { MultiSelect, EwSwitch, Input, Dropdown } = Atomic;
const id = "MPP";
const DimensionFeatureId = "19144";

function PeriodicReview() {
  const {
    PeriodicReview,
    PeriodicReviewFrequency,
    PeriodicReviewWorkflowID,
    PeriodicReviewDimensions,
    dimList,
  } = useSelector((state) => {
    if (!state.DataReducer[id]) return {};
    return state.DataReducer[id];
  });
  const dispatch = useDispatch();
  function updateState(val, type) {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: type,
        data: val,
      },
    });
  }
  let freqOptions = [
    { label: "Annual", value: "A" },
    { label: "Half-Yearly", value: "H" },
    { label: "Quarterly", value: "Q" },
    { label: "Monthly", value: "M" },
  ];
  return (
    <div>
      <div className="Flex">
        <EwSwitch
          label="Periodic Review"
          value={PeriodicReview === "E"}
          onChange={(val) => updateState(val ? "E" : "D", "PeriodicReview")}
        />
      </div>
      {PeriodicReview === "E" && (
        <>
          <div className="Flex">
            <WorkflowButton
              AuthorFeatureID={"19152"}
              value={PeriodicReviewWorkflowID}
              onChange={(val) => updateState(val, "PeriodicReviewWorkflowID")}
            />
          </div>
          <br />
          <div className="Flex-row">
            <div style={{ width: "50%" }}>
              <Dropdown
                label="Review Frequency"
                menuItems={freqOptions}
                value={PeriodicReviewFrequency}
                onChange={(val) => updateState(val, "PeriodicReviewFrequency")}
              />
            </div>
            <div style={{ width: "50%" }}>
              <MultiSelect
                label={"Dimesnions"}
                mapper={{
                  label: "DimensionName",
                  value: "TransactionID",
                }}
                includeID={true}
                featureId={DimensionFeatureId}
                value={PeriodicReviewDimensions}
                apiResult={dimList}
                storeApiResult={(val) => updateState(val, "dimList")}
                onChange={(val) => updateState(val, "PeriodicReviewDimensions")}
              />
            </div>
          </div>
          <br />
        </>
      )}
    </div>
  );
}

export default PeriodicReview;
