import React from "react";

import Grid from '@mui/material/Grid';

const Section1 = (props) => {
    console.log("Test HEader: ",props)
    const Placeholder1 = props.Placeholder_1;
    const Placeholder2 = props.Placeholder_2;
    let Component1 = Placeholder1 !== undefined ? require('./'+Placeholder1.AComponent).default : null ; 
    let Component2 = Placeholder2 !== undefined ? require('./'+Placeholder2.AComponent).default : null ; 

    return(
        <Grid container>
            <Grid xs = {2} className = "Placeholder_1">
                <Component1 />
            </Grid>
            <Grid xs = {4} className = "Placeholder_2">
                <Component2 {...Placeholder2}/>
            </Grid>
        </Grid>
    )
};

export default Section1;