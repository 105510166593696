import { Actions } from "../Actions/Actiontypes";
import HandleCondition from "../../Utils/Condition";

const initialState = {};

function DataReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_DATA: {
      return {
        ...state,
        [action.payload.id]: action.payload.data,
      };
    }
    case Actions.UPDATE_DATA: {
      console.log("In Update data ", state);
      action.payload.data.map((ele) => {
        state[action.payload.id][ele.fieldToBeEffected][ele.prop] =
          ele.finalDecision;
      });
      return state;
    }
    case Actions.UPDATE_PROPS: {
      let {
        id,
        componentNumber,
        data,
        props,
        append,
        updateAllProps,
        fieldName,
      } = action.payload;
      // console.log("From gerral reducer", action.payload," ",state," ",((!state[id] || !state[id][componentNumber]) && !props));
      //fieldName alternate to componentNumber
      if (fieldName) componentNumber = fieldName;
      if (updateAllProps) {
        if (!state[id]) state[id] = {};
        if (!state[id][fieldName]) state[id][fieldName] = {};
        state[id][fieldName] = data;
        console.log("Inside update all props: ", state);
        return state;
      }
      // if ((!state[id] || !state[id][componentNumber]) && props) return state;
      if (append) {
        if ((!state[id] || !state[id][componentNumber]) && props) return state;
        state[id][componentNumber][props] = {
          ...state[id][componentNumber][props],
          data,
        };
        return JSON.parse(JSON.stringify(state));
      }
      if (!props) {
        if (!state[id]) state[id] = {};
        state[id][componentNumber] = data;
      } else {
        if (!state[id]) return state;
        console.log("state is : ", state[id], " ", componentNumber);

        if (!state[id][componentNumber]) state[id][componentNumber] = {};
        //if(!state[id][componentNumber][props])state[id][componentNumber][props]={}
        state[id][componentNumber][props] = data;
      }
      console.log("From gerral reducer", state);
      return JSON.parse(JSON.stringify(state));
      // return state;
    }
    case Actions.UPDATE_ALL_FIELDS: {
      const { id, data, replaceReducer } = action.payload;
      if (replaceReducer) state = data;
      else state[id] = data;

      return { ...state };
    }
    case Actions.SET_FORM_DATA: {
      const { id, componentNumber, data } = action.payload;
      if (!state[id]) state[id] = {};
      state[id][componentNumber] = { ...state[id][componentNumber], ...data };
      return state;
    }
    case Actions.SET_FORM: {
      const { id, data } = action.payload;
      if (!state[id]) state[id] = {};
      Object.keys(data).map((ele) => {
        if (state[id][ele]) state[id][ele].value = data[ele];
        else {
          state[id][ele] = { value: data[ele], editMode: true };
        }
      });
      return state;
    }
    case Actions.SET_FIELD_DATA: {
      console.log("Inside Set field ", state);
      if (action.payload.prop)
        state[action.payload.id][action.payload.field][action.payload.prop] =
          action.payload.data;
      if (action.payload.fieldDependency)
        state[action.payload.id].fieldDependency =
          action.payload.fieldDependency;
      console.log("From Set field: ", state);
      if (state[action.payload.id].fieldDependency) {
        // action.payload.dispatch(HandleCondition(state[action.payload.id].fieldDependency,state,action.payload.id))
        //   HandleCondition(state[action.payload.id].fieldDependency,state,action.payload.id)?.map((ele)=>{
        //     state[action.payload.id][ele.fieldToBeEffected][ele.prop]=ele.finalDecision;
        //   })

        return state;
        //   .then((res)=>{
        //         console.log("Result it: ",res)
        //         res.map((ele)=>{
        //             state[action.payload.id][ele.fieldToBeEffected][ele.prop]=ele.finalDecision;
        //         });
        //         console.log("Before returning State ",state)
        //        return {...state,test:"hello"};

        //     }).catch((e)=>{
        //         console.log("error in ")
        //         return state;
        //     })
        //console.log("Test data : ",TestData)
      }
      console.log("Before returning State1 ", state);
      return state;
    }
    case Actions.UNSET_DATA: {
      let data = state;
      delete data[action.payload.id];
      return data;
    }
    case Actions.STORE_PAYLOAD: {
      console.log("From store payload ", action.payload);
      const { id, submitPayload } = action.payload;
      if (!state[id]) state[id] = {};
      if (state[id].submitPayload) {
        console.log("Inside if 1: ", submitPayload);
        state[id].submitPayload.Header = {
          ...state[id].submitPayload.Header,
          ...submitPayload.Header,
        };
        state[id].submitPayload.payloadSchema = {
          ...state[id].submitPayload.payloadSchema,
          ...submitPayload.payloadSchema,
        };
      } else {
        console.log("Inside if 2: ", submitPayload);
        state[id].submitPayload = submitPayload;
      }
      return state;
    }
    case Actions.EDIT_MODE: {
      const { id, header } = action.payload;
      //  if(!state[id].submitPayload?.editMode)return state;
      if (!state[id] || !state[id].submitPayload) return state;
      state[id].submitPayload.editMode = { header };
      console.log("Edit mode: ", action.payload);
      return state;
    }
    case Actions.ENABLED_GROUPS: {
      console.log(
        "Enabled groups: ",
        action.payload.id,
        " ",
        action.payload.data
      );
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          enabledGroups: action.payload.data,
        },
      };
    }
    case Actions.ADD_GROUP: {
      const { groupName, id } = action.payload;
      if (state[id].enabledGroups?.includes(groupName)) return state;
      return {
        ...state,
        [id]: {
          ...state[id],
          enabledGroups: [...state[id].enabledGroups, groupName],
        },
      };
    }
    case Actions.ADD_ENABLED_GROUPS: {
      const { id, enabledGroups } = action.payload;
      console.log("State is : ", state[id], " ", id);
      if (!state[id])
        state[id] = {
          otherData: {},
        };
      if (!state[id].otherData) state[id].otherData = {};
      state[id].otherData.enabledGroups = enabledGroups;
      return state;
    }
    case Actions.ADD_GROUPS: {
      const { groupsName, id } = action.payload;
      if (!groupsName || groupsName.length === 0) return state;
      return {
        ...state,
        [id]: {
          ...state[id],
          enabledGroups: [...state[id].enabledGroups, ...groupsName],
        },
      };
    }

    case Actions.SET_SHELL_LOADING: {
      const { id, loading } = action.payload;
      state[id].loading = loading;
      return state;
    }
    case Actions.REMOVE_GROUP: {
      const { groupName, id } = action.payload;
      var arr = state[id]?.enabledGroups;
      if (!arr) return state;
      var index = arr.indexOf(groupName);
      if (index === -1) return state;
      arr.splice(index, 1);
      return {
        ...state,
        [id]: {
          ...state[id],
          enabledGroups: arr,
        },
      };
    }
    case Actions.REMOVE_SHELL_DATA: {
      const { id } = action.payload;
      var obj = state;
      delete obj[id];
      return {
        ...obj,
      };
    }
    case Actions.GROUP_REFERNECE: {
      const { id, groupRef } = action.payload;
      console.log("From GROUP_REFERNECE ", id, groupRef);
      return {
        ...state,
        [id]: {
          ...state[id],
          groupRef,
        },
      };
    }
    // case Actions.GROUP_REFERNECE: {
    //   const { id, componentNumber, group } = action.payload
    //   var groupRef = state[id].groupRef
    //   if (!groupRef) {
    //     groupRef = {}
    //   } else {
    //     if (!groupRef[group]) {
    //       groupRef[group] = [componentNumber]
    //     } else if (!groupRef[group].includes(componentNumber)) {
    //       groupRef[group].push(componentNumber)
    //     }
    //   }

    //   return {
    //     ...state,
    //     [id]: {
    //       ...state[id],
    //       groupRef,
    //     },
    //   }
    // }
    case "clear": {
      return {};
    }

    default:
      return state;
  }
}

export default DataReducer;
