import { ExpandMoreRounded, ExpandLessRounded } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import Atomic from "../../../AComponent/Atomic";

const { Button } = Atomic;

function DimensionPeriodAccordian({ PeriodTime, children }) {
  const [open, setOpen] = useState(true);

  return (
    <Box sx={{ width: "100%", margin: "" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0.6em 0.3em 0.6em 0em",
          borderRadius: "6px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            sx={{ marginRight: "0.5em" }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {!open && (
              <ExpandMoreRounded fontSize="large" sx={{ color: "black" }} />
            )}
            {open && (
              <ExpandLessRounded fontSize="large" sx={{ color: "black" }} />
            )}
          </IconButton>
          <Typography variant="h3">{PeriodTime}</Typography>
        </Box>
        <Button
          color={"secondary"}
          label={"Current Period"}
          onClick={() => {}}
          title={"Current Period"}
          variant={"contained"}
        />
      </Box>
      {open && (
        <Box
          sx={{
            borderTop: 0,
            margin: "0 auto",
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

export default DimensionPeriodAccordian;
