// import React, { useState, useEffect } from 'react'
// import * as XLSX from 'xlsx'
// import Paper from '@mui/material/Paper'
// import * as BackValidations from './BackValidation'
// import ReactDataSheet from 'react-datasheet'
// import Select from '@mui/material/Select'
// import MenuItem from '@mui/material/MenuItem'
// import InputLabel from '@mui/material/InputLabel'
// import Button from '@mui/material/Button'
// import ListItemText from '@mui/material/ListItemText'
// import Checkbox from '@mui/material/Checkbox'
// import Switch from '@mui/material/Switch'
// import Tooltip from '@mui/material/Tooltip'
// import Menu from '@mui/material/Menu'
// import { Alert } from '@mui/material'
// import MoreVertIcon from '@mui/icons-material/MoreVert'
// import Modal from '@mui/material/Modal'
// import MenuIcon from '@mui/icons-material/Menu'
// import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
// import { makeStyles } from '@mui/material/styles'
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
// import 'react-datasheet/lib/react-datasheet.css'
// import './tesmp.css'
// import UndoIcon from '@mui/icons-material/Undo'
// import RedoIcon from '@mui/icons-material/Redo'
// import { parseSync } from '@babel/core'
// import { CircularProgress } from '@mui/material'

// const sheets = [
//   'application/vnd.ms-excel',
//   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
// ]

// const Validations = {
//   MOBILE_VALIDATION: 'MOBILE_VALIDATON',
//   EMAIL_VALIDATION: 'EMAIL_VALIDATION',
//   REQUIRED_VALIDATION: 'REQUIRED_VALIDATION',
//   UNIQUE_VALIDATION: 'UNIQUE_VALIDATION',
// }

// function inputValidation(field, validator) {
//   var pattern
//   switch (validator) {
//     case Validations.MOBILE_VALIDATION:
//       pattern = new RegExp('^[789]\\d{9}$')
//       return pattern.test(field)
//     case Validations.EMAIL_VALIDATION:
//       pattern = new RegExp(
//         '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
//       )
//       return pattern.test(field)
//     case Validations.REQUIRED_VALIDATION:
//       return field === ''
//     case Validations.UNIQUE_VALIDATION:
//       let obj = {}
//       let arr = []
//       for (let i = 0; i < field.length; i++) {
//         if (field[i] in obj) {
//           arr.push(field[i])
//         } else {
//           obj = {
//             ...obj,
//             [field[i]]: '',
//           }
//         }
//       }
//       return arr
//   }
// }

// const Fields = [
//   {
//     label: 'EMP',
//     validators: [],
//     optionalValidators: ['REQUIRED_VALIDATION', 'UNIQUE_VALIDATION'],
//     required: true,
//   },
//   {
//     label: 'EMp_name',
//     validators: [],
//     required: true,
//   },
//   { label: 'abc', matched: false, required: true },
//   {
//     label: 'phone',
//     validators: ['MOBILE_VALIDATON'],
//     required: true,
//   },
//   {
//     label: 'email',
//     validators: ['EMAIL_VALIDATION'],
//    require:true
//   },
//   // {
//   //   label: '1',
//   //   matched: false,
   
//   //   mappedTo: '',
//   //  require:true
//   // },
//   // {
//   //   label: '2',
//   //   matched: false,
    
//   //   mappedTo: '',
//   //  require:true
//   // },
//   // {
//   //   label: '3',
//   //   matched: false,
    
//   //   mappedTo: '',
//   //  require:true
//   // },
//   // {
//   //   label: '4',
//   //   matched: false,
    
//   //   mappedTo: '',
//   //  require:true
//   // },
//   // {
//   //   label: '5',
//   //   matched: false,
   
//   //   mappedTo: '',
//   //  require:true
//   // },
//   // {
//   //   label: '6',
//   //   matched: false,
 
//   //   mappedTo: '',
//   //  require:true
//   // },
//   // {
//   //   label: '7',
//   //   matched: false,
  
//   //   mappedTo: '',
//   //  require:true
//   // },
//   // {
//   //   label: '8',
//   //   matched: false,
   
//   //   mappedTo: '',
//   //  require:true
//   // },
//   // {
//   //   label: '9',
//   //   matched: false,
    
//   //   mappedTo: '',
//   //  require:true
//   // },
// ]

// const modalTypes = {
//   FIELDS_UNMAPPED: 'FIELDS_UNMAPPED',
//   SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
//   SUBMIT_FAILURE: 'SUBMIT_FAILURE',
// }




// function Excel2() {
//   const [fileData, setFileData] = useState({})
//   const [file, setFile] = useState('')
//   const [grid, setGrid] = useState([])
//   const [cardState, setCardState] = useState({})
//   const [displaySheet, setDisplaySheet] = useState(false)
//   const [fieldState, setFieldState] = useState(Fields)
//   const [mapper, setMapper] = useState({})
//   const [ignored, setIgnored] = useState({})
//   const [flowState, setFlowState] = useState(0)
//   const [errRows, setErrorRows] = useState({})
//   const [showErrorRows, setShowErrorRows] = useState(false)
//   const [complete, setComplete] = useState(false)
//   const [backError, setBackError] = useState([])
//   const [undoStack, setUndoStack] = useState([])
//   const [redoStack, setRedoStack] = useState([])
//   const [changed, setChanged] = useState(false)
//   const [prevDataState, setPrevDataState] = useState({
//     grid: [],
//     mapper: {},
//   })
//   const [modal, setModal] = useState({
//     open: false,
//     type: '',
//     payload: {},
//   })
// useEffect(()=>{
// let fields=fieldState;
// for(let i=0;i<fields.length;i++){
//   if(fields[i].matched!==false)fields[i].matched=false;
//   if(!fields[i].validators)fields[i].validators=[];
// }
// console.log(fields)
// setFieldState([...fields])
// },[])
//   function DisplayModal() {
//     var body

//     function rand() {
//       return Math.round(Math.random() * 20) - 10
//     }

//     function getModalStyle() {
//       const top = 50 + rand()
//       const left = 50 + rand()

//       return {
//         top: `${top}%`,
//         left: `${left}%`,
//         transform: `translate(-${top}%, -${left}%)`,
//       }
//     }

//     const useStyles = makeStyles((theme) => ({
//       paper: {
//         position: 'absolute',
//         width: 400,
//         backgroundColor: theme.palette.background.paper,
//         border: '2px solid #000',
//         boxShadow: theme.shadows[5],
//         padding: theme.spacing(2, 4, 3),
//       },
//     }))

//     const classes = useStyles()

//     const [modalStyle] = useState(getModalStyle)

//     if (modal.type === modalTypes.FIELDS_UNMAPPED) {
//       body = (
//         <div style={modalStyle} className={classes.paper}>
//           <h2
//             id="simple-modal-title"
//             style={{ display: 'flex', justifyContent: 'center' }}
//           >
//             {' '}
//             <ErrorOutlineIcon style={{ fontSize: 50, color: 'red' }} />{' '}
//           </h2>
//           <p id="simple-modal-description">
//             <div>
//               <h5>{'You Have Not Mapped Some Required Field:'}</h5>
//               <br />
//               <ul style={{listStyleType:'none'}}>
//                 {modal.payload.map((fi, index) => {
//                   return <li key={index}>{fi}</li>
//                 })}
//               </ul>
//             </div>
//           </p>
//           <div
//             style={{
//               display: 'flex',
//               width: '100%',
//               justifyContent: 'flex-end',
//             }}
//           >
//             <Button variant="contained" color="primary" onClick={handleClose}>
//               ok
//             </Button>
//           </div>
//         </div>
//       )
//     } else if (modal.type === modalTypes.SUBMIT_SUCCESS) {
//       body = (
//         <div style={modalStyle} className={classes.paper}>
//           <h2
//             id="simple-modal-title"
//             style={{ display: 'flex', justifyContent: 'center' }}
//           >
//             {' '}
//             <CheckCircleOutlinedIcon
//               style={{ fontSize: 100, color: 'lightgreen' }}
//             />{' '}
//           </h2>
//           <p id="simple-modal-description">
//             <div>
//               <h4 style={{ marginLeft: '50px' }}>
//                 {'Successfully Saved Data!'}
//               </h4>
//               <br />
//             </div>
//           </p>
//           <div
//             style={{
//               display: 'flex',
//               width: '100%',
//               justifyContent: 'flex-end',
//             }}
//           >
//             <Button onClick={handleClose}>Cancel</Button>
//             <br />
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={() => {
//                 console.log('Downlaoding...')
//               }}
//             >
//               Download A Copy
//             </Button>
//           </div>
//         </div>
//       )
//     } else if (modal.type === modalTypes.SUBMIT_FAILURE) {
//       body = (
//         <div style={modalStyle} className={classes.paper}>
//           <h2
//             id="simple-modal-title"
//             style={{ display: 'flex', justifyContent: 'center' }}
//           >
//             {' '}
//             <ErrorOutlineIcon style={{ fontSize: 50, color: 'red' }} />{' '}
//           </h2>
//           <p id="simple-modal-description">
//             <div>
//               <h4 style={{ marginLeft: '50px' }}>{'Error In Your Data'}</h4>
//               <br />
//             </div>
//           </p>
//           <div
//             style={{
//               display: 'flex',
//               width: '100%',
//               justifyContent: 'flex-end',
//             }}
//           >
//             <Button onClick={handleClose}>Ok</Button>
//             <br />
//             {/* <Button variant="contained" color="primary" onClick={()=>{console.log("Downlaoding...")}}>Download A Copy</Button> */}
//           </div>
//         </div>
//       )
//     }

//     function handleClose() {
//       setModal({ open: false })
//     }
//     return (
//       <div>
//         {/* <button type="button" onClick={handleOpen}>
//       Open Modal
//     </button> */}
//         <Modal
//           open={true}
//           onClose={handleClose}
//           aria-labelledby="simple-modal-title"
//           aria-describedby="simple-modal-description"
//         >
//           {body}
//         </Modal>
//       </div>
//     )
//   }

//   useEffect(() => {
//     let obj = {}
//     for (let i = 0; i < fileData.columns?.length; i++) {
//       obj[i] = { open: true, ignored: false }
//     }
//     //console.log("Obuject is ",JSON.stringify(obj)," ",fileData)
//     setCardState(obj)
//     setComplete(true)
//   }, [fileData])

//   useEffect(() => {}, [grid])

//   useEffect(() => {
//     if (file !== '') readFile()
//     //  / setFlowState(0)
//   }, [file])

//   function readFile() {
//     var f = file
//     var name = f.name
//     const reader = new FileReader()
//     reader.onload = (evt) => {
//       const bstr = evt.target.result
//       const wb = XLSX.read(bstr, { type: 'binary' })
//       const wsname = wb.SheetNames[0]
//       const ws = wb.Sheets[wsname]
//       const data = XLSX.utils.sheet_to_csv(ws, { header: 1 })
//       //  console.log('Data>>>' + data)
//       // console.log(convertToJson(data));

//       setFileData((prev) => ({
//         ...prev,
//         data: JSON.parse(convertToJson(data)),
//       }))
//     }
//     reader.readAsBinaryString(f)
//   }
//   function convertToJson(csv) {
//     var lines = csv.split('\n')

//     var result = []

//     var headers = lines[0].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
//     var tempArr = fieldState
//     for (let i = 0; i < headers.length; i++) {
//       var flag = false
//       for (let j = 0; j < fieldState.length; j++) {
//         if (
//           ('' + headers[i]).toLowerCase() ===
//             ('' + fieldState[j].label).toLowerCase() &&
//           !tempArr[j].matched
//         ) {
//           flag = true
//           tempArr[j] = { ...tempArr[j], matched: true }
//           setMapper((prev) => ({
//             ...prev,
//             [headers[i]]: {
//               mappedField: tempArr[j],
//               columnIndex: i,
//               fieldIndex: j,
//             },
//           }))
//         }
//       }
//       if (!flag) {
//         setMapper((prev) => ({
//           ...prev,
//           [headers[i]]: { mappedField: null, columnIndex: i },
//         }))
//         setIgnored((prev) => ({
//           ...prev,
//           [i]: true,
//         }))
//       }
//     }
//     setFieldState(tempArr)

//     setFileData((prev) => ({
//       ...prev,
//       columns: headers,
//     }))
//     setGrid([])
//     for (var i = 1; i < lines.length - 1; i++) {
//       var obj = {}
//       var currentline = lines[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
//       //  /  console.log("Header ",currentline);
//       let row = []
//       for (var j = 0; j < headers.length; j++) {
//         obj[headers[j]] = currentline[j]
//         row[j] = { value: currentline[j] }
//       }
//       setGrid((prev) => [...prev, row])
//       setDisplaySheet(true)
//       result.push(obj)
//     }

//     //return result; //JavaScript object
//     return JSON.stringify(result) //JSON
//   }
//   function reset(){
//     setFile('');
//     setFileData({});
//     setGrid([]);
//     setCardState({});
//     setFlowState(0);
//   }
//   function onUploadFile(e) {
//     // init();
//     reset()
//     e.stopPropagation()
//     e.preventDefault()
//     var file = e.target.files[0]
//     if (!sheets.includes(file.type)) {
//       alert('Only Excel sheets')
//       return
//     }

//     if (!file) return
//     setFile(file)
//   }
//   function cell(props) {
//     //  console.log(props)
//   //  return <span className="value-viewer">{(""+props.value).length>10?(""+props.value).substr(0,10)+"...":props.value}</span>
// return   <span className="value-viewer">{props.value}</span>
// }

//   //Micro Components
//   function Card({ columnObject }) {
//     var fieldIndex = columnObject.fieldIndex

//     function onSelectValue(e) {
//       let temp = fieldState
//       let val = e.target.value
//       let validArray = temp[fieldIndex].validators
//       let optionalVal = temp[fieldIndex].optionalValidators
//       let filterArr = []
//       console.log('Selected ', e.target.value, ' ', validArray)
//       if (validArray) {
//         val.map((val1) => {
//           if (!validArray.includes(val1)) validArray.push(val1)
//         })
//         if (optionalVal) {
//           optionalVal.map((op) => {
//             if (!val.includes(op)) filterArr.push(op)
//           })
//           //  console.log("Teswting ",filterArr)
//           validArray = validArray.filter((fil) => {
//             return !filterArr.includes(fil)
//           })
//         }
//         // console.log("Valid Array ",validArray," ",temp[fieldIndex])
//       }

//       temp[fieldIndex] = { ...columnObject.mappedField, validators: validArray }
//       setFieldState([...temp])
//     }
//     function onIgnored() {
    
//       //console.log("Ignored ",ignored)
//       let coIndex = columnObject.columnIndex

//       setIgnored((prev) => ({
//         ...prev,
//         [coIndex]: true,
//       }))

//       setCardState((prev) => ({
//         ...prev,
//         [coIndex]: { open: false, ignored: true },
//       }))
//     }
//     function onRevert() {
//       //  console.log("Ignored ",ignored)
//       let coIndex = columnObject.columnIndex
//       setIgnored((prev) => ({
//         ...prev,
//         [coIndex]: false,
//       }))
//       setCardState((prev) => ({
//         ...prev,
//         [coIndex]: { open: true, ignored: false },
//       }))
//     }
//     function fieldMapped(e) {
//       let val = e.target.value
//       let arr = fieldState
//       var newIndex

//       for (let i = 0; i < arr.length; i++) {
//         if (arr[i].label === val) {
//           newIndex = i
//           arr[i].matched = true
//           break
//         }
//       }
//       setMapper((prev) => ({
//         ...prev,
//         [fileData.columns[columnObject.columnIndex]]: {
//           mappedField: arr[newIndex],
//           fieldIndex: newIndex,
//           columnIndex: columnObject.columnIndex,
//         },
//       }))
//       //    console.log('Sleected ', arr)
//       setFieldState(arr)
//       onRevert()
//     }
//     function unMap() {
//       console.log(columnObject, ' ', fieldState)
//       let colName = fileData.columns[columnObject.columnIndex]
//       let obj = mapper[colName]
//       obj.mappedField = null
//       setMapper((prev) => ({
//         ...prev,
//         [colName]: obj,
//       }))
//       let obj2 = fieldState[fieldIndex]
//       obj2.matched = false
//       let arr = fieldState
//       arr[fieldIndex] = obj2
//       setFieldState([...arr])
//     }
//     return (
//       <>
//         {cardState[columnObject.columnIndex]?.open ? (
//           <Paper
//             style={{
//               display: 'flex',
//               height: 180,
//               width: 400,
//               alignItems: 'center',
//               padding: 20,
//             }}
//           >
//             <div
//               style={{
//                 display: 'flex',
//                 flexDirection: 'row',
//                 width: '100%',
//                 justifyContent: 'space-around',
//                 alignItems: 'center',
//               }}
//             >
//               <div style={{ width: '50%' }}>
//                 <div style={{ width: '40%' }}>
//                   <h6>{fileData.columns[columnObject.columnIndex]}</h6>
//                 </div>
//                 {/* 
//                 {console.log(
//                   'Debug ',
//                   columnObject,
//                   ' ',
//                   fieldState[fieldIndex],
//                 )} */}
//                 {columnObject.mappedField &&
//                   fieldState[fieldIndex]?.optionalValidators?.length > 0 && (
//                     <>
//                       <InputLabel>{'Optional Validators'}</InputLabel>
//                       <Select
//                         labelId="demo-mutiple-checkbox-label"
//                         id="demo-mutiple-checkbox"
//                         style={{ width: '100%' }}
//                         multiple
//                         value={
//                           !fieldState[fieldIndex].validators
//                             ? []
//                             : fieldState[fieldIndex].validators
//                         }
//                         onChange={onSelectValue}
//                         renderValue={(selected) => selected.join(', ')}
//                       >
//                         {fieldState[fieldIndex].optionalValidators?.map(
//                           (options) => {
//                             console.log('Options ', options)

//                             return (
//                               <MenuItem value={options}>
//                                 <Checkbox
//                                   checked={
//                                     fieldState[fieldIndex].validators?.indexOf(
//                                       options,
//                                     ) > -1
//                                   }
//                                 />
//                                 <ListItemText primary={options} />
//                               </MenuItem>
//                             )
//                           },
//                         )}
//                       </Select>
//                     </>
//                   )}
//               </div>

//               <div>
//                 {columnObject.mappedField ? (
//                   <>
//                     <ul style={{listStyleType:'none'}}>
//                       <li style={{ marginBottom: 30 }}>
//                         {'✔ Mapped To Field ' + fieldState[fieldIndex].label}
//                       </li>
//                       <li>
//                         <div style={{ width: '100%', display: 'flex' }}>
//                           <Button
//                             variant="contained"
//                             size="small"
//                             onClick={() => {
//                               unMap();
//                               // setCardState((prev) => ({ ...prev, [columnObject.columnIndex]:{...cardState[columnObject.columnIndex], open: false} }))
//                             }}
//                             style={{
//                               backgroundColor: '#5cb85c',
//                               color: 'white',
//                             }}
//                           >
//                             UnMap
//                           </Button>
//                           <br />
//                           <br />
//                           <Button
//                             style={{ marginLeft: 20 }}
//                             onClick={()=>{
//                               onIgnored();
//                               unMap();
//                             }}
//                           >
//                             Ignore
//                           </Button>
//                         </div>
//                       </li>
//                     </ul>
//                   </>
//                 ) : (
//                   <>
//                     <ul style={{listStyleType:'none'}}>
//                       <li>
//                         {'❌ Could not Map to any field so Ignored this column'}
//                       </li>

//                       <br />
//                       <li>
//                         <InputLabel>{'Availble Fields'}</InputLabel>
//                         <Select
//                           value={'Empty'}
//                           onChange={fieldMapped}
//                           style={{ width: '100%' }}
//                         >
//                           {console.log(fieldState)}
//                           {fieldState?.map((options) => {
//                             if (!options.matched)
//                               return (
//                                 <MenuItem value={options.label}>
//                                   {options.label}
//                                 </MenuItem>
//                               )
//                           })}
//                         </Select>
//                       </li>
//                     </ul>
//                   </>
//                 )}
//               </div>
//             </div>
//           </Paper>
//         ) : (
//           <Paper
//             style={{
//               width: 400,
//               height: 80,
//               display: 'flex',
//               justifyContent: 'center',
//             }}
//           >
//             {cardState[columnObject.columnIndex]?.ignored ? (
//               <>
//                 <span>
//                   {'🤷‍♀️ Ignored Column ' +
//                     fileData.columns[columnObject.columnIndex]}
//                 </span>
//               </>
//             ) : (
//               <>
//                 <span>
//                   {'✔ Confirmed Mapping for Column: ' +
//                     fileData.columns[columnObject.columnIndex]}
//                 </span>
//               </>
//             )}
//             <br />
//             <Button onClick={onRevert}>Revert</Button>
//           </Paper>
//         )}
//       </>
//     )
//   }
//   function Mapper() {
//     return (
//       <div
//         style={{
//           width: '100%',
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           marginTop:30,
//           marginBottom:30
//         }}
//       >
//         {Object.keys(mapper).map((key, value) => {
//           // console.log('sdjkfh ', mapper[key])
//           return (
//             <>
//               {' '}
//               <Card columnObject={mapper[key]} /> <br />{' '}
//             </>
//           )
//         })}
//         {JSON.stringify(mapper) !== '{}' && (
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={() => {
//               onApply();

//             }}
//           >
//             Apply
//           </Button>
//         )}
//       </div>
//     )
//   }

//   function sendToBack() {
//     BackValidations.sendPayload(grid, (result) => {
//       if (result.error) {
//         console.log('From BAck ', result)
//         setBackError(result.error)
//       } else {
//         setBackError([])
//       }
//     })
//   }

//   function onApply() {
//     setIgnored([]);
//     sendToBack()
//     var payload = []
//     fieldState.map((ele) => {
//       console.log("Testing.... ",ele)
//       if (!ele.matched && ele.required) {
//         payload.push(ele.label)
//       }
//     })
//     if (payload.length > 0) {
//       setModal({
//         open: true,
//         payload,
//         type: modalTypes.FIELDS_UNMAPPED,
//       })
//     } else {
//       setFlowState(1)
//     }
//   }

//   function conditinalRendering(cell, row, col) {
//     //console.log("Ignored 2 ",ignored)
//     //   if(ignored[col])return null;
//     // let validators=mapper[fileData.columns[col]].mappedField?.validators;
//     // let err=[];
//     // if(validators){
//     //   validators.map((exp,index)=>{
//     //     if(!inputValidation(cell.value,exp)){
//     //         err.push(exp);
//     //         if(!errRows[row]){
//     //           setErrorRows(prev=>({
//     //             ...prev,
//     //             [row]:true
//     //           }))
//     //         }
//     //     }
//     //   })
//     // }

//     // const MyComponent = React.forwardRef(function MyComponent(props, ref) {
//     //   //  Spread the props to the underlying DOM element.
//     //   return <span className="value-viewer" {...props} ref={ref} style={{color:'red'}} >{cell.value}</span>
//     //  // return JSON.stringify(cell.value)
//     // });

//     // function renderTooltip(arr){
//     //   return(
//     //     <ul style={{marginLeft:-20}}>
//     //     {arr.map((er)=>{
//     //       return<li>{er}</li>
//     //     })}
//     //     </ul>
//     //   )
//     // }

//     //if(err.length>0)return <Tooltip style={{width:'10px'}}  title={renderTooltip(err)}><MyComponent /></Tooltip>
//     //if(err.length>0)return cell.value
//     return cell.value
//   }

//   function rowRender(props) {
//     //console.log("Show Error rows ",errRows," ",showErrorRows);
//     //console.log("Error Rows ",errRows)
//     return (
//       <>
//         {showErrorRows && errRows[props.row] && (
//           <tr>
//             <td className="action-cell">
//               <span
//                 style={{ width: '50px', display: 'block', textAlign: 'center' }}
//               >
//                 {props.row + 1}
//               </span>
//             </td>

//             {props.children}
//           </tr>
//         )}
//         {!showErrorRows && (
//           <tr>
//             <td className="action-cell">
//               <span
//                 style={{ width: '50px', display: 'block', textAlign: 'center' }}
//               >
//                 {props.row + 1}
//               </span>
//             </td>

//             {props.children}
//           </tr>
//         )}
//       </>
//     )
//   }
//   function SimpleMenu(props) {
//     const [anchorEl, setAnchorEl] = useState(null)

//     const handleClick = (event) => {
//       setAnchorEl(event.currentTarget)
//     }

//     const handleClose = () => {
//       setAnchorEl(null)
//     }
//     function onDeletColumn() {
//       handleClose()
//       setIgnored((prev) => ({
//         ...prev,
//         [props.colNumber]: true,
//       }))
//       setUndoStack((prev) => [
//         ...prev,
//         { type: 'COLUMN_DELETE', col: props.colNumber },
//       ])
//       setGrid([...grid])
//     }
//     function onSortA() {
//       handleClose()
//       let grid1 = grid
//       grid1.sort((a, b) =>
//         a[props.colNumber].value > b[props.colNumber].value
//           ? 1
//           : b[props.colNumber].value > a[props.colNumber].value
//           ? -1
//           : 0,
//       )
//       setGrid([...grid1])
//     }
//     function onSortD() {
//       handleClose()
//       let grid1 = grid
//       grid1.sort((a, b) =>
//         a[props.colNumber].value > b[props.colNumber].value
//           ? -1
//           : b[props.colNumber].value > a[props.colNumber].value
//           ? 1
//           : 0,
//       )
//       setGrid([...grid1])
//     }
//     return (
//       <div>
//         <Button
//           style={{ padding: 0, minWidth: 0 }}
//           aria-controls="simple-menu"
//           aria-haspopup="true"
//           onClick={handleClick}
//         >
//           <MoreVertIcon />
//         </Button>
//         <Menu
//           id="simple-menu"
//           anchorEl={anchorEl}
//           keepMounted
//           open={Boolean(anchorEl)}
//           onClose={handleClose}
//         >
//           <MenuItem onClick={onSortA}>{'Sort A->Z'}</MenuItem>
//           <MenuItem onClick={onSortD}>{'Sort Z->A'}</MenuItem>
//           <MenuItem onClick={onDeletColumn}>
//             <span style={{ color: 'red' }}>Delete Column</span>
//           </MenuItem>
//         </Menu>
//       </div>
//     )
//   }

//   function renderSheet(props) {
//     // {showErrorRows &&  JSON.stringify(errRows)==='{}' && <h1>No Errors!</h1>}
//     if (showErrorRows && JSON.stringify(errRows) === '{}')
//       return <h1 style={{ color: 'lightgreen' }}>No Errors!</h1>
//     return (
//       <table className={props.className}>
//         <thead>
//           <tr>
//             <th className="action-cell" />
//             {fileData.columns.map((col, index) => {
//               if (ignored[index]) return null
//               return (
//                 <th
//                   className="action-cell"
//                   style={{ position: 'relative', height: 35 }}
//                 >
//                   {col}
//                   <div style={{ position: 'absolute', right: 2, top: 5 }}>
//                     <SimpleMenu colNumber={index} />
//                   </div>
//                 </th>
//               )
//             })}
//             {/* {fileData.columns.map((col,index) => (<th className="action-cell"  >{col}</th>))} */}
//           </tr>
//         </thead>
//         <tbody>{props.children}</tbody>
//       </table>
//     )
//   }

//   var unique = {}
//   function viewValue(props) {
//     const { cell, row, col } = props
//     // console.log("askf ",mapper," ",col,fileData.columns)
//     if (ignored[col]) return null

//     let validators = mapper[fileData.columns[col]].mappedField?.validators

//     let err = []

//     if (validators) {
//       validators.map((exp, index) => {
//         if (exp === Validations.UNIQUE_VALIDATION) {
//           if (
//             unique[col] &&
//             unique[col][cell.value] &&
//             unique[col][cell.value].freq === 1 &&
//             unique[col][cell.value].row !== row
//           ) {
//             err.push(exp)
//             console.log('New error rows ', errRows)
//             if (!errRows[row]) {
//               setErrorRows((prev) => ({
//                 ...prev,
//                 [row]: true,
//               }))
//             }
//           } else {
//             unique = {
//               ...unique,
//               [col]: {
//                 ...unique[col],
//                 [cell.value]: { freq: 1, row: row },
//               },
//             }
//           }
//         } else if (!inputValidation(cell.value, exp)) {
//           err.push(exp)
//           if (!errRows[row]) {
//             setErrorRows((prev) => ({
//               ...prev,
//               [row]: true,
//             }))
//           }
//         }
//       })
//     }

//     const MyComponent = React.forwardRef(function MyComponent(props, ref) {
//       //  Spread the props to the underlying DOM element.
//       return (
//         <span
//           {...props}
//           ref={ref}
//           style={{ color: 'red' }}
//           className="value-viewer"
//         >
//           {cell.value}
//         </span>
//       )
//       // return JSON.stringify(cell.value)
//     })

//     function renderTooltip(arr) {
//       return (
//         <ul style={{ marginLeft: -20 }}>
//           {arr.map((er) => {
//             return <li>{er}</li>
//           })}
//         </ul>
//       )
//     }
//     // setChanged(false);
//    //  if(err.length>0)return <Tooltip style={{width:'10px',zIndex:-10}}  title={renderTooltip(err)}><MyComponent /></Tooltip>
//    let val=(""+cell.value).length>30?(""+cell.value).substr(0,30)+"...":cell.value; 
//    if (err.length > 0)
//       return (
//         <span className="value-viewer" style={{ color: 'red' }}>
//           {/* {cell.value} */}
//           {val}
//         </span>
//       )
//     return <span className="value-viewer">
//       {/* {cell.value} */}
//       {val}
//       </span>
//   }

//   useEffect(() => {
//     if (!showErrorRows) setErrorRows({})
//   }, [grid, showErrorRows])

//   function renderCell(props) {
//     //  console.log("Cell ... ",props);
//     if (ignored[props.col]) return null
//     return (
//       <td {...props} cell="" row="" col="">
//         {props.children}
//       </td>
//     )
//   }

//   function onUndo() {
//     setBackError([])
//     let temp = undoStack

//     let changes = temp.pop()
//     if (changes.type === 'COLUMN_DELETE') {
//       setIgnored((prev) => ({
//         ...prev,
//         [changes.col]: false,
//       }))
//       return
//     }
//     let grid1 = grid
//     grid1[changes.row][changes.col] = {
//       ...grid1[changes.row][changes.col],
//       value: changes.cell.value,
//     }
//     //console.log(temp," ",undoStack," ",changes," ",grid1)
//     setUndoStack([...temp])
//     setGrid([...grid1])
//     setRedoStack((prev) => [...prev, changes])
//   }
//   function onRedo() {
//     let temp = redoStack

//     let changes = temp.pop()
//     let grid1 = grid
//     grid1[changes.row][changes.col] = {
//       ...grid1[changes.row][changes.col],
//       value: changes.cell.value,
//     }
//     //console.log(temp," ",undoStack," ",changes," ",grid1)
//     setRedoStack([...temp])
//     setGrid([...grid1])
//     setUndoStack((prev) => [...prev, changes])
//   }

//   function onSave() {
//     console.log('On Save')
//     var matrix = grid.map((row) => [...row])
//     for (let i = 0; i < matrix.length; i++) {
//       Object.keys(ignored).map((ele) => {
//         if (ignored[ele]) {
//           matrix[i].splice(ele, 1)
//         }
//       })
//     }
//     //setGrid([...matrix]);
//     console.log('Saving...')
//     BackValidations.onsave(matrix, (result) => {
//       if (result.success) {
//         setBackError([])
//         setModal({
//           open: true,
//           type: modalTypes.SUBMIT_SUCCESS,
//         })
//       } else if (result.error) {
//         setBackError([...result.error])
//         setModal({
//           open: true,
//           type: modalTypes.SUBMIT_FAILURE,
//         })
//       }
//     })
//   }

//   return (
//     <div
   
//     >
  
// <script src="./clusterize.min.js"></script>
//       <div className="data-grid-container"></div>
    
//       { JSON.stringify(fileData)==='{}' && <div class="button-wrapper">
//   <span class="label">
//     Upload File
//   </span>
  
//     <input onChange={onUploadFile} type="file" name="upload" id="upload" class="upload-box" placeholder="Upload File" />
  
// </div>}
// {/* <div>
//   <input type="file" onCh />
// </div> */}

       
//       {flowState === 0 && complete && <Mapper />}

//       {flowState === 1 && displaySheet && (
//         <div
//           style={{
//             width: '100%',
//             display: 'flex',
//             justifyContent: 'space-around',
//             flexDirection: 'column',
//             alignItems: 'center',
//           }}
//         >
//           <div>
//             <span>{'Show Only Error Rows: '}</span>
//             <Switch
//               checked={showErrorRows}
//               onChange={() => {
//                 setShowErrorRows(!showErrorRows)
//               }}
//               name="checkedA"
//               inputProps={{ 'aria-label': 'secondary checkbox' }}
//             />
//             {/* <Button onClick={()=>{setShowErrorRows(!showErrorRows);}} >Show Error</Button> */}
//           </div>
//           <br />
//           {/* {console.log(grid)} */}
//           {backError.length > 0 && (
//             <>
//               <ul>
//                 {backError.map((err, index) => {
//                   if (index > 3) return null
//                   return (
//                     <Alert
//                       severity="error"
//                       style={{ marginBottom: 5 }}
//                       key={index}
//                     >
//                       {err}
//                     </Alert>
//                   )
//                   //return <li key={index} style={{color:'red'}}>{err}</li>
//                 })}
//               </ul>
//             </>
//           )}
//           <div
//             style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
//           >
//             {undoStack.length > 0 ? (
//               <span style={{ cursor: 'pointer' }} onClick={onUndo}>
//                 <UndoIcon />
//               </span>
//             ) : (
//               <UndoIcon color="disabled" />
//             )}
//             {/* {redoStack.length>0 ? <span style={{cursor:'pointer'}} onClick={onRedo}><RedoIcon /></span>:<RedoIcon  color="disabled"/>} */}
//           </div>
//           {grid[0]?.length > 0 ? (
//             <div style={{margin:'auto',paddingLeft:30,paddingRight:30}} class="clusterize">
//             <ReactDataSheet
//               dataRenderer={(a, b, c) => {
//                 console.log('Renderer ', a, ' ', b, ' ', c)
//               }}
//               valueViewer={cell}
//               data={grid}
//               rowRenderer={rowRender}
//               sheetRenderer={renderSheet}
//               cellRenderer={renderCell}
//               //  rowRenderer={props => <CellComponent {...props} />}
//               valueRenderer={conditinalRendering}
//              // valueViewer={viewValue}
//               onCellsChanged={(changes) => {
//                 //  setChanged(true)
//                 setUndoStack((prev) => [...prev, changes[0]])

//                 const grid1 = grid.map((row) => [...row])
//                 //console.log("changed ",grid," ",changes[0].row);

//                 BackValidations.sendRow(grid[changes[0].row], (result) => {
//                   let err = backError

//                   if (result.error) {
//                     setBackError((prev) => [...result.error])
//                   }

//                   // setBackError(err);
//                 })
//                 changes.forEach(({ cell, row, col, value }) => {
//                   grid1[row][col] = { ...grid[row][col], value }
//                 })

//                 setGrid(grid1)
//               }}
//             />
//             </div>
//           ) : (
//             <h1>No Columns Selected</h1>
//           )}
//           <br />
//           <Button
//             variant="contained"
//             color="primary"
//             disabled={JSON.stringify(errRows) !== '{}'}
//             onClick={() => {
//               onSave()
//             }}
//           >
//             Save
//           </Button>
//           {/* <Button disabled={JSON.stringify(errRows)!=='{}' } onClick={()=>{onSave()}}>Test</Button> */}
//         </div>
//       )}
//       {modal.open && <DisplayModal />}

//       {/* {JSON.stringify(grid)} */}
//     </div>
//   )
// }

 export default ()=><></>
