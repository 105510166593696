import { Box, IconButton, Tab, Tabs, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import StepAccordian from "../UI/StepAccordian";
import Atomic from "../../../../AComponent/Atomic";
import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { Email, N2A, SMS, WhatsApp } from "./Notifications";
import useProps from "../../../../Utils/CustomHooks/GetProps";

const { EwSwitch } = Atomic;

function ActionExpandable({ name, enabled, onToggle, action, onChange }) {
  const [open, setOpen] = useState(enabled === "E");
  const componentList = [
    {
      label: "Email",
      Component: Email,
      props: {
        Notification: action.Notifications[0] || {},
        onChange: (notification) => {
          const Notifications = action.Notifications;
          Notifications[0] = notification;
          onChange(Notifications);
        },
      },
    },
    {
      label: "SMS",
      Component: SMS,
      props: {
        Notification: action.Notifications[1] || {},
        onChange: (notification) => {
          const Notifications = action.Notifications;
          Notifications[1] = notification;
          onChange(Notifications);
        },
      },
    },
    {
      label: "WhatsApp",
      Component: WhatsApp,
      props: {
        Notification: action.Notifications[2] || {},
        onChange: (notification) => {
          const Notifications = action.Notifications;
          Notifications[2] = notification;
          onChange(Notifications);
        },
      },
    },
    {
      label: "Need-2-Act",
      Component: N2A,
      props: {
        Notification: action.Notifications[3] || {},
        onChange: (notification) => {
          const Notifications = action.Notifications;
          Notifications[3] = notification;
          onChange(Notifications);
        },
        urgency: true,
      },
    },
  ];

  const [active, setActive] = React.useState(0);

  const handleChange = (event, newValue) => {
    setActive(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box>
      <Box display={"flex"} gap={"0.5em"} alignItems={"center"}>
        <IconButton
          size="small"
          onClick={() => {
            if (enabled === "E") {
              setOpen((prevState) => !prevState);
            }
          }}
        >
          {open && <KeyboardArrowDown />}
          {!open && <KeyboardArrowRight />}
        </IconButton>
        <Typography variant="subtitle2" fontWeight={600}>
          {name}:{" "}
        </Typography>
        <Box mt={"-10px"}>
          <EwSwitch
            onChange={(val) => {
              onToggle(val);
              setOpen(val);
            }}
            value={enabled === "E"}
          />
        </Box>
      </Box>
      {open && (
        <Box display={"flex"} gap="0.5em">
          <Box sx={{ opacity: "0" }}>
            <IconButton size="small" disabled={true}>
              {open && <KeyboardArrowDown />}
              {!open && <KeyboardArrowUp />}
            </IconButton>
          </Box>
          <Box>
            <Box maxHeight={"450px"} className="invisble-scroll">
              <Box
                display={"flex"}
                flexWrap={"wrap"}
                alignItems={"center"}
                gap={"0.5em"}
                paddingLeft={"0.5em"}
              >
                <Typography variant="subtitle2">
                  If the action is {action.ActionLabel}, send alert to the next
                  step owner using:
                </Typography>
                <Box>
                  <Tabs
                    value={active}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    {componentList.map((ele, index) => {
                      return (
                        <Tab
                          disabled={ele.disable ? true : false}
                          label={ele.label}
                          {...a11yProps(index)}
                        />
                      );
                    })}
                  </Tabs>
                </Box>
              </Box>
              <Box width={"50vw"}>
                {componentList.map((ele, index) => {
                  if (!ele.props) ele.props = {};
                  return (
                    <TabPanel value={active} index={index}>
                      {ele.Component ? (
                        <ele.Component {...ele.props} />
                      ) : (
                        ele.componentAsFunction()
                      )}
                    </TabPanel>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = useProps(props);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2, paddingLeft: "0" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function ScenarioForm({ ScenarioID, Actions, onChange }) {
  function updateState(Notifications, index) {
    Actions[index].Notifications = Notifications;
    onChange(ScenarioID, Actions);
  }

  return (
    <Box>
      {Actions.map((action, index) => {
        return (
          <Box key={action.ActionLabel}>
            <ActionExpandable
              name={action.ActionLabel}
              enabled={action.Alerts}
              onToggle={(val) => {
                Actions[index].Alerts = val ? "E" : "D";
                onChange(ScenarioID, Actions);
              }}
              action={action}
              onChange={(notifications) => updateState(notifications, index)}
            />
          </Box>
        );
      })}
    </Box>
  );
}

function Alerts({ StepScenarios, updateState }) {
  const saveAlerts = useCallback(
    (ScenarioID, Actions) => {
      const index = StepScenarios.findIndex((a) => a.ScenarioID === ScenarioID);
      if (index !== -1) {
        StepScenarios[index].Actions = Actions;
        updateState(StepScenarios, "StepScenarios");
      }
    },
    [StepScenarios, updateState]
  );

  return (
    <Box position={"relative"}>
      <Typography variant="h4" fontWeight={"600"}>
        Alerts
      </Typography>
      <Box
        mt={"1em"}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.6em",
          padding: "0.5em",
        }}
        height={"60vh"}
        className="invisble-scroll"
      >
        {StepScenarios.length === 0 ? (
          <Box className="Flex">No Scenarios available</Box>
        ) : (
          StepScenarios.map((scenario) => {
            return (
              <StepAccordian
                Name={scenario.ScenarioName}
                key={scenario.ScenarioID}
              >
                <ScenarioForm
                  ScenarioID={scenario.ScenarioID}
                  Actions={scenario.Actions || []}
                  onChange={saveAlerts}
                />
              </StepAccordian>
            );
          })
        )}
      </Box>
    </Box>
  );
}

export default Alerts;
