import React from 'react';

function AButton1( {label}){
    return(
        <div className="abutton1">
            {label}
        </div>
    ); 
}

export default AButton1;