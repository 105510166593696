import {
  Comment,
  CommentOutlined,
  EmojiEvents,
  EmojiEventsOutlined,
  TrackChanges,
  TrackChangesOutlined,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

const CycleDetails = ({ parameter, period }) => {
  return (
    <Box padding={"1em"} width={"35vw"}>
      <Box margin={"1em 0"}>
        <Typography variant="h5">{period.TargetLabel}</Typography>
      </Box>
      <Box>
        <Box margin={"1em 0"}>
          <Typography variant="h6">
            <TrackChangesOutlined /> Target
          </Typography>
          <Typography variant="body1">{period.TargetValue}</Typography>
        </Box>
        <Box margin={"1em 0"}>
          <Typography variant="h6">
            <EmojiEventsOutlined /> Achievement
          </Typography>
          <Typography variant="body1">{"No Achievements given"}</Typography>
        </Box>
        <Box margin={"1em 0"}>
          <Typography variant="h6">
            <CommentOutlined /> Comments
          </Typography>
          <Typography variant="body1">{"No Comments given"}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CycleDetails;
