import { LocationOnOutlined } from "@mui/icons-material";
import { Avatar, Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { complexQuery } from "../../../../Utils/Common/Library";
import { LocationIcon } from "../../../../Utils/Common/Icons";

const AppraiseeSnapshot = ({
  EPP,
  SnapShotWidgetEmployeeType,
  SnapShotWidgetDesignation,
  SnapShotWidgetRole,
  SnapShotWidgetEmployee,
  SnapShotWidgetLocation,
  SnapShotWidgetName,
  SnapShotWidgetGrade,
  SnapShotWidgetFunction,
}) => {
  const [document, setDocument] = useState();

  useEffect(() => {
    complexQuery({
      RequiredFields: [{ UserID: EPP.AppraiseeUserID }],
      QueryName: "FindUserAndItsOrganisationDetails",
    }).then((res) => {
      console.log(JSON.stringify(res), "JSON.STRINGIFY");
      setDocument({
        name: res[0]?.FullName,
        employeeCode: res[0]?.EmployeeCode,
        role: res[0]?.RoleName,
        designation: res[0]?.DesignationName,
        employeeType: res[0]?.EmployeeTypeName,
        location: res[0]?.LocationName,
        grade: res[0]?.GradeName,
        functioning: res[0]?.FunctionName,
      });
    });
  }, [EPP.AppraiseeUserID]);

  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: "0.5em",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      {!document ? (
        <>Loading your profile</>
      ) : (
        // <Box padding={"1em"}>
        //   <Box
        //     sx={{
        //       display: "flex",
        //       alignItems: "center",
        //       padding: "1em 0",
        //     }}
        //   >
        //     <Box marginRight={"1em"}>
        //       <Avatar />
        //     </Box>
        //     <Box
        //       sx={{
        //         display: "flex",
        //         alignItems: "baseline",
        //         flexWrap: "wrap",
        //       }}
        //     >
        //       {SnapShotWidgetName === "E" && (
        //         <Typography variant="h6">{document.name}</Typography>
        //       )}
        //       {SnapShotWidgetEmployee === "E" && (
        //         <Typography variant="overline" sx={{ marginLeft: "1em" }}>
        //           {document.employCode ? `#${document.employeeCode}` : ""}
        //         </Typography>
        //       )}
        //     </Box>
        //   </Box>
        //   <Box
        //     sx={{
        //       display: "flex",
        //       alignItems: "baseline",
        //       justifyContent: "space-between",
        //       flexWrap: "wrap",
        //     }}
        //   >
        //     <Typography sx={{ width: "20em", wordWrap: "break-word" }}>
        //       {`${[
        //         SnapShotWidgetDesignation === "E" ? document.designation : "",
        //         SnapShotWidgetRole === "E" ? document.role : "",
        //         SnapShotWidgetFunction === "E" ? document.functioning : "",
        //       ]
        //         .filter((des) => des)
        //         .join(", ")}`}
        //     </Typography>
        //     {SnapShotWidgetLocation === "E" && (
        //       <Box>
        //         <LocationOnOutlined /> {document.location}
        //       </Box>
        //     )}
        //   </Box>
        //   <Box sx={{ display: "flex" }}>
        //     <Typography>{`${[
        //       SnapShotWidgetGrade === "E" ? document.grade : "",
        //       SnapShotWidgetEmployeeType === "E" ? document.employeeType : "",
        //     ].filter((his) => his)}`}</Typography>
        //   </Box>
        // </Box>
        <Box display={"flex"} gap={"2em"} alignItems={"flex-start"}>
          <Box>
            <Avatar
              src="https://www.pngarts.com/files/10/Default-Profile-Picture-Transparent-Image.png"
              sx={{ height: "2.5em", width: "2.5em" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.3em",
              flex: 1,
            }}
          >
            <Typography variant="subtitle1">
              {document.name
                ? document.name.charAt(0).toUpperCase() + document.name.slice(1)
                : ""}
            </Typography>
            <Typography
              variant="body2"
              color={"grey"}
              sx={{ color: "grey !important" }}
            >
              {`${[
                SnapShotWidgetDesignation === "E" ? document.designation : "",
                SnapShotWidgetRole === "E" ? document.role : "",
                SnapShotWidgetFunction === "E" ? document.functioning : "",
              ]
                .filter((des) => des)
                .join(", ")}`}
            </Typography>
            <Typography
              variant="body2"
              color={"grey"}
              sx={{ color: "grey !important" }}
            >
              {`${[
                SnapShotWidgetGrade === "E" ? document.grade : "",
                SnapShotWidgetEmployeeType === "E" ? document.employeeType : "",
              ]
                .filter((his) => his)
                .join(", ")}`}
            </Typography>
            <Box display={"flex"} gap={"0.6em"} alignItems={"center"}>
              <LocationIcon />
              <Typography variant="caption" fontWeight={400} color={"#6A6A6A"}>
                {document.location || ""}
              </Typography>
            </Box>
          </Box>
          <Box display={"flex"}>
            <Typography variant="overline" fontWeight={400}>
              {document.employeeCode ? `#${document.employeeCode}` : ""}
            </Typography>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default AppraiseeSnapshot;
