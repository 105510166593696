import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Paper,
} from "@mui/material";
import {
  Bar,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ComposedChart,
  Line,
} from "recharts";
import DataGrid2 from "../../MComponent/Table/DataGrid2";

function DimensionDashboard({ dimension, queryResults }) {
  const [chartData, setChartData] = useState({ h1: [], h2: [], annual: [] });
  const [records, setRecords] = useState(queryResults);
  const [selectedFilters, setSelectedFilters] = useState({
    function: [],
    location: [],
    grade: [],
    role: [],
    gender: [],
    range: "All",
    period: "All",
  });
  const [availableFilters, setAvailableFilters] = useState({
    functions: [],
    locations: [],
    roles: [],
    grades: [],
    genders: [],
  });

  useEffect(() => {
    const graphWorker = new Worker("dashboard.worker.js");
    graphWorker.postMessage({
      module: "PSRD",
      type:
        dimension === "GOALS"
          ? "CREATE_GOALS_RECORDS"
          : "CREATE_CORE_VALUES_RECORDS",
      filters: selectedFilters,
      records: queryResults,
    });
    graphWorker.onmessage = (event) => {
      const {
        h1Results,
        h2Results,
        annualResults,
        functions,
        locations,
        roles,
        grades,
        genders,
      } = event.data;

      console.log(event.data, "EVENT>DATAPSR");

      setChartData({ h1: h1Results, h2: h2Results, annual: annualResults });
      setAvailableFilters({ functions, locations, roles, grades, genders });
    };
  }, []);

  function applyFiltersToRecords(
    currentFilters,
    currentRecords,
    updateChart = true
  ) {
    console.log(currentFilters, currentRecords, "THESE ARE THE RECORDS");
    const graphWorker = new Worker("dashboard.worker.js");
    graphWorker.postMessage({
      module: "PSRD",
      type:
        dimension === "GOALS"
          ? "FILTER_GOALS_RECORDS"
          : "FILTER_CORE_VALUES_RECORDS",
      filters: currentFilters,
      records: currentRecords,
    });
    graphWorker.onmessage = (event) => {
      const { h1Results, h2Results, annualResults, filteredRecords } =
        event.data;

      console.log(event.data, "EVNT>DATA");
      // updateChart &&
      setChartData({ h1: h1Results, h2: h2Results, annual: annualResults });
      setRecords(filteredRecords);
    };
  }

  const CustomTooltip = ({ active, payload, label }) => {
    console.log(active, payload, label, "THESEARETHEPROPRIJHGTHERE");
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            backgroundColor: "white",
            padding: "1em",
            border: "1px solid #CCCCCC",
            textAlign: "start",
          }}
        >
          <Typography variant="form_text1">Score Range: {label}</Typography>
          <br />
          <Typography variant="form_text1" sx={{ color: payload[0].fill }}>
            Number Of Employees : {payload[0].payload.numberOfEmployees}
          </Typography>
          <br />
          <Typography variant="form_text1">
            Total Employees : {records.length}
          </Typography>
        </Box>
      );
    }

    return null;
  };

  return (
    <Box>
      <br />
      <Box
        sx={{
          display: "flex",
          gap: "1em",
          alignItems: "center",
          justifyContent: "space-around",
        }}
        className="scroll"
      >
        <Box>
          <Typography variant="form_text1">Filter By Function</Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            options={["All", ...availableFilters.functions]}
            sx={{ width: "15em", marginTop: "0.5em" }}
            onChange={(event, value) => {
              setSelectedFilters((prevState) => {
                if (prevState.function.includes(value[0])) return prevState;
                const newFilters = {
                  ...prevState,
                  function:
                    value[0] === "All" ? [] : [...prevState.function, ...value],
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            multiple
            value={[]}
            renderInput={(params) => <TextField {...params} />}
            disableClearable={true}
          />
        </Box>
        <Box>
          <Typography variant="form_text1">Filter By Location</Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            options={["All", ...availableFilters.locations]}
            sx={{ width: "15em", marginTop: "0.5em" }}
            onChange={(event, value) => {
              setSelectedFilters((prevState) => {
                if (prevState.location.includes(value[0])) return prevState;
                const newFilters = {
                  ...prevState,
                  location:
                    value[0] === "All" ? [] : [...prevState.location, ...value],
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            multiple
            value={[]}
            renderInput={(params) => <TextField {...params} />}
            disableClearable={true}
          />
        </Box>
        <Box>
          <Typography variant="form_text1">Filter By Grade</Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            options={["All", ...availableFilters.grades]}
            sx={{ width: "15em", marginTop: "0.5em" }}
            onChange={(event, value) => {
              setSelectedFilters((prevState) => {
                if (prevState.grade.includes(value[0])) return prevState;
                const newFilters = {
                  ...prevState,
                  grade:
                    value[0] === "All" ? [] : [...prevState.grade, ...value],
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            multiple
            value={[]}
            renderInput={(params) => <TextField {...params} />}
            disableClearable={true}
          />
        </Box>
        <Box>
          <Typography variant="form_text1">Filter By Role</Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            options={["All", ...availableFilters.roles]}
            sx={{ width: "15em", marginTop: "0.5em" }}
            onChange={(event, value) => {
              setSelectedFilters((prevState) => {
                if (prevState.role.includes(value[0])) return prevState;
                const newFilters = {
                  ...prevState,
                  role: value[0] === "All" ? [] : [...prevState.role, ...value],
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            multiple
            value={[]}
            renderInput={(params) => <TextField {...params} />}
            disableClearable={true}
          />
        </Box>
        <Box>
          <Typography variant="form_text1">Filter By Gender</Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            options={["All", ...availableFilters.genders]}
            sx={{ width: "15em", marginTop: "0.5em" }}
            onChange={(event, value) => {
              setSelectedFilters((prevState) => {
                if (prevState.gender.includes(value[0])) return prevState;
                const newFilters = {
                  ...prevState,
                  gender: value[0] === "All" ? [] : [...value],
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            multiple
            value={[]}
            renderInput={(params) => <TextField {...params} />}
            disableClearable={true}
          />
        </Box>
      </Box>
      <br />
      <Box
        sx={{
          width: "100%",
          // border: "1px solid #0288d1",
          borderRadius: "5px",
          padding: "1em",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "1em",
        }}
      >
        <Typography variant="form_h1">Applied Filters: </Typography>
        {Object.values(selectedFilters).filter(
          (val) => val === "All" || val.length === 0
        ).length === 7 && (
          <Typography variant="form_text1">No filters selected</Typography>
        )}
        {selectedFilters.function.length !== 0 &&
          selectedFilters.function.map((functionValue) => {
            return (
              <Chip
                label={`Function: ${functionValue}`}
                onDelete={() => {
                  setSelectedFilters((prevState) => {
                    const functions = prevState.function.filter(
                      (f) => f !== functionValue
                    );
                    const newState = { ...prevState, function: functions };
                    applyFiltersToRecords(newState, queryResults);
                    return newState;
                  });
                }}
                variant="outlined"
                color="primary"
              />
            );
          })}
        {selectedFilters.location.length !== 0 &&
          selectedFilters.location.map((locationValue) => {
            return (
              <Chip
                label={`Location: ${locationValue}`}
                onDelete={() => {
                  setSelectedFilters((prevState) => {
                    const locations = prevState.location.filter(
                      (f) => f !== locationValue
                    );
                    const newState = { ...prevState, location: locations };
                    applyFiltersToRecords(newState, queryResults);
                    return newState;
                  });
                }}
                variant="outlined"
                color="primary"
              />
            );
          })}
        {selectedFilters.grade.length !== 0 &&
          selectedFilters.grade.map((gradeValue) => {
            return (
              <Chip
                label={`Grade: ${gradeValue}`}
                onDelete={() => {
                  setSelectedFilters((prevState) => {
                    const grades = prevState.grade.filter(
                      (f) => f !== gradeValue
                    );
                    const newState = { ...prevState, grade: grades };
                    applyFiltersToRecords(newState, queryResults);
                    return newState;
                  });
                }}
                variant="outlined"
                color="primary"
              />
            );
          })}
        {selectedFilters.role.length !== 0 &&
          selectedFilters.role.map((roleValue) => {
            return (
              <Chip
                label={`Role: ${roleValue}`}
                onDelete={() => {
                  setSelectedFilters((prevState) => {
                    const roles = prevState.role.filter((f) => f !== roleValue);
                    const newState = { ...prevState, role: roles };
                    applyFiltersToRecords(newState, queryResults);
                    return newState;
                  });
                }}
                variant="outlined"
                color="primary"
              />
            );
          })}
        {selectedFilters.gender.length !== 0 &&
          selectedFilters.gender.map((genderValue) => {
            return (
              <Chip
                label={`Gender: ${genderValue}`}
                onDelete={() => {
                  setSelectedFilters((prevState) => {
                    const genders = prevState.gender.filter(
                      (f) => f !== genderValue
                    );
                    const newState = { ...prevState, gender: genders };
                    applyFiltersToRecords(newState, queryResults);
                    return newState;
                  });
                }}
                variant="outlined"
                color="primary"
              />
            );
          })}
        {selectedFilters.range !== "All" && (
          <Chip
            label={`Period: ${selectedFilters.period}, Score Range: ${selectedFilters.range}`}
            onDelete={() => {
              setSelectedFilters((prevState) => {
                const newFilters = {
                  ...prevState,
                  period: "All",
                  range: "All",
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            variant="outlined"
            color="primary"
          />
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: "1em",
          paddingTop: 0,
        }}
      >
        <Typography variant="form_text1">
          Viewing <b>{records.length}</b> out of {queryResults.length} records
        </Typography>
      </Box>
      <br />
      <br />
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Box height={"35vh"} width={"33%"} sx={{ textAlign: "center" }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              width={500}
              height={300}
              data={chartData.h1}
              margin={{
                top: 5,
                right: 30,
                left: 10,
                bottom: 25,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="range"
                label={{
                  value: "Score Range",
                  position: "insideMiddle",
                  dy: 30,
                }}
              />
              <YAxis
                dataKey={"numberOfEmployees"}
                label={{
                  value: "Number of Employees",
                  angle: -90,
                  position: "insideMiddle",
                  dx: -30,
                }}
              />
              <Tooltip content={<CustomTooltip />} />

              <Bar
                dataKey="numberOfEmployees"
                fill="#00C49F"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                style={{ cursor: "pointer" }}
                onClick={({ payload }, index, event) => {
                  setSelectedFilters((prevState) => {
                    const newFilters = {
                      ...prevState,
                      period: "h1",
                      range: payload.range,
                    };
                    applyFiltersToRecords(newFilters, payload.records, false);
                    return newFilters;
                  });
                }}
              />
              <Line
                type="monotone"
                dataKey="numberOfEmployees"
                stroke={"black"}
                activeDot={{
                  r: 4,
                  onClick: (data, { payload }) => {
                    setSelectedFilters((prevState) => {
                      const newFilters = {
                        ...prevState,
                        period: "h1",
                        range: payload.range,
                      };
                      applyFiltersToRecords(newFilters, payload.records, false);
                      return newFilters;
                    });
                  },
                  style: { cursor: "pointer" },
                }}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <br />
          <Typography variant="p2">
            {dimension === "GOALS" ? "Goals" : "Core Values"} Aggregate Score-H1
          </Typography>
        </Box>
        <Box height={"35vh"} width={"33%"} sx={{ textAlign: "center" }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              width={500}
              height={300}
              data={chartData.h2}
              margin={{
                top: 5,
                right: 30,
                left: 10,
                bottom: 25,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="range"
                label={{
                  value: "Score Range",
                  position: "insideMiddle",
                  dy: 30,
                }}
              />
              <YAxis
                dataKey={"numberOfEmployees"}
                label={{
                  value: "Number of Employees",
                  angle: -90,
                  position: "insideMiddle",
                  dx: -30,
                }}
              />
              <Tooltip content={<CustomTooltip />} />

              <Bar
                dataKey="numberOfEmployees"
                fill="#FFBB28"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                onClick={({ payload }, index, event) => {
                  setSelectedFilters((prevState) => {
                    const newFilters = {
                      ...prevState,
                      period: "h2",
                      range: payload.range,
                    };
                    applyFiltersToRecords(newFilters, payload.records, false);
                    return newFilters;
                  });
                }}
                style={{ cursor: "pointer" }}
              />
              <Line
                type="monotone"
                dataKey="numberOfEmployees"
                stroke={"black"}
                activeDot={{
                  r: 4,
                  onClick: (data, { payload }) => {
                    setSelectedFilters((prevState) => {
                      const newFilters = {
                        ...prevState,
                        period: "h2",
                        range: payload.range,
                      };
                      applyFiltersToRecords(newFilters, payload.records, false);
                      return newFilters;
                    });
                  },
                  style: { cursor: "pointer" },
                }}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <br />
          <Typography variant="p2">
            {dimension === "GOALS" ? "Goals" : "Core Values"} Aggregate Score-H2
          </Typography>
        </Box>
        <Box height={"35vh"} width={"33%"} sx={{ textAlign: "center" }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              width={500}
              height={300}
              data={chartData.annual}
              margin={{
                top: 5,
                right: 30,
                left: 10,
                bottom: 25,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="range"
                label={{
                  value: "Score Range",
                  position: "insideMiddle",
                  dy: 30,
                }}
              />
              <YAxis
                dataKey={"numberOfEmployees"}
                label={{
                  value: "Number of Employees",
                  angle: -90,
                  position: "insideMiddle",
                  dx: -30,
                }}
              />
              <Tooltip content={<CustomTooltip />} />

              <Bar
                dataKey="numberOfEmployees"
                fill="#FF8042"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                onClick={({ payload }, index, event) => {
                  setSelectedFilters((prevState) => {
                    const newFilters = {
                      ...prevState,
                      period: "a",
                      range: payload.range,
                    };
                    applyFiltersToRecords(newFilters, payload.records, false);
                    return newFilters;
                  });
                }}
                style={{ cursor: "pointer" }}
              />
              <Line
                type="monotone"
                dataKey="numberOfEmployees"
                stroke={"black"}
                activeDot={{
                  r: 4,
                  onClick: (data, { payload }) => {
                    setSelectedFilters((prevState) => {
                      const newFilters = {
                        ...prevState,
                        period: "a",
                        range: payload.range,
                      };
                      applyFiltersToRecords(newFilters, payload.records, false);
                      return newFilters;
                    });
                  },
                  style: { cursor: "pointer" },
                }}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <br />
          <Typography variant="p2">
            {dimension === "GOALS" ? "Goals" : "Core Values"} Aggregate
            Score-Annual
          </Typography>
        </Box>
      </Box>
      <br />
      <br />
      <br />
      <br />
      <Paper sx={{ boxSizing: "border-box", height: "100%" }}>
        <DataGrid2
          heading={[
            "Employee ID",
            "Employee Full Name",
            "Plan Name",
            "Plan Period",
            ...(["All", "h1"].includes(selectedFilters.period)
              ? [
                  dimension === "GOALS"
                    ? "Goals Aggregate Score-H1"
                    : "Core Values Aggregate Score-H1",
                ]
              : []),
            ...(["All", "h2"].includes(selectedFilters.period)
              ? [
                  dimension === "GOALS"
                    ? "Goals Aggregate Score-H2"
                    : "Core Values Aggregate Score-H2",
                ]
              : []),
            ...(["All", "a"].includes(selectedFilters.period)
              ? [
                  dimension === "GOALS"
                    ? "Goals Aggregate Score-Annual"
                    : "Core Values Aggregate Score-Annual",
                ]
              : []),
            "Function",
            "Location",
            "Role",
            "Grade",
            "Grade Level",
            "Reviewer ID",
            "Reviewer Full Name",
          ]}
          rowsData={records}
          minColumnWidth={300}
          rowToFieldMap={[
            "Employee ID",
            "Employee Full Name",
            "Plan Name",
            "Plan Period",
            ...(["All", "h1"].includes(selectedFilters.period)
              ? [
                  dimension === "GOALS"
                    ? "Goals Aggregate Score-H1"
                    : "Core Values Aggregate Score-H1",
                ]
              : []),
            ...(["All", "h2"].includes(selectedFilters.period)
              ? [
                  dimension === "GOALS"
                    ? "Goals Aggregate Score-H2"
                    : "Core Values Aggregate Score-H2",
                ]
              : []),
            ...(["All", "a"].includes(selectedFilters.period)
              ? [
                  dimension === "GOALS"
                    ? "Goals Aggregate Score-Annual"
                    : "Core Values Aggregate Score-Annual",
                ]
              : []),
            "Function",
            "Location",
            "Role",
            "Grade",
            "Grade Level",
            "Reviewer ID",
            "Reviewer Full Name",
          ]}
        />
        <br />
        <br />
      </Paper>
    </Box>
  );
}

export default DimensionDashboard;
