import { combineReducers } from "redux";
import WorkspaceReducer from "./WorkspaceReducer";
import LayoutReducer from "./LayoutReducer";
import DataReducer from "./DataReducer";
import AuthReducer from "./AuthReducer";
import MessageReducer from "./MessageReducer";
import TwoFactor from "./TwoFactor";
import Workflow from "./workflow";
import SideNavReducer from "./SideNavReducer";

export default combineReducers({
  LayoutReducer,
  DataReducer,
  AuthReducer,
  MessageReducer,
  TwoFactor,
  Workflow,
  SideNavReducer,
});
