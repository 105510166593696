import React from "react"
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import {useDispatch,useSelector} from "react-redux"
import HandleCondition from "../../Utils/Condition";
import { render } from "react-dom";


function Input_1(props){
  var {Type,Placeholder,suffix,label,variant,dataReduxId,parentField,fieldDependency,dataReduxerState}=props;
  const dispatch=useDispatch();
  if(!label)label="Test"
  const inputValue=useSelector((state)=>{
    
    if(!dataReduxId || !parentField)return "";
   
    if(state.DataReducer[dataReduxId]){
      //console.log("From State 1",state.DataReducer[dataReduxId][parentField]?.value," ",state.DataReducer[dataReduxId][parentField])
    return state.DataReducer[dataReduxId][parentField]?.value;
    }
  })
  // const fieldDependency=useSelector((state)=>state.DataReducer[dataReduxId]?.fieldDependency);
  // const Rstate=useSelector((state)=>state.DataReducer)
  const renderNot=useSelector((state)=>{
    if(state.DataReducer[dataReduxId]){
      return state.DataReducer[dataReduxId][parentField].renderNot
    }
  })
function textChange(e){
  let val=e.target.value;
  console.log("Text Change ",val)
dispatch({
  type:'SET_FIELD_DATA',
  payload:{
    id:dataReduxId,
    field:parentField,
    data:val,
    prop:"value",
    dispatch
  }
});
dispatch(HandleCondition(fieldDependency,dataReduxerState,dataReduxId))
//HandleCondition(conditions,dataReduxId,parentField)
}
if(renderNot)return <> </>
    return(
        <>
      
        <div>
          {console.log(inputValue)}
        <TextField value={inputValue ?inputValue:""} onChange={textChange} type={Type} placeholder={Placeholder} label={label} variant={variant}/>
        <span style={{...suffix?.style,marginLeft:20}}>{suffix?.value}</span>
        </div>
        </>
    )
}


export default Input_1;