var agGrid=window.grid;
var customfilterparams = {
  filterOptions : [
    {
    displayKey: 'namefilter',
    displayName: 'Keerthi',
    test: (filterValue, cellValue) => {
      return cellValue == 'keerthi'
    }
    }
  ]
}

var datefilterparams = {
  filterOptions : [
    'blank',
    {
      displayKey : 'lastyear',
      displayName : 'Last Year',
      test : (filterValue,cellValue) => {
        if (cellValue == null) return true;

        var parts = cellValue.split('/');
        var cellDate = new Date(
          Number(parts[2]),
          Number(parts[1] - 1),
          Number(parts[0])
        );
        var today = new Date();
        var diff = today.getFullYear() - cellDate.getFullYear();
        return diff == 1
      },
      hideFilterInput: true
    },
    {
      displayKey : 'lastmonth',
      displayName : 'Last Month',
      test : (filterValue,cellValue) => {
        if (cellValue == null) return true;

        var parts = cellValue.split('/');
        var cellDate = new Date(
          Number(parts[2]),
          Number(parts[1] - 1),
          Number(parts[0])
        );
        var today = new Date();
        var diff = today.getMonth() - cellDate.getMonth();
        var ydiff = today.getFullYear() - cellDate.getFullYear();
        return diff == 1 && ydiff == 0
      },
      hideFilterInput: true
    }
  ],
  suppressAndOrCondition : true
}

var columns = [
  {
    field : 'name',
    Name : 'Name',
    filter : 'agTextColumnFilter',
    filterParams: customfilterparams
  },
  {
    field : 'date',
    Name : 'Date',
    filter : 'agDateColumnFilter',
    filterParams : datefilterparams
  }
]

var rows = [
  {
    id : '1',
    name : 'Keerthi',
    date : '27/11/2020'
  },
  {
    id : '2',
    name: 'Chamarthi',
    date : '15/11/2021'
  },
  {
    id : '3',
    name : 'Three',
    date : '14/05/2021'
  },
  {
    id : '4',
    name : 'Four',
    date : '25/05/2020'
  }
]

var gridOptions = {
  columnDefs : columns,
  rowData : rows,
  defaultColDef : {
    flex: 1,
    minWidth: 150,
    sortable : true,
    resizable : true,
  },
  sideBar : true
}

document.addEventListener('DOMContentLoaded',function(){
  var gridDiv = document.querySelector('#myGrid');
  new agGrid.Grid(gridDiv, gridOptions);
})