import React, { useState } from "react";
import Atomic from "../../../AComponent/Atomic";
import Molecule from "../../../MComponent/Molecule";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../Utils/CustomHooks/WindowRezise";
const { Input, Button, Date, Dropdown, EwSwitch, Radio } = Atomic;
const { Table, EwModal } = Molecule;
const id = "MPP";

function ConfidentialSection() {
  const [temp, setTemp] = useState("");
  const obj = useSelector((state) => {
    if (!state.DataReducer[id]) return {};
    return state.DataReducer[id];
  });
  const {
    ConfidentialSection,
    ConfidentialLabel,
    ConfidentialDescription,
    ConfidentialPerformanceSummary,
    ConfidentialPotentialSummary,
    GrowthRoleAssessment,
    GrowthRoleAssessmentOption,
    PromotionAssessment,
    PromotionAssessmentOptions,
    ExitAssessment,
    ExitAssessmentOptions,
    GrowthRoleAssessmentTemp,
    PromotionAssessmentTemp,
    ExitAssessmentTemp,
  } = obj;

  const radioOptions = [
    {
      label: "Disable",
      value: "D",
    },
    {
      label: "Mandatory",
      value: "M",
    },
    {
      label: "Optional",
      value: "O",
    },
  ];
  const dispatch = useDispatch();
  function updateState(val, type) {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: type,
        data: val,
      },
    });
  }
  function Delete(props) {
    const { options, id } = props;
    function deleteOp() {
      let arr = obj[options + "Options"];
      arr[id].ID="";

      updateState(arr, options + "Options");
    }
    return (
      <span onClick={deleteOp} className="pointer">
        Delete
      </span>
    );
  }
  return (
    <div>
      <div className="Flex">
        <div>
          <EwSwitch
            label="Confidential Section"
            value={ConfidentialSection === "E"}
            onChange={(val) =>
              updateState(val ? "E" : "D", "ConfidentialSection")
            }
          />
        </div>
      </div>
      {ConfidentialSection === "E" && (
        <div>
          <div className="Flex">
            <div>
              <Input
                label={"Label"}
                value={ConfidentialLabel}
                onChange={(val) => updateState(val, "ConfidentialLabel")}
              />
            </div>
            <div>
              <Input
                label={"Description"}
                value={ConfidentialDescription}
                onChange={(val) => updateState(val, "ConfidentialDescription")}
                rows={4}
              />
            </div>
          </div>
          <div className="Flex-row g20">
            <div>
              <Radio
                label="Performance Summary"
                menuItems={radioOptions}
                value={ConfidentialPerformanceSummary}
                onChange={(val) =>
                  updateState(val, "ConfidentialPerformanceSummary")
                }
              />
            </div>
            <div>
              <Radio
                label="Potential Summary"
                menuItems={radioOptions}
                value={ConfidentialPotentialSummary}
                onChange={(val) =>
                  updateState(val, "ConfidentialPotentialSummary")
                }
              />
            </div>
          </div>
          <br />
          <div>
            {[
              {
                label: "Growth Role Assessment",
                value: "GrowthRoleAssessment",
              },
              { label: "Promotion Assessment", value: "PromotionAssessment" },
              { label: "Exit Assessment", value: "ExitAssessment" },
            ].map(({ label, value }) => (
              <>
                <h3>{label}</h3>
                <hr />
                <br />
                {["M", "O"].includes(obj[value]) && (
                  <div style={{ width: "100%" }}>
                    {obj[value + "Options"] && (
                      <div
                        className="scroll"
                        style={{ maxHeight: "30vh", width: "100%" }}
                      >
                        <Table
                          heading={[label + " Options", "Delete"]}
                          rows={obj[value + "Options"].map((ele, index) => [
                            ele.ID,
                            <Delete options={value} id={index} />,
                          ])}
                        />
                      </div>
                    )}
                    <br />
                  </div>
                )}
                <div className="Flex-row g20">
                  <Radio
                    label={label}
                    menuItems={radioOptions}
                    value={obj[value]}
                    onChange={(val) => updateState(val, value)}
                  />
                  {["M", "O"].includes(obj[value]) && (
                    <div className="Flex-row">
                      <div>
                        <Input
                          label={label + " Options"}
                          value={obj[value + "Temp"]}
                          onChange={(val) => updateState(val, value + "Temp")}
                          rows={4}
                        />
                      </div>
                      <div>
                        <Button
                          title={"Add"}
                          onClick={() => {
                            let arr = obj[value + "Options"];
                            if (!arr) arr = [];
                            arr.push({ID:obj[value + "Temp"]});
                            updateState([...arr], value + "Options");
                            updateState("", value + "Temp");
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <hr />
                <br />
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ConfidentialSection;
