import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../Redux/Actions/Actiontypes";
import store from "../../Redux/Store";
import * as Library from "../Common/Library";

function useProps(props) {
  const { id, fieldName } = props;
  const reduxData = useSelector((state) => {
    if (id && fieldName && state.DataReducer[id]) {
      return state.DataReducer[id][fieldName];
    }
    return null;
  });
  useEffect(() => {
    if (id && !reduxData && props) {
      props = getOtherProps();
      console.log("setting props: ", props);

      dispatch({
        type: Actions.UPDATE_PROPS,
        payload: {
          updateAllProps: true,
          data: props,
          id,
          fieldName,
        },
      });
    }
  }, []);
  function setLoading(flag) {
    dispatch({
      type: Actions.UPDATE_PROPS,
      payload: {
        id,
        fieldName: "otherData",
        data: flag,
        props: "layoutLoading",
      },
    });
  }
  function setMessage(msg) {
    if (!msg) return;
    dispatch({
      type: Actions.UPDATE_PROPS,
      payload: {
        id,
        fieldName: "otherData",
        data: msg,
        props: "layoutMessage",
      },
    });
  }
  function handleOnClick({ method, props }) {
    props.fromLayoutJson = {
      id,
    };
    return () => {
      const res = Library[method](props);
      if (res instanceof Promise) {
        setLoading(true);
        res
          .then((res) => {
            if (res) {
              setMessage({
                type: 0,
                message: "Saved",
              });
            }
          })
          .catch((e) => {
            console.log("Error: ", e);
            setMessage({
              type: 1,
              message: "Network error",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };
  }

  function handleDocSelection() {}
  const dispatch = useDispatch();
  function getOtherProps() {
    let returnObj = reduxData;
    if (!returnObj) returnObj = {};
    if (!props) props = {};
    returnObj = {
      ...props,
      ...returnObj,
      onChange: (val) => {
        dispatch({
          type: Actions.UPDATE_PROPS,
          payload: {
            id,
            fieldName,
            data: val,
            props: "value",
          },
        });
      },
    };
    if (reduxData?.onClick) {
      returnObj.onClick = handleOnClick(reduxData.onClick);
    }
    returnObj.fromLayoutJson = {
      id,
    };
    console.log("Before senfin: ", returnObj);
    return returnObj;
  }
  if (id) {
    return getOtherProps();
  }

  return props;
}

export default useProps;
