import { Paper, Typography } from "@mui/material";
import Atomic from "../../../AComponent/Atomic";
import React, { useState, useEffect } from "react";
import EwModal from "../../../MComponent/Modal/Modal";
import Grid from "@mui/material/Grid";
import EwTable from "../../../MComponent/Table/Table";
import { getData } from "../../../Utils/Common/Library";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useWindowDimensions from "../../../Utils/CustomHooks/WindowRezise";
import EwSwitch from "../../../AComponent/Switch/EwSwitch";
import AlertMessage from "../../../Utils/Messages/AlertMessage";
import ListView from "../Dimension/ListView";
import {
  extractAddRules,
  extractDimensionStructure,
  extractDiscardRules,
  extractWeightRules,
  isDefinedInMap,
  isEnabled,
  isNoWeights,
  isUserDefined,
} from "../Dimension/Helpers/helper";
import { validateWeights } from "./WeightValidator";
import { Box } from "@mui/material";
const { Button, Checkboxes, EwSlider, Dropdown, Radio, Input } = Atomic;

const DimensionFeatureId = "19145";
const targetRule = {
  enableTargets: true,
  input: "M",
};
function Mapping(props) {
  const { parentState, updateParentState } = props;
  const {
    map,
    mapStructure,
    DimensionID,
    weightState,
    dimList,
    discarRules,
    addingRules,
  } = parentState;
  //if(!DimensionID || DimensionID===-1 || !dimList)return <>Select Dimension</>
  console.log("Weightstate: ", weightState);
  let selectedDim = dimList?.filter(
    (item) => item.TransactionID === DimensionID
  )[0];
  const { width } = useWindowDimensions();
  const [localState, setLocalState] = useState(
    map
      ? map
      : {
          parameterMap: {},
          selectedDimension: selectedDim,
        }
  );
  //const [parameterMap, setParamterMap] = useState({});
  const [loading, setLoading] = useState(true);
  const [mapError, setMapError] = useState(null);
  const [modal, setModal] = useState(null);

  const [secondGroups, setSecondGroups] = useState([]);
  const [firstGroups, setFirstGroups] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [currentTransactionID, setCurrentTransactionID] = useState("");
  const [message, setMessage] = useState("");

  const {
    level2,
    level1,
    level0,
    selectedGroup2,
    selectedGroup1,
    allSelectedGroups,
    idToWeightMap,
    parameterMap,
    selectedDimension,
    targetRule,
    achievementRule,
  } = localState;
  function updateState(val, type) {
    setLocalState((prev) => ({ ...prev, [type]: val }));
  }
  useEffect(() => {
    return () => {
      console.log("Unmountet ", localState);
      updateParentState(localState, "map");
    };
  }, [localState]);
  function Card(props) {
    let { id, weights, otherData, innerList, type } = props;
    let [wt2, wt1, wt0] = weightState.wtselected;
    let currentWt = type === 2 ? wt2 : type === 1 ? wt1 : wt0;
    if (idToWeightMap && idToWeightMap[id]) weights = idToWeightMap[id];
    let child = type === 2 ? 1 : 0;
    if (!otherData) return <></>;
    const { GroupName, ParameterName } = otherData;
    const [min, max] = weights ? weights : [0, 0];
    let weightStr = `[${min}-${max}]`;
    if (min === max) weightStr = `[${min}]`;
    if (type === 0 || currentWt !== "D") weightStr = "";
    let selectedCard =
      type === 2 ? selectedGroup2 : type === 1 && selectedGroup1;
    return (
      <div
        className="pointer"
        onClick={() => {
          console.log("Card Clicked");
          if (type === 0) {
            setModal({ parameter: props });
            return;
          }
          if (type === 2) updateState(null, "level0");
          updateState(id, "selectedGroup" + (child + 1));
          updateState(innerList, "level" + child);
        }}
      >
        <Paper
          style={{
            padding: 8,
            width: 0.15 * width,
            height: 50,
            border: id === selectedCard ? "2px solid red" : "",
          }}
        >
          <div
            className="Flex-row"
            style={{ justifyContent: "space-between", alignItems: "flex-end" }}
          >
            <div>
              <Typography>
                {(GroupName || ParameterName) + " " + weightStr}
              </Typography>
            </div>
            <div className="Flex-row">
              {currentWt === "D" && (
                <>
                  {" "}
                  <div
                    className="pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setModal({ edit: props });
                    }}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <EditIcon color="info" />
                  </div>
                  <span> | </span>
                </>
              )}
              <div
                className="pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setModal({ edit: props });
                }}
                style={{ justifyContent: "flex-end" }}
              >
                <DeleteIcon color="error" />
              </div>
            </div>
          </div>
        </Paper>
        <br />
      </div>
    );
  }

  function SelectGroup({ selected, setSelected, id }) {
    console.log("Inside check: ", selected, " ", id);
    function onCheck(check) {
      let temp = selected;
      if (check === true) {
        temp.add(id);
      } else {
        temp.delete(id);
      }
      console.log(temp);
      setSelected(new Set([...temp]));
    }
    return (
      <div style={{ padding: 5 }}>
        <Checkboxes value={selected.has(id)} onChange={onCheck} />
      </div>
    );
  }

  function Slider({ id, weights, setWeights }) {
    function Change(val) {
      let [min, max] = val;
      let obj = weights;
      obj[id] = val;
      console.log("from SLider: ", obj);
      setWeights({ ...obj });
    }
    return (
      <EwSlider value={weights[id] ? weights[id] : [5, 10]} onChange={Change} />
    );
  }
  function EditModal() {
    const { edit } = modal;
    let { weights, id } = edit;
    console.log("Edit: ", idToWeightMap);
    if (idToWeightMap && idToWeightMap[id]) weights = idToWeightMap[id];
    const [wt, setWt] = useState(weights ? weights : [0, 10]);
    function onSave() {
      let obj = idToWeightMap;
      if (!obj) obj = {};
      obj[id] = wt;
      updateState({ ...obj }, "idToWeightMap");
      setModal(null);
    }
    return (
      <div style={{ width: 0.3 * width }}>
        <div className="Flex">
          <EwSlider value={wt} onChange={(val) => setWt(val)} />
        </div>
        <br />
        <div className="Flex">
          <Button title={"Save"} onClick={onSave} />
        </div>
        {/* <div className="Flex">
            <h1>Are you sure you want to remove this group from list?</h1>
        </div>
        <div className="Flex-row">
        <div>
            <Button title={"Cancel"} onClick={()=} />
        </div>
        </div> */}
      </div>
    );
  }
  function getTargetTypes(input) {
    if (!input) return [];
    if (input === "M")
      return [
        {
          label: "Expected Scale Value",
          value: "EV",
        },
        {
          label: "Anchored to Rating Scale Value",
          value: "AR",
        },
        {
          label: "Anchored Rating Scale Computed Rating",
          value: "CR",
        },
        {
          label: "Rating Guide",
          value: "RG",
        },
      ];

    return [
      {
        label: "Fixed Value",
        value: "FV",
      },
      {
        label: "Rating Guide",
        value: "RG",
      },
    ];
  }
  function ParameterModal() {
    const { id } = modal.parameter;
    const { wtselected } = weightState;
    //const { enableTargets, input } = targetRule;
    const [modalLoading, setModalLoading] = useState(false);
    const [modalError, setModalError] = useState(null);
    const [wt, setWt] = useState(0);
    const [target, setTarget] = useState(
      parameterMap && parameterMap[id] ? parameterMap[id] : { targetsArray: [] }
    );
    const {
      enableTarget,
      input,
      targetType,
      expectedRating,
      dataType,
      minRange,
      maxRange,
      rangeDatatype,
      achievement,
      targetsArray,
    } = target;

    const rating = ["Poor", "Below", "Meets", "Exceeds", "Outstanding"];
    const otherTargetTypeList = [
      {
        label: "Alpha Numeric (upto 200 Characters)",
        value: "ST",
      },
      {
        label: "Alpha Numeric (large text)",
        value: "LT",
      },
    ];
    const numericDataTypesList = [
      {
        label: "Integer",
        value: "NI",
      },
      {
        label: "Decimal (upto 2 places)",
        value: "ND",
      },
      {
        label: "Percentage",
        value: "PI",
      },
      {
        label: "Percentage (upto 2 decimal)",
        value: "PD",
      },
    ];
    const inputList = [
      {
        label: "User Defined",
        value: "U",
      },
      {
        label: "Mapped",
        value: "M",
      },
    ];
    function updateTarget(val, type) {
      setTarget((prev) => ({ ...prev, [type]: val }));
    }
    function getTargetInputValue(index, type) {
      if (type === 0) return targetsArray[index];
      if (index === 0 && !targetsArray[0]) {
        let arr = [{ min: "*" }];
        arr[rating.length - 1] = {
          max: "*",
        };
        updateTarget(arr, "targetsArray");

        return;
      }
      if (!targetsArray[index]) return undefined;
      let { min, max } = targetsArray[index];
      if (type === 1) return min;
      return max;
    }
    function targetValueChange(val, index, type) {
      let arr = targetsArray;
      if (type === 0) {
        arr[index] = val;
        updateTarget(arr, "targetsArray");
        return;
      }
      if (!arr[index]) return;
      let num = 1;
      val = parseInt(val);
      if (targetType === "PI" || targetType === "PD") {
        num = 0.01;
        val = parseFloat(val);
      }
      arr[index].max = val;
      if (index < rating.length) {
        // if (arr.length > index + 1) {
        //   arr[index+1].max=arr[index+1].max;
        // }
        arr[index + 1] = {
          ...arr[index + 1],
          min: val + num,
        };
      }
      updateTarget(arr, "targetsArray");
    }
    function maxOnBlur(index) {
      if (!targetsArray[index]) return;
      let { max, min } = targetsArray[index];
      let arr = targetsArray;
      if (max <= min) {
        arr[index].error = "Value must be greated than " + min;
        updateTarget(arr, "targetsArray");
      } else {
        arr[index].error = null;
        updateTarget(arr, "targetsArray");
      }
    }
    function renderTargetTypes() {
      if (!targetType || !input) return <></>;
      if (
        (input === "U" && !["FV", "RG"].includes(targetType)) ||
        (input === "M" && ["FV", "RG"].includes(targetType))
      )
        return <></>;

      if (targetType === "EV")
        return (
          <>
            <div className="Flex">
              <Radio
                label={"Expected Rating"}
                menuItems={rating}
                value={expectedRating}
                onChange={(val) => updateTarget(val, "expectedRating")}
              />
            </div>
          </>
        );

      if (["AR", "CR", "RG"].includes(targetType)) {
        let menuForDatatype = [...otherTargetTypeList, ...numericDataTypesList];

        //   if (targetType === "CR") menuForDatatype = numericDataTypesList;
        return (
          <>
            <div className="Flex">
              <div className="Flex-row" style={{ gap: 20, width: "100%" }}>
                <div style={{ width: "40%" }}>
                  <Dropdown
                    label="Target Data Type"
                    menuItems={menuForDatatype}
                    value={dataType}
                    onChange={(val) => {
                      updateTarget([], "targetsArray");
                      updateTarget(val, "dataType");
                    }}
                  />
                </div>
                {["NI", "ND", "PI", "PD"].includes(dataType) && (
                  <div className="Flex-row">
                    {/* <div>
                      <Dropdown
                        label="Range Data Type"
                        menuItems={numericDataTypesList}
                        value={rangeDatatype}
                        onChange={(val) => updateTarget(val, "rangeDatatype")}
                      />
                    </div> */}

                    <div>
                      <div>
                        <Input
                          label={"Min Range"}
                          value={minRange}
                          type="number"
                          onChange={(val) => {
                            let arr = targetsArray;
                            arr[0].min = !val ? "*" : parseInt(val);
                            updateTarget(arr, "targetsArray");
                            updateTarget(val, "minRange");
                          }}
                        />
                      </div>
                      <div>
                        <Input
                          label={"Max Range"}
                          value={maxRange}
                          type="number"
                          onChange={(val) => {
                            let arr = targetsArray;
                            arr[rating.length - 1].max = !val
                              ? "*"
                              : parseInt(val);
                            updateTarget(arr, "targetsArray");
                            updateTarget(val, "maxRange");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <Grid className="Flex" container spacing={2}>
                {dataType &&
                  rating.map((ele, index) => {
                    let min, max, targetVal, error;
                    if (["NI", "ND", "PI", "PD"].includes(dataType)) {
                      min = getTargetInputValue(index, 1);
                      max = getTargetInputValue(index, 2);
                      error =
                        targetsArray &&
                        targetsArray[index] &&
                        targetsArray[index]?.error
                          ? targetsArray[index].error
                          : null;
                    }
                    return (
                      <>
                        <Grid item xs={5}>
                          <div
                            className="Flex"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <span>{ele + " :"}</span>
                          </div>
                        </Grid>
                        <Grid item xs={7}>
                          <div
                            className="Flex"
                            style={{ alignItems: "flex-start" }}
                          >
                            {["NI", "ND", "PI", "PD"].includes(dataType) ? (
                              <div className="Flex-row">
                                {min === "*" ? (
                                  <div>
                                    <Input disable={true} label="Upto :" />
                                  </div>
                                ) : (
                                  <div>
                                    <Input
                                      value={min}
                                      onChange={(val) =>
                                        targetValueChange(val, index, 1)
                                      }
                                      disable={true}
                                      type={index !== 0 && "number"}
                                      label=""
                                    />
                                  </div>
                                )}
                                {max === "*" ? (
                                  <div>
                                    <Input disable={true} label="and above " />
                                  </div>
                                ) : (
                                  <div>
                                    <Input
                                      value={max}
                                      onChange={(val) =>
                                        targetValueChange(val, index, 2)
                                      }
                                      type={"number"}
                                      label={
                                        index === rating.length - 1 ? "" : "Max"
                                      }
                                      disable={index === rating.length - 1}
                                      error={error}
                                      onBlur={() => maxOnBlur(index)}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div>
                                <Input
                                  value={getTargetInputValue(index, 0)}
                                  onChange={(val) =>
                                    targetValueChange(val, index, 0)
                                  }
                                  label="Target"
                                />
                              </div>
                            )}
                          </div>
                        </Grid>
                      </>
                    );
                  })}
              </Grid>
            </div>
          </>
        );
      }
    }
    function renderAchive() {
      return <></>;
    }
    function validate() {
      if (!input) return "Salect an input";
      if (!targetType) return "Select a target type";
      if (["AR", "CR", "RG"].includes(targetType)) {
        if (!dataType) return "Select a data type";
        if (["NI", "ND", "PI", "PD"].includes(dataType)) {
          if (!targetsArray[0].max) return "Missing target for " + rating[0];
          if (minRange && targetsArray[0].max <= minRange)
            return (
              "Max value for " +
              rating[0] +
              " cannot be less than Minimum Range (" +
              minRange +
              ")"
            );
          if (maxRange && targetsArray[rating.length - 1].max > maxRange)
            return `Max target for cannot be greater than max range`;
          for (let i = 1; i < rating.length - 1; i++) {
            if (targetsArray[i] === undefined)
              return "Missing Target for scale " + (i + 1);
            let { min, max } = targetsArray[i];
            if (max <= min)
              return "Max vlaue cannot be less than min for " + (i + 1);
          }
        } else {
          for (let i = 0; i < rating.length; i++) {
            console.log("vlaidation: ", targetsArray[i]);
            if (targetsArray[i] === undefined)
              return "Missing value for " + (i + 1);
            if (targetsArray[i].trim(" ") === "")
              return "Missing value for " + (i + 1);
          }
        }
      }
      return null;
    }
    function onSave() {
      // setModalLoading(true);
      // if (!enableTarget) {
      //   setModal(null);
      //   return;
      // }
      // let err = validate();
      // if (err) {
      //   setModalError(err);
      //   setModalLoading(false);
      //   return;
      // }
      setParameters((prevState) => {
        const newState = [...prevState];
        const index = newState.findIndex((value) => value.TransactionID === id);
        newState[index].Weight = wt;
        console.log("This is the newState", newState);
        return newState;
      });
      console.log(id, wt, "please click this");
      // let obj = parameterMap;
      // if (!obj) obj = {};
      // obj[id] = target;
      // updateState({ ...obj }, "parameterMap");
      setModal(null);
      // setModalLoading(false);
    }
    if (modalLoading) return <>loading...</>;
    //if (modalError) return <><AlertMessage type={1} message={modalError} /> <span className="pointer" onClick={()=>setModalError(null)}>X</span></>;
    return (
      <div className="scroll" style={{ width: 0.7 * width, height: 500 }}>
        {wtselected[2] === "D" && (
          <div className="Flex">
            <EwSlider
              label={"Weights"}
              value={wt}
              onChange={(val) => setWt(val)}
            />
            <span>{"Weight: " + wt + "%"}</span>
          </div>
        )}
        <br />
        <h3>Targets</h3>
        <div className="Flex">
          <EwSwitch
            value={enableTarget}
            onChange={(val) => updateTarget(val, "enableTarget")}
          />
        </div>
        <div>
          {enableTarget ? (
            <>
              <div className="Flex">
                <Radio
                  label="Input Type"
                  menuItems={inputList}
                  value={input}
                  onChange={(val) => updateTarget(val, "input")}
                />
              </div>
              {input && (
                <div>
                  <div>
                    <Dropdown
                      label={"Target Types"}
                      menuItems={getTargetTypes(input)}
                      value={targetType}
                      onChange={(val) => {
                        updateTarget(val, "targetType");
                      }}
                    />
                  </div>
                  <div>{renderTargetTypes()}</div>
                  <br />
                  {targetType && (
                    <>
                      <div className="Flex">
                        <EwSwitch
                          label={"Achievement"}
                          value={achievement}
                          onChange={(val) => updateTarget(val, "achievement")}
                        />
                      </div>
                      <br />
                      {achievement && <div>{renderAchive()}</div>}
                    </>
                  )}
                </div>
              )}
              {modalError && (
                <>
                  <AlertMessage type={1} message={modalError} />{" "}
                  <span className="pointer" onClick={() => setModalError(null)}>
                    X
                  </span>
                </>
              )}
              <br />
              <div className="Flex">
                <Button title={"Save"} onClick={onSave} />
              </div>
            </>
          ) : (
            <>
              <div>Targets Disabled</div>
              <div className="Flex">
                <Button title={"Save"} onClick={onSave} />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
  function ModalWrapper() {
    if (modal.edit) return <EditModal />;
    if (modal.parameter) return <ParameterModal />;

    return <ModalContent />;
  }
  function ModalContent() {
    console.log("Hey there, Why am I not rendering");
    let type = modal;
    let prevSelected = [];
    let prevWeights = {};
    let list = type === 2 ? level2 : type === 1 ? level1 : level0;
    if (list && list.length > 0) {
      list?.forEach((ele) => {
        prevSelected.push(ele.id);
        prevWeights[ele.id] = ele.weights;
      });
      console.log("From sevrver: ", prevSelected, " ", prevWeights);
    }
    const [modalLoading, setModalLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [selected, setSelected] = useState(new Set([...prevSelected]));
    const [weights, setWeights] = useState(prevWeights);
    const [idData, setIdData] = useState({});

    useEffect(() => {
      let obj = {};
      let [wt2, wt1, wt0] = weightState.wtselected;
      let currentWt = type === 2 ? wt2 : type === 1 ? wt1 : wt0;
      let allSelected = allSelectedGroups;
      if (!allSelected) allSelected = new Set();
      if (type === 0) {
        getData({ featureId: "19146" }).then((res) => {
          console.log("Please find this", res);
          let data = res.Data?.filter(
            (item) =>
              (item.Status =
                "1" &&
                !allSelected.has(item.TransactionID) &&
                item.DimensionID === DimensionID)
          ).map((ele) => {
            obj[ele.TransactionID] = ele;
            return [
              {
                Component: SelectGroup,
                props: {
                  id: ele.TransactionID,
                  selected,
                  setSelected,
                },
              },
              ele.ParameterName,
            ];
          });
          setRows([...data]);
          setIdData({ ...obj });
          setLoading(false);
        });
      } else {
        getData({ featureId: DimensionFeatureId }).then((res) => {
          console.log("Please find this", res);
          let data = res.Data?.filter(
            (item) =>
              (item.Status =
                "1" &&
                item.GroupLevel == type &&
                !allSelected.has(item.TransactionID) &&
                item.DimensionID === DimensionID)
          ).map((ele) => {
            obj[ele.TransactionID] = ele;
            let row = [
              {
                Component: SelectGroup,
                props: {
                  id: ele.TransactionID,
                  selected,
                  setSelected,
                },
              },
              ele.GroupName,
            ];
            if (currentWt === "D") {
              row.push({
                Component: Slider,
                props: {
                  id: ele.TransactionID,
                  weights,
                  setWeights,
                },
              });
            } else {
              row.push(
                currentWt === "N"
                  ? "No Weights"
                  : currentWt === "A"
                  ? "Aggregated"
                  : "User Defined"
              );
            }

            return row;
          });

          setRows([...data]);
          setIdData({ ...obj });
          setLoading(false);
        });
      }
    }, []);
    function setAtIndex(arr, id, list) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          arr[i].innerList = list;
          return arr;
        }
      }
    }

    const saveGroupsAndParameters = (ids, type, weights) => {
      if (type === 2) {
        const groups = ids.map((id) => ({
          ...idData[id],
          MinimumWeight: weights[id] ? weights[id][0] : "",
          MaximumWeight: weights[id] ? weights[id][1] : "",
        }));
        console.log(groups, weights, "Print this");
        setSecondGroups(groups);
      } else if (type === 1) {
        const groups = ids.map((id) => ({
          ...idData[id],
          SecondGroupID: currentTransactionID,
          MinimumWeight: weights[id] ? weights[id][0] : "",
          MaximumWeight: weights[id] ? weights[id][1] : "",
        }));
        console.log(groups, weights, "Print this");
        setFirstGroups(groups);
      } else if (type === 0) {
        const parameters = ids.map((id) => ({
          ...idData[id],
          FirstGroupID: currentTransactionID,
          Weight: "",
        }));
        setParameters(parameters);
      }
    };

    function onSave() {
      // let [map2, map1, p] = mapStructure.split("");
      saveGroupsAndParameters([...selected], type, weights);
      // let list = [...selected].map((ele) => {
      //   if (type === 0)
      //     return {
      //       id: ele,
      //       otherData: idData[ele],
      //       type: type,
      //     };
      //   return {
      //     id: ele,
      //     weights: weights[ele] ? weights[ele] : [5, 10],
      //     otherData: idData[ele],
      //     innerList: [],
      //     type: type,
      //   };
      // });
      // let prevVal = type === 2 ? level2 : type === 1 ? level1 : level0;
      // console.log("List: ", prevVal);
      // if (!prevVal) prevVal = [];
      // list = [...list, ...prevVal];
      // if (type === 1 && map2 !== "D") {
      //   let arr = level2;
      //   arr = setAtIndex(arr, selectedGroup2, list);
      //   updateState([...arr], "level2");
      // }
      // if (type === 0) {
      //   let l1;
      //   let l2;
      //   if (map1 !== "D") {
      //     l1 = level1;
      //     l1 = setAtIndex(l1, selectedGroup1, list);
      //     if (map2 !== "D") {
      //       l2 = level2;
      //       l2 = setAtIndex(l2, selectedGroup2, l1);
      //       updateState([...l2], "level2");
      //     } else {
      //       updateState([...l1], "level1");
      //     }
      //   }
      // }
      // let set = allSelectedGroups;
      // if (!set) set = selected;
      // else set = new Set([...set, ...selected]);

      // updateState(list, "level" + type);
      // updateState(new Set([...set]), "allSelectedGroups");
      setModal(null);
    }
    if (!rows || rows.length === 0) return <>No Groups</>;
    if (loading) return <>Loading...</>;
    let heading = ["Select", "Group Name", "Weights"];
    if (type === 0) heading = ["Select", "Paramter Name"];
    return (
      <div style={{ width: 0.7 * width, height: 500 }}>
        <div>
          <EwTable rows={rows} heading={heading} />
        </div>
        <br />
        <div className="Flex">
          <Button title={"Save"} onClick={onSave} />
        </div>
      </div>
    );
  }
  function List({ list, type }) {
    //   console.log("From List: ",list,type)
    let [l2, l1, p] = mapStructure.split("");
    if (type === 2 && l2 === "D") return <></>;
    if (type === 1 && l1 === "D") return <></>;

    if (type === 1 && l2 !== "D" && !selectedGroup2) return <></>;
    if (type === 0 && l1 !== "D" && !selectedGroup1) return <></>;

    let heading = type === 1 ? "Level 1" : type === 2 ? "Level 2" : "Paramter";

    return (
      <div style={{ borderRight: "2px solid black", padding: 10 }}>
        <div className="Flex">
          {" "}
          <h3>{heading}</h3>
        </div>
        <br />
        <div
          className="scroll"
          style={{ height: 300, maxHeight: 500, minWidth: 0.15 * width }}
        >
          {list && (
            <div>
              {list?.map((ele) => (
                <Card {...ele} />
              ))}
            </div>
          )}
        </div>
        <br />
        <div className="Flex">
          <Button title={"Add"} onClick={() => setModal(type)} />
        </div>
      </div>
    );
  }
  function extractGroup2() {
    if (mapStructure.charAt(0) !== "E") return null;
    let g2wt = weightState.wtselected[0];

    let g2arr = [];
    let arr = level2;
    var totalMin = 0;
    for (let i = 0; i < arr.length; i++) {
      let min = null,
        max = null;
      let wtMap = idToWeightMap[arr[i].id];
      let weights = wtMap ? wtMap : arr[i].weights;
      if (g2wt === "D") {
        min = weights[0];
        max = weights[1];
        totalMin += min;
      }
      g2arr.push({
        groupId: arr[i].id,
        MinimumWeight: min,
        MaximumWeight: max,
      });
    }
    if (g2wt === "D" && totalMin > 100)
      return {
        error: "Total Group 2 weight cannot be more than 100%",
      };

    return {
      success: g2arr,
    };

    // for(let i=0;i<arr.length;i++){
    //   let obj={}
    //   if(!arr[i].innerList || arr[i]?.innerList.length===0 )return {
    //     error:"No group added to group "
    //   }

    //   let arr2=arr[i].innerList;
    //   for(let j=0;j<arr2.length;j++){
    //     if(!arr2[j].innerList || arr2[j]?.innerList.length===0 )return {
    //       error:"No group added to group "
    //     }
    //     let arr3=arr2[j].innerList;
    //     for(let k=0;k<arr3.length;k++){
    //       obj.ParameterGroupID=arr3[k].id;
    //     }
    //   }
    // }
  }

  function extractGroup1() {
    if (mapStructure.charAt(1) !== "E") return null;
    let g1wt = weightState.wtselected[1];
    for (let i = 0; i < level1; i++) {}
  }
  function extractParams() {}
  function getAllGroupArr() {}
  function saveMap() {
    let payload = {
      DimensionID,
      MapApplicabilityID: "test",
      MapStructure: mapStructure,
      AddParametersRule: addingRules,
      WeightRule: discarRules,
      ParameterRatingScaleID: "Test",
    };
  }
  //if (loading) return <>Loading...</>;
  if (!mapStructure || mapStructure.length !== 3)
    return <>Select A Map Structure</>;
  if (!DimensionID || DimensionID === -1) return <>Select A Dimension</>;
  if (!weightState || weightState?.wtselected?.length !== 3)
    return <>Select Weight Rules </>;

  console.log("Haha here you go!", level2, level1, level0, "EEE", "PPP", "PPP");

  const handleGroup2ModalOpen = () => {
    if (isEnabled(mapStructure[0])) {
      setModal(2);
    } else if (isEnabled(mapStructure[1])) {
      setModal(1);
    } else {
      setModal(0);
    }
  };

  const onSaveMap = () => {
    const isValid = validateWeights(
      secondGroups,
      firstGroups,
      parameters,
      weightState.wtselected.join("")
    );

    if (!isValid.status) {
      setMessage(isValid.message);
      return;
    } else {
      setMessage("");
    }

    const DMP_MAP = {
      DimensionID: DimensionID,
      MapStructure: mapStructure,
      DiscardMapRule: discarRules,
      AddParametersRule: addingRules,
      WeightRule: weightState.wtselected.join(""),
      TargetRule: "",
      AchievementRule: "",
      ParameterRatingScaleID: "",
      SecondGroups: secondGroups.map((group) => ({
        TransactionID: group.TransactionID,
        MinimumWeight: group.MinimumWeight || "",
        MaximumWeight: group.MaximumWeight || "",
      })),
      FirstGroups: firstGroups.map((group) => ({
        TransactionID: group.TransactionID,
        SecondGroupID: group.SecondGroupID,
        MinimumWeight: group.MinimumWeight || "",
        MaximumWeight: group.MaximumWeight || "",
      })),
      MapParameters: [
        ...parameters.map((parameter) => ({
          TransactionID: parameter.TransactionID,
          FirstGroupID: parameter.FirstGroupID,
          Weight: parameter.Weight || "",
          Target: [],
          AchievementDataType: "",
        })),
      ],
    };

    console.log(DMP_MAP, "DMP is initiated");
  };

  return (
    <div>
      {/* {JSON.stringify(localState)} */}
      <EwModal
        open={modal || modal === 0 ? true : false}
        onClose={() => setModal(null)}
      >
        <ModalWrapper />
      </EwModal>
      {/* <div
        className="Flex-row"
        style={{
          gap: 20,
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <div>
          <List type={2} list={level2} />
        </div>
        <div>
          <List type={1} list={level1} />
        </div>
        <div>
          {" "}
          <List type={0} list={level0} />
        </div>
      </div>
      {JSON.stringify(level1)} */}
      <ListView
        secondGroups={secondGroups}
        firstGroups={firstGroups}
        parameters={parameters}
        groupStruct={
          extractDimensionStructure(mapStructure) ||
          extractDimensionStructure("EEE")
        }
        parameterClickable={true}
        parameterOnClickHandler={(id) => {
          setModal({ parameter: { id } });
        }}
        addStruct={extractAddRules("PPP")}
        deleteStruct={extractDiscardRules("PPP")}
        weightStruct={extractWeightRules(weightState.wtselected.join(""))}
        handleAddGroup2ModalOpen={handleGroup2ModalOpen}
        handleAddGroup1ModalOpen={(g2Id) => {
          setModal(1);
          setCurrentTransactionID(g2Id);
        }}
        handleAddParameterModalOpen={(g1Id) => {
          setModal(0);
          setCurrentTransactionID(g1Id);
        }}
      />
      {message && <Box sx={{ color: "red" }}>{message}</Box>}
      <Button onClick={onSaveMap} title={"Save"} variant={"contained"} />
    </div>
  );
}

export default Mapping;
