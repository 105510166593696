import React, { useState, useEffect } from "react";
import Molecule from "../../../MComponent/Molecule";
import {
  getFiles,
  getFullDateFromEpoch,
  getDateFromEpoch,
} from "../../../Utils/Common/Library";
import mappings from "./data";
import DownloadReport from "./DownloadReport";
import AlertMessage from "../../../Utils/Messages/AlertMessage";

const { Table } = Molecule;
const heading = ["File Name", "Date", "Download"];

function ReportsTable({ type, isAppraiser }) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    getFiles({ queryName: mappings[type], isAppraiser })
      .then((res) => {
        if (res?.success === "1" && res?.data) {
          setRows(
            res.data.map(({ fileName, lastModified, url }) => [
              <div style={{ maxWidth: 300 }}>{fileName}</div>,
              getFullDateFromEpoch({
                epoch: new Date(lastModified).getTime(),
                time: true,
              }),
              <DownloadReport
                url={url}
                fileName={fileName}
                onError={(err) => setError(err)}
              />,
            ])
          );
        }
      })
      .catch((e) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  if (loading) return <>Fetching Files...</>;
  if (error)
    return (
      <AlertMessage
        type={1}
        message={"Network Error"}
        onClose={() => setError(false)}
      />
    );
  if (!type || rows.length === 0) return <>No reports</>;
  return (
    <div>
      <Table heading={heading} rows={rows} removeSearch={true} />
    </div>
  );
}

export default ReportsTable;
