export const ConfidentialDocument = {
  Section: "E",
  SectionLabel: "Confidential",
  SectionDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras est turpis, auctor a 
  enim non, ullamcorper commodo turpis. Suspendisse efficitur metus non nunc vulputate, vel feugiat 
  metus egestas. Nunc ornare mauris et dignissim lobortis. Donec tempor diam ut elit ultrices, sed 
  pulvinar ex consectetur.`,
  GrowthRoleAssessment: "DB",
  GrowthRoleReadinessOptions: [
    { label: "Outstanding. Can grow into next role.", value: "1" },
    { label: "High Potential. Can grow into next role.", value: "2" },
    { label: "Appropriate for current level.", value: "3" },
    { label: "Needs to develop.", value: "4" },
    { label: "Not capable.", value: "5" },
  ],
  PromotionAssessment: "DB",
  PromotionAssessmentOptions: [
    { label: "Lorem Ipsum ", value: "1" },
    { label: "Lorem Ipsum ", value: "2" },
    { label: "Lorem Ipsum ", value: "3" },
    { label: "Lorem Ipsum ", value: "4" },
  ],
  ExitAssessment: "MD",
  ExitAssessmentOptions: [
    { label: "Lorem Ipsum ", value: "1" },
    { label: "Lorem Ipsum ", value: "2" },
    { label: "Lorem Ipsum ", value: "3" },
    { label: "Lorem Ipsum ", value: "4" },
  ],
};
