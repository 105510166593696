import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

//const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
const initialProps={
  steps:null,
  activeStep:null
}
 function EwStepper(props=initialProps) {
  var { steps, activeStep } = props;
 
  const isStepFailed = (step) => {
    return step === 1;
  };
  if (!steps || steps?.length === 0) return <></>;
  if (activeStep > steps.length)
    return (
      <Typography variant="caption" color="error">
        {" "}
        Active Step Error{" "}
      </Typography>
    );

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((ele, index) => {
          const labelProps = {};
          //   if (isStepFailed(index)) {
          //     labelProps.optional = (
          //       <Typography variant="caption" color="error">
          //         Alert message
          //       </Typography>
          //     );

          //     labelProps.error = true;
          //   }

          return (
            <Step key={index}>
              <StepLabel {...labelProps}>{ele.label?ele.label:ele}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

EwStepper.prototype = {
  steps: PropTypes.array,
  activeStep: PropTypes.number,
};


export default EwStepper;