import React, { useState } from "react";
import Atomic from "../../../AComponent/Atomic";
import { useDispatch, useSelector } from "react-redux";

const { Input, EwSwitch, MultiSelect, Dropdown } = Atomic;
const id = "MPP";
const DimensionId = "19144";

function TrainingNeeds() {
  const {
    TrainingSection,
    TrainingSectionLabel,
    TrainingSectionDescription,
    DimensionLinkage,
    UnlinkNeeds,
    LinkTraining,
    NeedDescription,
    dimList,
  } = useSelector((state) => {
    if (!state.DataReducer[id]) return {};
    return state.DataReducer[id];
  });
  const dispatch = useDispatch();
  function updateState(val, type) {
    dispatch({
      type: "UPDATE_PROPS",
      payload: {
        id,
        componentNumber: type,
        data: val,
      },
    });
  }
  return (
    <div>
      <div className="Flex">
        <EwSwitch
          label={"Training Needs"}
          value={TrainingSection === "E"}
          onChange={(val) => updateState(val ? "E" : "D", "TrainingSection")}
        />
      </div>
      {TrainingSection === "E" && (
        <>
          <div className="Flex-row">
            <div>
              <Input
                label="Section Label"
                value={TrainingSectionLabel}
                onChange={(val) => updateState(val, "TrainingSectionLabel")}
              />
            </div>
            <div>
              <Input
                label="Section Description"
                value={TrainingSectionDescription}
                onChange={(val) =>
                  updateState(val, "TrainingSectionDescription")
                }
              />
            </div>
          </div>

          <div>
            <MultiSelect
              label="Dimension Linkage"
              // menuItems={multiOptions}\
              featureId={DimensionId}
              mapper={{
                label: "DimensionName",
                value: "TransactionID",
              }}
              includeID={true}
              apiResult={dimList}
              storeApiResult={(val) => updateState(val, "dimList")}
              value={DimensionLinkage}
              onChange={(val) => updateState(val, "DimensionLinkage")}
            />
          </div>

          <div className="Flex-row">
            <div>
              <EwSwitch
                label="Unlinked Needs"
                value={UnlinkNeeds}
                onChange={(val) => updateState(val, "UnlinkNeeds")}
              />
            </div>
            <div>
              <EwSwitch
                label="Link Training Programs"
                value={LinkTraining}
                onChange={(val) => updateState(val, "LinkTraining")}
              />
            </div>
          </div>
          <Dropdown
            label="Need Description"
            menuItems={[
              "Not enabled",
              "Enabled but optional",
              "Enabled and mandatory",
            ]}
            value={NeedDescription}
            onChange={(val) => updateState(val, "NeedDescription")}
          />
        </>
      )}
    </div>
  );
}

export default TrainingNeeds;
