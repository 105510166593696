import { Actions } from '../Actions/Actiontypes'
import store from '../Store'
const initialState = {
   Layout:{},
   Modal:{}
  }

  // const someAction = () => (dispatch) => {
  //   dispatch({
  //     type: 'clear',
  //   });
  

  // };
  function LayoutReducer(state = initialState, action){
    switch (action.type) {
        case Actions.SET: {

          if(action.payload.pageType==='Modal'){
            return {
              ...state,
              Modal:action.payload
            }
          }
          return {
            ...state,
            Layout:action.payload,
          }
        }
      case Actions.MODAL_UNSET:{
        return{
          ...state,
          Modal:{}
        }
      }
      
    
        default:{
         
           return state
        }
          }
  }

  export default LayoutReducer;