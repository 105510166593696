import React from "react";
import EwTabs from "../../MComponent/Tabs/Tabs";
import { Paper, Typography, Card, Chip } from "@mui/material";
import Progress from "../../AComponent/CircularProgress/Progress";
import Card_5 from "../../CComponent/Cards/Card_5";
import Data from "./pmsData.json";
import EwTable from "../../MComponent/Table/Table";

function PMS() {
  const { Section } = Data;
  const compMap = {
    Summary,
    SelfDevSection: SelfDev,
  };
  function Summary() {
    const { Summary } = Section;
    const {
      GoalSection,
      CompetencySection,
      OverallComment,
      scores,
      Planagregatescore,
      attendance,
    } = Summary;
    const { performanceCycle, OverAllScore, Multirater } = scores;
    let sample1 = [
      {
        label: "Goal 1",
        value: 20,
      },
      {
        label: "Goal 2",
        value: 5,
      },
      {
        label: "Goal 3",
        value: 33,
      },
      {
        label: "Goal 4",
        value: 100,
      },
    ];
    let sample2 = [
      {
        label: "Competency  1",
        value: 20,
      },
      {
        label: "Competency  2",
        value: 5,
      },
    ];
    return (
      <>
        <div className="Flex">
          <Typography variant="h3" component="h3">
            Summary
          </Typography>
        </div>
        <br />
        <div className="Flex-row" style={{ justifyContent: "space-around" }}>
          <div>
            {GoalSection && (
              <Card_5 data={GoalSection.data} heading={GoalSection.label} />
            )}
          </div>
          <div>
            {CompetencySection && (
              <Card_5
                data={CompetencySection.data}
                heading={CompetencySection.label}
              />
            )}
          </div>
          <div>
            {Planagregatescore && (
              <Card_5
                data={Planagregatescore.data}
                heading={Planagregatescore.label}
              />
            )}
          </div>
        </div>
        <br />
        {OverallComment && (
          <div className="Flex">
            <Paper
              style={{
                padding: 20,
                position: "relative",
                backgroundColor: "#F5F6FB",
                minHeight: 100,
                width: "30%",
              }}
            >
              <div className="Flex" style={{ marginTop: "-12%" }}>
                <img
                  style={{ border: "5px solid white", borderRadius: "50%" }}
                  width={"25%"}
                  height={"25%"}
                  src={OverallComment.img}
                  alt="profile-img"
                />
                <div style={{ width: "80%", overflow: "hidden", height: 100 }}>
                  <Typography variant="caption">
                    {OverallComment.comment}
                  </Typography>
                </div>
                <div className="Flex">
                  <Typography variant="body1">{OverallComment.name}</Typography>
                  <Typography variant="body1">
                    {OverallComment.designation}
                  </Typography>
                </div>
              </div>
            </Paper>
          </div>
        )}
        <br />
        <br />
        <div className="Flex-row" style={{ justifyContent: "space-around" }}>
          {performanceCycle && (
            <div>
              <Progress
                label={performanceCycle.label}
                value={performanceCycle.value}
              />
            </div>
          )}
          {OverAllScore && (
            <div>
              <Progress
                color="warning"
                label={OverAllScore.label}
                value={OverAllScore.value}
              />
            </div>
          )}
          {Multirater && (
            <div>
              <Progress
                color="error"
                label={Multirater.label}
                value={Multirater.value}
              />
            </div>
          )}
        </div>
        <div>
          {/* {attendance && <VerticalChart data={{label:attendance.labels,datasets:attendance.datasets}} />} */}
        </div>
      </>
    );
  }
  function SelfDev() {
    const { name, table } = Section.SelfDevSection;
    return (
      <>
        <div className="Flex">
          <h1>{name}</h1>
          <EwTable heading={table.heading} rows={table.rows} removeSearch/>
        </div>
      </>
    );
  }
  let compList = [];
  Object.keys(Section).map((ele) => {
    if (!compMap[ele]) return;
    compList.push({
      label: "" + Section[ele].tabname,
      Component: compMap[ele],
    });
  });
  return (
    <div>
      <div className="Flex">
        <h1>Plan Name</h1>
      </div>
      <br /> <br />
      <div className="Flex">
        <Paper className="Flex" style={{ width: "80%", padding: 5 }}>
          <EwTabs componentList={compList} />
        </Paper>
      </div>
    </div>
  );
}

export default PMS;
