export const Phases = {
  DimensionID: 1,
  RatingScaleID: "1678793170575",
  TargetRule: "EARM",
  TargetEditing: "P",
  Parameters: [
    {
      ParameterID: 1,
      PhaseType: "A",
      PeriodFrequencyType: "H",
      Periods: [
        {
          PeriodLabel: "First Half",
          PeriodType: "FH",
          PeriodTargets: [
            {
              ScaleOptionID: "8b200f67-b847-4a4e-997f-b718de09e1e7",
              TargetValue: "Hero",
              MinimumTargetValue: "0",
              MaximumTargetValue: "20",
            },
            {
              ScaleOptionID: "81c44f89-4438-4c4d-92fc-8ab61887321d",
              TargetValue: "Zero",
              MinimumTargetValue: "21",
              MaximumTargetValue: "40",
            },
            {
              ScaleOptionID: "7c2e6831-bdb3-4a46-94ae-c8e38aecf02d",
              TargetValue: "Zoro",
              MinimumTargetValue: "41",
              MaximumTargetValue: "60",
            },
            {
              ScaleOptionID: "6cb2be64-23c3-44db-8f48-0a9697cc9200",
              TargetValue: "Mikey",
              MinimumTargetValue: "61",
              MaximumTargetValue: "100",
            },
          ],
        },
      ],
      AssessmentPeriodType: "FH",
      Assessment: [
        {
          ActorType: "S",
          ActorID: "",
          Achievement: "",
          Rating: "",
        },
        {
          ActorType: "A",
          ActorID: "",
          Achievement: "",
          Rating: "",
        },
        {
          ActorType: "R",
          ActorID: "",
          Achievement: "",
          Rating: "",
        },
      ],
      History: [
        {
          PeriodLabel: "First Half",
          PeriodType: "FH",
          PeriodTargets: [
            {
              ScaleOptionID: "8b200f67-b847-4a4e-997f-b718de09e1e7",
              TargetValue: "Hero",
              MinimumTargetValue: "0",
              MaximumTargetValue: "20",
            },
            {
              ScaleOptionID: "81c44f89-4438-4c4d-92fc-8ab61887321d",
              TargetValue: "Zero",
              MinimumTargetValue: "21",
              MaximumTargetValue: "40",
            },
            {
              ScaleOptionID: "7c2e6831-bdb3-4a46-94ae-c8e38aecf02d",
              TargetValue: "Zoro",
              MinimumTargetValue: "41",
              MaximumTargetValue: "60",
            },
            {
              ScaleOptionID: "6cb2be64-23c3-44db-8f48-0a9697cc9200",
              TargetValue: "Mikey",
              MinimumTargetValue: "61",
              MaximumTargetValue: "100",
            },
          ],
          Assessment: [
            {
              ActorType: "S",
              ActorID: "",
              Achievement: "Label 1 (3)",
              Rating: "3",
            },
            {
              ActorType: "A",
              ActorID: "",
              Achievement: "Label 1 (8)",
              Rating: "5",
            },
            {
              ActorType: "R",
              ActorID: "",
              Achievement: "Label 1 (6)",
              Rating: "3",
            },
          ],
        },
      ],
    },
  ],
};
