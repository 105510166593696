import React, { useState } from 'react'
import Paper from '@mui/material/Paper'

function Card_1({ Elements }) {
  const [elvation, setElevation] = useState(1)
  var styleArray = [
    { position: 'absolute', top: 10, left: 10 },
    { position: 'absolute', top: 50, left: 10, width: '75%' },
    { position: 'absolute', bottom: 10, width: '100%' },
    { position: 'absolute', top: 10, right: 10 },
  ]
  return (
    <>
      <Paper
        elevation={elvation}
        onMouseEnter={() => {
          setElevation(3)
        }}
        onMouseLeave={() => {
          setElevation(1)
        }}
        style={{
          height: 180,
          width: 350,
          position: 'relative',
        }}
      >
        {Elements.map((ele, index) => {
          let compArr = ele.Component.split('.')
          let compAddress
          if (compArr.length === 3)
            compAddress =
              compArr[0] + 'Component/' + compArr[1] + '/' + compArr[2]
          let Comp

          return (
            <div style={styleArray[index]}>
              {(Comp = require('../../' + compAddress).default)}
              <Comp key={index} {...ele.props} />
            </div>
          )
        })}
      </Paper>
      <br />
    </>
  )
}

export default Card_1
