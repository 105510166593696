import { createTheme } from "@mui/material";

const typography = {
  small_text1: {
    fontSize: 12,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0%",
    lineHeight: "auto",
    color:"#B1AEBB"
  },
  small_text2: {
    fontSize: 10,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0%",
    lineHeight: "auto",
  },
  "masthead-menu": {
    fontSize: 10,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0%",
    lineHeight: "auto",
  },
  card_label1: {
    fontSize: 8,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: "0px",
    lineHeight: "14px",
  },
  card_lable2: {
    fontSize: 8,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0%",
    lineHeight: "auto",
  },
  card_label3: {
    fontSize: 8,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0%",
    lineHeight: "auto",
  },
  "typography/headings/h1": {
    fontSize: 34,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.25px",
    lineHeight: "123.50000143051147%",
  },
  "typography/headings/h2": {
    fontSize: 24,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: "0px",
    lineHeight: "133.39999914169312%",
  },
  "typography/headings/h3": {
    fontSize: 20,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "160.0000023841858%",
  },
  "typography/headings/h4": {
    fontSize: 16,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0%",
    lineHeight: "auto",
  },
  "typography/headings/applicabilityview,1st,2nd group and parameter heading": {
    fontSize: 16,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0%",
    lineHeight: "116.68164730072021%",
  },
  "typography/paragraph/p1": {
    fontSize: 16,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "150%",
  },
  "typography/paragraph/p2": {
    fontSize: 16,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "175%",
  },
  "typography/paragraph/p3": {
    fontSize: 14,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: "0.17000000178813934px",
    lineHeight: "142.99999475479126%",
  },
  "typography/paragraph/p4": {
    fontSize: 14,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.10000000149011612px",
    lineHeight: "157.00000524520874%",
  },
  "typography/paragraph/p5": {
    fontSize: 12,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0%",
    lineHeight: "auto",
  },
  "typography/form/form_h1": {
    fontSize: 14,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: "0.4000000059604645px",
    lineHeight: "165.9999966621399%",
  },
  "typography/form/form_text1": {
    fontSize: 14,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0%",
    lineHeight: "auto",
  },
  "typography/form/form_text2": {
    fontSize: 12,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0%",
    lineHeight: "auto",
  },
  "typography/form/form_text3": {
    fontSize: 10,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0%",
    lineHeight: "auto",
  },
  "components/date-picker-current-month": {
    fontSize: 16,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "150%",
  },
  "components/bottom-navigation-active-label": {
    fontSize: 14,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.4000000059604645px",
    lineHeight: "165.9999966621399%",
  },
  "components/list-subheader": {
    fontSize: 14,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: "0.10000000149011612px",
    lineHeight: "48px",
  },
  "components/table-header": {
    fontSize: 14,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: "0.17000000178813934px",
    lineHeight: "24px",
  },
  "components/tooltip-label": {
    fontSize: 10,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: "0px",
    lineHeight: "14px",
  },
  "components/input-text": {
    fontSize: 16,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "24px",
  },
  "components/helper-text": {
    fontSize: 12,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.4000000059604645px",
    lineHeight: "165.9999966621399%",
  },
  "components/input-label": {
    fontSize: 12,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "12px",
  },
  "components/field_text1": {
    fontSize: 12,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: "0.14000000059604645px",
    lineHeight: "20px",
  },
  "components/avatar-initials": {
    fontSize: 20,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.14000000059604645px",
    lineHeight: "20px",
  },
  "components/chip-label": {
    fontSize: 13,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.1599999964237213px",
    lineHeight: "18px",
  },
  "components/xs-label": {
    fontSize: 8,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0%",
    lineHeight: "auto",
  },
  "components/menu/menu-item-dense": {
    fontSize: 14,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.17000000178813934px",
    lineHeight: "24px",
  },
  "components/menu/menu-item": {
    fontSize: 16,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "150%",
  },
  "components/buttontext/button-small": {
    fontSize: 13,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.46000000834465027px",
    lineHeight: "22px",
  },
  "components/buttontext/button-medium": {
    fontSize: 14,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.4000000059604645px",
    lineHeight: "24px",
  },
  "components/buttontext/button-large": {
    fontSize: 15,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0.46000000834465027px",
    lineHeight: "26px",
  },
  "components/alerts/alert-description": {
    fontSize: 14,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "142.99999475479126%",
  },
  "components/alerts/alert-title": {
    fontSize: 16,
    fontFamily: "Open Sans, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: "0.15000000596046448px",
    lineHeight: "150%",
  },
  "components/role-card/name": {
    fontSize: 8,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: "0%",
    lineHeight: "auto",
  },
  "components/role-card/info": {
    fontSize: 8,
    fontFamily: "Poppins, sans-serif",
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: "0%",
    lineHeight: "auto",
  },
};
export default typography;
// export default createTheme({
//  typography
// });
