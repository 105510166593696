import React from "react";
import Editor2 from "../../Workspace/Editor3";

function EmailContent() {
  return (
    <div className="Flex">
      <Editor2
        placeholder={"Subject"}
        // enableTags={true}
        height={200}
        btnLabel="Save Subject"
      />
      <br />
      <Editor2
        placeholder={"Body"}
        //enableTags={true}
        btnLabel="Save Body"
      />
      {/* <Editor2 /> */}
    </div>
  );
}

export default EmailContent;
