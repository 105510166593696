const mapping = {
  GradeApplicability: "Grade",
  LocationApplicability: "Location",
  FunctionApplicability: "Function",
  BusinessUnitApplicability: "BusinessUnit",
  RoleApplicability: "Role",
  EmployeeTypeApplicability: "EmployeeType",
  LegalEntityApplicability: "Company",
};

// Company;
// BusinessUnit;
// Location;
// Grade;
// Role;
// Function;
// EmployeeType;

export default function extract(applicability) {
  if (!applicability) applicability = {};
  // if (Object.keys(applicability).length === 0) return [];
  let arr = [];
  Object.entries(mapping).forEach(([key, value]) => {
    if (applicability[key] && applicability[key][value]?.length > 0) {
      const ids = applicability[key][value].map(({ ID }) => ID);
      arr.push({
        [value]: ids,
      });
    } else {
      arr.push({
        [value]: [],
      });
    }
  });
  return arr;
}
