import { Box, Divider, IconButton, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Atomic from "../../AComponent/Atomic";
import Molecule from "../../MComponent/Molecule";
import { Add, Delete } from "@mui/icons-material";
import { v4 as uuid } from "uuid";
import Editor3 from "../../Workspace/Editor3";
import { useSelector } from "react-redux";
import { getData, submitPayload } from "../../Utils/Common/Library";

const { Button, Input, Dropdown, Radio } = Atomic;
const { EwModal: Modal, Tabs } = Molecule;

const NotificationFeatureID = "00143";

const REDUX_ID = "Notification";

const userTypeMap = {
  SU: "Subject User",
  TU: "Transaction User",
  LM: "Line Manager",
  FM: "Functional Manager",
  EH: "Business Unit Head",
};
const layouts = [{ label: "PMS", value: "pms" }];
const senderTypeMap = {
  SU: "Subject User",
  TU: "Transaction User",
  AO: "Action Owner",
  NO: "Notifications@employwise.com",
};

const actionTypeMap = {
  SN: "Snooze",
  AR: "Archive",
  DE: "Delete",
  LI: "Link",
};

const userTypeValueMap = {
  SU: [0, 0],
  TU: [0, 0],
  LM: [0, 99],
  FM: [0, 99],
  EH: [0, 0],
};

const RecipientForm = ({ type, addRecipientHandler, closeForm }) => {
  const [selected, setSelected] = useState(-1);
  const [level, setLevel] = useState("0");

  const onSelectHandler = (value) => {
    setSelected(value);
  };

  const levelChangeHandler = (value) => {
    setLevel(value);
  };

  return (
    <Box>
      <Box
        margin={"2em 2em 0 0"}
        display={"flex"}
        justifyContent={"flex-start"}
        gap={"2em"}
      >
        <Dropdown
          menuItems={[
            ...Object.keys(userTypeMap).map((key) => ({
              label: userTypeMap[key],
              value: key,
            })),
          ]}
          label={"Recipient User"}
          value={selected}
          onChange={onSelectHandler}
          styles={{ marginTop: "0.5em" }}
        />
        {selected !== -1 &&
          userTypeValueMap[selected][0] !== userTypeValueMap[selected][1] && (
            <Box width={"100%"}>
              <Input
                styles={{ width: "100%", margin: "0" }}
                type={"number"}
                label={"Recipient User Level"}
                value={level}
                onChange={levelChangeHandler}
                InputProps={{
                  inputProps: {
                    min: userTypeValueMap[selected][0],
                    max: userTypeValueMap[selected][1],
                  },
                }}
              />
            </Box>
          )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "1em",
          margin: "0.5em 0",
        }}
      >
        <Button
          title={"Save"}
          variant={"text"}
          onClick={() => {
            addRecipientHandler(selected, level);
            closeForm();
          }}
        />
        <Button
          title={"Close"}
          variant={"text"}
          onClick={() => {
            setSelected(-1);
            setLevel(0);
            closeForm();
          }}
          color={"error"}
        />
      </Box>
    </Box>
  );
};

const RecipientCard = ({
  recipientUserType,
  recipientUserLevel,
  deleteRecipientHandler,
}) => {
  return (
    <Paper
      elevation={2}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1em",
        margin: "0 2em 1em 0",
      }}
    >
      <Box flex={1}>
        <Typography variant="overline">User Type</Typography>
        <Typography>{userTypeMap[recipientUserType]}</Typography>
      </Box>
      <Box flex={1}>
        <Typography variant="overline">User Value</Typography>
        <Typography>{recipientUserLevel}</Typography>
      </Box>
      <IconButton
        color="error"
        onClick={() => {
          deleteRecipientHandler(recipientUserType);
        }}
      >
        <Delete />
      </IconButton>
    </Paper>
  );
};

const RecipientsList = ({ type, label, saveRecipients, initRecipients }) => {
  console.log(initRecipients, "");
  const [recipients, setRecipients] = useState(initRecipients || []);
  const [addMode, setAddMode] = useState(false);

  const addRecipientHandler = (userType, userLevel) => {
    const index = recipients.findIndex(
      (recipient) => recipient.userType === userType
    );
    if (index >= 0) return;

    const newState = [...recipients];
    newState.push({
      RecipientType: type,
      RecipientUserType: userType,
      RecipientUserTypeValue: userLevel,
    });
    setRecipients(newState);
    saveRecipients(type, newState);
    return;
  };

  const deleteRecipientHandler = (userType) => {
    const newState = recipients.filter(
      (recipient) => recipient.RecipientUserType !== userType
    );
    setRecipients(newState);
    saveRecipients(type, newState);
    return;
  };

  return (
    <Box marginBottom={"1em"}>
      <Typography variant="h6" fontWeight={"400"}>
        {label || "Recipients"}
      </Typography>
      <Divider sx={{ margin: "0.6em 2em 1.5em 0" }} />
      {recipients.map((recipient) => (
        <RecipientCard
          type={recipient.RecipientType}
          recipientUserLevel={recipient.RecipientUserTypeValue}
          recipientUserType={recipient.RecipientUserType}
          deleteRecipientHandler={deleteRecipientHandler}
        />
      ))}
      {addMode && (
        <RecipientForm
          type={type}
          addRecipientHandler={addRecipientHandler}
          closeForm={() => {
            setAddMode(false);
          }}
        />
      )}
      <Button
        title={"Add Recipient"}
        variant={"text"}
        onClick={() => {
          setAddMode(true);
        }}
        style={{ margin: 0 }}
        startIcon={<Add />}
      />
    </Box>
  );
};

function ActionButtonForm({ addActionHandler, closeForm }) {
  const initAction = {
    CTAType: -1,
    CTALayoutID: "",
    CTALabel: "",
    AutoAction: "D",
    AutoActionTime: "",
  };
  const [action, setAction] = useState({ ...initAction });

  function onChangeHandler(key, value) {
    setAction((prevState) => ({ ...action, [key]: value }));
  }

  return (
    <div>
      <div className="Flex" style={{ gap: "0.5em" }}>
        <Dropdown
          menuItems={[
            ...Object.keys(actionTypeMap).map((key) => ({
              label: actionTypeMap[key],
              value: key,
            })),
          ]}
          label={"Action Type"}
          value={action.CTAType}
          onChange={(value) => onChangeHandler("CTAType", value)}
          styles={{ marginTop: "0.5em" }}
        />
        <div className="Flex-row g20">
          <div>
            <Input
              styles={{ width: "100%", margin: "0" }}
              label={"Label"}
              value={action.CTALabel}
              onChange={(value) => onChangeHandler("CTALabel", value)}
            />
          </div>
          {action.CTAType === "LI" && (
            <div style={{ width: "30%" }}>
              <Dropdown
                label={"Select A Layout"}
                menuItems={layouts}
                value={action.CTALayoutID}
                onChange={(value) => onChangeHandler("CTALayoutID", value)}
              />
              {/* <Input
              styles={{ width: "100%", margin: "0" }}
              label={"Layout ID"}
              value={action.CTALayoutID}
              onChange={(value) => onChangeHandler("CTALayoutID", value)}
            /> */}
            </div>
          )}
        </div>
        <div className="Flex-row">
          <div>
            <Radio
              flex={1}
              label={"Auto Action"}
              value={action.AutoAction}
              onChange={(value) => onChangeHandler("AutoAction", value)}
              menuItems={[
                { label: "Enabled", value: "E" },
                { label: "Disabled", value: "D" },
              ]}
            />
          </div>
          {action.AutoAction === "E" && (
            <div>
              <Input
                styles={{ width: "100%", margin: "0" }}
                label={"Auto Action Time"}
                type={"number"}
                value={action.AutoActionTime}
                onChange={(value) => onChangeHandler("AutoActionTime", value)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="Flex-row" style={{ gap: "1em" }}>
        <Button
          title={"Save"}
          variant={"text"}
          onClick={() => {
            addActionHandler({ ...action });
            closeForm();
          }}
        />
        <Button
          title={"Close"}
          variant={"text"}
          onClick={() => {
            setAction({ ...initAction });
            closeForm();
          }}
          color={"error"}
        />
      </div>
    </div>
  );
}

function ActionButtonCard({ CTAType, CTALabel, deleteActionHandler }) {
  return (
    <Paper
      elevation={2}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "1em",
        margin: "0 2em 1em 0",
      }}
    >
      <Box flex={1}>
        <Typography variant="overline">Action Type</Typography>
        <Typography>{actionTypeMap[CTAType]}</Typography>
      </Box>
      <Box flex={1}>
        <Typography variant="overline">Action Label</Typography>
        <Typography>{CTALabel}</Typography>
      </Box>
      <IconButton
        color="error"
        onClick={() => {
          deleteActionHandler(CTAType);
        }}
      >
        <Delete />
      </IconButton>
    </Paper>
  );
}

function ActionButtonList({ saveActions, initActions }) {
  const [actions, setActions] = useState(initActions || []);
  const [addMode, setAddMode] = useState(false);

  const addActionHandler = ({
    CTAType,
    CTALayoutID,
    CTALabel,
    AutoAction,
    AutoActionTime,
  }) => {
    const index = actions.findIndex((action) => action.CTAType === CTAType);
    if (index >= 0) return;

    const newState = [...actions];
    newState.push({
      CTAType,
      CTALayoutID,
      CTALabel,
      AutoAction,
      AutoActionTime,
    });
    setActions(newState);
    saveActions(newState);
    return;
  };

  const deleteActionHandler = (type) => {
    const newState = actions.filter((action) => action.CTAType !== type);
    setActions(newState);
    saveActions(newState);
    return;
  };

  return (
    <Box marginBottom={"1em"}>
      <Typography variant="h6" fontWeight={"400"}>
        Actions
      </Typography>
      <Divider sx={{ margin: "0.6em 2em 1.5em 0" }} />
      {!addMode &&
        actions.map((action) => (
          <ActionButtonCard
            CTAType={action.CTAType}
            CTALabel={action.CTALabel}
            deleteActionHandler={deleteActionHandler}
          />
        ))}
      {addMode && (
        <ActionButtonForm
          addActionHandler={addActionHandler}
          closeForm={() => {
            setAddMode(false);
          }}
        />
      )}
      <Button
        title={"Add Action"}
        onClick={() => {
          setAddMode(true);
        }}
        style={{ margin: 0 }}
        variant={"text"}
        startIcon={<Add />}
      />
    </Box>
  );
}

const Need2Act = ({
  NotificationChannel,
  NotificationType,
  initDocument,
  saveDocument,
}) => {
  const [document, setDocument] = useState(
    initDocument || {
      NotificationChannel,
      NotificationType,
      Recipients: [],
      SenderType: "AO",
      ContentType: "T",
      Subject: "",
      Message: "",
      ActionButtons: [],
    }
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initDocument) {
      setLoading(true);
      getData({
        featureId: NotificationFeatureID,
        payload: { "Header.TransactionID": initDocument.NotificationID },
      })
        .then((response) => {
          console.log(JSON.stringify(response), "");
          setDocument(response.Data[0]);
        })
        .catch((error) => {
          console.log(JSON.stringify(error), "");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [initDocument]);

  const subjectChangeHandler = (value) => {
    setDocument((prevState) => ({ ...prevState, Subject: value }));
  };

  const messageChangeHandler = (value) => {
    setDocument((prevState) => ({ ...prevState, Message: value }));
  };

  const recipientsChangeHandler = (type, value) => {
    setDocument((prevState) => ({ ...prevState, Recipients: value }));
  };

  const actionsChangeHandler = (value) => {
    console.log(value, "");
    setDocument((prevState) => ({ ...prevState, ActionButtons: value }));
  };

  function onSaveHandler() {
    const NOTIFICATION_PAYLOAD = {
      NotificationTemplateID: document.NotificationTemplateID || "",
      AuthorFeatureID: document.AuthorFeatureID || "11",
      AuthorFeatureVariantID: document.AuthorFeatureVariantID || "000",
      NotificationChannel: document.NotificationChannel || "",
      WorkflowID: document.WorkflowID || "",
      StepID: document.StepID || "",
      ActionDue: document.ActionDue || "",
      ActionID: document.ActionID || "",
      NotificationType: document.NotificationType || "",
      Recipients: document.Recipients || [
        {
          RecipientType: "",
          RecipientUserType: "",
          RecipientUserTypeValue: "",
        },
      ],
      SenderType: document.SenderType || "",
      ContentType: document.ContentType || "",
      Subject: document.Subject || "",
      Message: document.Message || "",
      TelecomTemplateID: document.TelecomTemplateID || "",
      Priority: document.Priority || "U",
      ActionButtons: document.ActionButtons || [
        {
          CTAType: "",
          CTALayoutID: "",
          CTALabel: "",
          AutoAction: "",
          AutoActionTime: "",
        },
      ],
      TransactionLayoutID: document.TransactionLayoutID || "123",
    };

    let Edit = null;
    if (initDocument) {
      Edit = {
        TransactionID: initDocument.NotificationID,
        EffectiveFromTimestamp: document.EffectiveFromTimestamp,
        RequestType: "1",
      };
    }

    setLoading(true);
    submitPayload({
      featureId: NotificationFeatureID,
      payload: NOTIFICATION_PAYLOAD,
      edit: Edit,
    }).then((response) => {
      const SAVED_DOCUMENT = {
        NotificationID: response.TransactionID,
      };
      console.log(JSON.stringify(response), "After saving ", SAVED_DOCUMENT);
      saveDocument(SAVED_DOCUMENT);
      setLoading(false);
    });
  }

  console.log(document, "");

  return loading ? (
    <div className="Flex">Loading ...</div>
  ) : (
    <Box height={"60vh"} width={"60vw"} className="scroll">
      <Box display={"flex"}>
        <Box flex={1} borderRight={"1px solid #8C8C8C"}>
          <RecipientsList
            type={"T"}
            saveRecipients={recipientsChangeHandler}
            initRecipients={document.Recipients}
          />
          <ActionButtonList
            saveActions={actionsChangeHandler}
            initActions={document.ActionButtons}
          />
        </Box>
        <Box flex={1} padding={"0 2em"}>
          <Input
            label={"Subject"}
            type={"text"}
            onChange={subjectChangeHandler}
            styles={{ margin: 0, marginBottom: "1em", width: "100%" }}
            value={document.Subject}
          />
          <Input
            label={"Message"}
            type={"text"}
            onChange={messageChangeHandler}
            rows={5}
            styles={{ margin: 0, marginBottom: "1em", width: "100%" }}
            value={document.Message}
          />
        </Box>
      </Box>
      <br />
      <div className="Flex"></div>
      <br />
      <div className="Flex">
        <Button onClick={onSaveHandler} title={"Save Need to act"} />
      </div>
    </Box>
  );
};

const Email = ({
  NotificationChannel,
  NotificationType,
  initDocument,
  saveDocument,
}) => {
  const [document, setDocument] = useState({
    NotificationChannel,
    NotificationType,
    Recipients: [],
    SenderType: "AO",
    ContentType: "T",
    Subject: "",
    Message: "",
  });

  const [loading, setLoading] = useState(true);

  const [recipients, setRecipients] = useState({
    T: [],
    C: [],
    B: [],
  });

  useEffect(() => {
    if (initDocument) {
      getData({
        featureId: NotificationFeatureID,
        payload: { "Header.TransactionID": initDocument.NotificationID },
      })
        .then((response) => {
          console.log(JSON.stringify(response), "From email response");
          setDocument(response.Data[0]);
          const responseRecipients = response.Data[0].Recipients;
          setRecipients({
            T: responseRecipients.filter(
              (recipient) => recipient.RecipientType === "T"
            ),
            C: responseRecipients.filter(
              (recipient) => recipient.RecipientType === "C"
            ),
            B: responseRecipients.filter(
              (recipient) => recipient.RecipientType === "B"
            ),
          });
        })
        .catch((error) => {
          console.log(JSON.stringify(error), "");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [initDocument]);

  const subjectChangeHandler = (value) => {
    console.log("Subject value: ", value);
    if (!value) return;
    setDocument((prevState) => ({
      ...prevState,
      Subject: value.replace(/"/g, ""),
    }));
  };

  const messageChangeHandler = (value) => {
    setDocument((prevState) => ({
      ...prevState,
      Message: value.replace(/"/g, ""),
    }));
  };

  const recipientsChangeHandler = (type, value) => {
    console.log(type, value, "");
    setRecipients((prevState) => ({ ...prevState, [type]: value }));
  };

  function onSaveHandler() {
    const NOTIFICATION_PAYLOAD = {
      NotificationTemplateID: document.NotificationTemplateID || "",
      AuthorFeatureID: document.AuthorFeatureID || "11",
      AuthorFeatureVariantID: document.AuthorFeatureVariantID || "000",
      NotificationChannel: document.NotificationChannel || "",
      WorkflowID: document.WorkflowID || "",
      StepID: document.StepID || "",
      ActionDue: document.ActionDue || "",
      ActionID: document.ActionID || "",
      NotificationType: document.NotificationType || "",
      Recipients: [...recipients.T, ...recipients.C, ...recipients.B] || [
        {
          RecipientType: "",
          RecipientUserType: "",
          RecipientUserTypeValue: "",
        },
      ],
      SenderType: document.SenderType || "",
      ContentType: document.ContentType || "",
      Subject: document.Subject || "",
      Message: document.Message || "",
      TelecomTemplateID: document.TelecomTemplateID || "",
      Priority: document.Priority || "U",
      ActionButtons: document.ActionButtons || [
        {
          CTALayoutID: "",
          CTALabel: "",
          AutoAction: "",
          CTAType: "A",
          AutoActionTime: "",
        },
      ],
      TransactionLayoutID: document.TransactionLayoutID || "123",
    };
    let Edit = null;
    if (initDocument) {
      Edit = {
        TransactionID: initDocument.NotificationID,
        EffectiveFromTimestamp: document.EffectiveFromTimestamp,
        RequestType: "1",
      };
    }

    setLoading(true);
    submitPayload({
      featureId: NotificationFeatureID,
      payload: NOTIFICATION_PAYLOAD,
      edit: Edit,
    }).then((response) => {
      console.log(JSON.stringify(response), "");
      const SAVED_DOCUMENT = {
        NotificationID: response.TransactionID,
      };
      saveDocument(SAVED_DOCUMENT);
      setLoading(false);
    });
  }

  return loading ? (
    <div className="Flex">Loading ...</div>
  ) : (
    <div
      className="scroll"
      style={{ height: "60vh", width: "60vw", paddingRight: "2em" }}
    >
      <div className="Flex" style={{ alignItems: "normal", gap: "3em" }}>
        <div
          className="Flex-row"
          style={{ flex: 1, gap: "1em", alignItems: "flex-start" }}
        >
          <div style={{ flex: "1 1 0px", width: 0 }}>
            <RecipientsList
              type={"T"}
              label={"To"}
              saveRecipients={recipientsChangeHandler}
              initRecipients={recipients.T}
            />
          </div>
          <div style={{ flex: "1 1 0px", width: 0 }}>
            <RecipientsList
              type={"C"}
              label={"CC"}
              saveRecipients={recipientsChangeHandler}
              initRecipients={recipients.C}
            />
          </div>
          <div style={{ flex: "1 1 0px", width: 0 }}>
            <RecipientsList
              type={"B"}
              label={"BCC"}
              saveRecipients={recipientsChangeHandler}
              initRecipients={recipients.B}
            />
          </div>
        </div>
        <div>
          <Typography variant="h6" fontWeight={"400"}>
            Sender
          </Typography>
          <Divider sx={{ margin: "0.6em 2em 1.5em 0" }} />
          <Dropdown
            label={"Sender"}
            menuItems={[
              ...Object.entries(senderTypeMap).map(([key, value]) => ({
                label: value,
                value: key,
              })),
            ]}
            onChange={(value) =>
              setDocument((prevState) => ({ ...prevState, SenderType: value }))
            }
            value={document.SenderType}
          />
        </div>
        <div>
          {console.log("Subject is: ", document.Subject)}
          {/* <Editor3
            placeholder={"Subject"}
            //enableTags={true}
            height={200}
            value={document.Subject}
            onChange={subjectChangeHandler}
          /> */}
          <Input
            styles={{ width: "100%" }}
            label={"Subject"}
            value={document.Subject}
            onChange={subjectChangeHandler}
          />
          <Box margin={"1em 0"}></Box>
          <Editor3
            placeholder={"Body"}
            value={document.Message}
            // enableTags={true}
            height={300}
            onChange={messageChangeHandler}
          />
        </div>
        <div className="Flex">
          <Button onClick={onSaveHandler} title={"Save Email"} />
        </div>
      </div>
    </div>
  );
};

const SMS = ({
  NotificationChannel,
  NotificationType,
  initDocument,
  saveDocument,
}) => {
  const [document, setDocument] = useState(
    initDocument || {
      NotificationChannel,
      NotificationType,
      Recipients: [],
      SenderType: "AO",
      ContentType: "T",
      Subject: "",
      Message: "",
    }
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(initDocument, "IMPORTANT");
    if (initDocument) {
      setLoading(true);
      getData({
        featureId: NotificationFeatureID,
        payload: { "Header.TransactionID": initDocument.NotificationID },
      })
        .then((response) => {
          console.log(JSON.stringify(response), "");
          setDocument(response.Data[0]);
        })
        .catch((error) => {
          console.log(JSON.stringify(error), "");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [initDocument]);

  const messageChangeHandler = (value) => {
    setDocument((prevState) => ({ ...prevState, Message: value }));
  };

  const recipientsChangeHandler = (type, value) => {
    setDocument((prevState) => ({ ...prevState, Recipients: value }));
  };

  function onSaveHandler() {
    const NOTIFICATION_PAYLOAD = {
      NotificationTemplateID: document.NotificationTemplateID || "",
      AuthorFeatureID: document.AuthorFeatureID || "11",
      AuthorFeatureVariantID: document.AuthorFeatureVariantID || "000",
      NotificationChannel: document.NotificationChannel || "",
      WorkflowID: document.WorkflowID || "",
      StepID: document.StepID || "",
      ActionDue: document.ActionDue || "",
      ActionID: document.ActionID || "",
      NotificationType: document.NotificationType || "",
      Recipients: document.Recipients || [
        {
          RecipientType: "",
          RecipientUserType: "",
          RecipientUserTypeValue: "",
        },
      ],
      SenderType: document.SenderType || "",
      ContentType: document.ContentType || "",
      Subject: document.Subject || "",
      Message: document.Message || "",
      TelecomTemplateID: document.TelecomTemplateID || "",
      Priority: document.Priority || "U",
      ActionButtons: document.ActionButtons || [
        {
          CTALayoutID: "",
          CTALabel: "",
          AutoAction: "",
          CTAType: "A",
          AutoActionTime: "",
        },
      ],
      TransactionLayoutID: document.TransactionLayoutID || "123",
    };

    let Edit = null;
    if (initDocument) {
      Edit = {
        TransactionID: initDocument.NotificationID,
        EffectiveFromTimestamp: document.EffectiveFromTimestamp,
        RequestType: "1",
      };
    }
    setLoading(true);
    submitPayload({
      featureId: NotificationFeatureID,
      payload: NOTIFICATION_PAYLOAD,
      edit: Edit,
    }).then((response) => {
      console.log(JSON.stringify(response), "THIS IS RESPONSE");
      const SAVED_DOCUMENT = {
        NotificationID: response.TransactionID,
      };
      saveDocument(SAVED_DOCUMENT);
      setLoading(false);
    });
  }

  return loading ? (
    <div className="Flex">Loading ...</div>
  ) : (
    <Box height={"60vh"} width={"60vw"} position={"relative"}>
      <Box display={"flex"}>
        <Box flex={1} borderRight={"1px solid #8C8C8C"}>
          <RecipientsList
            type={"T"}
            saveRecipients={recipientsChangeHandler}
            initRecipients={document.Recipients}
          />
        </Box>
        <Box flex={1} padding={"0 2em"} maxHeight={"52vh"} className="scroll">
          <Input
            label={"Message"}
            type={"text"}
            value={document.Message}
            onChange={messageChangeHandler}
            rows={7}
            styles={{ margin: 0, marginBottom: "1em", width: "100%" }}
          />
        </Box>
      </Box>
      <Button
        onClick={onSaveHandler}
        title={"Submit"}
        style={{ position: "absolute", bottom: 0, right: 0 }}
      />
    </Box>
  );
};

const WhatsApp = ({
  NotificationChannel,
  NotificationType,
  initDocument,
  saveDocument,
}) => {
  const [document, setDocument] = useState(
    initDocument || {
      NotificationChannel,
      NotificationType,
      Recipients: [],
      SenderType: "AO",
      ContentType: "T",
      Subject: "",
      Message: "",
    }
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initDocument) {
      setLoading(true);
      getData({
        featureId: NotificationFeatureID,
        payload: { "Header.TransactionID": initDocument.NotificationID },
      })
        .then((response) => {
          console.log(JSON.stringify(response), "");
          setDocument(response.Data[0]);
        })
        .catch((error) => {
          console.log(JSON.stringify(error), "");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [initDocument]);

  const messageChangeHandler = (value) => {
    setDocument((prevState) => ({ ...prevState, Message: value }));
  };

  const recipientsChangeHandler = (type, value) => {
    setDocument((prevState) => ({ ...prevState, Recipients: value }));
  };

  function onSaveHandler() {
    const NOTIFICATION_PAYLOAD = {
      NotificationTemplateID: document.NotificationTemplateID || "",
      AuthorFeatureID: document.AuthorFeatureID || "11",
      AuthorFeatureVariantID: document.AuthorFeatureVariantID || "000",
      NotificationChannel: document.NotificationChannel || "",
      WorkflowID: document.WorkflowID || "",
      StepID: document.StepID || "",
      ActionDue: document.ActionDue || "",
      ActionID: document.ActionID || "",
      NotificationType: document.NotificationType || "",
      Recipients: document.Recipients || [
        {
          RecipientType: "",
          RecipientUserType: "",
          RecipientUserTypeValue: "",
        },
      ],
      SenderType: document.SenderType || "",
      ContentType: document.ContentType || "",
      Subject: document.Subject || "",
      Message: document.Message || "",
      TelecomTemplateID: document.TelecomTemplateID || "",
      Priority: document.Priority || "U",
      ActionButtons: document.ActionButtons || [
        {
          CTALayoutID: "",
          CTALabel: "",
          AutoAction: "",
          CTAType: "A",
          AutoActionTime: "",
        },
      ],
      TransactionLayoutID: document.TransactionLayoutID || "123",
    };

    let Edit = null;
    if (initDocument) {
      Edit = {
        TransactionID: initDocument.NotificationID,
        EffectiveFromTimestamp: document.EffectiveFromTimestamp,
        RequestType: "1",
      };
    }
    setLoading(true);
    submitPayload({
      featureId: NotificationFeatureID,
      payload: NOTIFICATION_PAYLOAD,
      edit: Edit,
    }).then((response) => {
      console.log(JSON.stringify(response), "");
      const SAVED_DOCUMENT = {
        NotificationID: response.TransactionID,
      };
      saveDocument(SAVED_DOCUMENT);
      setLoading(false);
    });
  }

  return loading ? (
    <div className="Flex">Loading ...</div>
  ) : (
    <Box height={"60vh"} width={"60vw"} position={"relative"}>
      <Box display={"flex"}>
        <Box flex={1} borderRight={"1px solid #8C8C8C"}>
          <RecipientsList
            type={"T"}
            saveRecipients={recipientsChangeHandler}
            initRecipients={document.Recipients}
          />
        </Box>
        <Box flex={1} padding={"0 2em"} maxHeight={"52vh"} className="scroll">
          <Input
            label={"Message"}
            type={"text"}
            onChange={messageChangeHandler}
            rows={7}
            styles={{ margin: 0, marginBottom: "1em", width: "100%" }}
            value={document.Message}
          />
        </Box>
      </Box>
      <Button
        onClick={onSaveHandler}
        title={"Submit"}
        style={{ position: "absolute", bottom: 0, right: 0 }}
      />
    </Box>
  );
};

const NotificationTabs = ({ type, value, onChange }) => {
  const [documents, setDocuments] = useState(
    value && value.length > 0
      ? value
      : [undefined, undefined, undefined, undefined]
  );

  useEffect(() => {
    console.log("Documents ", documents);
    if (JSON.stringify(value) !== JSON.stringify(documents))
      onChange(documents);
  }, [documents]);

  function saveDocumentByIndex(document, index) {
    setDocuments((prevState) => {
      const newState = [...prevState];
      newState[index] = document;
      console.log(newState, "THIS IS AFTER");
      return newState;
    });
  }

  console.log(JSON.stringify(documents), "Documents are");

  const componentList = [
    {
      label: "Need To Act",
      Component: Need2Act,
      props: {
        NotificationChannel: "N",
        NotificationType: type,
        initDocument: documents[0],
        saveDocument: (document) => saveDocumentByIndex(document, 0),
      },
    },
    {
      label: "Email",
      Component: Email,
      props: {
        NotificationChannel: "E",
        NotificationType: type,
        initDocument: documents[1],
        saveDocument: (document) => saveDocumentByIndex(document, 1),
      },
    },
    {
      label: "SMS",
      Component: SMS,
      props: {
        NotificationChannel: "T",
        NotificationType: type,
        initDocument: documents[2],
        saveDocument: (document) => saveDocumentByIndex(document, 2),
      },
    },
    {
      label: "WhatsApp",
      Component: WhatsApp,
      props: {
        NotificationChannel: "W",
        NotificationType: type,
        initDocument: documents[3],
        saveDocument: (document) => saveDocumentByIndex(document, 3),
      },
    },
  ];

  return <Tabs componentList={componentList} />;
};

const Notification = ({ type, value, onChange }) => {
  const [modalOpen, setModalOpen] = useState(false);
  console.log(
    "Inside notification: ",
    type,
    " ",
    modalOpen,
    " ",
    value,
    " ",
    onChange
  );

  console.log(value, "THESE ARE THE VALUES");
  function getTitle() {
    if (value) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] && value[i].NotificationID) return "Edit Notification";
      }
    }
    return "Add Notification";
  }
  return (
    <Box>
      <Button
        title={getTitle()}
        variant={"text"}
        onClick={() => {
          setModalOpen(true);
        }}
      />
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <NotificationTabs type={type} value={value} onChange={onChange} />
      </Modal>
    </Box>
  );
};

export default Notification;
