import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import GroupAccordian from "./GroupAccordian";
import { Add } from "@mui/icons-material";
import Atomic from "../../../AComponent/Atomic";

const { Button } = Atomic;

const ListView = ({ secondGroups, firstGroups, parameters, cycles }) => {
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {}, [parameters]);

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "1em 0",
        }}
      >
        <Button
          onClick={() => {
            setCollapse(!collapse);
          }}
          title={collapse ? "Expand All" : "Collapse All"}
          variant={"text"}
        />
      </Box>
      {(!secondGroups.list || secondGroups.list.length === 0) &&
      (!firstGroups.list || firstGroups.list.length === 0) &&
      (!parameters.list || parameters.list.length === 0) ? (
        <div className="Flex">
          <Typography variant="h6" fontWeight={"400"} color={"#AAA7AC"}>
            No parametes to display
          </Typography>
        </div>
      ) : (
        <Box style={{ paddingRight: "1em" }}>
          {!secondGroups.disabled && (
            <Box>
              {(secondGroups.list || []).map((group2) => {
                return (
                  <GroupAccordian
                    key={group2.TransactionID || group2.SecondGroupID}
                    TransactionID={group2.TransactionID || group2.SecondGroupID}
                    collapse={collapse}
                    type={"g2"}
                    expandable={true}
                    title={group2.GroupName || group2.SecondGroupName}
                    //  caption={"1 KRA * 2 KPIs"}
                    {...secondGroups.props}
                  >
                    {!firstGroups.disabled &&
                      (firstGroups.list || []).map((group1) => {
                        console.log(parameters, "Inside");
                        return group1.SecondGroupID === group2.TransactionID ? (
                          <GroupAccordian
                            key={group1.TransactionID || group1.FirstGroupID}
                            TransactionID={
                              group1.TransactionID || group1.FirstGroupID
                            }
                            title={group1.GroupName || group1.FirstGroupName}
                            // caption={"1 KRA * 2 KPIs"}
                            collapse={collapse}
                            type={"g1"}
                            expandable={true}
                            {...firstGroups.props}
                          >
                            {(parameters.list || []).map((parameter) => {
                              console.log(
                                parameters.props.missingParameters,
                                "missingParameterAssessments",
                                parameter.ParameterID
                              );
                              return parameter.ParameterGroupID ===
                                group1.TransactionID ? (
                                <GroupAccordian
                                  key={
                                    parameter.TransactionID ||
                                    parameter.ParameterID
                                  }
                                  collapse={collapse}
                                  type={"p"}
                                  expandable={parameters.expandable}
                                  title={parameter.ParameterName}
                                  ParameterDescription={
                                    parameter.ParameterDescription
                                  }
                                  dataError={parameters.props.missingParameters?.includes(
                                    parameter.ParameterID
                                  )}
                                  TransactionID={
                                    parameter.TransactionID ||
                                    parameter.ParameterID
                                  }
                                  // caption={"1 KRA * 2 KPIs"}
                                  {...parameters.props}
                                >
                                  {parameters.expandable && (
                                    <parameters.children.Component
                                      parameter={parameter}
                                      collapse={collapse}
                                      {...parameters.children.props}
                                    />
                                  )}
                                </GroupAccordian>
                              ) : (
                                <></>
                              );
                            })}
                            {parameters.props.permissions.addable && (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  margin: "1em 0",
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    parameters.props.addModalHandler(
                                      group1.TransactionID
                                    );
                                  }}
                                  sx={{
                                    backgroundColor: "#3a82d3",
                                    ":hover": { backgroundColor: "#4091ed" },
                                  }}
                                >
                                  <Add sx={{ color: "white" }} />
                                </IconButton>
                              </Box>
                            )}
                          </GroupAccordian>
                        ) : (
                          <></>
                        );
                      })}
                    {firstGroups.props.permissions.addable && (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          margin: "1em 0",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            firstGroups.props.addModalHandler(
                              group2.TransactionID
                            );
                          }}
                          sx={{
                            backgroundColor: "#3a82d3",
                            ":hover": { backgroundColor: "#4091ed" },
                          }}
                        >
                          <Add sx={{ color: "white" }} />
                        </IconButton>
                      </Box>
                    )}
                  </GroupAccordian>
                );
              })}
            </Box>
          )}
          {secondGroups.disabled && !firstGroups.disabled && (
            <Box>
              {(firstGroups.list || []).map((group1) => {
                return !group1.SecondGroupID ? (
                  <GroupAccordian
                    key={group1.TransactionID || group1.FirstGroupID}
                    collapse={collapse}
                    TransactionID={group1.TransactionID || group1.FirstGroupID}
                    title={group1.GroupName || group1.FirstGroupName}
                    //  caption={"1 KRA * 2 KPIs"}
                    type={"g1"}
                    expandable={true}
                    {...firstGroups.props}
                  >
                    {(parameters.list || []).map((parameter) => {
                      return parameter.ParameterGroupID ===
                        group1.TransactionID ? (
                        <GroupAccordian
                          key={parameter.TransactionID || parameter.ParameterID}
                          type={"p"}
                          collapse={collapse}
                          expandable={parameters.expandable}
                          title={parameter.ParameterName}
                          ParameterDescription={parameter.ParameterDescription}
                          TransactionID={
                            parameter.TransactionID || parameter.ParameterID
                          }
                          dataError={parameters.props.missingParameters?.includes(
                            parameter.ParameterID
                          )}
                          //   caption={"1 KRA * 2 KPIs"}
                          {...parameters.props}
                        >
                          {parameters.expandable && (
                            <parameters.children.Component
                              parameter={parameter}
                              collapse={collapse}
                              {...parameters.children.props}
                            />
                          )}
                        </GroupAccordian>
                      ) : (
                        <></>
                      );
                    })}
                    {parameters.props.permissions.addable && (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          margin: "1em 0",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            parameters.props.addModalHandler(
                              group1.TransactionID
                            );
                          }}
                          sx={{
                            backgroundColor: "#3a82d3",
                            ":hover": { backgroundColor: "#4091ed" },
                          }}
                        >
                          <Add sx={{ color: "white" }} />
                        </IconButton>
                      </Box>
                    )}
                  </GroupAccordian>
                ) : (
                  <></>
                );
              })}
            </Box>
          )}
          {secondGroups.disabled && firstGroups.disabled && (
            <Box>
              {(parameters.list || []).map((parameter) => {
                return (
                  <GroupAccordian
                    key={parameter.TransactionID || parameter.ParameterID}
                    type={"p"}
                    expandable={parameters.expandable}
                    title={parameter.ParameterName}
                    collapse={collapse}
                    TransactionID={
                      parameter.TransactionID || parameter.ParameterID
                    }
                    dataError={parameters.props.missingParameters?.includes(
                      parameter.ParameterID
                    )}
                    ParameterDescription={parameter.ParameterDescription}
                    //      caption={"1 KRA * 2 KPIs"}
                    {...parameters.props}
                  >
                    {parameters.expandable && (
                      <parameters.children.Component
                        parameter={parameter}
                        collapse={collapse}
                        {...parameters.children.props}
                      />
                    )}
                  </GroupAccordian>
                );
              })}
            </Box>
          )}
        </Box>
      )}
      {!secondGroups.disabled && secondGroups.props.permissions.addable && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            margin: "1em 0",
          }}
        >
          <IconButton
            onClick={() => secondGroups.props.addModalHandler()}
            sx={{
              backgroundColor: "#3a82d3",
              ":hover": { backgroundColor: "#4091ed" },
            }}
          >
            <Add sx={{ color: "white" }} />
          </IconButton>
        </Box>
      )}
      {secondGroups.disabled &&
        !firstGroups.disabled &&
        firstGroups.props.permissions.addable && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              margin: "1em 0",
            }}
          >
            <IconButton
              onClick={() => firstGroups.props.addModalHandler()}
              sx={{
                backgroundColor: "#3a82d3",
                ":hover": { backgroundColor: "#4091ed" },
              }}
            >
              <Add sx={{ color: "white" }} />
            </IconButton>
          </Box>
        )}
      {secondGroups.disabled &&
        firstGroups.disabled &&
        parameters.props.permissions.addable && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              margin: "1em 0",
            }}
          >
            <IconButton
              onClick={() => parameters.props.addModalHandler()}
              sx={{
                backgroundColor: "#3a82d3",
                ":hover": { backgroundColor: "#4091ed" },
              }}
            >
              <Add sx={{ color: "white" }} />
            </IconButton>
          </Box>
        )}
    </Box>
  );
};

export default ListView;
