import { Groups } from "../Data/Groups";
import { Parameters } from "../Data/Parameters";
import { SelectGroup } from "../Modals/AddGroup";

export const extractDimensionStructure = (dimensionStructure) => {
  const [group2, group1, parameter] = dimensionStructure.split("");
  return {
    group2,
    group1,
    parameter,
  };
};

export const extractDiscardRules = (discardRule) => {
  const [group2, group1, parameter] = discardRule.split("");
  return {
    group2,
    group1,
    parameter,
  };
};

export const extractAddRules = (addRule) => {
  const [group2, group1, parameter] = addRule.split("");
  return {
    group2,
    group1,
    parameter,
  };
};

export const extractWeightRules = (weightRule) => {
  console.log("Weight rule",weightRule)
  const [group2, group1, parameter] = weightRule.split("");
  return {
    group2,
    group1,
    parameter,
  };
};

export const extractTargetRules = (targetRule) => {
console.log("Target rule: ",targetRule)
  const enabled = targetRule[0];
  const mapped = targetRule[3];
  const targetType = targetRule.substring(1, 3);
  return { enabled, targetType, mapped };
};

export const getGroupLibrary = (level) => {
  const groups = Groups.map((group) =>
    group.GroupLevel === level
      ? { label: group.GroupName, value: group.DocumentID }
      : 0
  );

  return groups.filter((group) => group !== 0);
};

export const getParameterLibrary = () => {
  return Parameters.map((param) => ({
    label: param.ParameterName,
    value: param.DocumentID,
  }));
};

export const getTableHeading = (type) => {
  switch (type) {
    case "g2":
      return ["Select", "Group Name"];
    case "g1":
      return ["Select", "Group Name"];
    case "p":
      return ["Select", "Parameter Name"];
    default:
      return [];
  }
};
export const isEnabled = (value) => value === "E";
export const isDisabled = (value) => value === "D";
export const isUserDefined = (value) => value === "U";
export const isPermitted = (value) => value === "P";
export const isRestricted = (value) => value === "R";
export const isNA = (value) => value === "N";
export const isNoWeights = (value) => value === "N";
export const isAggregated = (value) => value === "A";
export const isDefinedInMap = (value) => value === "D";
export const isMapped = (value) => value === "M";
export const isMappedEditable = (value) => value === "E";
export const isLibrary = (value) => value === "L";
