import React from 'react';
import AImg1 from './AImg1';

function AIconButton(){
    return(
        <div>
            <button id="convob">
                <AImg1 imgUrl={"Send"}/>
            </button>
        </div>
    );
}

export default AIconButton;