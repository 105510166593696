import React, { useState } from 'react'
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from 'react-floating-button-menu'
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import ChatIcon from '@mui/icons-material/Chat';
import Gateway from './Api/Gateway';
import {useDispatch} from "react-redux"

function FLoatAction() {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch=useDispatch();

 function fetchLayout(endPt){
    Gateway({},{},endPt,'GET').then((res)=>{
        dispatch({
            type:"SET",
            payload:res
        })
    })
 }

  return (
    <>
      <FloatingMenu
        slideSpeed={500}
        direction="up"
        spacing={8}
        isOpen={isOpen}
      >
        <MainButton
          iconResting={<AddIcon style={{ fontSize: 20 }} nativeColor="white" />}
          iconActive={<CloseIcon style={{ fontSize: 20 }} nativeColor="white" />}
          backgroundColor="black"
          onClick={() => setIsOpen(!isOpen)}
          size={56}
          background="linear-gradient(45deg, rgb(254, 107, 139) 30%, rgb(255, 142, 83) 90%);"
        />
        <ChildButton
          icon={<HomeIcon style={{ fontSize: 20,color:'white' }} nativeColor="white" />}
          backgroundColor="white"
          size={40}
          onClick={() => fetchLayout("/homepage")}
          background="linear-gradient(45deg, rgb(254, 107, 139) 30%, rgb(255, 142, 83) 90%);"
        />
        <ChildButton
          icon={<SettingsIcon style={{ fontSize: 20,color:'white' }} nativeColor="white" />}
          backgroundColor="white"
          size={40}
          onClick={()=>fetchLayout("/fetchModal")}
          background="linear-gradient(45deg, rgb(254, 107, 139) 30%, rgb(255, 142, 83) 90%);"
        />
        <ChildButton
          icon={<ChatIcon style={{ fontSize: 20,color:'white' }} nativeColor="white" />}
          backgroundColor="white"
          size={40}
          onClick={()=>fetchLayout("/layout2")}
          background="linear-gradient(45deg, rgb(254, 107, 139) 30%, rgb(255, 142, 83) 90%);"
        />
      </FloatingMenu>
    </>
  )
}

export default FLoatAction
