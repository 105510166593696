import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import LayoutRenderer from "../Interpreter/LayoutRenderer";
// import { getLayout } from "../Utils/Validators/library";
// import past from "../Testjson/Form/pastAttendance.json";

function Landing({ tk, layout_id }) {
  // const layout = useSelector((state) => {
  //   console.log("From Reducer landing", state);
  //   return state.LayoutReducer.Layout;
  // });
  // const modal = useSelector((state) => {
  //   return state.LayoutReducer.Modal;
  // });

  const dispatch = useDispatch();
  useEffect(() => {
    // if (layout_id === "past") {
    //   dispatch({
    //     type: "SET",
    //     payload: past,
    //   });
    // } else
    // if (layout_id) {
    //   getLayout({ FeatureID: layout_id });
    // }
  }, []);

  return (
    <>
      <h1>Need To Act</h1>
      {/* <LayoutRenderer Data={modal} />
      <LayoutRenderer Data={layout} /> */}
    </>
  );
}

export default Landing;
