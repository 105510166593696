import {
  Autocomplete,
  Box,
  Chip,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import DataGrid2 from "../MComponent/Table/DataGrid2";

function PlanProgressTrackerDashboard({ queryResults }) {
  const [chartData, setChartData] = useState([]);
  const [records, setRecords] = useState(queryResults);
  const [selectedFilters, setSelectedFilters] = useState({
    function: "All",
    location: "All",
    grade: "All",
    role: "All",
    currentStatus: "All",
  });
  const [availableFilters, setAvailableFilters] = useState({
    functions: [],
    locations: [],
    roles: [],
    grades: [],
  });

  useEffect(() => {
    const graphWorker = new Worker("dashboard.worker.js");
    graphWorker.postMessage({
      module: "PPTD",
      type: "CREATE_RECORDS",
      filters: selectedFilters,
      records: queryResults,
    });
    graphWorker.onmessage = (event) => {
      const data = event.data;
      setChartData(data.results);
      setAvailableFilters({
        functions: data.functions,
        locations: data.locations,
        roles: data.roles,
        grades: data.grades,
      });
    };
    return () => {
      graphWorker.terminate();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResults]);

  const COLORS = ["#00C49F", "#FFBB28", "#FF8042"];
  const renderCustomizedLabel = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          style={{ outline: "none", pointerEvents: "none" }}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
          style={{ outline: "none", pointerEvents: "none" }}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
          style={{ pointerEvents: "none" }}
        />
        <circle
          cx={ex}
          cy={ey}
          r={2}
          fill={fill}
          stroke="none"
          style={{ pointerEvents: "none" }}
        />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
          style={{ outline: "none", pointerEvents: "none" }}
        >{`${payload.currentStatus}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
          style={{ outline: "none", pointerEvents: "none" }}
        >
          {`${
            payload.currentStatus === "No Data Available"
              ? 0
              : payload.numberOfEmployees
          } employees`}
        </text>
      </>
    );
  };

  function applyFiltersToRecords(currentFilters, currentRecords) {
    const graphWorker = new Worker("dashboard.worker.js");
    graphWorker.postMessage({
      module: "PPTD",
      type: "FILTER_RECORDS",
      filters: currentFilters,
      records: currentRecords,
    });
    graphWorker.onmessage = (event) => {
      const { results, filteredRecords } = event.data;
      if (results.length === 0) {
        results.push({
          currentStatus: "No Data Available",
          numberOfEmployees: 1,
          records: [],
        });
      }
      setChartData(results);
      setRecords(filteredRecords);
    };
  }

  return (
    <Box>
      <Box
        sx={{ display: "flex", gap: "1em", justifyContent: "space-between" }}
      >
        <Box sx={{ height: "35vh", width: "60%" }}>
          <ResponsiveContainer
            width="100%"
            height="100%"
            style={{ outline: "none" }}
          >
            <PieChart style={{ outline: "none" }}>
              <Pie
                style={{ outline: "none" }}
                data={chartData}
                cx="50%"
                cy="45%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={100}
                fill="#8884d8"
                dataKey="numberOfEmployees"
                onClick={({ payload }, flag, event) => {
                  setSelectedFilters((prevState) => {
                    const newFilters = {
                      ...prevState,
                      currentStatus: payload.currentStatus,
                    };
                    applyFiltersToRecords(newFilters, queryResults);
                    return newFilters;
                  });
                }}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      entry.currentStatus === "No Data Available"
                        ? "#9e9e9e"
                        : COLORS[index % COLORS.length]
                    }
                    style={{ cursor: "pointer" }}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Box>
        <Box
          sx={{
            width: "25%",
            height: "350px",
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            gap: "1em",
            alignItems: "center",
          }}
          className="scroll"
        >
          <Box>
            <Typography variant="form_text1">Filter By Function</Typography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              options={["All", ...availableFilters.functions]}
              sx={{ width: "15em", marginTop: "0.5em" }}
              onChange={(event, value) => {
                if (value === -1) return;
                setSelectedFilters((prevState) => {
                  const newFilters = {
                    ...prevState,
                    function: value,
                  };
                  applyFiltersToRecords(newFilters, queryResults);
                  return newFilters;
                });
              }}
              value={selectedFilters.function}
              renderInput={(params) => <TextField {...params} />}
              disableClearable={true}
            />
          </Box>
          <Box>
            <Typography variant="form_text1">Filter By Location</Typography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              options={["All", ...availableFilters.locations]}
              sx={{ width: "15em", marginTop: "0.5em" }}
              onChange={(event, value) => {
                if (value === -1) return;
                setSelectedFilters((prevState) => {
                  const newFilters = {
                    ...prevState,
                    location: value,
                  };
                  applyFiltersToRecords(newFilters, queryResults);
                  return newFilters;
                });
              }}
              value={selectedFilters.location}
              renderInput={(params) => <TextField {...params} />}
              disableClearable={true}
            />
          </Box>
          <Box>
            <Typography variant="form_text1">Filter By Grade</Typography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              options={["All", ...availableFilters.grades]}
              sx={{ width: "15em", marginTop: "0.5em" }}
              onChange={(event, value) => {
                if (value === -1) return;
                setSelectedFilters((prevState) => {
                  const newFilters = {
                    ...prevState,
                    grade: value,
                  };
                  applyFiltersToRecords(newFilters, queryResults);
                  return newFilters;
                });
              }}
              value={selectedFilters.grade}
              renderInput={(params) => <TextField {...params} />}
              disableClearable={true}
            />
          </Box>
          <Box>
            <Typography variant="form_text1">Filter By Role</Typography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="small"
              options={["All", ...availableFilters.roles]}
              sx={{ width: "15em", marginTop: "0.5em" }}
              onChange={(event, value) => {
                if (value === -1) return;
                setSelectedFilters((prevState) => {
                  const newFilters = {
                    ...prevState,
                    role: value,
                  };
                  applyFiltersToRecords(newFilters, queryResults);
                  return newFilters;
                });
              }}
              value={selectedFilters.role}
              renderInput={(params) => <TextField {...params} />}
              disableClearable={true}
            />
          </Box>
        </Box>
      </Box>
      <br />
      <Box
        sx={{
          width: "100%",
          padding: "1em",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "1em",
        }}
      >
        <Typography variant="form_h1">Applied Filters: </Typography>
        {Object.values(selectedFilters).filter((val) => val === "All")
          .length === 5 && (
          <Typography variant="form_text1">No filters selected</Typography>
        )}
        {selectedFilters.function !== "All" && (
          <Chip
            label={`Function: ${selectedFilters.function}`}
            onDelete={() => {
              setSelectedFilters((prevState) => {
                const newFilters = {
                  ...prevState,
                  function: "All",
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            variant="outlined"
            color="primary"
          />
        )}
        {selectedFilters.location !== "All" && (
          <Chip
            label={`Location: ${selectedFilters.location}`}
            onDelete={() => {
              setSelectedFilters((prevState) => {
                const newFilters = {
                  ...prevState,
                  location: "All",
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            variant="outlined"
            color="primary"
          />
        )}
        {selectedFilters.grade !== "All" && (
          <Chip
            label={`Grade: ${selectedFilters.grade}`}
            onDelete={() => {
              setSelectedFilters((prevState) => {
                const newFilters = {
                  ...prevState,
                  grade: "All",
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            variant="outlined"
            color="primary"
          />
        )}
        {selectedFilters.role !== "All" && (
          <Chip
            label={`Role: ${selectedFilters.role}`}
            onDelete={() => {
              setSelectedFilters((prevState) => {
                const newFilters = {
                  ...prevState,
                  role: "All",
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            variant="outlined"
            color="primary"
          />
        )}
        {selectedFilters.currentStatus !== "All" && (
          <Chip
            label={`Current Status: ${selectedFilters.currentStatus}`}
            onDelete={() => {
              setSelectedFilters((prevState) => {
                const newFilters = {
                  ...prevState,
                  currentStatus: "All",
                };
                applyFiltersToRecords(newFilters, queryResults);
                return newFilters;
              });
            }}
            variant="outlined"
            color="primary"
          />
        )}
      </Box>
      <br />
      <Paper sx={{ boxSizing: "border-box", height: "100%" }}>
        <DataGrid2
          heading={[
            "Current Status",
            "Employee Code",
            "Employee Fullname",
            "Appraiser Code",
            "Function",
            "Grade",
            "Location",
            "Role",
            "Plan Name",
            "Plan Period",
            "Plan Action",
            "Plan Review Period",
            "Action Date",
          ]}
          rowsData={records}
          minColumnWidth={300}
          rowToFieldMap={[
            "Current Status",
            "Employee Code",
            "Employee Full Name",
            "Appraiser Code",
            "Function",
            "Grade",
            "Location",
            "Role",
            "Plan Name",
            "Plan Period",
            "Plan Actions",
            "Plan Review Period",
            "Action Date",
          ]}
        />
        <br />
        <br />
      </Paper>
    </Box>
  );
}

export default PlanProgressTrackerDashboard;
