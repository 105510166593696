import React from "react";
import { Paper, Typography } from "@mui/material";

function LocationIcon() {
  return (
    <>
      <svg
        width="9"
        height="12"
        viewBox="0 0 9 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.00968 2.38676C6.37216 1.54908 5.39713 1.07422 4.34709 1.07422C3.29706 1.07422 2.32203 1.54908 1.68451 2.38676C0.77183 3.5868 0.77183 5.24948 1.68451 6.44952L4.34709 9.9245L7.00968 6.44952C7.92236 5.24948 7.92236 3.5868 7.00968 2.38676ZM4.34709 2.58692C5.30947 2.58692 6.09702 3.37444 6.09702 4.33685C6.09702 5.29925 5.3095 6.08678 4.34709 6.08678C3.38469 6.08678 2.59716 5.29925 2.59716 4.33685C2.59716 3.37444 3.38469 2.58692 4.34709 2.58692Z"
          fill="#6A6A6A"
          stroke="#6A6A6A"
          stroke-width="1.38893"
          stroke-miterlimit="10"
        />
      </svg>
    </>
  );
}

function EmployeeInfo(props) {
  const { borderName, name, designation, id, location } = props;
  return (
    <Paper
      style={{
        height: 81,
        width: 306,
        borderRadius: 12,
        border: "1px solid #C2C8BD",
        position: "relative",
      }}
    >
      {borderName && (
        <div
          style={{
            position: "absolute",
            top: -15,
            zIndex: 10,
            left: 5,
            backgroundColor: "white",
          }}
        >
          Appraisee
        </div>
      )}
      <div
        className="Flex-row"
        style={{ justifyContent: "flex-start", height: "100%" }}
      >
        <div className="Flex" style={{ flex: 0.2 }}>
          <img
            src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?cs=srgb&dl=pexels-pixabay-220453.jpg&fm=jpg"
            height={40}
            width={40}
            style={{ borderRadius: "50%" }}
          />
        </div>
        <div style={{ flex: 0.8 }}>
          <div
            className="Flex"
            style={{ alignItems: "flex-start", marginLeft: 5 }}
          >
            <div
              className="Flex-row"
              style={{ justifyContent: "space-between", width: "100%" }}
            >
              {" "}
              <Typography style={{  fontWeight: "600" }}>
                {name}
              </Typography>
              <Typography
                variant="caption"
                style={{ color: "#36454F", marginRight: 10 }}
              >
                {id}
              </Typography>
            </div>
            <Typography style={{ fontSize: 12,  }}>
              {designation}
            </Typography>
            <Typography
              style={{
                fontSize: 10,
                color: "#6A6A6A",
              }}
            >
              <LocationIcon />
              {location}
            </Typography>
          </div>
        </div>
        {/* <div
          className="Flex"
          style={{
            flex: 0.3,
            justifyContent: "flex-start",
            height: "100%",
            marginTop: 5,
          }}
        >
          <Typography variant='caption' style={{ color: "#36454F" }}>#123</Typography>
        </div> */}
      </div>
    </Paper>
  );
}

export default EmployeeInfo;
