import React from 'react';


function ADate1( {label, date} ){

    return(
        <div>
            {
                label ? <p>{label} : {date.date}-{date.month}-{date.year}</p> : <div>{date.date}-{date.month}-{date.year}</div>
            }
            
        </div>
    );
}

export default ADate1;