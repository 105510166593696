export const conditionsData = [
  {
    label: "Applicability",
    value: "ApplicabilityID",
  },
  {
    label: "Age",
    value: "AgeCondition",
  },
  {
    label: "Gender",
    value: "GenderCondition",
  },
  {
    label: "Tenure",
    value: "TenureCondition",
  },
  // {
  //   label: "Confirmation Status",
  //   value: "ConfirmationStatusCondition",
  // },
  // {
  //   label: "Joining Stage",
  //   value: "JoiningStageCondition",
  // },
  // {
  //   label: "Separation Stage",
  //   value: "SeparationStageCondition",
  // },
  {
    label: "Transcation Data",
    value: "TransactionDataCondition",
  },
];

export const constants = {
  Applicability: "ApplicabilityID",
  Age: "AgeCondition",
  Gender: "GenderCondition",
  Tenure: "TenureCondition",
  CS: "ConfirmationStatusCondition",
  JS: "JoiningStageCondition",
  SS: "SeparationStageCondition",
  TD: "TransactionDataCondition",
  default: "Default",
  "-1": "Default",
};

export const stepOwners = [
  {
    label: "Subject User",
    value: "SU",
  },
  {
    label: "Transaction User",
    value: "TU",
  },
  {
    label: "Line Manager",
    value: "LM",
  },
  {
    label: "Functional Manager",
    value: "FM",
  },
  {
    label: "Legal Entity Head",
    value: "EH",
  },
  {
    label: "Business Unit Head",
    value: "BH",
  },
  {
    label: "Function Head",
    value: "FH",
  },
  {
    label: "Location Head",
    value: "LH",
  },
  {
    label: "Organisation Role",
    value: "OR",
  },
  {
    label: "Employee Role",
    value: "ER",
  },
  {
    label: "Employee Role In Location",
    value: "RL",
  },

  {
    label: "Employee Role In LegalEntity",
    value: "EL",
  },
  {
    label: "Employee Role In Business Unit",
    value: "EB",
  },
  {
    label: "Manager In Grade",
    value: "ManagerInGrade",
  },
  {
    label: "Role In Grade",
    value: "LG",
  },
  {
    label: "Team",
    value: "TE",
  },
];

export const actions = [
  {
    label: "Submit",
    value: "submit",
  },
];
