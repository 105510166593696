import React, { useState, useEffect, useRef } from "react";
import UploadIcon from "@mui/icons-material/Upload";
// import { csvToRaw } from "../../Utils/Common/Library";
import "./Uploader.css";

const initialProps = {
  onUpload: null, // will be called once file is selected
  multiple: null, //if multiple files are allowed or not
  allowedFiles: null, //array of allowed file extension
  fileOnly: null, // if true will return just a file not json/string
  raw: null, //if true will return a text and not table (will be no use if fileonly is set to true)
};

function FileUpload(props = initialProps) {
  var { onUpload, multiple, allowedFiles, fileOnly, raw } = props;
  const drop = useRef(null);
  const fileInp = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  if (!allowedFiles) {
    allowedFiles = new Set();
    allowedFiles.add("text/csv");
  } else {
    allowedFiles = new Set(allowedFiles);
  }

  useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);
    drop.current.addEventListener("dragenter", handleDragEnter);
    drop.current.addEventListener("dragleave", handleDragLeave);

    return () => {
      drop.current?.removeEventListener("dragover", handleDragOver);
      drop.current?.removeEventListener("drop", handleDrop);
      drop.current?.removeEventListener("dragenter", handleDragEnter);
      drop.current?.removeEventListener("dragleave", handleDragLeave);
    };
  }, []);
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const uploadFile = (files) => {
    console.log("Inside Fileupload: ", files);
    setError(null);
    if (files && files.length) {
      let ext = files[0].type;
      console.log("Inside Fileupload: ", ext, " ", allowedFiles);
      if (!allowedFiles.has(ext)) {
        setError("Only CSV Files Allowed");
        setLoading(false);
        return;
      }
      multiple ? onUpload(files) : onUpload(files[0]);
      // if (fileOnly) {
      //   multiple ? onUpload(files) : onUpload(files[0]);
      // } else {
      //   csvToRaw({ file: files[0], cb: (data) => onUpload(data),raw });
      // }
    }
    setLoading(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    setLoading(true);
    const { files } = e.dataTransfer;
    uploadFile(files);
  };
  return (
    <div className="FilesDragAndDrop Flex">
      <div
        onClick={() => fileInp.current.click()}
        ref={drop}
        style={{
          backgroundColor: dragging ? "#ECE8DD" : "white",
          cursor: "pointer",
        }}
        className="FilesDragAndDrop__area"
      >
        <input
          ref={fileInp}
          type="file"
          id="upload"
          style={{ display: "none" }}
          onChange={(e) => uploadFile(e.target.files)}
          multiple={multiple}
        />
        {error && <span style={{ color: "red" }}>{error}</span>}
        {!loading ? (
          <>
            Upload CSV File
            <UploadIcon sx={{ fontSize: 50 }} />
          </>
        ) : (
          <>Loading...</>
        )}
      </div>
    </div>
  );
}

export default FileUpload;
