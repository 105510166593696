import React from "react";
import { Button } from "@mui/material";

function UploadButton(props) {
  const { label, multiple, onChange } = props;
  return (
    <div>
      <Button variant="contained" component="label">
        {label ? label : "Upload"}
        <input
          hidden
          accept="image/*"
          onChange={onChange}
          multiple={multiple}
          type="file"
        />
      </Button>
    </div>
  );
}

export default UploadButton;
