import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Evaluate from "../../Utils/Validators/ExpressionHandler";
import useRender from "../utils/useRender";

function Text_2(props) {
  const { id, compNumber } = props;
  const dispatch = useDispatch();
  const {
    textStyle,
    value,
    heading,
    textContainerStyle,
    label,
    className,
    renderNot,
    Validation,
  } = useSelector((state) => {
    try {
      return JSON.parse(JSON.stringify(state.DataReducer[id][compNumber]));
    } catch (e) {
      return {};
    }
  });
  if (value?.split("Expression").length > 1) {
    Evaluate(value).then((res) => {
      dispatch({
        type: "UPDATE_PROPS",
        payload: {
          id,
          componentNumber: compNumber,
          data: res,
          props: "value",
        },
      });
    });
  }
  // if(Validation){
  //   Evaluate(Validation,"Atreya")
  // }

  console.log("From text: ", label);
  function validate(str) {
    if (!str) return false;
    if (str === "undefined" || str === "NaN" || str === "null") return false;

    return true;
  }
  if (!useRender({ id, compNumber })) return <></>;
  if (heading) {
    return (
      <div style={{ textContainerStyle }} className={className}>
        <h4 style={textStyle}>{value}</h4>
      </div>
    );
  }
  return (
    <>
      <div className={className} style={textContainerStyle}>
        <span style={textStyle}>{validate(label) ? label : ""}</span>
        <span>{validate(value) ? value : ""}</span>
      </div>
    </>
  );
}

export default Text_2;
