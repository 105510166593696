import React, { useState, useEffect } from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { useSelector, useDispatch } from "react-redux";
import setLayout from "../../Utils/setLayout";
import { v4 as uuid } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EWMenu from "../Menu/Menu";

const initialProps = {
  data: null,
  treeStyle: null,
  iconType: null,
};
function Tree(props = initialProps) {
  var { data, treeStyle, iconType, onRightClick } = props;
  const [expanded, setExpanded] = useState(new Set());
  const [anchor, setAnchor] = useState(null);
  const dispatch = useDispatch();
  if (!treeStyle) treeStyle = {};
  var Collpase = <ExpandMoreIcon />;
  var Expand = <ChevronRightIcon />;

  if (iconType !== null || iconType !== undefined) {
    if (iconType === 0) {
      Collpase = <RemoveIcon style={{ color: "#8EA7E9" }} />;
      Expand = <AddIcon style={{ color: "#8EA7E9" }} />;
    }
  }
  function treeClicked(clickObj, id) {
    console.log("clicked tree", id);
    setExpanded((prev) => {
      let tempSet = new Set(prev);
      if (tempSet.has(id)) {
        tempSet.delete(id);
      } else {
        tempSet.add(id);
      }
      return tempSet;
    });
    if (!clickObj) {
      return;
    }
    let { type, args } = clickObj;
    if (type === 0) {
      setLayout(args.layout);
    }
    if(type===1){
      console.log("Function : ", args.callParent)

      args.callParent()
    }
  }
  function rightClick(e) {
    // if(!onRightClick)return;
    e.preventDefault();
    console.log("Target is : ", e.target);
    setAnchor(e.target);
  }
  function handleDragStart(e) {
    console.log(e);
   //this.style.opacity = "0.4";
  }

  function handleDragEnd(e) {
    //this.style.opacity = "1";
  }
  function renderItemList(list, parentIndex) {
    return (
      <>
        {list?.map((ele, index) => {
          //   let id ="tree-"+ parentIndex+index;
          let id = ele.label;
          console.log("ids: ", id, " ", ele.label);
          if (ele?.children)
            return (
              <TreeItem
                key={parentIndex + index}
                onContextMenu={rightClick}
                //   nodeId={parentIndex + index}
                onClick={() => treeClicked(ele?.onClick, id)}
                nodeId={id}
                label={ele.label}
              >
                {renderItemList(ele.children, index)}
              </TreeItem>
            );
          return (
            <div
              draggable
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
            >
               
            
              <TreeItem
                key={parentIndex + index}
                onContextMenu={rightClick}
                // nodeId={parentIndex + index}
                onClick={() => treeClicked(ele?.onClick, id)}
                nodeId={id}
                label={ele.label}
              />
            </div>
          );
        })}
      </>
    );
  }
  function handleChange(event, nodes) {
    // setExpanded(new Set(nodes));
    console.log(event);
  }
  return (
    <div>
        {/* <div>
        <div draggable="true" onDragStart={handleDragStart}>
                Open console and drag me!
            </div>
        </div> */}
      <EWMenu
        anchor={anchor}
        menuList={[
          {
            label: "Edit",
          },
        ]}
        onMenuSelect={(value) => {
          setAnchor(null);
        }}
      />
      {console.log("Expanded: ", [...expanded])}
      <TreeView
        className="scroll"
        aria-label="Tree View"
        //  defaultCollapseIcon={<ExpandMoreIcon />}
        //  defaultExpandIcon={<ChevronRightIcon />}
        defaultCollapseIcon={Collpase}
        defaultExpandIcon={Expand}
        expanded={[...expanded]}
        // onNodeToggle={handleChange}
        sx={{
          height: 600,
          flexGrow: 1,
          maxWidth: 400,
          overflowY: "auto",
          overflowX: "hidden",
          ...treeStyle,
        }}
      >
        {renderItemList(data, 0)}
      </TreeView>
    </div>
  );
}

export default Tree;
