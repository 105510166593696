import React from'react';
import AChatWindow from '../Atmoic/AChatWindow';
import AIconButton from '../Atmoic/AIconButton';
import ATextfield from '../Atmoic/ATextfield';

function EWModalConvo(){
    return(
        <div>
            <AChatWindow/>
            <div className="ewmodalconvorow2">
                <div>
                    <ATextfield/>
                </div>
                <div>
                    <AIconButton/>
                </div>
            </div>
        </div>
    );
}

export default EWModalConvo;