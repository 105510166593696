export const CoachingDocument = {
  SectionLabel: "Coaching",
  SectionDescription: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
    Proin vitae risus augue. Sed feugiat turpis vel euismod laoreet. 
    Morbi magna dolor, tincidunt quis quam nec, vehicula consectetur augue.`,
  DataColumns: {
    Dimension: "D",
    SecondGroups: "D",
    FirstGroups: "D",
    Parameter: "D",
    CoachingArea: "D",
    CoachingPlan: "D",
    CoachingMilestone: "E",
    CoachingActionUpdate: "E",
    DueDate: "E",
    CoachingProgress: "E",
  },
};
