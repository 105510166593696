import React, { useEffect } from "react";
import Actions from "./Tabs/Actions";
import Data from "./Tabs/Data";
import Alerts from "./Tabs/Alerts";
import StepReminders from "./Tabs/StepReminders";
import StepEscalations from "./Tabs/StepEscalations";
import Molecule from "../../../MComponent/Molecule";
import { Box, Typography } from "@mui/material";
import Atomic from "../../../AComponent/Atomic";
import useReduxStore from "../../../Utils/CustomHooks/ReduxUpdater";

const { Button } = Atomic;
const { Tabs } = Molecule;

function StepFormModal({ StepData, onSaveStepData }) {
  StepData = JSON.parse(JSON.stringify(StepData));

  console.log(StepData, "STEPDATAISGHERELAMOO");
  const [state, setState] = useReduxStore({
    id: `StepFormModal-${StepData.StepID}`,
    initialState: {
      data: {
        StepID: StepData.StepID || "",
        StepName: StepData.StepName || "",
        StepTAT: StepData.StepTAT || "",
        StepTATUnit: StepData.StepTATUnit || "",
        StepScenarios: StepData.StepScenarios || [],
        StepNotifications: StepData.StepNotifications || [],
      },
    },
  });

  function updateState(value, type) {
    setState({ ...state.data, [type]: value }, "data");
  }

  useEffect(() => {
    setState(
      {
        StepID: StepData.StepID || "",
        StepName: StepData.StepName || "",
        StepTAT: StepData.StepTAT || "",
        StepTATUnit: StepData.StepTATUnit || "",
        StepScenarios: StepData.StepScenarios || [],
        StepReminders: StepData.StepReminders || [],
        StepEscalations: StepData.StepEscalations || [],
      },
      "data"
    );
  }, []);

  const componentList = [
    {
      label: "Actions",
      Component: Actions,
      props: {
        StepTAT: state.data.StepTAT,
        StepTATUnit: state.data.StepTATUnit,
        StepScenarios: state.data.StepScenarios,
        updateState,
      },
      disable: false,
    },
    {
      label: "Data",
      Component: Data,
      props: {},
      disable: false,
    },
    {
      label: "Alerts",
      Component: Alerts,
      props: {
        StepScenarios: state.data.StepScenarios,
        updateState,
      },
      disable: false,
    },
    {
      label: "Step Reminders",
      Component: StepReminders,
      props: {
        StepReminders: state.data.StepReminders,
        updateState,
      },
      disable: false,
    },
    {
      label: "Step Escalations",
      Component: StepEscalations,
      props: { StepEscalations: state.data.StepEscalations, updateState },
      disable: false,
    },
  ];

  return (
    <>
      <Box width={"80vw"} height={"90vh"} position={"relative"}>
        <Typography variant="h3" fontWeight={"600"}>{`<${
          state.data.StepName || "Step 1"
        }>`}</Typography>
        <br />
        <Tabs componentList={[...componentList]} />
        <Box position={"absolute"} bottom={"1em"} right={"1em"}>
          <Button
            label={"Save"}
            onClick={() => {
              onSaveStepData(state.data);
            }}
            title={"Save"}
          />
        </Box>
      </Box>
    </>
  );
}

export default StepFormModal;
