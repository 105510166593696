import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
//import PhotoCamera from '@mui/icons-material/PhotoCamera'
import Stack from '@mui/material/Stack'
import useRender from '../utils/useRender'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import store from '../../Redux/Store'


const Input = styled('input')({
  display: 'none',
})

export default function UploadButtons({ id, compNumber }) {
  // const [file, setFile] = useState(null)
  const { value, displayValue, label } = useSelector(
    (state) => state.DataReducer[id][compNumber],
  )

  const dispatch = useDispatch()
  const [fileDetails, setFileDetails] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [err,setError]=useState(null)
  function fileUpload(e) {
    setFileDetails(e.target.files[0])
    // setFile(URL.createObjectURL(e.target.files[0]))
    callDispatcher(URL.createObjectURL(e.target.files[0]))
    onSave(e.target.files[0])
  }
  function callDispatcher(data) {
    dispatch({
      type: 'UPDATE_PROPS',
      payload: {
        id,
        componentNumber: compNumber,
        data: data,
        props: 'displayValue',
      },
    })
  }

  function onSave(fileDetails) {
    setError(null);
    setUploading(true)
    let template = {
      tenantID: '1641969495669',
      featureID: '10107',
      featureVariantID: '000',
      documentCategory: 'Education',
      documentType: 'jpg',
      documentName: 'marksheet',
    }
    console.log('From image upload: ', fileDetails)
    template.documentName = fileDetails.name
    template.documentType = fileDetails.type
    var formData = new FormData()
    formData.append('file', fileDetails)
    template = JSON.stringify(template)
    formData.append('template', template)
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1])
    }
    // withCredentials: true,
    //   crossdomain: true,
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
        Authorization: `Bearer ${store.getState().AuthReducer.Token}`,
      },
    }

    console.log('From image upload: \n', formData)
    //http://functionhandler-env.eba-nsef3vus.ap-south-1.elasticbeanstalk.com/functionHandler/uploaddocument
    //http://localhost:5000/upload
    axios
      .post(
        'http://functionhandler-env.eba-nsef3vus.ap-south-1.elasticbeanstalk.com/functionHandler/uploaddocument',
        formData,
        config,
      )
      .then((res) => {
        console.log('From image upload: ', res)
        if(!res.data.transactionId){
          setError("Upload error")
          return;
        }
        dispatch({
          type: 'UPDATE_PROPS',
          payload: {
            id,
            componentNumber: compNumber,
            data: res.data.transactionId,
            props: 'value',
          },
        })
        setUploading(false)
        //documentId
      })
      .catch((e) => {
        console.log('From image upload: ', e)
        setError("Upload error")
        setUploading(false)
      })

    // axios({
    //   method: "post",
    //   url: "Functionhandler-env.eba-nsef3vus.ap-south-1.elasticbeanstalk.com/functionHandler/uploaddocument",
    //   data: formData,
    //   headers: { "Content-Type": "multipart/form-data" },
    // })
    //   .then(function (response) {
    //     //handle success
    //     console.log("From image upload: ",response);
    //   })
    //   .catch(function (response) {
    //     //handle error
    //     console.log("From image upload: ",response);
    //   });
  }

  if (!useRender({ id, compNumber })) return <></>
  if (uploading) return <>uploading...</>
  return (
    <div style={{padding:20}}>
    <Stack direction="column" alignItems="center" spacing={2}>
      <img src={displayValue?displayValue:"https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg"} alt="img" width={150} height={100} />
<div className='Flex' style={{flexDirection:'row',justifyContent:'space-around',width:'100%'}}>
      <label htmlFor="icon-button-file">
        <Input
          onChange={fileUpload}
          accept="image/*"
          id="icon-button-file"
          type="file"
        />
        {/* <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
        >
          <PhotoCamera />
        </IconButton> */}
      </label>
      <span
        onClick={() => callDispatcher(null)}
        style={{ cursor: 'pointer', color: 'red' }}
      >
        Delete
      </span>
      </div>
      {/* <span onClick={onSave}>Save</span> */}
    </Stack>
  {err && <span style={{color:'red'}}>Error: {err}</span>}
<h5>{label}</h5>
   
    </div>
  )
}
