import {
  extractDimensionStructure,
  isDisabled,
  isEnabled,
  isMappedEditable,
} from "../Dimension/Helpers/helper";
import { extractWeightRules, isMapped } from "../Dimension/Helpers/helper";

export const validateWeights = (g2, g1, p, rule, MapStructure, TargetRule) => {
  // return {
  //   status: true,
  //   message: `Validation Success`,
  // };
  //  console.log("target rule is: ", TargetRule);
  p = p.filter((item) => item.ParameterID && item.ParameterID !== "");
  console.log("filtered: ", p);
  const weightRules = extractWeightRules(rule);
  const mapRule = extractDimensionStructure(MapStructure);
  // const [enabled] = TargetRule.split("");
  let parameterGroup1s, parameterGroup2s, group1Group2s;
  // if (enabled === "E") {
  //   for (let i = 0; i < p.length; i++) {
  //     const { ParameterTargetDataType, ParameterTargets } = p[i];
  //     if (!ParameterTargetDataType)
  //       return {
  //         status: false,
  //         message: `Target Data Type Not Set For Paramter`,
  //       };
  //     if (!ParameterTargets || ParameterTargets?.length === 0) {
  //       return {
  //         status: false,
  //         message: `Targets Not Defined For Paramter`,
  //       };
  //     }
  //     for (let k = 0; k < ParameterTargets.length; k++) {
  //       const { TargetValue, MinimumTargetValue, MaximumTargetValue } =
  //         ParameterTargets[k];
  //       if (
  //         (!TargetValue || TargetValue === "") &&
  //         (!MinimumTargetValue || MinimumTargetValue === "") &&
  //         (!MaximumTargetValue || MaximumTargetValue === "")
  //       ) {
  //         return {
  //           status: false,
  //           message: `Target Values Not Set For Paramter`,
  //         };
  //       }
  //     }
  //   }
  // }

  if (isMapped(mapRule.group1)) {
    parameterGroup1s = p.reduce((parameter, item) => {
      const group = parameter[item.ParameterGroupID] || [];
      group.push(item);
      parameter[item.ParameterGroupID] = group;
      return parameter;
    }, {});
  }

  if (isMapped(mapRule.group2) && isMapped(mapRule.group1)) {
    parameterGroup2s = p.reduce((parameter, item) => {
      const index = g1.findIndex(
        (g) => g.TransactionID === item.ParameterGroupID
      );

      const group = parameter[g1[index]?.SecondGroupID] || [];
      group.push(item);
      parameter[g1[index]?.SecondGroupID] = group;
      return parameter;
    }, {});
  }

  if (isMapped(mapRule.group2) && isMapped(mapRule.group1)) {
    group1Group2s = g1.reduce((group1, item) => {
      const group = group1[item.SecondGroupID] || [];
      group.push(item);
      group1[item.SecondGroupID] = group;
      return group1;
    }, {});
  }

  if (
    isMappedEditable(weightRules.parameter) ||
    isMapped(weightRules.parameter)
  ) {
    if (isMappedEditable(weightRules.group1) || isMapped(weightRules.group1)) {
      for (const [ParameterGroupID, parameters] of Object.entries(
        parameterGroup1s
      )) {
        const index = g1.findIndex(
          (group) => group.TransactionID === ParameterGroupID
        );
        const firstGroup = g1[index];

        const minWeight = firstGroup.Weight;

        let totalParametersWeight = 0;
        parameters.forEach((parameter) => {
          totalParametersWeight += +parameter.Weight;
        });

        if (minWeight < totalParametersWeight)
          return {
            status: false,
            message: `Weight validation failed in group 1 named ${firstGroup.GroupName}! 
                      Total parameter weight inside group 1 should be ${minWeight}% 
                      but instead it added up to ${totalParametersWeight}`,
          };
      }
    } else {
      if (
        isMappedEditable(weightRules.group2) ||
        isMapped(weightRules.group2)
      ) {
        for (const [SecondGroupID, parameters] of Object.entries(
          parameterGroup2s
        )) {
          const index = g2.findIndex(
            (group) => group.TransactionID === SecondGroupID
          );
          const secondGroup = g2[index];

          const minWeight = secondGroup.Weight;

          let totalParametersWeight = 0;
          parameters.forEach((parameter) => {
            totalParametersWeight += +parameter.Weight;
          });

          if (minWeight < totalParametersWeight)
            return {
              status: false,
              message: `Weight validation failed in group 2 named ${secondGroup.GroupName}! 
                      Total parameter weight inside group 2 should be ${minWeight}% 
                      but instead it added up to ${totalParametersWeight}`,
            };
        }
      } else {
        let totalParametersWeight = 0;
        p.forEach((parameter) => {
          totalParametersWeight += +parameter.Weight;
        });
        console.log("Param before weights: ", p);
        if (totalParametersWeight > 100)
          return {
            status: false,
            message: `Weight validation failed for parameters! 
                    Total parameters weight should be less than or equal to 100% 
                    but instead it added up to ${totalParametersWeight}`,
          };
      }
    }
  }

  if (isMappedEditable(weightRules.group1) || isMapped(weightRules.group1)) {
    if (isMappedEditable(weightRules.group2) || isMapped(weightRules.group2)) {
      for (const [SecondGroupID, firstGroups] of Object.entries(
        group1Group2s
      )) {
        const index = g2.findIndex(
          (group) => group.TransactionID === SecondGroupID
        );
        const secondGroup = g2[index];
        const minWeight = secondGroup.Weight;

        let totalGroup1Weight = 0;
        firstGroups.forEach((group) => {
          totalGroup1Weight += +group.Weight;
        });
        if (minWeight < totalGroup1Weight)
          return {
            status: false,
            message: `Weight validation failed in group 2 named ${secondGroup.GroupName}! 
                    Total group1 weight inside group 2 should be${minWeight}% 
                    but instead it added up to ${totalGroup1Weight}`,
          };
      }
    } else {
      let totalGroup1Weight = 0;
      g1.forEach((group) => {
        totalGroup1Weight += group.Weight;
      });
      if (totalGroup1Weight > 100)
        return {
          status: false,
          message: `Weight validation failed for group1's! 
                Total group1's weight should be less than or equal to 100% 
                but instead it added up to ${totalGroup1Weight}`,
        };
    }
  }
  if (isMappedEditable(weightRules.group2) || isMapped(weightRules.group2)) {
    let totalGroup2Weight = 0;
    g2.forEach((group) => {
      totalGroup2Weight += group.Weight;
    });
    if (totalGroup2Weight > 100)
      return {
        status: false,
        message: `Weight validation failed for group2's! 
            Total group2's weight should be less than or equal to 100% 
            but instead it added up to ${totalGroup2Weight}`,
      };
  }
  return {
    status: true,
    message: `Validation Success`,
  };
};
