var agGrid=window.grid;
var columnDefs = [
  {
      "field":"AppraiseeName",
      "headerName":"Appraisee Name",
  },
  {
      "field":"Date",
      "headerName":"Some Period"
  },
  {
      "field":"Stage",
      "headerName":"Stage",
      "filter": 'agSetColumnFilter',
      "filterParams": {
        'buttons': ['reset', 'apply'],
                "values": ['Goal Setting', 'Review', 'Mid Year Review','Awaiting Review','Review Started']
            }
  },
  {
      "field":"Status",
      "headerName":"Status"
  },
  {
      "field":"AppraiserName",
      "headerName":"Appraiser Name",
      "hide":"true"
  },
  {
      "field":"flag",
      "headerName":"Flag",
      "hide":"true"
  }
]
var rowData = [
  {
    "id": "1",
    "AppraiseeName": "Abhay Singh",
    "Date": "01-Jan-2112 - 31-Dec-2112",
    "Stage": "Goal Setting",
    "Status": "Goal Setting Due",
    "AppraiserName": "Sharma Kant",
    "flag": "Need to act"
  },
  {
    "id": "2",
    "AppraiseeName": "Sukumari",
    "Date": "01-Jan-2112 - 31-Dec-2112",
    "Stage": "Goal Setting",
    "Status": "Reset goals",
    "AppraiserName": "Sharma Kant",
    "flag": "Need to act"
  },
  {
    "id": "3",
    "AppraiseeName": "Abhay Singh",
    "Date": "01-Jan-2112 - 31-Dec-2112",
    "Stage": "Review",
    "Status": "Review Due",
    "AppraiserName": "Sharma Kant",
    "flag": "Need to act"
  },
  {
    "id": "4",
    "AppraiseeName": "Abhay Singh",
    "Date": "01-Jan-2112 - 31-Dec-2112",
    "Stage": "Review",
    "Status": "Review Closed",
    "AppraiserName": "Sharma Kant",
    "flag": "Need to act"
  },
  {
    "id": "5",
    "AppraiseeName": "Abhay Singh",
    "Date": "01-Jan-2112 - 31-Dec-2112",
    "Stage": "Awaiting Review",
    "Status": "Goal Setting Due",
    "AppraiserName": "Sharma Kant",
    "flag": "Need to act"
  },
  {
    "id": "6",
    "AppraiseeName": "Abhay Singh",
    "Date": "01-Jan-2112 - 31-Dec-2112",
    "Stage": "Awaiting Review",
    "Status": "Goal Setting Due",
    "AppraiserName": "Sharma Kant",
    "flag": "Need to act"
  },
  {
    "id": "7",
    "AppraiseeName": "Abhay Singh",
    "Date": "01-Jan-2112 - 31-Dec-2112",
    "Stage": "Mid Year Review",
    "Status": "Goal Setting Due",
    "AppraiserName": "Sharma Kant",
    "flag": "Need to act"
  },
  {
    "id": "8",
    "AppraiseeName": "Abhay Singh",
    "Date": "01-Jan-2112 - 31-Dec-2112",
    "Stage": "Mid Year Review",
    "Status": "Goal Setting Due",
    "AppraiserName": "Sharma Kant",
    "flag":"My Request"
  },
  {
    "id": "9",
    "AppraiseeName": "Abhay Singh",
    "Date": "01-Jan-2112 - 31-Dec-2112",
    "Stage": "Mid Year Review",
    "Status": "Goal Setting Due",
    "AppraiserName": "Sharma Kant",
    "flag":"My Request"
  },
  {
    "id": "10",
    "AppraiseeName": "Abhay Singh",
    "Date": "01-Jan-2112 - 31-Dec-2112",
    "Stage": "Mid Year Review",
    "Status": "Goal Setting Due",
    "AppraiserName": "Sharma Kant",
    "flag":"My Request"
  },
  {
    "id": "11",
    "AppraiseeName": "Lisa Ray",
    "Date": "01-Jan-2070 - 31-Dec-2075",
    "Stage": "Review",
    "Status": "Review Self Rated",
    "AppraiserName": "Sharma Kant",
    "flag":"My Request"
  },
  {
    "id": "12",
    "AppraiseeName": "Ankita Sethi",
    "Date": "01-Jan-2020 - 31-Dec-2020",
    "Stage": "Review",
    "Status": "Review Reviewer Rated",
    "AppraiserName": "Sharma Kant",
    "flag":"My Request"
  },
  {
    "id": "13",
    "AppraiseeName": "Prakshitha Sethi",
    "Date": "01-Jan-2020 - 31-Dec-2020",
    "Stage": "Review",
    "Status": "Review Reviewer Rated",
    "AppraiserName": "Sharma Kant",
    "flag":"My Request"
  },
  {
    "id": "14",
    "AppraiseeName": "Prakshitha Sethi",
    "Date": "01-Jan-2020 - 31-Dec-2020",
    "Stage": "Review",
    "Status": "MultiRater Submission Due",
    "AppraiserName": "Sharma Kant",
    "flag":"My Request"
  },
  {
    "id": "15",
    "AppraiseeName": "Parimal Ayyar",
    "Date": "01-Jan-2020 - 31-Dec-2020",
    "Stage": "Review",
    "Status": "Review Due",
    "AppraiserName": "Sharma Kant",
    "flag":"My Request"
  }
]
  var yearFilter=null;
var gridOptions = {

    defaultColDef: {
      flex: 1,
      minWidth: 150,
      filter: true,
      sortable : true,
      resizable : true,
      enableRowGroup : true
    },
    components: {
      YearFilter: null,
    },
    sideBar : true,
    pagination: true,
    paginationPageSize: 10,
    onCellClicked : function(e){
      window.location.href = "http://google.com"
    },
    isExternalFilterPresent: isExternalFilterPresent,
    doesExternalFilterPass: doesExternalFilterPass,
    onFilterChanged: function (e) {
      var RowCount = gridOptions.api.getDisplayedRowCount();
      if(RowCount == 0)
        window.location.href = "http://google.com"
    }
  };

var ageType = 'all';

function isExternalFilterPresent() {
  // if ageType is not everyone, then we are filtering
  return ageType !== 'all';
}

function doesExternalFilterPass(node) {
  switch (ageType) {
    case 'needtoact':
      return node.data.flag === "Need to act";
    case 'myrequests':
      return node.data.flag === "My Request";
    default:
      return true;
  }
}

function externalFilterChanged(newValue) {
  ageType = newValue;
  gridOptions.api.onFilterChanged();
}

var savedFilterModel = null;
var model = null;

function saveFilterModel() {
  savedFilterModel = gridOptions.api.getFilterModel();
  var ColumnState = gridOptions.columnApi.getColumnState();
  document.cookie = "filters="+JSON.stringify(savedFilterModel);
  document.cookie = "columns="+JSON.stringify(ColumnState);
}

function getCookie(key) 
{
  var allcookies = document.cookie;
  var cookiearray = allcookies.split('; ');
  for(var i=0; i<cookiearray.length; i++) 
  {
     var name = cookiearray[i].split('=')[0];
     var value = cookiearray[i].split('=')[1];
     if(key === name)
      return value
  }
}


function restoreFilterModel() {
  var savedFilters = getCookie("filters");
  var savedColumns = getCookie("columns");
  console.log(savedFilters,savedColumns);
  var filterModel = JSON.parse(savedFilters);
  savedColumns = JSON.parse(savedColumns);
  if(savedFilters)
    gridOptions.api.setFilterModel(filterModel);
  if(savedColumns)
    gridOptions.columnApi.setColumnState(savedColumns);
}

function resetFilters(){
  gridOptions.api.setFilterModel(null);
}


function main(test){
  console.log("Inside ",test)
document.addEventListener('DOMContentLoaded', function () {
    var gridDiv = document.querySelector('#myGrid');
    console.log("from grid ",gridDiv)
    new agGrid.Grid(gridDiv, gridOptions);
    agGrid
    .simpleHttpRequest({
      url: 'https://c884f8bf-af1c-40d5-8ebe-57ade8f66528.mock.pstmn.io/gridview',
    })
    .then(function (data) {
      //gridOptions.api.setRowData(data.slice(0, 50));
      gridOptions.api.setRowData(data.Rows);
      gridOptions.api.setColumnDefs(data.Columns);
      //console.log(data)
    });
  });
}

function fun2(){
  var gridDiv = document.querySelector('#myGrid');
  console.log("from grid ",agGrid)
  console.log("Before laod ",document.getElementById("dtm"))
  document.getElementById("dtm").addEventListener('load', () => {
    // DTM is loaded
    console.log("loaded ",window.grid)
    var agGrid=window.grid;
    new agGrid.Grid(gridDiv, gridOptions);
  agGrid
  .simpleHttpRequest({
    url: 'https://c884f8bf-af1c-40d5-8ebe-57ade8f66528.mock.pstmn.io/gridview',
  })
  .then(function (data) {
    //gridOptions.api.setRowData(data.slice(0, 50));
    gridOptions.api.setRowData(data.Rows);
    gridOptions.api.setColumnDefs(data.Columns);
    //console.log(data)
  });
  })
  
  console.log("Called ",gridDiv)
  document.addEventListener('DOMContentLoaded', function () {
 
  });
  
 
}



export default fun2;