import React from 'react'
import EWButton from '../Atmoic/EWButton'
import './MoleculeStyles.css'

function EWButtonArray({ Actions }) {
    console.log("From BTN Array")
  return (
    <>
      <div className="flex" style={{ justifyContent: 'space-around' }}>
        {Actions.map((ele, index) => {
          function Handler() {
            console.log(ele.Feature_url)
          }

          return (
            <EWButton
              Type={ele.Type}
              Color={ele.Color}
              Title={ele.Title}
              Handler={Handler}
            />
          )
        })}
      </div>
    </>
  )
}

export default EWButtonArray;
